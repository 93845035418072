import app11En from './app11/en';
import app11Ua from './app11/ua';

export default {
  en: {
    translation: {
      ...app11En,
    },
  },
  ua: {
    translation: {
      ...app11Ua,
    },
  },
};
