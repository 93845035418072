import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AlertCircle } from 'react-feather';

const Error = styled.small`
  position: absolute;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  margin: -10px 0 0 0;

  svg {
    margin-right: 0.25rem;
    stroke: ${(props) => props.color};
  }
`

const IconWrapper = styled.div`
  position: relative;
  top: 0.12rem;
`;

export default function ValidationErrorMessage({ color, error, id, showFor }) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (showFor) {
      setTimeout(() => {
        setShow(false);
      }, showFor);
    }
  });

  return (
    show && (
      <Error
        color={color}
        name="validation-error"
        role="alert"
      >
        <IconWrapper>
          <AlertCircle size={14} />
        </IconWrapper>
        {error}
      </Error>
    )
  );
}

ValidationErrorMessage.propTypes = {
  color: PropTypes.string,
  error: PropTypes.string.isRequired,
  id: PropTypes.string,
  showFor: PropTypes.number,
};

ValidationErrorMessage.defaultProps = {
  color: '#E21F14',
  id: '',
  showFor: 0,
};
