import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled/macro';
import { Check, Copy, ExternalLink, X } from 'react-feather';
import SvgButton from './SvgButton';
import { colors } from '../constants';
import Loading from "./Loading";
import { Button } from "./index";

export const ModalBackground = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
`;

const ModalWrapper = styled.div`
  position: relative;
  min-height: 25vh;
  width: ${props => props.width};
`;

const ModalScrollWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
`

const ModalContent = styled.div`
  position: relative;
  background: ${colors.white};
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0 18px 64px -8px rgba(28, 50, 79, 0.19), 0px 4px 24px -3px rgba(28, 55, 90, 0.12);
  width: 100%;
  height: 100%;
  max-height: 85vh;
  display: flex;
  flex-flow: column;

  input {
    &::placeholder {
      color: ${(props) => (props.hasError ? `${colors.error}` : `${colors.text}`)};
    }
  }
`

const ModalIcons = styled.div`
  position: absolute;
  top: -2.5rem;
  right: 0;
  display: flex;
  background: ${colors.white};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.08), 0 0 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
`

const ModalHeader = styled.div`
  display: flex;
  padding: 1.25rem 1.5rem;

  &:empty {
    display: none;
  }
`

const ModalTitle = styled.h2`
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;

  &:empty {
    display: none;
  }
`

const IconContainer = styled(SvgButton)`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.4rem;

  &:focus {
    box-shadow: 0 0 0 0.125rem ${colors.focus};
  }

  svg {
    color: ${colors.textLight};
    width: 1.1rem;
  }
`

const ModalBody = styled.div`
  line-height: 1.7;
  padding: 0 1.5rem;
  white-space: pre-line;
`

const Buttons = styled.div`
  background: ${colors.grayBg};
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0.75rem;
  margin-top: 2rem;
  button:first-of-type {
    margin: 0;
  }
`

// create a Modal root container if none exists this is in index.html but cleans up tests
let modalRoot = document.getElementById('modal-root')
if (!modalRoot) {
  modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'modal-root')
  document.body.appendChild(modalRoot)
}

function handleTabKey(e, modalRef) {
  const focusableModalElements =
    modalRef.current?.querySelectorAll(
      'a[href], button, div[tabindex="0"], textarea, input[type="text"],' +
        ' input[type="radio"], input[type="checkbox"], select, li'
    ) ?? []
  const firstElement = focusableModalElements[0]
  const lastElement = focusableModalElements[focusableModalElements.length - 1]
  const nodeList = focusableModalElements ? Array.from(focusableModalElements) : []

  // tab is pressed right after modal pops ups
  if (!e.shiftKey && firstElement && !nodeList?.includes(document.activeElement)) {
    firstElement.focus()
    return e.preventDefault()
  }

  // last element in modal is active and force tabbing to first instead of modal background
  if (
    !e.shiftKey &&
    firstElement &&
    !document.activeElement.isEqualNode(firstElement) &&
    document.activeElement.isEqualNode(lastElement)
  ) {
    firstElement.focus()
    return e.preventDefault()
  }

  return null
}

export default function Modal({
  buttons,
  children,
  dismissable,
  isLoading,
  isOpen,
  onClose,
  onOpen,
  onClone,
  onSave,
  saveIcon,
  text,
  title,
  width,
}) {
  const handleClose = () => {
    if (dismissable) onClose()
  }
  const modalRef = React.createRef()
  const onTabKey = (e) => handleTabKey(e, modalRef)
  const keyListenersMap = new Map([
    [27, onClose],
    [9, onTabKey],
  ])

  React.useEffect(() => {
    function keyListener(e) {
      const listener = keyListenersMap.get(e.keyCode)
      return listener && listener(e)
    }
    document.addEventListener('keydown', keyListener)

    return () => document.removeEventListener('keydown', keyListener)
  })

  const renderButton = (onClick, ariaLabel, Icon) => {
    return (
      onClick && (
        <IconContainer aria-label={ariaLabel} onClick={onClick} role="button">
          <Icon size="24" />
        </IconContainer>
      )
    )
  }

  return (
    isOpen &&
    ReactDOM.createPortal(
      <ModalBackground aria-modal="true" data-testid="modal-background" onClick={handleClose} role="dialog">
        <ModalWrapper width={width} ref={modalRef}>
          <ModalIcons
            onClick={(e) => {
              e.stopPropagation()
            }}>
            {renderButton(onOpen, 'link', ExternalLink)}
            {renderButton(onSave, 'save', saveIcon)}
            {renderButton(onClone, 'clone', Copy)}
            {dismissable && renderButton(onClose, 'close', X)}
          </ModalIcons>
          <ModalContent
            onClick={(e) => {
              e.stopPropagation()
            }}>
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <ModalScrollWrapper>
                  <ModalBody>
                    {text}
                    {children}
                  </ModalBody>
                </ModalScrollWrapper>
                {buttons && (
                  <Buttons>
                    {buttons.map((button) => (
                      <Button
                        background={button.background}
                        color={button.color || Button.COLOR.BG_BLUE}
                        key={`dialog-button-${button.label}`}
                        tabIndex={0}
                        type="button"
                        disabled={button.disabled}
                        onClick={button.onClick}>
                        {button.label}
                      </Button>
                    ))}
                  </Buttons>
                )}
              </>
            )}
          </ModalContent>
        </ModalWrapper>
      </ModalBackground>,
      document.getElementById('modal-root')
    )
  )
}

Modal.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      background: PropTypes.string,
      color: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  children: PropTypes.element,
  dismissable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClone: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onSave: PropTypes.func,
  saveIcon: PropTypes.shape({}),
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
};

Modal.defaultProps = {
  buttons: [],
  children: null,
  dismissable: true,
  isLoading: false,
  onClone: null,
  onClose: null,
  onOpen: null,
  onSave: null,
  saveIcon: Check,
  text: '',
  width: '420px',
};
