import React, { useContext, useEffect, useState } from 'react'
import { authContext } from '../../contexts/AuthContext'
import { ROUTE, SnackbarStatus } from '../../constants'
import MenuItem from './MenuItem'
import MenuProcessesInfo from './MenuProcessesInfo'
import MenuMessagesInfo from './MenuMessagesInfo'
import iconHome from './icons/icon-home.png'
import iconActual from './icons/launcher-vehicle.png'
import iconProcesses from './icons/icon-processes.png'
import iconVehicles from './icons/icon-vehicles.png'
import iconRockets from './icons/icon-rockets.png'
import iconUsers from './icons/icon-users.png'
import iconOperationGroups from './icons/icon-operation-groups.png'
import iconMessages from './icons/icon-messages.png'
import iconObjcts from './icons/icon-objcts.png'
import iconGoals from './icons/icon-goals.png'
import iconGoalsAuto from './icons/icon-goals-auto.png'
import iconZones from './icons/icon-zones.png'
import iconReferences from './icons/icon-references.png'
import { httpRequests, getApiUrl } from '../../utils'
import { useSnackbar } from '../../contexts/SnackbarContext'

const SETTINGS_REQUEST_INTERVAL = 60 * 1000

const Menu = () => {
  const { auth, checkIsAutonomousLaunchAllowed, isAutonomousLaunch, workplace, refreshWorkplace } =
    useContext(authContext)
  const [autoLaunchAllowed, setAutoLaunchAllowed] = useState(checkIsAutonomousLaunchAllowed())
  const [localAutoLaunchAllowed, setLocalAutoLaunchAllowed] = useState(isAutonomousLaunch)
  const { showSnackbar } = useSnackbar()

  const requestSettingsInfo = async (url) => {
    try {
      const response = await httpRequests.get(url)
      if (response.status === 200 && response.data?.data) {
        await refreshWorkplace()
        const newStatus = response.data?.data?.vehicle?.is_autonomous_launch
        if (localAutoLaunchAllowed !== newStatus) {
          // const newStatusAutolaunch = checkIsAutonomousLaunchAllowed(newStatus)
          showSnackbar({
            message: newStatus ? 'Доступ до автономного пуску надано' : 'Доступ до автономного пуску відкликано',
            status: SnackbarStatus.default,
          })
        }
        setLocalAutoLaunchAllowed(newStatus)
        setAutoLaunchAllowed(checkIsAutonomousLaunchAllowed(newStatus))
      } else {
        throw new Error('Помилка запиту даних')
      }
    } catch (e) {
      console.error(e.message)
    }
  }

  useEffect(() => {
    let handlerInterval = null
    if (SETTINGS_REQUEST_INTERVAL && auth?.data !== null) {
      ;(async () => {
        await requestSettingsInfo(getApiUrl('app_settings'))
      })()
      handlerInterval = setInterval(async () => {
        await requestSettingsInfo(getApiUrl('app_settings'))
      }, SETTINGS_REQUEST_INTERVAL)
    }
    return () => {
      clearInterval(handlerInterval)
    }
  }, [auth, auth?.data, localAutoLaunchAllowed])

  return (
    <nav>
      <MenuItem to={ROUTE.home} icon={iconHome} text="Головна" />
      <MenuItem to={ROUTE.actual.root} icon={iconActual} text="Актуальний стан" permission={'actual_state'} />
      <MenuItem to={ROUTE.processes.root} icon={iconProcesses} text="Процеси" permission={'processes'}>
        <MenuProcessesInfo filterByUser filterByUnit />
      </MenuItem>
      <MenuItem to={ROUTE.vehicles.root} icon={iconVehicles} text="Техніка" permission={'technique'} />
      <MenuItem to={ROUTE.rockets.root} icon={iconRockets} text="Засоби ураження" permission={'rockets'} />
      <MenuItem to={ROUTE.users.list} icon={iconUsers} text="Особовий склад" permission={'users'} />
      <MenuItem
        to={ROUTE.operationGroups.tree}
        icon={iconOperationGroups}
        text="Оперативні групи"
        permission={'operation_groups'}
      />
      <MenuItem to={ROUTE.messages.root} icon={iconMessages} text="Повідомлення" permission={'messages'}>
        <MenuMessagesInfo limit={100} />
      </MenuItem>
      <MenuItem to={ROUTE.objcts.list} icon={iconObjcts} text="Обʼєкти" permission={'objects'} />
      <MenuItem to={ROUTE.goals.root} icon={iconGoals} text="Цілевказання" permission={'distribution'} />
      {(auth?.data?.isPrdAdmin || workplace?.appSettings?.vehicle?.vehicleDictionary?.vehicleType?.type === 'SPL') && (
        <MenuItem
          to={ROUTE.goalsAuto.root}
          icon={iconGoalsAuto}
          text="Автономний пуск"
          permission={'autonomous_launch'}
          isForcedAllow={autoLaunchAllowed}
        />
      )}
      <MenuItem
        to={ROUTE.zones.root}
        icon={iconZones}
        text="Заборонені зони"
        permission={'zoning'}
        isForcedAllow={autoLaunchAllowed}
      />
      <MenuItem
        to={ROUTE.vehicles.dictionaries.list}
        icon={iconReferences}
        text="Довідники"
        permission={'references'}
      />
    </nav>
  )
}

export default Menu
