import { useState, useEffect, useContext } from 'react'
import styled from '@emotion/styled/macro'
import { authContext } from '../../contexts/AuthContext'
import { httpRequests, getApiUrl } from '../../utils'
import { Modal, Button } from '../../components'

const Notifications = styled.div`
  position: absolute;
  bottom: 3px;
  left: 24px;
  height: 12px;
  display: flex;
  div + div {
    margin-left: 3px;
  }
`;

const UserWrapper = styled.div`
  font-size: 10px;
  color: ${(props) => props.color || '#ffc20b'};
  border: 1px solid ${(props) => props.color || '#d7d7d1'};
  border-radius: 50%;
  min-width: 12px;
  height: 12px;
  text-align: center;
  line-height: 1.2;
  font-weight: bold;
`

const requestMessagesInfo = async (url, setData) => {
  try {
    const response = await httpRequests.get(url)
    if (response.status === 200 && response?.data) {
      setData(response.data.amount)
    } else {
      throw new Error('Помилка запиту даних')
    }
  } catch (e) {
    console.error(e.message)
  }
}

const useIntervalRequest = (url, intervalSeconds) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    let handlerInterval = null
    ;(async () => {
      await requestMessagesInfo(url, setData)
    })()
    if (intervalSeconds) {
      handlerInterval = setInterval(async () => {
        await requestMessagesInfo(url, setData)
      }, intervalSeconds * 1000)
    }
    return () => {
      clearInterval(handlerInterval)
    }
  }, [intervalSeconds, url])

  return data;
}

const renderModal = (isOpen, content, color, closeModalFunction) => (
  <Modal
    isOpen={isOpen}
    text={content}
    title="Увага!"
    buttons={[
      {
        color,
        label: 'Зрозуміло',
        onClick: () => closeModalFunction(),
      },
    ]}
  />
)

function MenuMessagesInfo({ limit = 100, intervalSeconds = 60 }) {
  const { auth } = useContext(authContext)
  const userEntityId = auth?.data?.entityId
  const filter = `filter[personOnDuty]=1&filter[status]=new&filter[is_draft]=false&limit=${limit}`
  const filterForInformation = `filter[myThreadsForInformation]=1&filter[status]=new&filter[is_draft]=false&limit=${limit}`
  const [prevCountPersonOnDuty, setPrevCountPersonOnDuty] = useState(0)
  const [prevCountForInformation, setPrevCountForInformation] = useState(0)
  const [showOnDutyDialog, setShowOnDutyDialog] = useState(false)
  const [showInformationDialog, setShowInformationDialog] = useState(false)

  const dataPersonOnDuty = useIntervalRequest(`${getApiUrl('messages_amount')}${filter}`, intervalSeconds)
  const dataForInformation = useIntervalRequest(`${getApiUrl('messages_amount')}${filterForInformation}`, intervalSeconds)

  useEffect(() => {
    setPrevCountPersonOnDuty(dataPersonOnDuty)

    if (dataPersonOnDuty > prevCountPersonOnDuty) setShowOnDutyDialog(true)
  }, [dataPersonOnDuty, prevCountPersonOnDuty])

  useEffect(() => {
    setPrevCountForInformation(dataForInformation)

    if (dataForInformation > prevCountForInformation) setShowInformationDialog(true)
  }, [dataForInformation, prevCountForInformation])

  return (dataPersonOnDuty + dataForInformation)
    ? (
      <>
        {showOnDutyDialog && renderModal(showOnDutyDialog, 'З\'явилися нові повідомлення', Button.COLOR.WARNING, () => setShowOnDutyDialog(false))}
        {showInformationDialog && renderModal(showInformationDialog, 'З\'явилися нові повідомлення "До відома"', Button.COLOR.SUCCESS, () => setShowInformationDialog(false))}

        <Notifications>
          {dataPersonOnDuty > 0 ? <UserWrapper>{dataPersonOnDuty}</UserWrapper> : ''}
          {dataForInformation > 0 ? <UserWrapper color="#56C271">{dataForInformation}</UserWrapper> : ''}
        </Notifications>
      </>
    )
    : '';
}

export default MenuMessagesInfo
