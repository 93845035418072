import React, {useCallback} from 'react';
import useBatchDialog from "../hooks/useBatchDialog";
import useList from "../hooks/useList";
import { Button, DialogArchive, Page, Table } from "../components";
import { PAGE_SIZE } from '../constants';

const getActionsDefault = (listProps, defaultActions) => listProps.isArchived ? [] : defaultActions;

const BaseListPage = ({
  afterCleanCallback = () => {},
  apiSlug,
  apiURLFilter = '',
  children = null,
  collapsableFilters = false,
  controlsOnBottom = true,
  controlsOnTop = false,
  dataLoading = false,
  getActions = getActionsDefault,
  getBatchActions = getActionsDefault,
  getColumns,
  getTopContent = null,
  topTableContent = null,
  hideList = false,
  renderFilters = null,
  route,
  stateOverrides = {},
  storageKey = '',
  tabs,
  title,
}) => {
  const listData = useList(apiSlug, stateOverrides, storageKey, apiURLFilter)
  const { batchData, isDialogOpen, handleDialogOpen, handleDialogClose } = useBatchDialog()
  const initialFilters = stateOverrides.filters
  const {
    page,
    data,
    meta,
    isArchived,
    loading,
    sort,
    sortAsc,
    searchTerm,
    sortTable,
    setSearchTerm,
    refreshData,
    setPage,
    setIsArchived,
    setFilters,
  } = listData

  const resetFilters = useCallback(() => setFilters(initialFilters), [setFilters, initialFilters])

  const actions = getActions(listData, [
    {
      title: 'Додати запис',
      icon: 'add',
      color: Button.COLOR.PRIMARY,
      link: route.add,
    },
  ])

  const batchActions = getBatchActions(listData, [
    {
      title: 'Архівувати',
      icon: 'archiveBlue',
      color: Button.COLOR.BLUE,
      onClick: handleDialogOpen,
    },
  ])

  return (
    <Page
      filters={renderFilters && renderFilters(listData)}
      initialSearch={searchTerm}
      isArchived={isArchived}
      loading={loading || dataLoading}
      onClean={() => {
        if (renderFilters) {
          resetFilters();
          afterCleanCallback();
        }

        return undefined;
      }}
      onSearch={!hideList ? setSearchTerm : undefined}
      onSwitchArchived={!hideList ? setIsArchived : undefined}
      tabs={tabs}
      title={title}
      collapsableFilters={collapsableFilters}
      topContent={getTopContent && getTopContent(isArchived)}>
      {topTableContent}
      {!hideList && (
        <Table
          actions={actions}
          batchActions={batchActions}
          columns={getColumns(isArchived)}
          controlsOnBottom={controlsOnBottom}
          controlsOnTop={controlsOnTop}
          changePageCallback={setPage}
          currentPage={page}
          data={{
            result: data,
            total: meta?.total,
          }}
          pageSize={PAGE_SIZE}
          sortAsc={sortAsc}
          sortBy={sort}
          sortDataCallback={sortTable}
        />
      )}
      <DialogArchive
        batchData={batchData}
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        refreshData={refreshData}
        routeSlug={`${apiSlug}_entity`}
      />
      {children}
    </Page>
  )
}

export default BaseListPage;
