import React, { useEffect, useState } from 'react'
import styled from "@emotion/styled/macro";

const StyledButton = styled.button`
  && {
    display: flex;
    position: relative;
    width: ${({ width }) => width};
    height: 1.75rem;
    margin: 0.5rem;
    padding: 0;
    cursor: pointer;
    background-color: rgba(28, 55, 90, 0.16);
    border: 0;
    border-radius: 2.5rem;
    opacity: 1;

    &.ro_mode {
      opacity: 0.5;
    }

    &.on {
      background-color: #006af5;

      .slider {
        background: rgba(226, 238, 254, 0.8);
        transform: translateX(calc((${({ width }) => width} - 9px) / 2 - 90%)) translateY(-50%);
      }

      .name {
        justify-content: flex-start;
        color: #fff;
      }
    }

    &.off {
      background-color: rgba(28, 55, 90, 0.16);

      .slider {
        background: #006af5;
        transform: translateX(calc((9px - ${({ width }) => width}) / 2 - 10%)) translateY(-50%);
      }

      .name {
        justify-content: flex-end;
        color: #1b2b41;
      }
    }

    .slider {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background-color: #000;
      box-sizing: border-box;
      transition: transform 125ms, background-color 125ms, border-color 125ms;
    }

    .name {
      position: absolute;
      top: 0;
      right: 5px;
      bottom: 0;
      left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.6rem;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
`

const Toggler = ({
  isReadOnly = false,
  isEnabled = false,
  labels = { false: 'off', true: 'on' },
  width = '4rem',
  callbackAction = null,
  className,
  ...props
}) => {
  const [enabled, setEnabled] = useState(isEnabled)
  const clickHandler = (e) => {
    e.preventDefault()
    if (!isReadOnly) {
      setEnabled(!enabled)
      if (typeof callbackAction === 'function') {
        callbackAction(!enabled)
      }
    }
  }

  useEffect(() => {
    setEnabled(isEnabled)
  }, [isEnabled])

  return (
    <StyledButton
      className={`${className ? className : ''} ${enabled ? 'on' : 'off'} ${isReadOnly ? 'ro_mode' : ''}`}
      onClick={clickHandler}
      width={width}
      {...props}>
      <div className="name">{labels[enabled]}</div>
      <div className="slider" />
    </StyledButton>
  )
}

export default Toggler
