import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Search, Filter } from 'react-feather';
import { Button, Input, Loading, Tabs, Toggler, Collapse } from "../../components";
import styles from './Page.module.css';
import styled from "@emotion/styled/macro";

const ClearButton = styled(Button)`
  align-self: flex-end;
  height: 2.25rem;
`;

const Page = ({
  actions,
  breadcrumbs,
  bottomContent = null,
  children,
  collapsableFilters = false,
  filters = null,
  isArchived = false,
  loading = false,
  onClean = null,
  initialSearch = '',
  onSearch = null,
  onSwitchArchived = null,
  subtitle,
  tabs,
  text,
  title,
  topContent = null,
  contentClassName = '',
  doNotHideContentOnLoading = false,
}) => {
  const [searchText, setSearchText] = useState(initialSearch)
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const renderFiltersBlock = () => (
    onSearch ? (
      <div className={styles.search}>
        <Input
          icon={<Search />}
          label="Пошук"
          maxWidth="20rem"
          name="search"
          noMargin
          onChange={(e) => setSearchText(e.target.value)}
          onIconClick={() => onSearch(searchText)}
          onKeyDown={(e) => {
            if (e.keyCode === 13 || e.key.toLowerCase() === 'enter') {
              onSearch(searchText)
            }
          }}
          placeholder="Значення пошуку"
          value={searchText}
        />
        {!!filters && (
          <div className={styles.filters}>
            {filters}
          </div>
        )}
        {!!onClean && (
          <ClearButton
            color={Button.COLOR.GRAY}
            onClick={() => {
              setSearchText('')
              onClean(null)
              onSearch('')
            }}>
            Очистити
          </ClearButton>
        )}
      </div>
    ) : null
  )

  useEffect(() => setSearchText(initialSearch), [initialSearch])
  useEffect(() => {
    const defaultFilters = filters?.props?.listData?.stateOverrides?.filters;
    const appliedFilters = filters?.props?.listData?.filters;
    setIsFilterApplied((JSON.stringify(defaultFilters) !== JSON.stringify(appliedFilters)) || !!initialSearch);
  }, [filters, initialSearch])

  return (
    <>
      {title && <div className={styles.contentHeader}>{!!title && <h1 className={styles.title}>{title}</h1>}</div>}
      {!!tabs && <Tabs items={tabs} />}
      {!!breadcrumbs && (
        <ul className={styles.breadcrumbs}>
          {breadcrumbs.map((breadcrumb, index) => {
            let element
            if (breadcrumb.link) {
              element = (
                <Button link={breadcrumb.link} color={Button.COLOR.GRAY} rightMargin>
                  {breadcrumb.text}
                </Button>
              )
            } else if (breadcrumb.onClick) {
              element = (
                <button type="button" onClick={breadcrumb.onClick}>
                  {breadcrumb.text}
                </button>
              )
            } else {
              element = <span>{breadcrumb.text}</span>
            }

            return <li key={index}>{element}</li>
          })}
        </ul>
      )}
      {!!topContent && <div className={styles.topContent}>{topContent}</div>}
      {(!!onSearch || !!onSwitchArchived) && (
        <div className={styles.header}>
          <div className={styles.filterPanel}>
            {!!collapsableFilters ? (
              <Collapse
                isOpen={isFilterApplied}
                iconBefore={<Filter size="1rem" />}
                closeTitle="Фільтри та пошук"
                openTitle="Фільтри та пошук"
              >
                {renderFiltersBlock()}
              </Collapse>
            ) : renderFiltersBlock()}
          </div>
          {!!onSwitchArchived && (
            <div className={styles.toggle}>
              <label className={styles.toggleLabel}>Архівні записи</label>
              <Toggler
                isEnabled={isArchived}
                labels={{ false: 'Вимк.', true: 'Увім.' }}
                callbackAction={(v) => onSwitchArchived(v)}
              />
            </div>
          )}
        </div>
      )}
      {!!actions && (
        <div className={styles.actions}>
          {actions.map((action) =>
            !action ? null : (
              <Button key={action.title} color={Button.COLOR.GRAY} {...action}>
                {action.title}
              </Button>
            )
          )}
        </div>
      )}

      {loading && (<Loading />)}
      {(!loading || doNotHideContentOnLoading) && (
        <div className={`${styles.content} ${contentClassName}`}>
          {!!subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          {!!text && <p className={styles.text}>{text}</p>}
          {children}
        </div>
      )}
      {!!bottomContent && <div className={styles.bottomContent}>{bottomContent}</div>}
    </>
  )
}

Page.propTypes = {
  actions: PropTypes.array,
  children: PropTypes.any,
  filters: PropTypes.any,
  title: PropTypes.any,
  text: PropTypes.string,
  subtitle: PropTypes.any,
  breadcrumbs: PropTypes.array,
  tabs: PropTypes.array,
  contentClassName: PropTypes.string,
  doNotHideContentOnLoading: PropTypes.bool,
}

export default Page;
