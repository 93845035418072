import React from 'react';
import styled from '@emotion/styled/macro';

const StatBox = styled.div`
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 30px 30px 80px 30px;
  position: relative;
`;

const StatLabel = styled.div`
  color: #003057;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
`;

const StatNumber = styled.div`
  color: #56C271;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
`;

const StatBlock = ({ label, number }) => (
  <StatBox>
    <StatLabel>{label}</StatLabel>
    <StatNumber>{number}</StatNumber>
  </StatBox>
);

export default StatBlock;
