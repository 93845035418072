import styled from '@emotion/styled/macro';

const Label = styled.label`
  color: ${({ hasError }) => (hasError ? '#E21F14' : '#192434')};
  font-size: 0.875rem;
  pointer-events: none;
  transition: all 0.1s ease-out;
`;

export default Label;
