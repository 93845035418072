import React from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import styled from "@emotion/styled/macro";
import * as svg from './svg';

const IconElement = styled.span`
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: flex;
  align-items: center;
`;

const Icon = ({ kind, className }) => {
  const SvgIcon = svg[camelCase(kind)];
  return (
    <IconElement className={className}>
      {!!SvgIcon && <SvgIcon />}
    </IconElement>
  );
};

Icon.propTypes = {
  kind: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Icon;
