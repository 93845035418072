import React, { useState, forwardRef } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select/creatable';
import ReactSelectAsync from 'react-select/async';
// import Chip from 'components/shared/Chip/Chip';
import getSelectProps from './ReactSelectProps';

/*
 * Search start enum
 */
export const SEARCH_START = {
  /**
   * The search starts as soon as the component is rendered for the first time.
   */
  firstRender: 'firstRender',
  /**
   * The search starts when the user opens the select menu.
   */
  menuOpen: 'menuOpen',
  /**
   * The search starts as soon as the user types.
   */
  typing: 'typing',
};

/**
 * Returns true if the component will render a container (e.g., div) with all the
 * multiple selected options
 *
 * @param {boolean} isMulti - component prop
 * @param {boolean} isOptionsPanelVisible - component prop
 * @param {object} selectedOption - component prop
 */
export const isOptionsPanelRendered = ({ isMulti, isOptionsPanelVisible, selectedOption }) =>
  isOptionsPanelVisible && isMulti && Array.isArray(selectedOption) && !!selectedOption.length;

const SelectFieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  position: relative;
  border-radius: 0.3125rem;
  width: ${props => (props.width ? props.width : '100%')};
`;

/**
 * ReactSelectWrapper Component:
 * Wraps the ReactSelect and ReactSelectAsync components to keep
 * our (Scribe) boundaries and its boundaries clean. This approach
 * makes it easy to replace ReactSelect if that is ever necessary.
 */
const ReactSelectWrapper = forwardRef(
  (
    {
      autoFocus,
      customStyles,
      dropdownIndicator,
      errorMessage,
      formatCreateLabel,
      hasLabel,
      inputId,
      isCreatable,
      isDisabled,
      isInline,
      isLoading,
      isMenuOpen,
      isMulti,
      isOptionsPanelVisible,
      name,
      onBlur,
      onChange,
      onCreateOption,
      onFocus,
      onInputChange,
      onRemoveMultiOption,
      options,
      placeholder,
      searchHandler,
      searchInitialOptions,
      searchStart,
      selectedOption,
      width,
      menuPosition,
    },
    ref,
  ) => {
    const hasInitialOptions = !!(searchInitialOptions && searchInitialOptions.length);
    const [hasLoadedOptions, setHasLoadedOptions] = useState(hasInitialOptions);
    const [asyncKey, setAsyncKey] = useState(Date.now());
    const [searchImmediately, setSearchImmediately] = useState(
      searchStart === SEARCH_START.firstRender,
    );
    // If the searchHandler is provided, use the ReactSelectAsync component.
    const isAsync = !!searchHandler;

    // If the search should start when the menu is opened, set the async 'key' prop
    // in order to reset (create a new instance of) the ReactSelectAsync component.
    // This will trigger the search when the component is "re-mounted".
    const onMenuOpen = () => {
      if (searchStart === SEARCH_START.menuOpen && !hasLoadedOptions) {
        setAsyncKey(Date.now());
        setHasLoadedOptions(true);
        setSearchImmediately(true);
      }
    };

    // Get the props needed by react-select.
    const selectProps = getSelectProps({
      autoFocus,
      customStyles,
      dropdownIndicator,
      formatCreateLabel,
      hasError: !!errorMessage,
      hasLabel,
      inputId,
      isCreatable,
      isDisabled,
      isInline,
      isLoading,
      isMenuOpen,
      isMulti,
      name,
      onBlur,
      onChange,
      onCreateOption,
      onFocus,
      onInputChange,
      onMenuOpen,
      options,
      placeholder,
      ref,
      searchHandler,
      searchImmediately,
      searchInitialOptions,
      selectedOption,
      menuPosition
    });

    return (
      <SelectFieldset data-testid={name} isInline={isInline} width={width}>
        {isAsync ? (
          <ReactSelectAsync key={asyncKey} {...selectProps} />
        ) : (
          <ReactSelect {...selectProps} />
        )}
        {/*{isOptionsPanelRendered({ isMulti, isOptionsPanelVisible, selectedOption }) &&*/}
        {/*  selectedOption.map(selected => (*/}
        {/*    <Chip*/}
        {/*      isDisabled={isDisabled}*/}
        {/*      key={selected.value}*/}
        {/*      label={selected.label}*/}
        {/*      onChipRemoved={option => onRemoveMultiOption({ name, option })}*/}
        {/*      value={selected.value}*/}
        {/*    />*/}
        {/*  ))}*/}
      </SelectFieldset>
    );
  },
);

/**
 * Creates a PropType for the ReactSelectWrapper option.
 *
 * @property {string|number} value - The (unique) value of the option
 * @property {string|number} label - The text indicating the meaning of the option
 */
export const PropTypeOption = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.instanceOf(Date)]),
});

ReactSelectWrapper.propTypes = {
  /**
   * True if the select should have focus upon rendering.
   */
  autoFocus: PropTypes.bool,
  /**
   * An object to style React Select with a similar structure to https://react-select.com/styles.
   * Note: keys for components are added on demand (we only add the keys we need to keep the code clean).
   */
  customStyles: PropTypes.shape({}),
  /**
   * Props passed to the DropdownIndicator component
   */
  dropdownIndicator: PropTypes.shape({
    icon: PropTypes.node,
  }).isRequired,
  /**
   * Whether the component should show the error state (border color and message)
   */
  errorMessage: PropTypes.string.isRequired,
  /**
   * Custom format for create label
   */
  formatCreateLabel: PropTypes.func,
  /**
   * Handle css style whether it has label or not
   */
  hasLabel: PropTypes.bool.isRequired,
  /**
   * Id used for Input element. Also used as `htmlFor` attribute on the input Label.
   */
  inputId: PropTypes.string.isRequired,
  /**
   * Whether the Select is creatable
   */
  isCreatable: PropTypes.bool,
  /**
   * Whether the component is disabled
   */
  isDisabled: PropTypes.bool.isRequired,
  /**
   * Whether the Select is inline
   */
  isInline: PropTypes.bool,
  /**
   * Whether the select menu is loading. Manually set in some cases.
   */
  isLoading: PropTypes.bool,
  /**
   * Whether the select menu is open. The default value (null) allows react-select
   * to open and close the menu when the user clicks the select button
   */
  isMenuOpen: PropTypes.bool,
  /**
   * Whether to support multiple options
   */
  isMulti: PropTypes.bool.isRequired,
  /**
   * Whether to show the multi-option panel when it has selected options
   */
  isOptionsPanelVisible: PropTypes.bool.isRequired,
  /**
   * The name of the component
   */
  name: PropTypes.string.isRequired,
  /**
   * Handle blur events on the control
   */
  onBlur: PropTypes.func.isRequired,
  /**
   * Handle change events on the control:
   *  onChange ({ selectName, option, action })
   * See onChange at https://react-select.com/props#select-props
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Handle creation events
   */
  onCreateOption: PropTypes.func,
  /**
   * Handle focus events on the control
   */
  onFocus: PropTypes.func.isRequired,
  /**
   * Handle user typing into input part of select, above dropdown
   */
  onInputChange: PropTypes.func,
  /**
   * Handle the removal of a single item (only works in multiple selection mode)
   *   onRemoveMultiOption ({ selectName, option })
   */
  onRemoveMultiOption: PropTypes.func.isRequired,
  /**
   * Array of options that populate the select menu
   */
  options: PropTypes.arrayOf(PropTypeOption).isRequired,
  /**
   * Component to be displayed in the input when nothing is selected
   */
  placeholder: PropTypes.node.isRequired,
  /**
   * [Async Search]
   * The ReactSelectWrapper component can load options from a remote source. As the user types,
   * the component calls this searchHandler function with two arguments:
   *  - inputValue: The current value of the input of the component
   *  - callback: This handler must execute the callback with an array of PropTypeOption
   *
   * Example:
   *
   *  const searchHandler = (inputValue, callback) => {
   *    const filterColors = value => {
   *      return colorOptionsData.filter(c => c.label.toLowerCase().includes(value.toLowerCase()));
   *    };
   *
   *    setTimeout(() => {
   *      callback(filterColors(inputValue));
   *    }, 1000);
   *  };
   */
  searchHandler: PropTypes.func,
  /**
   * [Async Search]
   * If the searchStart prop is 'typing', you can provide the default set of options to show
   * before the user starts searching.
   */
  searchInitialOptions: PropTypes.arrayOf(PropTypeOption),
  /**
   * [Async Search]
   * Determines "when" your remote request is initially fired.
   */
  searchStart: PropTypes.oneOf(Object.values(SEARCH_START)).isRequired,
  /**
   * The selected option(s) of the component
   */
  selectedOption: PropTypes.oneOfType([PropTypeOption, PropTypes.arrayOf(PropTypeOption)]),
  /**
   * Width of the component
   */
  width: PropTypes.string,
  /**
   * Position of the options
   */
  menuPosition: PropTypes.string,
};

ReactSelectWrapper.defaultProps = {
  autoFocus: false,
  customStyles: null,
  formatCreateLabel: inputValue => `Create "${inputValue}"`,
  isCreatable: false,
  isInline: false,
  isLoading: false,
  isMenuOpen: false,
  onCreateOption: null,
  onInputChange: null,
  searchHandler: null,
  searchInitialOptions: null,
  selectedOption: null,
};

export default ReactSelectWrapper;
