import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import Label from './Label';
import Loading from './Loading';
import SvgButton from './SvgButton';
import ValidationErrorMessage from './ValidationErrorMessage';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { currencyFormatter } from '../utils/priceFormatter';
import pixelsToRems from '../utils/converters';
import { Lock } from 'react-feather';
import { colors } from '../constants'
import { roundStrValueToFixedDecimals } from '../utils/converters'

export const INPUT_TYPES = { HIDDEN: 'hidden' }

export const getInputId = (id, name) => id || name

export const getLabelId = (id, name) => `${getInputId(id, name)}-label`

const getHelperTextId = (id, name) => `${getInputId(id, name)}-helper-text`

// Returns true if the `props` object has a truthy `hasError` or `error` field
// (some components set `error` but others set `hasError`)
const hasError = (props) => props.hasError || !!props.error

// base styles can be reused by other input tags, e.g. textarea, select
export const baseInputStyles = (props) => css`
  position: relatire;
  background: ${hasError(props) ? `${colors.errorBg}` : `${colors.grayDarkBg}`};
  border: none;
  color: ${hasError(props) ? `${colors.error}` : `${colors.text}`};
  font-size: 1rem;
  outline: none;
  margin: 1.5rem 0 0 0;
  height: 36px;
  padding: 0 10px;

  &[readOnly] {
    color: #59595a;
    cursor: not-allowed;
    border: 1px dashed rgba(28, 52, 84, 0.26);
    background-color: transparent;
  }

  &:disabled {
    cursor: not-allowed;
    border: 1px dashed rgba(28, 52, 84, 0.26);
    background-color: transparent;
  }

  &:focus {
    border-color: ${hasError(props) ? '#E21F14' : '#5e6b7b'};
    outline: none;

    ::placeholder {
      color: ${hasError(props) ? '#E21F14' : '#868587'};
    }
  }

  // no background color for autofilled fields
  @keyframes onAutoFillStart {
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: #192434;
    animation-name: onAutoFillStart;

    &::first-line {
      font-size: 1rem;
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  // no text color for autofilled fields
  &:-webkit-autofill {
    -webkit-text-fill-color: #192434;
  }
`

export const StyledInput = styled.input`
  ${baseInputStyles}
  color: ${colors.text};
  border-radius: 4px;
  flex-grow: 1;
  padding-right: ${({ adornmentPadding }) => (adornmentPadding ? `${adornmentPadding}rem` : `0.5rem`)};
  width: 100%;
`

export const labelStyles = (props) => css`
  color: ${colors.label};
  left: 0;
  line-height: 1.2;
  position: absolute;
  top: 0;
`

export const StyledLabel = styled(Label)`
  ${labelStyles}
  font-size: 0.875rem;
  pointer-events: initial;
  z-index: 2;
`

const HelperText = styled.div`
  color: ${colors.label};
  font-size: 0.625rem;
  line-height: 1.2;
  margin: ${`-0.5rem 0 0.5rem`};
  z-index: 2;
`

export const InputWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  margin-bottom: ${({ inputType, noMargin }) => (inputType === INPUT_TYPES.HIDDEN || noMargin ? '0' : '0.75rem')};
  position: relative;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};

  div {
    max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
  }

  &:focus-within label {
    color: ${(props) => (hasError(props) ? '#E21F14' : '#5e6b7b')};
  }

  // char limit indicator
  &:focus-within small {
    border-color: #f3b032;
  }
`

export const GroupWrapper = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
  top: 1.5rem;
  z-index: 2;

  ${(props) =>
    props.isReadOnly && props.hasLimit
      ? `
    small {
      top: initial;
    }
  `
      : ''};
`

export const IconWrapper = styled.div`
  cursor: ${(props) => (props.isReadOnly ? 'not-allowed' : 'pointer')};

  > :only-child {
    background: none;
    border: 0;
    min-height: 36px;
  }

  svg {
    color: #59595a;
    width: 1rem;
  }
`

export const Limit = styled.small`
  color: ${(props) => (props.error ? `${colors.error}` : `${colors.label}`)};
  top: 0.65rem;
  white-space: nowrap;
  padding: 0 0.625rem;
  position: relative;
`

const IconLink = styled.a`
  pointer-events: ${(props) => (props.href?.length ? 'auto' : 'none')};
`

const LimitCount = ({ currentCount, limit, name }) => {
  return (
    <Limit data-testid={`${name}-limit`} className="limit" error={currentCount > limit}>
      {currentCount} / {limit}
    </Limit>
  )
}

LimitCount.propTypes = {
  currentCount: PropTypes.number,
  limit: PropTypes.number,
  name: PropTypes.string,
}

const Adornments = ({ children, onResize, hasLimit, isReadOnly }) => {
  const adornments = useRef(null)

  useEffect(() => {
    if (!hasLimit) return

    const { current: node } = adornments

    if (!node) return

    const observer = new window.ResizeObserver((entries) => {
      const {
        contentRect: { width },
      } = entries[0]

      onResize(pixelsToRems(Math.round(width)))
    })

    observer.observe(node)

    // eslint-disable-next-line consistent-return
    return () => observer.unobserve(node)
  }, [hasLimit, onResize])

  return (
    <GroupWrapper ref={adornments} isReadOnly={isReadOnly} hasLimit={hasLimit}>
      {children}
    </GroupWrapper>
  )
}

Adornments.propTypes = {
  children: PropTypes.node,
  hasLimit: PropTypes.bool,
  onResize: PropTypes.func,
}

Adornments.defaultProps = {
  children: null,
  hasLimit: false,
  onResize: () => {},
}

const Input = forwardRef(
  (
    {
      autoComplete,
      autoFocus,
      buttonAriaLabel,
      className,
      disabled,
      errorMessage,
      helperText,
      hardLimit,
      maxDecimals,
      href,
      icon,
      iconDisabled,
      id,
      isLoading,
      isReadOnly,
      label,
      isRequired,
      limitCount,
      maxWidth,
      min,
      max,
      name,
      noMargin,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onIconClick,
      onKeyDown,
      onValueChange,
      placeholder,
      pattern,
      showInline,
      softLimit,
      type,
      value,
    },
    ref
  ) => {
    const limit = softLimit || hardLimit
    const inputId = getInputId(id, name)
    const [focused, setFocused] = useState(false)
    const [isLabelVisible, setIsLabelVisible] = useState(false)
    const [updatedValue, setUpdatedValue] = useState(value)
    const [controlIcon, setControlIcon] = useState(icon)
    const [adornmentPadding, setAdornmentPadding] = useState(null)
    const isCurrency = type === 'currency'

    // gets the current count for the left side of the Limit component
    const getLimitCount = (count, inputValue) => {
      return count === 0 || count ? count : inputValue.length
    }

    const handleOnChange = (event) => {
      let isCorrect = true
      if (type === 'number' && min !== null) {
        let inputValue = event.target.value.trim() || ''
        const absValue = parseFloat(inputValue || '0')
        if (absValue < min) {
          inputValue = min + ''
          event.target.value = inputValue
        }
        if (onChange) onChange(event)
        setUpdatedValue(inputValue)
        isCorrect = false
      }
      if (type === 'number' && max !== null) {
        let inputValue = event.target.value.trim() || ''
        const absValue = parseFloat(inputValue || '0')
        if (absValue > max) {
          inputValue = max + ''
          event.target.value = inputValue
        }
        if (onChange) onChange(event)
        setUpdatedValue(inputValue)
        isCorrect = false
      }
      if (isCorrect) {
        if (onChange) onChange(event)
        setUpdatedValue(event.target.value)
      }
    }

    const handleOnBlur = (event) => {
      setFocused(false)

      if (onBlur) onBlur(event)
    }

    const handleOnFocus = (event) => {
      setFocused(true)
      if (onFocus) onFocus(event)
    }

    const handleOnKeyPress = (event) => {
      setUpdatedValue(event.target.value)
    }

    const onAnimationStart = (event) => {
      if (event.animationName === 'onAutoFillStart') {
        setFocused(true)
      }
    }

    const handleOnCurrencyChange = (event) => {
      const rawValue = event.target.value
      const price = parseFloat(rawValue.replace(/[^0-9/.]+/g, '')).toFixed(2)
      const zerosRgx = /\.0+$/
      const isTrailingZeros = rawValue.includes('.') && zerosRgx.test(price)
      const currencyPrice = currencyFormatter({ price })
      const formattedPrice = !isTrailingZeros
        ? currencyPrice
        : `${currencyPrice}.${rawValue
            .split('.')[1]
            .replace(/[^0-9]+/g, '')
            .slice(0, 2)}`

      onValueChange(formattedPrice)
      setUpdatedValue(formattedPrice)
    }

    useEffect(() => {
      const isValueEmpty =
        value === undefined ||
        value === null ||
        (typeof value === 'number' && Number.isNaN(value)) ||
        (typeof value === 'string' && value.trim().length === 0)
      const isUpdatedValueEmpty =
        updatedValue === undefined ||
        updatedValue == null ||
        (typeof updatedValue === 'number' && Number.isNaN(updatedValue)) ||
        (typeof updatedValue === 'string' && updatedValue.trim().length === 0)
      setIsLabelVisible(
        (label && !showInline && (!isValueEmpty || focused || !isUpdatedValueEmpty)) ||
          (label.length > 20 && type === 'number')
      )
      if (isReadOnly && !icon) setControlIcon(<Lock />)
      else setControlIcon(icon)
    }, [updatedValue, value, focused, label, showInline, isReadOnly, icon, type])

    useEffect(() => {
      setUpdatedValue(value)
    }, [value])

    const fieldProps = {
      'aria-describedby': helperText ? getHelperTextId(id, name) : null,
      'aria-label': label,
      autoFocus,
      'data-testid': `${name}-input`,
      disabled,
      error: errorMessage,
      id: inputId,
      min,
      max,
      name,
      onBlur: handleOnBlur,
      onFocus: handleOnFocus,
      pattern,
      placeholder: showInline ? label : placeholder,
      readOnly: isReadOnly,
      ref,
      tabIndex: isReadOnly ? -1 : 0,
      title: showInline ? label : null,
      value: updatedValue === null ? '' : updatedValue,
    }

    return (
      <div>
        <InputWrapper
          className={className}
          error={errorMessage}
          inputType={type}
          maxWidth={maxWidth}
          noMargin={noMargin}>
          {!showInline && (
            <StyledLabel
              id={getLabelId(id, name)}
              error={errorMessage}
              htmlFor={inputId}
              className={!isLabelVisible ? 'empty' : ''}>
              {`${label} ${isRequired ? '*' : ''}`}
            </StyledLabel>
          )}
          {isCurrency && <StyledInput onChange={handleOnCurrencyChange} {...fieldProps} />}
          {!isCurrency && (
            <StyledInput
              adornmentPadding={adornmentPadding}
              autoComplete={autoComplete}
              maxLength={hardLimit}
              onChange={handleOnChange}
              onKeyDown={onKeyDown}
              onKeyPress={handleOnKeyPress}
              onClick={onClick}
              onAnimationStart={onAnimationStart}
              type={type}
              {...fieldProps}
              onBlur={(event) => {
                if (maxDecimals) {
                  setUpdatedValue(roundStrValueToFixedDecimals(event.target.value, maxDecimals))
                }
              }}
            />
          )}
          {(controlIcon || limit) && (
            <Adornments hasLimit={!!limit} onResize={setAdornmentPadding} isReadOnly={isReadOnly}>
              {limit && typeof value === 'string' && (
                <LimitCount currentCount={getLimitCount(limitCount, value)} limit={limit} name={name} />
              )}

              {controlIcon && (
                <IconWrapper isDisabled={disabled} isReadOnly={isReadOnly}>
                  <Icon
                    buttonAriaLabel={isReadOnly ? 'non-editable-field' : buttonAriaLabel}
                    disabled={iconDisabled}
                    href={href}
                    icon={controlIcon}
                    iconTabIndex={isReadOnly && !icon ? -1 : 0} // Lock Icon is inaccessible
                    isLoading={isLoading}
                    name={name}
                    onIconClick={onIconClick}
                    type={type}
                    tooltip={isReadOnly ? 'non-editable-field' : null}
                  />
                </IconWrapper>
              )}
            </Adornments>
          )}
        </InputWrapper>
        {!!helperText && (
          <HelperText id={getHelperTextId(id, name)} error={!!errorMessage}>
            {helperText}
          </HelperText>
        )}
        {errorMessage && <ValidationErrorMessage id={inputId} error={errorMessage} color={colors.error} />}
      </div>
    )
  }
)

Input.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  buttonAriaLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hardLimit: PropTypes.number,
  maxDecimals: PropTypes.number,
  helperText: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.element,
  iconDisabled: PropTypes.bool,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.node.isRequired,
  limitCount: PropTypes.number,
  max: PropTypes.number,
  maxWidth: PropTypes.string,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onIconClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onValueChange: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  showInline: PropTypes.bool,
  softLimit: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Input.defaultProps = {
  autoComplete: 'on',
  autoFocus: false,
  buttonAriaLabel: '',
  className: '',
  disabled: false,
  errorMessage: '',
  hardLimit: null,
  maxDecimals: null,
  helperText: '',
  icon: null,
  iconDisabled: false,
  id: '',
  isLoading: false,
  isReadOnly: false,
  isRequired: false,
  max: null,
  maxWidth: '100%',
  min: null,
  noMargin: false,
  onBlur: null,
  onChange: null,
  onClick: null,
  onIconClick: Function.prototype,
  onKeyDown: null,
  onValueChange: null,
  placeholder: '',
  pattern: null,
  showInline: false,
  softLimit: null,
  type: 'text',
  value: '',
}

export const NumberedInput = styled(Input)`
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

/**
 * Icon component (Input child component)
 */
export const Icon = ({
  buttonAriaLabel,
  disabled,
  href,
  icon,
  iconTabIndex,
  isLoading,
  name,
  onIconClick,
  tooltip,
  type: inputType,
}) => {
  if (isLoading) return <Loading margin="0" />;

  if (inputType === 'button') {
    return disabled ? icon : React.cloneElement(icon, { onClick: onIconClick });
  }

  if (href !== undefined) {
    return (
      <IconLink
        aria-label={buttonAriaLabel}
        data-testid={`${name}-link`}
        key={name}
        href={href}
        id={`${name}-link`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {icon}
      </IconLink>
    );
  }

  return (
    <SvgButton
      aria-label={buttonAriaLabel}
      data-testid={`${name}-button`}
      data-tip={tooltip || null}
      id={`${name}-button`}
      isDisabled={disabled}
      key={name}
      onClick={onIconClick}
      tabIndex={iconTabIndex}
    >
      {icon}
    </SvgButton>
  );
};

// Note: All the Icon props are required because the parent Input component passes them
// This includes the 'name' prop, but ESLint cannot tell it is required in Input.propTypes
Icon.propTypes = {
  buttonAriaLabel: Input.propTypes.buttonAriaLabel.isRequired,
  disabled: Input.propTypes.disabled,
  href: PropTypes.string,
  icon: Input.propTypes.icon.isRequired,
  iconTabIndex: PropTypes.number,
  isLoading: Input.propTypes.isLoading.isRequired,
  name: Input.propTypes.name,
  onIconClick: Input.propTypes.onIconClick,
  tooltip: PropTypes.string,
  type: Input.propTypes.type,
};

Icon.defaultProps = {
  disabled: false,
  iconTabIndex: 0,
  name: null,
  onIconClick: null,
  tooltip: '',
  type: '',
};

export default Input;
