import { useCallback, useEffect, useState } from "react";
import qs from "qs";
import { useSnackbar } from "../contexts/SnackbarContext";
import { PAGE_SIZE, PAGE_SIZE_MAX, SnackbarStatus } from '../constants';
import { asCamelCase } from "../utils";
import { getAxiosMethod, request } from "../utils/httpRequests";

const useGetRequest = (
  url,
  onSuccess = null,
  skip = false,
  noFormatResult = false,
  el = 'data',
  suppressWarning = false
) => {
  const [data, setData] = useState({ data: [], meta: {} })
  const [loading, setLoading] = useState(!skip)
  const { showSnackbar } = useSnackbar()

  const refreshData = useCallback(
    (params, onRefreshSuccess = null, ignoreLoading = false) => {
      if (!ignoreLoading) {
        setLoading(true)
      }
      const isPaging = params && params.page > 0
      if (params && params.searchTerm) {
        params['filter[search]'] = params.searchTerm
      }
      if (params && params.sort && params.sortAsc) {
        params.sort = `-${params.sort}`
      }
      request(
        getAxiosMethod('get'),
        `${url}${qs.stringify({ limit: isPaging ? PAGE_SIZE : PAGE_SIZE_MAX, ...params })}`
      )
        .then((res) => {
          const formatted = noFormatResult ? res.data[el] : asCamelCase(isPaging ? res.data : res.data[el])
          typeof onSuccess === 'function' && onSuccess(formatted)
          typeof onRefreshSuccess === 'function' && onRefreshSuccess(formatted)
          setData(formatted)
        })
        .catch(() => {
          if (!suppressWarning) {
            showSnackbar({
              message: 'Помилка отримання даних',
              status: SnackbarStatus.error,
            })
          }
        })
        .finally(() => setLoading(false))
    },
    [url, onSuccess, noFormatResult]
  )

  useEffect(() => {
    if (!skip) {
      refreshData({})
    }
  }, [onSuccess, refreshData, skip])

  return [loading, data, refreshData]
}

export default useGetRequest;
