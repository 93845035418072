import { useState, useEffect, useContext } from 'react'
import styled from '@emotion/styled/macro'
import { authContext } from '../../contexts/AuthContext'
import { httpRequests, getApiUrl } from '../../utils'

const UserWrapper = styled.div`
  position: absolute;
  bottom: 3px;
  font-size: 10px;
  color: #ffc20b;
  left: 24px;
  border: 1px solid #d7d7d1;
  border-radius: 12px;
  width: 12px;
  height: 12px;
  text-align: center;
  line-height: 1.2;
  font-weight: bold;
`

const requestProcessesInfo = async (url, setData) => {
  try {
    const response = await httpRequests.get(url)
    if (response.status === 200 && response.data?.data) {
      setData(response.data?.data)
    } else {
      throw new Error('Помилка запиту даних')
    }
  } catch (e) {
    console.error(e.message)
  }
}

function MenuProcessesInfo({ filterByUser = false, filterByUnit = false, intervalSeconds = 60 }) {
  const { auth } = useContext(authContext)
  const userUnitId = auth?.data?.unitId
  const userEntityId = auth?.data?.entityId
  let filter = ''
  if (filterByUser || filterByUnit) {
    filter = `filter[userOrUnit]=${filterByUser ? userEntityId : ''}${
      filterByUnit && userUnitId ? ';' + userUnitId : ''
    }`
  }
  const url = getApiUrl('processes_input') + filter
  const [data, setData] = useState([])

  useEffect(() => {
    let handlerInterval = null
    ;(async () => {
      await requestProcessesInfo(url, setData)
    })()
    if (intervalSeconds) {
      handlerInterval = setInterval(async () => {
        await requestProcessesInfo(url, setData)
      }, intervalSeconds * 1000)
    }
    return () => {
      clearInterval(handlerInterval)
    }
  }, [intervalSeconds, url])

  return <>{data.length > 0 ? <UserWrapper>{data?.length > 0 ? data.length : ''}</UserWrapper> : ''}</>
}

export default MenuProcessesInfo
