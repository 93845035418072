import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import NoItemsMessage from './NoItemsMessage';

const Wrapper = styled.div`
  column-gap: 1.5rem;
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 2fr);
  margin-bottom: 1rem;
  row-gap: 0.5rem;
`;

const Grid = ({ children, columns = 2, noContentMessage, className }) => {
  return children ? (
    <Wrapper className={`${className ? className : ''}`} columns={columns}>
      {children}
    </Wrapper>
  ) : (
    <NoItemsMessage message={noContentMessage || 'Нема даних'} />
  )
}

Grid.propTypes = {
  noContentMessage: PropTypes.string,
};

Grid.defaultProps = {
  noContentMessage: '',
};

export default Grid;
