import slugify from 'slugify'

const ROOT_FONT_SIZE = 16
/**
 * Converts a number representing a pixel length to it's equivalent rem number unit with
 * the assumption that the font-size property on the document's root element is that of 16px
 * (i.e 1 rem = 16px )
 * @param {number} pixels - The number of pixels.
 * @returns {number}
 */
const pixelsToRems = (pixels) => {
  if (Number.isNaN(Number(pixels))) return 0

  return pixels / ROOT_FONT_SIZE
}

/**
 * Converts a string representing a rem value to it's equivalent number of pixels with
 * the assumption that the font-size property on the document's root element is that of 16px
 * @param {string} rems - rem value in the format of '1rem'
 * @param {boolean} withUOM - allows one to decide to return the number of pixels as a Number or with the 'px' unit of measure appended to it
 * @returns {number|string} pixels - pixel value as a number (e.g. 8) or a string (e.g '8px')
 */
export const remsToPixels = (rems, withUOM = true) => {
  const format = (value) => (withUOM ? `${value}px` : value)

  if (!rems || !rems.endsWith('rem')) return format(0)

  const unit = Number(rems.substring(0, rems.indexOf('rem')))

  if (Number.isNaN(unit)) return format(0)

  return format(unit * ROOT_FONT_SIZE)
}

export const camelCaseToSpaceSeparatedString = (str) => {
  return str.replace(/([A-Z])/g, ' $1').trim()
}

export const cleanAndTransliteUserNames = (firstName, middleName, lastName) => {
  const str = (lastName || '.') + (firstName || '.').charAt(0) + (middleName || '.').charAt(0)
  return slugify(str, { replacement: '', remove: /[*+/_~.()'"!:@-]/g }).toUpperCase()
}

export const roundCoordinate = (v, maxDecimals = 5) => {
  const value = parseFloat(v)
  return value ? (Math.round(value * 1000000) / 1000000).toFixed(maxDecimals) : ''
}

// function getDecimalSeparator() {
//   const numberWithDecimal = 1.1
//   const formattedNumber = new Intl.NumberFormat().format(numberWithDecimal)
//   return formattedNumber.replace(/\d/g, '').trim()
// }

// const decimalSeparator = getDecimalSeparator()

export const roundStrValueToFixedDecimals = (strValue, maxDecimals = 5) => {
  let result = 0
  let cleanedStr = ''
  if (!(typeof strValue !== 'string' || strValue === null)) {
    cleanedStr = strValue.replace(/[^0-9.-]/g, '')
    // if (decimalSeparator === '.') {
    //   cleanedStr = strValue.replace(/[^0-9.-]/g, '')
    // } else {
    //   cleanedStr = strValue.replace(/[^0-9,-]/g, '')
    // }
    const numValue = parseFloat(cleanedStr)
    if (!isNaN(numValue)) {
      result = numValue.toFixed(maxDecimals)
    }
  }
  return result
}

export const removePropFromObject = (obj, propName) => {
  const { [propName]: _, ...rest } = obj
  return { ...rest }
}

export default pixelsToRems
