import { useContext } from 'react'
import PropTypes from 'prop-types'
import { authContext } from '../../contexts/AuthContext'
import { interfaceContext } from '../../contexts/InterfaceContext'
import { colors } from '../../constants'
import { Link } from '..'

const MenuItem = ({ icon, text, to, children, permission = '', isForcedAllow = false, ...props }) => {
  const { checkPermission } = useContext(authContext)
  const { interfaceSettings } = useContext(interfaceContext)

  return (
    (isForcedAllow || checkPermission(permission)) && (
      <Link to={to} className="menu_item_link" activeClassName="active" isEnd={false} {...props}>
        {icon && <img src={icon} width={28} height={28} alt="logo" />}
        {!interfaceSettings.simplified && text}
        {children}
        <style jsx="true">{`
          .menu_item_link {
            position: relative;
            align-items: center;
            display: flex;
            width: 11rem;
            font-size: 1rem;
            font-weight: 500;
            text-decoration: none;
            color: rgba(226, 238, 254, 0.8);
            height: 1.5rem;
            padding: 0.7vh 1.5rem 0.7vh 0.4rem;
          }
          .menu_item_link:hover {
            background-color: ${colors.green};
          }
          .menu_item_link.active {
            color: ${colors.blue};
          }
        `}</style>
      </Link>
    )
  )
}

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isForcedAllow: PropTypes.bool,
  children: PropTypes.node,
}

export default MenuItem
