import styled from '@emotion/styled/macro';
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "./index";
import useSaveRequest from "../hooks/useSaveRequest";
import { getApiUrl } from "../utils";

const Actions = styled.div`
  padding: 2.5rem 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function FormActions({
  children,
  backLink,
  data,
  style,
  routeSlug,
  hasBack = true,
  hasSubmit = true,
  isVersion = false,
  submitTitle = 'Зберегти зміни',
  submitColor = Button.COLOR.BG_BLUE,
}) {
  const navigate = useNavigate()
  const [isDialogRestoreOpen, setIsDialogRestoreOpen] = useState(false)
  const [saving, saveRequest] = useSaveRequest(
    'patch',
    getApiUrl(routeSlug, { entityId: data.id }),
    useCallback(() => navigate(backLink), [backLink, navigate]),
    null,
    'Відновлено версію з архіву'
  )

  return (
    <Actions style={style}>
      <Modal
        isLoading={saving}
        isOpen={saving || isDialogRestoreOpen}
        text="Ви впевнені, що хочете відновити саме цю версію та зробити її актуальною? УВАГА: поточна активна версія автоматично архівується."
        title="Відновлення версії"
        buttons={[
          {
            color: Button.COLOR.BLUE,
            label: 'Відміна',
            onClick: () => setIsDialogRestoreOpen(false),
          },
          {
            label: 'Підтвердити',
            onClick: () => saveRequest({ isArchieved: false }),
          },
        ]}
      />
      {hasBack && (
        <Button color={Button.COLOR.BLUE} icon="arrowLeft" link={backLink}>
          Назад
        </Button>
      )}
      {hasSubmit && !isVersion && (
        <Button color={submitColor} icon="clipboardCheck" type="submit">
          {submitTitle}
        </Button>
      )}
      {isVersion && (
        <Button color={Button.COLOR.BG_BLUE} icon="clipboardCheck" onClick={() => setIsDialogRestoreOpen(true)}>
          Відновити версію
        </Button>
      )}
      {children}
    </Actions>
  )
}
