import PropTypes from 'prop-types';
import cn from 'classnames';
import isUndefined from 'lodash/isUndefined';
import { Link } from '../../components';
import styles from './Tabs.module.css';

const Tabs = ({ items, className, rightContent, onChange, active }) => (
  <div className={cn(styles.root, className)}>
    {!!rightContent && <div className={styles.right}>{rightContent}</div>}
    <ul className={styles.tabs}>
      {items.map((item, key) => {
        if (!item) {
          return null;
        }

        const onClick = onChange && !item.disabled ? () => onChange(item) : undefined;

        const activeClassName = cn(
          styles.active
        );
        let Component = Link;
        let props = {
          to: item.to,
          activeClassName,
          className: cn(styles.link, item.key && item.key === active ? activeClassName : ''),
          onClick,
        };

        if (!item.to) {
          Component = 'a';
          props = {
            onClick,
            className: cn(styles.link, item.value === active ? activeClassName : ''),
          };
        }

        return (
          <li
            className={styles.tab}
            key={key}
          >
            <Component {...props} data-tip={item.tip}>
              {item.label}
              {!isUndefined(item.count) && <> ({item.count})</>}
            </Component>
          </li>
        );
      })}
    </ul>
    <div className={styles.border} />
  </div>
);

Tabs.propTypes = {
  rightContent: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      label: PropTypes.node.isRequired,
      value: PropTypes.any,
      count: PropTypes.number,
      tip: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  active: PropTypes.any,
};

export default Tabs;
