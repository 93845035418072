import {createContext, useCallback, useContext, useState} from 'react'

const DEFAULT = {
  state: {},
  setState: () => null,
}

const StoredListsContext = createContext(DEFAULT)

export function useStoredListsContext(key, initialListState) {
  const {state, setState} = useContext(StoredListsContext)
  const setListState = useCallback((listState) => {
    setState(s => ({...s, [key]: listState}))
  }, [key, setState])
  return [state[key] || initialListState, setListState]
}

export default function StoredListsProvider({children}) {
  const [state, setState] = useState({})
  return (
    <StoredListsContext.Provider value={{state, setState}}>
      {children}
    </StoredListsContext.Provider>
  )
}
