import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "@emotion/styled/macro";
import { css } from '@emotion/react';
import { Controller } from "react-hook-form";
import { FORM_FIELD_MAX_WIDTH } from "../constants";
import { StyledLabel } from "./Input";
import ValidationErrorMessage from "./ValidationErrorMessage";
import { colors } from '../constants'

export const datePickerInputContainerStyles = css`
  box-sizing: border-box;
  width: 100%;
  border: none;
  height: 36px;
  outline: none;
  margin: 1.5rem 0 0 0;
  padding: 0 10px;
  font-size: 1rem;
  color: colors.text;
  background: ${colors.grayDarkBg};
  border: none;
  border-radius: 4px;
`;

const DatePickerWrapper = styled.div`
  background-color: transparent;
  position: relative;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
  margin-bottom: 0.75rem;

  .react-datepicker__input-container {
    margin-bottom: 0.75rem;
    overflow: hidden;
    input {
      ${datePickerInputContainerStyles}
      background: ${({ hasError }) => (hasError ? colors.errorBg : colors.grayDarkBg)};
    }
  }

  .react-datepicker-popper {
    z-index: 9999;
  }
`;

export default function ControllerDatePicker({
  control,
  error,
  label,
  name,
  setValue,
  maxWidth = FORM_FIELD_MAX_WIDTH,
  dateFormat = 'dd.MM.yyyy',
  showTimeSelect = false,
  timeIntervals = 5,
  onCalendarClose,
  emptyInitial = false,
  minDate,
  maxDate,
}) {
  const initialValue = emptyInitial ? null : new Date()

  return (
    <DatePickerWrapper maxWidth={maxWidth} hasError={!!error}>
      <StyledLabel hasError={!!error}>{label}</StyledLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { value } }) => (
          <DatePicker
            dateFormat={dateFormat}
            showTimeSelect={showTimeSelect}
            timeIntervals={timeIntervals}
            selected={!!value ? new Date(value) : initialValue}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(date) => {
              setValue(name, date, {
                shouldDirty: true,
                shouldValidate: true,
              })
            }}
            onCalendarClose={onCalendarClose}
          />
        )}
      />
      {!!error && <ValidationErrorMessage error={error} color={colors.error} />}
    </DatePickerWrapper>
  )
}
