import React, { useContext } from "react";
import styled from "@emotion/styled/macro";
import { authContext } from '../../contexts/AuthContext';
import { interfaceContext } from '../../contexts/InterfaceContext'
import { colors } from '../../constants'
import iconUser from './icon-user.png'
import iconLogout from './icon-logout.png'

const UserWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

  & > div {
    align-items: center;
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    color: rgba(226, 238, 254, 0.8);
    height: 1.5rem;
    padding: 0.7vh 0.4rem;
  }
`

const UserName = styled.div``

const LogoutLink = styled.div`
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: ${colors.green};
  }
`

const User = () => {
  const { auth, setAuthData } = useContext(authContext)
  const { interfaceSettings } = useContext(interfaceContext)

  const onLogout = async (event) => {
    if (event) {
      event.preventDefault()
    }
    setAuthData(null)
  }

  return (
    <UserWrapper>
      {auth.data && !interfaceSettings.simplified && (
        <UserName>
          <img src={iconUser} alt="logo" />
          {`${auth.data?.fullName}`}
        </UserName>
      )}

      {auth.data && (
        <LogoutLink onClick={onLogout}>
          <img src={iconLogout} alt="logo" />
          {!interfaceSettings.simplified && 'Вийти'}
        </LogoutLink>
      )}
    </UserWrapper>
  )
}

export default User;
