import PropTypes from 'prop-types';
import React, { useState, forwardRef } from 'react';
import { InputWrapper, StyledLabel } from '../Input';
import Label from '../Label';
import ValidationErrorMessage from '../ValidationErrorMessage';
import styled from '@emotion/styled/macro';
import { DEFAULT_FONT_SIZE } from '../../constants';
import ReactSelectWrapper, {
  isOptionsPanelRendered,
  PropTypeOption,
  SEARCH_START,
} from './SelectUtils/ReactSelectWrapper';
import isUndefined from 'lodash/isUndefined'

export const getSelectedOption = (value, optionsList) => {
  let res = optionsList?.find((option) => option.value === value)
  if (isUndefined(res)) {
    const group = optionsList?.find((item) => item?.options?.find((option) => option.value === value))
    res = group?.optionsList?.find((option) => option.value === value)
  }
  return res
}

const InlineLabel = styled(Label)`
  color: #59595A;
  font-size: ${DEFAULT_FONT_SIZE};
  line-height: 1.2;
  margin: 1.5rem 0 0 0;
  padding: 0 10px;
  pointer-events: initial;
  white-space: nowrap;
  z-index: 2;
`;

const SelectWrapper = styled(InputWrapper)`
  margin-left: ${props => (props.isInline ? '1.25rem' : '0')};
  background-color: transparent;

  ${({ isInline }) =>
    isInline &&
    `
    &:focus-within label {
      color: #59595A;
    }
  `}

  ${({ selectWrapperStyles }) => selectWrapperStyles}
`;

/**
 * Checks if the passed option is empty.
 * It's considered empty if it is falsy, or if its value is invalid (including the empty string).
 *
 * @param {PropTypeOption} option
 */
const hasEmptyOption = option =>
  !option || option.value === null || option.value === undefined || option.value === '';

/**
 * Returns an id string given a name string.
 * @param {string} name
 * @return {string}
 */
export const getSelectId = name => `select-${name}`;

const Select = forwardRef(
  (
    {
      autoFocus,
      customStyles,
      dropdownIndicator,
      errorMessage,
      formatCreateLabel,
      isDisabled,
      isCreatable,
      isInline,
      isLoading,
      isMenuOpen,
      isMulti,
      isOptionsPanelVisible,
      isRequired,
      label,
      labelShrunkTop,
      maxWidth,
      name,
      onBlur,
      onChange,
      onCreateOption,
      onFocus,
      onInputChange,
      onRemoveMultiOption,
      options,
      placeholder,
      searchHandler,
      searchInitialOptions,
      searchStart,
      selectWrapperStyles,
      selectedOption,
      width,
      menuPosition,
    },
    ref,
  ) => {
    let isShrunkLabel;
    const [hasFocus, setHasFocus] = useState(false);
    const inputId = getSelectId(name);
    const containerMinHeight = isOptionsPanelRendered({
      isMulti,
      isOptionsPanelVisible,
      selectedOption,
    })
      ? '4.561rem'
      : '';

    // isMulti mode doesn't show the label as a "placeholder".
    // Also, compute the error styles properly.
    if (isMulti) {
      isShrunkLabel = true;
    } else {
      const isEmpty = hasEmptyOption(selectedOption);
      isShrunkLabel = hasFocus || !isEmpty;
    }

    const handleBlur = event => {
      setHasFocus(false);
      onBlur(event);
    };

    const handleFocus = event => {
      setHasFocus(true);
      onFocus(event);
    };

    return (
      <div>
        <SelectWrapper
          hasError={!!errorMessage}
          maxWidth={maxWidth}
          minHeight={containerMinHeight}
          isInline={isInline}
          selectWrapperStyles={selectWrapperStyles}
        >
          {isInline && label && <InlineLabel htmlFor={inputId}>{`${label} ${isRequired ? '*' : ''}`}</InlineLabel>}
          {!isInline && (
            <StyledLabel
              hasError={!!errorMessage}
              htmlFor={inputId}
              isShrunk={isShrunkLabel}
              shrunkTop={labelShrunkTop}
            >
              {`${label} ${isRequired ? '*' : ''}`}
            </StyledLabel>
          )}
          <ReactSelectWrapper
            autoFocus={autoFocus}
            dropdownIndicator={dropdownIndicator}
            errorMessage={errorMessage}
            hasLabel={!!label}
            inputId={inputId}
            isDisabled={isDisabled}
            isInline={isInline}
            isLoading={isLoading}
            isMenuOpen={isMenuOpen}
            isMulti={isMulti}
            isOptionsPanelVisible={isOptionsPanelVisible}
            isRequired={isRequired}
            customStyles={customStyles}
            name={name}
            onBlur={handleBlur}
            onChange={onChange}
            onFocus={handleFocus}
            onInputChange={onInputChange}
            onRemoveMultiOption={onRemoveMultiOption}
            options={options}
            placeholder={placeholder}
            searchHandler={searchHandler}
            searchInitialOptions={searchInitialOptions}
            searchStart={searchStart}
            selectedOption={selectedOption}
            ref={ref}
            formatCreateLabel={formatCreateLabel}
            isCreatable={isCreatable}
            onCreateOption={onCreateOption}
            width={width}
            menuPosition={menuPosition}
          />
        </SelectWrapper>
        {!!errorMessage && <ValidationErrorMessage error={errorMessage} id={inputId} />}
      </div>
    );
  },
);

Select.propTypes = {
  /**
   * True if the select should have focus upon rendering.
   */
  autoFocus: PropTypes.bool,
  /**
   * An object to style React Select with a similar structure to https://react-select.com/styles.
   * Note: keys for components are added on demand (we only add the keys we need to keep the code clean).
   */
  customStyles: PropTypes.shape({}),
  /**
   * Props passed to the DropdownIndicator component
   * See: src/components/shared/Select/ReactSelectComponents.jsx
   */
  dropdownIndicator: PropTypes.shape({
    icon: PropTypes.node,
  }),
  /**
   * Any error message related to this component
   */
  errorMessage: PropTypes.string,
  /**
   * Custom format for create label
   */
  formatCreateLabel: PropTypes.func,
  /**
   * Whether the Select is creatable
   */
  isCreatable: PropTypes.bool,
  /**
   * Whether the Select is disabled
   */
  isDisabled: PropTypes.bool,
  /**
   * Whether the Select is inline
   */
  isInline: PropTypes.bool,
  /**
   * Whether the Select menu is loading options.
   * We need to manually do this for fetches that do not return Promises
   */
  isLoading: PropTypes.bool,
  /**
   * Whether the select menu is open. The default value (null) allows react-select
   * to open and close the menu when the user clicks the select button
   */
  isMenuOpen: PropTypes.bool,
  /**
   * Whether to support multiple options
   */
  isMulti: PropTypes.bool,
  /**
   * Whether to show the multi-option panel when it has selected options
   */
  isOptionsPanelVisible: PropTypes.bool,
  /**
   * Whether the user has to select a value
   */
  isRequired: PropTypes.bool,
  /**
   * The label of the component
   */
  label: PropTypes.node,
  /**
   * The top of the label when it is shrunk
   */
  labelShrunkTop: PropTypes.string,
  /**
   * Max width of the component
   */
  maxWidth: PropTypes.string,
  /**
   * The name of the component
   */
  name: PropTypes.string,
  /**
   * Handle blur events on the control
   */
  onBlur: PropTypes.func,
  /**
   * Handle change events on the control:
   *  onChange ({ selectName, option, action })
   */
  onChange: PropTypes.func,
  /**
   * Handle creation events
   */
  onCreateOption: PropTypes.func,
  /**
   * Handle focus events on the control
   */
  onFocus: PropTypes.func,
  /**
   * Handle user typing into input part of select, above dropdown
   */
  onInputChange: PropTypes.func,
  /**
   * Handle the removal of a single item (only works in multiple selection mode)
   */
  onRemoveMultiOption: PropTypes.func,
  /**
   * Array of options that populate the select menu
   */
  options: PropTypes.arrayOf(PropTypeOption),
  /**
   * Component to be displayed in the input when nothing is selected
   */
  placeholder: PropTypes.node,
  /**
   * [Async Search]
   * The Select component can load options from a remote source. As the user types,
   * the component calls this searchHandler function with two arguments:
   *  - inputValue: The current value of the input of the component
   *  - callback: This handler must execute the callback with an array of PropTypeOption
   *
   * Example:
   *
   *  const searchHandler = (inputValue, callback) => {
   *    const filterColors = value => {
   *      return colorOptionsData.filter(c => c.label.toLowerCase().includes(value.toLowerCase()));
   *    };
   *
   *    setTimeout(() => {
   *      callback(filterColors(inputValue));
   *    }, 1000);
   *  };
   */
  searchHandler: PropTypes.func,
  /**
   * [Async Search]
   * If the searchStart prop is 'typing', you can provide the default set of options to show
   * before the user starts searching.
   */
  searchInitialOptions: PropTypes.arrayOf(PropTypeOption),
  /**
   * [Async Search]
   * Determines "when" your remote request is initially fired.
   */
  searchStart: PropTypes.oneOf(Object.values(SEARCH_START)),
  /**
   * Prop to pass `css` styles to the SelectWrapper component.
   * https://emotion.sh/docs/css-prop#string-styles
   * https://emotion.sh/docs/with-props
   */
  selectWrapperStyles: PropTypes.shape({}),
  /**
   * The selected option(s) of the component
   */
  selectedOption: PropTypes.oneOfType([PropTypeOption, PropTypes.arrayOf(PropTypeOption)]),
  /**
   * Width of the component
   */
  width: PropTypes.string,
  /**
   * Position of the options
   */
  menuPosition: PropTypes.string,
};

Select.defaultProps = {
  autoFocus: false,
  customStyles: null,
  dropdownIndicator: {},
  errorMessage: '',
  formatCreateLabel: inputValue => `Create "${inputValue}"`,
  isCreatable: false,
  isDisabled: false,
  isInline: false,
  isLoading: false,
  isMenuOpen: null,
  isMulti: false,
  isOptionsPanelVisible: true,
  isRequired: false,
  label: '',
  labelShrunkTop: '',
  name: '',
  onBlur: () => {},
  onChange: () => {},
  onCreateOption: () => {},
  onFocus: () => {},
  onInputChange: () => {},
  onRemoveMultiOption: () => {},
  options: [],
  placeholder: '',
  searchHandler: null,
  searchInitialOptions: null,
  searchStart: SEARCH_START.firstRender,
  selectWrapperStyles: null,
  selectedOption: null,
  menuPosition: null,
};

export { SEARCH_START };

export default Select;
