// export const DEFAULT_API_HOST = window.location.hostname === 'localhost' ? 'http://localhost:8081' : ''
export const DEFAULT_API_HOST = process.env.REACT_APP_MODE === 'dev' ? 'http://localhost:8081' : window.location.origin

export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_FORMAT_DEFISES = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm'
export const TIME_FORMAT_FULL = 'HH:mm:ss'
export const PAGE_SIZE = 15
export const PAGE_SIZE_MAX = 1000
export const DEFAULT_FONT_SIZE = '1rem'
export const FORM_FIELD_MAX_WIDTH = '25rem'
export const SNACKBAR_MESSAGE_DURATION = 5000
export const TASK_RUNNER_TIMEOUT_INTERVAL = 5000
export const TASK_RUNNER_MESSAGE_DURATION = 10000

const REFERENCES_URL = 'references'

export const STORAGE_INTERFACE_SETTINGS_NAME = 'interface-settings'

export const ROUTE = {
  home: '/',
  login: '/login',
  logout: '/logout',
  appSettings: {
    root: `/${REFERENCES_URL}/appsettings`,
  },
  appsConfig: {
    root: `/${REFERENCES_URL}/appsconfig`,
    add: `/${REFERENCES_URL}/appsconfig/add`,
    edit(entityId = ':entityId') {
      return `${ROUTE.appsConfig.root}/edit/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.appsConfig.root}/version/${id}`
    },
  },
  processes: {
    root: '/processes',
    add: '/processes/add',
    edit(entityId = ':entityId') {
      return `${ROUTE.processes.root}/edit/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.processes.root}/version/${id}`
    },
    definitions: {
      list: '/processes/definitions',
      add: '/processes/definitions/add',
      edit(entityId = ':entityId') {
        return `${ROUTE.processes.definitions.list}/edit/${entityId}`
      },
      version(id = ':entityId') {
        return `${ROUTE.processes.definitions.list}/version/${id}`
      },
      templates(entityId = ':entityId') {
        return `${ROUTE.processes.definitions.list}/templates/${entityId}`
      },
    },
  },
  vehicles: {
    root: '/vehicles',
    add: '/vehicles/add',
    edit(entityId = ':entityId') {
      return `${ROUTE.vehicles.root}/edit/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.vehicles.root}/version/${id}`
    },
    dictionaries: {
      list: `/${REFERENCES_URL}/vehicles/dictionaries`,
      add: `/${REFERENCES_URL}/vehicles/dictionaries/add`,
      edit(entityId = ':entityId') {
        return `${ROUTE.vehicles.dictionaries.list}/edit/${entityId}`
      },
      version(id = ':entityId') {
        return `${ROUTE.vehicles.dictionaries.list}/version/${id}`
      },
    },
    types: {
      list: `/${REFERENCES_URL}/vehicles/types`,
      add: `/${REFERENCES_URL}/vehicles/types/add`,
      edit(entityId = ':entityId') {
        return `${ROUTE.vehicles.types.list}/edit/${entityId}`
      },
      version(id = ':entityId') {
        return `${ROUTE.vehicles.types.list}/version/${id}`
      },
    },
  },
  rockets: {
    root: '/rockets',
    add: '/rockets/add',
    edit(entityId = ':entityId') {
      return `${ROUTE.rockets.root}/edit/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.rockets.root}/version/${id}`
    },
    radii: {
      list: `/${REFERENCES_URL}/rockets/radii`,
      add: `/${REFERENCES_URL}/rockets/radii/add`,
      edit(entityId = ':entityId') {
        return `${ROUTE.rockets.radii.list}/edit/${entityId}`
      },
      version(id = ':entityId') {
        return `${ROUTE.rockets.radii.list}/version/${id}`
      },
    },
    types: {
      list: `/${REFERENCES_URL}/rockets/types`,
      add: `/${REFERENCES_URL}/rockets/types/add`,
      edit(entityId = ':entityId') {
        return `${ROUTE.rockets.types.list}/edit/${entityId}`
      },
      version(id = ':entityId') {
        return `${ROUTE.rockets.types.list}/version/${id}`
      },
    },
  },
  users: {
    list: '/users',
    add: '/users/add',
    edit(entityId = ':entityId') {
      return `${ROUTE.users.list}/edit/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.users.list}/version/${id}`
    },
  },
  units: {
    tree: '/users/units/tree',
    workingMode(entityId = ':entityId') {
      return `/units/working-mode/${entityId}`
    },
    actualTree: '/users/units/actual-tree',
  },
  positions: {
    list: `/${REFERENCES_URL}/positions`,
    add: `/${REFERENCES_URL}/positions/add`,
    edit(entityId = ':entityId') {
      return `${ROUTE.positions.list}/edit/${entityId}`
    },
    permissions(entityId = ':entityId') {
      return `${ROUTE.positions.list}/permissions/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.positions.list}/version/${id}`
    },
  },
  ranks: {
    list: `/${REFERENCES_URL}/ranks`,
    add: `/${REFERENCES_URL}/ranks/add`,
    edit(entityId = ':entityId') {
      return `${ROUTE.ranks.list}/edit/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.ranks.list}/version/${id}`
    },
  },
  operationGroups: {
    tree: '/operation-groups/tree',
    list: '/operation-groups',
    add: '/operation-groups/add',
    edit(entityId = ':entityId') {
      return `${ROUTE.operationGroups.list}/edit/${entityId}`
    },
    permissions(entityId = ':entityId') {
      return `${ROUTE.operationGroups.list}/permissions/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.operationGroups.list}/version/${id}`
    },
  },
  messages: {
    root: '/messages',
    add: '/messages/add',
    sent: '/messages/sent',
    draft: '/messages/draft',
    threads: '/messages/threads',
    threadsAttention: '/messages/threads-attention',
    pdfToLaunch: '/messages/input-pdf-to-launch',
    view(entityId = ':entityId') {
      return `${ROUTE.messages.root}/view/${entityId}`
    },
    edit(entityId = ':entityId') {
      return `${ROUTE.messages.root}/edit/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.messages.root}/version/${id}`
    },
    notes: {
      list: '/messages/notes/list',
      add: '/messages/notes/add',
      edit(entityId = ':entityId') {
        return `/messages/notes/edit/${entityId}`
      },
      version(id = ':entityId') {
        return `/messages/notes/list/version/${id}`
      },
    },
  },
  objcts: {
    list: '/objects',
    add: '/objects/add',
    edit(entityId = ':entityId') {
      return `${ROUTE.objcts.list}/edit/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.objcts.list}/version/${id}`
    },
  },
  objectTypes: {
    list: `/${REFERENCES_URL}/object-types`,
    add: `/${REFERENCES_URL}/object-types/add`,
    edit(entityId = ':entityId') {
      return `${ROUTE.objectTypes.list}/edit/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.objectTypes.list}/version/${id}`
    },
  },
  targetGroups: {
    list: `/${REFERENCES_URL}/target-groups`,
    add: `/${REFERENCES_URL}/target-groups/add`,
    edit(entityId = ':entityId') {
      return `${ROUTE.targetGroups.list}/edit/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.targetGroups.list}/version/${id}`
    },
  },
  protections: {
    list: `/${REFERENCES_URL}/protections`,
    add: `/${REFERENCES_URL}/protections/add`,
    edit(entityId = ':entityId') {
      return `${ROUTE.protections.list}/edit/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.protections.list}/version/${id}`
    },
  },
  goals: {
    root: '/goals',
    add: '/goals/add',
    edit(entityId = ':entityId') {
      return `${ROUTE.goals.root}/edit/${entityId}`
    },
    targets: {
      add(entityId = ':entityId') {
        return `${ROUTE.goals.root}/edit/${entityId}/targets/add`
      },
      edit(entityId = ':entityId') {
        return `${ROUTE.goals.root}/targets/edit/${entityId}`
      },
    },
    executors: {
      add(entityId = ':entityId') {
        return `${ROUTE.goals.root}/edit/${entityId}/executors/add`
      },
    },
    version(id = ':entityId') {
      return `${ROUTE.goals.root}/version/${id}`
    },
  },
  goalsAuto: {
    root: '/goals-auto',
    add: '/goals-auto/add',
    edit(entityId = ':entityId') {
      return `${ROUTE.goalsAuto.root}/edit/${entityId}`
    },
    targets: {
      add(entityId = ':entityId') {
        return `${ROUTE.goalsAuto.root}/edit/${entityId}/targets/add`
      },
      edit(entityId = ':entityId') {
        return `${ROUTE.goalsAuto.root}/targets/edit/${entityId}`
      },
    },
    executors: {
      add(entityId = ':entityId') {
        return `${ROUTE.goalsAuto.root}/edit/${entityId}/executors/add`
      },
    },
    version(id = ':entityId') {
      return `${ROUTE.goalsAuto.root}/version/${id}`
    },
  },
  distributions: {
    root: '/distributions',
    view(entityId = ':entityId') {
      return `${ROUTE.distributions.root}/view/${entityId}`
    },
  },
  distributionsAuto: {
    root: '/distributions-auto',
    view(entityId = ':entityId') {
      return `${ROUTE.distributionsAuto.root}/view/${entityId}`
    },
  },
  zones: {
    root: '/zones',
    add: '/zones/add',
    edit(entityId = ':entityId') {
      return `${ROUTE.zones.root}/edit/${entityId}`
    },
    version(id = ':entityId') {
      return `${ROUTE.zones.root}/version/${id}`
    },
  },
  references: {
    root: `/${REFERENCES_URL}`,
  },
  actual: {
    root: '/actual_state',
  },
}

export const TABS = {
  goals: [
    {
      to: ROUTE.goals.root,
      label: 'Робочі плани',
    },
  ],
  vehicles: [
    {
      to: ROUTE.vehicles.root,
      label: 'Одиниці техніки',
    },
  ],
  rockets: [
    {
      to: ROUTE.rockets.root,
      label: 'Одиниці ЗУ',
    },
  ],
  users: [
    {
      to: ROUTE.users.list,
      label: 'Особовий склад',
    },
    {
      to: ROUTE.units.tree,
      label: 'Структура підрозділів',
    },
    {
      to: ROUTE.units.actualTree,
      label: 'Актуальний стан',
    },
  ],
  operationGroups: [
    {
      to: ROUTE.operationGroups.tree,
      label: 'Довідник оперативних груп',
    },
    {
      to: ROUTE.operationGroups.list,
      label: 'Особовий склад і дозволи',
    },
  ],
  objcts: [
    {
      to: ROUTE.objcts.list,
      label: 'Доступні обʼєкти',
    },
  ],
  zones: [
    {
      to: ROUTE.zones.root,
      label: 'Заборонені зони',
    },
  ],
  processes: [
    {
      to: ROUTE.processes.root,
      label: 'Процеси',
    },
    {
      to: ROUTE.processes.definitions.list,
      label: 'Шаблони процесів',
    },
  ],
  definitions: [
    {
      to: ROUTE.processes.root,
      label: 'Процеси',
    },
    {
      to: ROUTE.processes.definitions.list,
      label: 'Шаблони процесів',
    },
  ],
  messages: [
    {
      to: ROUTE.messages.root,
      label: 'Вхідні',
    },
    {
      to: ROUTE.messages.sent,
      label: 'Надіслані',
    },
    {
      to: ROUTE.messages.draft,
      label: 'Чернетки',
    },
    {
      to: ROUTE.messages.threads,
      label: 'Мої треди',
    },
    {
      to: ROUTE.messages.threadsAttention,
      label: 'Треди до відома',
    },
    // {
    //   to: ROUTE.messages.pdfToLaunch,
    //   label: 'Ввести наказ на ПУСК',
    // },
    // {
    //   to: ROUTE.messages.notes.list,
    //   label: 'Накази від вищої ланки',
    // },
  ],
  references: [
    {
      to: ROUTE.vehicles.dictionaries.list,
      label: 'Техніка',
    },
    {
      to: ROUTE.vehicles.types.list,
      label: 'Типи техніки',
    },
    {
      to: ROUTE.rockets.types.list,
      label: 'Типи ракет',
    },
    {
      to: ROUTE.rockets.radii.list,
      label: 'Радіуси ураження',
    },
    {
      to: ROUTE.positions.list,
      label: 'Посади',
    },
    {
      to: ROUTE.ranks.list,
      label: 'Звання',
    },
    {
      to: ROUTE.objectTypes.list,
      label: 'Типи обʼєктів',
    },
    {
      to: ROUTE.targetGroups.list,
      label: 'Групи цілей',
    },
    {
      to: ROUTE.protections.list,
      label: 'Захищенність',
    },
    {
      to: ROUTE.appSettings.root,
      label: 'Налагодження',
    },
    {
      to: ROUTE.appsConfig.root,
      label: 'Конфігурація',
    },
  ],
}

export const conditions = [
  {
    label: 'У строю',
    value: true,
  },
  {
    label: 'На обслуговуванні',
    value: false,
  },
]

export const userStatuses = [
  {
    label: 'У строю',
    value: true,
  },
  {
    label: 'Не у строю',
    value: false,
  },
]

export const technicalConditions = [
  {
    label: 'Справна',
    value: true,
  },
  {
    label: 'Не справна',
    value: false,
  },
]

export const yesNoOptions = [
  {
    label: 'Так',
    value: true,
  },
  {
    label: 'Ні',
    value: false,
  },
]

export const locationOptions = [
  {
    label: 'Машина',
    value: 'vehicle',
  },
  {
    label: 'Місце зберігання',
    value: 'place',
  },
  {
    label: 'Запущена',
    value: 'fired',
  },
]

export const readinessDegreeOptionsDepend = {
  'Пуск ОТР': [
    {
      label: '4',
      value: '4',
    },
  ],
  1: [
    {
      label: 'Пуск ОТР',
      value: 'Пуск ОТР',
    },
    {
      label: '2а',
      value: '2а',
    },
    {
      label: '3',
      value: '3',
    },
  ],
  '2а': [
    {
      label: 'Пуск ОТР',
      value: 'Пуск ОТР',
    },
    {
      label: '1',
      value: '1',
    },
    {
      label: '3',
      value: '3',
    },
  ],
  '2б': [
    {
      label: 'Пуск ОТР',
      value: 'Пуск ОТР',
    },
    {
      label: '3',
      value: '3',
    },
  ],
  3: [
    {
      label: '2а',
      value: '2а',
    },
    {
      label: '2б',
      value: '2б',
    },
    {
      label: '1',
      value: '1',
    },
  ],
  4: [
    {
      label: '3',
      value: '3',
    },
  ],
}

export const readinessDegreeOptions = [
  {
    label: 'Пуск ОТР',
    value: 'Пуск ОТР',
    timeout: null, // Не регламентируем
  },
  {
    label: '1',
    value: '1',
    timeout: 2 * 3600 * 1000, // 2 часа
  },
  {
    label: '2а',
    value: '2а',
    timeout: 14 * 24 * 3600 * 1000, // 2а	14 дней
  },
  {
    label: '2б',
    value: '2б',
    timeout: 14 * 24 * 3600 * 1000, // 2б	14 дней
  },
  {
    label: '3',
    value: '3',
    timeout: 6 * 30 * 24 * 3600 * 1000, // 3	6 месяцев
  },
  {
    label: '4',
    value: '4',
    timeout: null, // Не регламентируем
  },
]

export const calculationTimeoutOptions = [
  {
    label: '1 хвилина',
    value: 1,
  },
  {
    label: '2 хвилини',
    value: 2,
  },
  {
    label: '3 хвилини',
    value: 3,
  },
  {
    label: '4 хвилини',
    value: 4,
  },
  {
    label: '5 хвилин',
    value: 5,
  },
  {
    label: 'без обмеження часу',
    value: 0,
  },
]

export const workModeOptions = [
  {
    label: 'Бойовий',
    value: 'Бойовий',
  },
  {
    label: 'Черговий',
    value: 'Черговий',
  },
]

export const colors = {
  black: 'rgba(25, 39, 57, 0.94)',
  blue: '#005FDB',
  dark: '#223348',
  error: '#E21F14',
  errorBg: '#FBB9B8',
  focus: '#6764D1',
  gray: '#808080',
  grayBg: '#f3f5f7',
  grayBlueBg: '#f3f7fc',
  grayDarkBg: '#dee4ec',
  green: '#158444',
  label: '#5e6b7b',
  paper: '#2D2D2D',
  red: '#CA150C',
  success: '#245B46',
  successBg: '#B9E1D1',
  text: '#192739',
  textLight: '#D9DADD',
  warning: '#F3B032',
  white: '#ffffff',
}

export const SnackbarStatus = {
  default: 'default',
  error: 'error',
  loading: 'loading',
  success: 'success',
  warning: 'warning',
}

export const distributionSnackbarStates = {
  complete: SnackbarStatus.success,
  failed: SnackbarStatus.error,
  invalid: SnackbarStatus.error,
}

export const app11NodesTypes = {
  set: 'set',
  container: 'container',
  scalar: 'scalar',
  content: 'content',
}

export const app11MessagePriority = [
  {
    label: 'Молнія',
    value: 'Z',
  },
  {
    label: 'Термінове',
    value: 'O',
  },
  {
    label: 'Пріоритетне',
    value: 'P',
  },
  {
    label: 'Регламентне',
    value: 'R',
  },
]

export const app11MessageSecurity = [
  {
    label: 'Несекретне (UNCLASSIFIED)',
    value: 'ZNR UUUUUU',
  },
  {
    label: 'Для обмеженого використання (RESTRICTED)',
    value: 'ZNY RRRRR',
  },
  {
    label: 'Конфіденційне (CONFIDENTAL)',
    value: 'ZNY CCCCC',
  },
  {
    label: 'Секретне (SECRET)',
    value: 'ZNY SSSSS',
  },
  {
    label: 'Абсолютно секретне (TOP SECRET)',
    value: 'ZNY TTTTT',
  },
]

export const VehicleTypeDetails = {
  CCM: {
    name: 'Командна машина управління',
    workingMode: true,
    readinessDegree: false,
  },
  SPL: {
    name: 'Самохідна пускова установка',
    workingMode: false,
    readinessDegree: true,
  },
  TCM: {
    name: 'Транспортно-зарядна машина',
    workingMode: false,
    readinessDegree: false,
  },
  MRM: {
    name: 'Машина технічного обслуговування і ремонту',
    workingMode: false,
    readinessDegree: false,
  },
  ACMM: {
    name: 'Автономна контрольно-вимірювальна машина',
    workingMode: false,
    readinessDegree: false,
  },
}
