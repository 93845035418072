import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from "./index";
import { getApiUrl } from "../utils";
import useSaveRequest from "../hooks/useSaveRequest";

const DialogArchive = ({ batchData, isOpen, onClose, refreshData, routeSlug }) => {
  const [saving, saveRequest] = useSaveRequest(
    'patch',
    null,
    useCallback(() => refreshData(), [refreshData]),
    null,
    'Додано до архіву'
  );
  const onSubmit = () => {
    Object.keys(batchData.selectedRows).forEach((id) => {
      const variables = batchData.selectedRows[id];
      variables.isArchieved = true;
      saveRequest(variables, getApiUrl(routeSlug, { entityId: variables.id }));
    });
    batchData.deselectCallback();
    onClose();
  }

  return (
    <Modal
      isLoading={saving}
      isOpen={saving || isOpen}
      text="Ви впевнені?"
      title="Архівувати"
      buttons={[
        {
          color: Button.COLOR.BLUE,
          label: 'Відміна',
          onClick: onClose,
        },
        {
          label: 'Підтвердити',
          onClick: onSubmit,
        },
      ]}
    />
  );
}

DialogArchive.propTypes = {
  batchData: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  routeSlug: PropTypes.string.isRequired,
};

export default DialogArchive;
