/**
 * This module used to encapsulate axios methods.
 *
 * Any failed ajax requests made will also fire an APIError
 * error event to the react app which gets notified via
 * the AppErrors snackbar component
 *
 * see: components/AppErrors.jsx
 *
 */
import axios from 'axios'
import { asCamelCase, asSnakeCase } from './index'
import { ROUTE, SnackbarStatus } from '../constants'
import { getApiUrl } from '../utils'

const nearExpiringLimit = 5 * 60 * 1000

const isExcludedAPI = (url) => {
  return url === getApiUrl('auth_login') || url === getApiUrl('auth_user') || url === getApiUrl('auth_refresh')
}

const getAuthTokenExpires = () => {
  const userData = JSON.parse(localStorage.getItem('authData'))
  return {
    token: userData?.access_token ? userData.access_token : '',
    expires: userData?.expires_at ? userData.expires_at : 0,
  }
}

const resetAuthTokenExpires = (token = '', expires_at = 0, totalClean = false) => {
  if (totalClean) {
    localStorage.removeItem('authData')
  } else {
    const userData = JSON.parse(localStorage.getItem('authData'))
    userData.access_token = token
    userData.expires_at = expires_at
    localStorage.setItem('authData', JSON.stringify(userData))
  }
}

const cleanAuthAndReLogin = () => {
  resetAuthTokenExpires('', 0, true)
  if (!window.location.href.endsWith('login')) {
    window.location.href = ROUTE.login
  }
}

const checkAuthState = async () => {
  const { token, expires } = getAuthTokenExpires()
  const hasAuth = token && token.length > 0 && expires && expires > 0
  const isLive = hasAuth && new Date().getTime() < expires
  const needRefresh = isLive && new Date().getTime() > expires - nearExpiringLimit
  if (!hasAuth) {
    cleanAuthAndReLogin()
  } else {
    if (!isLive || needRefresh) {
      refreshToken()
    }
  }
}

const refreshToken = async () => {
  try {
    const newTokenData = await requestServe(axios.post, getApiUrl('auth_refresh'))
    if (newTokenData.data?.data?.access_token && newTokenData.data?.data?.expires_in) {
      resetAuthTokenExpires(
        newTokenData.data?.data?.access_token,
        new Date(new Date().getTime() + newTokenData.data?.data?.expires_in * 1000).getTime()
      )
    } else {
      cleanAuthAndReLogin()
    }
  } catch (e) {
    console.error(e)
  }
}

const requestServe = async (method, url, ...args) => {
  try {
    axios.defaults.headers.common['Authorization'] = `Bearer ${getAuthTokenExpires().token}`
    return await method(url, ...args)
  } catch (e) {
    const { status = 500 } = e.response || {}
    if (status === 401 && !isExcludedAPI(url)) {
      cleanAuthAndReLogin()
    }
    const { response = e } = e
    response.data = response.data || {}
    // eslint-disable-next-line camelcase
    let message = response?.data || e.message
    if (typeof message !== 'string') {
      message = JSON.stringify(message)
    }
    throw new ApiError(status, message)
  }
}

export const getAxiosMethod = (method) => {
  switch (method) {
    case 'delete':
      return axios.delete
    case 'patch':
      return axios.patch
    case 'post':
      return axios.post
    case 'put':
      return axios.put
    default:
      return axios.get
  }
}

export class ApiError extends Error {
  constructor(status, message) {
    super(message)
    /**
     * Because webpack will compress the class name, we cannot use
     * this.constructor.name
     */
    this.name = ''
    this.status = status

    /**
     * Whether the user must re-authenticate before trying to rerun the API request.
     *
     * Description:
     * ------------
     * The following HTTP response status codes indicate Rover session token is no longer valid:
     *
     *  - 401 Unauthorized
     *  - 403 Forbidden
     *  - 440 Login Time-out
     *
     * This could happen because of the following reasons:
     *
     *  1) An administrator has manually removed the session token
     *
     *  2) The session token may have been forcibly removed
     *     by a misconfigured microservice
     *
     *  3) A bug in the app may have caused the token refresh to
     *     be out of sync
     *
     * Because of that, we will add an additional property to indicate
     * the request resulted from an authentication failure
     */
    this.needsAuthentication = [401, 403, 440].includes(status)

    // Dispatch event
    this.emit()
  }

  /**
   * Emits a event to the AuthContext component so that
   * the AppErrors snackbar can render it
   */
  emit() {
    const event = new Event(this.name, {
      error: this,
      message: this.message,
    })
    event.message = this.message
    event.needsAuthentication = this.needsAuthentication
    dispatchEvent(event)
    return event
  }
}

export const request = async (method, url, ...args) => {
  if (!isExcludedAPI(url)) {
    await checkAuthState()
  }
  return await requestServe(method, url, ...args)
}

export const getRequest = (url, setLoading, showSnackbar, setData) => {
  setLoading(true);
  request(axios.get, url)
    .then(res => setData(asCamelCase(res.data.data)))
    .catch(() => showSnackbar({ message: 'Помилка отримання даних', status: SnackbarStatus.error }))
    .finally(() => setLoading(false));
};

export const saveRequest = (url, isNew, variables, setLoading, showSnackbar, onSaved) => {
  setLoading(true);
  const method = isNew ? axios.post : axios.put
  request(method, url, asSnakeCase(variables))
    .then(() => {
      onSaved();
      showSnackbar({ message: 'Успішно збережено', status: SnackbarStatus.success });
    })
    .catch(() => showSnackbar({ message: 'Помилка збереження', status: SnackbarStatus.error }))
    .finally(() => setLoading(false));
};

const httpRequests = {
  /**
   * Http delete method
   */
  delete: async (...args) => request(axios.delete, ...args),

  /**
   * Http get method
   */
  get: async (...args) => request(axios.get, ...args),

  /**
   * Http patch method
   */
  patch: async (...args) => request(axios.patch, ...args),

  /**
   * Http post method
   */
  post: async (...args) => request(axios.post, ...args),

  /**
   * Http put method
   */
  put: async (...args) => request(axios.put, ...args),
};

export default httpRequests;
