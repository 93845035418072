import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { TASK_RUNNER_MESSAGE_DURATION, TASK_RUNNER_TIMEOUT_INTERVAL } from '../constants';
import { useSnackbar } from "./SnackbarContext";

export const TaskRunnerContext = createContext(undefined);

export const TaskRunnerProvider = props => {
  const { showSnackbar } = useSnackbar();

  const runTask = useCallback(
    ({
      process,
      refreshData,
      timeoutInterval = TASK_RUNNER_TIMEOUT_INTERVAL,
    }) => {
      const timer = setInterval(() => refreshData({}, data => {
        const snackbarParams = process(data);
        console.log('timer result: ', snackbarParams, data);
        if (snackbarParams) {
          clearInterval(timer);
          showSnackbar({
            ...snackbarParams,
            autoHideTimeout: TASK_RUNNER_MESSAGE_DURATION
          });
        }
      }, true), timeoutInterval);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSnackbar],
  );

  return <TaskRunnerContext.Provider value={runTask} {...props} />;
};

export const useTaskRunner = () => useContext(TaskRunnerContext);
