import { useState, useEffect } from 'react'
import moment from 'moment/moment'
import { asSnakeCase, httpRequests, getApiUrl } from '../utils'
import { Button } from '../components'
import { DATE_FORMAT, SnackbarStatus } from '../constants'
import { useSnackbar } from '../contexts/SnackbarContext'

const initialData = {
  selected: false,
  selectedPartial: false,
  expanded: true,
  id: 'virtual-root-id',
  entityId: 'virtual-root-entity-id',
  name: 'virtual-root-entity-name',
  depth: 0,
  children: [],
  units: [],
}

const visualizationRowHeight = 46

export const getWrappedVisualizedHierarchy = (data, vehicles = []) => {
  return { ...initialData, children: getWrappedVisualizedHierarchyItem(data, 0, vehicles) }
}

const getWrappedVisualizedHierarchyItem = (hierarchyData, parentDepth = 0, vehicles) => {
  const result = []
  for (let item of hierarchyData) {
    try {
      const record = {
        selected: false,
        expanded: false,
        id: item.id,
        entityId: item.entityId,
        name: item.name,
        depth: parentDepth + 1,
        children: [],
        units: [],
      }
      for (const v of vehicles) {
        if (v.unitId === item.entityId) {
          const unitData = {
            isFound: true,
            name: v.vehicleDictionary.name,
            number: v.number,
            type: v.vehicleDictionary.vehicleType.type,
            readinessDegree: v.unitWorkingModes?.length ? v.unitWorkingModes[0].readinessDegree : '',
            workingMode: v.unitWorkingModes?.length ? v.unitWorkingModes[0].workMode : '',
            hasConnection: v.hasConnection,
          }
          record.units.push(unitData)
        }
      }
      if (item?.children?.length) {
        record.children = getWrappedVisualizedHierarchyItem(item.children, record.depth, vehicles)
      }
      record.expanded = false
      result.push(record)
    } catch (e) {
      console.log(e)
    }
  }
  return result
}

export const getWrappedHierarchy = (data, selectedItems = []) => {
  return { ...initialData, children: getWrappedHierarchyItem(data, 0, selectedItems) }
}

const getWrappedHierarchyItem = (hierarchyData, parentDepth = 0, selectedItems = []) => {
  const result = []
  for (let item of hierarchyData) {
    try {
      const record = {
        selected: selectedItems.includes(item.entityId),
        expanded: false,
        id: item.id,
        entityId: item.entityId,
        name: item.name,
        depth: parentDepth + 1,
        children: [],
      }
      if (item?.children?.length) {
        record.children = getWrappedHierarchyItem(item.children, record.depth, selectedItems)
      }
      record.expanded = hasSelectedChildren(record)
      result.push(record)
    } catch (e) {
      console.log(e)
    }
  }
  return result
}

const hasSelectedChildren = (record) => {
  for (let r of record.children) {
    if (r.selected || hasSelectedChildren(r)) {
      return true
    }
  }
  return false
}

const isSameParentForSelected = (record, parentEntityId) => {
  for (let r of record.children) {
    if ((r.selected && record.entityId !== parentEntityId) || !isSameParentForSelected(r, parentEntityId)) {
      return false
    }
  }
  return true
}

const resetAllSelected = (record) => {
  for (let r of record.children) {
    r.selected = false
    resetAllSelected(r)
  }
}

const composeReturnValue = (record, children) => {
  const result = null
  for (let r of record.children) {
    if (r.selected) {
      children.push({
        entityId: r.entityId,
        name: r.name,
        selectedPartial: r.selectedPartial,
        children: [],
      })
    }
    if (r.children.length) {
      const current = {
        entityId: r.entityId,
        name: r.name,
        selectedPartial: r.selectedPartial,
        children: [],
      }
      composeReturnValue(r, current.children)
      if (current.children.length) {
        children.push(current)
      }
    }
  }
  return result
}

const InlineInputForm = ({ itemValue, setItemValue, onCancel, onOK }) => {
  return (
    <div className="inline_input_miniform">
      <input
        placeholder="введіть нове ім'я"
        required
        size="25"
        value={itemValue}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            onOK()
          }
        }}
        onChange={(event) => {
          setItemValue(event.target.value)
        }}></input>
      <div className="controls">
        <Button className="btn_item_manage center_icon" icon={'check'} color={Button.COLOR.BG_GREEN} onClick={onOK} />
        <Button className="btn_item_manage center_icon" icon={'cancel'} color={Button.COLOR.BLUE} onClick={onCancel} />
      </div>

      <style jsx="true">{`
        .inline_input_miniform {
          position: relative;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
        }
        .inline_input_miniform .controls {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
        }
      `}</style>
    </div>
  )
}

const InlineVersionsForm = ({ active, apiURL, currentVersion, onCancel, onOK }) => {
  const [versions, setVersions] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)

  useEffect(() => {
    ;(async () => {
      if (active) {
        try {
          const apiVersionsUrl = getApiUrl(apiURL, { entityId: currentVersion.entityId })
          const response = await httpRequests.get(apiVersionsUrl)
          if (response.status === 200 && response.data?.data) {
            setVersions([...response.data?.data])
          } else {
            throw new Error('Помилка отримання даних')
          }
        } catch (e) {
          console.error(e.message)
        }
      }
    })()
    // eslint-disable-next-line
  }, [active, currentVersion.id])

  return (
    <div className="wrapper_versions_miniform">
      <div className="inline_versions_miniform">
        <div className="version_options_list">
          <h3>Вибрати версію</h3>
          {versions.map((item) => {
            return (
              item.id !== currentVersion.id && (
                <div
                  className="version_option"
                  key={item.id}
                  onClick={(event) => {
                    event.stopPropagation()
                    setSelectedItem({ ...item })
                  }}>
                  <div className="item_author">{item?.created_by?.full_name}</div>
                  <div className="item_name">{item?.name}</div>
                  <div className="item_date">{moment(item?.created_at).format(DATE_FORMAT)}</div>
                  <div className="item_choice">
                    <input
                      name="select_version"
                      type="radio"
                      checked={item.id === selectedItem?.id}
                      onChange={() => {
                        setSelectedItem({ ...item })
                      }}
                    />
                  </div>
                </div>
              )
            )
          })}
        </div>
        <div className="controls">
          <Button
            className={`btn_item_manage center_icon ${!selectedItem?.id ? 'hidden' : ''}`}
            icon={'check'}
            color={Button.COLOR.BG_GREEN}
            onClick={() => onOK(selectedItem)}
          />
          <Button
            className="btn_item_manage center_icon"
            icon={'cancel'}
            color={Button.COLOR.BLUE}
            onClick={onCancel}
          />
        </div>
      </div>

      <style jsx="true">{`
        .wrapper_versions_miniform {
          position: absolute;
          left: 0;
          top: 0;
          width: 50vw;
          max-height: 50vh;
          padding: 2rem;
          background-color: #d6d6d6;
          border-radius: 5px;
          z-index: 100;
        }
        .inline_versions_miniform {
          position: relative;
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          width: 100%;
          height: 100%;
        }
        .inline_versions_miniform .version_options_list {
          width: 100%;
          max-height: 100%;
          padding-bottom: 1rem;
          display: flex;
          flex-flow: column nowrap;
          gap: 5px;
          overflow: auto;
        }
        .inline_versions_miniform .version_option {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          padding: 0 1rem 0 0;
          cursor: pointer;
          user-select: none;
          border: 1px solid transparent;
          border-radius: 3px;
        }
        .inline_versions_miniform .version_option:hover {
          border: 1px solid #000;
        }
        .inline_versions_miniform .item_author {
          width: 30%;
          padding-left: 1rem;
          box-sizing: border-box;
        }
        .inline_versions_miniform .item_name {
          width: 40%;
        }
        .inline_versions_miniform .item_date {
          width: 20%;
        }
        .inline_versions_miniform .item_choice {
          width: 10%;
          display: flex;
          justify-content: center;
        }
        .inline_versions_miniform input[type='radio'] {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
        .inline_versions_miniform .controls {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          gap: 5rem;
        }
      `}</style>
    </div>
  )
}

const isCorrectNameSuffix = (name) => {
  const splittedName = name.trim().split(',')
  if (splittedName.length !== 2 || splittedName[1].length !== 3) {
    return false
  }
  const r = new RegExp('^[0-9]*$')
  return r.test(splittedName[1].trim())
}

function HierarchyEditor({
  provider = {
    data: null,
    addHierarchyItemURL: null,
    deleteHierarchyItemURL: null,
    changeHierarchyItemURL: null,
    versionsHierarchyItemURL: null,
    returnCallback: null,
  },
  options = {
    active: true,
    editable: true,
    multipleChoice: true,
    plainSelectOnly: true,
    preventCollapseSelected: true,
    oneBranchOnly: true,
    maxDepth: 0,
    withSuffix: false,
    vehiclesVisualizationMode: false,
  },
  className,
  ...props
}) {
  const appearanceClassNames = className ? className : ''
  const { showSnackbar } = useSnackbar()
  const [data, setData] = useState(provider.data)
  const [inAddNew, setInAddNew] = useState(false)
  const [inEditName, setInEditName] = useState(false)
  const [inVersion, setInVersion] = useState(false)
  const [ownerEntityId, setOwnerEntityId] = useState(null)
  const [currentVersionRecord, setVersionRecord] = useState(null)
  const [itemValue, setItemValue] = useState('')
  const canAddItems =
    options.active && typeof provider.addHierarchyItemURL == 'string' && provider.addHierarchyItemURL.length > 1
  const canDeleteItems =
    options.active && typeof provider.deleteHierarchyItemURL == 'string' && provider.deleteHierarchyItemURL.length > 1
  const canChangeItems =
    options.active && typeof provider.changeHierarchyItemURL == 'string' && provider.changeHierarchyItemURL.length > 1
  const canVersioning =
    options.active &&
    typeof provider.versionsHierarchyItemURL == 'string' &&
    provider.versionsHierarchyItemURL.length > 1
  const canReturnItems = typeof provider.returnCallback == 'function'
  const apiAddUnitUrl = canAddItems ? getApiUrl(provider.addHierarchyItemURL) : null

  const setReturnValue = () => {
    if (canReturnItems && data) {
      const result = []
      composeReturnValue(data, result)
      provider.returnCallback(result)
    }
  }

  useEffect(() => {
    setReturnValue()
    // eslint-disable-next-line
  }, [])

  const getSanitizedName = (record) => {
    if (options.vehiclesVisualizationMode && !options.withSuffix) {
      const splittedName = record.name.split(',')
      if (splittedName.length > 1) {
        return `${splittedName[0]}`
      } else {
        return `${record.name}`
      }
    } else {
      return record.name
    }
  }

  const getUnitName = (unit) => {
    return unit.isFound ? `${unit.name} ${unit.number}` : ''
  }

  const clickExpandHandler = (e, item, depthIndex) => {
    e.preventDefault()
    if (options.active && !inAddNew && !inEditName && data && depthIndex && !hasSelectedChildren(item)) {
      item.expanded = !item.expanded
      setData({ ...data })
    }
  }

  const clickSelectHandler = (e, item, parent) => {
    if (
      !options.active ||
      inAddNew ||
      inEditName ||
      !data ||
      !canReturnItems ||
      (!options.multipleChoice && item.children.length > 0) ||
      (options.multipleChoice && options.oneBranchOnly && !isSameParentForSelected(data, parent.entityId)) ||
      (options.plainSelectOnly && item.children.length > 0)
    ) {
      return
    }
    if (!options.multipleChoice) {
      const prevSelectedItemState = item.selected
      resetAllSelected(data)
      item.selected = !prevSelectedItemState
    } else {
      item.selected = !item.selected
    }
    item.selectedPartial = false
    setData({ ...data })
    setReturnValue()
  }

  const miniFormAddNew = (status, fromEntityId = null) => {
    setOwnerEntityId(fromEntityId)
    setInAddNew(status)
  }

  const onAddNewItem = async (children = [], parentId = null, parentDepth = 0) => {
    const newValue = itemValue.trim()
    if (!newValue) {
      showSnackbar({
        message: 'Значення не має бути порожнім',
        status: SnackbarStatus.error,
      })
    } else if (options.withSuffix && !isCorrectNameSuffix(newValue)) {
      showSnackbar({
        message: 'Значення має містити код NNN після коми',
        status: SnackbarStatus.error,
      })
    } else {
      try {
        const variables = {
          name: newValue,
          parent_id: parentId,
        }
        const response = await httpRequests.post(apiAddUnitUrl, asSnakeCase(variables))
        if ([200, 201].includes(response.status) && response.data?.data) {
          const newRecord = {
            ...initialData,
            entityId: response.data?.data.entity_id,
            name: response.data?.data.name,
            children: [],
            depth: parentDepth + 1,
            expanded: false,
          }
          children.push(newRecord)
          setData({ ...data })
        } else {
          throw new Error('Помилка додавання даних')
        }
      } catch (e) {
        console.error(e.message)
      } finally {
        miniFormAddNew(false)
      }
    }
  }

  const miniFormEditName = (status, fromEntityId = null, currentName = '') => {
    setOwnerEntityId(fromEntityId)
    setItemValue(currentName)
    setInEditName(status)
  }

  const onChangeItem = async (record = null) => {
    if (record?.entityId) {
      const newValue = itemValue.trim()
      if (!newValue) {
        showSnackbar({
          message: 'Значення не має бути порожнім',
          status: SnackbarStatus.error,
        })
      } else if (options.withSuffix && !isCorrectNameSuffix(newValue)) {
        showSnackbar({
          message: 'Значення має містити код NNN після коми',
          status: SnackbarStatus.error,
        })
      } else {
        try {
          const variables = {
            name: newValue,
          }
          const apiUpdateUrl = getApiUrl(provider.changeHierarchyItemURL, { entityId: record.entityId })
          const response = await httpRequests.put(apiUpdateUrl, asSnakeCase(variables))
          if ([200, 201].includes(response.status) && response.data?.data) {
            record.name = response.data?.data.name
            setData({ ...data })
          } else {
            throw new Error('Помилка додавання даних')
          }
        } catch (e) {
          console.error(e.message)
        } finally {
          miniFormEditName(false)
        }
      }
    }
  }

  const miniFormVersions = (status, record = null) => {
    setOwnerEntityId(record?.entityId)
    setVersionRecord(record)
    setInVersion(status)
  }

  const onChangeVersionItem = async (currentVersion = null, newVersion) => {
    if (
      currentVersion?.id &&
      newVersion?.id &&
      window.confirm(`Ви дійсно хочете відновити верcію ${newVersion?.name}?`)
    ) {
      try {
        const variables = {
          is_archieved: false,
        }
        const apiArchiveUrl = getApiUrl(provider.deleteHierarchyItemURL, { entityId: newVersion.id })
        const response = await httpRequests.patch(apiArchiveUrl, asSnakeCase(variables))
        if (response.status === 201 && response.data?.data) {
          currentVersion.name = response.data?.data.name
          setData({ ...data })
        } else {
          throw new Error('Помилка відновлення даних')
        }
      } catch (e) {
        console.error(e.message)
      } finally {
        miniFormVersions(false)
      }
    }
  }

  const onRemoveItem = async (parentChildren = [], pos, id = null, name) => {
    if (window.confirm(`Ви дійсно хочете архівувати ${name}?`)) {
      try {
        const variables = {
          is_archieved: true,
        }
        const apiArchiveUrl = getApiUrl(provider.deleteHierarchyItemURL, { entityId: id })
        const response = await httpRequests.patch(apiArchiveUrl, asSnakeCase(variables))
        if (response.status === 201 && response.data?.data) {
          parentChildren.splice(pos, 1)
          setData({ ...data })
        } else {
          throw new Error('Помилка архівування даних')
        }
      } catch (e) {
        console.error(e.message)
      }
    }
  }

  const getOptionsSelector = (record, parent, depthIndex, pos) => {
    return (
      <div
        className={`entity_item ${options.vehiclesVisualizationMode ? ' with-images' : ''}`}
        style={{ display: parent.expanded ? 'flex' : 'none' }}>
        {depthIndex > 1 && record.children.length > 0 && pos < parent.children.length - 1 && (
          <div className="hierarchy_connections_container"></div>
        )}
        <div
          className={`item_option ${record.depth > 1 ? 'has_parent' : ''} ${
            pos === parent.children.length - 1 ? 'is_last' : ''
          }`}>
          {record.children.length > 0 && (
            // eslint-disable-next-line
            <a
              href="#"
              className={`expander ${record.expanded ? 'expanded' : ''}`}
              onClick={(event) => clickExpandHandler(event, record, depthIndex)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <polyline points="18 15 12 9 6 15" />
              </svg>
            </a>
          )}
          {record.children.length === 0 && <span className="expander spacer"></span>}
          {canReturnItems && (record.children.length === 0 || !options.plainSelectOnly) && (
            <input
              className={`selector ${record.selectedPartial ? 'partial' : ''}`}
              type={options.multipleChoice ? 'checkbox' : 'radio'}
              name={record.entityId}
              checked={record.selected}
              onChange={(event) => clickSelectHandler(event, record, parent)}
            />
          )}
          {!(canReturnItems && (record.children.length === 0 || !options.plainSelectOnly)) && (
            <span className="selector spacer"></span>
          )}
          <label
            htmlFor={record.entityId}
            className="title"
            style={{
              height: `${
                options.vehiclesVisualizationMode
                  ? (visualizationRowHeight + 6) * Math.max(record.units?.length, 1)
                  : 24
              }px`,
            }}
            onClick={(event) =>
              record.children.length === 0 || !options.plainSelectOnly
                ? clickSelectHandler(event, record, parent)
                : clickExpandHandler(event, record, depthIndex)
            }>
            {((!inEditName || ownerEntityId !== record.entityId) && !inVersion && (
              <span>{getSanitizedName(record)}</span>
            )) ||
              (canChangeItems && inEditName && !inVersion && ownerEntityId === record.entityId && (
                <InlineInputForm
                  itemValue={itemValue}
                  setItemValue={setItemValue}
                  onCancel={(event) => {
                    miniFormEditName(false)
                  }}
                  onOK={(event) => {
                    onChangeItem(record)
                  }}
                />
              ))}
            {options.vehiclesVisualizationMode && record?.units.length > 0 && (
              <div className="units-list">
                {record.units.map((unit, i) => (
                  <div className="unit-info" key={i}>
                    <span className="unit-title">{getUnitName(unit)}</span>
                    <div className={`vehicle-visualization-icon ${unit.hasConnection ? 'is-online' : 'is-offline'}`}>
                      <img
                        src={`/images/vehicle_type_${(unit.type + '').toLowerCase()}_small.webp`}
                        alt={`vehicle type ${'SPL'}`}
                      />
                    </div>
                    {unit.readinessDegree && (
                      <span className={`readiness-state`}>{`Ступінь готовності ${unit.readinessDegree}`}</span>
                    )}
                    {unit.workingMode && <span className={`working-mode`}>{unit.workingMode}</span>}
                  </div>
                ))}
              </div>
            )}
            {options.editable && (
              <div className="editor">
                {canAddItems &&
                  (options.maxDepth === 0 || options.maxDepth >= record.depth + 1) &&
                  ((!inAddNew && !inEditName && !inVersion && (
                    <Button
                      className="btn_item_manage center_icon"
                      icon={'plusBlue'}
                      color={Button.COLOR.BG_GREEN}
                      onClick={(event) => {
                        miniFormAddNew(true, record.entityId)
                      }}
                    />
                  )) ||
                    (!inVersion && inAddNew && ownerEntityId === record.entityId && (
                      <InlineInputForm
                        itemValue={itemValue}
                        setItemValue={setItemValue}
                        onCancel={(event) => {
                          miniFormAddNew(false)
                        }}
                        onOK={(event) => {
                          onAddNewItem(record.children, record.entityId, record.depth)
                        }}
                      />
                    )))}
                {!inAddNew && !inEditName && !inVersion && record.children.length === 0 && canDeleteItems && (
                  <Button
                    className="btn_item_manage center_icon"
                    icon={'minusBlue'}
                    color={Button.COLOR.ERROR}
                    onClick={(event) => {
                      onRemoveItem(parent.children, pos, record.id, record.name)
                    }}
                  />
                )}
                {!inAddNew && !inEditName && !inVersion && canChangeItems && (
                  <Button
                    className="btn_item_manage center_icon"
                    icon={'pencil'}
                    color={Button.COLOR.BG_GRAY}
                    onClick={(event) => {
                      miniFormEditName(true, record.entityId, record.name)
                    }}
                  />
                )}
                {!inVersion && !inEditName && canVersioning && (
                  <Button
                    className="btn_item_version center_icon"
                    color={Button.COLOR.GRAY}
                    icon="bookGray"
                    type="button"
                    onClick={(event) => {
                      miniFormVersions(true, record)
                    }}
                  />
                )}
              </div>
            )}
          </label>
        </div>
        <div className="list">
          {record.expanded &&
            !(inAddNew && ownerEntityId === parent.entityId) &&
            record.children.map((innerItem, i) => (
              <div
                className={`inner_item ${i === record.children.length - 1 ? 'last-list' : ''}`}
                key={innerItem.entityId}>
                {getOptionsSelector(innerItem, record, depthIndex + 1, i)}
              </div>
            ))}
        </div>
      </div>
    )
  }

  return (
    <div
      className={`tree_editor ${appearanceClassNames} ${!options.active ? ' disabled' : ''} ${
        options.vehiclesVisualizationMode ? ' with-images' : ''
      }`}
      {...props}>
      {canChangeItems && !inEditName && inVersion && (
        <InlineVersionsForm
          active={inVersion}
          apiURL={provider.versionsHierarchyItemURL}
          currentVersion={currentVersionRecord}
          onCancel={(event) => {
            miniFormVersions(false)
          }}
          onOK={(newVersion) => {
            onChangeVersionItem(currentVersionRecord, newVersion)
          }}
        />
      )}
      <div className={`list ${inVersion ? 'hidden' : ''}`}>
        {data?.children?.map((innerItem, i) => (
          <div className={`inner_item ${i === data?.children.length - 1 ? 'last-list' : ''}`} key={innerItem.entityId}>
            {getOptionsSelector(innerItem, data, 1, i)}
          </div>
        ))}
        {canAddItems &&
          ((!inAddNew && !inEditName && (
            <div style={{ marginLeft: '11px' }}>
              <Button
                className="btn_item_manage center_icon"
                icon={'plusBlue'}
                color={Button.COLOR.BG_GREEN}
                onClick={(event) => {
                  miniFormAddNew(true, null)
                }}
              />
            </div>
          )) ||
            (inAddNew && ownerEntityId == null && (
              <InlineInputForm
                itemValue={itemValue}
                setItemValue={setItemValue}
                onCancel={(event) => {
                  miniFormAddNew(false)
                }}
                onOK={(event) => {
                  onAddNewItem(data?.children, null, 0)
                }}
              />
            )))}
      </div>

      <style jsx="true">{`
        .tree_editor {
          position: relative;
        }
        .list {
          position: relative;
        }
        .list.hidden {
          visibility: hidden;
        }
        .entity_item {
          position: relative;
          padding: 0 0 0 1.5rem;
          display: flex;
          flex-flow: column nowrap;
        }
        .entity_item.with-images .title {
          height: ${visualizationRowHeight}px;
          display: flex;
          flex-flow: row;
          align-items: flex-start;
        }
        .entity_item.with-images .title > span {
          height: ${visualizationRowHeight + 6}px;
          display: flex;
          align-items: center;
        }
        .vehicle-visualization-icon {
          position: relative;
        }
        .vehicle-visualization-icon img {
          position: relative;
          width: 50px;
          height: ${visualizationRowHeight + 2}px;
          padding: 0 6px;
          top: 3px;
        }
        .vehicle-visualization-icon:after {
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          top: 5px;
          right: 5px;
          border-left: 1px solid gray;
          border-radius: 50%;
          background-color: #f00;
        }
        .vehicle-visualization-icon.is-online:after {
          background-color: #0f0;
        }
        .hierarchy_connections_container {
          position: absolute;
          top: -6px;
          left: ${visualizationRowHeight}px;
          bottom: 0;
          border-left: 1px solid gray;
        }
        .entity_item.with-images .item_option {
          padding: 0 7px;
          border: 1px solid;
          border-radius: 7px;
        }
        .item_option {
          position: relative;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
        }
        .item_option.has_parent:before {
          content: '';
          position: absolute;
          border-left: 1px solid gray;
          border-bottom: 1px solid gray;
          top: -8px;
          bottom: 46%;
          left: 22px;
          right: calc(100% - ${visualizationRowHeight}px);
        }
        .item_option.has_parent:after {
          content: '';
          position: absolute;
          border-left: 1px solid gray;
          top: 50%;
          bottom: 15%;
          left: 22px;
        }
        .item_option.has_parent.is_last:after {
          display: none;
        }
        .entity_item.with-images .list:before {
          content: '';
          position: absolute;
          border-left: 1px dashed gray;
          top: 0;
          left: 12px;
          bottom: 30px;
        }
        .entity_item.with-images .hierarchy_connections_container {
          border: none;
        }
        .entity_item.with-images .item_option.has_parent:before {
          border: none;
        }
        .entity_item.with-images .item_option.has_parent:after {
          content: '';
          position: absolute;
          border-bottom: 1px dashed gray;
          right: 100%;
          left: -12px;
          bottom: 30px;
        }
        .entity_item.with-images .item_option.has_parent.is_last:after {
          display: block;
        }
        .inner_item.last-list .inner_item.last-list .entity_item.with-images .list:after {
          content: '';
          position: absolute;
          border-left: 4px solid #fff;
          top: -30px;
          left: -14px;
          bottom: 28px;
        }
        .expander {
          text-decoration: none;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          background: rgba(51, 140, 255, 0.13);
          border-radius: 4px;
        }
        .expander svg {
          transition: transform 250ms;
          transform: rotateX(180deg);
        }
        .expander.expanded svg {
          transform: rotateX(0deg);
        }
        .expander.spacer {
          visibility: hidden;
        }
        .selector {
          position: relative;
          cursor: pointer;
          width: 1rem;
          height: 1rem;
          margin: 3px 0 0 11px;
        }
        .selector:before {
          content: '';
          position: absolute;
          background: #fff;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: -1;
        }
        .selector.spacer {
          visibility: hidden;
        }
        .selector.partial {
          accent-color: #ddd;
        }
        .title {
          position: relative;
          user-select: none;
          cursor: pointer;
          padding: 0.35rem 0 0.15rem 0.25rem;
          height: 24px;
          min-width: 15rem;
        }
        .unit-info {
          display: flex;
          align-items: center;
          padding: 0 0 0 10px;
          font-weight: bold;
        }
        .readiness-state,
        .working-mode {
          padding: 4px;
          font-size: 12px;
          font-weight: bold;
          color: #000;
          border: 1px solid #d0d0d0;
          border-radius: 3px;
        }
        .working-mode {
          margin-left: 0.5rem;
        }
        .editor {
          position: absolute;
          top: 0;
          right: -2.5rem;
          bottom: 0;
          width: 2rem;
          height: 100%;
          display: flex;
          align-items: center;
        }
        .edit_record {
          width: 1rem;
          height: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          border: 1px solid gray;
          border-radius: 3px;
          cursor: pointer;
          text-decoration: none;
        }
        .children {
        }
        .btn_item_manage {
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          height: 1.5rem;
          padding: 6px !important;
        }
        .btn_item_manage.hidden {
          visibility: hidden;
        }
        .btn_item_manage > span {
          margin: 0 !important;
        }
        .btn_item_manage > span svg {
          max-width: 13px;
        }
        .btn_item_version {
          padding: 0 !important;
          border: 1px solid #a3a3a3;
        }
        .btn_item_version > span {
          margin: 0 auto !important;
          height: 24px;
        }
        .btn_item_version > span svg {
          max-width: 24px;
          width: 24px;
          height: 24px;
        }
      `}</style>
    </div>
  )
}

export default HierarchyEditor
