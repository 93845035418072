import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import styles from './Table.module.css';
import { CheckBox } from "../../components";

const TableHeader = ({
  columns,
  disabled,
  hasBatchActions,
  hasRowActions,
  onSelectAll,
  rowActionsClassName,
  selectAllChecked,
  sortAsc,
  sortBy,
  sortCallback,
}) => (
  <thead className={styles.datagridHeader}>
    <tr>
      {hasBatchActions && (
        <th className={cn(styles.col, styles.batchCol)}>
          <CheckBox
            color={CheckBox.COLOR.CLIENT}
            checked={selectAllChecked}
            onChange={onSelectAll}
          />
        </th>
      )}
      {columns.map((column, columnKey) => {
        const sortField = column.sortField || column.key;
        let renderedItem = '';
        if (column.columnRenderer) {
          const CurrentComponent = column.columnRenderer;
          renderedItem = (
            <CurrentComponent
              row={column.attributes}
            />
          );
        } else {
          const sortField = column.sortField || column.key;
          renderedItem = <span className={styles.columnTitle}>
            {column.title}
            {column.sortable && sortBy !== sortField
              ? <Icon kind="sorting" className={styles.sortIndicator} />
              : null
            }
            {column.sortable && sortBy === sortField
              ? <Icon kind={sortAsc ? 'sorting-up' : 'sorting-down'} className={cn(styles.sortIndicator, styles.sorted)} />
              : null
            }
          </span>;
        }
        return (
          <th
            key={columnKey}
            className={cn(
              styles.col,
              `col-${column.key}`,
              column.sortable && styles.colSortable,
              column.className,
              column.headerClassName,
            )}
            onClick={() => (disabled ? null : sortCallback(sortField))}
          >
            {renderedItem}
          </th>
        );
      })}
      {hasRowActions && <th className={cn(styles.col, styles.colActions, rowActionsClassName)}>&nbsp;</th>}
    </tr>
  </thead>
);

TableHeader.propTypes = {
  columns: PropTypes.array,
  disabled: PropTypes.bool,
  hasBatchActions: PropTypes.bool,
  hasRowActions: PropTypes.bool,
  rowActionsClassName: PropTypes.string,
  onSelectAll: PropTypes.func,
  selectAllChecked: PropTypes.bool,
  sortAsc: PropTypes.bool,
  sortBy: PropTypes.string,
  sortCallback: PropTypes.func,
};

export default TableHeader;
