const messagesList = {
  FireMissionCallForFire: 'Fire Mission - Call For Fire'
};

export default {
  ...messagesList,
  _choice: 'Choice',
  Amplification: 'Amplification',
  Day: 'Day',
  ExerciseAdditionalIdentifier: 'Exercise Additional Identifier',
  ExerciseAdditionalNickname: 'Exercise Additional Nickname',
  ExerciseIdentification: 'Exercise Identification',
  ExerciseIdentifier: 'Exercise Identifier',
  ExerciseNickname: 'Exercise Nickname',
  FreeText: 'Free Text',
  HourTime: 'Hour Time',
  MessageIdentifier: 'Message Identifier',
  MinuteTime: 'Minute Time',
  MonthNumeric: 'Month Numeric',
  OperationCodeword: 'Operation Codeword',
  PlanOriginatorAndNumber: 'Plan Originator and Number',
  Reference: 'Reference',
  SecondTime: 'Second Time',
  TimeDelimiter: 'Time Delimiter',
  TimeZoneZulu: 'Time Zone Zulu',
  Year4Digit: 'Year, 4 Digit',
};
