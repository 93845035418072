import qs from 'qs'
import { DEFAULT_API_HOST } from '../constants'
import { ROUTES } from './useApiUrl'

export const getApiUrl = (slug, slugParams = {}, fullUrl = true) => {
  if (!Object.keys(ROUTES).includes(slug)) {
    throw new Error(`Invalid slug: ${slug}`)
  }

  const [url, searchParams] = Object.entries(slugParams).reduce(
    ([result, resultParams], [key, value]) => {
      const replacement = `{${key}}`
      const isSearchParam = result.indexOf(replacement) === -1
      if (isSearchParam) {
        return [result, { ...resultParams, [key]: value }]
      }
      return [result.replace(replacement, value), resultParams]
    },
    // @ts-ignore
    [ROUTES[slug], {}]
  )

  return `${fullUrl ? DEFAULT_API_HOST : ''}${url}?${qs.stringify(searchParams)}`
}

export default getApiUrl
