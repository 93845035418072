import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/react/macro';
import { Loader } from 'react-feather';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const LoadingIcon = styled(Loader)`
  transform-origin: center center;
  animation: ${spin} 1.5s linear infinite;
  color: ${({ color }) => color};
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  margin: ${({ margin }) => margin};
`;

export default function Loading({ color, size, margin }) {
  return (
    <LoadingWrapper>
      <LoadingIcon color={color} size={size} margin={margin} data-testid="loading-icon" />
    </LoadingWrapper>
  );
}

Loading.propTypes = {
  color: PropTypes.string,
  margin: PropTypes.string,
  size: PropTypes.string,
};

Loading.defaultProps = {
  color: '#192434',
  margin: '3rem',
  size: '24',
};
