import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from "moment/moment";
import { Button, Modal, Table } from "./index";
import { getApiUrl } from "../utils";
import useGetRequest from "../hooks/useGetRequest";
import { DATE_FORMAT, PAGE_SIZE, TIME_FORMAT } from "../constants";

const DialogVersions = ({ entityId, isOpen, onClose, onSelectVersion, routeSlug }) => {
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState(null);
  const apiUrl = getApiUrl(routeSlug, { entityId });
  const [loading, { data, meta }, refreshData] = useGetRequest(apiUrl, null, true);

  useEffect(() => {
    if (isOpen) {
      refreshData({ page })
    }
  }, [page, refreshData, isOpen])

  const onOpen = () => {
    onSelectVersion(selected);
    onClose();
  }

  const columns = [
    {
      key: 'createdBy.lastName',
      title: 'Ким оновлено',
      renderer: (props) => <>{props.value} {props.row.createdBy?.firstName} {props.row.createdBy?.middleName}</>,
    },
    {
      key: 'createdAt',
      title: 'Дата оновлення',
      renderer: ({ value }) => moment(value).format(`${DATE_FORMAT}`),
    },
    {
      key: 'createdAt',
      title: 'Час оновлення',
      renderer: ({ value }) => moment(value).format(`${TIME_FORMAT}`),
    },
    {
      key: 'id',
      renderer: (props) => (
        <input
          checked={selected === props.value}
          value={props.value}
          name="selectVersion"
          type="radio"
          onChange={(e) => setSelected(e.target.value)}
        />
      ),
    },
  ];

  return (
    <Modal
      isLoading={loading}
      isOpen={isOpen}
      title="Вибрати версію"
      width="800px"
      buttons={[
        {
          color: Button.COLOR.BLUE,
          label: 'Назад',
          onClick: onClose,
        },
        {
          label: 'Відкрити',
          onClick: () => onOpen(),
        },
      ]}
    >
      <Table
        columns={columns}
        changePageCallback={setPage}
        currentPage={page}
        data={{
          result: data,
          total: meta?.total,
        }}
        pageSize={PAGE_SIZE}
      />
    </Modal>
  );
}

DialogVersions.propTypes = {
  entityId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectVersion: PropTypes.func.isRequired,
  routeSlug: PropTypes.string.isRequired,
};

export default DialogVersions;
