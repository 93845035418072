import qs from 'qs'
import { DEFAULT_API_HOST } from '../constants'

export const ROUTES = {
  apps: '/api/apps',
  apps_archived: '/api/apps/archived',
  apps_entity: '/api/apps/{entityId}',
  apps_version: '/api/apps/version/{id}',
  apps_versions: '/api/apps/versions/{entityId}',
  apps_disable_exchange: '/api/apps/disable_exchange/',
  apps_rabbit_port: '/api/apps/rabbit-mq-port/',
  app_settings: '/api/app_settings',
  asku_sign_entity: '/api/asku/sign/{entityId}',
  asku_plant_missiles: '/api/asku/plant-missiles',
  asku_upload_to_asku: '/api/asku/upload-to-asku',
  asku_nnfp_fp: '/api/asku/nnfp-fp/{entityId}',
  asku_current_position: '/api/asku/current-position',
  asku_reference_points: '/api/asku/reference-points',
  auth_login: '/api/auth/login',
  auth_user: '/api/auth/user',
  auth_refresh: '/api/auth/refresh',
  frontend_errors: '/api/frontend_errors',
  user_login: '/api/login/{token}',
  user_get_logged_in_api: '/api/users',
  user_get_one_time_auth_token: '/api/user/me/one-time-auth-token',
  fuel_types: '/api/fuel_types',
  vehicles: '/api/vehicles',
  vehicles_archived: '/api/vehicles/archived',
  vehicles_entity: '/api/vehicles/{entityId}',
  vehicles_version: '/api/vehicles/version/{id}',
  vehicles_versions: '/api/vehicles/versions/{entityId}',
  vehicle_dictionaries: '/api/vehicle_dictionaries',
  vehicle_dictionaries_archived: '/api/vehicle_dictionaries/archived',
  vehicle_dictionaries_entity: '/api/vehicle_dictionaries/{entityId}',
  vehicle_dictionaries_version: '/api/vehicle_dictionaries/version/{id}',
  vehicle_dictionaries_versions: '/api/vehicle_dictionaries/versions/{entityId}',
  vehicle_types: '/api/vehicle_types',
  vehicle_types_archived: '/api/vehicle_types/archived',
  vehicle_types_entity: '/api/vehicle_types/{entityId}',
  vehicle_types_version: '/api/vehicle_types/version/{id}',
  vehicle_types_versions: '/api/vehicle_types/versions/{entityId}',
  rockets: '/api/rockets',
  rockets_archived: '/api/rockets/archived',
  rockets_entity: '/api/rockets/{entityId}',
  rockets_version: '/api/rockets/version/{id}',
  rockets_versions: '/api/rockets/versions/{entityId}',
  radii: '/api/radii',
  radii_archived: '/api/radii/archived',
  radii_entity: '/api/radii/{entityId}',
  radii_version: '/api/radii/version/{id}',
  radii_versions: '/api/radii/versions/{entityId}',
  rocket_types: '/api/rocket_types',
  rocket_types_archived: '/api/rocket_types/archived',
  rocket_types_entity: '/api/rocket_types/{entityId}',
  rocket_types_version: '/api/rocket_types/version/{id}',
  rocket_types_versions: '/api/rocket_types/versions/{entityId}',
  targets: '/api/targets',
  targets_entity: '/api/targets/{entityId}',
  executors: '/api/executors',
  executors_entity: '/api/executors/{entityId}',
  planner_calculation: '/api/planner/plan/{plan}',
  target_distribution_plans: '/api/target_distribution_plans',
  target_distribution_plans_entity: '/api/target_distribution_plans/{entityId}',
  target_distribution_plans_params: '/api/target_distribution_plans/params/{entityId}',
  target_distribution_plans_archived: '/api/target_distribution_plans/archived',
  target_distribution_plan_accesses: '/api/target_distribution_plan_accesses',
  target_distribution_plans_clone: '/api/target_distribution_plans/clone/{entityId}',
  target_distribution_plan_accesses_units: '/api/target_distribution_plan_accesses/accesses',
  target_distribution_plan_accesses_delete: '/api/target_distribution_plan_accesses/accesses/{entityId}/{unitId}',
  distribution_results: '/api/distribution_results',
  distribution_results_entity: '/api/distribution_results/{entityId}',
  users: '/api/users',
  users_archived: '/api/users/archived',
  users_entity: '/api/users/{entityId}',
  users_version: '/api/users/version/{id}',
  users_versions: '/api/users/versions/{entityId}',
  operation_groups: '/api/operation_groups',
  operation_groups_archived: '/api/operation_groups/archived',
  operation_groups_entity: '/api/operation_groups/{entityId}',
  operation_groups_version: '/api/operation_groups/version/{id}',
  operation_groups_versions: '/api/operation_groups/versions/{entityId}',
  operation_groups_hierarchy: '/api/operation_groups/hierarchy',
  operation_group_permissions: '/api/permissions/{entityId}',
  operation_group_permissions_add: '/api/permissions/{entityId}/add',
  operation_group_permissions_remove: '/api/permissions/{entityId}/remove',
  operation_group_person_on_duty: '/api/operation_groups/{entityId}/person-on-duty',
  objcts: '/api/objcts',
  objcts_archived: '/api/objcts/archived',
  objcts_entity: '/api/objcts/{entityId}',
  objcts_version: '/api/objcts/version/{id}',
  objcts_versions: '/api/objcts/versions/{entityId}',
  objct_accesses: '/api/objct_accesses',
  objct_accesses_units: '/api/objct_accesses/accesses',
  objct_accesses_delete: '/api/objct_accesses/accesses/{entityId}/{unitId}',
  object_types: '/api/object_types',
  object_types_archived: '/api/object_types/archived',
  object_types_entity: '/api/object_types/{entityId}',
  object_types_version: '/api/object_types/version/{id}',
  object_types_versions: '/api/object_types/versions/{entityId}',
  permissions: '/api/permissions',
  processes: '/api/processes',
  processes_version: '/api/processes/version/{id}',
  processes_versions: '/api/processes/versions/{entityId}',
  processes_entity: '/api/processes/{entityId}',
  processes_archived: '/api/processes/archived',
  processes_suspended: '/api/processes/{entityId}/suspended',
  processes_input: '/api/processes/input',
  processes_input_data: '/api/processes/input/{processId}',
  definitions: '/api/definitions',
  definitions_version: '/api/definitions/version/{id}',
  definitions_versions: '/api/definitions/versions/{entityId}',
  definitions_entity: '/api/definitions/{entityId}',
  definitions_deploy: '/api/definitions/{id}/deploy',
  definitions_start: '/api/definitions/{id}/start',
  definitions_archived: '/api/definitions/archived',
  definitions_templates: '/api/definitions/templates/{entityId}',
  definitions_purge_all: '/api/definitions/all',
  protections: '/api/protections',
  protections_archived: '/api/protections/archived',
  protections_entity: '/api/protections/{entityId}',
  protections_version: '/api/protections/version/{id}',
  protections_versions: '/api/protections/versions/{entityId}',
  positions: '/api/positions',
  positions_archived: '/api/positions/archived',
  positions_entity: '/api/positions/{entityId}',
  positions_version: '/api/positions/version/{id}',
  positions_versions: '/api/positions/versions/{entityId}',
  ranks: '/api/ranks',
  ranks_archived: '/api/ranks/archived',
  ranks_entity: '/api/ranks/{entityId}',
  ranks_version: '/api/ranks/version/{id}',
  ranks_versions: '/api/ranks/versions/{entityId}',
  rocket_notifications_unit: '/api/rocket_notifications/unit/{unitId}',
  rocket_notifications_read: '/api/rocket_notifications/{entityId}/read',
  target_designations: '/api/target_designations',
  target_designation_confirm: '/api/target_designations/confirm-execution/{entityId}',
  target_groups: '/api/target_groups',
  target_groups_archived: '/api/target_groups/archived',
  target_groups_entity: '/api/target_groups/{entityId}',
  target_groups_version: '/api/target_groups/version/{id}',
  target_groups_versions: '/api/target_groups/versions/{entityId}',
  units: '/api/units',
  units_entity: '/api/units/{entityId}',
  units_hierarchy: '/api/units/hierarchy',
  units_hierarchy_archived: '/api/units/hierarchy/archived',
  units_archive: '/api/units/{id}',
  units_versions: '/api/units/versions/{entityId}',
  units_actual_state: '/api/units/actual_state',
  utm_to_geodetic: '/api/planner/utm_to_geodetic',
  geodetic_to_utm: '/api/planner/geodetic_to_utm',
  zones: '/api/zones',
  zones_version: '/api/zones/version/{id}',
  zones_versions: '/api/zones/versions/{entityId}',
  zones_entity: '/api/zones/{entityId}',
  zones_archived: '/api/zones/archived',
  zones_is_point_allowed: '/api/planner/point_in_zone',
  zone_check: '/api/planner/zone_check',
  messages: '/api/messages',
  messages_list: '/api/messages/list',
  messages_amount: '/api/messages/amount',
  messages_entity: '/api/messages/{entityId}',
  messages_archived: '/api/messages/archived',
  messages_read: '/api/messages/{entityId}/read',
  messages_version: '/api/messages/version/{id}',
  messages_versions: '/api/messages/versions/{entityId}',
  messages_recipients: '/api/messages/recipients/{senderId}/{recipientId}',
  messages_templates: '/api/messages_templates',
  messages_template_entity: '/api/messages_templates/{entityId}',
  message_template_accesses: '/api/message_template_accesses',
  message_template_accesses_entity: '/api/message_template_accesses/{entityId}',
  message_print_fmc_entity: '/api/messages/print-fm-fmc/{entityId}',
  message_manual_order: '/api/messages/manual-order',
  notes: '/api/notes',
  notes_entity: '/api/notes/{entityId}',
  notes_archived: '/api/notes/archived',
  notes_version: '/api/notes/version/{id}',
  notes_versions: '/api/notes/versions/{entityId}',
  note_accesses: '/api/note_accesses',
  note_accesses_units: '/api/note_accesses/accesses',
  note_accesses_delete: '/api/note_accesses/accesses/{entityId}/{unitId}',
  threads: '/api/threads',
  threads_archived: '/api/threads/archived',
  threads_entity: '/api/threads/{entityId}',
  unit_working_modes: '/api/unit_working_modes',
  unit_working_modes_vehicle: '/api/unit_working_modes/vehicle/{vehicleId}',
  unit_working_modes_complex: '/api/unit_working_modes/complex/',
  workplace: '/api/workplace',
  workplace_unit: '/api/workplace/unit/{unitId}',
}

export const useApiUrl = (slug, slugParams = {}, fullUrl = true) => {
  if (!Object.keys(ROUTES).includes(slug)) {
    throw new Error('Invalid slug')
  }

  const [url, searchParams] = Object.entries(slugParams).reduce(
    ([result, resultParams], [key, value]) => {
      const replacement = `{${key}}`
      const isSearchParam = result.indexOf(replacement) === -1
      if (isSearchParam) {
        return [result, { ...resultParams, [key]: value }]
      }
      return [result.replace(replacement, value), resultParams]
    },
    // @ts-ignore
    [ROUTES[slug], {}]
  )

  return `${fullUrl ? DEFAULT_API_HOST : ''}${url}?${qs.stringify(searchParams)}`
}

export default useApiUrl
