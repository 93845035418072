import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { ChevronDown } from 'react-feather';
import Loading from '../../Loading';

const getDropdownIcon = (isLoading, dropdownIndicatorIcon) => {
  if (isLoading) return <Loading margin="0" size="1rem" />;

  return dropdownIndicatorIcon ?? <ChevronDown data-testid="chevron-down" size="20" />;
};

/**
 * This component is used to remove the default components of react-select.
 */
export const NullComponent = () => null;

/**
 * This component is rendered on the (dropdown) button of the Select component.
 *
 * See https://react-select.com/props#components
 */
export const DropdownIndicator = props => {
  const {
    selectProps: { isLoading, dropdownIndicator },
  } = props;

  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {getDropdownIcon(isLoading, dropdownIndicator.icon)}
      </components.DropdownIndicator>
    )
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    dropdownIndicator: PropTypes.shape({
      icon: PropTypes.node,
    }).isRequired,
    isLoading: PropTypes.bool,
  }).isRequired,
};
