import {useReducer, useMemo} from 'react';

const ACTIONS = {
  openDialog: 'openDialog',
  closeDialog: 'closeDialog',
}

const INITIAL_STATE = {
  batchData: {},
  isDialogOpen: false,
}

function dialogReducer(state, action) {
  switch (action.type) {
    case ACTIONS.openDialog:
      const {type, ...batchData} = action;
      return { ...state, batchData, isDialogOpen: true};
    case ACTIONS.closeDialog:
      return {...state, isDialogOpen: false, batchData: {}};
    default:
      throw new Error(`Unknown action: ${action.type}`)
  }
}

function dialogActions(dispatch) {
  return {
    handleDialogOpen: (selectedRows, deselectCallback) =>
      dispatch({ type: ACTIONS.openDialog, selectedRows, deselectCallback }),
    handleDialogClose: () => dispatch({ type: ACTIONS.closeDialog }),
  };
}

export default function useBatchDialog() {
  const [state, dispatch] = useReducer(dialogReducer, INITIAL_STATE);
  const actions = useMemo(() => dialogActions(dispatch), []);

  return useMemo(
    () => ({
      ...state,
      ...actions,
    }),
    [state, actions]
  );
}
