import React, { useState, useEffect, useRef } from 'react';
import styled from "@emotion/styled/macro";
import PropTypes from "prop-types";
import { ChevronDown, ChevronUp } from 'react-feather';
import { colors } from '../constants';

const Wrapper = styled.div`
  overflow: hidden;
  transition: height 0 ease-in-out;
  padding: 0;

  ${props => props.isOpen && `
    overflow: visible;
  `}
`;

const Content = styled.div`
  padding: 1rem 0 0 0;
`;

const Control = styled.div`
  align-items: center;
  background-color: #e6edf6;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 0.5rem;
  color: ${colors.label}
`;

const Collapse = ({ isOpen, openTitle, closeTitle, iconAfter, iconBefore, onChangeState, children }) => {
  const [contentHeight, setContentHeight] = useState(null)
  const contentRef = useRef(null)
  const [title, setTitle] = useState(null)

  const handleToggleCollapse = () => {
    const newState = contentHeight === 0 ? 'auto' : 0
    setContentHeight(newState)
    setTitle(newState ? openTitle : closeTitle)
    onChangeState && onChangeState(Boolean(newState))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setTitle(contentHeight ? closeTitle : openTitle), [contentHeight])
  useEffect(() => setContentHeight(isOpen ? 'auto' : 0), [isOpen])

  return (
    <>
      <Control onClick={handleToggleCollapse}>
        {!!contentHeight ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
        {iconBefore}
        {title}
        {iconAfter}
      </Control>

      <Wrapper style={{ height: contentHeight }} isOpen={!!contentHeight}>
        <Content ref={contentRef}>{children}</Content>
      </Wrapper>
    </>
  )
}

Collapse.propTypes = {
  closeTitle: PropTypes.string,
  isOpen: PropTypes.bool,
  iconAfter: PropTypes.element,
  iconBefore: PropTypes.element,
  openTitle: PropTypes.string,
  onChangeState: PropTypes.func | undefined,
}

Collapse.defaultProps = {
  closeTitle: 'Згорнути',
  isOpen: true,
  iconAfter: null,
  iconBefore: null,
  openTitle: 'Показати'
};

export default Collapse;
