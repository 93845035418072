import React, { lazy, useContext, Suspense } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ROUTE } from './constants';
import { Layout, Loading } from "./components";
import { authContext } from './contexts/AuthContext';
import Snackbar from './components/Snackbar';
import ErrorBoundary from './components/ErrorBoundary'

const Home = lazy(() => import('./pages/home/Home'));
const Login = lazy(() => import('./pages/login/Login'));

const ActualState = lazy(() => import('./pages/actualState/View'))

const AppSettingsEdit = lazy(() => import('./pages/appSettings/Edit'))
const AppsConfigList = lazy(() => import('./pages/appsConfig/List'))
const AppsConfigEdit = lazy(() => import('./pages/appsConfig/Edit'))

const Goals = lazy(() => import('./pages/goals/Goals'))
const GoalsEdit = lazy(() => import('./pages/goals/Edit'))
const GoalsTargetEdit = lazy(() => import('./pages/goalsTarget/Edit'))
const GoalsExecutorEdit = lazy(() => import('./pages/goalsExecutor/Edit'))

const DistributionResultView = lazy(() => import('./pages/distributions/View'))

const Vehicles = lazy(() => import('./pages/vehicles/List'))
const VehiclesEdit = lazy(() => import('./pages/vehicles/Edit'))
const VehicleTypes = lazy(() => import('./pages/vehicleTypes/List'))
const VehicleTypesEdit = lazy(() => import('./pages/vehicleTypes/Edit'))
const VehicleDictionaries = lazy(() => import('./pages/vehicleDictionaries/List'))
const VehicleDictionariesEdit = lazy(() => import('./pages/vehicleDictionaries/Edit'))

const Rockets = lazy(() => import('./pages/rockets/List'))
const RocketsEdit = lazy(() => import('./pages/rockets/Edit'))
const RocketRadii = lazy(() => import('./pages/rocketRadii/List'))
const RocketRadiiEdit = lazy(() => import('./pages/rocketRadii/Edit'))
const RocketTypes = lazy(() => import('./pages/rocketTypes/List'))
const RocketTypesEdit = lazy(() => import('./pages/rocketTypes/Edit'))

const Users = lazy(() => import('./pages/users/List'))
const UsersEdit = lazy(() => import('./pages/users/Edit'))

const UnitsTree = lazy(() => import('./pages/units/Tree'))
const UnitsActualTree = lazy(() => import('./pages/units/ActualTree'))
const UnitsWorkingMode = lazy(() => import('./pages/units/WorkingMode'))

const Positions = lazy(() => import('./pages/positions/List'))
const PositionsEdit = lazy(() => import('./pages/positions/Edit'))
const PositionsPermissions = lazy(() => import('./pages/positions/Permissions'))
const Ranks = lazy(() => import('./pages/ranks/List'))
const RanksEdit = lazy(() => import('./pages/ranks/Edit'))

const OperationGroupsTree = lazy(() => import('./pages/operationGroups/Tree'))
const OperationGroups = lazy(() => import('./pages/operationGroups/List'))
const OperationGroupsEdit = lazy(() => import('./pages/operationGroups/Edit'))
const OperationGroupsPermissions = lazy(() => import('./pages/operationGroups/Permissions'))

const Objcts = lazy(() => import('./pages/objcts/List'))
const ObjctsEdit = lazy(() => import('./pages/objcts/Edit'))
const ObjectTypes = lazy(() => import('./pages/objectTypes/List'))
const ObjectTypesEdit = lazy(() => import('./pages/objectTypes/Edit'))
const TargetGroups = lazy(() => import('./pages/targetGroups/List'))
const TargetGroupsEdit = lazy(() => import('./pages/targetGroups/Edit'))
const Protections = lazy(() => import('./pages/protections/List'))
const ProtectionsEdit = lazy(() => import('./pages/protections/Edit'))

const Zones = lazy(() => import('./pages/zones/List'))
const ZoneEdit = lazy(() => import('./pages/zones/Edit'))

const Processes = lazy(() => import('./pages/processes/List'))
const ProcessesEdit = lazy(() => import('./pages/processes/Edit'))
const ProcessDefinitions = lazy(() => import('./pages/processDefinitions/List'))
const ProcessDefinitionEdit = lazy(() => import('./pages/processDefinitions/Edit'))
const ProcessDefinitionTemplates = lazy(() => import('./pages/processDefinitionsTemplates/CheckList'))

const MessagesEdit = lazy(() => import('./pages/messages/Edit'))
const Messages = lazy(() => import('./pages/messages/List'))
const Threads = lazy(() => import('./pages/messages/Threads'))
const InputPdfToLaunch = lazy(() => import('./pages/messages/InputPdfToLaunch'))
const NotesList = lazy(() => import('./pages/messages/notes/List'))
const NotesEdit = lazy(() => import('./pages/messages/notes/Edit'))

const References = lazy(() => import('./pages/references/List'))

function App() {
  const navigate = useNavigate()
  const { auth } = useContext(authContext)

  React.useEffect(() => {
    if (!auth.data) {
      navigate(ROUTE.login)
    }
  }, [auth.data, navigate])

  return (
    <ErrorBoundary>
      <Layout>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path={ROUTE.home} element={<Home />} />
            <Route path={ROUTE.login} element={<Login />} />

            <Route path={ROUTE.actual.root} element={<ActualState />} />

            <Route path={ROUTE.appSettings.root} element={<AppSettingsEdit />} />

            <Route path={ROUTE.appsConfig.root} element={<AppsConfigList />} />
            <Route path={ROUTE.appsConfig.add} element={<AppsConfigEdit />} />
            <Route path={ROUTE.appsConfig.edit()} element={<AppsConfigEdit />} />
            <Route path={ROUTE.appsConfig.version()} element={<AppsConfigEdit isVersion />} />

            <Route path={ROUTE.vehicles.root} element={<Vehicles />} />
            <Route path={ROUTE.vehicles.add} element={<VehiclesEdit />} />
            <Route path={ROUTE.vehicles.edit()} element={<VehiclesEdit />} />
            <Route path={ROUTE.vehicles.version()} element={<VehiclesEdit isVersion />} />
            <Route path={ROUTE.vehicles.dictionaries.list} element={<VehicleDictionaries />} />
            <Route path={ROUTE.vehicles.dictionaries.add} element={<VehicleDictionariesEdit />} />
            <Route path={ROUTE.vehicles.dictionaries.edit()} element={<VehicleDictionariesEdit />} />
            <Route path={ROUTE.vehicles.dictionaries.version()} element={<VehicleDictionariesEdit isVersion />} />
            <Route path={ROUTE.vehicles.types.list} element={<VehicleTypes />} />
            <Route path={ROUTE.vehicles.types.add} element={<VehicleTypesEdit />} />
            <Route path={ROUTE.vehicles.types.edit()} element={<VehicleTypesEdit />} />
            <Route path={ROUTE.vehicles.types.version()} element={<VehicleTypesEdit isVersion />} />

            <Route path={ROUTE.rockets.root} element={<Rockets />} />
            <Route path={ROUTE.rockets.add} element={<RocketsEdit />} />
            <Route path={ROUTE.rockets.edit()} element={<RocketsEdit />} />
            <Route path={ROUTE.rockets.version()} element={<RocketsEdit isVersion />} />
            <Route path={ROUTE.rockets.radii.list} element={<RocketRadii />} />
            <Route path={ROUTE.rockets.radii.add} element={<RocketRadiiEdit />} />
            <Route path={ROUTE.rockets.radii.edit()} element={<RocketRadiiEdit />} />
            <Route path={ROUTE.rockets.radii.version()} element={<RocketRadiiEdit isVersion />} />
            <Route path={ROUTE.rockets.types.list} element={<RocketTypes />} />
            <Route path={ROUTE.rockets.types.add} element={<RocketTypesEdit />} />
            <Route path={ROUTE.rockets.types.edit()} element={<RocketTypesEdit />} />
            <Route path={ROUTE.rockets.types.version()} element={<RocketTypesEdit isVersion />} />

            <Route path={ROUTE.goals.root} element={<Goals />} />
            <Route path={ROUTE.goals.add} element={<GoalsEdit />} />
            <Route path={ROUTE.goals.edit()} element={<GoalsEdit />} />
            <Route path={ROUTE.goals.version()} element={<GoalsEdit isVersion />} />
            <Route path={ROUTE.goals.targets.add()} element={<GoalsTargetEdit />} />
            <Route path={ROUTE.goals.executors.add()} element={<GoalsExecutorEdit />} />
            <Route path={ROUTE.distributions.view()} element={<DistributionResultView />} />

            <Route path={ROUTE.goalsAuto.root} element={<Goals isAutonomous />} />
            <Route path={ROUTE.goalsAuto.add} element={<GoalsEdit isAutonomous />} />
            <Route path={ROUTE.goalsAuto.edit()} element={<GoalsEdit isAutonomous />} />
            <Route path={ROUTE.goalsAuto.version()} element={<GoalsEdit isAutonomous isVersion />} />
            <Route path={ROUTE.goalsAuto.targets.add()} element={<GoalsTargetEdit isAutonomous />} />
            <Route path={ROUTE.goalsAuto.executors.add()} element={<GoalsExecutorEdit isAutonomous />} />
            <Route path={ROUTE.distributionsAuto.view()} element={<DistributionResultView isAutonomous />} />

            <Route path={ROUTE.users.list} element={<Users />} />
            <Route path={ROUTE.users.add} element={<UsersEdit />} />
            <Route path={ROUTE.users.edit()} element={<UsersEdit />} />
            <Route path={ROUTE.users.version()} element={<UsersEdit isVersion />} />

            <Route path={ROUTE.units.tree} element={<UnitsTree />} />
            <Route path={ROUTE.units.workingMode()} element={<UnitsWorkingMode />} />
            <Route path={ROUTE.units.actualTree} element={<UnitsActualTree />} />

            <Route path={ROUTE.positions.list} element={<Positions />} />
            <Route path={ROUTE.positions.add} element={<PositionsEdit />} />
            <Route path={ROUTE.positions.edit()} element={<PositionsEdit />} />
            <Route path={ROUTE.positions.permissions()} element={<PositionsPermissions />} />
            <Route path={ROUTE.positions.version()} element={<PositionsEdit isVersion />} />

            <Route path={ROUTE.ranks.list} element={<Ranks />} />
            <Route path={ROUTE.ranks.add} element={<RanksEdit />} />
            <Route path={ROUTE.ranks.edit()} element={<RanksEdit />} />
            <Route path={ROUTE.ranks.version()} element={<RanksEdit isVersion />} />

            <Route path={ROUTE.operationGroups.tree} element={<OperationGroupsTree />} />
            <Route path={ROUTE.operationGroups.add} element={<OperationGroupsEdit />} />
            <Route path={ROUTE.operationGroups.list} element={<OperationGroups />} />
            <Route path={ROUTE.operationGroups.edit()} element={<OperationGroupsEdit />} />
            <Route path={ROUTE.operationGroups.permissions()} element={<OperationGroupsPermissions />} />
            <Route path={ROUTE.operationGroups.version()} element={<OperationGroupsEdit isVersion />} />

            <Route path={ROUTE.objcts.list} element={<Objcts />} />
            <Route path={ROUTE.objcts.add} element={<ObjctsEdit />} />
            <Route path={ROUTE.objcts.edit()} element={<ObjctsEdit />} />
            <Route path={ROUTE.objcts.version()} element={<ObjctsEdit isVersion />} />
            <Route path={ROUTE.objectTypes.list} element={<ObjectTypes />} />
            <Route path={ROUTE.objectTypes.add} element={<ObjectTypesEdit />} />
            <Route path={ROUTE.objectTypes.edit()} element={<ObjectTypesEdit />} />
            <Route path={ROUTE.objectTypes.version()} element={<ObjectTypesEdit isVersion />} />
            <Route path={ROUTE.targetGroups.list} element={<TargetGroups />} />
            <Route path={ROUTE.targetGroups.add} element={<TargetGroupsEdit />} />
            <Route path={ROUTE.targetGroups.edit()} element={<TargetGroupsEdit />} />
            <Route path={ROUTE.targetGroups.version()} element={<TargetGroupsEdit isVersion />} />
            <Route path={ROUTE.protections.list} element={<Protections />} />
            <Route path={ROUTE.protections.add} element={<ProtectionsEdit />} />
            <Route path={ROUTE.protections.edit()} element={<ProtectionsEdit />} />
            <Route path={ROUTE.protections.version()} element={<ProtectionsEdit isVersion />} />

            <Route path={ROUTE.zones.root} element={<Zones />} />
            <Route path={ROUTE.zones.add} element={<ZoneEdit />} />
            <Route path={ROUTE.zones.edit()} element={<ZoneEdit />} />
            <Route path={ROUTE.zones.version()} element={<ZoneEdit isVersion />} />

            <Route path={ROUTE.processes.root} element={<Processes />} />
            <Route path={ROUTE.processes.add} element={<ProcessesEdit />} />
            <Route path={ROUTE.processes.edit()} element={<ProcessesEdit />} />
            <Route path={ROUTE.processes.version()} element={<ProcessesEdit isVersion />} />
            <Route path={ROUTE.processes.definitions.list} element={<ProcessDefinitions />} />
            <Route path={ROUTE.processes.definitions.add} element={<ProcessDefinitionEdit />} />
            <Route path={ROUTE.processes.definitions.edit()} element={<ProcessDefinitionEdit />} />
            <Route path={ROUTE.processes.definitions.version()} element={<ProcessDefinitionEdit isVersion />} />
            <Route path={ROUTE.processes.definitions.templates()} element={<ProcessDefinitionTemplates />} />

            <Route path={ROUTE.messages.root} element={<Messages isSent />} />
            <Route path={ROUTE.messages.sent} element={<Messages />} />
            <Route path={ROUTE.messages.draft} element={<Messages isDraft />} />
            <Route path={ROUTE.messages.threads} element={<Threads />} />
            <Route path={ROUTE.messages.threadsAttention} element={<Threads toAttention />} />
            <Route path={ROUTE.messages.add} element={<MessagesEdit />} />
            <Route path={ROUTE.messages.edit()} element={<MessagesEdit />} />
            <Route path={ROUTE.messages.version()} element={<MessagesEdit isVersion />} />
            <Route path={ROUTE.messages.pdfToLaunch} element={<InputPdfToLaunch />} />
            <Route path={ROUTE.messages.notes.list} element={<NotesList />} />
            <Route path={ROUTE.messages.notes.add} element={<NotesEdit />} />
            <Route path={ROUTE.messages.notes.edit()} element={<NotesEdit />} />
            <Route path={ROUTE.messages.notes.version()} element={<NotesEdit isVersion />} />

            <Route path={ROUTE.references.root} element={<References />} />
          </Routes>
        </Suspense>
      </Layout>
      <Snackbar />
    </ErrorBoundary>
  )
}

export default App;
