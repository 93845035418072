import { baseInputStyles } from '../../Input';
import { DEFAULT_FONT_SIZE } from '../../../constants';
import { throttle } from '../../../utils';
import { css } from '@emotion/react/macro';
import { DropdownIndicator, NullComponent } from './ReactSelectComponents';
import { colors } from '../../../constants'

/**
 * The number of milliseconds to throttle invocations to.
 * See: https://lodash.com/docs/#throttle
 */
const THROTTLE_TIME_OUT = 500;

/**
 * Returns the correct `color` var based on props
 * @param {boolean} hasError - component prop
 * @param {boolean} isDisabled - component prop
 * @return {string}
 */
const textColor = ({ hasError, isDisabled } = {}) => {
  if (hasError) {
    return '#E21F14';
  }
  if (isDisabled) {
    return '#59595A';
  }
  return 'inherit';
};

/**
 * React Select style objects
 */
// istanbul ignore next (skip react-select styles)
const reactSelectStyles = {
  container: () => ({
    position: 'relative',
  }),

  control: (provided, state) => ({
    ...provided,
    ...css`
      position: relative;
      ${baseInputStyles(state?.selectProps)}
      background-color: ${colors.grayDarkBg};
      border-radius: 4px;
      ${state.isDisabled &&
      `
        border: 1px dashed rgba(28, 52, 84, 0.26);
        background-color: transparent;
        cursor: not-allowed;
      `}
      ${state.isFocused &&
      `
        border-color: #F3B032;
      `}
      ${state.selectProps.isInline &&
      `
        border-bottom: 0;
        color: #59595A;
      `}
      ${state.selectProps.hasError &&
      `
        background-color: ${colors.errorBg};
        border-color: #E21F14;
      `}
      ${state.selectProps.customStyles?.control}
    `,
  }),

  dropdownIndicator: () => ({
    height: '1em',
    width: '1em',
    color: colors.label
  }),

  group: provided => ({
    ...provided,
    lineHeight: '1.2rem',
    textIndent: '1rem',
  }),

  // For styles with grouping/nested options
  groupHeading: provided => ({
    ...provided,
    color: '#59595A',
    textIndent: '0',
    textTransform: 'none',
  }),

  indicatorsContainer: provided => ({
    ...provided,
    bottom: '30%',
    position: 'absolute',
    right: '10px',
  }),

  input: (provided, { selectProps }) => ({
    ...provided,

    '& input': {
      marginBottom: '0',
      position: 'relative',
    },
    border: '0',
    borderRadius: '0',
    boxShadow: 'none',
    color: textColor(selectProps),
    margin: '0',
    padding: '0',
  }),

  menu: (provided, { selectProps }) => ({
    ...provided,
    backgroundColor: '#FFFFFF',
    borderRadius: '0.3125rem',
    color: '#59595A',
    margin: 0,
    zIndex: 9999,
    ...selectProps.customStyles?.menu,
  }),

  menuList: (provided, { selectProps }) => ({
    ...provided,
    ...selectProps.customStyles?.menuList,
  }),

  option: (provided, state) => ({
    ...provided,

    '&:hover': {
      background: '#FAFBFC',
      cursor: 'pointer',
    },
    alignItems: 'center',
    background: '#FFFFFF',
    color: state.isFocused ? '#252525' : 'inherit',
    display: 'flex',
    ...(state.selectProps?.customStyles?.option instanceof Function
      ? state.selectProps?.customStyles?.option(state)
      : state.selectProps?.customStyles?.option),
  }),

  placeholder: (provided, { selectProps }) => ({
    ...provided,
    ...selectProps.customStyles?.placeholder,
  }),

  singleValue: (provided, { selectProps }) => ({
    ...provided,
    color: colors.text,
    margin: 0,
    maxWidth: `calc(100% - ${DEFAULT_FONT_SIZE})`,
    top: selectProps?.hasLabel ? '50%' : '23%',
    ...selectProps.customStyles?.singleValue,
  }),

  valueContainer: provided => ({
    ...provided,
    lineHeight: 'normal',
    top: '25%',
    padding: 0,
  }),
};

/**
 * Returns an object to be used as props for the react-select component.
 *
 * @see
 * https://react-select.com/props
 *
 * @param {object} params - Object with the parameters below
 * @param {object} params.customStyles - An object with a similar structure to https://react-select.com/styles
 * @param {object} params.dropdownIndicator - Object passed to the DropdownIndicator component
 * @param {boolean} params.hasError - Whether the component has one or more errors
 * @param {boolean} params.hasLabel - Whether the component has a related Label element
 * @param {string} params.inputId - ID of the input field
 * @param {boolean} params.isDisabled - Whether the component is disabled
 * @param {boolean} params.isMenuOpen - Whether the menu is open
 * @param {boolean} params.isMulti - Whether the component supports multiple selected options
 * @param {string} params.name - Name of the react-select HTML Input (optional: without this, no input will be rendered)
 * @param {function} params.onBlur - Handle blur events on the control
 * @param {function} params.onChange - Handle change events on the select
 * @param {function} params.onFocus - Handle focus events on the control
 * @param {function} params.onMenuOpen - Handle the menu opening
 * @param {object[]} params.options - Array of options that populate the select menu
 * @param {object} params.placeholder - Component to be displayed in the input when nothing is selected
 * @param {object[]} params.searchHandler - [Async] Callback used either when the component is created or when the user
 *                                          types on the component
 * @param {boolean} params.searchImmediately - [Async] True if searchHandler should be executed when the component is created
 *                                             False if it should be executed when the user types on the component
 * @param {object[]} params.searchInitialOptions - [Async] Optional default set of options to show before the user starts searching
 * @param {object | array} params.selectedOption - The current selected option(s)
 */
export default function getSelectProps({
  autoFocus,
  customStyles,
  dropdownIndicator,
  formatCreateLabel,
  hasError,
  hasLabel,
  inputId,
  isCreatable,
  isDisabled,
  isInline,
  isLoading,
  isMenuOpen,
  isMulti,
  name,
  onBlur,
  onChange,
  onCreateOption,
  onFocus,
  onInputChange,
  onMenuOpen,
  options,
  placeholder,
  searchHandler,
  searchImmediately,
  searchInitialOptions,
  selectedOption,
  ref,
  menuPosition,
}) {
  const handleChange = throttle((option, action) => {
    onChange({ action, option, selectName: name });
  }, THROTTLE_TIME_OUT);

  // Set defaultOptions, which is the default set of options to show when the
  // menu is open before the user starts searching.
  // If set to true, the component executes the loadOptions function instead.
  // If set to an array, the array is used as the default set of options.
  // If set to false, the component shows no options.
  const defaultOptions = searchImmediately || searchInitialOptions || false;

  return {
    autoFocus,
    backspaceRemovesValue: false,
    cacheOptions: true,
    components: {
      DropdownIndicator,
      LoadingIndicator: NullComponent,
      MultiValue: NullComponent,
    },
    customStyles,
    // The default set of options to show before the user starts searching.
    // If set to true, the components executes the loadOptions function instead.
    defaultOptions,
    dropdownIndicator,
    formatCreateLabel,
    hasError,
    hasLabel,
    inputId,
    isClearable: false,
    isDisabled,
    isInline,
    isLoading,
    isMulti,
    isValidNewOption: () => isCreatable,
    loadOptions: searchHandler,
    onBlur,
    onChange: handleChange,
    onCreateOption,
    onFocus,
    onInputChange,
    onMenuOpen,
    options,
    placeholder,
    ref,
    styles: reactSelectStyles,
    value: selectedOption,
    menuPosition,
    // If isMenuOpen is not null, add it to the returned object
    ...(isMenuOpen !== null && { menuIsOpen: isMenuOpen }),
    ...(!!name && { name }),
  };
}
