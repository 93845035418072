import { useState, useEffect, useContext } from 'react'
import { Button, Modal, Select, getSelectedOption } from '../../components'
import styled from '@emotion/styled/macro'
import { authContext } from '../../contexts/AuthContext'
import { interfaceContext } from '../../contexts/InterfaceContext'
import { httpRequests, getApiUrl } from '../../utils'
import {
  SnackbarStatus,
  workModeOptions,
  readinessDegreeOptions,
  readinessDegreeOptionsDepend,
  VehicleTypeDetails,
} from '../../constants'
import { useSnackbar } from '../../contexts/SnackbarContext'

const STATUSES_SPELLING = {
  installed: 'встановлений',
  removed: 'знятий',
  launched: 'запущений',
}

const RocketsChangedWrapper = styled.div`
  bottom: 3px;
  font-size: 14px;
  color: #ffc20b;
  left: 24px;
  border: 1px solid #ffc20b66;
  border-radius: 6px;
  text-align: center;
  line-height: 1.2;
  font-weight: bold;
  padding: 0.25rem;
  margin: 0.75rem;
  font-weight: normal;
  box-shadow: 0px 0px 6px #bab5a491;
  cursor: pointer;
  &:hover {
    color: #ffffff;
    border: 1px solid #ffffff;
  }
`

const VehicleStateChangedWrapper = styled.div`
  bottom: 3px;
  font-size: 14px;
  color: #ffc20b;
  left: 24px;
  border: 1px solid #ffc20b66;
  border-radius: 6px;
  text-align: center;
  line-height: 1.2;
  font-weight: bold;
  padding: 0.25rem;
  margin: 0.75rem;
  font-weight: normal;
  box-shadow: 0px 0px 6px #bab5a491;
  cursor: pointer;
  &:hover {
    color: #ffffff;
    border: 1px solid #ffffff;
  }
  label {
    text-align: center;
    width: 100%;
    color: #ffc20b;
  }
  select {
    font-size: 16px;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    background-color: rgb(34, 51, 72);
    color: #fff;
    margin-top: 4px;
  }
`

const MenuUrgentInfo = ({ intervalSeconds = 5 * 60 }) => {
  const { auth } = useContext(authContext)
  const { interfaceSettings } = useContext(interfaceContext)
  const { showSnackbar } = useSnackbar()
  const apiRocketNotifyByUnitEntityUrl = getApiUrl('workplace_unit', { unitId: auth?.data?.unitId || '' })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [data, setData] = useState([])
  const [hasReadinessDegree, setHasReadinessDegree] = useState(false)
  const [hasWorkMode, setHasWorkMode] = useState(false)
  const [hasChangedReadiness, setHasChangedReadiness] = useState(false)
  const [hasChangedWorkingMode, setHasChangedWorkingMode] = useState(false)
  const [targetReadiness, setTargetReadiness] = useState('')
  const [targetWorkingMode, setTargetWorkingMode] = useState('')
  const [actualReadiness, setActualReadiness] = useState('')
  const [actualWorkingMode, setActualWorkingMode] = useState('')

  const requestUrgentInfo = async () => {
    try {
      const response = await httpRequests.get(apiRocketNotifyByUnitEntityUrl)
      if (response.status === 200 && response.data) {
        setData(response.data)
        setHasReadinessDegree(false)
        setHasChangedReadiness(false)
        setHasChangedReadiness(false)
        setHasChangedWorkingMode(false)
        setTargetReadiness('')
        setTargetWorkingMode('')
        setActualReadiness('')
        setActualWorkingMode('')

        if (response.data?.app_settings.vehicle_id) {
          for (const wm of response.data?.unit_working_modes) {
            if (wm.vehicle_id === response.data?.app_settings.vehicle_id) {
              const hasReadinessDegree =
                VehicleTypeDetails[wm.vehicle?.vehicle_dictionary?.vehicle_type?.type]?.readinessDegree || false
              const hasWorkMode =
                VehicleTypeDetails[wm.vehicle?.vehicle_dictionary?.vehicle_type?.type]?.workMode || false
              setHasReadinessDegree(hasReadinessDegree)
              setHasWorkMode(hasWorkMode)
              setTargetReadiness(wm.readiness_degree)
              setTargetWorkingMode(wm.work_mode)
              setActualReadiness(wm.actual_readiness_degree)
              setActualWorkingMode(wm.actual_work_mode)
              setHasChangedReadiness(hasReadinessDegree && wm.readiness_degree !== wm.actual_readiness_degree)
              setHasChangedWorkingMode(hasWorkMode && wm.work_mode !== wm.actual_work_mode)
              break
            }
          }
        }
      } else {
        throw new Error('Помилка запиту даних')
      }
    } catch (e) {
      console.error(e.message)
    }
  }

  useEffect(() => {
    let handlerInterval = null
    if (auth?.data?.unitId) {
      requestUrgentInfo()
      if (intervalSeconds) {
        handlerInterval = setInterval(async () => {
          await requestUrgentInfo()
        }, intervalSeconds * 1000)
      }
    }
    return () => {
      clearInterval(handlerInterval)
    }
  }, [intervalSeconds, apiRocketNotifyByUnitEntityUrl, auth?.data?.unitId])

  const getAvailableOptions = () => {
    if (hasWorkMode) {
      return workModeOptions
    }
    if (hasReadinessDegree) {
      if (actualReadiness) {
        const tmpList = [...readinessDegreeOptionsDepend[actualReadiness]]
        if (!tmpList.find((r) => r.value === actualReadiness)) {
          tmpList.push({ label: actualReadiness, value: actualReadiness })
        }
        return tmpList
      } else {
        return readinessDegreeOptions
      }
    }
    return []
  }

  const setSendConfirmation = async () => {
    try {
      for (const r of data.rocket_notifications) {
        const apiReadNotifyByEntityUrl = getApiUrl('rocket_notifications_read', { entityId: r.entity_id })
        const response = await httpRequests.patch(apiReadNotifyByEntityUrl)
        if (![200, 201].includes(response.status)) {
          throw new Error('Помилка оновлення даних')
        }
      }
      setData([])
    } catch (e) {
      console.error(e.message)
    } finally {
      setIsModalOpen(false)
    }
  }

  const assignReadinessAndWorkMode = async (newStatus) => {
    if (hasWorkMode) {
      await saveChangedStates({
        work_mode: newStatus,
      })
      setActualWorkingMode(newStatus)
    } else if (hasReadinessDegree) {
      await saveChangedStates({
        readiness_degree: newStatus,
      })
      setActualReadiness(newStatus)
    }
  }

  const saveChangedStates = async (params) => {
    const response = await httpRequests.put(
      getApiUrl('unit_working_modes_vehicle', { vehicleId: data?.app_settings?.vehicle_id }),
      params
    )
    if ([200, 201].includes(response.status)) {
      if (hasWorkMode) {
        setTargetWorkingMode(response.data.data.work_mode)
        setActualWorkingMode(response.data.data.actual_work_mode)
        setHasChangedWorkingMode(response.data.data.work_mode !== response.data.data.actual_work_mode)
      }
      if (hasReadinessDegree) {
        setTargetReadiness(response.data.data.readiness_degree)
        setActualReadiness(response.data.data.actual_readiness_degree)
        setHasChangedReadiness(response.data.data.readiness_degree !== response.data.data.actual_readiness_degree)
      }
      showSnackbar({
        message: 'Статус змінено успішно',
        status: SnackbarStatus.success,
      })
    } else {
      showSnackbar({
        message: 'Помилка зміни статусу',
        status: SnackbarStatus.error,
      })
    }
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        title="Зміни засобів ураження"
        onClose={() => setIsModalOpen(false)}
        dismissable={false}
        buttons={[
          {
            color: Button.COLOR.BLUE,
            label: 'Ознайомлений',
            onClick: () => {
              setSendConfirmation()
            },
          },
        ]}
        width={'550px'}>
        <table cellpadding="5" border="1" style={{ borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th>Номер СПУ</th>
              <th>Подія</th>
              <th>Заводський номер</th>
              <th>Тип БЧ</th>
            </tr>
          </thead>
          <tbody>
            {data?.rocket_notifications?.map((r) => {
              return (
                <tr key={r.entity_id}>
                  <td>{r.vehicle.number}</td>
                  <td>{STATUSES_SPELLING[r.event].toUpperCase()}</td>
                  <td>{r.rocket?.factory_number}</td>
                  <td>{r.rocket?.rocket_type?.warhead}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Modal>
      {data?.rocket_notifications?.length > 0 ? (
        <RocketsChangedWrapper onClick={() => setIsModalOpen(true)}>
          {interfaceSettings.simplified ? 'Р' : 'Зміна ракет на СПУ'}
        </RocketsChangedWrapper>
      ) : (
        ''
      )}
      {hasReadinessDegree || hasWorkMode ? (
        <VehicleStateChangedWrapper>
          {interfaceSettings.simplified ? (
            <span>З</span>
          ) : (
            auth?.data?.position?.isCommander && (
              <>
                <label>{hasWorkMode ? `Режим ⇒ ${targetWorkingMode}` : `Готовність ⇒ ${targetReadiness}`}</label>
                <select
                  className="inline_vehicle_status_input"
                  value={hasReadinessDegree ? actualReadiness : actualWorkingMode}
                  onChange={(event) => {
                    assignReadinessAndWorkMode(event.target.value)
                  }}>
                  {getAvailableOptions().map((r) => (
                    <option key={r.value} value={r.value} style={{ backgroundColor: '#223348' }}>
                      {r.label}
                    </option>
                  ))}
                </select>
              </>
            )
          )}
        </VehicleStateChangedWrapper>
      ) : (
        ''
      )}
    </>
  )
}

export default MenuUrgentInfo
