import React from "react";
import { Input } from "./index";
import { Controller } from "react-hook-form";
import { FORM_FIELD_MAX_WIDTH } from "../constants";

export default function ControllerInput({
  control,
  error,
  isReadOnly,
  label,
  name,
  hardLimit = 255,
  maxWidth = FORM_FIELD_MAX_WIDTH,
  type = 'string',
  disabled = false,
  onKeyDown,
  maxDecimals = null,
  min = null,
  max = null,
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Input
          errorMessage={error}
          isReadOnly={isReadOnly}
          hardLimit={hardLimit}
          label={label}
          maxWidth={maxWidth}
          type={type}
          disabled={disabled}
          onKeyDown={onKeyDown}
          maxDecimals={maxDecimals}
          min={min}
          max={max}
          {...field}
        />
      )}
    />
  )
}
