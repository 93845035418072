import { httpRequests, getApiUrl } from './index'

export const ERROR_CATEGORY = {
  AUTH: 'auth',
  COMMON: 'common',
  RENDER: 'render',
  ASKU: 'asku',
}

const saveFrontendError = (errorName = 'noname', errorContent = '', errorCategory = ERROR_CATEGORY.COMMON) => {
  const params = {
    error: JSON.stringify({
      name: errorName,
      description: errorContent,
      category: errorCategory,
    }),
  }
  try {
    httpRequests.post(getApiUrl('frontend_errors'), params)
  } catch (e) {
    console.error(e)
  }
}

export default saveFrontendError
