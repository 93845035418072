import React, { useCallback, useEffect, useState } from 'react';
import { Button, getWrappedHierarchy, HierarchyEditor, Modal } from "./index";
import { asCamelCase, getApiUrl, useApiUrl } from "../utils";
import useSaveRequest from "../hooks/useSaveRequest";
import useGetRequest from "../hooks/useGetRequest";

const DialogTreeAccess = (
  {
    batchData,
    isOpen,
    onClose,
    routeSlug,
    selectedSaveToField,
    accessObjectField = 'operation_group_id',
    hierarchyUrl = 'operation_groups_hierarchy'
  }
) => {
  const [hierarchyData, setHierarchyData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [accessesUnits, setAccessesUnits] = useState({});
  const [rebuilding, setRebuilding] = useState(false);
  const selectedItems = {};

  const [removing, removeRequest] = useSaveRequest('delete');

  const defaultSelected = (data, accesses, parent = {}) => {
    data.selected = false;
    data.selectedPartial = false;
    if (data.entityId && accesses[data.entityId]) {
      parent.expanded = true;
      data.selected = true;
      data.selectedPartial = Object.keys(batchData.selectedRows).length > accesses[data.entityId].length;
    }
    if (data.children.length) {
      data.children.map(child => defaultSelected(child, accesses, data));
    }
  };

  const [loadingAccesses, getAccesses] = useSaveRequest(
    'post',
    useApiUrl(`${routeSlug}_units`),
    useCallback((data) => {
      setRebuilding(true);
      setAccessesUnits(data);
    }, []),
    null,
    '',
    true,
    true
  )

  useEffect(() => {
    if (hierarchyData && hierarchyData.children && accessesUnits) {
      defaultSelected(hierarchyData, accessesUnits);
      setRebuilding(false);
    }
  }, [hierarchyData, accessesUnits]);

  useEffect(() => {
    if (batchData && batchData.selectedRows) {
      getAccesses({
        IDs: Object.keys(batchData.selectedRows).map(item => batchData.selectedRows[item].entityId)
      });
    }
  }, [batchData]);

  const [loading] = useGetRequest(
    useApiUrl(hierarchyUrl),
    useCallback((data) => setHierarchyData(getWrappedHierarchy(asCamelCase(data))), [])
  )

  const [saving, saveRequest] = useSaveRequest('post', null, null, null, 'Надано доступ');

  const resetSelected = data => {
    data.selected = false;
    if (data?.children.length) {
      data.children.map(child => resetSelected(child));
    }
  };

  const proceedChildren = (selected, parent) => {
    selectedItems[parent.entityId] = parent;
    if (parent.children.length) {
      parent.children.map(item => proceedChildren(selected, item));
    } else if (!parent.selectedPartial) {
      console.log('adding',parent);
      const variables = { isArchieved: false };
      variables[accessObjectField] = parent.entityId;
      variables[selectedSaveToField] = selected.entityId;
      saveRequest(variables, getApiUrl(routeSlug));
    }
  };

  const onSubmit = () => {
    Object.keys(batchData.selectedRows).forEach(
      id => selected.map(item => proceedChildren(batchData.selectedRows[id], item))
    );
    Object.keys(accessesUnits).map(unitId => {
      console.log('checking...', unitId);
      if (!selectedItems[unitId]) {
        accessesUnits[unitId].map(entityId => {
          console.log('remove it', entityId, unitId);
          removeRequest(
            {
              isArchieved: true
            },
            getApiUrl(
              `${routeSlug}_delete`,
              { entityId, unitId }
            )
          );
        });
      }
    });
    setSelected([]);
    resetSelected(hierarchyData);
    batchData.deselectCallback();
    onClose();
  }

  return (
    <Modal
      isLoading={loading || loadingAccesses || saving || rebuilding || removing}
      isOpen={saving || isOpen}
      title="Надати доступ"
      width="800px"
      buttons={[
        {
          color: Button.COLOR.BLUE,
          label: 'Відміна',
          onClick: onClose,
        },
        {
          label: 'Надати доступ',
          onClick: onSubmit,
        },
      ]}
    >
      <HierarchyEditor
        provider={{
          data: hierarchyData,
          returnCallback: (data) => setSelected(data),
        }}
        options={{
          active: true,
          editable: false,
          multipleChoice: true,
          plainSelectOnly: false,
          preventCollapseSelected: true,
          oneBranchOnly: false,
          maxDepth: 0,
        }}
      />
    </Modal>
  );
}

export default DialogTreeAccess;
