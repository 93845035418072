import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled/macro";
import { Button, DialogVersions } from "./index";
import { getApiUrl } from "../utils";
import useSaveRequest from "../hooks/useSaveRequest";
import { colors, DATE_FORMAT, TIME_FORMAT } from '../constants';
import moment from "moment/moment";
import Loading from "./Loading";

const Info = styled.div`
  background: ${colors.grayBg};
  border-radius: 4px;
  font-size: 1rem;
  padding: 0.75rem 0.75rem;
  position: absolute;
  min-width: 12rem;
  right: 1.5rem;
  z-index: 99;
`;

const Title = styled.div`
  font-weight: 600;
`;

const Name = styled.div`
  padding-top: 0.75rem;
`;

const Date = styled.div`
  padding-top: 0.5rem;
`;

const Time = styled.div`
  font-size: 0.9rem;
`;

const Buttons = styled.div`
  margin-top: 1rem;
  
  button {
    display: flex;
    margin-left: 0;
  }
`;

const InfoBlock = ({ data, isVersion, onBack, onSelectVersion, routeSlug }) => {
  const [isDialogVersionsOpen, setIsDialogVersionsOpen] = useState(false);
  const versionsSlug = routeSlug.replace('_entity', '_versions');
  const [saving, saveRequest] = useSaveRequest(
    'patch',
    getApiUrl(routeSlug, { entityId: data.id }),
    useCallback(() => onBack(), [onBack]),
    null,
    'Додано до архіву'
  );

  const onArchive = () => {
    saveRequest({isArchieved: true});
  }

  return !!data.entityId && (
    <Info>
      <Title>Оновлено</Title>
      <Name>{data?.createdBy?.fullName}</Name>
      {saving ? <Loading /> : (
        <>
          <Date>
            {moment(data.createdAt).format(`${DATE_FORMAT}`)}
          </Date>
          <Time>
            {moment(data.createdAt).format(`${TIME_FORMAT}`)}
          </Time>
          <Buttons>
            <Button
              color={Button.COLOR.GRAY}
              icon="bookGray"
              onClick={() => setIsDialogVersionsOpen(true)}
              type="button"
            >
              Перегляд версій
            </Button>
            {!isVersion && (
              <Button
                color={Button.COLOR.BLUE}
                icon="archiveBlue"
                onClick={() => onArchive()}
                type="button"
              >
                Архівувати
              </Button>
            )}
          </Buttons>
        </>
      )}
      <DialogVersions
        entityId={data.entityId}
        isOpen={isDialogVersionsOpen}
        onClose={() => setIsDialogVersionsOpen(false)}
        onSelectVersion={onSelectVersion}
        routeSlug={versionsSlug}
      />
    </Info>
  );
}

InfoBlock.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  isVersion: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onSelectVersion: PropTypes.func.isRequired,
  routeSlug: PropTypes.string.isRequired,
};

InfoBlock.defaultProps = {
  isVersion: false,
};

export default InfoBlock;
