import React, { createContext, useState } from 'react'
import { STORAGE_INTERFACE_SETTINGS_NAME } from '../constants'

const defaultSettings = {
  simplified: false,
  plansExpanded: false,
  askuExpanded: false,
  targetsExpanded: false,
  journalExpanded: false,
}

export const interfaceContext = createContext(defaultSettings)

const InterfaceProvider = ({ children }) => {
  const getInterfaceSettings = () => {
    const s = localStorage.getItem(STORAGE_INTERFACE_SETTINGS_NAME)
    if (s) {
      return JSON.parse(s)
    } else {
      localStorage.setItem(STORAGE_INTERFACE_SETTINGS_NAME, JSON.stringify(defaultSettings))
      return defaultSettings
    }
  }

  const setInterfaceSettingsStore = (data) => {
    localStorage.setItem(STORAGE_INTERFACE_SETTINGS_NAME, JSON.stringify(data))
    setInterfaceSettings(data)
  }

  const [interfaceSettings, setInterfaceSettings] = useState(getInterfaceSettings())
  return (
    <interfaceContext.Provider value={{ interfaceSettings, setInterfaceSettingsStore }}>
      {children}
    </interfaceContext.Provider>
  )
}

export default InterfaceProvider
