import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../Loading';

const TableLoader = ({ columns, hasRowActions, hasBatchActions }) => (
    <tr>
        <td colSpan={columns.length + hasRowActions + hasBatchActions}>
            <Loading />
        </td>
    </tr>
);

TableLoader.propTypes = {
    columns: PropTypes.array,
    hasRowActions: PropTypes.bool,
    hasBatchActions: PropTypes.bool,
};

TableLoader.defaultProps = {
    columns: [],
    hasRowActions: false,
};

export default TableLoader;
