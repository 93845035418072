import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Page from './Page';
import styles from './Pagination.module.css';

class Pagination extends React.Component {
    handleChange = (page) => {
        const { pageSize, onChange } = this.props;

        onChange(page, pageSize);
    };

    numPages = () => {
        const { pageSize, total } = this.props;

        return Math.floor((total - 1) / pageSize) + 1;
    };

    getPageNumbers = () => {
        const { current } = this.props;

        const totalPages = this.numPages();
        const pages = [];
        let number = current - 1;
        if (current > totalPages - 1) {
            number -= 1;
        }
        do {
            if (number > 0) {
                pages.push(number);
            }
            number++;
        } while (pages.length < 3 && number <= totalPages);

        return pages;
    };

    isValidPage(page) {
        return typeof page === 'number' && page >= 1 && page !== this.state.current;
    }

    render() {
        const { current, className } = this.props;
        const totalPages = this.numPages();

        if (totalPages <= 1) {
            return false;
        }

        const disableNextButton = current === totalPages;
        const pagerList = this.getPageNumbers().map((page, index) => (
            <Page key={page + index} active={current === page} page={page} onClick={() => this.handleChange(page)} />
        ));

        return (
            <div className={cn(styles.pagination, className)}>
                <Page page="«" className={styles.first} onClick={() => this.handleChange(1)} disabled={current <= 1} />
                <Page
                    page="<"
                    className={styles.prev}
                    onClick={() => this.handleChange(current - 1)}
                    disabled={current <= 1}
                />
                {pagerList}
                <Page
                    page=">"
                    className={styles.next}
                    onClick={() => this.handleChange(current + 1)}
                    disabled={disableNextButton}
                />
                <Page
                    page="»"
                    className={styles.last}
                    onClick={() => this.handleChange(totalPages)}
                    disabled={disableNextButton}
                />
            </div>
        );
    }
}

Pagination.propTypes = {
    total: PropTypes.number,
    pageSize: PropTypes.number,
    current: PropTypes.number,
    className: PropTypes.string,
    onChange: PropTypes.func,
};

Pagination.defaultProps = {
    current: 1,
    pageSize: 30,
};

export default Pagination;
