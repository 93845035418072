import {useReducer, useMemo, useEffect} from 'react';
import {useStoredListsContext} from '../contexts/StoredListsContext';

const ACTIONS = {
  setPage: 'setPage',
  sortTable: 'sortTable',
  setSearch: 'setSearch',
  setFilters: 'setFilters',
  setFilter: 'setFilter',
  setIsArchived: 'setIsArchived',
}

const INITIAL_STATE = {
  page: 1,
  sort: 'name',
  sortAsc: false,
  isArchived: false,
  searchTerm: '',
  filters: {},
  stateOverrides: {}
}

function listReducer(state, action) {
  switch (action.type) {
    case ACTIONS.setPage:
      return {...state, page: action.page};
    case ACTIONS.sortTable:
      return {...state, sort: action.column, sortAsc: action.direction };
    case ACTIONS.setSearch:
      return {...state, searchTerm: action.searchTerm, page: 1};
    case ACTIONS.setFilters:
      return {...state, filters: action.filters, page: 1};
    case ACTIONS.setFilter:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filter]: action.value,
        },
        page: 1
      };
    case ACTIONS.setIsArchived:
      const {stateOverrides} = state;
      return {...INITIAL_STATE, ...stateOverrides, stateOverrides, isArchived: action.isArchived};
    default:
      throw new Error(`Unknown action: ${action.type}`)
  }
}

function listActions(dispatch) {
  return {
    setPage: (page) => dispatch({ type: ACTIONS.setPage, page }),
    sortTable: (column, direction) => dispatch({ type: ACTIONS.sortTable, column, direction }),
    setIsArchived: (isArchived) => dispatch({ type: ACTIONS.setIsArchived, isArchived }),
    setSearchTerm: (searchTerm) => dispatch({ type: ACTIONS.setSearch, searchTerm }),
    setFilters: (filters) => dispatch({ type: ACTIONS.setFilters, filters }),
    setFilter: (filter, value) => dispatch({ type: ACTIONS.setFilter, filter, value }),
  };
}

function getInitialState(stateOverrides) {
  return {...INITIAL_STATE, ...stateOverrides, stateOverrides};
}

export default function useListReducer(apiSlug, stateOverrides = {}, storageKey = '') {
  const initialListState = useMemo(() => getInitialState(stateOverrides), [stateOverrides]);
  const [initialState, setListState] = useStoredListsContext(storageKey || apiSlug, initialListState);
  const [state, dispatch] = useReducer(listReducer, initialState);
  const actions = useMemo(() => listActions(dispatch), []);

  useEffect(() => {
    setListState(state);
  }, [state, setListState])

  return useMemo(
    () => ({
      state,
      ...actions,
    }),
    [state, actions]
  );
}
