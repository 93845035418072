import React from 'react';
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import isPlainObject from 'lodash/isPlainObject'

const processTo = (to) => {
  if (isPlainObject(to)) {
    return `${to.pathname || ''}${to.search ? `?${to.search}` : ''}`
  }
  return to
}

const Link = ({ to, children, activeClassName, className, isEnd = true, ...props }) => {
  const processedTo = processTo(to)
  return (
    <NavLink
      to={processedTo}
      end={isEnd}
      className={({ isActive, isPending }) => {
        return isPending
          ? className
          : isActive
          ? `${className ? className : ''} ${activeClassName ? activeClassName : ''}`
          : className
      }}
      {...props}>
      {children}
    </NavLink>
  )
}

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isEnd: PropTypes.bool,
  children: PropTypes.node,
}

export default Link

