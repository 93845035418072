import React, { Component } from 'react';
import PropTypes from 'prop-types';
import keys from 'lodash/keys';
import cn from 'classnames';
import { Button, NoItemsMessage } from "../../components";
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableLoader from './TableLoader';
import Pagination from './Pagination/Pagination';
import styles from './Table.module.css'

class Table extends Component {
  state = {
    selectedRows: {...this.props.selected},
  };

  changePage = (page) => this.props.changePageCallback(page);

  selectRow = (row) => {
    const { selectedRows } = this.state;

    if (selectedRows[row.id]) {
      const { [row.id]: remove, ...rest } = selectedRows;
      this.setState({ selectedRows: rest });
    } else {
      selectedRows[row.id] = row;
      this.setState({
        selectedRows: {
          ...selectedRows,
          [row.id]: row,
        },
      });
    }
  };

  selectAll = () => {
    const { data } = this.props;
    const gridData = data.result ? data.result : data;
    const selectedCount = Object.keys(this.state.selectedRows).length;

    const selectedRows = {};
    if (selectedCount < gridData.length) {
      gridData.forEach((item) => (selectedRows[item.id] = item));
    }

    this.setState({ selectedRows });
  };

  deselectAll = () => {
    this.setState({ selectedRows: {} });
  };

  sortData(columnName) {
    if (!this.props.sortDataCallback) {
      throw new Error('sortDataCallback is undefined');
    }

    const sortableColumns = this.props.columns
      .filter(({ sortable }) => sortable)
      .map(({ key, sortField }) => sortField || key);

    if (this.props.dataLoading || sortableColumns.indexOf(columnName) === -1) {
      return;
    }

    const { sortBy, sortAsc } = this.props;
    const sortDirection = sortBy === columnName ? !sortAsc : false;

    this.props.sortDataCallback(columnName, sortDirection);
  }

  render() {
    const { selectedRows } = this.state;
    const {
      actions = [],
      batchActions = [],
      columns,
      controlsOnBottom,
      controlsOnTop,
      rowActions,
      dataUpdateCallback,
      data,
      dataLoading,
      rowClassName,
      enablePaging,
      pageSize,
      currentPage,
      className,
      rowActionsClassName,
      sortBy,
      sortAsc,
      noHeader,
      footerComponent,
    } = this.props;
    const hasRowActions = rowActions.filter((action) => !!action).length > 0;
    const selectedCount = keys(selectedRows).length;
    const disableHeader = dataLoading;
    const gridData = enablePaging && data.result ? data.result : data;
    const hasBatchActions = batchActions.filter((action) => !!action).length > 0 && gridData.length > 0;

    if (!Array.isArray(gridData)) {
      throw new Error('Invalid data type, grid data should be an array');
    }

    const getControls = () => (
      <div className={styles.batchActions}>
        {!!actions && actions.map((action) => !action ? null : (
          <Button
            key={action.title}
            color={Button.COLOR.GRAY}
            rightMargin
            {...action}
          >
            {action.title}
          </Button>
        ))}
        {hasBatchActions && batchActions.map(
          (batchAction) => !batchAction ? null : (selectedCount > 0 || batchAction.always) && (
            <Button
              key={batchAction.title}
              color={Button.COLOR.GRAY}
              rightMargin
              {...batchAction}
              onClick={() => batchAction.onClick(selectedRows, this.deselectAll)}
            >
              {batchAction.title}
            </Button>
          )
        )}
      </div>
    );

    return (
      <div className={cn(styles.datagrid, className)}>
        {!gridData.length ? (
          <NoItemsMessage message="Нема даних" />
        ) : (
          <>
            {controlsOnTop && getControls()}
            <table>
              {!noHeader && !dataLoading && (
                <TableHeader
                  columns={columns}
                  disabled={disableHeader}
                  hasBatchActions={hasBatchActions}
                  hasRowActions={hasRowActions}
                  onSelectAll={this.selectAll}
                  rowActionsClassName={rowActionsClassName}
                  sortAsc={sortAsc}
                  sortBy={sortBy}
                  sortCallback={(key) => this.sortData(key)}
                  selectAllChecked={selectedCount === gridData.length}
                />
              )}
              <tbody className={styles.body}>
                {dataLoading && (
                  <TableLoader
                    columns={columns}
                    hasRowActions={hasRowActions}
                    hasBatchActions={hasBatchActions}
                  />
                )}
                {!dataLoading && gridData.map((dataItem, dataKey) => (
                  <TableRow
                    key={dataKey}
                    dataItem={dataItem}
                    columns={columns}
                    rowActions={rowActions}
                    rowActionsClassName={rowActionsClassName}
                    className={rowClassName(dataItem)}
                    dataUpdateCallback={dataUpdateCallback}
                    hasRowActions={hasRowActions}
                    hasBatchActions={hasBatchActions}
                    onSelectRow={this.selectRow}
                    selected={!!selectedRows[dataItem.id]}
                    index={dataKey}
                    getInlineForm={this.props.getInlineForm}
                  />
                ))}
              </tbody>
            </table>
            {(enablePaging || !!this.props.footer) && (
              <div className={styles.footer}>
                {footerComponent && (
                  <div>{footerComponent}</div>
                )}
                <div>
                  {enablePaging && (
                    <Pagination
                      total={data.result ? data.total : data.length}
                      pageSize={pageSize}
                      current={currentPage}
                      onChange={this.changePage}
                    />
                  )}
                </div>
                {!!this.props.footer && this.props.footer}
              </div>
            )}
          </>
        )}
        {controlsOnBottom && getControls()}
      </div>
    );
  }
}

Table.propTypes = {
  actions: PropTypes.array,
  batchActions: PropTypes.array,
  className: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string,
      renderer: PropTypes.func,
    }),
  ).isRequired,
  controlsOnBottom: PropTypes.bool,
  controlsOnTop: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  dataLoading: PropTypes.bool,
  dataUpdateCallback: PropTypes.func,
  enablePaging: PropTypes.bool,
  changePageCallback: PropTypes.func,
  footer: PropTypes.object,
  rowActions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        title: PropTypes.string,
        className: PropTypes.string,
        link: PropTypes.func,
        callback: PropTypes.func,
        displayCallback: PropTypes.func,
      }),
    ]),
  ),
  rowActionsClassName: PropTypes.string,
  rowClassName: PropTypes.func,
  selected: PropTypes.object,
  sortAsc: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDataCallback: PropTypes.func,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  skipComponentUpdate: PropTypes.bool,
  checkPropsChangeToSkipComponentUpdate: PropTypes.bool,
  onSwap: PropTypes.func,
  noHeader: PropTypes.bool,
  footerComponent: PropTypes.object,
  getInlineForm: PropTypes.func,
};

Table.defaultProps = {
  actions: [],
  batchActions: [],
  controlsOnBottom: true,
  controlsOnTop: false,
  rowActions: [],
  rowClassName: () => null,
  dataLoading: false,
  footer: null,
  enablePaging: true,
  pageSize: 10,
  changePageCallback: () => {},
  selected: {},
  sortDataCallback: () => {},
  skipComponentUpdate: false,
  checkPropsChangeToSkipComponentUpdate: false,
  footerComponent: null,
  getInlineForm: null,
};

export default Table;
