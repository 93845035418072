import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../contexts/SnackbarContext";
import { SnackbarStatus } from '../constants';
import { asCamelCase, asSnakeCase } from "../utils";
import { getAxiosMethod, request } from "../utils/httpRequests";

const useSaveRequest = (
  method,
  url = null,
  onSuccess = null,
  onError = null,
  message = 'Успішно збережено',
  noChangeVariables = false,
  noFormatResult = false
) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const saveRequest = useCallback((variables, entityUrl = null) => {
    setLoading(true);
    const formatted = noChangeVariables
      ? variables
      : asSnakeCase(variables);

    request(getAxiosMethod(method), url || entityUrl, formatted)
      .then(res => {
        const resFormatted = noFormatResult
          ? (res.data.data || res.data)
          : asCamelCase(res.data.data || res.data);
        onSuccess && onSuccess(resFormatted);
        if (message) {
          showSnackbar({
            message: message,
            status: SnackbarStatus.success
          });
        }
      })
      .catch((error) => {
        const errorData = JSON.parse(error);
        console.log('Api error data', errorData);
        onError && onError(errorData);
        showSnackbar({
          message: `Помилка збереження: ${t(errorData.message)}`,
          status: SnackbarStatus.error
        })
      })
      .finally(() => setLoading(false));
  }, [method, url, onSuccess, onError, message, showSnackbar, noChangeVariables]);

  return [loading, saveRequest];
}

export default useSaveRequest;
