import React from 'react';
import styled from '@emotion/styled/macro';
import { keyframes } from "@emotion/react/macro";
import { colors, SnackbarStatus as STATUS } from '../constants';
import { useSnackbar } from "../contexts/SnackbarContext";

export const fadeInAnimation = () => keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  animation: ${fadeInAnimation()} 0.2s linear;
  background: ${({ status }) => {
    switch (status) {
      case STATUS.error:
        return colors.errorBg;
      case STATUS.success:
        return colors.successBg;
      case STATUS.warning:
        return colors.warning;
      default:
        return colors.dark;
    }
  }};
  border-radius: var(--border-radius);
  bottom: 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.08), 0 0 2px rgba(0, 0, 0, 0.05);
  color: ${({ status }) => {
    switch (status) {
      case STATUS.error:
        return colors.error;
      case STATUS.success:
        return colors.success;
      case STATUS.warning:
        return colors.text;
      default:
        return colors.white;
    }
  }};
  left: 16rem;
  min-width: 20rem;
  padding: 1rem;
  position: fixed;
  text-align: center;
  z-index: 999999;
`;

export default function Snackbar() {
  const { message, status } = useSnackbar();
  return message ? (
    <Wrapper status={status} data-testid={status} role="alert">
      {message}
    </Wrapper>
  ) : null;
}
