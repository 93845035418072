const strPriceRgx = /^[0-9 + .]+$/;

/**
 * converting a number by moving decimal places based on the value of [decimalShift] passed through the function, and return it as string or number type based on the value of [returnedAs] with the shifted decimal places.
 *-----
 * @param  {[string | number]} price A string of digits or number to be converted.
 * @param  {[number]} decimalShift  An integer number to determine the number of decimals that will be shifted for left or right.
 * @param {[string]} returnedAs A string representation that will determine the returned value type.
 * @return {[number | string]}  A string of digits or number with shifted decimal places.
 *
 * @example
 * priceConverter({ price: 15050, decimalShift: -2, returnedAs: "string" })  ==> "150.5".
 *
 * priceConverter({ price: "150.50", decimalShift: 2, returnedAs: "number" })  ==> 15050.
 *
 * priceConverter({ price: 150.50 }) ==> "15050".
 *
 */

export const priceConverter = params => {
  if (!params) return '';

  const { price } = params;
  let { decimalShift, returnedAs } = params;

  if (
    (!price && price !== 0) ||
    (typeof price !== 'number' && typeof price !== 'string') ||
    (typeof price === 'string' && !strPriceRgx.test(price.trim()))
  ) {
    return '';
  }

  if ((!decimalShift && decimalShift !== 0) || !Number.isInteger(decimalShift)) {
    decimalShift = -2;
  }

  if (returnedAs !== 'number' && returnedAs !== 'string') {
    returnedAs = 'string';
  }

  let prePrice = Number(price).toFixed(2);
  prePrice *= 10 ** decimalShift;
  prePrice = Math.fround(prePrice).toFixed(4);

  if (Math.sign(decimalShift) === 1 || Math.sign(decimalShift) === 0) {
    prePrice = Math.trunc(prePrice);
  }

  if (returnedAs === 'string') {
    return String(prePrice);
  }

  return Number(prePrice);
};

/**
 * Converting a formatted price with currencies to cents
 *-----
 * @param  {[string | number]} price A string of digits with currencies to be converted.
 * @return {number}  Number of cents.
 *
 * @example
 * priceConverter({ price: $150.59 })  ==> "15059".
 *
 * priceConverter({ price: $12 })  ==> "1200".
 *
 */

export const formattedPriceToCent = params => {
  if (!params) return 0;

  const { price } = params;

  if (!price || (typeof price !== 'number' && typeof price !== 'string')) return 0;

  const str = Number(price.replace(/[^0-9/.]+/g, ''))
    .toFixed(2)
    .toString();
  const [int] = str.split('.');

  return Number(str.replace('.', '').padEnd(int.length === 1 ? 3 : 4, '0'));
};

/**
 * Formatting price with currency symbol.
 *----
 * A standard built-in object in JavaScript is used in this function, for more details please visit @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 *
 * @param  {[string | number]} price A number or a string of digits to be formatted with currencies.
 * @param  {[string]} lang A string with a BCP 47 language tag. For the general form and interpretation of the locales argument.
 * @param {[string]} currencyCode The currency to use in currency formatting. Possible values are the ISO 4217 currency codes, such as "USD" for the US dollar, "EUR" for the euro, or "CNY" for the Chinese RMB.
 *
 * @return {[string]}  A formatted price with currency symbol.
 * @example currencyFormatter({ price: 20.75 }) ==> '$20.75'
 *
 */

export const currencyFormatter = params => {
  if (!params) return '';

  const { price } = params;
  let { currencyCode, lang } = params;

  const langRgx = /^[a-zA-Z + -]+$/;
  const currencyCodeRgx = /^[A-Z]{3}$/;

  if (
    (!price && price !== 0) ||
    (typeof price !== 'number' && typeof price !== 'string') ||
    (typeof price === 'string' && !strPriceRgx.test(price.trim()))
  ) {
    return '';
  }

  if (
    !currencyCode ||
    (typeof currencyCode === 'string' && !currencyCodeRgx.test(currencyCode.trim())) ||
    typeof currencyCode !== 'string'
  ) {
    currencyCode = 'USD';
  }

  if (
    !lang ||
    (typeof lang === 'string' && !langRgx.test(lang.trim())) ||
    typeof lang !== 'string'
  ) {
    lang = 'en';
  }

  return new Intl.NumberFormat(lang, {
    currency: currencyCode,
    maximumSignificantDigits: 12,
    style: 'currency',
  }).format(price);
};

/**
 * Convert offer details into a readable format as needed.
 *-----
 * @param  {[object]} values Values that come from form fields on creation mode or from fetched data from the database for the display mode, that contains (price, durations, intervals, currency names, etc...)
 * @param {[function]} t Translation function.
 * @param  {[string]} lang A String with a BCP 47 language tag. For the general form and interpretation of the locales argument.
 * @param {[boolean]} autoRenew A Boolean indicating whether the auto-renewal is enabled for the current offer or not.
 * @param {[boolean]} isTitle A Boolean indicating whether the returned string should be for the title details or renewal details on the offer card.
 * @param  {[boolean]} isForm A Boolean indicating whether the price converting process is for the form fields or for fetched not auto-renewal offer.
 * @return {[string]}
 *
 * @example
 * {
    "renewalOffer": {
        "interval": "MONTH",
        "price": 2000,
        "duration": 1,
        "currencyName": "USD",
        "__typename": "Offer"
    },
    "isTrial": true,
    "currencyName": "USD",
    "id": "500e3510-0e85-430a-81bd-e855c7807ff1",
    "autoRenewal": true,
    "renewalOfferId": "cfc8d381-b1e9-4a17-833a-a2601523f355",
    "interval": "MONTH",
    "price": 1500,
    "duration": 3,
    "title": "Reduced Price Trial Test",
    "offerDetails": [],
    "__typename": "Offer"
}
 * "**returns values**"
 *
 * "$15 for a 3 Month trial" --> isTitle: true
 * "$20 Per Month"
 *
 */
export const getOfferRateText = ({ values, t, lang, autoRenew, isTitle = true, isForm = true }) => {
  const renewalOfferData = values?.autoRenewalOffer || values?.renewalOffer;
  const offerRate = (autoRenew && renewalOfferData) || values;

  // eslint-disable-next-line prefer-const
  let { price, duration, currencyName, interval } = offerRate;

  if (renewalOfferData) {
    if (values?.autoRenewalOffer) {
      price = autoRenew ? priceConverter({ price }) : price;
    }
    if (values?.renewalOffer) {
      price = priceConverter({ price });
    }
  } else {
    price = isForm ? price : priceConverter({ price });
  }

  const currencyPrice = currencyFormatter({
    currencyCode: currencyName,
    lang,
    price,
  });

  if (values?.isTrial && isTitle) {
    return duration > 1
      ? `${currencyPrice} ${t('for-a')} ${duration} ${t(interval).toLowerCase()}s ${t('trial')}`
      : `${currencyPrice} ${t('for-a')} ${duration} ${t(interval).toLowerCase()} ${t('trial')}`;
  }

  const rate =
    duration > 1
      ? `${currencyPrice} ${t('every')} ${duration} ${t(interval).toLowerCase()}s`
      : `${currencyPrice} ${t('per')} ${t(interval).toLowerCase()}`;
  return currencyPrice && Number(price) >= 0 ? rate : '';
};
