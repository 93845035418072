import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from "../../components";
import styles from './CheckBox.module.css';

const SIZE = {
    DEFAULT: 'default',
    BIG: 'big',
};

const COLOR = {
    DARK: 'dark',
    CLIENT: 'client',
};


const CheckBox = ({ checked, size, onChange, disabled, color, label, className }) => {
    const _className = classNames(
        styles.checkBox,
        {
            [styles.checked]: checked,
            [styles.disabled]: disabled,
        },
        styles[`size-${size}`],
        styles[`color-${color}`],
        (color === COLOR.CLIENT && checked) && 'client-main-colour-background client-main-colour-border',
        className,
    );

    const checkBox = (
        <span className={_className} onClick={!label && onChange && !disabled ? () => onChange(!checked) : null}>
            <Icon kind="tick" className={styles.icon} />
        </span>
    );

    if (label) {
        return (
            <span
                className={styles.checkBoxRow}
                onClick={onChange && !disabled ? () => onChange(!checked) : null}
            >
                {checkBox}
                {label}
            </span>
        );
    }

    return checkBox;
};

CheckBox.SIZE = SIZE;
CheckBox.COLOR = COLOR;

CheckBox.defaultProps = {
    size: SIZE.DEFAULT,
    color: COLOR.CLIENT,
};

CheckBox.propTypes = {
    /**
     * Class name
     */
    className: PropTypes.string,
    /**
     * Is checked
     */
    checked: PropTypes.bool,
    /**
     * Check box size: default | big
     */
    size: PropTypes.string,
    /**
     * Check box color: dark | client
     */
    color: PropTypes.string,
    /**
     * Gets called when the user change che value
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     */
    onChange: PropTypes.func,
    /**
     * Is disabled
     */
    disabled: PropTypes.bool,
    /**
     * Rentered text next to check box
     */
    label: PropTypes.string,
};

export default CheckBox;
