import React, { useContext, useEffect, useState, useCallback } from 'react'
import { Menu, User } from '../../components'
import logo from './logo.svg'
import styled from '@emotion/styled/macro'
import MenuUrgentInfo from '../Menu/MenuUrgentInfo'
import { interfaceContext } from '../../contexts/InterfaceContext'

const Wrapper = styled.div`
  display: flex;
`

const Sidebar = styled.div`
  background-color: #223348;
  height: 100vh;
  width: 13rem;
  overflow: hidden;
  position: sticky;
  top: 0;
  &.shortened {
    width: 42px;
  }
  & > img {
    padding: 1.8vh 0 1.8vh 0.8rem;
  }
`

const Content = styled.div`
  padding: 0;
  position: relative;
  width: calc(100% - 13rem);
  &.shortened {
    width: 100%;
  }
`

const Layout = ({ children }) => {
  const { interfaceSettings } = useContext(interfaceContext)

  return (
    <Wrapper>
      <Sidebar className={interfaceSettings.simplified && 'shortened'}>
        {!interfaceSettings.simplified && <img src={logo} alt="logo" />}
        <Menu />
        <MenuUrgentInfo />
        <User />
      </Sidebar>
      <Content className={interfaceSettings.simplified && 'shortened'}>{children}</Content>
    </Wrapper>
  )
}

export default Layout
