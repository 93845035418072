import React, { Component } from 'react'
import saveFrontendError, { ERROR_CATEGORY } from '../utils/saveFrontendError'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(_) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    setTimeout(() => {
      const containerTitle = document.getElementById('error-title')
      // const containerDetails = document.getElementById('error-details')
      const containerComponent = document.getElementById('error-component')
      if (containerTitle) {
        containerTitle.textContent = error
      }
      // if (containerDetails) {
      //   containerDetails.textContent = JSON.stringify(errorInfo)
      // }
      if (containerComponent) {
        containerComponent.textContent = errorInfo.componentStack
      }
    }, 100)
    console.error('Uncaught error:', error)
    console.log('errorInfo: ' + JSON.stringify(errorInfo))
    console.log('componentStack: ' + errorInfo.componentStack)
    saveFrontendError(error.toString(), errorInfo, ERROR_CATEGORY.RENDER)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error_boundary">
          <h2>React component error!</h2>
          <div className="tip">see console for details</div>
          <div className="error-boundary-description-container">
            <div id="error-title"></div>
            {/* <div id="error-details"></div> */}
            <div id="error-component"></div>
          </div>
          {this.state.description}
          <button
            onClick={() => {
              window.location.href = '/'
            }}>
            На головну
          </button>

          <style jsx="true">{`
            .error-boundary-description-container {
              padding: 2rem;
            }
            #error-title {
              padding: 1rem;
              margin-bottom: 1rem;
              border-bottom: 1px solid black;
            }
            #error-details {
              padding: 1rem;
              margin-bottom: 1rem;
              border-bottom: 1px solid black;
            }
            #error-component {
              padding: 1rem;
              margin-bottom: 1rem;
              border: 1px solid black;
            }
            .error_boundary {
              display: flex;
              flex-flow: column wrap;
              justify-content: center;
              align-items: center;
              padding: 2rem;
              box-sizing: border-box;
              width: 100%;
              height: 100%;
            }
            .tip {
              padding: 2rem;
            }
          `}</style>
        </div>
      )
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary
