import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react'
import { useApiUrl } from '../utils'
import useGetRequest from '../hooks/useGetRequest'

export const authContext = createContext({})

const AuthProvider = ({ children }) => {
  const [workplace, setWorkplace] = useState({})
  const [isAutonomousLaunch, setIsAutonomousLaunch] = useState(false)

  const [, _workplace, refreshWorkplace] = useGetRequest(
    useApiUrl('workplace'),
    useCallback((data) => {
      setIsAutonomousLaunch(data?.appSettings?.vehicle?.isAutonomousLaunch || false)
      setWorkplace(data)
    }, []),
    undefined,
    undefined,
    undefined,
    true
  )
  const [auth, setAuth] = useState({
    loading: false,
    data: JSON.parse(localStorage.getItem('authData')),
  })

  const setAuthData = (data) => {
    setAuth({ loading: false, data: data })
  }

  const checkPermission = (permission) =>
    !permission ||
    auth?.data?.isPrdAdmin ||
    !!auth?.data?.permissions?.filter((userPermission) => userPermission.name === permission).length

  const checkIsCommanderWithAutolaunchOwnSPU = useMemo(() => {
    return auth?.data?.position?.isCommander && isAutonomousLaunch
  }, [auth, isAutonomousLaunch])

  const checkIsAutonomousLaunchAllowed = (isKnownAutoLaunchFlag) => {
    const workAutoLaunch = isKnownAutoLaunchFlag === undefined ? isAutonomousLaunch : isKnownAutoLaunchFlag
    return (
      auth?.data?.isPrdAdmin ||
      auth?.data?.permissions?.find((p) => p.name === 'autonomous_launch') ||
      (auth?.data?.position?.isCommander && workAutoLaunch)
    )
  }

  useEffect(() => {
    localStorage.setItem('authData', JSON.stringify(auth.data))
  }, [auth.data])

  return (
    <authContext.Provider
      value={{
        auth,
        setAuthData,
        checkPermission,
        checkIsCommanderWithAutolaunchOwnSPU,
        checkIsAutonomousLaunchAllowed,
        workplace,
        refreshWorkplace,
        isAutonomousLaunch,
      }}>
      {children}
    </authContext.Provider>
  )
}

export default AuthProvider
