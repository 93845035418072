import { useCallback, useEffect } from 'react'
import useGetRequest from './useGetRequest'
import useListReducer from './useListReducer'
import { useApiUrl } from '../utils'

export default function useList(apiSlug, stateOverrides = {}, storageKey = '', apiURLFilter = '') {
  const { state, ...actions } = useListReducer(apiSlug, stateOverrides, storageKey)
  const { page, searchTerm, sort, sortAsc, isArchived, filters } = state
  const apiUrl = useApiUrl(isArchived ? `${apiSlug}_archived` : apiSlug) + apiURLFilter
  const [loading, { data, meta }, requeryData] = useGetRequest(apiUrl, null, true)

  const refreshData = useCallback((newPage = page) => {
    const _filters = Object.fromEntries(
      Object.entries(filters)
        .filter(([, filterValue]) => filterValue !== null)
        .map(([filter, value]) => [`filter[${filter}]`, value])
    )
    const checkLastPageIsEmpty = (result) => {
      if (!result.data.length && page > 1) {
        actions.setPage(page-1);
      }
      return result
    }
    requeryData({ searchTerm, page: newPage, sort, sortAsc, ..._filters }, checkLastPageIsEmpty)
  }, [page, requeryData, searchTerm, sort, sortAsc, filters])

  useEffect(refreshData, [refreshData])

  return { loading, data, meta, refreshData, ...state, ...actions }
}
