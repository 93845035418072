import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Link from '../Link/Link';
import Icon from '../Icon/Icon';
import styles from './Button.module.css';

/**
 * Base button
 */
const Button = ({
    name,
    type,
    className,
    children,
    color,
    icon,
    small,
    rightMargin,
    rightIcon,
    href,
    link,
    onClick,
    disabled,
    id,
    blank,
    form,
    iconSpin,
    title
}) => {
    const buttonClassName = cn(
      styles.button,
      styles[`button-${color}`],
      rightMargin && styles.rightMargin,
      small && styles.small,
      disabled && styles.disabled,
      icon && styles.hasIcon,
      icon && !children && styles.iconOnly,
      className
    );

    const iconElement = icon ? (
      <Icon kind={icon} className={cn(styles.icon, iconSpin && styles.iconSpin, rightIcon && styles.rightIcon)} />
    ) : null;

    const handleClick = e => {
        if (e) {
            // Disable event bubbling, especially with a Form
            e.preventDefault();
            e.stopPropagation();
        }

        if (disabled) {
            return;
        }

        onClick(e);
    };

    switch (true) {
        case !!href:
            return (
              <a
                name={name}
                className={buttonClassName}
                onClick={onClick || null}
                href={href}
                target={blank ? '_blank' : '_parent'}
                rel="noopener noreferrer"
                id={id}
              >
                  {!rightIcon && iconElement}
                  {children}
                  {!!rightIcon && iconElement}
              </a>
            );
        case !!link:
            return (
              <Link
                name={name}
                className={buttonClassName}
                to={link}
                id={id}
              >
                  {!rightIcon && iconElement}
                  {children}
                  {!!rightIcon && iconElement}
              </Link>
            );
        default:
            return (
              <button
                type="button"
                {...{ type: type || 'button' }}
                name={name}
                className={buttonClassName}
                onClick={onClick ? handleClick : null}
                disabled={disabled}
                id={id}
                form={form}
                title={title}
              >
                  {!rightIcon && iconElement}
                  <span className="btn-text">{children}</span>
                  {!!rightIcon && iconElement}
              </button>
            );
    }
};

Button.defaultProps = {
    color: 'primary',
    type: 'button',
    blank: true,
};

Button.COLOR = {
  PRIMARY: 'primary',
  BG_BLUE: 'bg-blue',
  BG_GREEN: 'bg-green',
  BG_GREEN_SOLID: 'bg-green-solid',
  BG_GRAY: 'bg-gray',
  BG_RED: 'bg-red',
  WHITE: 'white',
  GRAY: 'gray',
  GREEN: 'green',
  BLUE: 'blue',
  PURPLE: 'purple',
  CYAN: 'cyan',
  LINK: 'link',
  NONE: 'none',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error',
  SUCCESS: 'success',
  RED: 'red',
}

Button.propTypes = {
    /**
     * Button text
     */
    children: PropTypes.any,
    /**
     * Button class name
     */
    className: PropTypes.string,
    /**
     * Button color. Values are in Button.COLOR property
     */
    color: PropTypes.oneOf(Object.keys(Button.COLOR).map(key => Button.COLOR[key])),
    disabled: PropTypes.bool,
    /**
     * Button as link to outer resource
     */
    href: PropTypes.string, // Outer link
    /**
     * Open href in another tab when true
     */
    blank: PropTypes.bool,
    /**
     * Id attr
     */
    id: PropTypes.string,
    /**
     * Valid string, for <Icon /> component
     */
    icon: PropTypes.string,
    /**
     * Button as link for internal route
     */
    link: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string,
            hash: PropTypes.string,
            state: PropTypes.object,
        }),
    ]), // Inner application link
    /**
     * Name attr
     */
    name: PropTypes.string,
    /**
     * Render small button when true
     */
    small: PropTypes.bool,
    /**
     * Render spin for icon
     */
    iconSpin: PropTypes.bool,
    /**
     * Put icon to the right when true
     */
    rightIcon: PropTypes.bool,
    /**
     * Add margin to the right when true
     */
    rightMargin: PropTypes.bool,
    /**
     * Type attr
     */
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    /**
     * Gets called when the user clicks on the button
     *
     * @param {SyntheticEvent} event The react `SyntheticEvent`
     */
    onClick: PropTypes.func,
    /**
     * Form id to work with
     */
    form: PropTypes.string,
    title: PropTypes.string,
};

export default Button;
