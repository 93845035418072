import React from "react";
import isUndefined from 'lodash/isUndefined';
import { Select } from "./index";
import { Controller } from "react-hook-form";
import { FORM_FIELD_MAX_WIDTH } from "../constants";

export default function ControllerSelect({
  control,
  error,
  isDisabled,
  label,
  maxWidth = FORM_FIELD_MAX_WIDTH,
  name,
  options,
  setValue,
  disabled = false,
  onChangeCallBack,
  isRequired,
  isLoading = false,
}) {
  const getSelectedOption = (value) => {
    let res = options?.find((option) => option.value === value)
    if (isUndefined(res)) {
      const group = options?.find((item) => item?.options?.find((option) => option.value === value))
      res = group?.options?.find((option) => option.value === value)
    }
    return res
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => (
        <Select
          errorMessage={error}
          label={label}
          maxWidth={maxWidth}
          name={name}
          isDisabled={disabled || isDisabled}
          isRequired={isRequired}
          onChange={({ option }) => {
            if (!disabled) {
              setValue(name, option.value, {
                shouldDirty: true,
                shouldValidate: true,
              })
              if (onChangeCallBack) {
                onChangeCallBack(option.value)
              }
            }
          }}
          options={options}
          selectedOption={getSelectedOption(value)}
          isLoading={isLoading}
        />
      )}
    />
  )
}
