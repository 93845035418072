import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

const Button = styled.button`
  background-color: transparent;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  min-width: ${prop => (prop.size ? prop.size : '2.75rem')};
  min-height: ${prop => (prop.size ? prop.size : '2.75rem')};
  padding: 0;

  &:hover {
    svg {
      stroke: #6764D1;
    }
  }

  &:disabled {
    svg {
      stroke: #59595A;
      cursor: not-allowed;
    }
  }
`;

const SvgButton = React.forwardRef(
  ({ 'aria-label': ariaLabel, size, className, isDisabled, onClick, children, ...rest }, ref) => (
    <Button
      aria-label={ariaLabel}
      className={className}
      disabled={isDisabled}
      onClick={onClick}
      ref={ref}
      type="button"
      size={size}
      {...rest}
    >
      {children}
    </Button>
  ),
);

SvgButton.propTypes = {
  /**
   * The 'aria-label' prop is required because svgs don't have (at least the
   * ones provided by react-feather) an attribute to label themselves
   *
   * https://github.com/feathericons/react-feather/issues/67
   */
  'aria-label': PropTypes.string.isRequired,
  /**
   * One instantiated react-feather icon (e.g., <Camera />)
   */
  children: PropTypes.node.isRequired,
  /**
   * The className prop is used by @emotion when you use the
   * styled method on another component:
   *
   * const StyledSvgButton = styled(SvgButton)`
   *   color: #6764D1;
   * `;
   *
   * Note that the styles you are creating will be applied to the parent button of the SVG.
   *
   * https://emotion.sh/docs/styled#styling-any-component
   */
  className: PropTypes.string,
  /**
   * Forwards this boolean value to the HTML button.disabled attribute
   */
  isDisabled: PropTypes.bool,
  /**
   * Click event triggered by the parent button of the SVG.
   */
  onClick: PropTypes.func.isRequired,
  /**
   * You can pass other props including any number of aria attributes and the component will use them "as is"
   * (the component reads them using the ...rest operator). Note that aria-label is required
   * and it's validated as a separate prop.
   */
  rest: PropTypes.shape({}),
  /**
   * Button height and width value.
   */
  size: PropTypes.string,
  /**
   * Text to be displayed on hover.
   */
  tooltip: PropTypes.string,
};

SvgButton.defaultProps = {
  className: null,
  isDisabled: false,
  rest: null,
  size: '2.75rem',
  tooltip: '',
};

export default SvgButton;
