import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import get from 'lodash/get';
import { ChevronDown, ChevronUp } from 'react-feather';
import Button from '../Button/Button';
import styles from './Table.module.css';
import CheckBox from '../CheckBox/CheckBox';

const TableRowActions = ({ rowActionsClassName, rowActions, dataItem, index }) => {
    if (!rowActions.length) {
        return null;
    }

    return (
      <td className={cn(styles.col, styles.colActions, rowActionsClassName)}>
          {rowActions.map((rowAction, actionKey) => {
              if (!rowAction || (rowAction.displayCallback && !rowAction.displayCallback(dataItem))) {
                  return null;
              }

              if (rowAction.children) {
                  return rowAction.children(dataItem);
              }

              const actionProps = {};
              if (rowAction.link) {
                  actionProps.link = rowAction.link(dataItem);
              }

              if (rowAction.href) {
                  actionProps.href = rowAction.href(dataItem);
              }

              if (rowAction.onClick) {
                  actionProps.onClick = () => {
                      rowAction.onClick(dataItem, index)();
                  };
              }

              return (
                <Button
                  tiny
                  tip={rowAction.title}
                  tipClass={rowAction.tipClass}
                  key={actionKey}
                  className={cn(styles.actionButton, rowAction.className)}
                  {...rowAction}
                  {...actionProps}
                />
              );
          })}
      </td>
    );
};

TableRowActions.propTypes = {
    dataItem: PropTypes.any,
    rowActions: PropTypes.array,
    rowActionsClassName: PropTypes.string,
};

TableRowActions.defaultProps = {
    rowActions: [],
};

const TableRow = ({
    className,
    columns,
    dataItem,
    rowActions,
    innerRef,
    rowActionsClassName,
    hasRowActions,
    hasBatchActions,
    onSelectRow,
    selected,
    index,
    getInlineForm,
}) => {
  const [isVisibleRow, setVisibleRow] = useState(false);

  return (
    <>
      <tr className={cn(className, isVisibleRow && 'opened-row')} ref={innerRef}>
        {hasBatchActions && (
          <td className={cn(styles.col, styles.batchCol)} onClick={() => onSelectRow(dataItem)}>
            <CheckBox checked={selected} />
          </td>
        )}
        {columns.map((column, columnKey) => {
          let renderedItem = ''
          const value = get(dataItem, column.key)

          if (column.renderer) {
            const CurrentComponent = column.renderer
            renderedItem = (
              <CurrentComponent value={value} row={dataItem} column={column.key} params={column.params} index={index} />
            )
          } else {
            renderedItem = <span className={styles.value}>{value}</span>
          }

          const clnm = 'td-name-' + (column.key || '').replaceAll('.', '-')

          return (
            <td key={columnKey} className={cn(styles.col, column.className, clnm)} width={column.width}>
              {renderedItem}
            </td>
          )
        })}
        {!!getInlineForm && (
          <td className={cn(styles.col, styles.batchCol)} onClick={() => setVisibleRow(!isVisibleRow)}>
            {isVisibleRow ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </td>
        )}
        {hasRowActions && (
          <TableRowActions
            rowActions={rowActions}
            dataItem={dataItem}
            rowActionsClassName={rowActionsClassName}
            index={index}
          />
        )}
      </tr>
      {!!getInlineForm && isVisibleRow && (
        <tr className="opened-row-alter">
          <td colSpan={columns.length + 1}>{getInlineForm(dataItem)}</td>
        </tr>
      )}
      <style jsx="true">{`
        tr:nth-child(even) {
          &.opened-row {
            background: #ecfcf8;
          }
          &.opened-row-alter {
            background: #fbfcec;
          }
        }
        tr:nth-child(odd) {
          &.opened-row {
            background: #fbfcec;
          }
          &.opened-row-alter {
            background: #ecfcf8;
          }
        }
      `}</style>
    </>
  )
};

TableRow.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array,
  rowActions: PropTypes.array,
  dataItem: PropTypes.any,
  hasRowActions: PropTypes.bool,
  rowActionsClassName: PropTypes.string,
  hasBatchActions: PropTypes.bool,
  onSelectRow: PropTypes.func,
  selected: PropTypes.bool,
  index: PropTypes.number,
  getInlineForm: PropTypes.func,
};

TableRow.defaultProps = {
  columns: [],
  getInlineForm: null,
};

export default TableRow;
