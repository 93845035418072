import isUndefined from 'lodash/isUndefined';
export { default as httpRequests } from './httpRequests';
export { default as useApiUrl } from './useApiUrl';
export { default as getApiUrl } from './getApiUrl'

/**
 * A helper for reformatting data to preparing objects for select component
 */
export const prepareOptions = (data, labelField = 'name') => data.map(type => ({
  label: typeof labelField === 'function' ? labelField(type) : type[labelField],
  value: type.entityId,
}))

/**
 * A helper for reformatting object to a camel case
 * Traverses the entire object to turn all keys to camel case
 * @param {Object} object The target object to deeply camel case transform keys
 */
export const asCamelCase = object => {
  if (Array.isArray(object)) {
    return object.map(val => asCamelCase(val));
  }
  if (object !== null && object.constructor === Object) {
    return Object.keys(object).reduce(
      (result, key) => ({
        ...result,
        [key.replace(/([-_][a-z])/gi, $1 => {
          return $1.toUpperCase().replace('-', '').replace('_', '');
        })]: asCamelCase(object[key]),
      }),
      {},
    );
  }
  return object;
};

/**
 * A helper for reformatting object to a snake case
 * Traverses the entire object to turn all keys to snake case
 * @param {Object} object The target object to deeply snake case transform keys
 */
export const asSnakeCase = object => {
  if (Array.isArray(object)) {
    return object.map(val => asSnakeCase(val));
  }
  if (object !== null && !isUndefined(object) && object.constructor === Object) {
    return Object.keys(object).reduce(
      (result, key) => ({
        ...result,
        [key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)]: asSnakeCase(object[key]),
      }),
      {},
    );
  }
  return object;
};

/**
 * Function only invokes func at most once per every wait milliseconds
 * @param {Function} func
 * @param {Number} delay
 * @returns Function
 */
export const throttle = (func, delay = 1000) => {
  let shouldWait = false;
  let waitingArgs;
  const timeoutFunc = () => {
    if (waitingArgs == null) {
      shouldWait = false;
    } else {
      func(...waitingArgs);
      waitingArgs = null;
      setTimeout(timeoutFunc, delay);
    }
  };

  const throttled = (...args) => {
    if (shouldWait) {
      waitingArgs = args;
      return;
    }

    func(...args);
    shouldWait = true;
    setTimeout(timeoutFunc, delay);
  };

  throttled.cancel = () => {
    shouldWait = false;
  };

  return throttled;
};
