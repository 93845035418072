import React from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import isUndefined from "lodash/isUndefined";
import styled from "@emotion/styled/macro";
import { StyledLabel } from "./Input";
import ValidationErrorMessage from "./ValidationErrorMessage";
import down from "./Icon/svg/chevron-down.svg";
import up from "./Icon/svg/chevron-up.svg";

export const getSelectId = name => `select-${name}`;

export const prepareTreeSelectData = (data, namePrefix = '', currentId = null) => (data.map(item => ({
  checked: !!currentId && currentId === item.entityId,
  label: item.name,
  tagLabel: namePrefix + item.name,
  value: item.entityId,
  children: !isUndefined(item.children)
    ? prepareTreeSelectData(item.children, namePrefix + item.name + ', ', currentId)
    : [],
})));

const hasError = props => props.hasError || !!props.error;

const TreeSelectWrapper = styled.div`
  position: relative;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
  margin-bottom: 0.75rem;
  
  * {
    box-sizing: border-box;
  }

  &:focus-within label {
    color: ${props => (hasError(props) ? '#E21F14' : '#6764D1')};
  }

  .react-dropdown-tree-select {
    .dropdown {
      position: relative;
      width: 100%;

      .dropdown-trigger .tag {
        display: inline-block;
        padding: 2rem 0 0 0;
        
        .tag-remove {
          margin-left: 0.5rem;
          text-transform: uppercase;
          cursor: pointer;
        }
      }

      input.search {
        width: 100%;
        border: none;
        border-bottom: solid 1px #192434;
        outline: none;
        padding: 2rem 0 0.75rem 0;
        font-size: 1rem;
      }

      .tag-list::after {
        position: absolute;
        bottom: 0.75rem;
        right: 0;
        content: url(${down});
      }

      .dropdown-content {
        position: absolute;
        padding: 4px;
        z-index: 999;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid rgba(25, 59, 103, 0.05);
        box-shadow: 0 8px 24px -4px rgba(28, 50, 79, 0.19), 0px 2px 6px -1px rgba(28, 55, 90, 0.12);
        border-radius: 4px;

        ul li:hover {
          background: #e5f0fe;
          border-radius: 4px;            
        }
      }

      .node {
        display: block;
        list-style: none;
        white-space: nowrap;
        
        label {
          display: block;
          color: rgba(25, 39, 57, 0.94);
          cursor: pointer;
          padding: 0.5rem 1rem 0.5rem 0.5rem;
        }
      }

      .toggle {
        position: absolute;
        cursor: pointer;
        font-style: normal;
        width: 1.5rem;
        text-align: right;
        right: 0;
        padding: 0.5rem 0.5rem 0.5rem 0;

        &::after {
          content: ' ';
        }

        &.collapsed::after {
          content: url(${down});
        }

        &.expanded::after {
          content: url(${up});
        }
      }

      .node.leaf.collapsed,
      .searchModeOn .toggle,
      .checkbox-item,
      .radio-item {
        display: none;
      }
    }
  }
`;

export default function TreeSelect({
  data,
  error,
  label,
  maxWidth,
  name,
  onChange,
  placeholder,
}) {
  const inputId = getSelectId(name);

  return (
    <TreeSelectWrapper maxWidth={maxWidth}>
      <StyledLabel
        hasError={!!error}
        htmlFor={inputId}
      >
        {label}
      </StyledLabel>
      <DropdownTreeSelect
        data={data}
        onChange={onChange}
        mode="radioSelect"
        texts={{ placeholder: placeholder || "Пошук" }}
        keepTreeOnSearch={true}
        keepChildrenOnSearch={true}
      />
      {!!error && <ValidationErrorMessage error={error} id={inputId} />}
    </TreeSelectWrapper>
  );
}
