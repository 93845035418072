import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

const EmptyP = styled.p`
  margin: ${({ margin }) => margin};
  text-align: center;
`;

const NoItemsMessage = ({ margin, message }) => {
  const msg = message || "Нема даних";
  return (
    <EmptyP data-testid="no-items-message" margin={margin}>
      {msg}
    </EmptyP>
  );
};

NoItemsMessage.propTypes = {
  margin: PropTypes.string,
  message: PropTypes.string,
};

NoItemsMessage.defaultProps = {
  margin: '2rem',
  message: '',
};

export default NoItemsMessage;
