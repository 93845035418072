const options = {
  'There are links to the archived object': 'Запис не може бути заархівовано, оскільки він використовується іншиби об`єктами',
  'MSG BETWEEN "BLUE" PLAYERS': 'Msg між "синіми" гравцями',
  'MSG ADDRESSED TO PLAYERS TO CONTROL THE EXERCISE': 'MSG звертався до гравців для контролю вправ',
  'MSG FOR DISTAFF OR DICONSTAFF ONLY': 'MSG лише для distaff або diconstaff',
  'MSG FOR TEST OR PRACTICE NOT RELATED TO THE EXERCISE': 'MSG для тесту або практики, не пов\'язаної з вправою',
  'MSG NOT PART OF PLAY BUT AFFECTING THE EXERCISE': 'Msg не частина гри, а впливає на вправу',
  'MSG INTERCEPTION NOT FOR USE IN DIRECTION FINDING': 'Перехоплення MSG не для використання у пошуку напрямку',
  'MSG BETWEEN "ORANGE" PLAYERS': 'Msg між "помаранчевим" гравцями',
  'MSG ORIGINATED BY A COMMANDER ASSIGNED A "PURPLE" ROLE': 'MSG зародився командувачем, призначений "Purple" роль',
  'MSG ORIGINATED BY AN UMPIRE': 'MSG зародився за допомогою суду',
  'MSG ADDRESSED TO UMPIRES ONLY': 'MSG, адресований лише ампіам',
  'JANUARY': 'Січень',
  'FEBRUARY': 'Лютий',
  'MARCH': 'Березень',
  'APRIL': 'Квітень',
  'MAY': 'Травень',
  'JUNE': 'Червень',
  'JULY': 'Липень',
  'AUGUST': 'Серпень',
  'SEPTEMBER': 'Вересень',
  'OCTOBER': 'Жовтень',
  'NOVEMBER': 'Листопад',
  'DECEMBER': 'Грудень',
  'TIME DATA DELIMITER': 'Розмежування даних часу',
  'UNIVERSAL TIME COORDINATE (UTC)': 'Універсальна координата часу (UTC)',
  'AMPLIFYING': 'Посилення',
  'BLOCK TIME PERIODS.': 'Блокувати періоди часу.',
  'DIRECTS A SEQUENTIAL CHANGE TO A PREVIOUSLY SENT MESSAGE.': 'Спрямовує послідовну зміну на попереднє повідомлення.',
  'DEVIATION': 'Відхилення',
  'FINAL': 'Остаточний',
  'FOLLOW-UP': 'СЛІДУВАТИ',
  'INITIAL': 'Початковий',
  'NEW': 'Новачок',
  'PERMANENT': 'Постійний',
  'REQUEST': 'Прохання',
  'UPDATE': 'Оновити',
  'The security classification of an UNCLASSIFIED information.': 'Класифікація безпеки некласифікованої інформації.',
  'The security classification of a RESTRICTED information.': 'Класифікація безпеки обмеженої інформації.',
  'The security classification of a CONFIDENTIAL information.': 'Класифікація безпеки конфіденційної інформації.',
  'The security classification of a SECRET information.': 'Класифікація безпеки секретної інформації.',
  'The security classification of a TOP SECRET information.': 'Класифікація безпеки провідної секретної інформації.',
  'CONFERENCE/MEETING': 'Конференція/зустріч',
  'DOCUMENT': 'Документ',
  'ELECTRONIC MAIL': 'ЕЛЕКТРОННА ПОШТА',
  'LETTER/MEMORANDUM': 'Лист/меморандум',
  'MESSAGE (NOT FORMATTED)': 'Повідомлення (не відформатовано)',
  'TELEPHONE': 'Телефон',
  'VIDEO TELECONFERENCE': 'Відео Телеконференція',
  'UTC PLUS 1 HOUR': 'UTC плюс 1 година',
  'UTC PLUS 2 HOURS': 'UTC плюс 2 години',
  'UTC PLUS 3 HOURS': 'UTC плюс 3 години',
  'UTC PLUS 4 HOURS': 'UTC плюс 4 години',
  'UTC PLUS 5 HOURS': 'UTC плюс 5 годин',
  'UTC PLUS 6 HOURS': 'UTC плюс 6 годин',
  'UTC PLUS 7 HOURS': 'UTC плюс 7 годин',
  'UTC PLUS 8 HOURS': 'UTC плюс 8 годин',
  'UTC PLUS 9 HOURS': 'UTC плюс 9 годин',
  'UTC PLUS 10 HOURS': 'UTC плюс 10 годин',
  'UTC PLUS 11 HOURS': 'UTC плюс 11 годин',
  'UTC PLUS 12 HOURS': 'UTC плюс 12 годин',
  'UTC MINUS 1 HOUR': 'UTC мінус 1 година',
  'UTC MINUS 2 HOURS': 'UTC мінус 2 години',
  'UTC MINUS 3 HOURS': 'UTC мінус 3 години',
  'UTC MINUS 4 HOURS': 'UTC мінус 4 години',
  'UTC MINUS 5 HOURS': 'UTC мінус 5 годин',
  'UTC MINUS 6 HOURS': 'UTC мінус 6 годин',
  'UTC MINUS 7 HOURS': 'UTC мінус 7 годин',
  'UTC MINUS 8 HOURS': 'UTC мінус 8 годин',
  'UTC MINUS 9 HOURS': 'UTC мінус 9 годин',
  'UTC MINUS 10 HOURS': 'UTC мінус 10 годин',
  'UTC MINUS 11 HOURS': 'UTC мінус 11 годин',
  'UTC MINUS 12 HOURS': 'UTC мінус 12 годин',
  'Being passed separately.': 'Проходять окремо.',
  'Not to all nor needed by all addressees.': 'Не всім і не потрібні всім адресатам.',
  'CANCEL': 'Скасувати',
  'TRANSMIT': 'Передавати',
  'ADDITIONAL POINTS': 'Додаткові бали',
  'LAST TARGET': 'Остання ціль',
  'APPLICABLE TO 155MM.': 'Застосовується до 155 мм.',
  'APPLICABLE TO 105MM, 155MM, 203MM, NGF, AND LANCE.': 'Застосовується до 105 мм, 155 мм, 203 мм, NGF та Lance.',
  'APICM ANTI-PERSONNEL SUBMUNITIONS': 'APICM підрозділи анти-Персоналу',
  'APPLICABLE TO 155MM, 203MM AND MLRS.': 'Застосовується до 155 мм, 203 мм та MLR.',
  'APPLICABLE TO 105MM, 155MM, 203MM, AND LANCE.': 'Застосовується до 105 мм, 155 мм, 203 мм і Ленса.',
  'APPLICABLE TO 105MM, 155MM AND 203MM.': 'Застосовується до 105 мм, 155 мм і 203 мм.',
  'DPICMM': 'DPICMM',
  'DPICM ANTI-PERSONNEL & ANTI-MATERIAL SUBMUNITIONS': 'DPICM проти особистості та антиматеріальних підрозділів',
  'REDUCED RANGE PRACTICE ROCKET': 'Ракета з зменшенням діапазону',
  'EXTENDED RANGE ROCKET': 'Ракета розширеного діапазону',
  'GE/UK REDUCED RANGE PRACTICE ROCKET': 'Ge/uk зменшений діапазон Ракета',
  'ANTI-TANK MINE SSD (SHORT SELF DESTRUCT TIME)': 'Протитанкова шахта SSD (короткий час самознищення)',
  'MLRS TERMINALLY GUIDED WARHEAD': 'MLRS Термінально керована боєголовка',
  'MLRS SADARM': 'Mlrs sadarm',
  'ATCMS BRILLIANT ANTI-TANK': 'ATCMS геніальний протитанковий',
  'APPLICABLE TO 155MM AND 203MM.': 'Застосовується до 155 мм і 203 мм.',
  'APPLICABLE TO 105MM AND 155MM.': 'Застосовується до 105 мм і 155 мм.',
  'APPLICABLE TO 105MM, 155MM, 203MM AND NGF.': 'Застосовується до 105 мм, 155 мм, 203 мм і NGF.',
  'APPLICABLE TO 105MM, 155MM, 203MM, MLRS AND NGF.': 'Застосовується до 105 мм, 155 мм, 203 мм, MLR та NGF.',
  'APPLICABLE TO 105MM, 155MM, 203MM AND NGF EXCEPT 16IN50.': 'Застосовується до 105 мм, 155 мм, 203 мм і NGF, крім 16in50.',
  'IRELAND 1965 (IRELAND)': 'Ірландія 1965 р. (Ірландія)',
  'ADINDAN (BURKINA FASO)': 'Adindan (Burkina Faso)',
  'ADINDAN (CAMEROON)': 'Адіндан (Камерун)',
  'ADINDAN (ETHIOPIA)': 'Адіндан (Ефіопія)',
  'ADINDAN (MALI)': 'Адіндан (Малі)',
  'ADINDAN (SENEGAL)': 'Адіндан (Сенегал)',
  'ADINDAN (SUDAN AND ETHIOPIA)': 'Адіндан (Судан та Ефіопія)',
  'ADINDAN (SUDAN)': 'Адіндан (Судан)',
  'AFGOOYE (SOMALIA)': 'Afgooye (Сомалі)',
  'AIN EL ABD 1970 (BAHRAIN ISLAND)': 'Ain El Abd 1970 (острів Бахрейн)',
  'AIN EL ABD 1970 (SAUDI ARABIA)': 'Ain El Abd 1970 (Саудівська Аравія)',
  'AMERICAN SAMOA 1962 (AMERICAN SAMOA ISLANDS)': 'Американський Самоа 1962 р. (Американські острови Самоа)',
  'ANNA 1 ASTRO 1965 (COCOS ISLAND)': 'Анна 1 Astro 1965 (острів Кокос)',
  'ANTIGUA ISLAND ASTRO 1943 (ANTIGUA, LEEWARD ISLANDS)': 'Острів Антігуа Астро 1943 (Антігуа, Левардські острови)',
  'ARC 1950 (BOTSWANA)': 'ARC 1950 (Ботсвана)',
  'ARC 1950 (BOTSWANA, LESOTHO, MALAWI, SWAZILAND, ZAIRE, ZAMBIA, ZIMBABWE)': 'ARC 1950 (Ботсвана, Лесото, Малаві, Свазіленд, Зар, ​​Замбія, Зімбабве)',
  'ARC 1950 (BURUNDI)': 'ARC 1950 (Бурунді)',
  'ARC 1950 (LESOTHO)': 'Дуга 1950 (Лесото)',
  'ARC 1950 (MALAWI)': 'Дуга 1950 (Малаві)',
  'ARC 1950 (SWAZILAND)': 'ARC 1950 (Свазіленд)',
  'ARC 1950 (ZAIRE)': 'ARC 1950 (Заїр)',
  'ARC 1950 (ZAMBIA)': 'ARC 1950 (Замбія)',
  'ARC 1950 (ZIMBABWE)': 'ARC 1950 (Зімбабве)',
  'ARC 1960 (KENYA AND TANZANIA)': 'Arc 1960 (Кенія та Танзанія)',
  'ARC 1960 (KENYA)': 'Arc 1960 (Кенія)',
  'ARC 1960 (TANZANIA)': 'Arc 1960 (Танзанія)',
  'ASCENSION ISLAND 1958 (ASCENSION ISLAND)': 'Острів Вознесіння 1958 р. (Острів Вознесіння)',
  'ASTRO BEACON \E\" 1945 (IWO JIMA)"': 'Astro Beacon \ e \ "1945 (Iwo Jima)"',
  'ASTRO DOS 71/4 (ST. HELENA ISLAND)': 'Astro DOS 71/4 (Св. Острів Гелена)',
  'ASTRO TERN ISLAND (FRIG) 1961 (TERN ISLAND)': 'Астро Терн -острів (Frig) 1961 (острів Терн)',
  'ASTRONOMICAL STATION 1952 (MARCUS ISLAND)': 'Астрономічна станція 1952 (острів Маркус)',
  'AUSTRALIAN GEODETIC 1966 (AUSTRALIA AND TASMANIA)': 'Австралійський геодетник 1966 року (Австралія та Тасманія)',
  'AUSTRALIAN GEODETIC 1984 (AUSTRALIA AND TASMANIA)': 'Австралійський геодетник 1984 (Австралія та Тасманія)',
  'AYABELLE LIGHTHOUSE (DJIBOUTI)': 'Маяк Аябел (Djibouti)',
  'BELLEVUE (IGN) (EFATE AND ERROMANGO ISLANDS)': 'Bellevue (IGN) (острови Efate та Erromango)',
  'BERMUDA 1957 (BERMUDA ISLANDS)': 'Бермуди 1957 р. (Бермудські острови)',
  'BISSAU (GUINEA-BISSAU)': 'Біссау (Гвінея-Біссау)',
  'BOGOTA OBSERVATORY (COLOMBIA)': 'Обсерваторія Боготи (Колумбія)',
  'BUKIT RIMPAH (BANGKA AND BELITUNG ISLANDS (INDONESIA))': 'Bukit Rimpah (Банка та Белітунські острови (Індонезія))',
  'CAMP AREA ASTRO (CAMP MCMURDO AREA, ANTARCTICA)': 'Кемп -район Астро (район табору Макмурдо, Антарктида)',
  'CAMPO INCHAUSPE 1969 (ARGENTINA)': 'Campo Incauspe 1969 (Аргентина)',
  'CANTON ASTRO 1966 (PHOENIX ISLANDS)': 'Canton Astro 1966 (Фенікс -острови)',
  'CAPE (SOUTH AFRICA)': 'Мис (Південна Африка)',
  'CAPE CANAVERAL (FLORIDA AND BAHAMAS)': 'Кейп Канаверал (Флорида та Багами)',
  'CARTHAGE (TUNISIA)': 'Карфаген (Туніс)',
  'CHATHAM ISLAND ASTRO 1971 (CHATHAM ISLAND (NEW ZEALAND))': 'Острів Чатем Астро 1971 (острів Чатем (Нова Зеландія))',
  'CHUA ASTRO (PARAGUAY)': 'Чуа Астро (Парагвай)',
  'CO-ORDINATE SYSTEM 1937 OF ESTONIA (ESTONIA)': 'Координатна система 1937 р. Естонія (Естонія)',
  'CORREGO ALEGRE (BRAZIL)': 'Corrego Alegre (Бразилія)',
  'DABOLA (GUINEA)': 'Дабола (Гвінея)',
  'DECEPTION ISLAND (DECEPTION ISLAND, ANTARCTICA)': 'Острів обману (Острів обману, Антарктида)',
  'DJAKARTA (BATAVIA) (SUMATRA (INDONESIA))': 'Djakarta (Batavia) (Суматра (Індонезія))',
  'DOS 1968 (GIZO ISLAND (NEW GEORGIA ISLANDS))': 'DOS 1968 (острів Гізо (нові острови Джорджія))',
  'EASTER ISLAND 1967 (EASTER ISLAND)': 'Острів Великодня 1967 р. (Острів Великодня)',
  '(Austria, Belgium, Denmark, Finland, France, FRG (Federal Republic of Germany), Gibraltar, Greece, Italy, Luxembourg, Netherlands, Norway, Portugal, Spain, Sweden and Switzerland)': '(Австрія, Бельгія, Данія, Фінляндія, Франція, ФРГ (Федеральна Республіка Німеччина), Гібралтар, Греція, Італія, Люксембург, Нідерланди, Норвегія, Португалія, Іспанія, Швеція та Швейцарія)',
  'EUROPEAN 1950 (CYPRUS)': 'Європейський 1950 р. (Кіпр)',
  'EUROPEAN 1950 (EGYPT)': 'Європейський 1950 р. (Єгипет)',
  'EUROPEAN 1950 (ENGLAND, CHANNEL ISLANDS, SCOTLAND AND SHETLAND ISLANDS)': 'Європейський 1950 р. (Англія, острови на каналі, острови Шотландії та Шетланд)',
  'EUROPEAN 1950 (ENGLAND, IRELAND, SCOTLAND AND SHETLAND ISLANDS)': 'Європейський 1950 р. (Англія, Ірландія, Шотландія та Шетландські острови)',
  'EUROPEAN 1950 (GREECE)': 'Європейський 1950 р. (Греція)',
  'EUROPEAN 1950 (IRAN)': 'Європейський 1950 р. (Іран)',
  'EUROPEAN 1950 (IRAQ, ISRAEL, JORDAN, KUWAIT, LEBANON, SAUDI ARABIA AND SYRIA)': 'Європейський 1950 р. (Ірак, Ізраїль, Йордан, Кувейт, Ліван, Саудівська Аравія та Сирія)',
  'EUROPEAN 1950 (MALTA)': 'Європейський 1950 р. (Мальта)',
  'EUROPEAN 1950 (NORWAY AND FINLAND)': 'Європейський 1950 р. (Норвегія та Фінляндія)',
  'EUROPEAN 1950 (SARDINIA (ITALY))': 'Європейський 1950 р. (Сардинія (Італія))',
  'EUROPEAN 1950 (SICILY (ITALY))': 'Європейський 1950 р. (Сицилія (Італія))',
  'EUROPEAN 1950 (SPAIN AND PORTUGAL)': 'Європейський 1950 р. (Іспанія та Португалія)',
  'EUROPEAN 1950 (TUNISIA)': 'Європейський 1950 р. (Туніс)',
  '(Western Europe (Limited to Austria, Denmark, France, FRG (Federal Republic of Germany) Netherlands and Switzerland))': '(Західна Європа (обмежена Австрією, Данія, Франція, ФРГ (Федеральна Республіка Німеччина) Нідерланди та Швейцарія))',
  '(Austria, Finland, Netherlands, Norway, Spain, Sweden and Switzerland)': '(Австрія, Фінляндія, Нідерланди, Норвегія, Іспанія, Швеція та Швейцарія)',
  'FORT THOMAS 1955 (LEEWARD,NEVIS,ST. KITTS, ISLAND)': 'Форт Томас 1955 р. (Leeward, Nevis, St. Kitts, Island)',
  'GAN 1970 (REPUBLIC OF MALDIVES)': 'Ган 1970 р. (Республіка Мальдіви)',
  'GEODETIC DATUM 1949 (NEW ZEALAND)': 'Геодетична дата 1949 р. (Нова Зеландія)',
  '(Graciosa, Pico, Sao Jorge, Tercira and Faial Islands (Azores))': '(Graciosa, Pico, Sao Jorge, Tercira та Faial Allass (Азорські))',
  'GUAM 1963 (GUAM)': 'Гуам 1963 (Гуам)',
  'GUNUNG SEGARA (KALIMANTAN (INDONESIA))': 'Гунунг Сегара (Калімантан (Індонезія))',
  'GUX 1 ASTRO (GUADALCANAL ISLAND)': 'Gux 1 Astro (Острів Гуадалканал)',
  'HERAT NORTH (AFGHANISTAN)': 'Герат Північ (Афганістан)',
  '(Yugoslavia (prior to 1990) Slovenia, Croatia, Bosnia and Herzegovina, Serbia)': '(Югославія (до 1990 р.) Словенія, Хорватія, Боснія та Герцеговина, Сербія)',
  'HJORSEY 1955 (ICELAND)': 'Hjorsey 1955 (Ісландія)',
  'HONG KONG 1963 (HONG KONG)': 'Гонконг 1963 (Гонконг)',
  'HU-TZU-SHAN (TAIWAN)': 'Ху-цу-Шан (Тайвань)',
  'INDIAN (BANGLADESH)': 'Індійський (Бангладеш)',
  'INDIAN (INDIA AND NEPAL)': 'Індійський (Індія та Непал)',
  'INDIAN (PAKISTAN)': 'Індійський (Пакистан)',
  'INDIAN 1954 (THAILAND)': 'Індійський 1954 (Таїланд)',
  'INDIAN 1960 (CON SON ISLAND (VIETNAM))': 'Індійський 1960 р. (Острів Конн (В\'єтнам))',
  'INDIAN 1960 (VIETNAM (NEAR 16° N))': 'Індійський 1960 р. (В\'єтнам (близько 16 ° с))',
  '6 Satellite Stations Used': '6 Використовувані супутникові станції',
  'INDONESIAN 1974 (INDONESIA)': 'Індонезійський 1974 (Індонезія)',
  'ISTS 061 ASTRO 1968 (SOUTH GEORGIA ISLAND)': 'ISTS 061 ASTRO 1968 (Острів Південної Джорджії)',
  'ISTS 073 ASTRO 1969 (DIEGO GARCIA)': 'ISTS 073 Astro 1969 (Дієго Гарсія)',
  'JOHNSTON ISLAND 1961 (JOHNSTON ISLAND)': 'Острів Джонстон 1961 (острів Джонстон)',
  'KANDAWALA (SRI LANKA)': 'Кандавала (Шрі -Ланка)',
  'KERGUELEN ISLAND 1949 (KERGUELEN ISLAND)': 'Острів Кергюлен 1949 р. (Острів Кергюлен)',
  'KERTAU 1948 (WEST MALAYSIA AND SINGAPORE)': 'Кертау 1948 р. (Західна Малайзія та Сінгапур)',
  'KUSAIE ASTRO 1951 (CAROLINE ISLANDS, FEDERAL STATES OF MICRONESIA)': 'Kusaie Astro 1951 (Каролінські острови, федеральні штати Мікронезія)',
  'L.C. 5 ASTRO 1961 (CAYMAN BRAC ISLAND)': 'L.C. 5 Astro 1961 (острів Кайман Брач)',
  'LEIGON (GHANA)': 'Лейгон (Гана)',
  'LIBERIA 1964 (LIBERIA)': 'Ліберія 1964 (Ліберія)',
  'LUZON (MINDANAO ISLAND)': 'Лусон (острів Мінданао)',
  'LUZON (PHILIPPINES (EXCLUDING MINDANAO ISLAND))': 'Лусон (Філіппіни (за винятком острова Мінданао))',
  "M'PORALOKO (GABON)": 'M\'Poraloko (Габон)',
  'MAHE 1971 (MAHE ISLAND)': 'Махе 1971 (острів Махе)',
  'MASSAWA (ERITREA (ETHIOPIA))': 'Массава (Еритрея (Ефіопія))',
  'MERCHICH (MOROCCO)': 'Мерчіч (Марокко)',
  'MIDWAY ASTRO 1961 (MIDWAY ISLAND)': 'Midway Astro 1961 (Midway Island)',
  'MINNA (CAMEROON)': 'Мінна (Камерун)',
  'MINNA (NIGERIA)': 'Мінна (Нігерія)',
  'MONTSERRAT ISLAND ASTRO 1958 (LEEWARD, MONTSERRAT ISLANDS)': 'Монтсеррат Астро 1958 р.',
  'NAHRWAN (MASIRAH ISLAND (OMAN))': 'Нарван (острів Масіра (Оман))',
  'NAHRWAN (SAUDI ARABIA)': 'Нарван (Саудівська Аравія)',
  'NAHRWAN (UNITED ARAB EMIRATES)': 'Нарван (Об\'єднані Арабські Емірати)',
  'NAPARIMA, BWI (TRINIDAD AND TOBAGO)': 'Naparima, BWI (Тринідад і Тобаго)',
  'NORTH AMERICAN 1927 (ALASKA (EXCLUDING ALEUTIAN ISLANDS))': 'Північноамериканський 1927 р. (Аляска (за винятком алеутських островів))',
  'NORTH AMERICAN 1927 (ALBERTA AND BRITISH COLUMBIA)': 'Північна Америка 1927 р. (Альберта та Британська Колумбія)',
  'NORTH AMERICAN 1927 (ALEUTIAN ISLANDS-EAST OF 180° W)': 'Північноамериканський 1927 р. (Алеутські острови-схід 180 ° Ш)',
  'NORTH AMERICAN 1927 (ALEUTIAN ISLANDS-WEST OF 180° W)': 'Північноамериканський 1927 р. (Алейтські острови-захід 180 ° Ш)',
  'NORTH AMERICAN 1927 (BAHAMAS (EXCLUDING SAN SALVADOR ISLAND))': 'Північноамериканський 1927 р. (Багами (за винятком острова Сан -Сальвадор))',
  'NORTH AMERICAN 1927 (CANADA MEAN SOLUTION / (INCLUDING NEWFOUNDLAND))': 'Північноамериканський 1927 р. (Середнє рішення в Канаді (включаючи Ньюфаундленд))',
  'NORTH AMERICAN 1927 (CANAL ZONE)': 'Північна Америка 1927 р. (Зона каналу)',
  '(Caribbean (Antigua Island, Barbados, Barbuda, Caicos Island, Cuba, Dominican Republic, Grand Cayman, Jamaica and Turks Islands))': '(Карибський басейн (острів Антігуа, Барбадос, Барбуда, острів Кайкос, Куба, Домініканська Республіка, Великий Кайман, Ямайка та Турки)))',
  '(Central America (Belize, Costa Rica, El Salvador, Guatemala, Honduras and Nicaragua))': '(Центральна Америка (Беліз, Коста -Ріка, Сальвадор, Гватемала, Гондурас та Нікарагуа))',
  'NORTH AMERICAN 1927 (CUBA)': 'Північна Америка 1927 р. (Куба)',
  '(Eastern Canada (Newfoundland, New Brunswick, Nova Scotia and Quebec))': '(Східна Канада (Ньюфаундленд, Нью -Брансвік, Нова Шотландія та Квебек))',
  '(Eastern United States (Alabama, Connecticut, Delaware, District Of Columbia, Florida, Georgia, Illinois, Indiana, Kentucky, Louisiana, Maine, Maryland, Massachusetts, Michigan, Minnesota, Mississippi, Missouri, New Hampshire, New Jersey, New York, North Carolina, Ohio, Pennsylvania, Rhode Island, South Carolina, Tennessee, Vermont, Virginia, West Virginia and Wisconsin.))': '(Східні Сполучені Штати (Алабама, Коннектикут, Делавер, округ Колумбія, Флорида, Джорджія, Іллінойс, Індіана, Кентуккі, Луїзіана, Мен, Меріленд, Массачусетс, Мічиган, Міннесота, Міссісіпі, Міссурі, Нью -Гемпшир, Нью -Джерсі, Нью -Йорк, Північна Кароліна, Огайо, Пенсильванія, Род -Айленд, Південна Кароліна, Теннессі, Вермонт, Вірджинія, Західна Вірджинія та Вісконсін)))',
  'NORTH AMERICAN 1927 (GREENLAND (HAYES PENINSULA))': 'Північноамериканський 1927 р. (Гренландія (півострів Хейс))',
  'NORTH AMERICAN 1927 (MANITOBA AND ONTARIO)': 'Північноамериканський 1927 р. (Манітоба та Онтаріо)',
  'NORTH AMERICAN 1927 (MEXICO)': 'Північна Америка 1927 р. (Мексика)',
  'NORTH AMERICAN 1927 (SAN SALVADOR ISLAND)': 'Північноамериканський 1927 р. (Острів Сан -Сальвадор)',
  'NORTH AMERICAN 1927 (SASKATCHEWAN AND NORTHWEST TERRITORIES)': 'Північноамериканський 1927 р. (Саскачеван та північно -західні території)',
  '(Western United States (Arizona, Arkansas, California, Colorado, Idaho, Iowa, Kansas, Montana, Nebraska, Nevada, New Mexico, North Dakota, Oklahoma, Oregon, South Dakota, Texas, Utah, Washington and Wyoming))': '(Західні Сполучені Штати (Арізона, Арканзас, Каліфорнія, Колорадо, Айдахо, Айова, Канзас, Монтана, Небраска, Невада, Нью -Мексико, Північна Дакота, Оклахома, Орегон, Південна Дакота, Техас, штат Юта, Вашингтон та Вайомінг))',
  'NORTH AMERICAN 1927 (YUKON)': 'Північноамериканський 1927 р. (Юкон)',
  'NORTH AMERICAN 1983 (ALASKA (EXCLUDING ALEUTIAN ISLANDS))': 'Північна Америка 1983 р. (Аляска (за винятком алеутських островів))',
  'NORTH AMERICAN 1983 (ALEUTIAN ISLANDS)': 'Північна Америка 1983 р. (Алеутські острови)',
  'NORTH AMERICAN 1983 (CANADA)': 'Північна Америка 1983 р. (Канада)',
  'NORTH AMERICAN 1983 (CONUS)': 'Північна Америка 1983 р. (Conus)',
  'NORTH AMERICAN 1983 (HAWAII)': 'Північна Америка 1983 р. (Гаваї)',
  'NORTH AMERICAN 1983 (MEXICO AND CENTRAL AMERICA)': 'Північна Америка 1983 р. (Мексика та Центральна Америка)',
  'NORTH SAHARA 1959 (ALGERIA)': 'Північна Сахара 1959 (Алжир)',
  'OBSERVATORIO METEOROLOGICO 1939 (FLORES AND CORVO ISLANDS (AZORES))': 'Surstartatorio Meteorologico 1939 (Флорес та Корво -острови (азори))',
  'OLD EGYPTIAN 1907 (EGYPT)': 'Старий єгипетський 1907 р. (Єгипет)',
  'OLD HAWAIIAN (HAWAII)': 'Старий гавайський (Гаваї)',
  'OLD HAWAIIAN (KAUAI)': 'Старий гавайський (Кауай)',
  'OLD HAWAIIAN (MAUI)': 'Старий гавайський (Мауї)',
  'OLD HAWAIIAN (MEAN SOLUTION)': 'Старий гавайський (середнє рішення)',
  'OLD HAWAIIAN (OAHU)': 'Старий гавайський (Оаху)',
  'OMAN (OMAN)': 'Оман (Оман)',
  'ORDNANCE SURVEY OF GREAT BRITAIN 1936 (ENGLAND)': 'Опитування Орденів Великобританії 1936 р. (Англія)',
  'ORDNANCE SURVEY OF GREAT BRITAIN 1936 (ENGLAND, ISLE OF MAN AND WALES)': 'Опитування орденів Великобританії 1936 р. (Англія, Острів Мен і Уельс)',
  'ORDNANCE SURVEY OF GREAT BRITAIN 1936 (ENGLAND, ISLE OF MAN, SCOTLAND, SHETLAND ISLANDS AND WALES)': 'Опитування Орденса Великобританії 1936 р. (Англія, Острів Мен, Шотландія, Шетландські острови та Уельс)',
  'ORDNANCE SURVEY OF GREAT BRITAIN 1936 (SCOTLAND AND SHETLAND ISLANDS)': 'Опитування Ордена Великобританії 1936 р. (Шотландія та Шетландські острови)',
  'ORDNANCE SURVEY OF GREAT BRITAIN 1936 (WALES)': 'Опитування Орденів Великобританії 1936 р. (Уельс)',
  'PICO DE LAS NIEVES (CANARY ISLANDS)': 'Pico de Las Nieves (Канарські острови)',
  'PITCAIRN ASTRO 1967 (PITCAIRN ISLAND)': 'Pitcairn Astro 1967 (острів Піткерн)',
  'POINT 58 (BURKINA FASO AND NIGER)': 'Точка 58 (Буркіна -Фасо та Нігер)',
  'POINTE NOIRE 1948 (CONGO)': 'Pointe Noire 1948 (Конго)',
  'PORTO SANTO 1936 (PORTO SANTO AND MADEIRA ISLANDS)': 'Порто Санто 1936 р. (Порто Санто та Мадейра острови)',
  '(Bolivia, Chile, Colombia, Ecuador, Guyana, Peru and Venezuela)': '(Болівія, Чилі, Колумбія, Еквадор, Гайана, Перу та Венесуела)',
  'PROVISIONAL SOUTH AMERICAN 1956 (BOLIVIA)': 'Тимчасовий південноамериканський 1956 р. (Болівія)',
  'PROVISIONAL SOUTH AMERICAN 1956 (COLOMBIA)': 'Тимчасовий південноамериканський 1956 р. (Колумбія)',
  'PROVISIONAL SOUTH AMERICAN 1956 (ECUADOR)': 'Тимчасовий південноамериканський 1956 р. (Еквадор)',
  'PROVISIONAL SOUTH AMERICAN 1956 (GUYANA)': 'Тимчасовий південноамериканський 1956 р. (Гайана)',
  'PROVISIONAL SOUTH AMERICAN 1956 (NORTHERN CHILE (NEAR 19° S))': 'Тимчасовий південноамериканський 1956 р. (Північний Чилі (близько 19 ° с))',
  'PROVISIONAL SOUTH AMERICAN 1956 (PERU)': 'Тимчасовий південноамериканський 1956 р. (Перу)',
  'PROVISIONAL SOUTH AMERICAN 1956 (SOUTHERN CHILE (NEAR 43° S))': 'Тимчасовий південноамериканський 1956 р. (Південний Чилі (близько 43 ° S)',
  'PROVISIONAL SOUTH AMERICAN 1956 (VENEZUELA)': 'Тимчасовий південноамериканський 1956 р. (Венесуела)',
  'PROVISIONAL SOUTH CHILEAN 1963 (SOUTHERN CHILE (NEAR 53°S))': 'Тимчасовий південний чилійський 1963 р. (Південний Чилі (близько 53 ° S)',
  'PUERTO RICO (PUERTO RICO AND VIRGIN ISLANDS)': 'Пуерто -Рико (Пуерто -Рико та Віргінські острови)',
  'PULKOVO 1942 (S-42) (RUSSIA)': 'Pulkovo 1942 (S-42) (Росія)',
  'QATAR NATIONAL (QATAR)': 'Катар Національний (Катар)',
  'QORNOQ (SOUTH GREENLAND)': 'Qornoq (Південна Гренландія)',
  'REUNION (MASCARENE ISLANDS)': 'Об\'єднання (Маскаренські острови)',
  'ROME 1940 (SARDINIA (ITALY))': 'Рим 1940 р. (Сардинія (Італія))',
  'S-42 (PULKOVO 1942) (ALBANIA)': 'S-42 (Pulkovo 1942) (Албанія)',
  'S-42 (PULKOVO 1942) (CZECHOSLOVAKIA)': 'S-42 (Pulkovo 1942) (Чехословакія)',
  'S-42 (PULKOVO 1942) (HUNGARY)': 'S-42 (Pulkovo 1942) (Угорщина)',
  'S-42 (PULKOVO 1942) (KAZAKHSTAN)': 'S-42 (Pulkovo 1942) (Казахстан)',
  'S-42 (PULKOVO 1942) (LATVIA)': 'S-42 (Pulkovo 1942) (Латвія)',
  'S-42 (PULKOVO 1942) (POLAND)': 'S-42 (Pulkovo 1942) (Польща)',
  'S-42 (PULKOVO 1942) (ROMANIA)': 'S-42 (Pulkovo 1942) (Румунія)',
  'S-JTSK (CZECHOSLOVAKIA)': 'S-JTSK (Чехословакія)',
  'SANTO (DOS) 1965 (ESPIRITO SANTO ISLAND)': 'Santo (DOS) 1965 р. (Острів Еспіріто Санто)',
  'SAO BRAZ (SAO MIGUEL, SANTA MARIA ISLANDS (AZORES))': 'Сао Браз (Сао Мігель, Санта -Марія Острови (Азорес))',
  'SAPPER HILL 1943 (EAST FALKLAND ISLAND)': 'Sapper Hill 1943 (Східний Фолкленд -Айленд)',
  'SCHWARZECK (NAMIBIA)': 'Schwarzeck (Намібія)',
  'SELVAGEM GRANDE 1938 (SALVAGE ISLANDS)': 'Selvagem Grande 1938 (острови для порятунку)',
  'SIERRA LEONE 1960 (SIERRA LEONE)': 'Sierra Leone 1960 (Sierra Leone)',
  '(Argentina, Bolivia, Brazil, Chile, Colombia, Ecuador, Guyana, Paraguay, Peru, Trinidad and Tobago and Venezuela)': '(Аргентина, Болівія, Бразилія, Чилі, Колумбія, Еквадор, Гайана, Парагвай, Перу, Тринідад і Тобаго та Венесуела)',
  'SOUTH AMERICAN 1969 (ARGENTINA)': 'Південноамериканська 1969 (Аргентина)',
  'SOUTH AMERICAN 1969 (BALTRA, GALAPAGOS ISLANDS)': 'Південноамериканський 1969 р. (Балтра, Галапагосські острови)',
  'SOUTH AMERICAN 1969 (BOLIVIA)': 'Південноамериканська 1969 (Болівія)',
  'SOUTH AMERICAN 1969 (BRAZIL)': 'Південноамериканський 1969 (Бразилія)',
  'SOUTH AMERICAN 1969 (CHILE)': 'Південноамериканський 1969 р. (Чилі)',
  'SOUTH AMERICAN 1969 (COLOMBIA)': 'Південноамериканська 1969 р. (Колумбія)',
  'SOUTH AMERICAN 1969 (ECUADOR (EXCLUDING GALAPAGOS ISLANDS))': 'Південноамериканський 1969 р. (Еквадор (за винятком островів Галапагос))',
  'SOUTH AMERICAN 1969 (GUYANA)': 'Південноамериканський 1969 (Гайана)',
  'SOUTH AMERICAN 1969 (PARAGUAY)': 'Південноамериканський 1969 (Парагвай)',
  'SOUTH AMERICAN 1969 (PERU)': 'Південноамериканський 1969 р. (Перу)',
  'SOUTH AMERICAN 1969 (TRINIDAD AND TOBAGO)': 'Південноамериканський 1969 р. (Тринідад і Тобаго)',
  'SOUTH AMERICAN 1969 (VENEZUELA)': 'Південноамериканська 1969 (Венесуела)',
  'SOUTH ASIA (SINGAPORE)': 'Південна Азія (Сінгапур)',
  'TANANARIVE OBSERVATORY 1925 (MADAGASCAR)': 'Тананарійна обсерваторія 1925 р. (Мадагаскар)',
  'TIMBALAI 1948 (BRUNEI AND EAST MALAYSIA (SARAWAK AND SABAH))': 'Тімбалай 1948 р. (Бруней та Східна Малайзія (Саравак і Сабах))',
  'TOKYO (JAPAN)': 'Токіо (Японія)',
  'TOKYO (MEAN SOLUTION (JAPAN, OKINAWA AND SOUTH KOREA))': 'Токіо (середнє рішення (Японія, Окінава та Південна Корея))',
  'TOKYO (OKINAWA)': 'Токіо (Окінава)',
  'TOKYO (SOUTH KOREA)': 'Токіо (Південна Корея)',
  'TRISTAN ASTRO 1968 (TRISTAN DA CUNHA)': 'Tristan Astro 1968 (Tristan da Cunha)',
  'VITI LEVU 1916 (VITI LEVU ISLAND (FIJI ISLANDS))': 'Віті Леву 1916 р. (Острів Віті Леву (острови Фіджі))',
  'VOIROL 1874 (ALGERIA/TUNISIA)': 'Voirol 1874 (Алжир/Туніс)',
  'VOIROL 1960 (ALGERIA)': 'Voirol 1960 (Алжир)',
  'WAKE ISLAND ASTRO 1952 (WAKE ATOLL)': 'Острів Уейк Астро 1952 (Wake Atoll)',
  'WAKE-ENIWETOK 1960 (MARSHALL ISLANDS)': 'Wake-Eniwetok 1960 (Маршаллські острови)',
  'WORLD GEODETIC SYSTEM 1972 (WORLD WIDE)': 'Всесвітня геодезична система 1972 (World Wide)',
  'WORLD GEODETIC SYSTEM 1984 (WORLD WIDE)': 'Всесвітня геодезична система 1984 (World Wide)',
  'YACARE (URUGUAY)': 'Yacare (Uruguay)',
  'ZANDERIJ (SURINAME)': 'Zanderij (Suriname)',
  'ACCRA (GHANA)': 'Аккра (Гана)',
  'GEOCENTRIC DATUM AUSTRALIA (AUSTRALIA)': 'Геоцентрична дата Австралія (Австралія)',
  'GGRS87 (GREECE)': 'Ggrs87 (Греція)',
  'HONG KONG 1980 (HONG KONG)': 'Гонконг 1980 (Гонконг)',
  'IRAQ-KUWAIT BOUNDARY 1992 (IRAQ/KUWAIT)': 'Ірак-Кувейтська межа 1992 р. (Ірак/Кувейт)',
  '62 Satellite Stations Used': '62 Використовувані супутникові станції',
  'KOREAN GEODETIC SYSTEM 1995 (SOUTH KOREA)': 'Корейська геодезична система 1995 р. (Південна Корея)',
  'LKS94 (LITHUANIA)': 'LKS94 (Литва)',
  'NORTH AMERICAN 1927 (MEAN SOLUTION (CONUS))': 'Північноамериканський 1927 р. (Середнє рішення (Conus))',
  'NTF (FRANCE)': 'NTF (Франція)',
  'International 1924 Ellipsoid': 'Міжнародний еліпсоїд 1924 року',
  'PALESTINE 1928 (ISRAEL)': 'Палестина 1928 р. (Ізраїль)',
  'SOUTH AMERICAN GEOCENTRIC REFERENCE SYSTEM (SIRGAS) (SOUTH AMERICA)': 'Південноамериканська геоцентрична довідкова система (SIRGAS) (Південна Америка)',
  'TOKYO SOUTH KOREA (TOY-B1)': 'Токіо Південна Корея (іграшка-B1)',
  'ARMY ARTILLERY GROUP': 'Армійська артилерійська група',
  'ARMY ARTILLERY SUB-GROUP': 'Підгрупа армійської артилерії',
  'AN ORGANISATION THAT DIRECTS THE STRATEGIC AND TACTICAL EMPLOYMENT OF TWO OR MORE ARMIES AND SUPPORTING UNITS.': 'Організація, яка спрямовує стратегічну та тактичну зайнятість двох або більше армій та підтримуючих підрозділів.',
  'AIR ARMY': 'Повітряна армія',
  'AIR COMMAND': 'Повітряна команда',
  'AIR CONTROL PARTY': 'Партія повітряного контролю',
  'AIR CORPS': 'Повітряний корпус',
  'AIR DETACHMENT': 'Повітряна відшарування',
  'AIR DIVISION': 'Повітряна дивізія',
  'AIR ELEMENT': 'Повітряний елемент',
  'AIR FLIGHT': 'Повітряний політ',
  'AIR GROUP': 'Авіаперевезення',
  'AIR REGIMENT': 'Повітряний полк',
  'AIR SQUADRON': 'Повітряна ескадра',
  'AIR WING': 'Повітряне крило',
  'ARMY OR FIELD ARMY': 'Армія або польова армія',
  'BRIGADE': 'Бригада',
  'BATTLE GROUP': 'Бойова група',
  'BRIGADE GROUP': 'Бригадна група',
  'BATTALION': 'Батальйон',
  'BORDER DISTRICT HEADQUARTERS': 'Штаб прикордонного району',
  'BATTERY': 'Акумулятор',
  'COMMANDO': 'Командно',
  'COMBAT COMMAND': 'Бойова команда',
  'COMMAND': 'Командування',
  'CORPS': 'Корпус',
  'COMPANY': 'Компанія',
  'COMPANY TEAM': 'Команда компанії',
  'COMBAT TEAM': 'Бойова команда',
  'DIVISIONAL ARTILLERY GROUP': 'Дивізіональна артилерійська група',
  'DETACHMENT': 'Загін',
  'DIVISION': 'Дивізіон',
  'FLEET': 'Флот',
  'FIELD FORCE': 'Польова сила',
  'FLOTILLA': 'Флотилія',
  'FLIGHT': 'Виліт',
  'FRONT': 'Фронт',
  'GROUP': 'Група',
  'GROUP OF FORCES': 'Група сил',
  'GROUP OF FRONTS': 'Група фронтів',
  'HOME DEFENCE BRIGADE': 'Бригада домашньої оборони',
  'A TACTICAL UNIT OF THE FORMER USSR WITH A STRENGTH VARYING FROM 200 - 500 MEN. SUBORDINATE TO THE OTRYAD.': 'Тактична одиниця колишнього СРСР з силою, що варіюється від 200 до 500 чоловіків. Підлеглий до Отріаду.',
  'MILITARY DISTRICT': 'Військовий округ',
  'MAJOR FLEET': 'Основний флот',
  'MINISTRY OF DEFENCE': 'Міністерство оборони',
  'NAVAL DETACHMENT': 'Військово -морський загін',
  'NAVAL DIVISION': 'Військово -морський дивізіон',
  'NAVAL FORCE': 'Військово -морська сила',
  'NAVAL GROUP': 'Військово -морська група',
  'NAVAL SECTION': 'Військово -морський розділ',
  'NAVAL SQUADRON': 'Військово -морська ескадра',
  'NAVAL TASK ELEMENT': 'Морський елемент завдання',
  'NAVAL TASK FORCE': 'Військово -морська цільова група',
  'NAVAL TASK GROUP': 'Морська група завдань',
  'NAVAL TASK UNIT': 'Військово -морський завдань',
  'NATIONAL DEFENSE HEADQUARTERS': 'Штаб національної оборони',
  'NUMBERED FLEET': 'Пронумерований флот',
  'PLATOON': 'Взвод',
  'PATROL': 'Патрулювати',
  'REGIMENTAL ARTILLERY GROUP': 'Полкова артилерійська група',
  'REGIMENT': 'Полк',
  'SECTION': 'Розділ',
  'SINGLE UNIT': 'Одиниця',
  'SQUADRON': 'Ескадра',
  'SQUAD': 'Загін',
  'TERRITORIAL COMMAND': 'Територіальне командування',
  'TASK ELEMENT': 'Елемент завдання',
  'TASK FORCE': 'ОПЕРАТИВНА ГРУПА',
  'TASK GROUP': 'Група завдань',
  'THEATRE ARMY': 'Театральна армія',
  'TROOP': 'Військ',
  'TASK UNIT': 'Блок завдання',
  'UNIT': 'Одиниця',
  'WING': 'Крила',
  'Afghanistan': 'Афганістан',
  'Aland Islands': 'АЛАНДА АРАНДИ',
  'Albania': 'Албанія',
  'Algeria': 'Алжир',
  'American Samoa': 'Американський Самоа',
  'Andorra': 'Андорра',
  'Angola': 'Ангола',
  'Anguilla': 'Ангоя',
  'Antarctica': 'Антарктида',
  'Antigua and Barbuda': 'Антигуа та Барбуда',
  'Argentina': 'Аргентина',
  'Armenia': 'Вірменія',
  'Aruba': 'Аруба',
  'Australia': 'Австралія',
  'Austria': 'Австрія',
  'Azerbaijan': 'Азербайджан',
  'Bahamas': 'Багами',
  'Bahrain': 'Бахрейн',
  'Bangladesh': 'Бангладеш',
  'Barbados': 'Барбадос',
  'Belarus': 'Білорус',
  'Belgium': 'Бельгія',
  'Belize': 'Белізувати',
  'Benin': 'Бенін',
  'Bermuda': 'Бермудські острови',
  'Bhutan': 'Бутан',
  'Bolivia': 'Болівія',
  'Bonaire, Sint Eustatius and Saba': 'Бонер, Сінт Євстат і Саба',
  'Bosnia and Herzegovina': 'Боснія і Герцеговина',
  'Botswana': 'Ботсвана',
  'Bouvet Island': 'Острів Бувет',
  'Brazil': 'Бразилія',
  'British Indian Ocean Territory': 'Британська територія Індійського океану',
  'Brunei Darussalam': 'Бруней Даруссалам',
  'Bulgaria': 'Болгарія',
  'Burkina Faso': 'Буркіна Фасо',
  'Burundi': 'Бурунді',
  'Cambodia': 'Камбоджа',
  'Cameroon': 'Камерун',
  'Canada': 'Канада',
  'Cape Verde': 'КАПО ВЕРДЕ',
  'Cayman Islands': 'Кайманські острови',
  'Central African Republic': 'Центральноафриканська республіка',
  'Chad': 'Чад',
  'Chile': 'Чилі',
  'China': 'Китай',
  'Christmas Island': 'Різдвяний острів',
  'Cocos (Keeling) Islands': 'Кокос (кілінг) острови',
  'Colombia': 'Колумбія',
  'Comoros': 'Комор',
  'Congo': 'Конго',
  'Congo, The Democratic Republic of the': 'Конго, демократична республіка',
  'Cook Islands': 'Кухарські острови',
  'Costa Rica': 'Коста-Ріка',
  "Cote D'Ivoire (Ivory Coast)": 'Cote d\'ivoire (Кот -д\'Івуар)',
  'Croatia (Hrvatska)': 'Хорватія (Hrvatska)',
  'Cuba': 'Куба',
  'Curacao': 'Куракао',
  'Cyprus': 'Кіпр',
  'Czech Republic': 'Чеська Республіка',
  'Denmark': 'Данія',
  'Djibouti': 'Djibouti',
  'Dominica': 'Домініка',
  'Dominican Republic': 'Домініканська республіка',
  'Ecuador': 'Еквадор',
  'Egypt': 'Єгипет',
  'El Salvador': 'Сальвадор',
  'Equatorial Guinea': 'Екваторіальна Гвінея',
  'Eritrea': 'Еритрея',
  'Estonia': 'Естонія',
  'Ethiopia': 'Ефіопія',
  'Falkland Islands': 'Фолклендські острови',
  'Faroe Islands': 'Фарерські острови',
  'Fiji': 'Фіджі',
  'Finland': 'Фінляндія',
  'France': 'Франція',
  'French Guiana': 'Французька Гвіана',
  'French Polynesia': 'Французька Полінезія',
  'French Southern Territories': 'Французькі південні території',
  'Gabon': 'Габон',
  'Gambia': 'Гамбія',
  'Georgia': 'Грузія',
  'Germany': 'Німеччина',
  'Ghana': 'Гана',
  'Gibraltar': 'Гібралтар',
  'Greece': 'Греція',
  'Greenland': 'Гренландія',
  'Grenada': 'Гренада',
  'Guadeloupe': 'Гваделуп',
  'Guam': 'Гуам',
  'Guatemala': 'Гватемала',
  'Guernsey': 'Гернсі',
  'Guinea': 'Гвінея',
  'Guinea-Bissau': 'Гвінея-Біссау',
  'Guyana': 'Гайана',
  'Haiti': 'Гаїті',
  'Heard Island and McDonald Islands': 'Черг острови та Макдональд острови',
  'Holy See (Vatican City State)': 'Святий Див (штат Ватикан)',
  'Honduras': 'Гондурас',
  'Hong Kong': 'Гонконг',
  'Hungary': 'Угорщина',
  'Iceland': 'Ісландія',
  'India': 'Індія',
  'Indonesia': 'Індонезія',
  'Iran, Islamic Republic of': 'Іран, Ісламська Республіка',
  'Iraq': 'Ірак',
  'Ireland': 'Ірландія',
  'Isle of Man': 'Острів Мен',
  'Israel': 'Ізраїль',
  'Italy': 'Італія',
  'Jamaica': 'Ямайка',
  'Japan': 'Японія',
  'Jersey': 'Трикотаж',
  'Jordan': 'Йордан',
  'Kazakhstan': 'Казахстан',
  'Kenya': 'Кенія',
  'Kiribati': 'Кірібаті',
  "Korea, Democratic People's Republic of": 'Корея, демократична народна республіка',
  'Korea, Republic of': 'Корея, Республіка',
  'Kuwait': 'Кувейт',
  'Kyrgyzstan': 'Киргизстан',
  "Lao People's Democratic Republic": 'Народна демократична республіка Лао',
  'Latvia': 'Латвія',
  'Lebanon': 'Ліван',
  'Lesotho': 'Лесото',
  'Liberia': 'Ліберія',
  'Libyan Arab Jamahiriya': 'Лівійська арабська Джамахірія',
  'Liechtenstein': 'Ліхтенштейн',
  'Lithuania': 'Литва',
  'Luxembourg': 'Люксембург',
  'Macao': 'Макао',
  'Madagascar': 'Мадагаскар',
  'Malawi': 'Малаві',
  'Malaysia': 'Малайзія',
  'Maldives': 'Мальдіви',
  'Mali': 'Малі',
  'Malta': 'Мальта',
  'Marshall Islands (the)': 'Маршаллські острови (The)',
  'Martinique': 'Мартиніка',
  'Mauritania': 'Мавританія',
  'Mauritius': 'Маврикій',
  'Mayotte': 'Майотта',
  'Mexico': 'Мексика',
  'Micronesia, Federated States of': 'Мікронезія, федеративні держави',
  'Moldova, Republic of': 'Молдова, Республіка',
  'Monaco': 'Монако',
  'Mongolia': 'Монголія',
  'Montenegro': 'Чорногорія',
  'Montserrat': 'Монтсеррат',
  'Morocco': 'Марокко',
  'Mozambique': 'Мозамбік',
  'Myanmar': 'М\'янма',
  'Namibia': 'Намібія',
  'Nauru': 'Науру',
  'Nepal': 'Непал',
  'Netherlands': 'Нідерланди',
  'New Caledonia': 'Нова Каледонія',
  'New Zealand': 'Нова Зеландія',
  'Nicaragua': 'Нікарагуа',
  'Niger': 'Нігер',
  'Nigeria': 'Нігерія',
  'Niue': 'Ніуе',
  'Norfolk Island': 'Острів Норфолк',
  'Northern Mariana Islands': 'Північні Маріанські острови',
  'Norway': 'Норвегія',
  'Oman': 'Оманський',
  'Pakistan': 'Пакистан',
  'Palau': 'Палау',
  'Palestinian Territory, Occupied': 'Палестинська територія, окупована',
  'Panama': 'Панама',
  'Papua New Guinea': 'Папуа-Нова Гвінея',
  'Paraguay': 'Парагвай',
  'Peru': 'Перу',
  'Philippines': 'Філіппіни',
  'Pitcairn': 'Піткерн',
  'Poland': 'Польща',
  'Portugal': 'Португалія',
  'Puerto Rico': 'Пуерто Ріко',
  'Qatar': 'QATAR',
  'Reunion': 'Возз\'єднання',
  'Romania': 'Румунія',
  'Russian Federation': 'Російська Федерація',
  'Rwanda': 'Руанда',
  'Saint Barthelemy': 'Святий Бартелемія',
  'Saint Helena': 'Свята Гелена',
  'Saint Kitts and Nevis': 'Сент -Кітс і Невіс',
  'Saint Lucia': 'Сент -Люсія',
  'Saint Martin (French part)': 'Сент -Мартін (французька частина)',
  'Saint Pierre and Miquelon': 'Сент -П\'єр і Мікелон',
  'Saint Vincent and The Grenadines': 'Сент -Вінсент та Гренадини',
  'Samoa': 'Самоа',
  'San Marino': 'Сан -Маріно',
  'Sao Tome and Principe': 'Sao Tome і Principe',
  'Saudi Arabia': 'Саудівська Аравія',
  'Senegal': 'Сенегал',
  'Serbia': 'Сербія',
  'Seychelles': 'Сейшели',
  'Sierra Leone': 'Сьєрра-Леоне',
  'Singapore': 'Сінгапур',
  'Sint Maarten (Dutch part)': 'Сінт Маартен (голландська частина)',
  'Slovakia': 'Словаччина',
  'Slovenia': 'Словенія',
  'Solomon Islands': 'Соломонські острови',
  'Somalia': 'Сомалі',
  'South Africa': 'Південна Африка',
  'South Georgia and South Sandwich Islands': 'Острови Південної Джорджії та Південних сендвічів',
  'South Sudan': 'Південний Судан',
  'Spain': 'Іспанія',
  'Sri Lanka': 'Шрі Ланка',
  'Sudan': 'Судан',
  'Suriname': 'Сурінам',
  'Svalbard and Jan Mayen Islands': 'Острови Свальбард та Ян Мейен',
  'Swaziland': 'Свазіленд',
  'Sweden': 'Швеція',
  'Switzerland': 'Швейцарія',
  'Syrian Arab Republic': 'Сирійська арабська республіка',
  'Taiwan, Province of China': 'Тайвань, провінція Китай',
  'Tajikistan': 'Таджикистан',
  'Tanzania, United Republic of': 'Танзанія, Об\'єднана республіка',
  'Thailand': 'Таїланд',
  'Turkey recognises the Republic of Macedonia with its constitutional name.': 'Туреччина визнає Республіку Македонію своєю конституційною назвою.',
  'Timor-Leste': 'Тимор',
  'Togo': 'Йти',
  'Tokelau': 'Токелау',
  'Tonga': 'Тонга',
  'Trinidad and Tobago': 'Тринідад і Тобаго',
  'Tunisia': 'Туніс',
  'Turkey': 'Індичка',
  'Turkmenistan': 'Туркменістан',
  'Turks and Caicos Islands': 'Турки та острови Кайкос',
  'Tuvalu': 'Тувалу',
  'Uganda': 'Уганда',
  'Ukraine': 'Україна',
  'United Arab Emirates': 'Об\'єднані Арабські Емірати',
  'The United Kingdom of Great Britain and Northern Ireland': 'Великобританія Великобританії та Північна Ірландія',
  'United States': 'Сполучені Штати',
  'Uruguay': 'Уругвай',
  'US Minor Outlying Islands': 'США незначні острови',
  'Uzbekistan': 'Узбекистан',
  'Vanuatu': 'Вануату',
  'Venezuela': 'Венесуела',
  'Viet Nam': 'В\'єтнам',
  'Virgin Islands (British)': 'Віргінські острови (британські)',
  'Virgin Islands (U.S.)': 'Віргінські острови (США)',
  'Wallis and Futuna Islands': 'Острови Уолліс та Футуна',
  'Western Sahara': 'Західна Сахара',
  'Yemen': 'Ємен',
  'Zambia': 'Замбія',
  'Zimbabwe': 'Зімбабве',
  'NATO Exercise code': 'Код вправи НАТО',
  'NATO Entity': 'Суб\'єкт НАТО',
  'European Union': 'Європейський Союз',
  'United Nations': 'Об\'єднані Нації',
  'Non Governmental Organization': 'Не урядова організація',
  'ANTI-AIRCRAFT': 'Зенітний',
  'AIRBORNE': 'У повітрі',
  'AIR DEFENSE': 'Протиповітряна оборона',
  'ATOMIC DEMOLITION MUNITIONS': 'Атомні знесення боєприпасів',
  'AIR INTERDICTION': 'Повітряне втручання',
  'AIRLIFT': 'Авіакомпанія',
  'AIR TRANSPORTABLE': 'Повітряний транспорт',
  'AMBULANCE': 'Швидка допомога',
  'AIRMOBILE': 'Авіакомплекс',
  'AMPHIBIOUS': 'Амфібійний',
  'AMPHIBIOUS SUPPORT': 'Амфібійна підтримка',
  'ARMOUR': 'Броня',
  'AIR ASSAULT': 'Повітряний напад',
  'AIR RECONNAISSANCE': 'Повітряна розвідка',
  'ARMOURED': 'Бронетанковий',
  'ARTILLERY': 'Артилерія',
  'ASSAULT': 'Напад',
  'ASSAULT GUN': 'Штурмовий пістолет',
  'ASSAULT TRANSPORT': 'Штурмовий транспорт',
  'ASSAULT CROSSING': 'Перетинання нападів',
  'ANTI-TANK': 'Протитанковий',
  'ATTACK': 'Напад',
  'ARMY AVIATION': 'Армійська авіація',
  'BORDER POLICE': 'Прикордонна поліція',
  'BRIDGING': 'Мости',
  'COMBAT': 'Бій',
  'CONVOY': 'Конвой',
  'COASTAL DEFENSE': 'Прибережна оборона',
  'EVACUATION': 'Евакуація',
  'CHEMICAL': 'Хімічний',
  'CHEMICAL PROTECTION': 'Хімічний захист',
  'MILITARY/CIVIL AFFAIRS': 'Військові/цивільні справи',
  'CIV HQ NON-SPECIFIC': 'Civ HQ неспецифічний',
  'POLICE': 'Поліція',
  'CIVIL SUPPORT': 'Цивільна підтримка',
  'CLINICAL': 'Клінічний',
  'CONSTRUCTION': 'Будівництво',
  'REFUGEE SHELTER': 'Притулок для біженців',
  'CIVILIAN/NON-GOVT TRANSPORTATION': 'Цивільний/не-ховний транспорт',
  'AIRCRAFT CARRIER': 'Авіаносець',
  'DISPOSAL': 'Утилізація',
  'DRONE': 'Безпілотник',
  'ELECTRONIC': 'Електронний',
  'ENGINEER': 'Інженер',
  'EXPLOSIVE ORDNANCE': 'Вибухонебезпечний боєприпас',
  'ELECTRONIC RANGING': 'Електронний',
  'ELECTRONIC WARFARE': 'Електронна війна',
  'FIELD': 'Поле',
  'FUEL': 'Паливо',
  'GENERAL': 'Загальний',
  'GENERAL PURPOSE': 'ГОЛОВНА МЕТА',
  'GUN': 'Рушниця',
  'HELICOPTER': 'Вертоліт',
  'HUMANITARIAN SUPPORT': 'Гуманітарна підтримка',
  'HEAVY': 'Важкий',
  'HEAVY SUPPORT': 'Важка підтримка',
  'INDEPENDENT': 'Незалежний',
  'INFANTRY': 'Піхота',
  'LABOUR RESOURCES': 'Трудові ресурси',
  'LOCATING': 'Розташування',
  'LONG-RANGE RECONNAISSANCE': 'Розвідка',
  'LIGHT SELF-PROPELLED': 'Світло',
  'MAINTENANCE': 'Технічне обслуговування',
  'MARITIME PREPOSITIONING': 'Морське прийменник',
  'MATERIAL': 'Матеріал',
  'MINE COUNTERMEASURE': 'Міна контрзахода',
  'MECHANISED': 'Механізований',
  'MEDICAL': 'Медичний',
  'MEDIUM': 'Середній',
  'MEDIUM SELF-PROPELLED': 'Середній самозакоханий',
  'METEOROLOGICAL': 'Метеорологічний',
  'MACHINE GUN': 'КУЛЕМЕТ',
  'MILITARY INTELLIGENCE': 'Військова розвідка',
  'MISSILE LAUNCHING': 'Запуск ракети',
  'MULTIPLE LAUNCHER ROCKET': 'Кілька ракет запуску',
  'MOTORIZED': 'Моторизований',
  'MILITARY POLICE': 'Військова поліція',
  'MISSILE': 'Ракета',
  'MOUNTAIN': 'Гора',
  'NUCLEAR, BIOL, AND CHEM': 'Ядерний, біол та хім',
  'OFFENSIVE AIR': 'Образливий повітря',
  'OBSERVATION': 'Спостереження',
  'ORDNANCE': 'Обряд',
  'PARACHUTE': 'Парашут',
  'PATROL BOAT': 'Патрульний',
  'PERSONNEL SERVICES': 'Персонал',
  'PONTOON': 'Понтон',
  'POSTAL': 'Поштовий',
  'PIPELINE': 'Трубопровід',
  'PSYCHOLOGICAL': 'Психологічний',
  'QUARTERMASTER': 'Чверть',
  'AUXILIARY': 'Допоміжний',
  'RECONNAISSANCE': 'Розвідка',
  'REPLENISHMENT': 'Поповнення',
  'REPLACEMENT': 'Заміна',
  'RAILWAY': 'Залізниця',
  'SURFACE-TO-AIR MISSILE': 'Ракета "поверхня-повітря"',
  'SECURITY': 'Безпека',
  'SURFACE COMBATANT': 'Поверхневий бойовий',
  'SEA LANDING': 'Морська посадка',
  'SPECIAL OPERATION FORCES': 'Спеціальні сили експлуатації',
  'SOUND RANGING': 'Звук',
  'SELF-PROPELLED': 'Самозакоханий',
  'ATTACK SUBMARINE': 'Підводний човен',
  'SURFACE-TO-SURFACE MISSILE': 'Ракета поверхні до поверхні',
  'SURFACE-TO-SURFACE ROCKET': 'Ракета поверхні до поверхні',
  'HEADQUARTERS STATIC': 'Штаб -квартира статики',
  'HEADQUARTERS STEP-UP': 'Штаб-квартира',
  'STORE': 'Магазин',
  'SUPPLY': 'Постачання',
  'SURGICAL': 'Хірургічний',
  'SURVEY': 'Опитування',
  'SPECIAL WEAPONS': 'Спеціальна зброя',
  'TRAINING': 'Навчання',
  'TRANSPORT': 'Транспортувати',
  'TRANSPORTATION': 'Перевезення',
  'UNSPECIFIED UNIT ROLE': 'Невизначена роль одиниці',
  'UNKNOWN/UNCONFIRMED': 'Невідомий/непідтверджений',
  'UNMANNED AIR RECCE': 'Безпілотне повітря recce',
  'VETERINARY': 'Ветеринар',
  'WHEELED APC': 'Колісний APC',
  'A TERM USED TO DESCRIBE THE ARMIES OF A NATION. (THE MILITARY ORGANISATION INTENDED FOR LAND OFFENSIVE OR DEFENSIVE WARFARE ON THE GROUND.)': 'Термін, що використовується для опису армій нації. (Військова організація, призначена для наступальної чи оборонної війни на землі.)',
  'CONNOTED ACTIVITIES, OPERATIONS, ORGANISATIONS, ETC. IN WHICH ELEMENTS OF MORE THAN ONE NATION PARTICIPATE.': 'Суновна діяльність, операції, організації тощо. В якому беруть участь елементи більш ніж однієї нації.',
  'THE ARMED FORCES OF A COUNTRY INTENDED FOR MILITARY OPERATIONS FROM SHIPS IN COASTAL WATERS.': 'Збройні сили країни, призначені для військових операцій з кораблів у прибережних водах.',
  'AIR DEFENCE': 'Протиповітряна оборона',
  'THE COMPLETE PERMANENT LAND BASED MILITARY ORGANISATION OF A COUNTRY INTENDED FOR MILITARY OPERATIONS IN THE AIR.': 'Повна постійна земельна військова організація країни, призначена для військових операцій у повітрі.',
  'THE ORGANISATION TASKED TO PROVIDE MEDICAL SERVICES.': 'Організація, яка має завдання надати медичні послуги.',
  'INCLUDING ARMY, NAVY, AIR FORCE AND /OR MINISTRY OF DEFENCE': 'Включаючи армію, ВМС, ВВС та /або Міністерство оборони',
  'CONNOTES ACTIVITIES, OPERATIONS, ORGANISATIONS, ETC. IN WHICH ELEMENTS OF MORE THAN ONE SERVICE OF THE SAME NATION PARTICIPATE.': 'Конотс діяльність, операції, організації тощо. В якому беруть участь елементи більш ніж однієї служби однієї нації.',
  'A MILITARY FORCE ASSOCIATED WITH NAVAL FORCES OF A COUNTRY INTENDED FOR LAND WARFARE THROUGH LANDING TYPE OPERATIONS.': 'Військова сила, пов’язана з військово -морськими силами країни, призначена для земельної війни за допомогою операцій типу посадки.',
  'THE ARMED FORCES OF A COUNTRY INTENDED FOR MILITARY OPERATIONS FROM SHIPS IN DEEP WATER AREAS.': 'Збройні сили країни, призначені для військових операцій з кораблів у районах глибокої води.',
  'ANY SPECIFIC ARMED SERVICE OTHER THAN THE ABOVE.': 'Будь -яка конкретна збройна служба, крім вищезазначеного.',
  'PARAMILITARY': 'Воєнізований',
  'ROCKET FORCES': 'Ракета',
  'CIVIL SERVICE': 'ДЕРЖАВНА СЛУЖБА',
  'A MILITARY FORCE REPRESENTING A SPECIFIC TERRITORY.': 'Військова сила, що представляє конкретну територію.',
  'NOT KNOWN': 'Невідомий',
  'SPECIAL SUBORDINATION': 'Спеціальне підпорядкування',
  'THE FULL NAME IS COOPERATIVE EFFORT AMERICAN RELIEF EVERYWHERE.': 'Повна назва - це спільне зусилля американського полегшення скрізь.',
  'OTHER CIVILIAN/NON-GOVT': 'Інші цивільні/не-руки',
  'INTERNATIONAL COMMITTEE RED CROSS/RED CRESCENT': 'Міжнародний комітет Червоний Хрест/Червоний півмісяць',
  'INTERNATIONAL PRIVATE': 'Міжнародний рядовий',
  'MEDECINS SANS FRONTIERES': 'Medecins sans Frontiers',
  'NATIONAL PRIVATE': 'Національний рядовий',
  'UNITED NATIONS, GENERAL': 'Організація Об\'єднаних Націй, генерал',
  'UN CIVIL POLICE': 'Цивільна поліція ООН',
  'UN DEVELOPMENT PROGRAM': 'Програма розвитку ООН',
  'THE FULL NAME IS UNITED NATIONS HIGH COMMISSIONER FOR REFUGEES.': 'Повна назва - Верховний комісар ООН з біженців.',
  "UN INTERNATIONAL CHILDREN'S EMERGENCY FUND": 'Міжнародний фонд надзвичайних ситуацій ООН',
  'OTHER UN': 'Інші ООН',
  'UN REFUGEE AGENCY': 'Агентство з біженців ООН',
  'UN WORLD HEALTH ORGANIZATION': 'Всесвітня організація охорони здоров\'я ООН',
  'WESTERN EUROPEAN UNION': 'Західний Європейський Союз',
  'NORTH LATITUDE HEMISPHERE': 'Північна широта півсфера',
  'SOUTH LATITUDE HEMISPHERE': 'Південна півсфера',
  'HYPHEN': 'Дефіс',
  'EAST LONGITUDE HEMISPHERE': 'Східна довгота півсфера',
  'WEST LONGITUDE HEMISPHERE': 'Західна довгота півсфера',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 80 SOUTH AND 72 SOUTH.': 'Лист -позначення широтної зони UTM включав між 80 Півдня та 72 Південь.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 72 SOUTH AND 64 SOUTH.': 'Позначення листа широтної зони UTM включав між 72 півдня та 64 півдні.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 64 SOUTH AND 56 SOUTH.': 'Лист -позначення широтної зони UTM включав між 64 Південним та 56 Півдня.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 56 SOUTH AND 48 SOUTH.': 'Лист -позначення широкої зони UTM включає між 56 Півдня та 48 Південь.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 48 SOUTH AND 40 SOUTH.': 'Лист -позначення широкої зони UTM входить між 48 півдня та 40 півдня.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 40 SOUTH AND 32 SOUTH.': 'Лист -позначення широтної зони UTM включає між 40 півдня та 32 півдня.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 32 SOUTH AND 24 SOUTH.': 'Позначення листа широтної зони UTM входить між 32 півдня та 24 півдня.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 24 SOUTH AND 16 SOUTH.': 'Позначення листа широтної зони UTM входить між 24 півдня та 16 півдня.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 16 SOUTH AND 8 SOUTH.': 'Лист -позначення широтної зони UTM включав між 16 на південь та 8 півдня.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 8 SOUTH AND 0.': 'Позначення листа широтної зони UTM включається між 8 на південь та 0.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 0 AND 8 NORTH.': 'Лист -позначення широтної зони UTM включає від 0 до 8 на північ.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 8 NORTH AND 16 NORTH.': 'Лист -позначення широтної зони UTM включає між 8 та 16 північ.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 16 NORTH AND 24 NORTH.': 'Лист -позначення широтної зони UTM входить між 16 та 24 північ.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 24 NORTH AND 32 NORTH.': 'Лист -позначення широтної зони UTM входить між 24 на північ і 32 північ.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 32 NORTH AND 40 NORTH.': 'Лист -позначення широтної зони UTM входить між 32 на північ і 40 північ.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 40 NORTH AND 48 NORTH.': 'Лист -позначення широкої зони UTM включає між 40 на північ і 48 північ.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 48 NORTH AND 56 NORTH.': 'Лист -позначення широтної зони UTM включає між 48 на північ і 56 північ.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 56 NORTH AND 64 NORTH.': 'Лист -позначення широкої зони UTM включав між 56 на північ і 64 північ.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 64 NORTH AND 72 NORTH.': 'Лист -позначення широтної зони UTM входить між 64 на північ і 72 північ.',
  'LETTER DESIGNATOR OF THE UTM LATITUDINAL ZONE INCLUDED BETWEEN 72 NORTH AND 84 NORTH.': 'Лист -позначення широтної зони UTM входить між 72 на північ і 84 північ.',
  'LETTER DESIGNATOR OF MGRS UPS HEMISPHERE ZONE.': 'Лист -позначення зони півсфери MGRS.',
  'REFERENCE INFORMATION CERTAIN': 'Довідкова інформація певна',
  'QUESTION MARK': 'ЗНАК ПИТАННЯ',
  'REFERENCE INFORMATION NOT AVAILABLE': 'Довідкова інформація недоступна',
  'BOMBER': 'Бомбардувальник',
  'CARGO/TRANSPORT': 'Вантаж/транспорт',
  'FIGHTER': 'Винищувач',
  'FIXED-WING AIRCRAFT, GENERAL': 'Літаки з фіксованим крилом, загальний',
  'HELICOPTER, LIGHT OBSERVATION': 'Вертоліт, Світло спостереження',
  'HELICOPTER, ARMED/ ASSAULT/ANTI-TANK': 'Вертоліт, озброєний/ напад/ протизабір',
  'HELICOPTER, UTILITY/ TECHNICAL TRANSPORT': 'Вертоліт, комунальний/ технічний транспорт',
  'HELICOPTER, MEDIUM TRANSPORT': 'Вертоліт, середній транспорт',
  'HELICOPTER, HEAVY TRANSPORT': 'Вертоліт, важкий транспорт',
  '25,000 TO 50,000 FEET.': 'Від 25 000 до 50 000 футів.',
  '500 TO 4,999 FEET.': '500 до 4999 футів.',
  '5,000 TO 24,999 FEET.': '5000 до 24 999 футів.',
  'ABOVE 50,000 FEET.': 'Вище 50 000 футів.',
  'BELOW 500 FEET.': 'Нижче 500 футів.',
  'MACH 1 OR GREATER': 'Мах 1 або більше',
  '400 KNOTS TO LESS THAN MACH 1': '400 вузлів до менше, ніж Mach 1',
  '200 KNOTS TO LESS THAN 400 KNOTS': '200 вузлів до менше 400 вузлів',
  'UNDER 200 KNOTS': 'За 200 вузлами',
  'KILOMETRE': 'Кілометр',
  'METRE': 'Метр',
  'NAUTICAL MILE': 'Морська миля',
  'Attack is unlikely without adequate warning.': 'Атака навряд чи без адекватного попередження.',
  'Attack is probable.': 'Атака є ймовірною.',
  'Attack is imminent, or has already commenced.': 'Атака неминуча або вже розпочалася.',
  'DIRECT SUPPORT': 'Пряма підтримка',
  'GENERAL SUPPORT': 'Загальна підтримка',
  'GENERAL SUPPORT REINFORCING': 'Загальна підтримка посилення',
  'REINFORCING': 'Підсилюючий',
  'AIRCRAFT': 'Літак',
  'MORTAR': 'Міномет',
  'ROCKET OR MISSILE': 'Ракета або ракета',
  'UNKNOWN': 'Невідомий',
  'ANTI-AIRCRAFT ARTILLERY': 'Анті-зенітна артилерія',
  'ARMED/ASSAULT/ANTI-TANK HELICOPTER': 'Озброєний/напад/протитанковий вертоліт',
  'ARMOURED INFANTRY FIGHTING VEHICLE': 'Бронетанковий бойовий транспортний засіб',
  'ANTENNA': 'Антена',
  'ARMOURED PERSONNEL CARRIER': 'Бронетанковий персонал',
  'AIR-TO-SURFACE MISSILE': 'Ракета з повітря-поверхів',
  'ANTI-TANK GUN': 'Протитанковий пістолет',
  'ANTI-TANK GUIDED MISSILE': 'Протитанкова керована ракета',
  'BOX BODY VEHICLES': 'Коробка кузова транспортні засоби',
  'CHEMICAL EQUIPMENT': 'Хімічне обладнання',
  'COMMAND VEHICLE': 'Командний транспортний засіб',
  'HEAVY TRANSPORT HELICOPTER': 'Важкий транспортний вертоліт',
  'LOAD CARRYING VEHICLES': 'Завантаження транспортних засобів',
  'LIGHT OBSERVATION HELICOPTER': 'Вертоліт спостереження світла',
  'MAIN BATTLE TANK': 'Основний бойовий танк',
  'MECHANIZED INFANTRY COMBAT VEHICLE': 'Механізований бойовий транспортний засіб',
  'MORTARS': 'Міномети',
  'MULTI-BARREL ROCKET LAUNCHER': 'Ракетна установка з багатокоштувальною',
  'MEDIUM TRANSPORT HELICOPTER': 'Середній транспортний вертоліт',
  'REMOTELY PILOTED VEHICLES': 'Віддалено пілотовані транспортні засоби',
  'SMALL ARMS': 'Стрічка',
  'TARGET ACQUISITION': 'Цільове придбання',
  'TANK, LIGHT': 'Танк, світло',
  'TRANSPORT HELICOPTER': 'Транспортний вертоліт',
  'UTILITY/TACTICAL TRANSPORT HELICOPTER': 'Корисній/тактичний транспортний вертоліт',
  'UNARMOURED VEHICLES': 'Беззбройні транспортні засоби',
  'WINGED AIRCRAFT': 'Крилатий літак',
  'CROSSING EQUIPMENT': 'Обладнання перехрестя',
  'ADJUST FIRE': 'Налаштувати вогонь',
  'ADJUSTMENT PLUS FIRE FOR EFFECT': 'Коригування плюс пожежа для ефекту',
  'CONTINUOUS ILLUMINATION EFFECT': 'Ефект безперервного освітлення',
  'COORDINATED ILLUMINATION': 'Координоване освітлення',
  'FIRE FOR EFFECT': 'Пожежа для ефекту',
  'FINAL PROTECTIVE FIRE': 'Остаточний захисний вогонь',
  'HARASSING FIRE': 'Домагання вогонь',
  'NUCLEAR': 'Ядерний',
  'SMOKE': 'Палити',
  'SPECIAL EFFECTS': 'СПЕЦЕФЕКТИ',
  'BOMB, GENERAL PURPOSE': 'Бомба, загальна мета',
  'ROCKETS, GENERAL PURPOSE': 'Ракети, загальне призначення',
  'SHELLS': 'Раковини',
  'CENTIMETRE': 'Сантиметр',
  'MILLIMETRE': 'Міліметр',
  'LIGHT': 'Світлий',
  'MODERATE': 'Помірний',
  'NIL': 'Нуль',
  'AIR OBSERVER': 'Повітряний спостерігач',
  'COUNTER BATTERY RADAR': 'Лічильник Радар акумулятора',
  'COUNTER MORTAR RADAR': 'Радар проти мінометів',
  'COMMUNICATIONS INTELLIGENCE': 'Комунікаційний інтелект',
  'ELECTRONIC INTELLIGENCE': 'Електронний інтелект',
  'FLASH RANGING': 'Спалах',
  'FORWARD OBSERVER': 'Спостерігач вперед',
  'FORWARD OBSERVER WITHOUT LASER': 'Уперед спостерігач без лазера',
  'GROUND SURVEILLANCE RADAR': 'Радар нагляду',
  'AIRBORNE INFRARED': 'Повітряний інфрачервоний',
  'OBSERVER NOT ARTILLERY': 'Спостерігач не артилерія',
  'PHOTO INTERPRETATION': 'Інтерпретація фотографій',
  'PRISONER OF WAR': 'В\'язня військовополоненого',
  'LONG RANGE RECONNAISSANCE PATROL': 'Відвідний патруль дальнього діапазону',
  'REMOTELY PILOTED VEHICLE': 'Дистанційно пілотований транспортний засіб',
  'SIDE LOOKING RADAR': 'Радар',
  'TACTICAL AIR': 'Тактичне повітря',
  'TARGET BASE': 'Цільова база',
  'EXCELLENT': 'Відмінний',
  'GOOD': 'Добрий',
  'FAIRLY RELIABLE': 'Досить надійний',
  'YES': 'ТАК',
  'NO': 'НІ',
  'ANGLE OF APPROACH INDICATOR (AAI)': 'Кут індикатора підходу (AAI)',
  'INFRA-RED': 'Інфрачервоний',
  'LIGHTS': 'Вогня',
  'OTHER': 'ІНШИЙ',
  'PANELS': 'Панелі',
  'RADIO BEACON': 'Радіо Баяк',
  'STAR CLUSTER': 'Зоряний кластер',
  'STROBE BEACON': 'Строб -маяк',
  'TORCH OR FLASHLIGHT': 'Факел або ліхтарик',
  'BLUE': 'Блакитний',
  'GREEN': 'Зелений',
  'ORANGE': 'Оранжевий',
  'PURPLE': 'Фіолетовий',
  'RED': 'Червоний',
  'WHITE': 'Білий',
  'YELLOW': 'Жовтий',
  'H SHAPE': 'H форма',
  'I SHAPE': 'Я формую',
  'T SHAPE': 'Т',
  'X SHAPE': 'X форма',
  'BOAT': 'Човен',
  'USE AN AMPN SET TO SPECIFY THE MEANS OF TRANSPORTATION.': 'Використовуйте набір AMPN, щоб вказати засоби транспортування.',
  'TRACKED VEHICLE': 'Відстежений транспортний засіб',
  'WHEELED VEHICLE': 'Колісний транспортний засіб',
  'AIRSTRIP': 'Аеродрому',
  'HARD STAND': 'Тверда стенд',
  'OPEN SPACE': 'ВІДКРИТИЙ ПРОСТІР',
  'PORT': 'Порт',
  'RAILROAD': 'Залізниця',
  'ROAD': 'Дорога',
  'FEET': 'Стоп',
  'YARD': 'ЯРД',
  'URGENT': 'Терміновий',
  'PRIORITY': 'Пріоритет',
  'ROUTINE': 'Звичайний',
  'INTERNAL LITTER': 'Внутрішня послід',
  'SITTING': 'Сидячий',
  'GIGAHERTZ': 'Гігагерц',
  'HERTZ': 'Герц',
  'KILOHERTZ': 'Кілогерц',
  'MEGAHERTZ': 'Мегахерц',
  'USED TO SPECIFY THAT DATA THAT FOLLOW WILL PERTAIN TO A BIOLOGICAL REPORT': 'Використовується для визначення того, що наступні дані стосуються біологічного звіту',
  'USED TO SPECIFY THAT DATA TO FOLLOW WILL PERTAIN TO A CHEMICAL REPORT.': 'Використовується для визначення того, що дані, що слідують, стосуються хімічного звіту.',
  'USED TO SPECIFY THAT THE TYPE OF REPORT IS NOT KNOWN': 'Використовується для визначення того, що тип звіту не відомий',
  'USED TO SPECIFY THAT DATA TO FOLLOW WILL PERTAIN TO A NUCLEAR REPORT.': 'Використовується для визначення того, що дані, що слідують, стосуватимуться ядерного звіту.',
  'USED TO SPECIFY THAT DATA THAT FOLLOW WILL PERTAIN TO A RADIOLOGICAL REPORT': 'Використовується для визначення того, що наступні дані стосуються рентгенологічного звіту',
  'USE TO SPECIFY THAT THE TYPE OF REPORT IS A SITUATION REPORT': 'Використовуйте, щоб вказати, що тип звіту - це звіт про ситуацію',
  'FOR USE IN CBRN WARNING MESSAGE FROM STANAG 2229.': 'Для використання в попередженому повідомленні CBRN від Stanag 2229.',
  'THIS DATA IS RELATED TO A MISSILE INTERCEPTION REPORT': 'Ці дані пов\'язані з звітом про ракетне перехоплення',
  'CBRN BASIC WIND DATA FORECAST': 'Прогноз базових даних CBRN',
  'CBRN BASIC WIND DATA MESSAGE': 'CBRN Основне повідомлення про вітрові дані',
  'CBRN CHEMICAL DOWNWIND FORECAST': 'Хімічний прогноз CBRN',
  'CBRN CHEMICAL DOWNWIND MESSAGE': 'CBRN Chemical Hemical Downwind Повідомлення',
  'CBRN EFFECTIVE DOWNWIND FORECAST': 'CBRN ЕФЕКТИВ',
  'CBRN EFFECTIVE DOWNWIND MESSAGE': 'CBRN Ефективне повідомлення вниз',
  'NATO CODE - NATIONALITY: UK/GBR': 'Код НАТО - Національність: Великобританія/GBR',
  'NATO CODE': 'Код НАТО',
  'INCLUDING ANTARCTIC LANDS, EXCLUDING TERRE ADELIE.': 'Включаючи Антарктичні землі, за винятком Терре Аделі.',
  'NATO CODE. STANAG 1059 INCLUDES CLIPPERTON ISLAND.': 'Код НАТО. Stanag 1059 включає острів Кліпертон.',
  'ARGENTINA': 'Аргентина',
  'BARBADOS': 'Барбадос',
  'BURKINA FASO': 'Буркіна Фасо',
  'NATO CODE (2-LETTER) - CODE IN ACCORDANCE WITH STANAG 1059 ED 7. Footnote in line with ES(2003)30/29 February 2000: Turkey recognizes the Republic of Macedonia with its constitutional name.': 'Код НАТО (2 -літери) - Код відповідно до Stanag 1059 ED 7. Виноска в черзі з ES (2003) 30/29 лютого 2000 року: Туреччина визнає Республіку Македонію з її конституційною назвою.',
  'YUGOSLAVIA, FEDERAL REPUBLIC OF': 'Югославія, Федеральна республіка',
  'AFGHANISTAN': 'Афганістан',
  'ALBANIA': 'Албанія',
  'ALGERIA': 'Алжир',
  'AMERICAN SAMOA': 'Американський Самоа',
  'ANDORRA': 'Андорра',
  'ANGOLA': 'Ангола',
  'ANGUILLA': 'Ангоя',
  'ANTARCTICA': 'Антарктида',
  'ANTIGUA AND BARBUDA': 'Антигуа та Барбуда',
  'ARMENIA': 'Вірменія',
  'ARUBA': 'Аруба',
  'AUSTRALIA': 'Австралія',
  'AUSTRIA': 'Австрія',
  'AZERBAIJAN': 'Азербайджан',
  'BAHAMAS': 'Багами',
  'BAHRAIN': 'Бахрейн',
  'BANGLADESH': 'Бангладеш',
  'BELARUS': 'Білорус',
  'BELGIUM': 'Бельгія',
  'BELIZE': 'Белізувати',
  'BENIN': 'Бенін',
  'BERMUDA': 'Бермудські острови',
  'BHUTAN': 'Бутан',
  'BOLIVIA': 'Болівія',
  'BOSNIA AND HERZEGOVINA': 'БОСНІЯ І ГЕРЦЕГОВИНА',
  'BOTSWANA': 'Ботсвана',
  'BOUVET ISLAND': 'Острів Бувет',
  'BRAZIL': 'Бразилія',
  'BRITISH INDIAN OCEAN TERRITORY': 'Британська територія Індійського океану',
  'BRUNEI DARUSSALAM': 'Бруней Даруссалам',
  'BULGARIA': 'Болгарія',
  'BURUNDI': 'Бурунді',
  'CAMBODIA': 'Камбоджа',
  'CAMEROON': 'Камерун',
  'CANADA': 'Канада',
  'CAPE VERDE': 'КАПО ВЕРДЕ',
  'CAYMAN ISLANDS': 'Кайманські острови',
  'CENTRAL AFRICAN REPUBLIC': 'Центральноафриканська республіка',
  'CHAD': 'Чад',
  'CHILE': 'Чилі',
  'CHINA': 'Китай',
  'CHRISTMAS ISLAND': 'Різдвяний острів',
  'COCOS (KEELING) ISLANDS': 'Кокос (Кілінг) Острови',
  'COLOMBIA': 'Колумбія',
  'COMOROS': 'Комор',
  'CONGO': 'Конго',
  'CONGO, THE DEMOCRATIC REPUBLIC OF THE': 'Конго, демократична республіка',
  'COOK ISLANDS': 'Кухарські острови',
  'COSTA RICA': 'КОСТА-РІКА',
  "COTE D'IVOIRE (IVORY COAST)": 'Cote d\'ivoire (Кот -д\'Івуар)',
  'CROATIA (HRVATSKA)': 'Хорватія (Hrvatska)',
  'CUBA': 'Куба',
  'CYPRUS': 'Кіпр',
  'CZECH REPUBLIC': 'ЧЕСЬКА РЕСПУБЛІКА',
  'DENMARK': 'Данія',
  'DJIBOUTI': 'Djibouti',
  'DOMINICA': 'Домініка',
  'DOMINICAN REPUBLIC': 'ДОМІНІКАНСЬКА РЕСПУБЛІКА',
  'ECUADOR': 'Еквадор',
  'EGYPT': 'Єгипет',
  'EL SALVADOR': 'САЛЬВАДОР',
  'EQUATORIAL GUINEA': 'ЕКВАТОРІАЛЬНА ГВІНЕЯ',
  'ERITREA': 'Еритрея',
  'ESTONIA': 'Естонія',
  'ETHIOPIA': 'Ефіопія',
  'FALKLAND ISLANDS (MALVINAS)': 'Фолклендські острови (Мальвінас)',
  'FAROE ISLANDS': 'Фарерські острови',
  'FIJI': 'Фіджі',
  'FINLAND': 'Фінляндія',
  'STANAG 1059 LIMITS TO THE EUROPEAN PART OF FRANCE, EXCLUDING FRENCH GUIANA, FRENCH POLYNESIA, FRENCH SOUTHERN AND ANTARCTIC LANDS, GUADELOUPE, MARTINIQUE, NEW CALEDONIA, REUNION, ST. PIERRE AND MIQUELON, WALLIS AND FUTUNA.': 'Стенаг 1059 обмежує європейську частину Франції, за винятком французької Гвіани, Французької Полінезії, Французьких південних та Антарктичних земель, Гваделуп, Мартініка, Нова Каледонія, Об’єднання, вул. П\'єр і Мікелон, Уолліс і Футуна.',
  'FRENCH GUIANA': 'Французька Гвіана',
  'STANAG 1059 INCLUDES CLIPPERTON ISLAND.': 'Stanag 1059 включає острів Кліпертон.',
  'GABON': 'Габон',
  'GAMBIA': 'Гамбія',
  'GEORGIA': 'Грузія',
  'GERMANY': 'НІМЕЧЧИНА',
  'GHANA': 'Гана',
  'GIBRALTAR': 'Гібралтар',
  'GREECE': 'Греція',
  'GREENLAND': 'Гренландія',
  'GRENADA': 'Гренада',
  'GUADELOUPE': 'Гваделуп',
  'GUAM': 'Гуам',
  'GUATEMALA': 'Гватемала',
  'GUINEA': 'Гвінея',
  'GUINEA-BISSAU': 'Гвінея-Біссау',
  'GUYANA': 'Гайана',
  'HAITI': 'Гаїті',
  'HEARD ISLAND AND MCDONALD ISLANDS': 'Черг острови та Макдональд острови',
  'HOLY SEE (VATICAN CITY STATE)': 'Святий Див (штат Ватикан)',
  'HONDURAS': 'Гондурас',
  'HONG KONG': 'ГОНКОНГ',
  'HUNGARY': 'Угорщина',
  'ICELAND': 'Ісландія',
  'INDIA': 'Індія',
  'INDONESIA': 'Індонезія',
  'IRAN, ISLAMIC REPUBLIC OF': 'Іран, Ісламська Республіка',
  'IRAQ': 'Ірак',
  'IRELAND': 'Ірландія',
  'ISRAEL': 'Ізраїль',
  'ITALY': 'Італія',
  'JAMAICA': 'Ямайка',
  'JAPAN': 'Японія',
  'JORDAN': 'Йордан',
  'KAZAKHSTAN': 'Казахстан',
  'KENYA': 'Кенія',
  'KIRIBATI': 'Кірібаті',
  "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF": 'Корея, демократична народна республіка',
  'KOREA, REPUBLIC OF': 'Корея, Республіка',
  'KUWAIT': 'Кувейт',
  'KYRGYZSTAN': 'Киргизстан',
  "LAO PEOPLE'S DEMOCRATIC REPUBLIC": 'Народна демократична республіка Лао',
  'LATVIA': 'Латвія',
  'LEBANON': 'Ліван',
  'LESOTHO': 'Лесото',
  'LIBERIA': 'Ліберія',
  'LIBYAN ARAB JAMAHIRIYA': 'Лівійська арабська Джамахірія',
  'LIECHTENSTEIN': 'Ліхтенштейн',
  'LITHUANIA': 'Литва',
  'LUXEMBOURG': 'Люксембург',
  'MACAO': 'Макао',
  'MADAGASCAR': 'Мадагаскар',
  'MALAWI': 'Малаві',
  'MALAYSIA': 'Малайзія',
  'MALDIVES': 'Мальдіви',
  'MALI': 'Малі',
  'MALTA': 'Мальта',
  'MARSHALL ISLANDS': 'Маршаллські острови',
  'MARTINIQUE': 'Мартиніка',
  'MAURITANIA': 'Мавританія',
  'MAURITIUS': 'Маврикій',
  'MEXICO': 'Мексика',
  'MICRONESIA (FEDERATED STATES OF)': 'Мікронезія (федераційні стани)',
  'MOLDOVA, REPUBLIC OF': 'Молдова, Республіка',
  'MONACO': 'Монако',
  'MONGOLIA': 'Монголія',
  'MONTSERRAT': 'Монтсеррат',
  'MOROCCO': 'Марокко',
  'MOZAMBIQUE': 'Мозамбік',
  'MYANMAR': 'М\'янма',
  'NAMIBIA': 'Намібія',
  'NAURU': 'Науру',
  'NEPAL': 'Непал',
  'NETHERLANDS': 'Нідерланди',
  'NETHERLANDS ANTILLES': 'Нідерланди Антильські острови',
  'NEW CALEDONIA': 'Нова Каледонія',
  'NEW ZEALAND': 'НОВА ЗЕЛАНДІЯ',
  'NICARAGUA': 'Нікарагуа',
  'NIGER': 'Нігер',
  'NIGERIA': 'Нігерія',
  'NIUE': 'Ніуе',
  'NORFOLK ISLAND': 'Острів Норфолк',
  'NORTHERN MARIANA ISLANDS': 'Північні Маріанські острови',
  'NORWAY': 'Норвегія',
  'OMAN': 'Оманський',
  'PAKISTAN': 'Пакистан',
  'PALAU': 'Палау',
  'THE DISPUTED TERRITORIES OF THE WEST BANK (CISJORDAN) AND GAZA. STRIP.': 'Спеціальні території Західного берега (Сісьордан) та Газа. Смуга.',
  'PANAMA': 'Панама',
  'PAPUA NEW GUINEA': 'ПАПУА-НОВА ГВІНЕЯ',
  'PARAGUAY': 'Парагвай',
  'PERU': 'Перу',
  'PHILIPPINES': 'Філіппіни',
  'PITCAIRN': 'Піткерн',
  'POLAND': 'Польща',
  'PORTUGAL': 'Португалія',
  'PUERTO RICO': 'ПУЕРТО РІКО',
  'QATAR': 'QATAR',
  'REUNION': 'Возз\'єднання',
  'ROMANIA': 'Румунія',
  'RUSSIAN FEDERATION': 'РОСІЙСЬКА ФЕДЕРАЦІЯ',
  'RWANDA': 'Руанда',
  'SAINT HELENA': 'Свята Гелена',
  'SAINT KITTS AND NEVIS': 'Сент -Кітс і Невіс',
  'SAINT LUCIA': 'Сент -Люсія',
  'SAINT PIERRE AND MIQUELON': 'Сент -П\'єр і Мікелон',
  'SAINT VINCENT AND THE GRENADINES': 'Сент -Вінсент та Гренадини',
  'SAMOA': 'Самоа',
  'SAN MARINO': 'Сан -Маріно',
  'SAO TOME AND PRINCIPE': 'Sao Tome і Principe',
  'SAUDI ARABIA': 'САУДІВСЬКА АРАВІЯ',
  'SENEGAL': 'Сенегал',
  'SERBIA AND MONTENEGRO': 'Сербія та Чорногорія',
  'SEYCHELLES': 'Сейшели',
  'SIERRA LEONE': 'СЬЄРРА-ЛЕОНЕ',
  'SINGAPORE': 'Сінгапур',
  'SLOVAKIA': 'Словаччина',
  'SLOVENIA': 'Словенія',
  'SOLOMON ISLANDS': 'Соломонські острови',
  'SOMALIA': 'Сомалі',
  'SOUTH AFRICA': 'ПІВДЕННА АФРИКА',
  'SOUTH GEORGIA AND SOUTH SANDWICH ISLANDS': 'Острови Південної Джорджії та Південних сендвічів',
  'SPAIN': 'Іспанія',
  'SRI LANKA': 'ШРІ ЛАНКА',
  'SUDAN': 'Судан',
  'SURINAME': 'Сурінам',
  'SVALBARD AND JAN MAYEN ISLANDS': 'Острови Свальбард та Ян Мейен',
  'SWAZILAND': 'Свазіленд',
  'SWEDEN': 'Швеція',
  'SWITZERLAND': 'Швейцарія',
  'SYRIAN ARAB REPUBLIC': 'Сирійська арабська республіка',
  'TAIWAN, PROVINCE OF CHINA': 'Тайвань, провінція Китай',
  'TAJIKISTAN': 'Таджикистан',
  'TANZANIA, UNITED REPUBLIC OF': 'Танзанія, Об\'єднана республіка',
  'THAILAND': 'Таїланд',
  'NATO CODE (3-LETTER) - TEMPORARY CODE IN ACCORDANCE WITH STANAG 1059 ED 8. Footnote in line with ES(2000)30/29 February 2000: Turkey recognizes the Republic of Macedonia with its constitutional name.': 'Код НАТО (3 -літери) - Тимчасовий код відповідно до Stanag 1059 Ed 8. Виноска відповідно до ES (2000) 30/29 лютого 2000 року: Туреччина визнає Республіку Македонію з її конституційною назвою.',
  'TIMOR-LESTE': 'Тимор',
  'TOGO': 'ЙТИ',
  'TOKELAU': 'Токелау',
  'TONGA': 'Тонга',
  'TRINIDAD AND TOBAGO': 'Тринідад і Тобаго',
  'TUNISIA': 'Туніс',
  'TURKEY': 'Індичка',
  'TURKMENISTAN': 'Туркменістан',
  'TURKS AND CAICOS ISLANDS': 'Турки та острови Кайкос',
  'TUVALU': 'Тувалу',
  'UGANDA': 'Уганда',
  'UKRAINE': 'Україна',
  'UNITED ARAB EMIRATES': 'ОБ\'ЄДНАНІ АРАБСЬКІ ЕМІРАТИ',
  'UNITED KINGDOM': 'ОБ\'ЄДНАНЕ КОРОЛІВСТВО',
  'UNITED STATES': 'СПОЛУЧЕНІ ШТАТИ',
  'URUGUAY': 'Уругвай',
  'US MINOR OUTLYING ISLANDS': 'США незначні острови',
  'UZBEKISTAN': 'Узбекистан',
  'VANUATU': 'Вануату',
  'VENEZUELA': 'Венесуела',
  'VIETNAM': 'В\'єтнам',
  'VIRGIN ISLANDS (BRITISH)': 'Віргінські острови (британські)',
  'VIRGIN ISLANDS (US)': 'Віргінські острови (США)',
  'WALLIS AND FUTUNA': 'Уолліс і Футуна',
  'WESTERN SAHARA': 'Західна Сахара',
  'YEMEN': 'Ємен',
  'APPEARS ONLY IN STANAG 1059 EDITION 8 ANNEX B \TEMPORARY CODES\"."': 'З\'являється лише у Stanag 1059 Edition 8 Додаток B \ Тимчасові коди \ "."',
  'ZAMBIA': 'Замбія',
  'ZIMBABWE': 'Зімбабве',
  'BIOLOGICAL': 'Біологічний',
  'RADIOLOGICAL': 'Рентгенологічний',
  'DEGREES GRID': 'Сітка ступенів',
  'DEGREES MAGNETIC': 'Градуси магніт',
  'DEGREES TRUE': 'Градуси правдиві',
  'MILS GRID': 'Мілс сітка',
  'MILS MAGNETIC': 'MILS MAGNETIC',
  'MILS TRUE': 'MILS True',
  'ACTUAL LOCATION': 'Фактичне розташування',
  'ESTIMATED LOCATION': 'Орієнтовне місце розташування',
  'SUSPECTED': 'Підозрюваний',
  'OBSERVED': 'Спостережуваний',
  'BOMB': 'Бомба',
  'CANNON': 'Гармат',
  'DEVICE': 'Пристрій',
  'FUEL FABRICATION FACILITY': 'Об\'єкт виготовлення палива',
  'FISSILE MATERIAL STORAGE': 'Зберігання матеріалів',
  'FUEL REPROCESSING FACILITY': 'Об\'єкт для переробки палива',
  'MULTIPLE LAUNCH ROCKET SYSTEM': 'Кілька запущених ракетних систем',
  'PLANT': 'РОСЛИНА',
  'RAILROAD CAR': 'Залізничний автомобіль',
  'REACTOR NUCLEAR PLANT': 'Ядерна установка реактора',
  'RESEARCH NUCLEAR REACTOR': 'Дослідження ядерного реактора',
  'RADIOACTIVE WASTE STORAGE': 'Зберігання радіоактивних відходів',
  'SHIP': 'Корабель',
  'TOXIC INDUSTRIAL RADIOLOGICAL FACILITY': 'Токсичний промисловий рентгенологічний об\'єкт',
  'BOMBLETS': 'Бомбочки',
  'BULK MISSILE PAYLOAD (BULK WARHEAD)': 'Основна ракетна корисна навантаження (масова боєголовка)',
  'PRESSURIZED GAS BOTTLE': 'Газова пляшка під тиском',
  'BUNKER': 'Бункер',
  'CANISTER MISSILE PAYLOAD (BINARY AGENT WARHEAD)': 'Ракетна навантаження каністра (боєголовка бінарного агента)',
  'GENERIC STORAGE CONTAINER': 'Загальний контейнер для зберігання',
  'NOMINAL 200 LITRES STORAGE DRUM': 'Номінальний барабан для зберігання 200 літрів',
  'GENERATOR (AEROSOL)': 'Генератор (аерозоль)',
  'INTERMEDIATE BULK CONTAINER': 'Проміжний масовий контейнер',
  'LARGE ISO CONTAINERS': 'Великі контейнери ISO',
  'MINE (CBRN FILLED ONLY)': 'Шахта (лише CBRN наповнений)',
  'NUCLEAR WARHEAD': 'Ядерна боєголовка',
  'PIPE OR PIPELINE': 'Труба або трубопровід',
  'REACTOR': 'Реактор',
  'ROCKET': 'Ракета',
  'SHELL': 'Штурм',
  'SUB-MUNITIONS MISSILE PAYLOAD (SUB-MUNITIONS WARHEAD)': 'Ракетне навантаження підсумки (Waread) (Waread)',
  'SPRAY (TANK)': 'Спрей (танк)',
  'STOCKPILE': 'Запас',
  'STORAGE TANK': 'РЕЗЕРВУАР ДЛЯ ЗБЕРІГАННЯ',
  'TORPEDO': 'Торпеда',
  'WASTE': 'Витрачати',
  'KILOGRAM': 'Кілограм',
  'KILOTON': 'Кілотон',
  'POUND': 'Клопоти',
  'LONG TON': 'Довга тонна',
  'MEGATON': 'Мегатон',
  'SHORT TON': 'Коротка тонна',
  'METRIC TON (TONNE)': 'Метрична тонна (тонна)',
  'TON': 'Тонна',
  '(LESS THAN 1KG)': '(Менше 1 кг)',
  '(GREATER THAN 1KG BUT EQUAL TO OR LESS THAN 10KG)': '(Більше 1 кг, але дорівнює або менше 10 кг)',
  '(GREATER THAN 10KG BUT EQUAL TO OR LESS THAN 100KG)': '(Більше 10 кг, але дорівнює або менше 100 кг)',
  '(GREATER THAN 100KG)': '(Більше 100 кг)',
  '(200 LITRES/KILOGRAM OR LESS)': '(200 літрів/кілограм або менше)',
  '(GREATER THAN 200 LITRES/KILOGRAM BUT EQUAL TO OR LESS THAN 1500 LITRES/KILOGRAM)': '(Більше 200 літрів/кілограм, але дорівнює або менше 1500 літрів/кілограм)',
  '(GREATER THAN 1500 LITRES/KILOGRAM BUT EQUAL TO OR LESS THAN 50000 LITRES/KILOGRAM)': '(Більше 1500 літрів/кілограм, але дорівнює або менше 50000 літрів/кілограм)',
  '(GREATER THAN 50000 LITRES/KILOGRAM)': '(Більше 50000 літрів/кілограм)',
  '(EVIDENCE OF DISRUPTION/INTACT PACKAGE OR DEVICE)': '(Докази зриву/неушкодженого пакету або пристрою)',
  '(FIRE/EXPOSED SOURCE)': '(Пожежа/оголене джерело)',
  '(EXPLOSIONS AND FIRE/DAMAGED PACKAGE AND CONTAMINATION)': '(Вибухи та пожежа/пошкоджене пакет та забруднення)',
  'AIR': 'Повітря',
  'ONLY USED IN NUC REPORTS.': 'Використовується лише у звітах NUC.',
  'SURFACE': 'Поверхня',
  'HECTOMETRE': 'Гектометра',
  'KILOFEET': 'Кілофет',
  'STATUTE MILE': 'Статут миля',
  'BACTERIAL': 'Бактеріальний',
  'BLISTER AGENT': 'Блістер',
  'BLOOD': 'Кров',
  'CHOKING AGENT': 'Задушливий агент',
  'CHLAMYDIA': 'Хламідія',
  'NUCLEAR WEAPON FALLOUT': 'Випадання ядерної зброї',
  'G AGENT': 'G агент',
  'MUSTARD': 'Гірчиця',
  'INCAPACITATING': 'Недієздатний',
  'IRRITANT': 'Подразник',
  'NERV AGENT': 'Нервовий агент',
  'NO SUBSTANCE DETECTED (only used in CBRN4)': 'Жодна речовина не виявлена ​​(використовується лише в CBRN4)',
  'OTHER SUBSTANCE': 'Інша речовина',
  'PENETRATING AGENT': 'Проникливий агент',
  'RICKETTSIAE': 'Rickettsiae',
  'TOXIC INDUSTRIAL BIOLOGICAL': 'Токсичний промисловий біологічний',
  'TOXIC INDUSTRIAL CHEMICAL': 'Токсична промислова хімічна речовина',
  'TOXIN': 'Токсин',
  'V-AGENT': 'V-агент',
  'VIRAL': 'Вірусний',
  'VOMITING AGENT': 'Блювота',
  'HYDROGEN CYANIDE': 'Ціанід водню',
  'QUINUCLIDINYL BENZILATE': 'Хінуклідиніл бензилат',
  'PHOSGENE': 'Фосген',
  'CYANOGEN CHLORIDE': 'Ціаногенний хлорид',
  'PHOSGENE OXIME': 'Фосген Оксим',
  'DI-PHOSGENE': 'Ди-фосген',
  'TABUN': 'Табун',
  'SARIN': 'Сарін',
  'SOMAN': 'Соман',
  'CYCLO-SARIN': 'Цикло-сарін',
  'MUSTARD DISTILLED': 'Гірчичний дистильований',
  'MUSTARD-LEWISITE': 'Гірчиця',
  'NITROGEN MUSTARD': 'Азотна гірчиця',
  'TRIMERIC MUSTARD': 'Тримерна гірчиця',
  'LEWISITE': 'Хижак',
  'CHLOROPICRIN': 'Хлоропікрин',
  'ARSIN': 'Арсін',
  'TEAR GAS': 'СЛЬОЗОГІННИЙ ГАЗ',
  'VX': 'Vx',
  'BACILLUS ANTHRACIC': 'Антрациний бацил',
  'BRUCELLA SPP': 'Brucella spp',
  'VIBRIO CHOLERAE': 'Vibrio Cholerae',
  'ESCHERICHIA': 'Ешерріхія',
  'RICKETTSIA TYPHI': 'Rickettsia typhi',
  'YERSINIA PESTIS': 'Yersinia pestis',
  'COXIELLA BURNETII': 'Coxiella burnetii',
  'RICKETTSIA RICKETTSII': 'Rickettsia rickettsii',
  'SALMONELLA SPP': 'Salmonella spp',
  'ORIENTIA TSUTSUGAMUSHI': 'Orientia tsutsugamushi',
  'SHIGELLA DYSENTERIAE': 'Shigella dysenteriae',
  'FRANCISELLA TULARENSIS': 'Francisella tularensis',
  'SALMONELLA TYPHI': 'Сальмонела тифі',
  'JUNIN VIRUS': 'Вірус Джуніна',
  'MACHUPO VIRUS': 'Вірус Мачупо',
  'CHIKUNGUNYA VIRUS': 'Вірус Чікунгуня',
  'CRIMEAN-CONGO HEMORRHAGIC FEVER VIRUS': 'Кримсько-Конго геморагічна лихоманка вірус',
  'EBOLA VIRUS': 'Вірус Ебола',
  'EASTERN EQUINE ENCEPHALOMYEL ITIS VIRUS': 'Вірус Encephalomyel Encephalomyel Enceine Encephalomyel',
  'EUROPEAN TICK BORNE ENCEPHALITIS': 'Європейський кліщ, що переноситься на енцефаліт',
  'INFLUENZA VIRUS': 'Вірус грипу',
  'HANTAVIRUSES': 'Хантавіруси',
  'LASSA VIRUS': 'Вірус Ласса',
  'MARBURG VIRUS': 'Марбургський вірус',
  'MONKEYPOX VIRUS': 'Вірус мавпурок',
  'OMSK HEMORRHAGIC FEVER VIRUS': 'Омський геморагічний вірус лихоманки',
  'RIFT VALLEY FEVER VIRUS': 'Вірус лихоманки Ріфт -Веллі',
  'FLAVIVIRUSES': 'Флавівіруси',
  'VARIOLA VIRUS': 'Вірус Варіоли',
  'VENEZUELAN EQUINE ENCEPHALITIS VIRUS': 'Венесуельський вірус енцефаліту коней',
  'WESTERN EQUINE ENCEPHALITIS VIRUS': 'Західний вірус енцефаліту коней',
  'YELLOW FEVER VIRUS': 'Вірус жовтої лихоманки',
  'AFLATOXINS': 'Афлатоксини',
  'BOTULINUM TOXINS': 'Токсини ботуліну',
  'BURKHOLDERIA MALLEI': 'Burkholderia mallei',
  'BURKHOLDERIA PSEUDOMALLEI': 'Burkholderia pseudomallei',
  'CLOSTRIDIUM PERFRINGENS TOXINS': 'Clostridium perfringens токсини',
  'PALYTOXIN': 'Палитоксин',
  'RICINS': 'Рицини',
  'SAXITOXINS': 'Сакситоксини',
  'STAPHYLOCOCCAL ENTEROTOXINS': 'Стафілококові ентеротоксини',
  'TETRADOTOXIN': 'Тетрадотоксин',
  'TRICHOTHECENE MYCOTOXINS': 'Трихотенціль мікотоксини',
  'CESIUM': 'Цезій',
  'COBALT': 'Кобальт',
  'AMERICIUM': 'Американський',
  'URANIUM': 'Уран',
  'STRONTIUM': 'Стронцій',
  'PLUTONIUM': 'Плутоній',
  'IRRIDIUM': 'Іридій',
  'SCANDIUM': 'Скандію',
  'YTURBIUM': 'Ітурбій',
  'CALIFORNIUM': 'Каліфорнія',
  'RADIUM': 'Радій',
  'IODINE': 'Йод',
  'KRYPTON': 'Криптон',
  'POLONIUM': 'Полоній',
  'AMERICIUM/BERILLIUM': 'Americium/Berillium',
  'RUTHENIUM/RHODIUM': 'Рутеній/Роудій',
  'MIXTURE OF RADIATION EMISSIONS': 'Суміш радіаційних викидів',
  'CURIUM': 'Курію',
  'PROMETHIUM': 'Промієт',
  'THULIUM': 'Тюліум',
  'NON-PERSISTENT': 'Непересічний',
  'PERSISTENT': 'Наполегливий',
  'THICKENED': 'Потовщений',
  'AERIAL SURVEY': 'Повітряне опитування',
  'DEPLOYED LABORATORY': 'Розгорнута лабораторія',
  'MANNED POINT DETECTION SYSTEM': 'Система виявлення пілотованих точок',
  'MANNED STAND-OFF DETECTION SYSTEM': 'Пішохідна система виявлення строку',
  'MANNED SURVEY': 'Пілотоване опитування',
  'REMOTE DETECTION': 'Віддалене виявлення',
  'SATELLITE-BASED DETECTION': 'Виявлення на основі супутників',
  'UN-MANNED AERIAL SURVEY': 'Повітряне опитування',
  'UN-MANNED GROUND SURVEY': 'Опитування ґрунтовки без рук',
  'UN-MANNED DETECTION SYSTEM': 'Система виявлення без рук',
  'UN-MANNED POINT DETECTION SYSTEM': 'Система виявлення точок без рук',
  'UN-MANNED STAND-OFF DETECTION SYSTEM': 'Система виявлення без рук',
  'UN-MANNED SURVEY': 'Опитування безперешкодного',
  '(USE GENTEXT OR AMPN SET TO SPECIFY)': '(Використовуйте набір gentext або ampn для вказівки)',
  'DEFINITIVE': 'Остаточний',
  'EVIDENTIAL': 'Доказовий',
  'INDICATIVE': 'Вказівний',
  'PRESUMPTIVE': 'Припущений',
  'ACTIVATED RADIOLOGICAL DISPERSION DEVICE': 'Активований рентгенологічний дисперсійний пристрій',
  'VISIBLE CLOUD': 'Видима хмара',
  'DAMAGED PACKAGE AND CONTAMINATION': 'Пошкоджений пакет та забруднення',
  'EVIDENCE OF SITE DISRUPTION': 'Докази порушення сайту',
  'EXPLOSIONS AND FIRE': 'Вибухи та вогонь',
  'EXPOSED SOURCE': 'Оголене джерело',
  'BURNING FIRE': 'Палаючий вогонь',
  'INTACT PACKAGE OR DEVICE': 'Неушкоджений пакет або пристрій',
  'SUBSTANCE SPILLED INTO WATER': 'Речовина пролита у воду',
  'CONTINUOUS FLOW FROM DAMAGED PIPE OR CONTAINER': 'Безперервний потік з пошкодженої труби або контейнера',
  'LIQUID': 'Рідкий',
  'MISSILE INTERCEPT': 'Ракетний перехоплення',
  'NON ACTIVATED RADIOLOGICAL DISPERSION DEVICE': 'Не активований рентгенологічний дисперсійний пристрій',
  'LARGE QUANTITY OF STILL LIQUID': 'Велика кількість нерухомої рідини',
  'CATASTROPHIC RUPTURE OF A TANK': 'Катастрофічний розрив танка',
  'SMALL QUANTITY OF STILL LIQUID': 'Невелика кількість нерухомої рідини',
  'CONTINUOUS': 'Безперервний',
  'SINGLE RELEASE OF A CLOUD': 'Єдиний випуск хмари',
  'SPRAYING': 'Розпилення',
  'FLAT': 'Рівномірний',
  'HILL': 'Пагорб',
  'SEA': 'Море',
  'URBAN': 'Міський',
  'VALLEY': 'Долина',
  'TERRAIN HAS NO APPARENT VEGETATION.': 'Місцевість не має явної рослинності.',
  'TERRAIN IS GENERALLY COVERED WITH LOW BUSHES OR SCRUB SUCH THAT PERSONNEL TRAVERSING WOULD BRUSH AGAINST THE FOLIAGE AND BE CONTAMINATED WITH LIQUID CHEMICAL AGENT.': 'Місцевість, як правило, покрита низькими кущами або скрабом, щоб перехід персоналу протистояв на листя і забруднити рідким хімічним засобом.',
  'TERRAIN IS THICKLY WOODED.': 'Місцевість густо лісиста.',
  'KILOMETRES PER HOUR': 'Кілометри на годину',
  'NAUTICAL MILES PER HOUR (KNOTS)': 'Морські милі на годину (вузли)',
  'STATUTE MILES PER HOUR': 'Статут милі на годину',
  'METRES PER SECOND': 'Метри на секунду',
  'UNSTABLE': 'Нестабільний',
  'VERY UNSTABLE': 'Дуже нестабільний',
  'SLIGHTLY UNSTABLE': 'Трохи нестабільний',
  'NEUTRAL': 'Нейтральний',
  'SLIGHTLY STABLE': 'Злегка стабільний',
  'STABLE': 'Конюшня',
  'VERY STABLE': 'Дуже стійкий',
  'DEGREES CELSIUS': 'ГРАДУСИ ЗА ЦЕЛЬСІЄМ',
  'DEGREES FAHRENHEIT': 'Градусів Фаренгейт',
  '0 PERCENT THROUGH 9 PERCENT': '0 відсотків до 9 відсотків',
  '10 PERCENT THROUGH 19 PERCENT': '10 відсотків до 19 відсотків',
  '20 PERCENT THROUGH 29 PERCENT': '20 відсотків до 29 відсотків',
  '30 PERCENT THROUGH 39 PERCENT': '30 відсотків до 39 відсотків',
  '40 PERCENT THROUGH 49 PERCENT': '40 відсотків до 49 відсотків',
  '50 PERCENT THROUGH 59 PERCENT': '50 відсотків до 59 відсотків',
  '60 PERCENT THROUGH 69 PERCENT': '60 відсотків до 69 відсотків',
  '70 PERCENT THROUGH 79 PERCENT': '70 відсотків до 79 відсотків',
  '80 PERCENT THROUGH 89 PERCENT': '80 відсотків до 89 відсотків',
  '90 PERCENT THROUGH 100 PERCENT': '90 відсотків до 100 відсотків',
  'NO SIGNIFICANT WEATHER PHENOMENA': 'Немає значних погодних явищ',
  'SEA BREEZE': 'Морський бриз',
  'LAND BREEZE': 'Земляний вітерець',
  'BLOWING SNOW, SAND STORM, DUST STORM.': 'Видування снігу, піщана буря, пилова буря.',
  'FOG, ICE FOG OR THICK HAZE (VISIBILITY LESS THAN FOUR KM).': 'Туман, крижаний туман або густа імла (видимість менше чотирьох км).',
  'DRIZZLE': 'Мріяти',
  'RAIN': 'Дощ',
  'SNOW OR RAIN AND SNOW MIXED (NO SHOWERS).': 'Сніг або дощ та сніг змішані (без душових).',
  'SHOWERS OF RAIN, SNOW, RAIN AND SNOW MIXED, OR HAIL.': 'Змішувачі дощу, снігу, дощу та снігу змішані, або град.',
  'THUNDERSTORMS, WITH OR WITHOUT PRECIPITATION.': 'Грози, з опадами або без них.',
  'TOP OF INVERSION LAYER LOWER THAN 800 METRES': 'Верх інверсійного шару нижче 800 метрів',
  'TOP OF INVERSION LAYER LOWER THAN 400 METRES': 'Верх інверсійного шару нижче 400 метрів',
  'TOP OF INVERSION LAYER LOWER THAN 200 METRES': 'Верх інверсійного шару нижче 200 метрів',
  'LESS THAN HALF COVERED (SCATTERED)': 'Менше половини покритого (розкиданий)',
  'MORE THAN HALF COVERED (BROKEN)': 'Більше половини покритого (зламано)',
  'COMPLETELY COVERED (OVERCAST)': 'Повністю покритий (похмуро)',
  'NO CLOUD (CLEAR CONDITIONS)': 'Немає хмари (чіткі умови)',
  'MILS': 'MIL',
  'DEGREES': 'Ступінь',
  'TOP': 'Топ',
  'BOTTOM': 'Дно',
  'FRESH NUCLEAR FUEL': 'Свіже ядерне паливо',
  'INDUSTRIAL SOURCE': 'Промислове джерело',
  'MEDICAL SOURCE': 'Медичне джерело',
  'MILITARY WEAPON SOURCE': 'Джерело військової зброї',
  'RADIOLOGICAL DEVICE POINT SOURCE': 'Радіологічне джерело точки пристрою',
  'SPENT REACTOR FUEL': 'Проведено паливо реактора',
  'ALPHA': 'Альфа',
  'BETA': 'Бета',
  'GAMMA': 'Гамма',
  'NEUTRON': 'Нейтрон',
  'Mixture of radiation emissions': 'Суміш радіаційних викидів',
  'Not known': 'Невідомий',
  'AMERICIUM/BERYLLIUM': 'Americium/Beryllium',
  'DEPELETED URANIUM': 'Закінчений уран',
  'IRIDIUM': 'Іридій',
  'COBALT 58': 'Кобальт 58',
  'IODINE 125': 'Йод 125',
  'IODINE 131': 'Йод 131',
  'NATURAL URANIUM': 'Природний уран',
  'POTASSIUM': 'Калій',
  'PLUTONIUM 238': 'Плутоній 238',
  'PLUTONIUM 239': 'Плутоній 239',
  'PLUTONIUM 240': 'Plutonium 240',
  'THORIUM 232': 'Торій 232',
  'URANIUM 233': 'Уран 233',
  'URANIUM 235': 'Уран 235',
  'URANIUM 240': 'Уран 240',
  'YTTERBIUM': 'Іттербій',
  'ECHO': 'Відлуння',
  'DAY': 'День',
  'HOUR': 'Година',
  'MINUTE': 'Хвилина',
  'MONTH': 'Місяць',
  'SECOND': 'Другий',
  'WEEK': 'Тиждень',
  'YEAR': 'Рік',
  'GAMMA SPECTROMETER': 'Гамма -спектрометр',
  'HAND HELD GAMMA SURVEY MONITOR': 'Вручну проводиться монітор обстеження гамми',
  'PERSONAL INDICATING DOSIMETER': 'Особистий, що вказує на дозиметр',
  'RADIOLOGICAL CONTAMINATION PROBE': 'Рентгенологічне зонд забруднення',
  'REMOTE DETECTION SYSTEM': 'Система віддаленого виявлення',
  'SATELLITE-BASED DETECTION SYSTEMS': 'Системи виявлення на супутниковій основі',
  'VEHICLE BORN RADIATION DETECTOR': 'Детектор радіаційного транспортного засобу',
  'CONFIRMED': 'Підтверджений',
  'PROVISIONAL': 'Тимчасовий',
  'UNAMBIGUOUS': 'Однозначний',
  'VARIABLE': 'Змінний',
  '50 DEGREES': '50 градусів',
  'LESS THAN 40 DEGREES': 'Менше 40 градусів',
  '60 DEGREES': '60 градусів',
  '70 DEGREES': '70 градусів',
  '80 DEGREES': '80 градусів',
  '90 DEGREES': '90 градусів',
  '100 DEGREES': '100 градусів',
  '110 DEGREES': '110 градусів',
  '120 DEGREES': '120 градусів',
  'MORE THAN 120 DEGREES': 'Більше 120 градусів',
  'HUNDREDS OF FEET': 'Сотні футів',
  'AGENT CONTAINING PARTICLES PER LITRE': 'Агент, що містять частинки на літр',
  'BECQUEREL PER SQUARE METER': 'Becquerel на квадратний метр',
  'BECQUEREL PER CUBIC METER': 'Becquerel на кубічний метр',
  'MILLIGRAMS PER SQUARE METER': 'Міліграм на квадратний метр',
  'MILLIGRAMS PER CUBIC METER': 'Міліграм на кубічний метр',
  'PARTS PER BILLION': 'Частини на мільярд',
  'PARTS PER MILLION': 'Частини на мільйон',
  'COLONY FORMING UNITS PER SQUARE METRE': 'Колонії, що утворюють одиниці на квадратний метр',
  'COLONY FORMING UNITS PER MILLIMETRE': 'Колонія, що утворюють одиниці на міліметр',
  'CENTIGRAY': 'Центригрей',
  'CENTISIEVERT': 'Centisievert',
  'MILLIGRAY': 'Міліграй',
  'MILLIGRAM PER 70 KG PERSON': 'Міліграм на 70 кг',
  'MILLISIEVERT': 'Millisievert',
  'NUMBER OF MICRO-ORGANISMS': 'Кількість мікроорганізмів',
  'MICROGRAY': 'Мікрограй',
  'MICROGRAM PER 70 KG PERSON': 'Мікрограма на 70 кг',
  'MICROSIEVERT': 'Microsievert',
  'CENTIGRAY PER HOUR': 'Центрігрей на годину',
  'CENTISIEVERT PER HOUR': 'Centisievert на годину',
  'MICROSIEVERT PER HOUR': 'MicroSievert за годину',
  'MILLIGRAY PER HOUR': 'Міліграй на годину',
  'MILLIGRAM-MINUTES PER CUBIC METER': 'Мілліграм-хвилини на кубічний метр',
  'MILLISIEVERT PER HOUR': 'Millisievert на годину',
  'MICROGRAY PER HOUR': 'Мікрогрей на годину',
  'INCAPACITATING DOSAGE': 'Недієздатна доза',
  'INCAPACITATING DOSE': 'Недієздатна доза',
  'LETHAL DOSAGE': 'Смертельна доза',
  'LETHAL DOSE': 'Смертельна доза',
  'NEGATIVE INDICATOR': 'Негативний показник',
  'AFFIRMATIVE INDICATOR': 'Стверджуючий показник',
  'TO PUT A COMMUNICATION FACILITY INTO EXISTENCE BY OFFICIAL ORDER SO IT CAN FUNCTION IN ITS ASSIGNED CAPACITY.': 'Щоб встановити комунікаційне обладнання, офіційним наказом, щоб він міг функціонувати в його призначеній якості.',
  'REFERS TO THE CHANGE OF LOCATION OF WHQS.': 'Відноситься до зміни розташування WHQS.',
  'THE POINT AT WHICH THE COMMUNICATION SYSTEM(S) HAVE REACHED THEIR CAPACITY AND CAN NO LONGER PROCESS TRAFFIC AS IT IS GENERATED BY THE USERS OF THE SYSTEM(S).': 'Точка, в якій система комунікацій досягла своєї потужності і не може більше обробляти трафік, оскільки вона генерується користувачами системи (ів).',
  'PLACING A COMMUNICATIONS FACILITY IN AN INACTIVE STATUS.': 'Розміщення комунікаційного закладу в неактивному статусі.',
  'THE PLACING OF COMMUNICATIONS FACILITIES/EQUIPMENT IN AN UNUSABLE AND IRREPARABLE CONDITION': 'Розміщення комунікаційних засобів/обладнання в непридатному та непоправному стані',
  'INDICATES THE COMMUNICATION SERVICE NORMALLY PROVIDED BY A COMMUNICATION FACILITY HAS BEEN DISRUPTED BY SOME CAUSE.': 'Вказує, що послуга зв\'язку, як правило, надається комунікаційним закладом, була порушена деякою причиною.',
  'INDICATES THE COMMUNICATION FACILITY SERVICE IS EXPERIENCING DIFFICULTY OR LOSS OF SERVICE DUE TO RADIATION, RERADIATION, OR REFLECTION OF ELECTROMAGNETIC ENERGY.': 'Вказує, що служба комунікаційних закладів відчуває труднощі або втрату послуги через випромінювання, переробку або відображення електромагнітної енергії.',
  'ANY EVENT OTHER THAN THE ABOVE SPECIFIC DATA ITEMS. ENTER EXPLANATION IN SUBSEQUENT FREE TEXT SET': 'Будь -яка подія, крім наведених вище конкретних елементів даних. Введіть пояснення в наступному наборі безкоштовного тексту',
  'INDICATES THE COMMUNICATION FACILITY/SERVICE HAS SUFFERED OR WILL SUFFER A LOSS OF SERVICE.': 'Вказує, що комунікація/послуга зазнала або зазнає втрати послуг.',
  'INDICATES THE COMMUNICATION FACILITY/SERVICE HAS BEEN OR IS RECONFIGURED TO PROVIDE/DELETE A COMMUNICATION SERVICE.': 'Вказує, що комунікація/послуга була або переналаштована для надання/видалення послуги зв\'язку.',
  'INDICATES THE COMMUNICATION FACILITY/SERVICE HAS BEEN/WILL BE RELOCATED TO A NEW AREA.': 'Вказує, що комунікація/послуга була/буде перенесена на нову область.',
  'INDICATES THE DISRUPTED COMMUNICATION SERVICE HAS BEEN RESTORED TO AN ACTIVE STATUS AFTER A TEMPORARY OUTAGE.': 'Вказує, що служба порушеної комунікації була відновлена ​​до активного статусу після тимчасового відключення.',
  'AS SOON AS POSSIBLE': 'ЯКНАЙШВИДШЕ',
  'INDEFINITE': 'Невизначений',
  'UNTIL FURTHER NOTICE': 'ДО ПОДАЛЬШОГО ПОВІДОМЛЕННЯ',
  'AIRCRAFT NOT AVAILABLE': 'Літак недоступний',
  'AIRFIELD OR FACILITIES UNSERVICABLE': 'Аеродром або споруди несерйозно',
  'CREW NOT AVAILABLE': 'Екіпаж недоступний',
  'ENEMY ACTION': 'Ворожі дії',
  'PRIORITY OF OTHER REQUESTS PREVAIL': 'Пріоритет інших запитів переважає',
  'WEATHER UNSUITABLE': 'Погода непридатна',
  'LOCATION UNSUITABLE': 'Місцезнаходження непридатне',
  'MAP REFERENCES AMBIGUOUS': 'Посилання на карту неоднозначні',
  'ANIMAL': 'Тварина',
  'MOTORCYCLE': 'Мотоцикл',
  'ROAD VEHICLE': 'Дорожній транспортний засіб',
  'VEHICLE': 'Транспортний засіб',
  'LAST 24 HOURS': 'Останні 24 години',
  'NEXT 24 HOURS': 'Наступні 24 години',
  'NEXT 48 HOURS': 'Наступні 48 годин',
  'INLAND WATERWAYS': 'Внутрішні водні шляхи',
  'RAIL': 'Залізничний',
  '20 FOOT CONTAINER': '20 -футовий контейнер',
  '40 FOOT CONTAINER': '40 -футовий контейнер',
  'AIR PORT': 'Повітряний порт',
  'RAIL LOADING POINT': 'Точка завантаження рейки',
  'SEA PORT': 'Морський порт',
  'HIGH FREQUENCY TRANSMISSION IN KILOHERTZ, AM': 'Висока частота передачі в Кіхерц, Am',
  'HIGH FREQUENCY TRANSMISSION IN KILOHERTZ, LOWER SIDEBAND': 'Високочастотна передача в Кілогерці, нижня бічна смуга',
  'TROPOSPHERIC TRANSMISSION IN GIGAHERTZ': 'Тропосферна передача в Gigahertz',
  'HIGH FREQUENCY TRANSMISSION IN KILOHERTZ, UPPER SIDEBAND': 'Високочастотна передача в Кілогерці, верхня бічна смуга',
  'SEQUENCE OF GRID REF DESCRIBES AN AREA CLOCKWISE; THE FIRST FIELD MAY NOT BE REPEATED AT THE END TO CLOSE THE LINE.': 'Послідовність сітки Ref описує область за годинниковою стрілкою; Перше поле може не повторити в кінці, щоб закрити лінію.',
  'EACH GRID REF IS DISPLAYED AS A DOT.': 'Кожна сітка Ref відображається як крапка.',
  'COUNTERMOBILITY': 'Контргранність',
  'GENERAL ENGINEER SUPPORT': 'Загальна підтримка інженерів',
  'MOBILITY': 'Мобільність',
  'SURVIVABILITY': 'Виживаність',
  'CROSSING AREA': 'Область перехрестя',
  'MINEFIELD BOUNDARY': 'Межа мінного поля',
  'ROUTE/AXIS': 'Маршрут/вісь',
  'GALLON': 'Галон',
  'PINT': 'Пінта',
  'QUART': 'Чверть',
  'BAG': 'Мішок',
  'BOARD FEET': 'Ноги',
  'BOX': 'Коробка',
  'COIL': 'Котушка',
  'CRATE': 'Ящик',
  'CASE': 'Випадок',
  'CUBIC METRE': 'Кубічний метр',
  'CUBIC YARD': 'Кубічний двір',
  'DOZEN': 'Десяток',
  'DRUM': 'Барабан',
  'EACH': 'Кожен',
  'GRAM': 'Грам',
  'IMPERIAL GALLON': 'Імператорський галон',
  'INCH': 'Дюйм',
  'IMPERIAL PINT': 'Імператорська пінта',
  'IMPERIAL QUART': 'Імператорський кварт',
  'LITRE': 'Літер',
  'OUNCE': 'Унція',
  'PALLET': 'Піддон',
  'PACKET': 'Пакет',
  'ROUNDS': 'Раунди',
  'SQUARE METRE': 'Квадратний метр',
  'EQUIPMENT': 'Обладнання',
  'LOGISTIC': 'Логістичний',
  'MATERIEL': 'Матеріал',
  'PERSONNEL': 'Персонал',
  'TIME': 'Час',
  'AIR DEFENCE ARTILLERY - HEAVY': 'Артилерія протиповітряної оборони - важка',
  'AIR DEFENCE ARTILLERY - LIGHT': 'Артилерія протиповітряної оборони - Світло',
  'AIR DEFENCE ARTILLERY - MEDIUM': 'Артилерія протиповітряної оборони - середня',
  'AIR DEFENCE ARTILLERY - MISSILE': 'Артилерія протиповітряної оборони - ракета',
  'AIR DEFENCE ARTILLERY - POSITION': 'Артилерія протиповітряної оборони - позиція',
  'AIR DEFENCE ARTILLERY - UNKNOWN': 'Артилерія протиповітряної оборони - Невідома',
  'ARMOR - ARMORED PERSONNEL CARRIER': 'Броня - бронетанковий персонал',
  'ARMOR - HEAVY': 'Броня - Важка',
  'ARMOR - LIGHT': 'Броня - Світло',
  'ARMOR - MEDIUM': 'Броня - середня',
  'ARMOR - POSITION': 'Броня - положення',
  'ARMOR - UNKNOWN': 'Броня - невідома',
  'ARTILLERY - HEAVY': 'Артилерія - важка',
  'ARTILLERY - LIGHT': 'Артилерія - Світло',
  'ARTILLERY - MEDIUM': 'Артилерія - середня',
  'ARTILLERY - POSITION': 'Артилерія - позиція',
  'ARTILLERY - UNKNOWN': 'Артилерія - Невідома',
  'ASSEMBLY AREAS - TROOPS': 'Асамблея - війська',
  'ASSEMBLY AREAS - TROOPS AND ARMOR': 'Зонні зони - війська та броня',
  'ASSEMBLY AREAS - MECHANIZED TROOPS': 'Області складання - механізовані війська',
  'ASSEMBLY AREAS - TROOPS AND VEHICLES': 'Зонні зони - війська та транспортні засоби',
  'ASSEMBLY AREAS - UNKNOWN': 'Області складання - невідомі',
  'BUILDING - CONCRETE': 'Будівля - бетон',
  'BUILDING - MASONRY': 'Будівля - Масонство',
  'BUILDING - METAL': 'Будівля - метал',
  'BUILDING - SPECIAL PURPOSE': 'Будівництво - Спеціальне призначення',
  'BUILDING - UNKNOWN': 'Будівля - Невідома',
  'BUILDING - WOOD': 'Будівля - деревина',
  'BRIDGE - CONCRETE': 'Міст - бетон',
  'BRIDGE - FERRY': 'Міст - пором',
  'BRIDGE - FOOT PONTOON': 'Міст - Понтон для ніг',
  'BRIDGE - RAFT': 'Міст - пліт',
  'BRIDGE - SITE': 'Міст - Сайт',
  'BRIDGE - STEEL': 'Міст - сталь',
  'BRIDGE - UNKNOWN': 'Міст - Невідомий',
  'BRIDGE - VEHICLE PONTOON': 'Міст - понтон транспортного засобу',
  'BRIDGE - WOOD': 'Міст - деревина',
  'CENTER - BATTALION': 'Центр - батальйон',
  'CENTER - DIVISION': 'Центр - Відділ',
  'CENTER - FORWARD': 'Центр - вперед',
  'CENTER - REGIMENT': 'Центр - полк',
  'CENTER - SMALL': 'Центр - невеликий',
  'CENTER - UNKNOWN': 'Центр - Невідомий',
  'EQUIPMENT - ELECTRONIC WARFARE': 'Обладнання - Електронна війна',
  'EQUIPMENT - GUIDANCE': 'Обладнання - керівництво',
  'EQUIPMENT - LOUD-SPEAKER': 'Обладнання - гучниймовник',
  'EQUIPMENT - RADAR': 'Обладнання - радар',
  'EQUIPMENT - SEARCH-LIGHT': 'Обладнання - пошук',
  'EQUIPMENT - UNKNOWN': 'Обладнання - Невідоме',
  'MORTARS - HEAVY': 'Міномети - важкі',
  'MORTARS - LIGHT': 'Міномети - Світло',
  'MORTARS - MEDIUM': 'Міномети - середні',
  'MORTARS - POSITION': 'Міномети - положення',
  'MORTARS - UNKNOWN': 'Міномети - невідомі',
  'MORTARS - VERY HEAVY': 'Міномети - дуже важкі',
  'PERSONNEL - INFANTRY': 'Персонал - піхота',
  'PERSONNEL - OBSERVATION POST': 'Персонал - спостереження',
  'PERSONNEL - POSITION': 'Персонал - позиція',
  'PERSONNEL - PATROL': 'Персонал - патруль',
  'PERSONNEL - UNKNOWN': 'Персонал - невідомий',
  'PERSONNEL - WORK PARTY': 'Персонал - Робоча партія',
  'ROCKETS/MISSILES - ANTI-PERSONNEL': 'Ракети/ракети - анти -особистий',
  'ROCKETS/MISSILES - ANTI-TANK': 'Ракети/ракети - протитанковий',
  'ROCKETS/MISSILES - HEAVY MISSILE': 'Ракети/ракети - Важка ракета',
  'ROCKETS/MISSILES - LIGHT MISSILE': 'Ракети/ракети - Світла ракета',
  'ROCKETS/MISSILES - MEDIUM MISSILE': 'Ракети/ракети - Середня ракета',
  'ROCKETS/MISSILES - POSITION': 'Ракети/ракети - положення',
  'ROCKETS/MISSILES - UNKNOWN': 'Ракети/ракети - невідомі',
  'SUPPLY DUMP - CLASS 1': 'Скидання постачання - клас 1',
  'SUPPLY DUMP - CLASS 2': 'Дамп постачання - клас 2',
  'SUPPLY DUMP - CLASS 3': 'Дамп постачання - клас 3',
  'SUPPLY DUMP - CLASS 4': 'Дамп постачання - клас 4',
  'SUPPLY DUMP - CLASS 5': 'Скидання постачання - клас 5',
  'SUPPLY DUMP - UNKNOWN': 'Скидання постачання - Невідомий',
  'TERRAIN FEATURES - DEFILE': 'Особливості місцевості - дефіл',
  'TERRAIN FEATURES - HILL': 'Особливості місцевості - Хілл',
  'TERRAIN FEATURES - ROAD JUNCTION': 'Особливості місцевості - дорожній вузол',
  'TERRAIN FEATURES - LANDING STRIP': 'Особливості місцевості - посадкова смуга',
  'TERRAIN FEATURES - ROAD': 'Особливості місцевості - дорога',
  'TERRAIN FEATURES - RAILROAD': 'Особливості місцевості - залізниця',
  'TERRAIN FEATURES - UNKNOWN': 'Особливості місцевості - невідомі',
  'VEHICLE - AIRCRAFT': 'Транспортний засіб - літак',
  'VEHICLE - BOATS': 'Транспортний засіб - човни',
  'VEHICLE - HELICOPTER': 'Транспортний засіб - вертоліт',
  'VEHICLE - HEAVY WHEELED': 'Транспортний засіб - Важка колеса',
  'VEHICLE - LIGHT WHEELED': 'Транспортний засіб - легкий колесо',
  'VEHICLE - RECONNAISSANCE': 'Транспортний засіб - розвідка',
  'VEHICLE - UNKNOWN': 'Транспортний засіб - невідомий',
  'WEAPONS - ANTITANK GUN': 'Зброя - протитанкова пістолет',
  'WEAPONS - HEAVY MACHINE GUN': 'Зброя - важкий кулемет',
  'WEAPONS - LIGHT MACHINE GUN': 'Зброя - легкий кулемет',
  'WEAPONS - RECOILLESS RIFLE': 'Зброя - повторна гвинтівка',
  'WEAPONS - UNKNOWN': 'Зброя - невідома',
  'BRIDGE - ABUTMENT': 'Міст - пристосування',
  'BRIDGE - CENTRE SPAN': 'Міст - центральний проміжок',
  'BRIDGE - EXIT APPROACHS': 'Міст - Вихідні підходи',
  'BRIDGE - FAR ABUTMENT': 'Міст - Далекий абатмент',
  'BRIDGE - NEAR ABUTMENT': 'Міст - поблизу абатмента',
  'BRIDGE - PIER': 'Міст - причал',
  'TERRAIN FEATURES - AIRFIELD': 'Особливості місцевості - аеродром',
  'TERRAIN FEATURES - AREA': 'Особливості місцевості - площа',
  'TERRAIN FEATURES - CANAL': 'Особливості місцевості - канал',
  'TERRAIN FEATURES - CULVERT': 'Особливості місцевості - квот',
  'TERRAIN FEATURES - DITCH': 'Особливості місцевості - канав',
  'TERRAIN FEATURES - EMBANKMENT': 'Особливості місцевості - набережна',
  'TERRAIN FEATURES - FENCE': 'Особливості місцевості - паркан',
  'TERRAIN FEATURES - FORD': 'Особливості місцевості - Ford',
  'TERRAIN FEATURES - GAP': 'Особливості місцевості - GAP',
  'TERRAIN FEATURES - MOTORWAY': 'Особливості місцевості - автострада',
  'TERRAIN FEATURES - PASS': 'Особливості місцевості - проходження',
  'TERRAIN FEATURES - PIPELINE': 'Особливості місцевості - трубопровід',
  'TERRAIN FEATURES - RIVER': 'Особливості місцевості - річка',
  'TERRAIN FEATURES - RIVERBANK': 'Особливості місцевості - Рівербанк',
  'TERRAIN FEATURES - TRACK': 'Особливості місцевості - трек',
  'TERRAIN FEATURES - TRACK JUNCTION': 'Особливості місцевості - Track Junction',
  'TERRAIN FEATURES - TUNNEL': 'Особливості місцевості - тунель',
  'TERRAIN FEATURES - VALLEY': 'Особливості місцевості - Долина',
  'TERRAIN FEATURES - WOOD': 'Особливості місцевості - деревина',
  'UNDER OVERHEAD COVER': 'Під накладкою',
  'DUG-IN': 'Викопаний',
  'HALF PRONE, HALF STANDING AT FIRST VOLLEY; ALL PRONE AFTER FIRST VOLLEY.': 'Наполовину схильний, наполовину стоячи спочатку залп; Всі схильні після першого залпу.',
  'PRONE': 'Схильний',
  'PRONE, UNDER OVERHEAD COVER AFTER FIRST VOLLEY ATTACK': 'Схильний, під накладним покриттям після першого нападу',
  'PRONE, DUG-IN, AFTER FIRST VOLLEY ATTACK': 'Схильний, викопаний, після першої нападу залпів',
  'USED FOR ARTILLERY UNITS ONLY. AN INDICATION THAT A HIGH TRAJECTORY IS TO BE EMPLOYED BY USING ELEVATION ANGLES GREATER THAN 800 MILS FOR EACH WEAPON.': 'Використовується лише для артилерійських одиниць. Вказівка ​​на те, що висока траєкторія повинна використовуватись за допомогою кутів висоти більше 800 миль для кожної зброї.',
  'USED FOR ARTILLERY UNITS ONLY. AN INDICATION THAT A LOW TRAJECTORY IS TO BE EMPLOYED BY USING ELEVATION ANGLES FOR EACH WEAPON THAT ARE 800 MILS OR LESS.': 'Використовується лише для артилерійських одиниць. Вказівка ​​на те, що низька траєкторія повинна використовуватись за допомогою кутів висоти для кожної зброї, яка становить 800 міл або менше.',
  'APPLICABLE TO 105MM, 155MM, AND NGF (5IN38, 5IN54, 6IN47, 8IN55).': 'Застосовується до 105 мм, 155 мм та NGF (5in38, 5in54, 6in47, 8in55).',
  'AT MY COMMAND': 'За моєю командою',
  'TIME ON TARGET': 'Час на ціль',
  'TIME TO FIRE': 'Час вогню',
  'WHEN READY': 'Коли буде готовий',
  'END OF MISSION-RECORD AS TARGET': 'Кінець місії рекорд як ціль',
  'END OF MISSION': 'Кінець місії',
  'CANCEL CHECK FIRE': 'Скасувати перевірку вогонь',
  'CANCEL CEASE LOADING': 'Скасувати припинення завантаження',
  'CEASE LOADING': 'Припинити завантаження',
  'CHECK FIRE': 'Перевірте вогонь',
  'COMMAND TO EXECUTE': 'Команда для виконання',
  'CANNOT COMPLY': 'Не може дотримуватися',
  'ROUNDS COMPLETE': 'Раунди завершені',
  'EXECUTION COMPLETE': 'Виконання завершено',
  'READY': 'Готовий',
  'BURNING': 'Спалювання',
  'DESTROYED': 'Знищений',
  'NEUTRALIZED/BURNING': 'Нейтралізований/спалювання',
  'NEUTRALIZED': 'Нейтралізований',
  'NO EFFECT': 'Без ефекту',
  'UNKNOWN EFFECT': 'Невідомий ефект',
  'NO CHANGE': 'БЕЗ ЗМІН',
  'NATO UNCLASSIFIED': 'НАТО некласифіковані',
  'UNCLASSIFIED': 'Незахищений',
  'NATO RESTRICTED': 'НАТО обмежено',
  'RESTRICTED': 'Обмежений',
  'NATO CONFIDENTIAL': 'НАТО конфіденційно',
  'CONFIDENTIAL': 'Конфіденційний',
  'NATO SECRET': 'НАТО секрет',
  'SECRET': 'Таємний',
  'COSMIC TOP SECRET': 'Космічний топ -секрет',
  'TOP SECRET': 'ДУЖЕ СЕКРЕТНО',
  'FOR CODEWORD MATERIAL': 'Для матеріалу кодорда',
  'NATO UNCLASSIFIED/PFP RELEASABLE': 'НАТО некласифіковані/PFP',
  'NATO RESTRICTED/PFP RELEASABLE': 'НАТО обмежений/PFP',
  'NATO CONFIDENTIAL/PFP RELEASABLE': 'НАТО конфіденційно/PFP',
  'NATO SECRET/PFP RELEASABLE': 'НАТО Секрет/ПФП',
  'ATOMAL': 'Атомальний',
  'CRYPTO SECURITY': 'Криптовалюта',
  'DATA MESSAGE': 'Повідомлення про дані',
  'EXCLUSIVE': 'Ексклюзивний',
  'USE SUBSEQUENT FREE TEXT SET TO EXPLAIN NAME OR ABBREVIATION OF THE PERSON, ORGANISATION, OR COUNTRY FOR WHICH THE MESSAGE IS INTENDED.': 'Використовуйте наступний безкоштовний набір тексту, щоб пояснити ім\'я або абревіатуру особи, організації чи країни, для якої призначено повідомлення.',
  'SPECIAL INTELLIGENCE OPERATIONS': 'Спеціальні розвідувальні операції',
  'SPECIAL FORMAT': 'Спеціальний формат',
  'UNIDENTIFIED': 'Невстановлений',
  'ESTIMATED, ASSUMED, SUSPECTED': 'Оцінюється, передбачається, підозрювано',
  'ORDER TO EXECUTE': 'Замовити для виконання',
  'PREPARE FOR EXECUTION': 'Підготуйтеся до виконання',
  'PLANNED': 'Запланований',
  'THE FIRST GRID REF DESCRIBES THE BEGINNING (ARROW HEAD OR POINT) AND THE SECOND GRID REF DESCRIBES THE END OF THE ARROW.': 'Перша сітка Ref описує початок (голова стрілки або точка), а друга сітка описує кінець стрілки.',
  'THE FIRST REF DESCRIBES THE CENTER OF THE ELLIPSE; THE SECOND GRID REF DESCRIBES THE END OF THE SEMI-MAJOR AXIS; AND THE THIRD REF DESCRIBES THE END OF THE SEMI-MINOR AXIS OF THE ELLIPSE.': 'Перший реф описує центр еліпса; Друга сітка Ref описує кінець напів-майора вісь; І третій реф описує кінець напівмінорної осі еліпса.',
  'SEQUENCE OF GRID REF DESCRIBES A LINE FROM REAR TO FRONT AND FROM LEFT TO RIGHT FACING THE FRONT.': 'Послідовність сітки Ref описує лінію ззаду на передню частину і зліва направо на передній частині.',
  'THE FIRST REF DESCRIBES THE LEFT FORWARD EXTREMITY OF THE SECTOR, THE SECOND REF DESCRIBES THE CENTRE FORWARD, AND THE THIRD REF DESCRIBES THE RIGHT FORWARD EXTREMITY OF THE SECTOR.': 'Перший реф описує ліву кінцівку сектору, другий реф описує центр вперед, а третій реф описує праворуч вперед кінцівки сектору.',
  'AIRBASE': 'Авіабаза',
  'ALTERNATE COMMAND POST': 'Альтернативний командний пост',
  'ARTILLERY RESERVED AREA': 'Артилерія зарезервована зона',
  'AREA': 'Область',
  'ASSEMBLY AREA': 'Зона складання',
  'ALTERNATE WAR HEADQUARTERS': 'Альтернативна війна',
  'UNIT OR FORMATION BOUNDARY': 'Межа одиниці або утворення',
  'BRIDGEHEAD': 'Плацдарм',
  'BRIDGE': 'Мост',
  'BYPASS': 'Обхід',
  'COMBAT SURVEILLANCE': 'Бойовий нагляд',
  'CHECK POINT': 'Точка перевірки',
  'CENTRE OF MASS': 'Центр Меси',
  'COMMUNICATIONS CENTRE': 'Центр комунікацій',
  'COMMUNICATIONS HEAD': 'Комунікація',
  'INTERNATIONAL COMMUNI- CATIONS INTERFACE': 'Міжнародний інтерфейс комунікацій',
  'COMMUNICATIONS ZONE': 'Зона комунікацій',
  'CONCENTRATION AREA': 'Зона концентрації',
  'PLACE OF CONTACT': 'Місце контакту',
  'COORDINATION POINT': 'Координаційна точка',
  'COMMAND POST': 'Командний пост',
  'DEFILE': 'Дефіл',
  'DROP OFF POINT': 'Займайте точку',
  'DUMMY HEADQUARTERS': 'Штаб -квартира',
  'DUMMY GUN POSITION': 'Положення манекена',
  'DUMMY MISSILE POSITION': 'Положення ракети',
  'DUMMY RADAR POSITION': 'Манекен Радар положення',
  'DROP ZONE': 'Зона краплі',
  'ENEMY FRONT LINE TRACE': 'Ворожий фронт -трас',
  'END OF CONVOY': 'Кінець конвой',
  'FORWARD COMBAT ZONE': 'Вперед бойова зона',
  'FIELD AID STATION': 'Польова допомога',
  'FORWARD EDGE OF BATTLE AREA': 'Передній край бойової зони',
  'FIRING POSITION': 'Позиція',
  'FORWARD LINE OF ENEMY TROOPS': 'Вперед лінія ворожих військ',
  'FORWARD LINE OF OWN TROOPS': 'Вперед лінії власних військ',
  'FIRE SUPPORT COORDINATION LINE': 'Координаційна лінія пожежної підтримки',
  'FIELD STORAGE SITE': 'Сайт зберігання поля',
  'FORWARD HEADQUARTERS': 'Штаб -квартира вперед',
  'GAP': 'Розрив',
  'HALT POINT': 'Зупинити',
  'HEAD OF CONVOY': 'Глава конвой',
  'HIDE': 'Сховати',
  'HEADQUARTERS': 'Штаб',
  'INTERMEDIATE OBJECTIVE': 'Проміжна мета',
  'JAMMING POSITION': 'Позиція заклинання',
  'KEY AREA': 'Ключова область',
  'LAAGER POSITION': 'Позиція Laager',
  'LEAPFROG ELEMENT': 'Елемент Leapfrog',
  'LOGISTIC COMMAND POST': 'Логістична командна публікація',
  'LANDING ZONE': 'Зона посадки',
  'MAIN COMMAND POST': 'Основна командна публікація',
  'MARSHALLING AREA': 'Зона Маршаллінгу',
  'MILITARY ROAD': 'Військова дорога',
  'MOBILE COMMAND POST': 'Мобільний командний пост',
  'MAIN SUPPLY ROUTE': 'Основний маршрут постачання',
  'MOBILE WAR HEADQUARTERS': 'Мобільна війна',
  'COMMUNICATIONS NODE': 'Вузол комунікацій',
  'NUCLEAR RESERVED AREA': 'Ядерна зарезервована зона',
  'OBJECTIVE': 'Об\'єктивний',
  'OBSERVATION POST': 'Спостереження',
  'OBSTACLE': 'Перешкода',
  'OPERATIONS AREA': 'Операційна зона',
  'PASSAGE POINT': 'Точка уривку',
  'PEACE HEADQUARTERS': 'Штаб миру',
  'PRIMARY WAR HEADQUARTERS': 'Основна війна',
  'RADAR POSITION': 'Радар позиції',
  'REAR COMBAT ZONE': 'Зона бойових дій',
  'REAR COMMAND POST': 'Задній командний пост',
  'RELAY POINT': 'РЕЛЕТАЦІЯ',
  'RELEASE POINT': 'Точка випуску',
  'RESERVE COMMAND POST': 'Резервне командне повідомлення',
  'RESTRICTED FIRE LINE': 'Обмежена пожежа лінія',
  'RECONNAISSANCE AND INTERDICTION PLANNING LINE': 'Лінія планування розвідки та заборони',
  'RIVER LINE': 'Річка',
  'START LINE': 'Початок лінії',
  'AREA TO BE SEARCHED.': 'Область, яку слід шукати.',
  'STAGING AREA': 'Постановка',
  'START POINT': 'Початкова точка',
  'STEP UP HEADQUARTERS': 'Активізуйте штаб -квартиру',
  'STATIC WAR HEADQUARTERS': 'Статична війна',
  'TACTICAL COMMAND POST': 'Тактична командна публікація',
  'TACTICAL ASSEMBLY AREA': 'Тактична зона складання',
  'SUPPLY ISSUE POINT TERRITIORIAL ARMY': 'Проблема з питань постачання Терирітська армія',
  'VEHICLE PARKING SPACE': 'Місце для паркування транспортних засобів',
  'CROSSING SITE': 'Місце перехрестя',
  'AN AIRBORNE TANKER AND ANOTHER AIRCRAFT CONDUCTING REFUELING OPERATIONS.': 'Повітряний танкер та інший літальний апарат, що проводить операції з заправки.',
  'THE AERIAL MANEUVERS USED TO EFFECTIVELY EMPLOY FIGHTER AIRCRAFT AGAINST HOSTILE OR SIMULATED HOSTILE AIRCRAFT.': 'Повітряні маневри, що використовуються для ефективного використання винищувальних літаків проти ворожих або імітованих ворожих літаків.',
  'THE PHASE OF A MISSION WHICH INVOLVES THE RETURN TO A BASE.': 'Фаза місії, яка передбачає повернення до бази.',
  'ADVANCE': 'Просуватися',
  'A TERM APPLIED TO AN AIRCRAFT FROM THE INSTANT IT BECOMES ENTIRELY SUSTAINED BY AIR.': 'Термін, що застосовується до літака з миттєвого, він повністю підтримується повітрям.',
  'TO ISOLATE OR SEAL OFF AN AREA BY ANY MEANS, I.E., TO DENY USE OF ROUTE OR APPROACH.': 'Виділення або ущільнення зони будь -якими способами, тобто заперечувати використання маршруту або підходу.',
  'AIR MOVEMENT': 'Повітряний рух',
  'AN OPERATION IN WHICH SPECIFICALLY EQUIPPED AIRCRAFT COLLECT SAMPLES OF AIR IN THE ATMOSPHERE.': 'Операція, в якій спеціально обладнані літаки збирають зразки повітря в атмосфері.',
  'AMPHIBIOUS OPS': 'Амфібійні операції',
  'THE ARRIVAL OR LANDING OF AN AIRCRAFT.': 'Прибуття або посадка літака.',
  'OPERATIONS CONDUCTED WITH THE INTENTION OF DENYING THE ENEMY THE EFFECTIVE USE OF HIS SUBMARINES.': 'Операції, проведені з наміром заперечувати ворогу в ефективному використанні його підводних човнів.',
  'AIR WARNING/AIR CONTROL': 'Попередження повітря/управління повітрям',
  'ONE OR MORE DIVISIONS OR ELEMENTS OF FIGHTER AIRCRAFT EMPLOYED BETWEEN A FORCE AND AN OBJECTIVE AREA AS A BARRIER ACROSS THE PROBABLE DIRECTION OF ENEMY ATTACK.': 'Один або кілька підрозділів або елементів винищувальних літаків, що застосовуються між силою та об\'єктивною областю як бар\'єр через ймовірний напрямок нападу противника.',
  'BARRIER CLEARING': 'Бар\'єрне очищення',
  'BARRIER PREPARATION': 'Підготовка бар\'єру',
  'BLOCK': 'Блок',
  'A TERM FOR AIR OPERATIONS WHEN USING AIR TO GROUND ORDNANCE TO DESTROY OR NEUTRALIZE ENEMY FORCES OR INSTALLATIONS.': 'Термін для повітряних операцій при використанні повітряного обряду для руйнування або нейтралізації сил або установок противника.',
  'AN AIRCRAFT PATROL PROVIDED OVER AN OBJECTIVE AREA, OVER THE FORCE PROTECTED, OVER THE CRITICAL AREA OF A COMBAT ZONE, OR OVER AN AIR DEFENSE AREA, FOR THE PURPOSE OF INTERCEPTING AND DESTROYING HOSTILE AIRCRAFT BEFORE THEY REACH THEIR TARGET.': 'Патруль літака, наданий об\'єктивною областю, над захищеною силою, над критичною зоною бойової зони або над зоною ППО, з метою перехоплення та знищення ворожих літальних апаратів, перш ніж вони досягнуть своєї цілі.',
  'AIR MOVEMENT OF MILITARY SUPPLIES AND EQUIPMENT.': 'Повітряний рух військових запасів та обладнання.',
  'AIR ATTACKS AGAINST HOSTILE TARGETS WHICH ARE IN CLOSE PROXIMITY TO FRIENDLY FORCES AND WHICH REQUIRE DETAILED INTEGRATION OF EACH AIR MISSION WITH THE FIRE AND MOVEMENT OF THESE FORCES.': 'Повітряні атаки на ворожі цілі, які знаходяться в безпосередній близькості від доброзичливих сил і які потребують детальної інтеграції кожної повітряної місії з вогнем та рухом цих сил.',
  'COUNTER BATTERY': 'Лічильник акумулятора',
  'CLOSING': 'Закриття',
  'CONSTRUCTING': 'Конструкція',
  'COUNTERATTACK': 'Контратака',
  'A TERM FOR AIR OPERATIONS CONDUCTED TO ATTAIN AND MAINTAIN A DESIRED DEGREE OF AIR SUPERIORITY BY THE DESTRUCTION OR NEUTRIZATION OF ENEMY FORCES, CONDUCTED NEAR TO OR OVER FRIENDLY TERRITORY AND GENERALLY REACTIVE TO THE INITIATIVE OF THE ENEMY AIR FORCES.': 'Термін для повітряних операцій, проведений для досягнення та підтримки бажаної ступеня переваги повітря шляхом руйнування або нейтронізації сил противника, проведеного поблизу дружньої території або, як правило, реагує на ініціативу повітряних сил противника.',
  'DECEPTION': 'Обман',
  'DEFENCE': 'Захист',
  'AN AIR SUPPRESSION MISSION, E.G., WILD WEASEL MISSION.': 'Місія з придушення повітря, наприклад, місія диких мереж.',
  'DELAY': 'Затримка',
  'DEPLOY': 'Розгортання',
  'THE RELOCATION OF AIRCRAFT TO DESIRED AREAS OF OPERATION.': 'Переїзд літака до бажаних ділянок експлуатації.',
  'DESTROYING': 'Знищення',
  'DIRECT FIRE': 'Прямий вогонь',
  'DIVERSION': 'Диверсія',
  'DIRECT SUPPORT REINFORCING': 'Пряма підтримка посилення',
  'DUMMY': 'Манекен',
  'DUMPING': 'Скидання',
  'IN AIR OPERATIONS, AIRCRAFT ASSIGNED TO PROTECT OTHER AIRCRAFT DURING A MISSION.': 'У повітряних операціях літаки, призначені для захисту інших літальних апаратів під час місії.',
  'ESTABLISHING': 'Встановлення',
  'MILITARY ACTION INVOLVING THE USE OF ELECTROMAGNETIC ENERGY TO DETERMINE, EXPLOIT, REDUCE OR PREVENT HOSTILE USE OF THE ELECTROMAGNETIC SPECTRUM AND ACTION WHICH RETAINS FRIENDLY USE OF THE ELECTROMAGNETIC SPECTRUM.': 'Військові дії, що включають використання електромагнітної енергії для визначення, використання, зменшення або запобігання ворожих використання електромагнітного спектру та дії, що зберігає дружнє використання електромагнітного спектру.',
  'FIELD FORTIFICATION': 'Польове укріплення',
  'THAT STATUS IN WHICH AIRCRAFT ON THE GROUND/DECK ARE FULLY SERVICED AND ARMED WITH COMBAT CREWS IN READINESS TO TAKE OFF WITHIN A SPECIFIED SHORT PERIOD OF TIME (USUALLY 15 MINUTES) AFTER RECEIPT OF A MISSION ORDER.': 'Той статус, в якому літальні апарати на землі/палубі повністю обслуговуються та озброєні бойовими екіпажами в готовності зняти протягом визначеного короткого періоду часу (як правило, 15 хвилин) після отримання замовлення на місію.',
  'HOLD': 'Утримувати',
  'INTERCEPTION': 'Перехоплення',
  'JAMMING': 'Заклинання',
  'LOCATE': 'Знаходити',
  "LINE OF COMMUNICATION'S MAINTENANCE": 'Лінія обслуговування комунікації',
  'THE PROCESS OF DETECTION AND/OR REMOVING MINES BY MANUAL OR MECHANICAL MEANS.': 'Процес виявлення та/або видалення мін за допомогою ручних або механічних засобів.',
  'MINE CLEARING': 'Швидка очищення',
  'MINE LAYING': 'Шахта',
  'THE PROCESS OF PLACING MINES IN A MINEFIELD.': 'Процес розміщення шахт на мінному полі.',
  'THE TECHNIQUE OF SEARCHING FOR, OR CLEARING MINES USING MECHANICAL OR EXPLOSION GEAR, WHICH PHYSICALLY REMOVES OR DESTROYS THE MINE.': 'Техніка пошуку або очищення мін за допомогою механічної або вибухової передачі, яка фізично видаляє або руйнує шахту.',
  'MOPUP': 'Шнур',
  'MOVE': 'Рухатися',
  'MOVING': 'Що рухається',
  'NO ACTIVITY': 'Немає діяльності',
  'A TERM FOR AIR OPERATIONS CONDUCTED TO ATTAIN AND MAINTAIN A DESIRED DEGREE OF AIR SUPERIORITY BY THE DESTRUCTION OR NEUTRALIZATION OF ENEMY FORCES, CONDUCTED THROUGHOUT ENEMY TERRITORY ANY GENERALLY CONDUCTED AT THE INITIATIVE OF FRIENDLY FORCES.': 'Термін для повітряних операцій, проведений для досягнення та підтримки бажаного ступеня переваги повітря шляхом руйнування або нейтралізації сил противника, проведеного по всій території противника, будь -якій загальній проведеній на ініціативі дружніх сил.',
  'IN AIR OPERATIONS, TO REMOVE CARGO AND/OR EQUIPMENT FROM AN AIRCRAFT.': 'У повітряних операціях для видалення вантажних та/або обладнання з літака.',
  'IN AIR OPERATIONS, TO PLACE CARGO AND/OR EQUIPMENT IN AN AIRCRAFT.': 'У повітряних операціях розміщувати вантажні та/або обладнання в літаку.',
  'OPERATING': 'Експлуатаційний',
  'IN AIR INTERCEPT, MEANS CIRCLING OR CIRCLE AND SEARCH.': 'У перехопленні повітря означає кружлення або коло та пошук.',
  'DEFINE OTHER IN FOLLOWING AMPN SET.': 'Визначте інші в наступному наборі AMPN.',
  'AERIAL PHOTOGRAPHY FOR USE IN INTELLIGENCE.': 'Повітряна фотографія для використання в інтелекті.',
  'PURSUE': 'Переслідувати',
  'RECOVERY': 'Відновлення',
  'REDEPLOY': 'Переробляти',
  'REFILLING': 'Заправка',
  'REFUELING': 'Заправка',
  'REGROUP': 'Перегрупувати',
  'RELIEF': 'Полегшення',
  'REPAIRING': 'Ремонт',
  'RESERVE': 'Резервувати',
  'REST': 'Відпочинок',
  'RESUPPLYING': 'Поповнення',
  'RIVER CROSSING': 'Річкове переїзд',
  'THE USE OF AIRCRAFT, SURFACE CRAFT, SUBMARINE, SPECIALIZED RESCUE TEAMS AND EQUIPMENT TO SEARCH FOR AND RESCUE PERSONNEL IN DISTRESS ON LAND OR AT SEA.': 'Використання літаків, поверхневого судна, підводних човнів, спеціалізованих рятувальних груп та обладнання для пошуку та порятунку персоналу в біді на суші або в морі.',
  'SCREEN': 'Екран',
  'REPETITIVE, FREQUENT MOVEMENTS OF PERSONNEL BETWEEN TWO OR MORE LOCATIONS.': 'Повторні, часті рухи персоналу між двома або більше місць.',
  'SECONDARY OR SUPPORTING OPERATIONS WHICH MAY BE ADJUNCTS TO VARIOUS OTHER OPERATIONS AND FOR WHICH NO ONE SERVICE IS ASSIGNED PRIMARY RESPONSIBILITY.': 'Вторинні або допоміжні операції, які можуть бути доповнені до різних інших операцій, і для яких жодна служба не покладається на основну відповідальність.',
  'STATIC': 'Статичний',
  'THE TAKEOFF OR DEPARTURE OF AN AIRCRAFT.': 'Зліт або від\'їзд літака.',
  'THE USE OF TRANSPORT AIRCRAFT IN DIRECT SUPPORT OF AIRBORNE ASSAULTS, TACTICAL AIR SUPPLY, EVACUATION OF CASUALTIES FROM FORWARD AREAS, AND CLANDESTINE OPERATIONS.': 'Використання транспортних літальних апаратів у безпосередній підтримці повітряних нападів, тактичного подачі повітря, евакуації жертв з передніх районів та підпільних операцій.',
  'THE SYSTEMATIC AND CONTINUING INVESTIGATION OF AN AREA OR ALONG A LINE TO DETECT OR HAMPER SUBMARINES.': 'Систематичне та постійне дослідження області або по лінії для виявлення або перешкод підводних човнів.',
  'THE DETECTION, IDENTIFICATION AND LOCATION OF A TARGET.': 'Виявлення, ідентифікація та розташування цілі.',
  'THE MOVEMENT OF NON-SPECIFIC PERSONNEL AND/OR CARGO.': 'Рух неспецифічного персоналу та/або вантажу.',
  'TRANSPORTING': 'Транспортування',
  'WITHDRAWAL': 'Вилучення',
  'THE COLLECTION OF METEOROLOGICAL INFORMATION.': 'Збір метеорологічної інформації.',
  'MAIN': 'Головний',
  'SUPPORTING': 'Підтримка',
  'NORTH': 'Північ',
  'NORTHEAST': 'Північний схід',
  'EAST': 'Схід',
  'SOUTHEAST': 'Південний схід',
  'SOUTH': 'Південь',
  'SOUTHWEST': 'НА ПІВДЕННИЙ ЗАХІД',
  'WEST': 'Захід',
  'NORTHWEST': 'ПІВНІЧНИЙ ЗАХІД',
  'COMPANY LEVEL': 'Рівень компанії',
  'BATTALION LEVEL': 'Рівень батальйону',
  'REGIMENT LEVEL': 'Рівень полку',
  'NOT APPLICABLE': 'Не застосовується',
  'SQUAD LEVEL': 'Рівень загін',
  'SECTION LEVEL': 'Рівень розділу',
  'PLATOON LEVEL': 'Рівень взводу',
  'BRIGADE LEVEL': 'Рівень бригади',
  'DIVISION LEVEL': 'Рівень поділу',
  'CORPS LEVEL': 'Рівень корпусу',
  'NUMBERED ARMY LEVEL': 'Пронумерований армійський рівень',
  'MILITARY DISTRICT (FRONT) OR ARMY GROUP': 'Військовий округ (фронт) або армійська група',
  'THEATRE ARMY LEVEL': 'Рівень театральної армії',
  'LINE OF DEPARTURE': 'Лінія відправлення',
  'PLANNING LINE': 'Лінія планування',
  'PICKUP POINT': 'Pickup Point',
  'THE POINT ESTABLISHED AS A SURVIVOR PICKUP POINT.': 'Точка, встановлена ​​як пункт пікапа, що вижив.',
  'THE POINT ESTABLISHED AS A RENDEZVOUS.': 'Точка, встановлена ​​як рандеву.',
  'REPORT LINE': 'Лінія звітів',
  'MINUS DISPLACEMENT QUALIFIER': 'Кваліфікатор мінус переміщення',
  'PLUS DISPLACEMENT QUALIFIER': 'Плюс кваліфікатор переміщення',
  'AFTER': 'Після',
  'AND': 'І',
  'AS SOON AS POSSIBLE AFTER': 'Якнайшвидше після',
  'AS SOON AS POSSIBLE NOT LATER THAN': 'Якнайшвидше не пізніше',
  'AS OF': 'ЩОДО',
  'AT': 'В',
  'BEFORE': 'Перед',
  'BETWEEN': 'Між',
  'CLEARED': 'Очищений',
  'DUE': 'Належить',
  'END': 'Кінець',
  'FROM': 'З',
  'NOT EARLIER THAN': 'Не раніше',
  'NOT LATER THAN': 'Не пізніше',
  'OFF': 'Не вистачати',
  'ON': 'На',
  'ON CALL': 'ЗА ВИКЛИКОМ',
  'SINCE': 'З тих пір',
  'START': 'Почати',
  'TO': 'До',
  'TIME OF DETONATION': 'Час детонації',
  'TIME OF DETONATION NOT EARLIER THAN': 'Час детонації не раніше, ніж',
  'TIME OF DETONATION NOT LATER THAN': 'Час детонації не пізніше',
  'TIME ON TARGET NOT EARLIER THAN': 'Час на ціль не раніше, ніж',
  'TIME ON TARGET NOT LATER THAN': 'Час на ціль не пізніше',
  'UNTIL': 'До',
  'WITH EFFECT FROM': 'З ефектом від',
  'D-TIME (DAY)': 'D-час (день)',
  'E-TIME (DAY)': 'E-Time (день)',
  'F-TIME (DAY)': 'F-TIME (день)',
  'THE SPECIFIC HOUR ON D-DAY AT WHICH A PARTICULAR OPERATION COMMENCES OR IS ABOUT TO COMMENCE.': 'Конкретна година D-Day, в якій розпочинається певна операція або збирається розпочати.',
  'J-TIME (DAY)': 'J-Time (день)',
  'K-TIME (DAY)': 'K-TIME (день)',
  'L-TIME (DAY)': 'Лайт (день)',
  'M-TIME (DAY)': 'M-TIME (день)',
  'R-TIME': 'R-час',
  'S-TIME (DAY)': 'S-час (день)',
  'TIME BLOCK/SLOT 1': 'Блок часу/слот 1',
  'TIME BLOCK/SLOT 2': 'Блок часу/слот 2',
  'TIME BLOCK/SLOT 3': 'Блок часу/слот 3',
  'TIME BLOCK/SLOT 4': 'Блок часу/слот 4',
  'TIME BLOCK/SLOT 5': 'Блок часу/слот 5',
  'TIME BLOCK/SLOT 6': 'Блок часу/слот 6',
  'TIME BLOCK/SLOT 7': 'Блок часу/слот 7',
  'TIME BLOCK/SLOT 8': 'Блок часу/слот 8',
  'TIME BLOCK/SLOT 9': 'Блок часу/слот 9',
  'V-TIME (DAY)': 'V-час (день)',
  'W-TIME (DAY)': 'W-час (день)',
  'Y-TIME (DAY)': 'Y-time (день)',
  'PLUS': 'Плюс',
  'MINUS': 'Мінус',
  'ARRIVAL': 'Прибуття',
  'DEPARTURE': 'Відхід',
  'TOT TOO EARLY OR TOO LATE': 'Tot занадто рано або занадто пізно',
  'WEAPON NOT AVAILABLE': 'Зброя недоступна',
  'GRADIENT': 'Градієнт',
  'HORIZONTAL CURVE': 'Горизонтальна крива',
  'HEIGHT': 'Висота',
  'WIDTH': 'Ширина',
  'PERCENTAGE': 'ВІДСОТОК',
  'SAND': 'Пісок',
  'POTHOLES/RUTTING': 'Вибоїни/руйнування',
  'LANDSLIDE': 'Зсув',
  'SNOW/ICE': 'Сніг/лід',
  'FLOODING': 'Затоплення',
  'NO OR SOFT SHOULDER': 'Ні, ні м\'яке плече',
  'WASH OUT': 'Вимивати',
  'HUMP BACK BRIDGE': 'Горб назад',
  'MILITARY LOAD CLASSIFICATION (WEIGHT)': 'Класифікація військової навантаження (вага)',
  'OVERHEAD RESTRICTION': 'Накладні обмеження',
  'SHARP CURVE': 'Різка крива',
  'STEEP GRADIENT': 'Крутий градієнт',
  'WIDTH RESTRICTION': 'Обмеження ширини',
  'ADVANCED': 'Просунутий',
  'ALTERNATIVE': 'Альтернативний',
  'FORWARD': 'Вперед',
  'SUPPORT': 'Підтримка',
  'MOBILE': 'Мобільний',
  'PEACETIME': 'Мирний час',
  'REAR': 'Задній',
  'TACTICAL': 'Тактичний',
  'COORDINATION': 'Координація',
  'PROTECTION': 'Захист',
  'DELIBERATE BOMBING': 'Навмисні бомбардування',
  'POSTAL BOMBING': 'Поштове бомбардування',
  'PROXY BOMBING': 'Проксі -бомбардування',
  'AMBUSH SHOOTING': 'Зйомка в засідці',
  'ASSASSIN SHOOTING': 'Стрілянина вбивці',
  'RANDOM SHOOTING': 'Випадкова стрілянина',
  'SNIPER SHOOTING': 'Снайперська стрілянина',
  'HIJACK': 'Викрадати',
  'HOSTAGE TAKING': 'Заручник',
  'INTIMIDATION': 'Залякування',
  'MURDER': 'Вбивство',
  'CIVIL DISOBEDIENCE': 'Громадянська непокора',
  'ESTABLISHING NO-GO AREAS': 'Встановлення районів без руху',
  'PICKETING': 'Пікетування',
  'RIOT': 'Бунт',
  'STRIKE': 'Вдарити',
  'DISEASE': 'ЗАХВОРЮВАННЯ',
  'DROUGHT': 'Посуха',
  'FAMINE': 'Голод',
  'FLOOD': 'Затоплення',
  'PESTILENCE': 'Морозиво',
  'INTERNATIONAL MEDIA ATTENTION': 'Міжнародна ЗМІ',
  'LOCAL MEDIA ATTENTION': 'Увага місцевих ЗМІ',
  'NATIONAL MEDIA ATTENTION': 'Національна увага ЗМІ',
  'GOVERNMENT AGENCY': 'Урядове агентство',
  'HUMANITARIAN RELIEF ORGANIZATION': 'Гуманітарна організація допомоги',
  'INFORMER': 'Інформатор',
  'MEDIA': 'ЗМІ',
  'UNITED NATIONS': 'ОБ\'ЄДНАНІ НАЦІЇ',
  'COMPLETELY RELIABLE': 'Повністю надійний',
  'USUALLY RELIABLE': 'Зазвичай надійний',
  'NOT USUALLY RELIABLE': 'Зазвичай не надійний',
  'UNRELIABLE': 'Ненадійний',
  'RELIABILITY CANNOT BE JUDGED': 'Надійність не може бути оцінена',
  'COMPLETE. ENTIRELY ACCEPTED. IT CAN BE STATED WITH CERTAINTY THAT THE SUBJECT MATTER BEING EVALUATED HAS BEEN PREVIOUSLY REPORTED BY TWO COMPLETELY RELIABLE SOURCES OR SUPPORTED BY TWO GOOD QUALITY PHOTOS.': 'Завершити. Цілком прийнятий. З впевненістю можна зазначити, що про оцінювану тему раніше повідомлялося два повністю надійні джерела або підтримується двома фотографіями хорошої якості.',
  'NO PROOF THAT CONFIRMATION CAN BE ESTABLISHED, BUT SUBJECT MATTER IS BELIEVED TO HAVE BEEN OBTAINED FROM ANOTHER SOURCE. IF CONTENTS OF A REPORT ARE CONFIRMED IN ESSENTIAL PARTS BY ALREADY AVAILABLE INFORMATION FROM ANOTHER SOURCE, THE REPORT MAY BE JUDGED AS PROBABLY TRUE. INFORMATION IS BASED ON GOOD QUALITY PHOTO COVERAGE.': 'Немає доказів того, що підтвердження не може бути встановлено, але, як вважають, тема отримана з іншого джерела. Якщо вміст звіту підтверджується в основних частинах, вже наявною інформацією з іншого джерела, звіт може бути визнаний, ймовірно, правдивим. Інформація базується на якісному висвітленні фотографій.',
  'INVESTIGATION REVEALS THAT SUBJECT MATTER ON WHICH NO OTHER INFORMATION IS AVAILABLE COMPLIES WITH THE BEHAVIOR OF THE TARGET AS OBSERVED UP TO NOW OR THE KNOWN BACKGROUND OF A PERSON LEADS TO THE CONCLUSION THAT HE MIGHT HAVE BEHAVED AS REPORTED.': 'Дослідження показує, що тема, на якій не доступна інша інформація, відповідає поведінці цілі, як це спостерігається до цього часу, або відомий фон людини призводить до висновку, що він, можливо, поводиться так, як повідомляється.',
  'SUBJECT MATTER CONTRADICTS EXISTING INFORMATION ON THE SUBJECT WHICH HAS BEEN PREVIOUSLY RATED AS PROBABLE, BUT DATA CANNOT BE DISPROVED BY FACTS.': 'Тема суперечить існуючій інформації на цю тему, яка раніше була оцінена як ймовірна, але дані не можуть бути спростовані фактами.',
  'SUBJECT MATTER THAT CONTRADICTS EXISTING INFORMATION ON THAT SUBJECT WHICH HAS PREVIOUSLY BEEN RATED AS CONFIRMED.': 'Тема, яка суперечить існуючій інформації з цього предмету, яка раніше була оцінена як підтверджена.',
  'INVESTIGATION OF SUBJECT MATTER REVEALS NO BASIS FOR ALLOCATING ANY OF THE RATINGS CONFIRMED THROUGH IMPROBABLE. THIS RATING MUST BE USED RATHER THAN AN INACCURATE USE OF THE OTHER RATINGS. IT SHOULD BE USED WHEN NO INFORMATION IS AVAILABLE FOR COMPARISON.': 'Дослідження предмета не виявляє підстав для розподілу будь -якого з рейтингів, підтверджених через неймовірну. Цей рейтинг повинен бути використаний, а не неточне використання інших рейтингів. Він повинен використовуватися, коли для порівняння немає інформації.',
  'NORTH NORTHEAST': 'На північний схід',
  'EAST NORTHEAST': 'Схід на північний схід',
  'EAST SOUTHEAST': 'Схід на південному сході',
  'SOUTH SOUTHEAST': 'Південний Схід',
  'SOUTH SOUTHWEST': 'Південний південний захід',
  'WEST SOUTHWEST': 'Західний південний захід',
  'WEST NORTHWEST': 'Західний північний захід',
  'NORTH NORTHWEST': 'На північний захід',
  'BICYCLE': 'Велосипед',
  'FOOT': 'Стоп',
  'TRAM/CABLE CAR': 'Трамвай/канатна дорога',
  'MINOR': 'Неповнолітній',
  'SEVERE': 'СИЛЬНИЙ',
  'TOTALLY DISRUPTED': 'Повністю порушений',
  'CIVILIAN': 'Цивільний',
  'INSTIGATOR': 'Підбурювач',
  'OWN FORCE': 'Власна сила',
  'FEMALE PERSONNEL': 'Жіночий персонал',
  'MALE PERSONNEL': 'Чоловічий персонал',
  'NATIONAL TITLE': 'Національний титул',
  'GENERAL OFFICER 9': 'Генеральний офіцер 9',
  'GENERAL OFFICER 8': 'Генеральний офіцер 8',
  'GENERAL OFFICER 7': 'Генеральний офіцер 7',
  'GENERAL OFFICER 6': 'Генеральний офіцер 6',
  'SENIOR OR FIELD GRADE OFFICER 5': 'Старший або офіцер польових класів 5',
  'SENIOR OR FIELD GRADE OFFICER 4': 'Старший або офіцер польових класів 4',
  'SENIOR OR FIELD GRADE OFFICER 3': 'Старший або офіцер польового класу 3',
  'JUNIOR OFFICER 2': 'Молодший офіцер 2',
  'JUNIOR OFFICER 1': 'Молодший офіцер 1',
  'CHIEF WARRANT OFFICER 4': 'Головний прапорщик 4',
  'CHIEF WARRANT OFFICER 3': 'Головний прапорщик 3',
  'CHIEF WARRANT OFFICER 2': 'Головний прапорщик 2',
  'WARRANT OFFICER': 'Прапорщик',
  'OTHER RANK 9': 'Інший ранг 9',
  'OTHER RANK 8': 'Інший ранг 8',
  'OTHER RANK 7': 'Інший ранг 7',
  'OTHER RANK 6': 'Інший ранг 6',
  'OTHER RANK 5': 'Інший ранг 5',
  'OTHER RANK 4': 'Інший ранг 4',
  'OTHER RANK 3': 'Інший ранг 3',
  'OTHER RANK 2': 'Інший ранг 2',
  'OTHER RANK 1': 'Інший ранг 1',
  'A': 'А',
  'B': 'Б',
  'C': 'C',
  'D': 'Р.',
  'E': 'Е',
  'F': 'F',
  'G': 'G',
  'H': 'H',
  'I': 'Я',
  'J': 'J',
  'K': 'K',
  'L': 'Л',
  'M': 'М',
  'N': 'П.',
  'O': 'О',
  'P': 'С',
  'Q': 'Q',
  'R': 'R',
  'S': 'S',
  'T': 'Т',
  'U': 'U',
  'V': 'V',
  'W': 'Ш',
  'X': 'X',
  'Y': 'У',
  'Z': 'Z',
  'BARBUDA': 'Барбуда',
  'SAINT GEORGE': 'Святий',
  'SAINT JOHN': 'Святий Іоанн',
  'SAINT MARY': 'Свята Марія',
  'SAINT PAUL': 'Святий Павло',
  'SAINT PETER': 'Святий Петро',
  'SAINT PHILIP': 'Сент -Філіп',
  'BADAKHSHAN': 'Бадахшан',
  'BADGHIS': 'Бадгій',
  'BAGHLAN': 'Баглан',
  'BALKH': 'Бальт',
  'BAMIAN': 'Бамійський',
  'FARAH': 'Фара',
  'FARYAB': 'Фаріаб',
  'GHAZNI': 'Газні',
  'GHOWR': 'Гудр',
  'HELMAND': 'Гельманд',
  'HERAT': 'Герат',
  'JOWZJAN': 'Джежжан',
  'KABOL': 'Кабол',
  'KAPISA': 'Капіса',
  'KONARHA': 'Конарха',
  'LAGHMAN': 'Лагман',
  'LOWGAR': 'Лоугар',
  'NANGARHAR': 'Нангархар',
  'NIMRUZ': 'Німруз',
  'ORUZGAN': 'Орузган',
  'PAKTIA': 'Пактія',
  'PARVAN': 'Паран',
  'QANDAHAR': 'QANDAHAR',
  'KONDUZ': 'Контуз',
  'SAMANGAN': 'Саманган',
  'TAKHAR': 'Тахар',
  'VARDAK': 'Вардак',
  'ZABOL': 'Забол',
  'PAKTIKA': 'Пактика',
  'ALGER': 'Аплікація',
  'ANNABA': 'Аннаба',
  'BATNA': 'Батна',
  'CONSTANTINE': 'Костянтин',
  'EL ASNAM': 'Ель -Аснам',
  'MEDEA': 'Медіа',
  'MOSTAGANEM': 'Мультаганем',
  'ORAN': 'Оран',
  'SAIDA': 'Саяда',
  'SETIF': 'Сетф',
  'TIARET': 'Тіарет',
  'TIZI OUZOU': 'Тізі Узу',
  'TLEMCEN': 'Тленкен',
  'ADRAR': 'Адрар',
  'BECHAR': 'Бехар',
  'BEJAIA': 'Беяя',
  'BISKRA': 'Біскра',
  'BLIDA': 'Бліда',
  'BOUIRA': 'Буйра',
  'DJELFA': 'Джельфа',
  'GUELMA': 'Гельма',
  'JIJEL': 'Джижель',
  'LAGHOUAT': 'Лагуат',
  'MASCARA': 'Туш',
  "M'SILA": 'М\'Сіла',
  'OUARGLA': 'Ouargla',
  'OUM EL BOUAGHI': 'Oum El Bouaghi',
  'SIDI BEL ABBES': 'Sidi Bel Abbes',
  'SKIKDA': 'Скікда',
  'TAMANRASSET': 'Таманрассет',
  'TEBESSA': 'Тебесса',
  'BERAT': 'Берат',
  'DIBRE': 'Дібр',
  'DURRES': 'Дюрес',
  'ELBASAN': 'Ельбасан',
  'FIER': 'Жорсткий',
  'GJIROKASTER': 'Gjirokaster',
  'GRAMSH': 'Грамш',
  'KOLONJE': 'Колондже',
  'KORCE': 'Котедж',
  'KRUJE': 'Кран',
  'KUKES': 'Кукес',
  'LEZHE': 'Леже',
  'LIBRAZHD': 'Лібрій',
  'LUSHNJE': 'Люшне',
  'MAT': 'Килимок',
  'MIRDITE': 'Міртит',
  'PERMET': 'Пермет',
  'POGRADEC': 'Поградек',
  'PUKE': 'Плутанина',
  'SARANDE': 'Саранде',
  'SHKODER': 'Шкодер',
  'SKRAPAR': 'Скрапар',
  'TEPELENE': 'Тепелен',
  'TIRANE': 'Тиран',
  'TROPOJE': 'Тропоє',
  'VLORE': 'Влор',
  'CANILLO': 'Канілло',
  'ENCAMP': 'Табір',
  'LA MASSANA': 'La Massana',
  'ORDINO': 'Орден',
  'SAN JULIA DE LORIA': 'Сан -Джулія де Лорія',
  'BENGUELA': 'Бенгела',
  'BIE': 'Біле',
  'CABINDA': 'Кабінда',
  'CUANDO CUBANGO': 'Куандо Кубанго',
  'CUANZA NORTE': 'Куанца Норте',
  'CUANZA SUL': 'Куанца Сул',
  'CUNENE': 'Кунен',
  'HUAMBO': 'Хуамбо',
  'HUILA': 'Хуйла',
  'LUANDA': 'Луанда',
  'MALANJE': 'Маланьє',
  'MOCAMEDES': 'Мокамед',
  'MOXICO': 'Моксико',
  'UIGE': 'Уйєг',
  'ZAIRE': 'Зер',
  'LUNDA NORTE': 'Лунда Норте',
  'LUNDA SUL': 'Лунда Сул',
  'BENGO': 'Бенго',
  'BUENOS AIRES': 'БУЕНОС-АЙРЕС',
  'CATAMARCA': 'Катамарка',
  'CHUBUT': 'Чубут',
  'CORDOBA': 'Кордона',
  'CORRIENTES': 'Коррієнтес',
  'DISTRITO FEDERAL': 'Дистритуто федеральний',
  'ENTRE RIOS': 'Entre Rios',
  'FORMOSA': 'Форма',
  'JUJUY': 'Джуджуй',
  'LA PAMPA': 'Ла -Пампа',
  'LA RIOJA': 'La Rioja',
  'MENDOZA': 'Мендоса',
  'MISIONES': 'Місіони',
  'NEUQUEN': 'Неуакен',
  'RIO NEGRO': 'Ріо Негр',
  'SALTA': 'Сальта',
  'SAN JUAN': 'Сан -Хуан',
  'SAN LUIS': 'Сан -Луїс',
  'SANTA CRUZ': 'Санта -Крус',
  'SANTA FE': 'Санта -Фе',
  'SANTIAGO DEL ESTERO': 'Сантьяго дель Естеро',
  'TIERRA DEL FUEGO, ANTARTIDA E ISLAS DEL ATLANTICO SUR': 'Tierra del Fuego, Antartida E Islas del Atlantico Sur',
  'TUCUMAN': 'Тулюман',
  'TEST': 'Тест',
  'CANTAL': 'Кантальський',
  'CHARENTE': 'Чарента',
  'CHARENTE-MARITIME': 'Шаренте-Мартіме',
  'CHER': 'Шер',
  'CORREZE': 'Корри',
  "COTE-D'OR": 'Cote-d\'or',
  'COTES-DU-NORD': 'Котс-Дуорд',
  'CREUSE': 'Кревза',
  'DEUX-SEVRES': 'Deux-sevres',
  'DORDOGNE': 'Дордогне',
  'DOUBS': 'Дубс',
  'EURE': 'Оселя',
  'EURE-ET-LOIR': 'Eure-et-loir',
  'FINISTERE': 'Фіністер',
  'GARD': 'Гард',
  'GERS': 'Герб',
  'GIRONDE': 'Жирна',
  'HAUTE-GARONNE': 'Високопоставлений',
  'HAUTE-LOIRE': 'Високопоставлений',
  'HAUTE-MARNE': 'Хай-Марн',
  'HAUTES-ALPES': 'Hautes-Alpes',
  'HAUTE-SAONE': 'Високий',
  'HAUTE-SAVOIE': 'Haute-Savoie',
  'HAUTES-PYRENEES': 'Пішолії',
  'HAUTE-VIENNE': 'Haute-vienne',
  'HAUT-RHIN': 'Хаут-Рін',
  'HERAULT': 'Геранг',
  'ILLE-ET-VILAINE': 'Іл-Ет-Вілайн',
  'INDRE': 'Індре',
  'INDRE-ET-LOIRE': 'Індре-Ет-Луара',
  'ISERE': 'Isere',
  'JURA': 'Юра',
  'LANDES': 'Земля',
  'LOIRE': 'Лебідувати',
  'LOIRE-ATLANTIQUE': 'Луара-Атлантика',
  'LOIRET': 'Лорет',
  'LOIR-ET-CHER': 'Loir-et-cher',
  'LOT': 'Лот',
  'LOT-ET-GARONNE': 'Лот-Е-Гаронна',
  'LOZERE': 'Лози',
  'MAINE-ET-LOIRE': 'Мен-Ет-Луара',
  'MANCHE': 'Манчевий',
  'MARNE': 'Марин',
  'MAYENNE': 'Майєнна',
  'MEURTHE-ET-MOSELLE': 'Meurthe-et-moselle',
  'MEUSE': 'Мізер',
  'MORBIHAN': 'Морбіхан',
  'MOSELLE': 'Музель',
  'NIEVRE': 'Ній',
  'NORD': 'Північний',
  'OISE': 'Вибухнути',
  'ORNE': 'Орне',
  'PAS-DE-CALAIS': 'Pas-de-Calais',
  'PUY-DE-DOME': 'Пуй-де-купу',
  'PYRENEES-ORIENTALES': 'Pyrenees-orientales',
  'RHONE': 'Роне',
  'SAONE-ET-LOIRE': 'Saone-et-luire',
  'SARTHE': 'Сарте',
  'SAVOIE': 'Савоя',
  'HAUTS-DE-SEINE': 'Hauts-de-sesine',
  'SEINE-ET-MARNE': 'Сен-Ет-Марн',
  'ESSONNE': 'Ессон',
  'SEINE-MARITIME': 'SEINE-MARITIME',
  'SOMME': 'Сомма',
  'TARN': 'Таран',
  'TARN-ET-GARONNE': 'Тарн-Ет-Гаронна',
  'VAR': 'Var',
  'VAUCLUSE': 'Валклюза',
  'VENDEE': 'Веней',
  'VIENNE': 'Відієнна',
  'VOSGES': 'Войди',
  'YONNE': 'Йонна',
  'CORSE-DU-SUD': 'Corse-Du-Sud',
  'SEINE-SAINT-DENIS': 'Сен-Сен-Денис',
  'VAL-DE-MARNE': 'Валь-Марн',
  "VAL-D'OISE": 'Валь-д\'оаза',
  'VILLE DE PARIS': 'Вілл де Париж',
  'YVELINES': 'Yvelines',
  'HAUTE-CORSE': 'Високий коник',
  'BANJUL': 'Банджул',
  'LOWER RIVER': 'Нижня річка',
  'MACCARTHY ISLAND': 'Острів Маккарті',
  'WESTERN': 'Західний',
  'NORTH BANK': 'Північний берег',
  'UPPER RIVER': 'Верхня річка',
  'ESTUAIRE': 'Лиман',
  'HAUT-OGOOUE': 'Haut-ogoooue',
  'MOYEN-OGOOUE': 'Moyen-ogootae',
  'NGOUNIE': 'Ногою',
  'NYANGA': 'Нюнга',
  'OGOOUE-IVINDO': 'Ogooue-Ivindo',
  'OGOOUE-LOLO': 'Ogooue-lolo',
  'OGOOUE-MARITIME': 'Ogooue-maritime',
  'WOLEU-NTEM': 'Woleu-ntem',
  'BADEN-WUERTTEMBERG': 'Баден-Вуертемберг',
  'BAYERN': 'Баваря',
  'BREMEN': 'Бремен',
  'HAMBURG': 'Гамбург',
  'HESSEN': 'Гессен',
  'NIEDERSACHSEN': 'Нідерсахсен',
  'NORDRHEIN-WESTFALEN': 'Нордрхейн-Вестфален',
  'RHEINLAND-PFALZ': 'Rheinland-Pfalz',
  'SAARLAND': 'Саарланд',
  'SCHLESWIG-HOLSTEIN': 'Шлезвіг-Гольштейн',
  'BERLIN': 'Берлін',
  'BRANDENBURG': 'Бранденбург',
  'MECKLENBURG-VORPOMMERN': 'Mecklenburg-vorpommern',
  'SACHSEN ANHALT': 'Саксен Анхальт',
  'SACHSEN': 'Сачсен',
  'THUERINGEN': 'Тюрген',
  'GREATER ACCRA': 'Великий Акра',
  'ASHANTI': 'Ашанті',
  'BRONG-AHAFO': 'Бронг-А-Афо',
  'CENTRAL': 'Центральний',
  'EASTERN': 'Східний',
  'NORTHERN': 'Північний',
  'UPPER': 'Верхній',
  'VOLTA': 'Вольта',
  'NORDGRONLAND': 'Nordgronland',
  'OSTGRONLAND': 'Остгронленд',
  'VESTGRONLAND': 'Бакалавр',
  'EVROS': 'Evros',
  'RODHOPI': 'Родхопі',
  'XANTHI': 'Ксанті',
  'DRAMA': 'Драма',
  'SERRAI': 'Серраї',
  'KILKIS': 'Кілкіс',
  'PELLA': 'Пелла',
  'FLORINA': 'Флоріна',
  'KASTORIA': 'Касторія',
  'GREVENA': 'Гревена',
  'KOZANI': 'Козані',
  'IMATHIA': 'Іматія',
  'THESSALONIKI': 'Салоніки',
  'KAVALA': 'Кавала',
  'KHALKIDHIKI': 'Халкідхікі',
  'PIERIA': 'Пірія',
  'IOANNINA': 'Ioannina',
  'THESPROTIA': 'Thesprotia',
  'PREVEZA': 'Превеза',
  'ARTA': 'Арта',
  'LARISA': 'Лариса',
  'TRIKALA': 'Трикала',
  'KARDHITSA': 'Кардхітса',
  'MAGNISIA': 'Магісія',
  'KERKIRA': 'Керкіра',
  'LEVKAS': 'Левкас',
  'KEFALLINIA': 'Кеффалінія',
  'ZAKINTHOS': 'Zakinthos',
  'FTHIOTIS': 'Fthiotis',
  'EVRITANIA': 'Евританія',
  'AITOLIA KAI AKARNANIA': 'Aitolia Kai Akarnania',
  'FOKIS': 'Фокіс',
  'VOIOTIA': 'Воїт',
  'EVVOIA': 'EVVOIA',
  'ATTIKI': 'Аттіті',
  'ARGOLIS': 'Арголіс',
  'KORINTHIA': 'Корінтія',
  'AKHAIA': 'Аххая',
  'ILIA': 'Ілія',
  'MESSINIA': 'Мессінія',
  'ARKADHIA': 'Аркадхія',
  'LAKONIA': 'Лаконія',
  'KHANIA': 'Ханія',
  'RETHIMNI': 'Rethimni',
  'IRAKLION': 'Іракліон',
  'LASITHI': 'Лази',
  'DHODHEKANISOS': 'Dhodhekanisos',
  'SAMOS': 'Самос',
  'KIKLADHES': 'Кікладхеси',
  'KHIOS': 'Хіос',
  'LESVOS': 'Лесвос',
  'ALTA VERAPAZ': 'Альта Верапаз',
  'BAJA VERAPAZ': 'Баджа Верапаз',
  'CHIMAL TENANGO': 'Chimal Tenango',
  'CHIQUIMULA': 'Чікімула',
  'EL PROGRESO': 'El Progreso',
  'ESCUINTLA': 'Escuintla',
  'HUEHUETENANGO': 'Huehuetenango',
  'IZABAL': 'Ізабал',
  'JALAPA': 'Джалапа',
  'JUTIAPA': 'Ютіапа',
  'PETEN': 'Петля',
  'QUEZALTENANGO': 'Quezaltenango',
  'QUICHE': 'Кіше',
  'RETALHULEU': 'Ретальхулеу',
  'SACATEPEQUEZ': 'Sacatepequez',
  'SAN MARCOS': 'Сан -Маркос',
  'SANTA ROSA': 'Санта -Роза',
  'SOLOLA': 'Солола',
  'SUCHITEPEQUEZ': 'Suceitepequez',
  'TOTONICAPAN': 'Тоттонікан',
  'ZACAPA': 'ZACAPA',
  'BEYLA': 'Бейла',
  'BOFFA': 'Буфта',
  'BOKE': 'Бокер',
  'CONAKRY': 'Конакрі',
  'DABOLA': 'Дабола',
  'DALABA': 'Далаба',
  'DINGUIRAYE': 'Dinguiraye',
  'DUBREKA': 'Дубрека',
  'FARANAH': 'Фарана',
  'FORECARIAH': 'Фурікат',
  'FRIA': 'Фрі',
  'GAOUAL': 'Гауал',
  'GUECKEDOU': 'Гекеду',
  'KANKAN': 'Кананський',
  'KEROUANE': 'Керуан',
  'KINDIA': 'Крадіжка',
  'KISSIDOUGOU': 'Кіссидугу',
  'KOUNDARA': 'Кундара',
  'KOUROUSSA': 'Курусса',
  'LABE': 'Лабораторія',
  'MACENTA': 'Макента',
  'MAMOU': 'Маму',
  'NZEREKORE': 'Нзерекоре',
  'PITA': 'Лава',
  'SIGUIRI': 'Сигурі',
  'TELIMELE': 'Телімеле',
  'TOUGUE': 'Тугу',
  'YOMOU': 'Йому',
  'BARIMI-WAINI': 'Барімі-Вейні',
  'CUYUNI-MAZARUNI': 'Cuyuni-Mazaruni',
  'DEMERARA-MAHAICA': 'Демерара-Махаїка',
  'EAST BERBICE-CORENTYNE': 'Східний Бербіс-Корентін',
  'ESSEQUIBO ISLANDS-WEST DEMERARA': 'Essequibo острови-захід Демерара',
  'ARTIBONITE': 'Артибоніт',
  'NORD-OUEST': 'Північний',
  'OUEST': 'Вишуканий',
  'SUD': 'Суд',
  'ATLANTIDA': 'Атлантида',
  'CHOLUTECA': 'Холутека',
  'COLON': 'Товста кишка',
  'COMAYAGUA': 'Комауагуа',
  'COPAN': 'Копан',
  'CORTES': 'Кортес',
  'EL PARAISO': 'El Paraiso',
  'FRANCISCO MORAZAN': 'Франциско Моразан',
  'GRACIAS A DIOS': 'Gracias a dios',
  'INTIBUCA': 'Інтибука',
  'ISLAS DE LA BAHIA': 'Іслас де ла Бая',
  'LA PAZ': 'La paz',
  'LEMPIRA': 'Лемпіра',
  'OCOTEPEQUE': 'Окотепек',
  'OLANCHO': 'Оланчо',
  'SANTA BARBARA': 'Санта -Барбара',
  'VALLE': 'Долина',
  'YORO': 'Йоро',
  'BACS-KISKUN': 'BACS-KISKUN',
  'BARANYA': 'Баранья',
  'BEKES': 'Бокс',
  'BORSOD-ABAUJ-ZEMPLEN': 'Borsod-abauj-Zemplen',
  'BUDAPEST': 'Будапешт',
  'CSONGRAD': 'Csongrad',
  'DEBRECEN': 'Дебрезентувати',
  'FEJER': 'Феєр',
  'GYOR-SOPRON': 'GYOR-SOPRON',
  'HAJDU-BIHAR': 'Хадду-біхар',
  'HEVES': 'Хліб',
  'KOMAROM': 'Комаром',
  'MISKOLC': 'MISKOLC',
  'NOGRAD': 'Ноград',
  'PECS': 'Пекар',
  'PEST': 'Шкідник',
  'SOMOGY': 'Сокогія',
  'SZABOLCS-SZATMAR': 'Szabolcs-szatmar',
  'SZED': 'Згорблений',
  'SZOLNOK': 'Szolnok',
  'TOLNA': 'Толна',
  'VAS': 'Vas',
  'VESZPREM': 'Veszprem',
  'ZALA': 'ZALA',
  'GYOR': 'Gyor',
  'AKRANES': 'Акрани',
  'AKUREYRI': 'Акурерірі',
  'ARNESSYSLA': 'Arnessla',
  'AUSTUR-BARDHASTRANDARSYSLA': 'Austur-bardhastrandarsysla',
  'AUSTUR-HUNAVATNSSYSLA': 'Austur-hunavatnssysla',
  'AUSTUR-SKAFTAFELLSSYSLA': 'Austur-Skaftafellssysla',
  'BORGARFJARDHARSYSLA': 'Borgarfjardharsysla',
  'DALASYSLA': 'Даласила',
  'EYJAFJARDHARSYSLA': 'Eyjafjardharsysla',
  'GULLBRINGUSYSLA': 'Gullbringusysla',
  'HAFNARFJORDHUR': 'Hafnarfjordhur',
  'HUSAVIK': 'Хусавік',
  'ISAFJORDHUR': 'Isafjordhur',
  'KEFLAVIK': 'Кефлавик',
  'KJOSARSYSLA': 'Kjosarsysla',
  'KOPAVOGUR': 'Копавогур',
  'MYRASYSLA': 'Myrasysla',
  'NESKAUPSTADHUR': 'Neskaupstadhur',
  'NORDHOUR-ISAFJARDHARSYSLA': 'Nordhour-isafjardharsysla',
  'NORDHUR-MULASYSLA': 'Nordhur-mulasysla',
  'NORDHUR-THINYJARSYSLA': 'Nordhur-Thinyjarsysla',
  'OLAFSFJORDHUR': 'Olafsfjordhur',
  'RANGARVALLASYSLA': 'Rangarvallasysla',
  'REYKJAVIK': 'Рейк\'явік',
  'SAUDHARKROKUR': 'Саудхаркрокур',
  'SEYDHISFJORDHUR': 'Seydhisfjordhur',
  'SIGLUFJORDHUR': 'Сіглуфьордхур',
  'SKAGAFJARDHARSYSLA': 'Skagafjardharsysla',
  'SNAEFELLSNES-OG HNAPPADALSSYSLA': 'Snaefellsnes-og hnappadalssysla',
  'STRANDASYSLA': 'Strandasysla',
  'SUDHUR-MULASYSLA': 'Sudhur-mulasysla',
  'SUDHUR-THINYJARSYSLA': 'Sudhur-thinyjarsysla',
  'VESTMANNAEYJAR': 'Вестманнеяяр',
  'VESTUR-BARDHASTRANDARSYSLA': 'Vestur-Bardhastrandarsysla',
  'VESTUR-HUNAVATNSSYSLA': 'Вестур-Хунаватнссла',
  'VESTUR-ISAFJARDHARSYSLA': 'Весур-Ісафджардхарссла',
  'VESTUR-SKAFTAFELLSSYSLA': 'Vestur-skaftafellssysla',
  'ACEH': 'Aceh',
  'BALI': 'Балі',
  'BENGKULU': 'Бенгкулу',
  'JAKARTA RAYA': 'Джакарта Рая',
  'JAMBI': 'Джамбі',
  'JAWA BARAT': 'Jawa barat',
  'JAWA TENGAH': 'Jawa tengah',
  'JAWA TIMUR': 'Джава Тимур',
  'IRIAN JAYA': 'Іріан Джая',
  'YOGYAKARTA': 'Джокякарта',
  'KALIMANTAN BARAT': 'Калімантан Барат',
  'KALIMANTAN SELATAN': 'Калімантан Селатан',
  'KALIMANTAN TENGAH': 'Калімантан Тенга',
  'KALIMANTAN TIMUR': 'Калімантан Тимур',
  'LAMPUNG': 'Лампунг',
  'MALUKU': 'Малаку',
  'NUSA TENGGARA BARAT': 'NUSA TENGGARA BARAT',
  'NUSA TENGGARA TIMUR': 'NUSA TENGGARA TIMUR',
  'RIAU': 'Ряу',
  'SULAWESI SELATAN': 'Сулавесі Селатан',
  'SULAWESI TENGAH': 'Сулавесі Тенга',
  'SULAWESI TENGGARA': 'Sulawesi Tenggara',
  'SULAWESI UTARA': 'Сулавесі Утара',
  'SUMATERA BARAT': 'Суматера Барат',
  'SUMATERA SELATAN': 'Sumatera Selatan',
  'SUMATERA UTARA': 'Sumatera Utara',
  'TIMOR TUMUR': 'Тимор Тумур',
  'ANDAMAN AND NICOBAR ISLANDS': 'АНДАМАН та Нікобарські острови',
  'ANDHRA PRADESH': 'Андхра -Прадеш',
  'ASSAM': 'Ацсам',
  'BIHAR': 'Біхар',
  'CHANDIGARH': 'Чандігарх',
  'DADRA AND NAGAR HAVELI': 'Дадра та Нагар Хавелі',
  'DELHI': 'Делі',
  'GOA, DAMAN, AND DIU': 'Гоа, Даман і Діу',
  'GUJARAT': 'Гуджарат',
  'HARYANA': 'Хар\'яна',
  'HIMACHAL PRADESH': 'Хімачал -Прадеш',
  'JAMMU AND KASHMIR': 'Джамму та Кашмір',
  'KERALA': 'Керала',
  'LAKSHADWEEP': 'Lakshadweep',
  'MADHYA PRADESH': 'Мадх\'я -Прадеш',
  'MAHARASHTRA': 'Махараштра',
  'MANIPUR': 'Маніпур',
  'MEGHALAYA': 'Мегалая',
  'KARNATAKA': 'Карнатака',
  'NAGALAND': 'Нагаланд',
  'ORISSA': 'Орісса',
  'PONDICHERRY': 'Пондічеррі',
  'PUNJAB': 'Пенджаб',
  'RAJASTHAN': 'Раджастан',
  'TAMIL NADU': 'Таміл Наду',
  'TRIPURA': 'Трипура',
  'UTTAR PRADESH': 'Уттар -Прадеш',
  'WEST BENGAL': 'Західний Бенгал',
  'SIKKIM': 'Сіккім',
  'ARUNACHAL PRADESH': 'Аруначал -Прадеш',
  'MIZORAM': 'Мізорам',
  'AZARBAYJAN-E BAKHTARI': 'Azarbayjan-e Bakhtari',
  'AZARBAYJAN-E KHAVARI': 'Azarbayjan-e Khavari',
  'CHAHAR MAHALL VA BAKHTIARI': 'Chahar Mahall Va Bakhtiari',
  'BALUCHESTAN VA SISTAN': 'Baluchestan VA Sistan',
  'BOVIR AHMADI VA KOHKILUYEH': 'Bovir Ahmadi Va Kokiluyeh',
  'FARS': 'Фарш',
  'GILAN': 'Гілан',
  'HAMADAN': 'Хамадан',
  'ILAM': 'Ілам',
  'HORMOZGAN': 'Горомозган',
  'BAKHTARAN': 'Бахтаран',
  'KHUZESTAN': 'Хузестан',
  'KORDESTAN': 'Корестан',
  'MAZANDARAN': 'Мазандаран',
  'BUSHEHR': 'Бушер',
  'LORESTAN': 'Лорестан',
  'MARKAZI': 'Марказі',
  'SEMNAN': 'Семнан',
  'TEHRAN': 'Тегеран',
  'ZANJAN': 'ZANJAN',
  'ESFAHAN': 'Есфахан',
  'KERMAN': 'Керман',
  'KHORASAN': 'Хорасан',
  'YAZD': 'Язд',
  'HADAROM': 'Хадраром',
  'HAMERKAZ': 'Хамерказ',
  'HAZAFON': 'Хазафон',
  'HEFA': 'Хефа',
  'TEL AVIV': 'ТЕЛЬ-АВІВ',
  'YERUSHALAYIM': 'Єрушалаїм',
  'ABRUZZI': 'Абруцці',
  'BASILICATA': 'Базиліката',
  'CALABRIA': 'Калабрія',
  'CAMPANIA': 'Кампанія',
  'EMILIA-ROMAGNA': 'Емілія-Романья',
  'FRIULI-VENEZIA GIULIA': 'Фріулі-Венеція Джулія',
  'LAZIO': 'Лаціо',
  'LIGURIA': 'Лігурія',
  'LOMBARDIA': 'Ломбардія',
  'MARCHE': 'Марш',
  'MOLISE': 'Молізувати',
  'PIEMONTE': 'Пімонте',
  'PUGLIA': 'Пулія',
  'SARDEGNA': 'Сардегна',
  'SICILIA': 'Сицилія',
  'TOSCANA': 'Тоскана',
  'TRENTINO-ALTO ADIGE': 'Trentino-Alto Adige',
  'UMBRIA': 'Умбрія',
  'VALLE D AOSTA': 'Valle D Aosta',
  'VENETO': 'Венето',
  'ABENGOUROU': 'Абенгуру',
  'ABIDJAN': 'Абіджан',
  'DABAKALA': 'Дабакала',
  'ABOISSO': 'Заборгованість',
  'ADZOPE': 'Adzope',
  'AGBOVILLE': 'Агбовіл',
  'BIANKOUMA': 'Біанкума',
  'BONDOUKOU': 'Бондука',
  'BOUAFLE': 'Буфе',
  'BOUAKE': 'Букей',
  'BOUNA': 'Буна',
  'BOUNDIALI': 'Oundiali',
  'DALOA': 'Далоа',
  'DANANE': 'Данан',
  'DIMBOKRO': 'Димбокро',
  'DIVO': 'Диво',
  'FERKESSEDOUGOU': 'Ferksedougou',
  'GAGNOA': 'Ганьоа',
  'GUIGLO': 'Гурт',
  'KATIOLA': 'Катіола',
  'KORHOGO': 'Коріго',
  'MAN': 'Людина',
  'ODIENNE': 'Одієнна',
  'SASSANDRA': 'Сассандра',
  'SEGUELA': 'Сегула',
  'TOUBA': 'Туба',
  'AL ANBAR': 'Аль -Анбар',
  'AL BASRAH': 'Аль Басра',
  'AL MUTHANNA': 'Аль Мутанна',
  'AL QADISIYAH': 'Аль -Кадісія',
  'AS SULAYMANIYAH': 'Як Сулеймія',
  'BABIL': 'Бабал',
  'BAGHDAD': 'Багдад',
  'DAHUK': 'Дахук',
  'DHI QAR': 'Dhi qar',
  'DIYALA': 'Діяла',
  'IRBIL': 'Ірбіл',
  'KARBALA': 'Карбала',
  "AT TA'MIM": 'В Ta\'mim',
  'MAYSAN': 'Мейсан',
  'NINAWA': 'Нінава',
  'WASIT': 'ЦЕ БУЛО',
  'AN NAJAF': 'Najaf',
  'SALAH AD DIN': 'Salah ad din',
  'AICHI': 'Айчі',
  'AKITA': 'Акіта',
  'AOMORI': 'Аморі',
  'CHIBA': 'Чіба',
  'EHIME': 'Ehime',
  'FUKUI': 'Фукуї',
  'FUKUOKA': 'Фукуока',
  'FUKUSHIMA': 'Фукусіма',
  'GIFU': 'Гифу',
  'GUMMA': 'Гамма',
  'HIROSHIMA': 'Хіросіма',
  'HOKKAIDO': 'Хоккайдо',
  'HYOGO': 'Hyogo',
  'IBARAKI': 'Ібаракі',
  'ISHIKAWA': 'Ishikawa',
  'IWATE': 'Iwate',
  'KAGAWA': 'Кагава',
  'KAGOSHIMA': 'Кагосіма',
  'KANAGAWA': 'Канагава',
  'KOCHI': 'Кочі',
  'KUMAMOTO': 'Кумамото',
  'KYOTO': 'Кіот',
  'MIE': 'Міра',
  'MIYAGI': 'Міягі',
  'MIYAZAKI': 'Міядзакі',
  'NAGANO': 'Нагано',
  'NAGASAKI': 'Нагасакі',
  'NARA': 'Нара',
  'NIIGATA': 'Нігата',
  'OITA': 'Ойта',
  'OKAYAMA': 'Окаяма',
  'OSAKA': 'Окака',
  'SAGA': 'Сага',
  'SAITAMA': 'Сайтама',
  'SHIGA': 'Шига',
  'SHIMANE': 'Шиман',
  'SHIZUOKA': 'Шизуока',
  'TOCHIGI': 'Точігі',
  'TOKUSHIMA': 'Токусіма',
  'TOKYO': 'Токіо',
  'TOTTORI': 'Тотторі',
  'TOYAMA': 'Тойама',
  'WAKAYAMA': 'Вакаяма',
  'YAMAGATA': 'Ямагата',
  'YAMAGUCHI': 'Ямагучі',
  'YAMANASHI': 'Яманаші',
  'OKINAWA': 'Окінава',
  'CLARENDON': 'Кларендон',
  'HANOVER': 'Ганновер',
  'MANCHESTER': 'Манчестер',
  'PORTLAND': 'Портленд',
  'SAINT ANDREW': 'Сент -Ендрю',
  'SAINT ANN': 'Святий',
  'SAINT CATHERINE': 'Свята',
  'SAINT ELIZABETH': 'Свята Єлизавета',
  'SAINT JAMES': 'Святий Джеймс',
  'SAINT THOMAS': 'Святий Томас',
  'TRELAWNY': 'Трейновий',
  'WESTMORELAND': 'Вестморленд',
  'KINGSTON': 'Кінгстон',
  'AL ASIMAH': 'Аль Асіма',
  'AL BALQA': 'Аль Балька',
  'AL KARAK': 'Аль -Карак',
  'AL KHALIL': 'Аль Халіл',
  'AL QUDS': 'Al quds',
  'IRBID': 'Ірбід',
  'MAAN': 'Маан',
  'NABULUS': 'Накол',
  'COAST': 'УЗБЕРЕЖЖІ',
  'NAIROBI AREA': 'Район Найробі',
  'NORTH-EASTERN': 'Північно-схід',
  'NYANZA': 'Нянза',
  'RIFT VALLEY': 'Ріфт -Веллі',
  'CHAGANG-DO': 'Чаганг-До',
  "CH'ONGJIN-SI": 'Ch\'ongjin-si',
  'HAMGYONG-NAMDO': 'Hamgyong-Namdo',
  'HAMGYONG-BUKTO': 'Хамгіонг-Букто',
  'HWANGHAE-NAMDO': 'Hwanghae-Namdo',
  'KWANGHAE-BUKTO': 'Kwanghae-Bukto',
  'KAESONG-CHIGU': 'Каесонг-Чигу',
  'KANGWON-DO': 'Кангвон-До',
  "P'YONGAN-NAMDO": 'P\'Yongan-Namdo',
  "'P'YONGAN-BUKTO": 'P\'Yongan-Bukto',
  "'P'YONGYANG-TUKPYOLSI": 'P\'yongyang-tukpyolsi',
  'YANGGANG-DO': 'Янганг-До',
  "NAMP'O-SI": 'Namp\'o-si',
  'GILBERT ISLANDS': 'Острови Гілберт',
  'LINE ISLANDS': 'Лінійні острови',
  'PHOENIX ISLANDS': 'Фенікс -острови',
  'CHEJU-DO': 'Чеджу',
  'CHOLLA-NAMDO': 'Чолла-Намдо',
  'CHOLLA-BUKTO': 'Чолла-Букто',
  "CH'UNGCH'ONG-NAMDO": 'Ch\'ungch\'ong-namdo',
  "CH'UNGCH'ONG-BUKTO": 'Ch\'ungch\'ong-bukto',
  'HYONGSANG-NAMDO': 'Hyongsang-Namdo',
  'PUSAN-JIKHALSI': 'PUSAN-Jikhalsi',
  "SOUL-T'UKPYOLSI": 'Душа-т\'укпіолсі',
  "INCH'ON-JIKHALSI": 'Inch\'on-jikhalsi',
  'KYONGGI-DO': 'Kyonggi-do',
  'KYONGSANG-BUKTO': 'Kyongsang-bukto',
  'AL AHMADI': 'Аль Ахмаді',
  'AL KUWAYT': 'Аль Куейт',
  'HAWALLI': 'Хаваллі',
  'ATTAPU': 'Аттапу',
  'CHAMPASAK': 'Шампасак',
  'HOUAPHAN': 'Хоуфан',
  'KHAMMOUAN': 'Хаммуан',
  'LOUANG NAMTHA': 'Луанг Намта',
  'LOUANGPHRABANG': 'Луангфрабанг',
  'OUDOMXAI': 'Oudomxai',
  'PHONGSALI': 'Фонгсалі',
  'SARAVAN': 'Сараван',
  'SAVANNAKHET': 'Саванахет',
  'VIENTIANE': 'Винця',
  'XAIGNABOURI': 'Xaignabouri',
  'XIANGKHOANG': 'Xiangkhoang',
  'AL BIQA': 'Al Biqa',
  'AL JANUB': 'Аль Януб',
  'ASH SHAMAL': 'Попіл Шамал',
  'BAYRUT': 'Бухта',
  'JABAL LUBNAN': 'Джабал Любнан',
  'BONG': 'Бонг',
  'GRAND JIDE': 'Грандіозний',
  'GRAND BASSA': 'Великий Басса',
  'GRAND CAPE MOUNT': 'Гранд -Кейп -гора',
  'LOFA': 'Лофа',
  'MARYLAND': 'Меріленд',
  'MONROVIA': 'Монровія',
  'MONTSERRADO': 'Монтсеррадо',
  'NIMBA': 'Німба',
  'SINO': 'Китайський',
  'BALZERS': 'Бальзерс',
  'ESCHEN': 'Вишукуватися',
  'GAMPRIN': 'Гампрін',
  'MAUREN': 'Маурен',
  'PLANKEN': 'Планкен',
  'RUGGELL': 'Руггель',
  'SCHAAN': 'Шан',
  'SCHELLENBERG': 'Шелленберг',
  'TRIESEN': 'Спермати',
  'TRIESENBERG': 'Трилесенберг',
  'VADUZ': 'Вадуз',
  'BEREA': 'Бійка',
  'BUTHA-BUTHE': 'Батха-бут',
  'LERIBE': 'Леріб',
  'MAFETENG': 'Мафетенг',
  'MASERU': 'Масер',
  'MOHALES HOEK': 'Мохалес Хок',
  'MOKHOTLONG': 'Мохотлонг',
  'QACHAS NEK': 'Qachas nek',
  'QUTHING': 'Кут',
  'DIEKIRCH': 'Діекірх',
  'GREVENMACHER': 'Grevenmacher',
  'AJDABIYA': 'Ajdabiya',
  'AL ABYAR': 'Аль Аб\'яр',
  'AL AZIZIYAH': 'Аль -Азізія',
  'AL BAYDA': 'Аль Байда',
  'AL JUFRAH': 'Аль Джуфра',
  'AL JUMAYL': 'Аль Джумайл',
  'AL KHUMS': 'Аль Хумс',
  'AL KUFRAH': 'Аль Куфра',
  'AL MARJ': 'Аль Мардж',
  'AL QARABULLI': 'Аль -Карабуллі',
  'AL QUBBAH': 'Al qubbah',
  'AL UJAYLAT': 'Al ujaylat',
  'ASH SHATI': 'Попел Шаті',
  'AWBARI': 'Awbari',
  'AZ ZAHRA': 'AZ Захра',
  'AZ ZAWIYAH': 'AZ Завія',
  'BANGHAZI': 'Бангазі',
  'BANI WALID': 'Бані Валід',
  'BIN JAWWAD': 'Bin jawwad',
  'DARNAH': 'Дарна',
  'GHADAMIS': 'Гадаміс',
  'GHARYAN': 'Гаріан',
  'GHAT': 'Гуде',
  'JADU': 'Яду',
  'JALU': 'Джалу',
  'JANZUR': 'Янзур',
  'MASALLATAH': 'Масаллата',
  'MISRATAH': 'Мізерат',
  'MIZDAH': 'Мізда',
  'MURZUQ': 'Мурзук',
  'NALUT': 'Налут',
  'QAMINIS': 'Каміні',
  'QASR BIN GHASHIR': 'Qasr bin Ghashir',
  'SABHA': 'Сабха',
  'SABRATAH': 'Сабрата',
  'SHAHHAT': 'Шаххат',
  'SURMAN': 'Сурман',
  'SURT': 'Переробляти',
  'TAJURA': 'Таджура',
  'TARABULUS': 'Тарабул',
  'TARHUNAH': 'Тархуна',
  'TUBRUG': 'Тюбраг',
  'TUKRAH': 'Тукра',
  'YAFRAN': 'Яфран',
  'ZLITAN': 'Zlitan',
  'ZUWARAH': 'Зувара',
  'ANTSIRANANA': 'Мантранана',
  'FIANARANTSOA': 'Fianantansoa',
  'MAHAJANGA': 'Махаджанга',
  'TOAMASINA': 'Томасіна',
  'ANTANANARIVO': 'Антананаріво',
  'TOLIARA': 'Толіара',
  'ILHAS': 'Ільха',
  'MACAU': 'Макао',
  'ARHANGAY': 'Архангай',
  'BAYANHONGOR': 'Баянхонгор',
  'BAYAN-OLGIY': 'Баян-Олгія',
  'BULGAN': 'Бульган',
  'DARHAN': 'Дархан',
  'DORNOD': 'Дорнд',
  'DORNOGOVI': 'Доргові',
  'DUNDGOVI': 'Дундгові',
  'DZAVHAN': 'Дзавхан',
  'GOVI-ALTAY': 'Govi-Altay',
  'HENTIY': 'Хентія',
  'HOVD': 'Хижак',
  'HOVSGOL': 'Ховсгол',
  'OMNOGOVI': 'Омнігові',
  'OVORHANGAY': 'Овору',
  'SELENGE': 'Сномен',
  'SUHBAATAR': 'Сухбаатар',
  'TOV': 'TOV',
  'UVS': 'УФ',
  'ULAANBAATAR': 'Улянбаатар',
  'SAINT ANTHONY': 'Святий Ентоні',
  'SAINT GEORGES': 'Святий Жорж',
  'BLANTYRE': 'Блантайр',
  'CHIKWAWA': 'Чіквава',
  'CHIRADZULU': 'Чирадзулу',
  'CHITIPA': 'Хітіпа',
  'CHOLO': 'Холо',
  'DEDZA': 'Дедза',
  'DOWA': 'Дуа',
  'KARONGA': 'Караунга',
  'KASUNGU': 'Касунгу',
  'MACHINGA': 'Махінга',
  'LILONGWE': 'Лілонгве',
  'MANGOCHE': 'Мангош',
  'MCHINJI': 'Макінджі',
  'MULANJE': 'Мулунже',
  'MZIMBA': 'Мзимба',
  'NCHEU': 'Ncheu',
  'NKHATA BAY': 'Затока Нкхата',
  'NKHOTA KOTA': 'Nkhota Kota',
  'NSANJE': 'Нсанже',
  'RUMPHI': 'Румфі',
  'SALIMA': 'Саліма',
  'ZOMBA': 'Зомба',
  'BAMAKO': 'Бамако',
  'GAO': 'Гао',
  'KAYES': 'Кайя',
  'MOPTI': 'Мопті',
  'SEGOU': 'Сегуг',
  'SIKASSO': 'Сікассо',
  'KOULIKORO': 'Кулікоро',
  'TOMBOUCTOU': 'Томбукту',
  'LA CONDAMINE': 'La Condamine',
  'MONTE-CARLO': 'МОНТЕ КАРЛО',
  'AGADIR': 'Агадір',
  'AL HOCEIMA': 'Аль Хосеїма',
  'AZILAL': 'Азилальний',
  'BEN SLIMANE': 'Бен Сліман',
  'BENI MELLAL': 'Бені Меллал',
  'BOULEMANE': 'Бульман',
  'CASABLANCA': 'Касабланка',
  'CHAOUEN': 'Чауен',
  'EL JADIDA': 'Ель Джадіда',
  'EL KELAA DES SRARHNA': 'Ель Келаа де Срархна',
  'ER RACHIDIA': 'Ер Рахідія',
  'ESSAOUIRA': 'Ессауїра',
  'FES': 'Фес',
  'FIGUIG': 'Фігург',
  'KENITRA': 'Кенітра',
  'KHEMISSET': 'Хеміссет',
  'KHENIFRA': 'Хеніфра',
  'KHOURIBGA': 'Khouribga',
  'MARRAKECH': 'Марракех',
  'MEKNES': 'Мокнес',
  'NADOR': 'Надор',
  'QUARZAZATE': 'Карзазат',
  'OUJDA': 'Oujda',
  'RABAT-SALE': 'Рабат',
  'SAFI': 'Сафи',
  'SETTAT': 'Поселення',
  'TANGER': 'Манера',
  'TATA': 'Тата',
  'TAZA': 'Таза',
  'TETOUAN': 'Тетуан',
  'TIZNIT': 'Тизніт',
  'GUELMIM': 'Гельмім',
  'IFRANE': 'Іфран',
  'LAAYOUNE': 'Лайна',
  'TAN-TAN': 'Засмаг',
  'TAOUNATE': 'Примикати',
  'BEAU BASSIN-ROSE HILL': 'Beau Bassin-Rose Hill',
  'CUREPIPE': 'Крепово',
  'MOKA-FLACQ': 'Moka-Flacq',
  'PORT LOUIS': 'Порт Луїс',
  'QUATRE BORNES': 'Quatre Bornes',
  'VACOAS-PHOENIX': 'Вакуас-фенікс',
  'HODH ECH CHARGUI': 'Hodh ech chargui',
  'HODH EL GHARBI': 'Hodh El Gharbi',
  'EL ACABA': 'Ель Акаба',
  'GORGOL': 'Горгол',
  'BRAKNA': 'Бракна',
  'TRARZA': 'Трарза',
  'DAKHLET NOUADHIBOU': 'Daklet Nouadhibou',
  'TAGANT': 'Обтягуючий',
  'GUIDIMAKA': 'Гудімака',
  'TIRIS ZEMMOUR': 'Тіріс Земмур',
  'INCHIRI': 'Дихірі',
  'SEENU': 'Сайду',
  'ALIFF': 'Аліфф',
  'LAVIYANI': 'Лавіяні',
  'WAAVU': 'Вааву',
  'LAAMU': 'Лайну',
  'HAA ALIFF': 'Хаа Аліфф',
  'THAA': 'Thaa',
  'MEEMU': 'Meemu',
  'RAA': 'Раа',
  'FAAFU': 'Фаафу',
  'DAALU': 'Даалу',
  'BAA': 'Баа',
  'HAA DAALU': 'Хаа Даалу',
  'SHAVIYANI': 'Шавіяні',
  'NOONU': 'Нона',
  'KAAFU': 'Каафу',
  'GAAFU ALIFF': 'Гаафу Аліфф',
  'GAAFU DAALU': 'Гаафу Даалу',
  'NAVIYANI': 'Навіяні',
  'AGUASCALIENTES': 'Aguascalientes',
  'BAJA CALIFORNIA NORTE': 'Баджа Каліфорнія Норте',
  'BAJA CALIFORNIA SUR': 'Baja California Sur',
  'CAMPECHE': 'Кемпече',
  'CHIAPAS': 'Чіапас',
  'CHIHUAHUA': 'Чихуахуа',
  'COAHUILA DE ZARAGOZA': 'Коахіла де Сарагоса',
  'COLIMA': 'Коліма',
  'DURANGO': 'Дуранго',
  'GUANAJUATO': 'Гуанахуато',
  'GUERRERO': 'Герреро',
  'HIDALGO': 'Хідальго',
  'JALISCO': 'Jalisco',
  'MICHOACAN DE OCAMPO': 'Michoacan de ocampo',
  'MORELOS': 'Морелос',
  'NAYARIT': 'Наарит',
  'NUEVO LEON': 'Нуево Леон',
  'OAXACA': 'Оакака',
  'PUEBLA': 'Пуебла',
  'QUERETARO DE ARTEAGA': 'Queretaro de arteaga',
  'QUINTANA ROO': 'Quintana Roo',
  'SAN LUIS POTOSI': 'Сан -Луїс Потосі',
  'SINALOA': 'Сіналоа',
  'SONORA': 'Сонора',
  'TABASCO': 'Табаско',
  'TAMAULIPAS': 'Тамауліпас',
  'TLAXCALA': 'Tlaxcala',
  'VERACRUZ-LLAVE': 'Веракрус-Лаве',
  'YUCATAN': 'Юкатан',
  'ZACATECAS': 'Закатекас',
  'JOHOR': 'Джохор',
  'KEDAH': 'Кеда',
  'KELANTAN': 'Келантан',
  'MELAKA': 'Мелака',
  'NEGERI SEMBILAN': 'Негері Сембілан',
  'PAHANG': 'Паханг',
  'PERAK': 'Перак',
  'PERLIS': 'Перліс',
  'PULAU PINANG': 'Пулау Пінанг',
  'SABAH': 'Саба',
  'SARAWAK': 'Саравак',
  'SELANGOR': 'Селангор',
  'TERENGGANU': 'Теренггану',
  'WILAYAH PERSEKUTUAN': 'Wilayah perkutuan',
  'CABO DELGADO': 'Кабо Дельгадо',
  'GAZA': 'Газа',
  'INHAMBANE': 'Індамбан',
  'MAPUTO': 'Мапуто',
  'SOFALA': 'Софала',
  'NAMPULA': 'Нампула',
  'NIASSA': 'Ніаса',
  'TETE': 'Тіт',
  'ZAMBEZIA': 'Замбезія',
  'MANICA': 'Маніка',
  'AGADEZ': 'Агадес',
  'DIFFA': 'Різниця',
  'DOSSO': 'Чоловий',
  'MARADI': 'Мараді',
  'NIAMEY': 'Ніамея',
  'TAHOUA': 'Іхоа',
  'ZINDER': 'ZINDER',
  'CENTRAL DISTRICT NUMBER 1': 'Центральний район № 1',
  'CENTRAL DISTRICT NUMBER 2': 'Центральний округ № 2',
  'SOUTHERN': 'Південний',
  'BENUE': 'Бенуе',
  'ANAMBRA': 'Анамбра',
  'KANO': 'Кано',
  'KWARA': 'Квар',
  'LAGOS': 'Лагос',
  'BAUCHI': 'Баучі',
  'BENDEL': 'Шпилька',
  'BORNO': 'Борно',
  'CROSS RIVER': 'Перехрест',
  'RIVERS': 'Річки',
  'FEDERAL CAPITAL TERRITORY': 'Федеральна столична територія',
  'GONGOLA': 'Гонгола',
  'IMO': 'ІМО',
  'KADUNA': 'Кадуна',
  'OGUN': 'Огун',
  'ONDO': 'Ондо',
  'OYO': 'Ойо',
  'PLATEAU': 'Плато',
  'SOKOTO': 'Сокото',
  'DRENTHE': 'Дрен',
  'FRIESLAND': 'Фрисланд',
  'GELDERLAND': 'Гельдерленд',
  'GRONINGEN': 'Гронінген',
  'LIMBURG': 'Лімбург',
  'NOORD-BRABANT': 'Нордовий',
  'NOORD-HOLLAND': 'Ноорд-Холланд',
  'OVERIJSSEL': 'Оверіссель',
  'UTRECHT': 'Утрехт',
  'ZEELAND': 'Зеландія',
  'ZUID-HOLLAND': 'Zuid-Holland',
  'DRONTEN': 'Дроновий',
  'ZUIDELIJKE IJSSELMEERPOLDERS': 'Zuidelijke ijsselmeerpolders',
  'LELYSTAD': 'Леліністад',
  'AKERSHUS': 'Акершус',
  'AUST-AGDER': 'Aust-agder',
  'BUSKERUD': 'Букеруд',
  'FINNMARK': 'Фінан',
  'HEDMARK': 'Хедмарк',
  'HORDALAND': 'Города',
  'MORE OG ROMSDAL': 'Більше OG Romsdal',
  'NORDLAND': 'Нордленд',
  'NORD-TRONDELAG': 'Nord-Trondelag',
  'OPPLAND': 'Оплланд',
  'OSLO': 'Шлюз',
  'OSTFOLD': 'Вишукуватися',
  'ROGALAND': 'Рогаланд',
  'SOGN OG FJORDANE': 'Sogn og fjordane',
  'SOR-TRONDELAG': 'Сор-Тронделаг',
  'TELEMARK': 'Телемарк',
  'TROMS': 'Крадіжки',
  'VEST-AGDER': 'Жилет',
  'VESTFOLD': 'Переплетення',
  'BAGMATI': 'Багмати',
  'BHERI': 'Бери',
  'DHAWLAGIRI': 'Дахавлагірі',
  'GANDAKI': 'Гандакі',
  'JANAKPUR': 'Джанакпур',
  'KARNALI': 'Карналі',
  'KOSI': 'Косі',
  'LUMBINI': 'Лумбіні',
  'MAHAKALI': 'Махакалі',
  'MECHI': 'Мечі',
  'NARAYANI': 'Нараяні',
  'RAPTI': 'Хустка',
  'SAGARMATHA': 'Сагармата',
  'SETI': 'Сета',
  'AIWO': 'Aiwo',
  'ANABAR': 'Анабар',
  'ANETAN': 'Анетинський',
  'ANIBARE': 'Анібаре',
  'BAITI': 'Байті',
  'BOE': 'Богослов',
  'BUADA': 'Буада',
  'DENIGOMODU': 'Денігомоду',
  'EWA': 'Ewa',
  'IJUW': 'Ijuw',
  'MENENG': 'Мененг',
  'NIBOK': 'Нібок',
  'UABOE': 'Уабо',
  'YAREN': 'Ярен',
  'BROKOPONDO': 'Брокопондо',
  'COMMEWIJNE': 'Комітет',
  'CORONIE': 'Корона',
  'MAROWIJNE': 'Мароуяне',
  'NICKERIE': 'Нікель',
  'PARA': 'Пара',
  'PARAMARIBO': 'Парамарібо',
  'SARAMACCA': 'Сарамакка',
  'BOACO': 'Боако',
  'CARAZO': 'Каразо',
  'CHINANDEGA': 'Чінандега',
  'CHONTALES': 'Чонтале',
  'ESTELI': 'Естелі',
  'GRANADA': 'Гранада',
  'JINOTEGA': 'Джинотега',
  'LEON': 'Леон',
  'MADRIZ': 'Мадриріз',
  'MANAGUA': 'Манагуа',
  'MASAYA': 'Масая',
  'MATAGALPA': 'Матагалпа',
  'NUEVA SEGOVIA': 'Нуева Сеговія',
  'RIO SAN JUAN': 'Ріо Сан -Хуан',
  'RIVAS': 'Рівас',
  'ZELAYA': 'Зелая',
  'WHANG AREI': 'Whang arei',
  'WHANGAROA': 'Whangaroa',
  'WOODVILLE': 'Вудвілл',
  'WAITAKI': 'Вайтакі',
  'WAITOMO DISTRICT': 'Район Вайтомо',
  'WAITOTARA': 'Вайтара',
  'WALLACE': 'Уоллес',
  'WANGANUI': 'Вангануї',
  'WESTLAND': 'Вестленд',
  'WHAKATANE DISTRICT': 'Район Уакатан',
  'HURUNUI': 'Гурунуї',
  'SILVERPEAKS': 'Сріблясті',
  'STRATHALLAN': 'Страталлан',
  'WAIBEWA': 'Вайбева',
  'WAIHEKE': 'Вайхеке',
  'THAMES-COROMANDEL DISTRICT': 'Темза-Коромандельський район',
  'AKAROA': 'Акароа',
  'AMURI': 'Амурі',
  'ASHBURTON': 'Ешбуртон',
  'BAY OF ISLANDS': 'Затока островів',
  'BRUCE': 'Брюс',
  'BULLER': 'Буллер',
  'CHATHAM ISLANDS': 'Чатемські острови',
  'CHEVIOT': 'Шевіот',
  'CLIFTON': 'Кліфтон',
  'CLUTHA': 'Клюта',
  'COOK': 'Готувати',
  'DANNEVIRKE': 'Данневірке',
  'EGMONT': 'Егмонт',
  'EKETAHUNA': 'Eketahuna',
  'ELLESMERE': 'Еллесмір',
  'ELTHAM': 'Елтем',
  'EYRE': 'Ойр',
  'FEATHERSTON': 'ПЕТЕРСТОН',
  'FIORD': 'Фіорд',
  'FRANKLIN': 'Франклін',
  'GOLDEN BAY': 'Золота бухта',
  'GREAT BARRIER ISLAND': 'Великий бар\'єрний острів',
  'GREY': 'Сірий',
  'HAURAKI PLAINS': 'Хауракі рівнини',
  'HAWERA DISTRICT': 'Район Гавера',
  "HAWKE'S BAY": 'Затока Хоука',
  'HEATHCOTE': 'Хаткот',
  'HOBSON': 'Хобсон',
  'HOKIANGA': 'Хокіанга',
  'HOROWHENUA': 'Горовенуа',
  'HUTT': 'Хатт',
  'INANGAHUA': 'Інангахуа',
  'INGLEWOOD': 'Інглевуд',
  'KAIKOURA': 'Кайкора',
  'KAIRANGA': 'Кайранга',
  'KIWITEA': 'Ківітея',
  'LAKE': 'Озеро',
  'MACKENZIE': 'Макензі',
  'MALVERN': 'Мальвер',
  'MANAWATU': 'Манавату',
  'MANGONUI': 'Мангонуї',
  'MANIOTOTO': 'Маніотото',
  'MARLBOROUGH': 'Марлборо',
  'MASTERTON': 'Майстер',
  'MATAMATA': 'Матамата',
  'MT. HERBERT': 'Mt. Герберт',
  'OHINEMURI': 'Ойнемурі',
  'OPOTIKI': 'Опотікі',
  'OROUA': 'Оруа',
  'OTAMATEA': 'Отаматея',
  'OTOROHANGA': 'Отороханга',
  'OXFORD': 'Оксфорд',
  'PAHIATUA': 'Пахіатуа',
  'PAPARUA': 'Папаруа',
  'PATEA': 'Петя',
  'PIAKO': 'Піако',
  'POHANGINA': 'Пухангіна',
  'RAGLAN': 'Реглан',
  'RANGIORA DISTRICT': 'Район Рангіора',
  'RANGITIKEI': 'Rangitikei',
  'RODNEY': 'Родні',
  'ROTORUA DISTRICT': 'Район Роторуа',
  'SOUTHLAND': 'Південний',
  'STEWART ISLAND': 'Острів Стюарт',
  'STRATFORD': 'Стратфорд',
  'TARANAKI': 'Таранакі',
  'TAUMARUNUI': 'Taumarunui',
  'TAUPO': 'Таупо',
  'TAURANGA': 'Тауранга',
  'TUAPEKA': 'Тупека',
  'VINCENT': 'Вінсент',
  'WAIAPU': 'Вайапу',
  'WAIHEMO': 'Вайгемо',
  'WAIKATO': 'Вайкато',
  'WAIKOHU': 'Вайкоху',
  'WAIMAIRI': 'Waimairi',
  'WAIMARINO': 'Ваймаріно',
  'WAIMATE': 'Шалений',
  'WAIMATE WEST': 'Waimate West',
  'WAIMEA': 'Ваймея',
  'WAIPA': 'Вайпа',
  'WAIPAWA DISTRICT': 'Район Вайпава',
  'WAIPUKURAU DISTRICT': 'Район Вапукурау',
  'WAIRARAPA SOUTH': 'Вайрарапа Південь',
  'WAIROA': 'Вайроа',
  'ALTO PARANA': 'Альто Парана',
  'AMABAY': 'Амабай',
  'BOQUERON': 'Бокерон',
  'CAAGUAZU': 'Каагуасу',
  'CAAZAPA': 'Каазапа',
  'CONCEPCION': 'Концепція',
  'CORDILLERA': 'Кордильєра',
  'GUAIRA': 'Гуара',
  'ITAPUA': 'Ітапуа',
  'NEEMBUCU': 'Нембуку',
  'PARAGUARI': 'Парагуарі',
  'PRESIDENTE HAYES': 'Президент Хейс',
  'SAN PEDRO': 'Сан -Педро',
  'ALTO PARAGUAY': 'Альто Парагвай',
  'CANENDIYU': 'Canendiyu',
  'CHACO': 'Чако',
  'NUEVA ASUNCION': 'Nueva Asuncion',
  'AMAZONAS': 'Амазонас',
  'ANCASH': 'Анкал',
  'APURIMAC': 'Апіримак',
  'AREQUIPA': 'Arequipa',
  'AYACUCHO': 'Аякаччо',
  'CAJAMARCA': 'Каджарка',
  'CALLAO': 'Каллао',
  'CUSCO': 'Куско',
  'HUANCAVELICA': 'Huancavelica',
  'HUANUCO': 'Хуануко',
  'ICA': 'ICA',
  'JUNIN': 'Джунін',
  'LA LIBERTAD': 'La Libertad',
  'LAMBAYEQUE': 'Ламбейк',
  'LIMA': 'Ліма',
  'LORETO': 'Лорето',
  'MADRE DE DIOS': 'Мадре де Діос',
  'MOQUEGUA': 'Мокегуа',
  'PASCO': 'Паско',
  'PIURA': 'Піура',
  'PUNO': 'Кістка',
  'SAN MARTIN': 'Сан -Мартін',
  'TACNA': 'Такна',
  'TUMBES': 'Пекло',
  'UCAYALI': 'Ucayali',
  'FEDERALLY ADMINISTERED TRIBAL AREAS': 'Федерально вводяться племінні райони',
  'BALUCHISTAN': 'Белуджистан',
  'NORTH-WEST FRONTIER': 'Північно-Західний кордон',
  'SIND': 'Сінд',
  'BIALA PODLASKA': 'Біала Подласка',
  'BIALYSTOK': 'Біялсток',
  'BIELSKO': 'Біелько',
  'BYDGOSZCZ': 'Bydgoszcz',
  'CHELM': 'Чельм',
  'CIECHANOW': 'Цикіконова',
  'CZESTOCHOWA': 'Чесестова',
  'ELBLAG': 'Ельблаг',
  'GDANSK': 'Гтанск',
  'GORZOW': 'Горзов',
  'JELENIA GORA': 'JENENIA GORA',
  'KALISZ': 'Каліш',
  'KATOWICE': 'Катовіце',
  'KIELCE': 'Кілець',
  'KONIN': 'Конін',
  'KOSZALIN': 'Косалін',
  'KRAKOW': 'Краков',
  'KROSNO': 'Кросно',
  'LEGNICA': 'Легіка',
  'LESNZOJ': 'Lesnzoj',
  'LODZ': 'Лодз',
  'LOMZA': 'Ломза',
  'LUBLIN': 'Люблін',
  'NOWY SACZ': 'Наразі сакал',
  'OLSZTYN': 'Олштін',
  'OPOLE': 'Опол',
  'OSTROLEKA': 'Остролейка',
  'PILA': 'Піла',
  'PIOTRKOW': 'Піотркова',
  'PLOCK': 'Плита',
  'POZNAN': 'Познан',
  'PRZEMYSL': 'Przemysl',
  'RADOM': 'Радом',
  'RZESZOW': 'Rzesow',
  'SIEDLCE': 'Siedlce',
  'SIERADZ': 'Сьєрадц',
  'SKIERNIEWICE': 'Skierniewice',
  'TARNOBRZEG': 'Тарнобзег',
  'TARNOW': 'Дратівка',
  'TORUN': 'БІГТИ',
  'WALBRZYCH': 'Валбзич',
  'WARSZAWA': 'Варсава',
  'WLOCLAWEK': 'Wloclawek',
  'WROCLAW': 'Стовпчик',
  'ZAMOSC': 'Замоск',
  'ZIELONA GORA': 'Зеєлона Гора',
  'BOCAS DEL TORO': 'Bocas del Toro',
  'CHIRIQUI': 'Чистокі',
  'COCLE': 'Кокус',
  'DARIEN': 'Дарієн',
  'HERRERA': 'Еррера',
  'LOS SANTOS': 'Лос -Сантос',
  'SAN BLAS': 'Сан -Блас',
  'VERAGUAS': 'Дерагуас',
  'ANGRA DO HEROISMO': 'Ангра до героїзму',
  'AVEIRO': 'Авейро',
  'BEJA': 'Бея',
  'BRAGA': 'Брага',
  'BRAGANCA': 'Благанка',
  'CASTELO BRANCO': 'Кастело Бранко',
  'COIMBRA': 'Коїмбра',
  'EVORA': 'Евора',
  'FARO': 'Фаро',
  'FUNCHAL': 'Фанчальний',
  'GUARDA': 'Гуртта',
  'HORTA': 'Гору',
  'LEIRIA': 'Лейрія',
  'LISBOA': 'Лісбоа',
  'PONTA DELGADA': 'Понта Дельгада',
  'PORTALEGRE': 'Порталегре',
  'PORTO': 'Порто',
  'SANTAREM': 'Сантарем',
  'SETUBAL': 'Сетубальний',
  'VIANA DO CASTELO': 'Viana do Castelo',
  'VILA REAL': 'Віла Реальна',
  'VISEU': 'Візу',
  'GULF': 'Затока',
  'MILNE BAY': 'Мілн -Бей',
  'SOUTHERN HIGHLANDS': 'Південний нагір\'я',
  'NORTH SOLOMONS': 'Північні Соломони',
  'CHIMBU': 'Чимбу',
  'EASTERN HIGHLANDS': 'Східний нагір\'я',
  'EAST NEW BRITAIN': 'Східно -Нова Британія',
  'EAST SEPIK': 'Східний Сепік',
  'MADANG': 'Маданг',
  'MANUS': 'Манус',
  'MOROBE': 'Моро',
  'NEW IRELAND': 'Нова Ірландія',
  'WESTERN HIGHLANDS': 'Західний нагір\'я',
  'WEST NEW BRITAIN': 'Західна Нова Британія',
  'WEST SEPIK': 'Західний сепік',
  'ENGA': 'Енга',
  'NATIONAL CAPITAL': 'Національна столиця',
  'BAFATA': 'Бафата',
  'BUBA': 'Буба',
  'BISSAU': 'Біссау',
  'OIO': 'OIO',
  'BOLAMA': 'Болама',
  'CACHEU': 'Кешю',
  'TOMBALI': 'Томбалі',
  'GABU': 'Габу',
  'ALBA': 'Альба',
  'ARAD': 'Арад',
  'ARGES': 'Аргет',
  'BACAU': 'Бакау',
  'BIHOR': 'Біхор',
  'BISTRITA-NASAUD': 'Бістрита-Насао',
  'BOTOSANI': 'Босані',
  'BRAILA': 'Брейла',
  'BRASOV': 'Брасов',
  'BUCURESTI': 'Букресті',
  'BUZAU': 'Бузау',
  'CARAS-SEVERIN': 'Карас-северін',
  'CLUJ': 'Клюх',
  'CONSTANTA': 'Константа',
  'COVASNA': 'Ковзна',
  'DIMBOVITA': 'Дімбовіта',
  'DOLJ': 'Дальй',
  'GALATI': 'Галаті',
  'GORJ': 'Гуркіт',
  'HARGHITA': 'Гаргіта',
  'HUNEDOARA': 'Хунедоара',
  'IALOMITA': 'Іаліза',
  'IASI': 'IASI',
  'MEHEDINTI': 'Мехедінті',
  'MURES': 'Змішування',
  'NEAMT': 'Нанівець',
  'OLT': 'Olt',
  'PRAHOVA': 'Права',
  'SALAJ': 'Салай',
  'SATU MARE': 'Satu mare',
  'SIBIU': 'Сибіу',
  'SUCEAVA': 'Сучава',
  'TELEORMAN': 'Телеорман',
  'TIMIS': 'Тими',
  'TULCEA': 'Тулцея',
  'VASLUI': 'Васлюй',
  'VILCEA': 'Вільцея',
  'VRANCEA': 'Вранка',
  'CALARASI': 'Кальарасі',
  'GIURGIU': 'Джургіу',
  'ANGELES': 'Анджелес',
  'BACOLOD': 'Балод',
  'BAGO': 'Банова',
  'BAGUIO': 'Багуйо',
  'BAIS': 'Бейс',
  'BASILAN CITY': 'Базиланський місто',
  'BATANGAS CITY': 'Батангас -Сіті',
  'BUTUAN': 'Бутуан',
  'CABANATUAN': 'Кабанатуан',
  'CADIZ': 'Кадіз',
  'CAGAYAN DE ORO': 'Cagayan de Oro',
  'CALBAYOG': 'Кальбайог',
  'CALOOCAN': 'Калокан',
  'CANLAON': 'Канлаон',
  'CAVITE CITY': 'Місто Кавіт',
  'CEBU CITY': 'Місто Себу',
  'COTABATO': 'Котабато',
  'DAGUPAN': 'Дагупан',
  'DANAO': 'Данао',
  'DAPITAN': 'Дапітан',
  'DAVAO DITY': 'Давао',
  'DIPOLOG': 'Диполог',
  'DUMAGUETE': 'Дамагет',
  'GENERAL SANTOS': 'Генерал Сантос',
  'GINGOOG': 'Гінгуг',
  'ILIGAN': 'Іліган',
  'ILOILO CITY': 'Місто Ілоїло',
  'IRIGA': 'Іріга',
  'LA CARLOTA': 'Ла Карлота',
  'LAOAG': 'Лаоаг',
  'LAPU-LAPU': 'Лапу-Лапу',
  'LEGAZPI': 'Легазпі',
  'LIPA': 'Ліпа',
  'LUCENA': 'Лукена',
  'MANDAUE': 'Мандауе',
  'MANILA': 'Маніла',
  'MARAWI': 'Мараві',
  'NAGA': 'Нога',
  'OLONGAPO': 'Олонгапо',
  'ORMOC': 'Ормок',
  'OROQUIETA': 'Oroquieta',
  'OZAMIZ': 'Озаміз',
  'PAGADIAN': 'Ясновидний',
  'PALAYAN': 'Палаян',
  'PASAY': 'Паса',
  'PUERTO PRINCESA': 'Пуерто -Прінцеса',
  'QUEZON CITY': 'Квезон -Сіті',
  'ROXAS': 'Роксас',
  'SAN CARLOS, NEGROS OCCIDENTAL': 'Сан -Карлос, Негрос Окцидент',
  'SAN CARLOS, PANGASINAN': 'Сан -Карлос, Пангасінан',
  'SAN JOSE': 'Сан -Хосе',
  'SAN PABLO': 'Сан -Пабло',
  'SILAY': 'Сильний',
  'SURIGAO': 'Сурігао',
  'TACLOBAN': 'Таклобан',
  'TAGAYTAY': 'Тагайтай',
  'TAGBILARAN': 'Тагбіларан',
  'TANGUB': 'Тупашка',
  'TOLEDO': 'Толедо',
  'TRECE MARTIRES': 'Trece Martires',
  'ZAMBOANGA': 'Замбоанга',
  'ABRA': 'Афра',
  'AGUSAN DEL NORTE': 'Агусан дель Норте',
  'AGUSAN DEL SUR': 'Агусан дель Сур',
  'AKLAN': 'Аклан',
  'ALBAY': 'Альбей',
  'ANTIQUE': 'Антикварний',
  'BATAAN': 'Батаан',
  'BATANES': 'Батани',
  'BATANGAS': 'Батангас',
  'BENGUET': 'Бенгет',
  'BOHOL': 'Бохол',
  'BUKIDNON': 'Букіднон',
  'BULACAN': 'Булакан',
  'CAGAYAN': 'Кагаян',
  'CAMARINES NORTE': 'Камаріни Норте',
  'CAMARINES SUR': 'Camarines sur',
  'CAMIGUIN': 'Камгуїн',
  'CAPIZ': 'Кепіз',
  'CATANDUANES': 'Катандуани',
  'CAVITE': 'Кавіт',
  'CEBU': 'Себу',
  'BASILAN': 'Базілан',
  'EASTERN SAMAR': 'Східний Самар',
  'DAVAO': 'Давао',
  'DAVAO DEL SUR': 'Давао дель Сур',
  'DAVAO ORIENTAL': 'Давао східний',
  'IFUGAO': 'Іфугао',
  'ILOCOS NORTE': 'Ilocos norte',
  'ILOCOS SUR': 'Ilocos sur',
  'ILOILO': 'Ілоїло',
  'ISABELA': 'Ізабела',
  'KALINGA-APAYAO': 'Калінга-Апаяо',
  'LAGUNA': 'Лагуна',
  'LANAO DEL NORTE': 'Ланао дель Норте',
  'LANAO DEL SUR': 'Ланао дель Сур',
  'LA UNION': 'La Union',
  'LEYTE': 'Лейт',
  'MARINDUQUE': 'Марінгук',
  'MASBATE': 'Масбат',
  'MINDORO OCCIDENTAL': 'Mindoro Occidental',
  'MINDORO ORIENTAL': 'Міндоро східний',
  'MISAMIS OCCIDENTAL': 'Misamis Occidental',
  'MISAMIS ORIENTAL': 'Місаміс східний',
  'NEGROS OCCIDENTAL': 'Негри',
  'NEGROS ORIENTAL': 'Негроси східні',
  'NUEVA ECIJA': 'Nueva Ecija',
  'NUEVA VIZCAYA': 'Нуева Візкая',
  'PALAWAN': 'Палаван',
  'PAMPANGA': 'Памганга',
  'PANGASINAN': 'Пангасинан',
  'QUEZON': 'Кесон',
  'RIZAL': 'Різаль',
  'ROMBLON': 'Ромблон',
  'SAMAR': 'Самар',
  'MAGUINDANAO': 'Магуінданао',
  'NORTH COTABATO': 'Північний Котабато',
  'SORSOGON': 'Сорсогон',
  'SOUTHERN LEYTE': 'Південний Лейт',
  'SULU': 'Сулу',
  'SURIGAO DEL NORTE': 'Сурігао дель Норте',
  'SURIGAO DEL SUR': 'Сурігао дель Сур',
  'TARLAC': 'Тарлак',
  'ZAMBALES': 'Замбалес',
  'ZAMBOANGA DEL NORTE': 'Замбоанга -дель -Норте',
  'ZAMBOANGA DEL SUR': 'Замбоанга -дель -Сур',
  'NORTHERN SAMAR': 'Північний Самар',
  'QUIRINO': 'Квіріно',
  'SIQUIJOR': 'Сікійор',
  'SOUTH COTABATO': 'Південний Котабато',
  'SULTAN KUDARAT': 'Султан Кударат',
  'TAWITAWI': 'Tawitawi',
  'ADYGEY': 'Adygey',
  'ASHKORTOSTAN': 'Ашкортостан',
  'URYAT': 'Уріат',
  'HECHEN': 'Гехен',
  'HUVASH': 'Хуваш',
  'AGESTAN': 'Агент',
  'ORNIY ALTAY': 'Орні Алтай',
  'NGUSH': 'Нюш',
  'ABARDIN-BALKAR': 'Абардін-Балкар',
  'ALMYKIYA-KHALMG TANGCH': 'Almykiya-Khalmg Tangch',
  'ARACHAY-CHERKESS': 'Арахай-Черкес',
  'ARELIAN': 'Арельський',
  'HAKASS': 'Хакас',
  'OMI': 'Омі',
  'ARIY EL': 'Ariy el',
  'ORDOVIAN': 'Ордовіан',
  'ORTH OSETIAN': 'Орт Осетян',
  'AKHA (YAKUT)': 'Аха (якут)',
  'ATARSTAN': 'Атарстан',
  'YVA': 'Іва',
  'DMURT': 'Дмурт',
  'LTAY': 'Ltay',
  'HABAROVSK': 'Хабаровський',
  'RASNODAR': 'Раснодар',
  'RASNOYARSK': 'Расноярск',
  'RIMORSKIY': 'Риморський',
  'TAVROPOL': 'Таврополь',
  'MUR': 'Мур',
  'RKANGELSK': 'Rkangelsk',
  'STRAKHAN': 'Стахан',
  'ELGOROD': 'Ельгород',
  'RYANSK': 'Рянськ',
  'HELYABINSK': 'Гелябінськ',
  'HITA': 'Хіт',
  'RKUTSK': 'Rkutsk',
  'VANOVO': 'Vanovo',
  'ALININGRAD': 'Алінінрад',
  'ALUGA': 'Алуга',
  'AMCHATKA': 'Амчатка',
  'EMEROVO': 'Емерано',
  'IROV': 'Іров',
  'OSTROMA': 'Острома',
  'URGAN': 'Урган',
  'URSK': 'Урський',
  'IPETSK': 'Іпет',
  'AGADAN': 'Агадан',
  'OSCOW': 'Осін',
  'URMANSK': 'Урманськ',
  'IZHNIY NOVGOROD': 'Izhniy novgorod',
  'OVGOROD': 'Овгород',
  'OVOSIBIRSK': 'Яйцебірський',
  'SK': 'SK',
  'RENBURG': 'Ренбург',
  'RYOL': 'Райол',
  'ENZA': 'Енца',
  'ERM': 'Ерм',
  'SKOV': 'Сов',
  'OSTOV': 'Остова',
  'YAZAN': 'Язан',
  'AKHALIN': 'Ахалін',
  'AMARA': 'Амара',
  'ARATOV': 'Аратонов',
  'MOLENSK': 'Молленський',
  'T. PETERSBURGA': 'Т. Петербурга',
  'AMBOV': 'Амков',
  'OMSK': 'Омський',
  'SARITSYN (VOLGOGRAD)': 'Сарітін (Волгоград)',
  'AUSTRALIAN CAPITAL TERRITORY': 'Австралійська столична територія',
  'NEW SOUTH WALES': 'НОВИЙ ПІВДЕННИЙ УЕЛЬС',
  'NORTHERN TERRITORY': 'Північна територія',
  'QUEENSLAND': 'Квінсленд',
  'SOUTH AUSTRALIA': 'Південна Австралія',
  'TASMANIA': 'Тасманія',
  'VICTORIA': 'Вікторія',
  'WESTERN AUSTRALIA': 'Західна Австралія',
  'BURGENLAND': 'Бургенленд',
  'KARNTEN': 'Карнтен',
  'NIEDEROSTERREICH': 'Нідеростеррейх',
  'OBEROSTERREICH': 'Oberosterreich',
  'SALZBURG': 'Зальцбург',
  'STEIERMARK': 'Стеєрмарк',
  'TIROL': 'Тирол',
  'VORARLBERG': 'Ворарлберг',
  'WIEN': 'Зліти',
  'AL HADD': 'Аль Хадд',
  'AL MANAMAH': 'Аль -Манама',
  'AL MUHARRAQ': 'Al Muharraq',
  'AR RIFA ASH SHARQI': 'AR RIFA ASH SHARQI',
  'JIDD HAFS': 'Jidd hafs',
  'SITRAH': 'Ситра',
  'CHRIST CHURCH': 'Церква Христа',
  'SAINT JOSEPH': 'Святий Йосиф',
  'SAINT LUCY': 'Сент -Люсі',
  'SAINT MICHAEL': 'Святий Майкл',
  'SAINT PHILP': 'Сент -Філп',
  'CHOBE': 'Чаша',
  'GHANZI': 'Ганзі',
  'KGALAGADI': 'Кгалагаді',
  'KGATLENG': 'KGATLENG',
  'KWENENG': 'Квененг',
  'NGAMILAND': 'Нгаміланд',
  'NORTH-EAST': 'Північний схід',
  'SOUTH-EAST': 'Південний схід',
  'DEVONSHIRE': 'Девонширський',
  'HAMILTON': 'Гамільтон',
  'PAGET': 'Пейзаж',
  'PEMBROKE': 'Пемброк',
  'SANDYS': 'Піщані',
  'SMITHS': 'Сміти',
  'SOUTHAMPTON': 'Саутгемптон',
  'WARWICK': 'Уорсвік',
  'ANTWERPEN': 'Антверпен',
  'BRABANT': 'Брабант',
  'HAINAUT': 'Хайнаут',
  'LIEGE': 'Ланж',
  'NAMUR': 'Намур',
  'OOST-VLAANDEREN': 'Oost-vlaanderen',
  'WEST-VLAANDEREN': 'Захід-Влаанден',
  'ABACO': 'Абако',
  'ACKLINS ISLAND': 'Острів Акліна',
  'ANDROS ISLAND': 'Острів Андрос',
  'BERRY ISLANDS': 'Ягідні острови',
  'BIMINIS': 'Бімініс',
  'CAT ISLAND': 'Кіт -острів',
  'CAY LOBOS': 'Кей Лобос',
  'CROOKED ISLAND': 'Кривий острів',
  'ELEUTHERA': 'Елеутер',
  'EXUMA': 'Exuma',
  'GRAND BAHAMA': 'Великий Багама',
  'HARBOUR ISLAND': 'Гавань',
  'INAGUA': 'Інагуа',
  'LONG CAY': 'Довгий Кей',
  'LONG ISLAND': 'ДОВГИЙ ОСТРІВ',
  'MAYAGUANA': 'Майяана',
  'NEW PROVIDENCE': 'Нове провидіння',
  'RAGGED ISLAND': 'Рваний острів',
  'RUM CAY': 'Ром Кей',
  'SAN SALVADOR': 'Сан -Сальвадор',
  'SPANISH WELLS': 'Іспанські свердловини',
  'BARISAL': 'Барісал',
  'BOGRA': 'Богра',
  'CHITTAGONG': 'Чіттагонг',
  'BANDARBAN': 'Бандаран',
  'COMILLA': 'Коміла',
  'DACCA': 'Дакка',
  'DINAJPUR': 'Дінайпур',
  'FARIDPUR': 'Фарідпур',
  'JESSORE': 'Джессор',
  'KHULNA': 'Хулна',
  'KUSHTIA': 'Куштія',
  'MYMENSINGH': 'Mymensingh',
  'NOAKHALI': 'Ноахалі',
  'PABNA': 'Пабна',
  'PATUAKHALI': 'Патуахалі',
  'RAJSHAHI': 'Раджхахі',
  'RANGPUR': 'Рангпур',
  'SYLHET': 'Сільхет',
  'TANGAIL': 'Тупик',
  'RAMGARH': 'Рамгарх',
  'RANGAMATI': 'Рангаматі',
  'CAYO': 'Кайо',
  'COROZAL': 'Корозал',
  'ORANGE WALK': 'Апельсинова прогулянка',
  'STANN CREEK': 'Стенн Крик',
  'CHUQUISACA': 'Чукісака',
  'COCHABAMBA': 'Кочабамба',
  'EL BENI': 'Ель Бені',
  'ORURO': 'Оруро',
  'PANDO': 'Пандо',
  'POTOSI': 'Потосі',
  'TARIJA': 'Тарія',
  'ARAKAN STATE': 'Араканська держава',
  'CHIN STATE': 'Держава підборіддя',
  'IRRAWADDY': 'Ірравадді',
  'KACHIN STATE': 'Держава Качін',
  'KAREN STATE': 'Карен',
  'KAYAH STATE': 'Держава Кая',
  'MAGWE': 'Магн',
  'MANDALAY': 'Мандалай',
  'PEGU': 'Пелюг',
  'SAGAING': 'Сага',
  'SHAN STATE': 'Шан -держава',
  'TENASSERIM': 'Тенассерім',
  'MON STATE': 'Держава Мон',
  'RANGOON': 'Рангун',
  'ATAKORA': 'Атакора',
  'ATLANTIQUE': 'Атлантика',
  'BORGOU': 'Боргу',
  'MONO': 'МОНОНУКЛЕОЗ',
  'QUEME': 'QUEME',
  'ZOU': 'Zou',
  'MALAITA': 'Малаїта',
  'ACRE': 'Акр',
  'ALAGOAS': 'АЛАГОС',
  'AMAPA': 'Amapa',
  'BAHIA': 'Бахія',
  'CEARA': 'Ceara',
  'ESPIRITO SANTO': 'Espirito Santo',
  'FERNANDO DE NORONHA': 'Фернандо де Норонха',
  'GOIAS': 'Горії',
  'MATO GROSSO DO SUL': 'Mato grosso do sul',
  'MARANHAO': 'Маранхао',
  'MATO GROSSO': 'Мато Гроссо',
  'MINAS GERAIS': 'Minas gerais',
  'PARAIBA': 'Парайба',
  'PARANA': 'Парана',
  'PERNAMBUCO': 'Пернамбуко',
  'PIAUI': 'Піауї',
  'RIO DE JANEIRO': 'РІО-ДЕ-ЖАНЕЙРО',
  'RIO GRANDE DO NORTE': 'Rio Grande do Norte',
  'RIO GRANDE DO SUL': 'Rio Grande do Sul',
  'RONDONIA': 'Рондонія',
  'RORAIMA': 'Рораїма',
  'SANTA CATARINA': 'Санта -Катаріна',
  'SAO PAULO': 'САН-ПАУЛУ',
  'SERGIPE': 'Сергіпе',
  'CENTRAL BHUTAN': 'Центральний Бутан',
  'EASTERN BHUTAN': 'Східний Бутан',
  'SOUTHERN BHUTAN': 'Південний Бутан',
  'WESTERN BHUTAN': 'Західний Бутан',
  'BLAGOEVGRAD': 'Blagoevgrad',
  'BURGAS': 'Бургас',
  'GABROVO': 'Габрово',
  'KHASKOVO': 'Хасково',
  'KURDZHALI': 'Курджалі',
  'KYUSTENDIL': 'Кюстенділ',
  'LOVECH': 'Любити',
  'MIKHAYLOVGRAD': 'Mikhaylovgrad',
  'PAZARDZHIK': 'Пазарджик',
  'PERNIK': 'Пернік',
  'PLEVEN': 'Пільвен',
  'PLOVDIV': 'Плівдів',
  'RAZGRAD': 'Razgrad',
  'RUSE': 'Розгублювати',
  'SHUMEN': 'Ворог',
  'SILISTRA': 'Силова',
  'SLIVEN': 'Слизький',
  'SMOLYAN': 'Смолян',
  'SOFIYA': 'Софія',
  'GRAD SOFIYA': 'Град Софія',
  'STARA ZAGORA': 'Stara zagora',
  'TOLBUKHIN': 'Толбухін',
  'TURGOVISHTE': 'Турговішт',
  'VARNA': 'Варна',
  'VELIKO TURNOVO': 'Веліко Тервово',
  'VIDIN': 'Відін',
  'VRATSA': 'Враца',
  'YAMBOL': 'Ямбол',
  'BELAIT': 'Бевейт',
  'BRUNEI AND MUARA': 'Бруней і Муара',
  'TEMBURONG': 'Тембуронг',
  'TUTONG': 'Тугун',
  'BUBANZA': 'Бубанза',
  'BURURI': 'Буюр',
  'CANKUZO': 'Канкузо',
  'CIBITOKE': 'Цибітоке',
  'KAZURI': 'Казурі',
  'KAYANZA': 'Каянза',
  'KIRUNDO': 'Кірундо',
  'MAKAMBO': 'Макамбо',
  'MUYINGA': 'Муїнга',
  'NGOZI': 'Ногозі',
  'RUTANA': 'Рутана',
  'ALBERTA': 'Альберта',
  'BRITISH COLUMBIA': 'БРИТАНСЬКА КОЛУМБІЯ',
  'MANITOBA': 'Манітоба',
  'NEW BRUNSWICK': 'Нью -Брансвік',
  'NEWFOUNDLAND': 'Ньюфаундленд',
  'NORTHWEST TERRITORIES': 'Північно -Західні території',
  'NOVA SCOTIA': 'НОВА ШОТЛАНДІЯ',
  'ONTARIO': 'Онтаріо',
  'PRINCE EDWARD ISLAND': 'Острів принца Едварда',
  'QUEBEC': 'Квебек',
  'SASKATCHEWAN': 'Саскачеван',
  'YUKON TERRITORY': 'Територія Юкон',
  'BATDAMBAND': 'Батдамбанд',
  'KAMPONG CHAM': 'Кампонг Чам',
  'KAMPONG CHHNANG': 'Кампонг Чханг',
  'KAMPONG SPOE': 'Kampong Spoe',
  'KAMPONG THUM': 'Кампонг Тум',
  'KAMPOT': 'Кампот',
  'KANDAL': 'Kandal',
  'KAOH KONG': 'Kaoh Kong',
  'KRACHEH': 'Краче',
  'MONDOL KIRI': 'Мондол Кірі',
  'PHNUM PENH': 'Phnum penh',
  'POUTHISAT': 'Путісат',
  'PREAH VIHEAR': 'Preah vihear',
  'PREY VENG': 'Здобич Венг',
  'ROTANOKIRI': 'Ротанокірі',
  'SIEMREAB-OTDAR MEANCHEY': 'Siemreab-Otdar Meanchey',
  'STOENG TRENG': 'Stoeng Treng',
  'SVAY RIENG': 'Свей Ріенг',
  'TAKEV': 'Потяг',
  'BATHA': 'Бата',
  'BILTINE': 'Двоясний',
  'BORKOU-ENNEDI-TIBESTI': 'Borkou-ennedi-tibesti',
  'CHARI-BAGUIRMI': 'Chari-Baguirmi',
  'GUERA': 'Гурт',
  'KANEM': 'Канем',
  'LAC': 'Лак',
  'LOGONE OCCIDENTAL': 'Logone Occidental',
  'LOGONE ORIENTAL': 'Логон східний',
  'MAYO KEBI': 'Кебі Майо',
  'MOYEN-CHARI': 'Мойен-Харрі',
  'QUADDAI': 'QUADAI',
  'SALAMAT': 'Саламат',
  'TANDJILE': 'Танджиле',
  'AMPARAI': 'Ампаї',
  'ANURADHAPURA': 'Анурадхапура',
  'BADULLA': 'Бадулла',
  'BATTICALOA': 'Баттікалоа',
  'GALLE': 'Галле',
  'HAMBANTOTA': 'Гамбантота',
  'KALUTARA': 'Калура',
  'KANDY': 'Kandy',
  'KEGALLA': 'Кегалла',
  'KURUNEGALA': 'Курунегала',
  'MATALE': 'Маталь',
  'MATARA': 'Матара',
  'MONERAGALA': 'Монагала',
  'NUWARA ELIYA': 'Nuwara Eliya',
  'POLONNARUWA': 'Polonnaruwa',
  'PUTTALAM': 'Путталам',
  'RATNAPURA': 'Ратнапура',
  'TRINCOMALEE': 'Трінкомалі',
  'COLOMBO': 'Коломбо',
  'GAMPAHA': 'Гампаха',
  'JAFFNA': 'Яфна',
  'MANNAR': 'Маннар',
  'MULLAITTIVU': 'Mullaittivu',
  'VAVUNIYA': 'Вавунія',
  'BOUENZA': 'Буенза',
  'CUVETTE': 'Кювет',
  'KOUILOU': 'Кулуло',
  'LEKOUMOU': 'Лекуму',
  'LIKOUALA': 'Лікувала',
  'NIARI': 'Ніарі',
  'PLATEAUX': 'Плато',
  'POOL': 'Басейн',
  'BANDUNDU': 'Бандаж',
  'EQUATEUR': 'Рівномірний',
  'KASAI-OCCIDENTAL': 'Касай-ОКСИЦІЗНАЛЬНИЙ',
  'KASAI-ORIENTAL': 'Касай-орієнт',
  'SHABA': 'Шаба',
  'KINSHASA': 'Кіншаса',
  'KIVU': 'Ківу',
  'BAS-ZAIRE': 'Батонце',
  'HAUT-ZAIRE': 'Haut-Zaire',
  'SANGHA': 'Сангха',
  'ANHUI': 'Анхуй',
  'ZHEJIANG': 'Чжецзян',
  'JIANGXI': 'Цвангксі',
  'JIANGSU': 'Цзянсу',
  'JILIN': 'Джилін',
  'QINGHAI': 'Цинхай',
  'FUJIAN': 'Фуджіян',
  'HEILONGJIANG': 'Heilongjiang',
  'HENAN': 'Хенан',
  'HEBEI': 'Hebei',
  'HUNAN': 'Хун',
  'HUBEI': 'Хубей',
  'XINJIANG': 'Xinjiang',
  'XIJANG': 'Xijang',
  'GANSU': 'Гансу',
  'GUANGXI': 'Гуансі',
  'GUANGDONG': 'Гуандун',
  'GUIZHOU': 'Гуджжоу',
  'LIAONING': 'Поєднання',
  'NEI MONGOL': 'Nei Mongol',
  'NINGXIA': 'Нінксія',
  'BEIJING': 'Пекін',
  'SHANGHAI': 'Шанхай',
  'SHANXI': 'Шансі',
  'SHANDONG': 'Шаньдуг',
  'SHAANXI': 'Шансі',
  'SICHUAN': 'Сичуан',
  'TIANJIN': 'Тяньцзінь',
  'YUNNAN': 'Юньнань',
  'VALPARAISO': 'Вальпараїсо',
  'AISEN DEL GENERAL CARLOS IBANEZ DEL CAMPO': 'Aisen del General Carlos Ibanez del Campo',
  'ANTOFAGASTA': 'Антофагаста',
  'ARUCANIA': 'Аруканія',
  'ATACAMA': 'Атакама',
  'BIOBIO': 'Біобіо',
  'COQUIMBO': 'Кокімбо',
  "LIBERTADOR GENERAL BERNARDO O'HIGGINS": 'Генерал Лібертадор Бернардо О\'Гіггінс',
  'LOS LAGOS': 'Лос -Лагос',
  'MAGALLANES Y ANTARTICA CHILENA': 'Magallanes y Antartica Chilena',
  'MAULE': 'Моле',
  'REGION METROPOLITANA': 'Регіон Метрополітана',
  'TARAPACA': 'Тарапака',
  'CREEK': 'Крик',
  'MIDLAND': 'Мідленд',
  'SOUTH TOWN': 'Південне місто',
  'SPOT BAY': 'Пляма',
  'STAKE BAY': 'Затока',
  'WEST END': 'УЕСТ-ЕНД',
  'CENTRE-SUD': 'Центри',
  'EST': 'Est',
  'LITTORAL': 'Пристосований',
  'NORD-QUEST': 'Наркоман',
  'QUEST': 'Пошук',
  'SUD-QUEST': 'Суд-Кест',
  'ANTIOQUIA': 'Антиокія',
  'ARAUCA': 'Араука',
  'ATLANTICO': 'Атлантико',
  'CAQUETA': 'Какета',
  'CAUCA': 'Кайка',
  'CESAR': 'Цезар',
  'CHOCO': 'Шоколад',
  'GUAVIARE': 'Гуавіар',
  'GUAINIA': 'Гуайнія',
  'LA GUAJIRA': 'La Guajira',
  'META': 'Мета',
  'NARINO': 'Наріно',
  'NORTE DE SANTANDER': 'Норте де Сантандер',
  'PUTUMAYO': 'Putumayo',
  'QUINDIO': 'Квіндіо',
  'RISARALDA': 'Рісаральда',
  'SAN ANDREAS Y PROVIDENCIA': 'San Andreas y Providencia',
  'SANTANDER': 'Сантандер',
  'SUCRE': 'Сукре',
  'TOLIMA': 'Толіма',
  'VALLE DEL CAUCA': 'Валле -дель -Каука',
  'VAUPES': 'Вариво',
  'VICHADA': 'Вічада',
  'CASANARE': 'Казанаре',
  'CUNDINAMARCA': 'Кундінамарка',
  'BOLIVAR': 'Болівар',
  'BOYACA': 'Бояака',
  'CALDAS': 'Кальда',
  'MAGDALENA': 'Магдалена',
  'ALAJUELA': 'Аладжуела',
  'CARTAGO': 'Картаго',
  'GUANACASTE': 'Гуанакаст',
  'HEREDIA': 'Ередія',
  'LIMON': 'Лімон',
  'PUNTARENAS': 'Пунтарени',
  'BAMINGUI-BANGORAN': 'Бамінгі-Бангоран',
  'BASSE-KOTTO': 'Бассе-Котто',
  'HAUTE-KOTTO': 'Високий котто',
  'HAUTE-SANGHA': 'Висота',
  'HAUT-MBOMOU': 'Haut-mbomou',
  'KEMO-GRIBINGUI': 'Kemo-Gribingi',
  'LOBAYE': 'Лобай',
  'MBOMOU': 'Mbomou',
  'NANA-MAMBERE': 'Нана-Мамбер',
  'OMBELLA-MPOKO': 'Омбелла-Мпоко',
  'QUAKA': 'QUAKA',
  'OUHAM': 'Оухам',
  'OUHAM-PENDE': 'Outham-Pende',
  'VAKAGA': 'Вакага',
  'GRIBINGUI': 'Грибінгі',
  'PINAR DEL RIO': 'Пінар дель Ріо',
  'LA HABANA': 'Ла -Хабана',
  'MATANZAS': 'Матанзас',
  'ISLA DE LA JUVENTUD': 'Ісла де ла Ювентуд',
  'CAMAGUEY': 'Камагей',
  'CIEGO DE AVILA': 'Ciego de Avila',
  'CIENFUEGOS': 'Cienfuegos',
  'GRANMA': 'Бак',
  'GUANTANAMO': 'Гуантанамо',
  'HOLGUIN': 'Гольгуїн',
  'LAS TUNAS': 'Лас -туни',
  'SANCTI SPIRITUS': 'Sancti Spiritus',
  'SANTIAGO DE CUBA': 'Сантьяго де Куба',
  'VILLA CLARA': 'Вілла Клара',
  'BOA VISTA': 'Boa Vista',
  'BRAVA': 'Брава',
  'FOGO': 'Фого',
  'MAIO': 'Мейо',
  'PAUL': 'Павло',
  'PRAIA': 'Прая',
  'RIBEIRA GRANDE': 'Рібейра Гранде',
  'SAL': 'Салат',
  'SAO NICOLAU': 'Сао Ніколау',
  'SAO VICENTE': 'Сао -Вісенте',
  'TARRAFAL': 'Таррафал',
  'FAMAGUSTA': 'Фамагуста',
  'KYRENIA': 'Кіренія',
  'LARNACA': 'Ларнака',
  'NICOSIA': 'Нікосія',
  'LIMASSOL': 'Лімасол',
  'PAPHOS': 'Пайос',
  'ARHUS': 'Архус',
  'BORNHOLM': 'Борнгольм',
  'FREDERIKSBORG': 'Фредеріксборг',
  'FYN': 'Фій',
  'KOBENHAVN': 'Кобенхавн',
  'STANDEN KOBENHAVN': 'Стоїть Кобенхавн',
  'NORDJYLLAND': 'Nordjylland',
  'RIBE': 'Ребро',
  'RINGKOBING': 'Рингкобінг',
  'ROSKILDE': 'Роклд',
  'SONDERJYLLAND': 'Sonderjylland',
  'STORSTROM': 'Накопичувач',
  'VEJLE': 'Ойле',
  'VESTSJAELLAND': 'Жилетка',
  'VIBORG': 'Вібірг',
  'ALI SABIH': 'Алі Сабіх',
  'DIKHIL': 'Дихіл',
  'OBOCK': 'Око',
  'TADJOURA': 'Таджура',
  'SAINT DAVID': 'Святий Давид',
  'SAINT LUKE': 'Святий Лука',
  'SAINT MARK': 'Святий Марк',
  'SAINT PATRICK': 'Святий Патрік',
  'AZUA': 'Азуа',
  'BAORUCO': 'Баоруко',
  'BARAHONA': 'Брахона',
  'DAJABON': 'Джабон',
  'DISTRITO NACIONAL': 'Розповсюджувати',
  'DUARTE': 'Дуарт',
  'EL SEIBO': 'Ель -Сейбо',
  'ESPAILLAT': 'Еспайлат',
  'INDEPENDENCIA': 'Незалежність',
  'LA ALTAGRACIA': 'La altagracia',
  'ELIAS PINA': 'Еліас Піна',
  'LA ROMANA': 'Ла Романа',
  'LA VEGA': 'La Vega',
  'MARIA TRINIDAD SANCHEZ': 'Марія Тринідад Санчес',
  'MONTE CRISTI': 'Монте -Крісті',
  'PEDERNALES': 'Педерналес',
  'PERAVIA': 'Перавія',
  'PUERTO PLATA': 'Пуерто -Плата',
  'SALCEDO': 'Сальдо',
  'SAMANA': 'Самана',
  'SANCHEZ RAMIREZ': 'Санчес Рамірес',
  'SAN CRISTOBAL': 'Сан -Крістобаль',
  'SAN PEDRO DE MACORIS': 'Сан -Педро де Макоріс',
  'SANTIAGO': 'Сантьяго',
  'SANTIAGO RODRIGUEZ': 'Сантьяго Родрігес',
  'VALVERDE': 'Валверде',
  'GALAPAGOS': 'Галапагос',
  'AZUAY': 'Азуай',
  'CANAR': 'Канар',
  'CARCHI': 'Карчі',
  'CHIMBORAZO': 'Чімборазо',
  'COTOPAXI': 'Котопаксі',
  'EL ORO': 'Ель Оро',
  'ESMERALDAS': 'Есмеральдас',
  'GUAYAS': 'Гуаї',
  'IMBABURA': 'Імбабура',
  'LOJA': 'Ложа',
  'LOS RIOS': 'Лос -Ріос',
  'MANABI': 'Манабі',
  'MORONA-SANTIAGO': 'Морона-Сантьяго',
  'NAPO': 'Напо',
  'PASTAZA': 'Пастаза',
  'PICHINCHA': 'Пічінча',
  'TUNGURAHUA': 'Тунгухуа',
  'ZAMORA-CHINCHIPE': 'Замора-Чінхіпе',
  'AD DAQAHLIYAH': 'Ad daqahliyah',
  'AL BAHR AL AHMAR': 'Аль Бахр Аль Ахмар',
  'AL BUHAYRAH': 'Аль Бухайра',
  'AL FAYYUM': 'Аль Файум',
  'AL GHARBIYAH': 'Al Gharbiyah',
  'AL ISKANDARIYAH': 'Аль Іскандарія',
  'AL ISMA ILIYAH': 'Аль Ісма Ілія',
  'AL JIZAH': 'Аль -джизах',
  'AL MINUFIYAH': 'Аль Мінуфія',
  'AL MINYA': 'Аль Мінія',
  'AL QAHIRAH': 'Аль -Кахіра',
  'AL QALYUBIYAH': 'Al Qalyubiyah',
  'AL WADI AL JADID': 'Аль Ваді Аль Джадід',
  'ASH SHARQIYAH': 'Попіл Шаркія',
  'AS SUWAYS': 'Як Сууей',
  'ASWAN': 'Апетит',
  'ASYUT': 'Асьут',
  'BANI SUWAYF': 'Бані Суейф',
  'BUR SA ID': 'Bur sa id',
  'DUMYAT': 'Дюмат',
  'DFR ASH SHAYKH': 'DFR ASH SHAYKH',
  'MARSA MATRUH': 'Марса Матрух',
  'QINA': 'Qina',
  'SUHAJ': 'Сухая',
  'SINA': 'Сина',
  'CARLOW': 'Карли',
  'CAVAN': 'Кваб',
  'CLARE': 'Клацання',
  'CORK': 'Пробка',
  'DONEGAL': 'Донегал',
  'DUBLIN': 'Дублін',
  'GALWAY': 'Голуб',
  'KERRY': 'Керрі',
  'KILDARE': 'Кілдаре',
  'KILKENNY': 'Кілкенні',
  'LEITRIM': 'Лейтрім',
  'LAOIS': 'Лаоа',
  'LIMERICK': 'Лімерик',
  'LONGFORD': 'Лонгфорд',
  'LOUTH': 'Лайк',
  'MAYO': 'Майо',
  'MEATH': 'Мізер',
  'MONAGHAN': 'Монаган',
  'OFFALY': 'Офалій',
  'ROSCOMMON': 'Роскоммон',
  'SLIGO': 'Сліго',
  'TIPPERARY': 'Типкер',
  'WATERFORD': 'Водний',
  'WESTMEATH': 'Вестміт',
  'WEXFORD': 'Вексфорд',
  'WICKLOW': 'Шнурка',
  'BIOKO': 'Біоко',
  'RIO MUNI': 'Ріо Муні',
  'AHUACHAPAN': 'Ахуачапан',
  'CABANAS': 'Кабани',
  'CHALATENANGO': 'Халатенанго',
  'CUSCATLAN': 'Кускатлан',
  'MORAZAN': 'Мозан',
  'SAN MIGUEL': 'Сан -Мігель',
  'SANTA ANA': 'Санта -Ана',
  'SAN VICENTE': 'Сан -Вісенте',
  'SONSONATE': 'Синосонат',
  'USULUTAN': 'Усулутан',
  'ARSI': 'Арсі',
  'GONDER': 'Гондером',
  'BALE': 'Тюк',
  'GAMO GOFA': 'Gamo gofa',
  'GOJAM': 'Годжам',
  'HARERGE': 'Гареред',
  'ILUBABOR': 'Ilubabor',
  'KEFA': 'Кефа',
  'SHEWA': 'Шева',
  'SIDAMO': 'Сидамо',
  'TIGRAY': 'Тигр',
  'WELEGA': 'Урега',
  'WELO': 'Вело',
  'AHVENANMAA': 'Ахвенанмаа',
  'HAME': 'Хаме',
  'KESKI-SUOMI': 'Кескі-Суомі',
  'KUOPIO': 'Куопіо',
  'KYMI': 'Кайма',
  'LAPPI': 'Лаппі',
  'MIKKELI': 'Міккелі',
  'OULU': 'Олу',
  'POHJOIS-KARJALA': 'Pohjois-Карджала',
  'TURKU JA PORI': 'Турку Джа Порі',
  'UUSIMAA': 'Uusimaa',
  'VAASA': 'Вааса',
  'ROTUMA': 'Ротума',
  'AIN': 'Аїн',
  'AISNE': 'Айсне',
  'ALLIER': 'Мульє',
  'ALPES-MARITIMES': 'Alpes Maritimes',
  'ARDECHE': 'Артиче',
  'ARDENNES': 'Арденни',
  'ARIEGE': 'Обрізати',
  'AUBE': 'Аубе',
  'AUDE': 'Ауда',
  'AVEYRON': 'Aveyron',
  'BAS-RHIN': 'Батонце',
  'ALPES-DE-HAUTE-PROVENCE': 'Alpes-de-Haute-Provence',
  'PYRENEES-ANTLANTIQUES': 'Pyrenees-Antlantiques',
  'TERRITOIRE DE BELFORT': 'Territoire de Belfort',
  'BOUCHES-DU-RHONE': 'Bouches-du-Rhone',
  'CALVADOS': 'Кальвадос',
  'ULA': 'Ула',
  'VER': 'Вершина',
  'YUMEN': 'Юмен',
  'LYANOVSK': 'Ліановський',
  'LADIMIR': 'Ладімір',
  'OLOGDA': 'Ологда',
  'ORONEZH': 'Оронеж',
  'AROSLAVL': 'Арославла',
  'EKATERINBURG': 'Екатеринбург',
  'GINSKIY BURYAT': 'Ginskiy buryat',
  'HUKOTKA': 'Хукотка',
  'VENKIYSKIY': 'Венкійський',
  'HANTY-MANSI': 'Ханті-Мансі',
  'OMI-PERMYAK': 'Омі-Пермак',
  'ORYAK': 'Оряк',
  'ENETS': 'Енети',
  'AYMYRSKIY': 'Аймірський',
  'ST-ORDYNSKIY': 'Сент -ординський',
  'AMALO-NENETS': 'Амало-Ненети',
  'EVREYSKAYA': 'Evreyskaya',
  'BUTARE': 'Батаре',
  'BYUMBA': 'Баймба',
  'CYANGUGU': 'Циангугу',
  'GIKONGORO': 'Гіккоро',
  'GISENYI': 'Гісеній',
  'GITARAMA': 'Гітарама',
  'KIBUNGO': 'Кібунго',
  'KIBUYE': 'Кібує',
  'KIGALI': 'Кігалі',
  'RUHENGERI': 'Рухенгі',
  'AL BAHAH': 'Аль -Баха',
  'AL JAWF': 'Al jawf',
  'AL MADINAH': 'Аль Мадіна',
  'AL QASIM': 'Аль Касим',
  'AL QURAYYAT': 'Аль Кураят',
  'AR RIYAD': 'Ar riyad',
  'ASIR': 'Асір',
  'HAIL': 'Град',
  'MAKKAH': 'Мекка',
  'AL HUDUD ASH SHAMALIYAH': 'Al Hudud Ash Shamaliyah',
  'NAJRAN': 'Найменший',
  'JIZAN': 'Джизан',
  'NABUK': 'Набук',
  'CHRIST CHURCH NICHOLA TOWN': 'Церква Христа Нікола Таун',
  'SAINT ANNE SANDY POINT': 'Свята Енн Сенді Пойнт',
  'SAINT GEORGE BASSETERRE': 'Святий Георгій Бассетер',
  'SAINT GEORGE GINGERLAND': 'Святого Георджа Джинджерленд',
  'SAINT JAMES WINDWARD': 'Святий Джеймс Вітервард',
  'SAINT JOHN CAPISTERRE': 'Сент -Джон Капістерр',
  'SAINT JOHN FIGTREE': 'Сент -Джон Фігтрі',
  'SAINT MARY CAYON': 'Сент -Мері Кайон',
  'SAINT PAUL CAPISTERRE': 'Сент -Пол Капістерр',
  'SAINT PAUL CHARLESTOWN': 'Сент -Пол Чарлстаун',
  'SAINT PETER BASSETERRE': 'Сент -Пітер Бассетер',
  'SAINT THOMAS LOWLAND': 'Святий Томас низовина',
  'SAINT THOMAS MIDDLE ISLAND': 'Сент -Томас Середній острів',
  'TRINITY PALMETTO POINT': 'Trinity Palmetto Point',
  'CAPE PROVINCE': 'Провінція Кейп',
  'NATAL': 'Натуральний',
  'ORANGE FREE STATE': 'Помаранчева вільна держава',
  'TRANSVAAL': 'Трансвал',
  'CAP VERT': 'Шапка верт',
  'CASAMANCE': 'Касамант',
  'DIOURBEL': 'Діурбель',
  'FLEUVE': 'Флайв',
  'SENEGAL ORIENTAL': 'Сенегальний східний',
  'SINE-SALOUM': 'Синус-соум',
  'THIES': 'Міри',
  'LOUGA': 'Луга',
  'ASCENSION': 'Сходження',
  'TRISTAN DA CUNHA': 'Трістан да Кунья',
  'WESTERN AREA': 'Західна зона',
  'ACQUAVIVA': 'Акубавіва',
  'CHIESANUOVA': 'Chiesanuova',
  'DOMAGNANO': 'Домінано',
  'FAETANO': 'Фаетано',
  'FIORENTINO': 'Фіорентино',
  'BORGO MAGGIORE': 'Борго Маджоре',
  'MONTE GIARDINO': 'Монте Джардіно',
  'SERRAVALLE': 'Серравалл',
  'BAKOOL': 'Бакол',
  'BANAADIR': 'Баноадір',
  'BARI': 'Барі',
  'BAY': 'ЗАТОКА',
  'GALGUDUUD': 'Галгудуда',
  'GEDO': 'Гедо',
  'HIIRAAN': 'Hiiraan',
  'JUBBADA DHEXE': 'Jubbada dhexe',
  'JUBBADA HOOSE': 'Джуббада Хуз',
  'MUDUG': 'Мудуг',
  'NUGAAL': 'Нугаал',
  'SANAAG': 'Санаг',
  'SHABEELLAHA DHEXE': 'Shabeellaha dhexe',
  'SHABEELLAHA HOOSE': 'Shabeellaha hoose',
  'TOGDHEER': 'Переплетення',
  'WOQOOYI GALBEED': 'Woqooyi galbeed',
  'ALAVA': 'Алава',
  'ALBACETE': 'Альбакета',
  'ALICANTE': 'Аліканте',
  'ALMERIA': 'Алмерія',
  'AVILA': 'Авіла',
  'BADAJOZ': 'Бададжоз',
  'BALEARES': 'Балеарес',
  'BARCELONA': 'Барселона',
  'BURGOS': 'Бургос',
  'CACERES': 'Касер',
  'CASTELLON': 'Кастеллон',
  'CIUDAD REAL': 'Ciudad Real',
  'CORUNA': 'Коруна',
  'CUENCA': 'Куенка',
  'GERONA': 'Герона',
  'GUADALAJARA': 'Гвадалахара',
  'GUIPUZCOA': 'Гіпузкоа',
  'HUELVA': 'Хуельва',
  'HUESCA': 'Худька',
  'JAEN': 'Джин',
  'LERIDA': 'Леріда',
  'RIOJA, LA': 'Ріожа, Лос -Анджелес',
  'LUGO': 'Луго',
  'MADRID': 'Мадрид',
  'MALAGA': 'Мальага',
  'MURCIA': 'Мурсія',
  'NAVARRA': 'Наварра',
  'ORENSE': 'Порис',
  'ASTURIAS': 'Астурія',
  'PALENCIA': 'Паленсія',
  'PALMAS, LAS': 'Пальмас, Лас',
  'PONTEVEDRA': 'Понтеведра',
  'SALAMANCA': 'Саламанка',
  'SANTA CRUZ DE TENERIFE': 'Санта -Крус де Тенеріфе',
  'CANTABRIA': 'Кантабрія',
  'SEGOVIA': 'Сеговія',
  'SEVILLA': 'Севілла',
  'SORIA': 'Сорія',
  'TARRAGONA': 'Таррагона',
  'TERUEL': 'Таріл',
  'VALENCIA': 'Валенсія',
  'VALLADOLID': 'Валладолід',
  'VIZCAYA': 'Візкая',
  'ZAMORA': 'Замора',
  'ZARAGOZA': 'Зарагоса',
  'ANSE-LA-RAYE': 'Anse-la-ray',
  'DAUPHIN': 'Дофін',
  'CASTRIES': 'Кастра',
  'CHOISEUL': 'Choiseul',
  'DENNERY': 'Деннері',
  'GROS-ISLET': 'Грос-Іслет',
  'LABORIE': 'Леймей',
  'MICOUD': 'Мікуд',
  'SOUFRIERE': 'Суфрієр',
  'VIEUX-FORT': 'Vieux-fort',
  'PRASLIN': 'Праслін',
  'JUNQALI': 'Юнкалі',
  'A ALI AN NIL': 'Алі нуль',
  'AL AWSAT': 'Al Awsat',
  'AL ISTIWA I': 'Al istiwa i',
  'AL KHARTUM': 'Аль Хартум',
  'ASH SHAMALI': 'Попел Шамалі',
  'ASH SHARGI': 'Еш Шаргі',
  'BAHR AL GHAZAL': 'Бахр Аль Газал',
  'DARFUR': 'Дарфур',
  'KHURDUFAN': 'Хурдуфан',
  'ALVSBORGS LAN': 'Alvsborgs lan',
  'BLEKINGE LAN': 'БЛІНГЕ ЛАН',
  'GAVLEBORGS LAN': 'Gavleborgs lan',
  'GOTEBORGS OCH BOHUS LAN': 'Goteborgs och bohus lan',
  'GOTLANDS LAN': 'Gotlands lan',
  'HALLANDS LAN': 'Hallands Lan',
  'JAMTLANDS LAN': 'Jamtlands lan',
  'JONKOPINGS LAN': 'Jonkopings Lan',
  'KALMAR LAN': 'Калмар Лан',
  'KOPPARBERGS LAN': 'Kopparbergs lan',
  'KRISTIANSTADS LAN': 'Крістіанстад Лан',
  'KRONOBERGS LAN': 'Kronobergs lan',
  'MALMOHUS LAN': 'Мальмохус Лан',
  'NORRBOTTENS LAN': 'Lan norrbottens',
  'OREBRO LAN': 'Orebro lan',
  'OSTERGOTLANDS LAN': 'Лан Остерготландс',
  'SKARABORGS LAN': 'LAN SKARABORGS',
  'SODERMANLANDS LAN': 'LAN Sodermanlands',
  'STOCKHOLMS LAN': 'Стокгольмс Лан',
  'STOCKHOLMS STAD': 'Стокгольми Стад',
  'UPPSALA LAN': 'Uppsala lan',
  'VARMLANDS LAN': 'Локальна мережа Varmlands',
  'VASTERBOTTENS LAN': 'Vasterbottens lan',
  'VASTERNORRLANDS LAN': 'LAN Vasternorrlands',
  'VASTMANLANDS LAN': 'LAN VASTMANLANDS',
  'AL HASAKAH': 'Аль -Хасака',
  'AL LADHIQIYAH': 'Аль Ладхікія',
  'AL QUNAYTIRAH': 'Al Qunaytirah',
  'AR RAQQAH': 'Ar raqqah',
  'AS SUWAYDA': 'Як Suwayda',
  "DAR'A": 'Дар\'а',
  'DAYR AZ ZAWR': 'Дейр Аз Завр',
  'DIMASHQ': 'DIMASHQ',
  'HALAB': 'Халоб',
  'HAMAH': 'Хамах',
  'HIMS': 'Його',
  'IDLIB': 'Idlib',
  'MADINAT DIMASHQ': 'Мадінат Дімашк',
  'TARTUS': 'Тартус',
  'AARGAU': 'Ааргау',
  'AUSSER-RHODEN': 'Ausser-Rhoden',
  'BASEL-LANDSCHAFT': 'Базель-Ландшфт',
  'BASEL-STADT': 'Базель-стадіст',
  'BERN': 'Берн',
  'FRIBOURG': 'Фріборг',
  'GENEVE': 'Генев',
  'GLARUS': 'Глар',
  'GRAUBUNDEN': 'Граубунден',
  'INNER-RHODEN': 'Внутрішній-Рходен',
  'LUZERN': 'Лузерна',
  'NEUCHATEL': 'Ночатель',
  'NIDWALDEN': 'Нідвальден',
  'OBWALDEN': 'Обвальден',
  'SANKT GALLEN': 'Санкт Галлен',
  'SCHAFFHAUSEN': 'Шаффхаузен',
  'SCHWYZ': 'Швіц',
  'SOLOTHURN': 'Соротон',
  'THURGAU': 'Тургау',
  'TICINO': 'Тичіно',
  'URI': 'URI',
  'VALAIS': 'Вал',
  'VAUD': 'Хустка',
  'ZUG': 'Зміст',
  'ZURICH': 'Цюріх',
  'ABU ZABY': 'Абу Забі',
  'AJMAN': 'Айман',
  'DUBAYY': 'Дубай',
  'AL FUJAYRAH': 'Аль Фуджара',
  'RAS AL KHAYMAH': 'Рас Аль Хайма',
  'ASH SHARIQAH': 'Еш -шаріках',
  'UMM AL QAYWAYN': 'Ммм al qaywayn',
  'ARIMA': 'Арима',
  'CARONI': 'Квароні',
  'MAYARO': 'Майяро',
  'NARIVA': 'Наріва',
  'PORT-OF-SPAIN': 'Порт-Іспанія',
  'SAN FERNANDO': 'Сан -Фернандо',
  'TOBAGO': 'Тобаго',
  'MAE HONG SON': 'Мей Гонг син',
  'CHIANG MAI': 'ЧІАНГМАЙ',
  'CHIANG RAI': 'Чіанг Рай',
  'NAN': 'Нан',
  'LAMPHUN': 'Ламфон',
  'LAMPANG': 'Лампанг',
  'PHRAE': 'Фра',
  'TAK': 'Кістка',
  'SUKHOTHAI': 'Сухотай',
  'UTTARADIT': 'Uttaradit',
  'KAMPHAENG PHET': 'Кампхенг Фет',
  'PHITSANULOK': 'Фітсанулок',
  'PHICHIT': 'Фічіт',
  'PHETCHABUN': 'Фетчбун',
  'UTHAI THANI': 'Uthai Thani',
  'NAKHON SAWAN': 'Нахон Саван',
  'NONG KHAI': 'Нонг Хай',
  'LOEI': 'Лой',
  'UDON THANI': 'Удон Тані',
  'SAKON NAKHON': 'Сакон Нахон',
  'NAKHON PHANOM': 'Нахон Фаном',
  'KHON KAEN': 'Khon Kaen',
  'KALASIN': 'Каласін',
  'MAHA SARAKHAM': 'Маха Саракхем',
  'ROI ET': 'ROI ET',
  'CHAIYAPHUM': 'Чаяфум',
  'NAKHON RATCHASIMA': 'Нахон Ратчасіма',
  'BURIRAM': 'Бурірам',
  'SURIN': 'Сурін',
  'SISAKET': 'Сісакет',
  'NARATHIWAT': 'Наратіват',
  'CHAI NAT': 'Чай Нат',
  'SING BURI': 'Співати бурі',
  'LOP BURI': 'Lop Buri',
  'ANG THONG': 'Анг',
  'PHRA NAKHON SI AYUTTHAYA': 'Phra Nakhon Si Ayutthaya',
  'SARABURI': 'Сарабурі',
  'NONTHABURI': 'Немабурі',
  'PATHUM THANI': 'Патум Тані',
  'KRUNG THEP': 'Krung thep',
  'PHAYAO': 'Фаяо',
  'SAMUT PRAKAN': 'Самут Пракан',
  'NAKHON NAYOK': 'Нахон Наок',
  'CHACHOENGSAO': 'Чахоенгсао',
  'PRACHIN BURI': 'Прачін Бурі',
  'CHON BURI': 'Чон Бурі',
  'RAYONG': 'Районг',
  'CHANTHABURI': 'Чантабурі',
  'TRAT': 'Трат',
  'KANCHANABURI': 'Канчанабурі',
  'SUPHAN BURI': 'Suphan buri',
  'RATCHABURI': 'Ратчбурі',
  'NAKHON PATHOM': 'Nakhon Pathom',
  'SAMUT SONGKHRAM': 'Самут Сонгкрам',
  'SAMUT SAKHON': 'Самут Сахон',
  'PHETCHABURI': 'Фетчбурі',
  'PRACHUAP KHIRI KHAN': 'Прачуап Хірі Хан',
  'CHUMPHON': 'Брут',
  'RANONG': 'Ранонг',
  'SURAT THANI': 'Сурат Тані',
  'PHANGNGA': 'Фангнга',
  'PHUKET': 'Пукет',
  'KRABI': 'Крабі',
  'NAKHON SI THAMMARAT': 'Nakhon Si Thammarat',
  'TRANG': 'Транг',
  'PHATTHALUNG': 'Фатталунг',
  'SATUN': 'Сита',
  'SONGKHLA': 'Пісня',
  'PATTANI': 'Паттані',
  'YALA': 'Ява',
  'UBON RATCHATHANI': 'Ubon ratchathani',
  'YASOTHON': 'Ясотон',
  'HAAPAI': 'Хаапай',
  'TONGATAPU': 'Тонгатапу',
  'VAVAU': 'Вавау',
  'AMLAME': 'Амлан',
  'ANEHO': 'Анехо',
  'ATAKPAME': 'Atakpame',
  'BAFILO': 'Блафіло',
  'BASSAR': 'Басар',
  'DAPAONG': 'Дапаонг',
  'KANTE': 'Кант',
  'KLOUTO': 'Клюто',
  'LAMA-KARA': 'Лама-Кара',
  'LOME': 'Лам',
  'MANGO': 'Манго',
  'NIAMTOUGOU': 'Ніамтугу',
  'NOTSE': 'Ніщо',
  'KPAGOUDA': 'Kpagouda',
  'BADOU': 'Бадоу',
  'SOTOUBOUA': 'Сотубуа',
  'TABLIGBO': 'Табулігбо',
  'TSEVIE': 'Цукерка',
  'TCHAMBA': 'Чамба',
  'TCHAOUDJO': 'Чауджо',
  'VOGAN': 'Фуган',
  'PRINCIPE': 'Принципі',
  'SAO TOME': 'SAO TOME',
  'AL QASRAYN': 'Аль Касрейн',
  'AL QAYRAWAN': 'Аль -Кайраван',
  'JUNDUBAH': 'Юндуба',
  'MADANIN': 'Маданін',
  'QABIS': 'QABIS',
  'QAFSAH': 'QAFSAH',
  'AL KAF': 'Аль -каф',
  'AL MAHDIYAH': 'Аль -Махдія',
  'AL MUNASTIR': 'Аль Мунастір',
  'BAJAH': 'Баджа',
  'BANZART': 'Банцарт',
  'NABUL': 'Набал',
  'SAFAQIS': 'Сафакіс',
  'SIDI BU ZAYD': 'Сіді Бу Зайд',
  'SILYANAH': 'Сіляна',
  'SUSAH': 'Сума',
  'TUNIS': 'Туніс',
  'TUNIS AL JANUBIYAH': 'Туніс Аль Януяя',
  'ADANA': 'Адана',
  'ADIYAMAN': 'Адіяман',
  'AFYON': 'Афій',
  'AGRI': 'Агрі',
  'AMASYA': 'Амася',
  'ANKARA': 'Анкара',
  'ANTALYA': 'Анталія',
  'ARTVIN': 'Артвін',
  'AYDIN': 'Айдін',
  'BALIKESIR': 'Балакесір',
  'BILECIK': 'Білецик',
  'BINGOL': 'Бінгол',
  'BITLIS': 'Бітліс',
  'BOLU': 'Болу',
  'BURDUR': 'Бурхливий',
  'BURSA': 'Бурса',
  'CANAKKALE': 'Канаккале',
  'CANKIRI': 'Канкірі',
  'CORUM': 'Корум',
  'DENIZLI': 'Денізлі',
  'DIYARBAKIR': 'Діярбакір',
  'EDIRNE': 'Едірне',
  'ELAZIG': 'Елазиг',
  'ERZINCAN': 'Ерзінкан',
  'ERZURUM': 'Ерзурум',
  'ESKISEHIR': 'Ескісехір',
  'GAZIANTEP': 'Gaziantep',
  'GIRESUN': 'Гірсун',
  'GUMUSHANE': 'Гумушан',
  'HAKKARI': 'Хакарі',
  'HATAY': 'Хижак',
  'ICEL': 'Крик',
  'ISPARTA': 'ISPARTA',
  'ISTANBUL': 'Стамбул',
  'IZMIR': 'Ізмір',
  'KARS': 'Карта',
  'KASTAMONU': 'Кастамоні',
  'KAYSERI': 'Кайсері',
  'KIRKLARELI': 'Кіркларелі',
  'KIRSEHIR': 'Кірсехір',
  'KOCAELI': 'Кокаелі',
  'KONYA': 'Коняна',
  'KUTAHYA': 'Кутахя',
  'MALATYA': 'Малатія',
  'MANISA': 'Маніса',
  'MARAS': 'Маре',
  'MARDIN': 'Мардін',
  'MUGLA': 'Мула',
  'MUS': 'Муз',
  'NEVSEHIR': 'Невсехір',
  'NIGDE': 'Нігде',
  'ORDU': 'Орду',
  'RIZE': 'Обрізати',
  'SAKARYA': 'Сакарія',
  'SAMSUN': 'Самсун',
  'SIIRT': 'Сір',
  'SINOP': 'Синоп',
  'SIVAS': 'Сівас',
  'TEKIRDAG': 'Tekirdag',
  'TOKAT': 'Токат',
  'TRABZON': 'Трабзон',
  'TUNCELI': 'Тунселі',
  'URFA': 'Урфа',
  'USAK': 'Усак',
  'VAN': 'Фургон',
  'YOZGAT': 'Йозгат',
  'ZONGULDAK': 'Zonguldak',
  'AKSARAY': 'Аксарай',
  'BAYBURT': 'Байбурт',
  'KARAMAN': 'Караман',
  'KIRIKKALE': 'Кірккале',
  'BATMAN': 'Бетмен',
  'SIRNAK': 'Sirnak',
  'BARTIN': 'Бартін',
  'ARDAHAN': 'Ардахан',
  'IGDIR': 'Igdir',
  'FU-CHIEN': 'Фу-Чіен',
  'KAO-HSIUNG': 'Kao-hsiung',
  "T'AI-PEI": 'T\'ai-pei',
  "T'AI-WAN": 'Т\'АЙ-Ван',
  'ARUSHA': 'Аруша',
  'PWANI': 'Пвані',
  'DODMOA': 'Додмоа',
  'IRINGA': 'Ірінга',
  'KIGOMA': 'Кігома',
  'KILIMANJARO': 'Кіліманджаро',
  'LINDI': 'Лінді',
  'MARA': 'Мара',
  'MBEYA': 'Мбея',
  'MOROGORO': 'Морогоро',
  'MTWARA': 'Mtwara',
  'MWANZA': 'Мванза',
  'PEMBA NORTH': 'Пемба Північ',
  'RUVUMA': 'Рувума',
  'SHINYANGA': 'Шиньянга',
  'SINGIDA': 'Сингіда',
  'TABORA': 'Табора',
  'TANGA': 'Танга',
  'ZIWA MAGHARIBI': 'Зіва Магарібі',
  'PEMBA SOUTH': 'Пемба Південь',
  'ZANZIBAR CENTRAL/SOUTH': 'Занзібар Центральний/Південь',
  'ZANZIBAR NORTH': 'Занзібар Північ',
  'DAR ES SALAAM': 'Dar es salaam',
  'RUKWA': 'Руква',
  'ZANZIBAR URBAN/WEST': 'Zanzibar Urban/West',
  'BUSOGA': 'Бусога',
  'KARAMOJA': 'Карамоя',
  'SOUTH BUGUNDA': 'South Bugunda',
  'NILE': 'Ніль',
  'NORTH BUGANDA': 'Північна Буканда',
  'AVON': 'Ябло',
  'BEDFORD': 'Бедфорд',
  'BERKSHIRE': 'Беркшир',
  'BUCKINGHAM': 'Бакінгем',
  'CAMBRIDGE': 'Кембридж',
  'CHESTER': 'Честер',
  'CLEVELAND': 'Клівленд',
  'CORNWALL': 'Корнуолл',
  'CUMBRIA': 'Кумбрія',
  'DERBY': 'Дербі',
  'DEVON': 'Девон',
  'DORSET': 'Дорсет',
  'DURHAM': 'Дарем',
  'EAST SUSSEX': 'Східний Сассекс',
  'ESSEX': 'Ессекс',
  'GLOUCESTER': 'Глостер',
  'GREATER LONDON': 'Великий Лондон',
  'GREATER MANCHESTER': 'Великий Манчестер',
  'HAMPSHIRE': 'Гемпшир',
  'HEREFORD AND WORCESTER': 'Герефорд і Вустер',
  'HERTFORD': 'Гартфорд',
  'HUMBERSIDE': 'Хамберна',
  'ISLE OF WIGHT': 'Острів Вайт',
  'KENT': 'Кент',
  'LANCASTER': 'Ланкастер',
  'LEICESTER': 'Лестер',
  'LINCOLN': 'Лінкольн',
  'MERSERYSIDE': 'Merseryside',
  'NORFOLK': 'Норфолк',
  'NORTH YORKSHIRE': 'Північний Йоркшир',
  'NORTHHAMPTON': 'Нортхемптон',
  'NORTHUMBERLAND': 'Нортумберленд',
  'NOTTINGHAM': 'Ноттінгем',
  'SHROPSHIRE': 'Шропшир',
  'SOMERSET': 'Сомерсет',
  'SOUTH YORKSHIRE': 'Південний Йоркшир',
  'STAFFORD': 'Стаффорд',
  'SUFFOLK': 'Суффолк',
  'SURREY': 'Суррей',
  'TYNE AND WEAR': 'Тайне і носіння',
  'WEST MIDLANDS': 'Західний Мідлендс',
  'WEST SUSSEX': 'Західний Сассекс',
  'WEST YORKSHIRE': 'Західний Йоркшир',
  'WILTSHIRE': 'Уїлтшир',
  'ANTRIM': 'Антрім',
  'ARDS': 'ГРДС',
  'ARMAGH': 'Арма',
  'BALLYMENA': 'Баллімена',
  'BALLYMONEY': 'Баллімоні',
  'BANBRIDGE': 'Банбридж',
  'BELFAST': 'Белфаст',
  'CARRICKFERGUS': 'Carrickfergus',
  'CASTLEREAGH': 'Каслера',
  'COLERAINE': 'Колерен',
  'COOKSTOWN': 'Кук',
  'CRAIGAVON': 'Крейгавон',
  'DOWN': 'Вниз',
  'DUNGANNON': 'Данганнон',
  'FERMANAGH': 'Ферманах',
  'LARNE': 'Ларне',
  'LIMAVADY': 'Лімаваді',
  'LISBURN': 'Лісберн',
  'LONDONDERRY': 'Лондондеррі',
  'MAGHERAFELT': 'Магерафельт',
  'MOYLE': 'Мойле',
  'NEWRY AND MOURNE': 'Ньюрі та Морн',
  'NEWTOWNABBEY': 'Newtownabbey',
  'NORTH DOWN': 'Північ вниз',
  'OMAGH': 'Омах',
  'STRABANE': 'Штабан',
  'DUMFRIES AND GALLOWAY': 'Дамфріс і Галлоуей',
  'FIFE': 'Файф',
  'GRAMPIAN': 'Граман',
  'HIGHLAND': 'Високогір\'я',
  'LOTHIAN': 'Лотіанський',
  'ORKNEY': 'Оркней',
  'SHETLAND': 'Шетленд',
  'STRATHCLYDE': 'Strathclyde',
  'TAYSIDE': 'Тайсайд',
  'WESTERN ISLES': 'Західні острови',
  'CLWYD': 'Квіти',
  'DYFED': 'Диферований',
  'GWENT': 'Гвент',
  'GWYNEDD': 'Гвінедд',
  'MID GLAMORGAN': 'Середній гламорган',
  'POWYS': 'Павутини',
  'SOUTH GLAMORGAN': 'Південний гламорган',
  'WEST GLAMORGAN': 'Західний гламорган',
  'ALABAMA': 'Алабама',
  'ALASKA': 'Аляска',
  'ARIZONA': 'Арізона',
  'ARKANSAS': 'Арканзас',
  'CALIFORNIA': 'Каліфорнія',
  'COLORADO': 'Колорадо',
  'CONNECTICUT': 'Коннектикут',
  'DELAWARE': 'Делавер',
  'DISTRICT OF COLUMBIA': 'Округ Колумбія',
  'FLORIDA': 'Флорида',
  'HAWAII (CODE DELETED)': 'Гаваї (код видалено)',
  'IDAHO': 'Айдахо',
  'ILLINOIS': 'Іллінойс',
  'INDIANA': 'Індіана',
  'IOWA': 'Айова',
  'KANSAS': 'Канзас',
  'KENTUCKY': 'Кентуккі',
  'LOUISIANA': 'Луїзіана',
  'MAINE': 'Мен',
  'MASSACHUSETTS': 'Массачусетс',
  'MICHIGAN': 'Мічиган',
  'MINNESOTA': 'Міннесота',
  'MISSISSIPPI': 'Міссісіпі',
  'MISSOURI': 'Міссурі',
  'MONTANA': 'Монтана',
  'NEBRASKA': 'Небраска',
  'NEVADA': 'Невада',
  'NEW HAMPSHIRE': 'НЬЮ-ГЕМПШИР',
  'NEW JERSEY': 'НЬЮ ДЖЕРСІ',
  'NEW MEXICO': 'Нью -Мексико',
  'NEW YORK': 'НЬЮ-ЙОРК',
  'NORTH CAROLINA': 'ПІВНІЧНА КАРОЛІНА',
  'NORTH DAKOTA': 'Північна Дакота',
  'OHIO': 'Огайо',
  'OKLAHOMA': 'Оклахома',
  'OREGON': 'Орегон',
  'PENNSYLVANIA': 'Пенсильванія',
  'RHODE ISLAND': 'РОД-АЙЛЕНД',
  'SOUTH CAROLINA': 'ПІВДЕННА КАРОЛІНА',
  'SOUTH DAKOTA': 'Південна Дакота',
  'TENNESSEE': 'Теннессі',
  'TEXAS': 'Техас',
  'UTAH': 'Юта',
  'VERMONT': 'Вермонт',
  'VIRGINIA': 'Вірджинія',
  'WASHINGTON': 'Вашингтон',
  'WEST VIRGINIA': 'Західна Вірджинія',
  'WISCONSIN': 'Вісконсін',
  'WYOMING': 'Вайомінг',
  'BAN': 'Заборона',
  'BAZEGA': 'Базега',
  'BOUGOURIBA': 'Бугоба',
  'BOULGOU': 'Булгу',
  'BOULKIEMDE': 'Boulkiemde',
  'GANZOURGOU': 'Ганзургу',
  'GNAGNA': 'Гнанья',
  'GOURMA': 'Гурма',
  'HOUET': 'Готу',
  'KADIOGO': 'Кадіого',
  'KENEDOUGOU': 'Кенедугу',
  'KOMOE': 'Комове',
  'KOSSI': 'Коссі',
  'KOURITENGA': 'Курітенга',
  'MOUHOUN': 'Мюхун',
  'NAMENTENGA': 'НАМІНІНЕНГА',
  'NAOURI': 'Наурі',
  'OUBRITENGA': 'Oubritenga',
  'OUDALAN': 'Оудалан',
  'PASSORE': 'Пастори',
  'PONI': 'Поні',
  'SANGUIE': 'Сангей',
  'SANMATENGA': 'Санматенга',
  'SENO': 'Сено',
  'SISSILI': 'Сіссілі',
  'SOUM': 'Сум',
  'SOUROU': 'Суколо',
  'TAPOU': 'Іполо',
  'YETENGA': 'Антенга',
  'ZOUNDWESGO': 'Zoundwesgo',
  'ARTIGAS': 'Артигас',
  'CANELONES': 'Канелони',
  'CERRO LARGO': 'Серро Ларго',
  'COLONIA': 'Колонію',
  'DURAZNO': 'Дюразно',
  'FLORES': 'Фреси',
  'LAVALLEJA': 'Лаваллея',
  'MALDONADO': 'Мальдонадо',
  'MONTEVIDEO': 'Монтевідео',
  'PAYSANDU': 'Оплата',
  'RIVERA': 'Рівера',
  'ROCHA': 'Роша',
  'SALTO': 'Сальто',
  'SORIANO': 'Соріано',
  'TACUAREMBO': 'Такуарембо',
  'TREINTA Y TRES': 'Treinta y tres',
  'CHARLOTTE': 'Шарлотта',
  'GRENADINES': 'Гренадини',
  'ANZOATEGUI': 'Anzoateguii',
  'APURE': 'Вигадувати',
  'ARAGUA': 'Арагуа',
  'BARINAS': 'Баріни',
  'CARABOBO': 'Карабобо',
  'COJEDES': 'Коджед',
  'DELTA AMACURO': 'Дельта Амакоро',
  'FALCON': 'Сокіл',
  'GUARICO': 'Гуарік',
  'LARA': 'Лара',
  'MERIDA': 'Мерида',
  'MIRANDA': 'Міранда',
  'MONAGAS': 'Монагас',
  'NUEVA ESPARTA': 'Нуева Еспарта',
  'PORTUGUESA': 'Португеса',
  'TACHIRA': 'Тачіра',
  'TRUJILLO': 'Трухільо',
  'YARACUY': 'Яракуй',
  'ZULIA': 'Зулія',
  'DEPENDENCIAS FEDERALES': 'Залежності федералів',
  'AN GIANG': 'Джанг',
  'BAC THAI': 'Бак тайський',
  'BE N TRE': 'Бути n tre',
  'BINH TRI THIEN': 'Binh tri thien',
  'CAO BANG': 'Cao Bang',
  'CUU LONG': 'Cuu довгий',
  'DAC LAC': 'DAC LAC',
  'DONG THAP': 'Dong Thap',
  'GIA LAI-KON TUM': 'Gia Lai-Kon Tum',
  'HA BAC': 'Ha bac',
  'HAI HUNG': 'Хай Хун',
  'HAI PHONG': 'Хай Фонг',
  'HA NAM NINH': 'Ha nam ninh',
  'HA NOI': 'Ха Ной',
  'HA SON BINH': 'Ха син Бінь',
  'HA TUYEN': 'Ha Tuyen',
  'HOANG LIEN SON': 'Hoang Lien Son',
  'HO CHI MINH': 'Хо Чі Мін',
  'KIE N GIANG': 'Kie n Giang',
  'LAI CHAU': 'Lai chau',
  'LAM DONG': 'Лам Донг',
  'LONG AN': 'Довгий',
  'MINH HAI': 'Мін Хай',
  'NGHE TINH': 'Nghe tinh',
  'NGHIA BINH': 'Nghia binh',
  'PHU KHANH': 'Phu Khanh',
  'QUANG NAM-DA NANG': 'Quang nam-da nang',
  'QUANG NINH': 'Quang Ninh',
  'SONG BE': 'Пісня',
  'SON LA': 'Син ла',
  'TAY NINH': 'Тей Нінь',
  'THANH HOA': 'Тхан Хоа',
  'THAI BINH': 'Тайський бінь',
  'THUAN HAI': 'Thuan hai',
  'TIEN GIANG': 'Тянь Джанг',
  'VINH PHU': 'Вінх Фу',
  'LANG SON': 'Ланг син',
  'DONG NAI': 'Донг Наї',
  'HAU GIANG': 'Хау Джанг',
  'VUNGTAU-CON DAO': 'Vungtau-Con Dao',
  'BETHANIEN': 'Бетанієн',
  'CAPRIVI OOS': 'Caprivi oos',
  'BOESMANLAND': 'Боесманленд',
  'GOBABIS': 'Гобабіс',
  'GROOTFONTEIN': 'Grootfontein',
  'KAOKOLAND': 'Каоколанд',
  'KARIBIB': 'Карбіб',
  'KEETMANSHOOP': 'Keetmanshoop',
  'LUDERITZ': 'Людеріц',
  'MALTAHOHE': 'Мальтахое',
  'OKAHANDJA': 'Окаханджа',
  'OMARURU': 'Омаруру',
  'OTJIWARONGO': 'Otjiwarongo',
  'OUTJO': 'Поза',
  'OWAMBO': 'Совбо',
  'REHOBOTH': 'Речовина',
  'SWAKOPMUND': 'Свакопмунд',
  'TSUMEB': 'Цумеб',
  'KARASBURG': 'Карасбург',
  'WINDHOEK': 'Віндхок',
  'DAMARALAND': 'Дамараланд',
  'HEREROLAND OOS': 'Hereroland OOS',
  'HEREROLAND WES': 'Hereroland Wes',
  'KAVANGO': 'Каванго',
  'MARIENTAL': 'Марієнтальний',
  'NAMALAND': 'Намаланд',
  "A'ANA": 'A\'ANA',
  'AIGA-I-LA-TAI': 'AIGA-I-LA-TAI',
  'ATUA': 'Атуа',
  "FA'ASALELEAGA": 'Fa\'asaleleaga',
  "GAGA'EMAUGA": 'Gaga\'emauga',
  "VA'A-O-FONOTI": 'Va\'a-o-fonoti',
  'GAGAIFOMAUGA': 'Gagaifomauga',
  'PALAULI': 'Палаулі',
  "SATUPA'ITEA": "Satupa\'itea",
  'TUAMASAGA': 'Туамасага',
  'VAISIGANO': 'Vaisigano',
  'HHOHHO': 'Hhohho',
  'LUBOMBO': 'Любомбо',
  'MANZINI': 'Манзіні',
  'SHISELWENI': 'Шизельвені',
  'AL HUDAYDAH': 'Аль Худайда',
  'HAJJAH': 'Хаджа',
  'IBB': 'IBB',
  "SA'DAH": 'Са\'да',
  'SANA': 'Сана',
  "TA'IZZ": 'Ta\'izz',
  'AL MAHWIT': 'Аль Махвіт',
  'DHAMAR': 'Дхамар',
  "MA'RIB": 'Ма\'ріб',
  'ABYAN': 'Абіан',
  'ADAN': 'Адан',
  'AL MAHRAH': 'Аль -Махра',
  'HADRAMAWT': 'Хадрамавт',
  'SHABWAH': 'Шабва',
  'LAHIJ': 'Лагій',
  'KOSOVO': 'Косово',
  'MONTENEGRO': 'Чорногорія',
  'SRBIJA': 'Srbija',
  'VOJVODINA': 'Воєводіна',
  'LUAPULA': 'Луапула',
  'NORTH-WESTERN': 'Північно-західний',
  'COPPERBELT': 'Мідь',
  'LUSAKA': 'Лусака',
  'MANICALAND': 'Manicaland',
  'MIDLANDS': 'Мідлендс',
  'MASHONALAND CENTRAL': 'Mashonaland Central',
  'MASHONALAND EAST': 'Машоналанд Схід',
  'MASHONALAND WEST': 'Машоналанд Захід',
  'MATABELELAND NORTH': 'Matabeleland North',
  'MATABELELAND SOUTH': 'Matabeleland Південь',
  'GRID NORTH': 'Сітка Північ',
  'MAGNETIC NORTH': 'Магнітна півночі',
  'RELATIVE': 'Відносний',
  'TRUE NORTH': 'Справжній північ',
  'KILOYARD': 'Кілопарк',
  'BARREL': 'Бочка',
  'JERRICAN': 'Еррикан',
  'CUBIC CENTIMETRE': 'Кубічний сантиметр',
  'CUBIC FOOT': 'Кубічна стопа',
  'CENTIGRAM': 'Ценграм',
  'CUBIC INCH': 'Кубічний дюйм',
  'CENTILITRE': 'Центритре',
  'CONTAINER': 'Контейнер',
  'HUNDRED WEIGHT': 'Сто вага',
  'GROSS': 'Грубий',
  'HECTOLITRE': 'Гектолітр',
  'MILLIGRAM': 'Міліграм',
  'MILLILITRE': 'Мілілітр',
  'RATION': 'Пайка',
  'AIRPORT OF DEBARKATION': 'Аеропорт декорації',
  'SEAPORT OF DEBARKATION': 'Морський порт дебаркації',
  'ABATIS/LOG OBSTACLE': 'Абатіс/перешкода журналу',
  'FERRY ACCESS DEMOLITION': 'Знесення доступу на поромі',
  'ANTENNAS': 'Антени',
  'ANTI-PERSONNEL': 'Анти-особистий',
  'APRON FENCES': 'Огорожі фартухів',
  'ANTI-TANK DITCH': 'Протитанкова канавка',
  'BEAMPOST': 'Пучок',
  'BRIDGE DEMOLITION': 'Знищення мосту',
  'BARBED WIRE': 'КОЛЮЧИЙ ДРІТ',
  'CABLE STAYED BALLONS': 'Кабель залишився БАЛОНИ',
  'CONTAMINATION, BIOLOGICAL': 'Забруднення, біологічне',
  'CONTAMINATION, CHEMICAL': 'Забруднення, хімічна речовина',
  'CONTAMINATION, NUCLEAR': 'Забруднення, ядерне',
  'CURVED RAILS': 'Вигнуті рейки',
  'DRAGON TEETH': 'Драконові зуби',
  'FALLBLOCK': 'Фальшивий',
  'FENCE': 'Огорожа',
  'FLOATING NETS': 'Плаваючі сітки',
  'FLOATING OBJECTS': 'Плаваючі предмети',
  'HIGH TENSION CABLES': 'Кабелі з високою напругою',
  'INUNDATED GROUNDS': 'Затоплені майданчики',
  'KNIFE RESTS': 'Нож відпочиває',
  'MAST': 'Щогла',
  'MINE FIELD': 'Міне поле',
  'MULTIPURPOSE BARRIER': 'Багатоцільовий бар\'єр',
  'POWER LINE': 'ЛІНІЯ ЕЛЕКТРОПЕРЕДАЧ',
  'RAILROAD DEMOLITION': 'Знищення залізниць',
  'ROAD CRATER': 'Дорожній кратер',
  'ROAD DEMOLITION': 'Знищення доріг',
  'TELEPHONE LINE': 'Телефонна лінія',
  'TETRAHEDRONS': 'Тетраедрони',
  'TOWER': 'Башта',
  'TREE BLOWDOWN': 'Видування дерев',
  'TUNNEL DEMOLITION': 'Знищення тунелю',
  'BELT': 'Пояса',
  'LINEAR': 'Лінійний',
  'POINT': 'Точка',
  'KILOGALLON': 'Кілогаллон',
  'KILOGRAMS': 'Кілограм',
  'THOUSANDS OF POUNDS': 'Тисячі фунтів',
  'POUNDS': 'Кілограми',
  'GASOLINE, AVIATION, GRADE 100/130': 'Бензин, авіація, клас 100/130',
  'GRADE JP-8. S748 IS FUEL SYSTEM ICING INHIBITOR.': 'Клас JP-8. S748 - це інгібітор глазур\'ю паливної системи.',
  'TURBINE FUEL, AVIATION, KEROSENE TYPE': 'Турбінове паливо, авіація, тип гасу',
  'GRADE JP-4. S748 IS FUEL SYSTEM ICING INHIBITOR.': 'Клас JP-4. S748 - це інгібітор глазур\'ю паливної системи.',
  'GRADE JP-5. S748 IS FUEL SYSTEM ICING INHIBITOR.': 'Клас JP-5. S748 - це інгібітор глазур\'ю паливної системи.',
  'GASOLINE, AUTOMOTIVE, MILITARY (91 RON)': 'Бензин, автомобільний, військовий (91 Рон)',
  'DIESEL FUEL, MILITARY': 'Дизельне паливо, військові',
  'GASOLINE, AUTOMOTIVE, (98 RON)': 'Бензин, автомобільний, (98 Рон)',
  'KEROSENE': 'Гас',
  'FOG OIL': 'Туманна олія',
  'DIESEL FUEL, LOW TEMPERATURE BLEND': 'Дизельне паливо, низька температурна суміш',
  'GASOLINE, AUTOMOTIVE, UNLEADED (95 RON)': 'Бензин, автомобільний, нечесний (95 Рон)',
  'FUEL, NAVAL DISTILLATE, LOW POUR POINT': 'Паливо, військово -морський дистилят, низька точка заливки',
  'FUEL, NAVAL DISTILLATE': 'Паливо, військово -морський дистилят',
  'NAVY STANDARD FUEL OIL (NSFO).': 'Військово -морський стандартний мазут (NSFO).',
  'CASH': 'Готівка',
  'DEFERRED PAYMENT': 'Відкладений платіж',
  'REQUEST ACCEPTED': 'Прохання прийнято',
  'REQUEST REFUSED': 'Запит відмовився',
  'REQUEST DELAYED': 'Запит затримується',
  'PREVIOUS MISSION PRIORITY PREVAILS': 'Попередня пріоритет місії переважає',
  'PROPOSED REIMBURSEMENT UNACCEPTABLE': 'Запропонована відшкодування неприйнятної',
  'STOCK UNAVAILABLE': 'Запас недоступний',
  'SATISFACTORY BUT WITH LIMITATIONS': 'Задовільно, але з обмеженнями',
  'SATISFACTORY': 'Задовільний',
  'UNSATISFACTORY': 'Незадовільний',
  'PRESENT/CURRENT': 'Теперішній/струм',
  'FUTURE': 'Майбутнє',
  'LOGISTICS': 'Логістика',
  'MEDICAL PERSONNEL': 'Медичний персонал',
  'OPERATIONS': 'Операції',
  'SUPPORT (NON-MEDICAL)': 'Підтримка (немедична)',
  'OTHER THAN POTABLE': 'Крім питного',
  'FEEDWATER': 'Живильна вода',
  'FRESHWATER INTENDED FOR HUMAN/ANIMAL CONSUMPTION': 'Прісна вода, призначена для споживання людини/тварин',
  'DEFICIENCY': 'Дефіцит',
  'SURPLUS': 'Надлишок',
  'RATIONS/WATER': 'Раціон/вода',
  'PERSONAL EQUIPMENT': 'Особисте обладнання',
  'POL': 'Пол',
  'CONSTRUCTION MATERIALS': 'Будівельні матеріали',
  'AMMUNITION': 'Боєприпаси',
  'MORALE SUPPORT': 'Підтримка моралі',
  'MAJOR EQUIPMENT': 'Основне обладнання',
  'REPAIRS PARTS': 'Ремонт деталей',
  'LEVEL OF EFFORT METHODOLOGY': 'Методологія рівня зусиль',
  'TARGET RELATED METHODOLOGY': 'Цільова методологія',
  'DELIVERY': 'Доставка',
  'PICKUP': 'Пікап',
  'To be at hospital facility within 90 minutes of first notification.': 'Бути в лікарняному закладі протягом 90 хвилин після першого повідомлення.',
  'To be at hospital facility within 4 hours of first notification.': 'Бути в лікарняному закладі протягом 4 годин після першого повідомлення.',
  'To be at hospital facility within 24 hours of first notification.': 'Бути в лікарняному закладі протягом 24 годин після першого повідомлення.',
  'No special equipment required.': 'Не потрібно спеціального обладнання.',
  'Hoist or winch.': 'Підйомник або лебідка.',
  'Extraction equipment.': 'Обладнання для видобутку.',
  'Ventilator.': 'Вентилятор.',
  'Lying patients, (litter/stretcher).': 'Лежачи пацієнтів (сміття/носилки).',
  'Sitting/walking patients.': 'Сидячи/ходьби пацієнти.',
  'Escorts, e.g. for Child, or POW.': 'Супроводження, напр. для дитини, або війська.',
  'No enemy detected in vicinity.': 'Жоден ворог не виявлений поблизу.',
  'Possible enemy in vicinity.': 'Можливий ворог поблизу.',
  'Enemy positively identified in vicinity.': 'Ворог позитивно ідентифікований поблизу.',
  'Pick up zone under fire.': 'Підберіть зону під обстрілом.',
  'PYRO': 'Пірот',
  'NONE': 'Ні',
  'INFRA RED': 'Інфра Червона',
  'Coalition military forces personnel': 'Коаліція військових сил',
  'Coalition civilian personnel': 'Коаліція цивільний персонал',
  'Non coalition security forces personnel': 'Необхідні сили безпеки коаліції',
  'Non coalition civilian personnel': 'Неоцінка цивільного персоналу',
  'Opposing forces personnel including POW and detainees': 'Протилежні сили персоналу, включаючи військовополонених та затриманих',
  'Child': 'Дитина',
  'FIRST (1)': 'Перший (1)',
  'SECOND (2)': 'Другий (2)',
  'THIRD (3)': 'Третій (3)',
  'FOURTH (4)': 'Четвертий (4)',
  'FIFTH (5)': 'П\'ятий (5)',
  'SIXTH (6)': 'Шостий (6)',
  'SEVENTH (7)': 'Сьомий (7)',
  'EIGHTH (8)': 'Восьмий (8)',
  'NINTH (9)': 'Дев\'ятий (9)',
  'TENTH (10)': 'Десяте (10)',
  'ELEVENTH (11)': 'Одинадцята (11)',
  'TWELFTH (12)': 'Дванадцятий (12)',
  'THIRTEENTH (13)': 'Тринадцяте (13)',
  'FOURTEENTH (14)': 'Чотирнадцяте (14)',
  'FIFTEENTH (15)': 'П’ятнадцятий (15)',
  'SIXTEENTH (16)': 'Шістнадцятий (16)',
  'SEVENTEENTH (17)': 'Сімнадцятий (17)',
  'EIGHTEENTH (18)': 'Вісімнадцять (18)',
  'NINETEENTH (19)': 'Дев\'ятнадцятий (19)',
  'TWENTIETH (20)': 'Двадцять (20)',
  'TWENTY-FIRST (21)': 'Двадцять перше (21)',
  'TWENTY-SECOND (22)': 'Двадцять секунд (22)',
  'TWENTY-THIRD (23)': 'Двадцять третьої (23)',
  'TWENTY-FOURTH (24)': 'Двадцять четверта (24)',
  'TWENTY-FIFTH (25)': 'Двадцять п’ятий (25)',
  'TWENTY-SIXTH (26)': 'Двадцять шосте (26)',
  'TWENTY-SEVENTH (27)': 'Двадцять сьомий (27)',
  'TWENTY-EIGHTH (28)': 'Двадцять восьмий (28)',
  'TWENTY-NINTH (29)': 'Двадцять дев\'ятий (29)',
  'THIRTIETH (30)': 'Тридцять (30)',
  'THIRTY-FIRST (31)': 'Тридцять першого (31)',
  'THIRTY-SECOND (32)': 'Тридцять секунд (32)',
  'THIRTY-THIRD (33)': 'Тридцять третини (33)',
  'THIRTY-FOURTH (34)': 'Тридцять четверта (34)',
  'THIRTY-FIFTH (35)': 'Тридцять п’ята (35)',
  'THIRTY-SIXTH (36)': 'Тридцять шосте (36)',
  'THIRTY-SEVENTH (37)': 'Тридцять сьомий (37)',
  'THIRTY-EIGHTH (38)': 'Тридцять восьмий (38)',
  'THIRTY-NINTH (39)': 'Тридцять дев\'ятий (39)',
  'FORTIETH (40)': 'Соки (40)',
  'FORTY-FIRST (41)': 'Сорок перший (41)',
  'FORTY-SECOND (42)': 'Сорок секунд (42)',
  'FORTY-THIRD (43)': 'Сорок третини (43)',
  'FORTY-FOURTH (44)': 'Сорок четверта (44)',
  'FORTY-FIFTH (45)': 'Сорок п’ята (45)',
  'FORTY-SIXTH (46)': 'Сорок шосте (46)',
  'FORTY-SEVENTH (47)': 'Сорок сьомого (47)',
  'FORTY-EIGHTH (48)': 'Сорок восьмий (48)',
  'FORTY-NINTH (49)': 'Сорок дев\'ятий (49)',
  'FIFTIETH (50)': 'П’ятдесятий (50)',
  'ADD AFTER': 'Додати',
  'ADD BEFORE': 'Додати перед',
  'DELETE': 'Видаляти',
  'REPLACE': 'Замінити',
  'COLUMNAR': 'Стовпчик',
  'FREE TEXT': 'Безкоштовний текст',
  'NORTH LATITUDE 0 DEGREES TO 90 DEGREES WEST LONGITUDE.': 'Північна широта 0 градусів до 90 градусів Західна довгота.',
  'NORTH LATITUDE 90 DEGREES TO 180 DEGREES WEST LONGITUDE': 'Північна широта 90 градусів до 180 градусів Західна довгота',
  'NORTH LATITUDE 180 DEGREES TO 90 DEGREES EAST LONGITUDE.': 'Північна широта 180 градусів до 90 градусів східна довгота.',
  'NORTH LATITUDE 90 DEGREES TO 0 DEGREES EAST LONGITUDE.': 'Північна широта 90 градусів до 0 градусів східна довгота.',
  'SOUTH LATITUDE 0 DEGREES TO 90 DEGREES WEST LONGITUDE.': 'Південна широта 0 градусів до 90 градусів Західна довгота.',
  'SOUTH LATITUDE 90 DEGREES TO 180 DEGREES WEST LONGITUDE.': 'Південна широта 90 градусів до 180 градусів Західна довгота.',
  'SOUTH LATITUDE 180 DEGREES TO 90 DEGREES EAST LONGITUDE.': 'Південна широта 180 градусів до 90 градусів східна довгота.',
  'SOUTH LATITUDE 90 DEGREES TO 0 DEGREES EAST LONGITUDE.': 'Південна широта 90 градусів до 0 градусів східна довгота.',
  'USED WHEN THE LOCATION IS SPECIFIED IN SOMETHING OTHER THAN LATITUDE AND LONGITUDE.': 'Використовується, коли місце розташування вказано в чомусь іншому, ніж широта та довгота.',
  '12': '12',
  '1 HOUR': '1 година',
  '2 HOURS': '2 ГОДИНИ',
  '3 HOURS': '3 ГОДИНИ',
  '4 HOURS': '4 години',
  '5 HOURS': '5 годин',
  '6 HOURS': '6 годин',
  '7 HOURS': '7 годин',
  '8 HOURS': '8 годин',
  'MET REQUEST': 'ЗАПИТАННЯ ЗАПИТАННЯ',
  'BALLISTIC MET (ANTI-AIRCRAFT)': 'Балістичний Мет (зенітний)',
  'BALLISTIC MET (SURFACE TO SURFACE)': 'Балістичний Мет (поверхня на поверхню)',
  'BASIC WIND REPORT': 'Основний звіт про вітру',
  'CHEMICAL DOWNWIND REPORT': 'Звіт про те, що хімічні речовини',
  'EFFECTIVE DOWNWIND REPORT': 'Ефективний звіт про вітрову вітру',
  'COMPUTER': 'Комп\'ютер',
  'ANY ELECTROMAGNETIC OR ELECTRO-OPTIC DISTURBANCE WHICH INTERRUPTS, OBSTRUCTS, OR OTHERWISE DEGRADES OR LIMITS THE EFFECTIVE PERFORMANCE OF ELECTRONICS/ ELECTRICAL/ELECTRO-OPTIC EQUIPMENT.': 'Будь-яке електромагнітне або електроооптичне порушення, яке перериває, перешкоджає або іншим чином погіршує або обмежує ефективну продуктивність електроніки/ електричного/ електрооптичного обладнання.',
  'THE INTENTIONAL INSERTION OF ELECTROMAGNETIC ENERGY INTO TRANSMISSION PATHS IN ANY MANNER WITH THE OBJECTIVE OF DECEIVING OPERATORS OR OF CAUSING CONFUSION.': 'Навмисне введення електромагнітної енергії в шляхи передачі будь -яким чином з метою обману, що обманюють, або спричиняють плутанину.',
  'THE DELIBERATE RADIATION, RERADIATION, OR REFLECTION OF ELECTROMAGNETIC SIGNALS WITH THE OBJECT OF IMPAIRING THE USE OF ELECTRONIC DEVICES.': 'Навмисне випромінювання, повторення або відображення електромагнітних сигналів з об\'єктом погіршення використання електронних пристроїв.',
  'A SYSTEM OF RECEIVING RADIO BEACON SIGNALS AND REBROADCASTING THEM ON THE SAME FREQUENCY TO CONFUSE NAVIGATION.': 'Система отримання сигналів радіовикона та ретрансляції їх на одній частоті, щоб заплутати навігацію.',
  'COMMUNICATIONS': 'Комунікація',
  'DATA LINKS': 'Посилання даних',
  'IFF': 'IFF',
  'NAVIGATIONAL AIDS': 'Навігаційні засоби',
  'RADAR': 'Радар',
  'WARNING SENSORS': 'Попереджувальні датчики',
  'AMPLITUDE': 'Амплітуда',
  'FREQUENCY (OR PHASE)': 'Частота (або фаза)',
  'PULSE': 'Пульс',
  'STRONG': 'Сильний',
  'WEAK': 'Слабкий',
  'NATO OR NATIONAL MILITARY PLANS, WEAPONS, SYSTEMS, OR OPERATIONS.': 'НАТО або Національні військові плани, зброя, системи чи операції.',
  'FOREIGN GOVERNMENT INFORMATION.': 'Іноземна інформація уряду.',
  'INTELLIGENCE ACTIVITIES (INCLUDING SPECIAL ACTIVITIES), INTELLIGENCE SOURCES OR METHODS, OR CRYPTOLOGY.': 'Розвідувальна діяльність (включаючи спеціальну діяльність), джерела розвідки чи методи чи криптологію.',
  'FOREIGN RELATIONS OR NATO OR NATIONAL ACTIVITIES, INCLUDING CONFIDENTIAL SOURCES.': 'Зовнішні відносини або НАТО або національна діяльність, включаючи конфіденційні джерела.',
  'SCIENTIFIC, TECHNOLOGICAL, OR ECONOMIC MATTERS RELATING TO NATO OR NATIONAL SECURITY.': 'Наукові, технологічні чи економічні питання, що стосуються НАТО або національної безпеки.',
  'NATO OR NATIONAL PROGRAMS FOR SAFEGUARDING NUCLEAR MATERIALS OR FACILITIES.': 'НАТО або Національні програми для захисту ядерних матеріалів чи споруд.',
  'VULNERABILITIES OR CAPABILITIES OF SYSTEMS, INSTALLATIONS, PROJECTS, OR PLANS RELATING TO NATO OR NATIONAL SECURITY.': 'Уразливості або можливості систем, установок, проектів або планів, що стосуються НАТО або національної безпеки.',
  'SOURCE MATERIAL FOR MESSAGE MARKED ORIGINATING AGENCY DETERMINATION REQUIRED (OADR) IN ACCORDANCE WITH GUIDELINES THAT WERE IN EFFECT AT THE TIME THE MATERIAL WAS PRODUCED.': 'Вихідний матеріал для позначеного повідомлення, що позначається, необхідне визначення агентства (OADR) відповідно до вказівок, які діяли на момент вироблення матеріалу.',
  'INTELLIGENCE SOURCE, METHOD, OR ACTIVITY, OR A CRYPTOLOGIC SYSTEM OR ACTIVITY.': 'Джерело інтелекту, метод чи діяльність, або криптологічна система чи діяльність.',
  'INFORMATION THAT WOULD ASSIST IN THE DEVELOPMENT OR USE OF WEAPONS OF MASS DESTRUCTION.': 'Інформація, яка допоможе у розробці або використанні зброї масового знищення.',
  'INFORMATION THAT WOULD IMPAIR THE DEVELOPMENT OF USE OF TECHNOLOGY WITHIN A NATO OR NATIONAL WEAPONS SYSTEM.': 'Інформація, яка погіршить розвиток використання технологій в системі НАТО або національної зброї.',
  'NATO OR NATIONAL MILITARY OR SECURITY EMERGENCY PREPAREDNESS PLANS.': 'НАТО або Національні плани підготовки до надзвичайних ситуацій у військовій службі.',
  'INFORMATION THAT WOULD DAMAGE RELATIONS BETWEEN NATO OR A NATO NATION AND A FOREIGN GOVERNMENT, REVEAL A CONFIDENTIAL SOURCE, OR SERIOUSLY UNDERMINE DIPLOMATIC ACTIVITIES THAT ARE REASONABLY EXPECTED TO BE ONGOING.': 'Інформація, яка завдала б шкоди відносинам між НАТО або нацією НАТО та іноземним урядом, виявляє конфіденційне джерело або серйозно підірвати дипломатичну діяльність, яка, як очікується, триватиме.',
  'INFORMATION THAT WOULD IMPAIR THE ABILITY OF RESPONSIBLE NATO OR NATIONAL OFFICIALS TO PROTECT HEADS OF STATE OR OTHER INDIVIDUALS FOR WHOM PROTECTIVE SERVICES, IN THE INTEREST OF NATO OR NATIONAL SECURITY, ARE AUTHORIZED.': 'Інформація, яка погіршить здатність відповідальних НАТО чи національних чиновників захищати глави держав чи інших осіб, для яких захисні послуги, в інтересах НАТО чи національної безпеки, уповноважені.',
  'INFORMATION THAT WOULD VIOLATE A STATUTE, TREATY, OR INTERNATIONAL AGREEMENT.': 'Інформація, яка порушила б статут, договір чи міжнародну угоду.',
  'PRIORITY 1': 'Пріоритет 1',
  'PRIORITY 2': 'Пріоритет 2',
  'PRIORITY 3': 'Пріоритет 3',
  'PRIORITY 4': 'Пріоритет 4',
  'PRIORITY 5': 'Пріоритет 5',
  'A UNIT FULLY CAPABLE OF PERFORMING THE MISSION FOR WHICH IT IS ORGANIZED OR DESIGNED.': 'Підрозділ, повністю здатний виконувати місію, для якої він організований або розроблений.',
  'A UNIT HAS MINOR DEFICIENCIES WHICH LIMIT ITS CAPABILITY TO ACCOMPLISH THE MISSION FOR WHICH IT IS ORGANIZED OR DESIGNED.': 'Підрозділ має незначні недоліки, які обмежують його здатність виконувати місію, для якої вона організована або розроблена.',
  'A UNIT MARGINALLY CAPABLE OF PERFORMING THE MISSION FOR WHICH IT IS ORGANIZED OR DESIGNED.': 'Одиниця незначно здатна виконувати місію, для якої вона організована або розроблена.',
  'A UNIT NOT CAPABLE OF PERFORMING THE MISSION FOR WHICH IT IS ORGANIZED OR DESIGNED.': 'Підрозділ, не здатний виконувати місію, для якої він організований або розроблений.',
  'ABOVE 90%': 'Вище 90%',
  '75-90%': '75-90%',
  '60-75%': '60-75%',
  'BELOW 60%': 'Нижче 60%',
  'FULLY RESTED': 'Повністю відпочиваючи',
  'OK IN 6': 'Добре в 6',
  'OK IN 12': 'Добре в 12',
  'NON OP': 'Неоднозначний',
  '80-90%': '80-90%',
  '70-80%': '70-80%',
  'BELOW 70%': 'Нижче 70%',
  'HIGH LEVEL, OPERATIONAL READINESS': 'Високий рівень, оперативна готовність',
  'LOWER LEVEL, OPERATIONAL READINESS': 'Нижчий рівень, оперативна готовність',
  'LOWEST LEVEL, OPERATIONAL READINESS': 'Найнижчий рівень, оперативна готовність',
  'WITHIN 48 HOURS': 'Протягом 48 годин',
  'BETWEEN 48 HOURS AND 4 DAYS': 'Від 48 годин до 4 днів',
  'BETWEEN 5 AND 15 DAYS': 'Від 5 до 15 днів',
  'BETWEEN 16 AND 30 DAYS': 'Від 16 до 30 днів',
  'AFTER 30 DAYS': 'Через 30 днів',
  'NATO ASSIGNED FORCES': 'НАТО призначили сили',
  'NATO COMMAND FORCES': 'НАТО командні сили',
  'NATO EARMARKED FORCES': 'НАТО виділяли сили',
  'NATIONAL FORCES': 'Національні сили',
  'OTHER FORCES FOR NATO': 'Інші сили для НАТО',
  'TEAM': 'Команда',
  'NATO EUROPE INTEGRATED AIR DEFENCE': 'НАТО Європа інтегрована протиповітряна оборона',
  'ACE MOBILE FORCE': 'ACE Mobile Force',
  "ACE MOBILE FORCE/ SACEUR'S STRATEGIC RESERVE": 'Стратегічний резерв ACE Mobile/ Saceur',
  'AIR TRANSPORT UNIT': 'Повітряний транспорт',
  'AIR AUGMENTATION': 'Збільшення повітря',
  'DUAL BASED': 'Подвійне на основі',
  'ACE MOBILE FORCE/ GENERAL DEFENCE PLAN': 'ACE MOBEL Force/ General Defense Plan',
  'GENERAL DEFENCE PLAN': 'Загальний план оборони',
  'GENERAL STRIKE PLAN': 'Загальний план страйку',
  'MARITIME CONTINGENCY FORCE': 'Морські сили надзвичайних ситуацій',
  'ACE MOBILE FORCE/ REGIONAL RESERVE': 'ACE Mobile Force/ Regional Reserve',
  'RAPID REACTION': 'Швидка реакція',
  'REGIONAL RESERVE FORCE': 'Регіональна резервна сила',
  "SACEUR'S STRATEGIC RESERVE": 'Стратегічний резерв Сейтера',
  'MAJOR HEADQUARTERS': 'Основна штаб',
  'COMBAT UNIT': 'Бойовий блок',
  'COMBAT SUPPORT UNIT': 'Блок бойової підтримки',
  'COMBAT SERVICE SUPPORT UNIT': 'Блок підтримки бойових послуг',
  'G-DAY': 'G-день',
  'ADDITION': 'Додавання',
  'EXEMPTION': 'Звільнення',
  'THE EVENT HAS OCCURRED AND/OR THE DATA IS ACTUAL.': 'Подія відбулася та/або дані є фактичними.',
  'CURRENT': 'Поточний',
  'THE EVENT IS PLANNED BUT HAS NOT YET BEEN PROPOSED.': 'Подія планується, але ще не запропонована.',
  'ATTACHED': 'Прикріплений',
  'OPERATIONAL COMMAND': 'Оперативна команда',
  'OPERATIONAL CONTROL': 'Оперативний контроль',
  'ORGANIC': 'Органічний',
  'TACTICAL COMMAND': 'Тактична команда',
  'TACTICAL CONTROL': 'Тактичний контроль',
  'CABLE': 'Кабель',
  'DATA LINK': 'Посилання даних',
  'FACSIMILE LINK': 'Факсиміле посилання',
  'FIBER': 'Волокно',
  'HIGH FREQUENCY': 'ВИСОКА ЧАСТОТА',
  'LASER': 'Лазер',
  'LOW FREQUENCY': 'Низька частота',
  'MASER': 'Мазер',
  'MICROWAVE': 'Мікрохвильова піч',
  'RADIO': 'Радіо',
  'RADIO RELAY': 'Радіо реле',
  'SATELLITE': 'Супутник',
  'SUPER HIGH FREQUENCY': 'Супер Висока частота',
  'TELEPHONE LINK': 'Телефонне посилання',
  'TROPOSCATTER': 'Тропорознавець',
  'TELETYPE LINK': 'Посилання Teletype',
  'ULTRA HIGH FREQUENCY': 'Ультра висока частота',
  'VERY HIGH FREQUENCY': 'Дуже висока частота',
  'VERY LOW FREQUENCY': 'Дуже низька частота',
  'VOICE LINK': 'Голосне посилання',
  'VIDEO': 'Відео',
  'EXTRA/EXTREMELY HIGH FREQUENCY': 'Додаткова/надзвичайно висока частота',
  'MEDIUM FREQUENCY': 'Середня частота',
  'HIGH FREQUENCY-SINGLE SIDE BAND': 'Високочастотна бічна смуга',
  'VERY HIGH FREQUENCY-FREQUENCY MODULATION': 'Дуже високочастотна частотна модуляція',
  'NO FIRE LINE': 'Немає пожежної лінії',
  'ENLISTED FEMALE': 'Заручена самка',
  'ENLISTED MALE': 'Заручений самець',
  'ENLISTED TOTAL': 'Заручений усього',
  'GRAND TOTAL': 'Загальний',
  'NON-COMBATANTS FEMALE': 'Не бойові речовини',
  'NON-COMBATANTS MALE': 'Не бойові бойовики чоловіки',
  'NON-COMBATANTS TOTAL': 'Не бойові речовини',
  'OFFICER FEMALE': 'Офіцер',
  'OFFICER MALE': 'Офіцер -чоловік',
  'OFFICER TOTAL': 'Офіцер Всього',
  'OTHER FEMALE': 'Інша самка',
  'OTHER MALE': 'Інший самець',
  'OTHER TOTAL': 'Інший загальний',
  'WO-NCO FEMALE': 'Жінка Wo-nco',
  'WO-NCO MALE': 'Wo-nco самець',
  'WO-NCO TOTAL': 'Wo-nco загальний',
  'BECAME REPORTABLE AT': 'Став звітним у',
  'WILL BECOME REPORTABLE AT': 'Стане звітуванням за адресою',
  'The earliest possible time': 'Найдавніший можливий час',
  'Time intended is not defined': 'Призначений час не визначається',
  'Time will be specified later by a dedicated call': 'Час буде визначений пізніше за допомогою спеціального дзвінка',
  'Time intended is to be determined later': 'Призначений час повинен бути визначений пізніше',
  'Time will be specified later': 'Час буде вказано пізніше',
  'Time is not yet known': 'Час ще не відомий',
  'MESSAGE UNDERSTOOD, EXECUTION NOT YET GUARANTEED': 'Повідомлення зрозуміло, виконання ще не гарантовано',
  'EXECUTION OF MESSAGE COMPLETED': 'Виконання повідомлення завершено',
  'MESSAGE UNDERSTOOD, EXECUTION MUST BE MODIFIED': 'Повідомлення зрозуміло, виконання повинно бути змінене',
  'MESSAGE UNDERSTOOD, EXECUTION IMPOSSIBLE': 'Повідомлення зрозуміло, виконання неможливо',
  'MESSAGE NOT UNDERSTOOD, VERIFICATION REQUESTED': 'Повідомлення не зрозуміло, перевірка запитувана',
  'MESSAGE UNDERSTOOD, EXECUTION GUARANTEED': 'Повідомлення зрозуміло, виконання гарантовано',
  'AFU.FUS AND AFU.AMS': 'Afu.fus та afu.ams',
  'AMMUNITION FIRE UNIT-AMMUNITION STATUS': 'Вогневий підрозділ – забезпеченість боєприпасами',
  'AMMUNITION FIRE UNIT-FIRE UNIT STATUS': 'Вогневий підрозділ – стан вогневого підрозділу',
  'MODIFICATION-ATTACK CRITERIA': 'Модифікація – критерії атаки',
  'MODIFICATION-EXCLUDE CRITERIA': 'Модифікація – критерії виключень',
  'NON-NUCLEAR FIRE PLANNING-RESERVE FIRE UNIT': 'Планування вогню неядерними боєприпасами – резерв вогневих підрозділів',
  'SUPPORT-AIRSPACE COORDINATION AREA': 'Підтримка – координаційна зона повітряного простору',
  'SUPPORT-BATTLEFIELD GEOMETRY': 'Підтримка – геометрія бойового простору',
  'A report is rendered each time data in it changes.': 'Звіт надається щоразу, коли дані в ньому змінюються.',
  'Once every two weeks.': 'Раз на два тижні.',
  'Once each day.': 'Раз на день.',
  'Once every eight hours.': 'Раз на вісім годин.',
  'Once every four hours.': 'Раз на чотири години.',
  'Once each hour.': 'Один раз на годину.',
  'Once each month.': 'Один раз на місяць.',
  'One time only.': 'Тільки один раз.',
  'Once every six hours.': 'Раз на шість годин.',
  'Once every twelve hours.': 'Раз кожні дванадцять годин.',
  'Once every two hours.': 'Раз на дві години.',
  'Once each week.': 'Один раз на тиждень.',
  'BOUNDARY': 'Межа',
  'CHEMICAL HAZARD AREA': 'Область хімічної небезпеки',
  'FREE FIRE AREA': 'Безкоштовна зона пожежі',
  'MINED AREA': 'Видобуток',
  'MINEFIELD': 'Мінне поле',
  'NO FIRE AREA': 'Немає пожежної зони',
  'RESTRICTED FIRE AREA': 'Обмежена пожежна зона',
  'TARGET AREA': 'Цільова зона',
  'ZONE OF FIRE': 'Зона вогню',
  'TARGET': 'Цільовий',
  'GIVES THE CLASSIFICATION OF DATA IN ACCORDANCE WITH THE DATA CODES USED IN AINTP-3.': 'Наводить класифікацію даних відповідно до кодів даних, що використовуються в AINTP-3.',
  'GIVES THE CLASSIFICATION OF DATA IN ACCORDANCE WITH THE DATA CODES USED IN AINTP-3': 'Дає класифікацію даних відповідно до кодів даних, що використовуються в AINTP-3',
  'FULLY VALIDATED AND ACCEPTED RECORD.': 'Повністю затверджений та прийнятий запис.',
  'EXERCISE DATA': 'Дані про вживання',
  'INACTIVE': 'Неактивний',
  'INACTIVE, QUANTITIES HAVE BEEN ROLLED TO A HIGHER LEVEL': 'Неактивні, кількість були перекинуті на більш високий рівень',
  'PENDING': 'Що очікує',
  'ARCHIVED': 'Заархівований',
  'RECORD WHICH MAY NOT YET HAVE BEEN VALIDATED OR ACCEPTED': 'Запис, який може ще не був підтверджений або прийнятий',
  'COMPLETE, ENTIRELY ACCEPTED. IT CAN BE STATED WITH CERTAINTY THAT THE SUBJECT MATTER BEING EVALUATED HAS BEEN PREVIOUSLY REPORTED BY TWO COMPLETELY RELIABLE SOURCES OR SUPPORTED BY TWO DIFFERENT, GOOD QUALITY PHOTOS.': 'Повна, цілком прийнята. З впевненістю можна зазначити, що про оцінювану тему раніше повідомлялося два повністю надійні джерела або підтримується двома різними фотографіями хорошої якості.',
  'NO PROOF OF CONFIRMATION ESTABLISHED. SUBJECT MATTER IS BELIEVED TO HAVE BEEN OBTAINED FROM ANOTHER SOURCE. IF CONTENTS OF A REPORT ARE CONFIRMED IN ESSENTIAL PARTS BY ALREADY AVAILABLE INFO FROM OTHER SOURCES, THE REPORT MAY BE JUDGED.': 'Ніяких доказів підтвердження не встановлено. Вважається, що тема була отримана з іншого джерела. Якщо вміст звіту підтверджується в основних частинах, вже доступною інформацією з інших джерел, звіт може бути оцінений.',
  'INVESTIGATION REVEALS THAT SUBJECT MATTER, ON WHICH NO OTHER INFORMATION IS AVAILABLE, CONFORMS TO THE BEHAVIOUR OF THE TARGET AS OBSERVED UP TO NOW.': 'Дослідження показує, що тема, на якій немає іншої інформації, відповідає поведінці цілі, як це було досі.',
  'THE SUBJECTIVE CONFIDENCE LEVEL THE ANALYST HAS IN THE FIGURES QUOTED. SUBJECT MATTER THAT CONTRADICTS EXISTING INFORMATION ON THAT SUBJECT WHICH HAS PREVIOUSLY BEEN RATED AS CONFIRMED.': 'Суб\'єктивний рівень довіри, який аналітик має на цитованих цифрах. Тема, яка суперечить існуючій інформації з цього предмету, яка раніше була оцінена як підтверджена.',
  'THE SUBJECTIVE CONFIDENCE LEVEL THE ANALYST HAS IN THE FIGURES QUOTED. INVESTIGATION OF SUBJECT MATTER REVEALS NO BASIS FOR ALLOCATING ANY OTHER OF THE RATINGS. THIS RATING MUST BE USED RATHER THAN AN INACCURATE USE OF THE OTHER RATINGS. IT SHOULD BE USED WHEN NO OTHER INFORMATION IS AVAILABLE FOR COMPARISON.': 'Суб\'єктивний рівень довіри, який аналітик має на цитованих цифрах. Дослідження предмета не виявляє підстав для розподілу будь -яких інших рейтингів. Цей рейтинг повинен бути використаний, а не неточне використання інших рейтингів. Він повинен використовуватися, коли для порівняння немає іншої інформації.',
  'THE SUBJECTIVE CONFIDENCE LEVEL THE ANALYST HAS IN THE FIGURES QUOTED. IT CAN BE STATED WITH CERTAINTY THAT THE SUBJECT MATTER DOES NOT EXIST. THE SUBJECT MATTER NO LONGER IN EXISTENCE HAS BEEN REPORTED BY TWO COMPLETELY RELIABLE SOURCES OR SUPPORTED BY TWO GOOD QUALITY PHOTOS.': 'Суб\'єктивний рівень довіри, який аналітик має на цитованих цифрах. З впевненістю можна зазначити, що предмета не існує. Тема, яка вже не існує, не існувала двома повністю надійними джерелами або підтримується двома фотографіями хорошої якості.',
  'INDICATES THAT THE RECORD CONTAINS DATA ABOUT EQUIPMENT.': 'Вказує, що запис містить дані про обладнання.',
  'AIRCRAFT, FIXED WING': 'Літак, фіксоване крило',
  'AIRCRAFT, ROTARY WING': 'Літак, поворотне крило',
  'VESSELS, COMBATANT SHIP CATEGORY': 'Судна, категорія бойових суден',
  'VESSELS, COMBATANT CRAFT CATEGORY': 'Судна, категорія Comatant Craft',
  'VESSELS, AUXILIARY SHIP CATEGORY': 'Судна, категорія допоміжного корабля',
  'VESSELS, SUPPORT CRAFT CATEGORY': 'Судна, категорія підтримки ремесла',
  'VESSELS, MERCHANT, FISHING, RESEARCH, SPECIAL PURPOSE AND OTHER NON-MILITARY TYPES': 'Судна, торговець, риболовля, дослідження, спеціальне призначення та інші невійськові типи',
  'VESSELS, MILITARY IN NON-MILITARY SERVICE AND/OR CIVIL SERVICE MANNED NAVAL AUXILIARIES': 'Судна, військові в невійськовій службі та/або державній службі, що пішли на військово-морські допоміжні засоби',
  'ENGINES AND PROPULSION SYSTEMS': 'Двигуни та систем руху',
  'SPACE OBJECTS/EQUIPMENT AND LAUNCH VEHICLES': 'Космічні предмети/обладнання та запускові транспортні засоби',
  'ASSOCIATED/MISCELLANEOUS EQUIPMENT': 'Асоційоване/інше обладнання',
  'ANTITANK WEAPONS': 'Протитанкова зброя',
  'ARMOURED VEHICLES': 'Броньовані транспортні засоби',
  'TANKS': 'Резервуари',
  'GENERAL PURPOSE VEHICLES': 'Транспортні засоби загального призначення',
  'SPECIAL PURPOSE VEHICLE': 'Транспортний засіб спеціального призначення',
  'ENGINEERING EQUIPMENT': 'Інженерне обладнання',
  'AIR DEFENCE WEAPONS': 'Зброя протиповітряної оборони',
  'FIELD ARTILLERY/SURFACE BOMBARDMENT WEAPONS/TORPEDO TUBES': 'Польова артилерія/поверхневі обстріли зброї/торпедні трубки',
  'SURFACE-TO-SURFACE MISSILE LAUNCHERS/ROCKET LAUNCHERS': 'Ракетні установки/ракетні установки',
  'RADAR, ELECTRONIC WARFARE EQUIPMENT AND OTHER REMOTE DETECTION DEVICES': 'Радар, електронне обладнання для війни та інші пристрої віддаленого виявлення',
  'COMMUNICATIONS AND ADP EQUIPMENT': 'Комунікація та обладнання ADP',
  'AMMUNITION RELOAD': 'Перезавантаження боєприпасів',
  'INDICATES THAT THE RECORD CONTAINS UNIT DATA.': 'Вказує на те, що запис містить одиничні дані.',
  'AIR ORDER OF BATTLE (AOB) RELATED': 'Повітряний порядок бою (AOB) пов’язаний',
  'ELECTRONIC ORDER OF BATTLE (EOB) RELATED': 'Електронний порядок бою (EOB) пов’язаний',
  'USED AS A FACILITY': 'Використовується як об\'єкт',
  'GROUND ORDER OF BATTLE (GOB) RELATED': 'Наземний порядок бою (GOB)',
  'MISSILE ORDER OF BATTLE (MOB) RELATED': 'Ракетний порядок бою (Mob) пов’язаний',
  'NAVAL ORDER OF BATTLE (NOB) RELATED': 'Військово -морський орден бою (NOB) пов’язаний',
  'SPACE ORDER OF BATTLE (SOB) RELATED': 'Космічний порядок бою (SOB) пов’язаний',
  'UNKNOWN OR UNAVAILABLE': 'Невідомий або недоступний',
  'JOINT': 'Суглоб',
  'INCONCLUSIVE ANALYSIS': 'Непереконливий аналіз',
  'INDICATES THAT THE RECORD CONTAINS DATA ABOUT AN EVENT.': 'Вказує, що запис містить дані про подію.',
  'INDICATES THAT THE RECORD CONTAINS BIOGRAPHICAL DATA': 'Вказує на те, що запис містить біографічні дані',
  'READY, BUT THE DEGREE OF READINESS CANNOT BE DETERMINED.': 'Готовий, але ступінь готовності неможливо визначити.',
  'FULLY CAPABLE OF CARRYING OUT ALL MISSION REQUIREMENTS AND/OR HAS 75-100% WARTIME AUTHORISED EQUIPMENT ON HAND.': 'Повністю здатний виконувати всі вимоги щодо місії та/або має під рукою 75-100% уповноваженого обладнання воєнного часу.',
  'CAPABLE OF CARRYING OUT MOST MISSION REQUIREMENTS BUT HAS SIGNIFICANT DEGRADATION DUE TO EITHER PERSONNEL OR EQUIPMENT PROBLEMS AND/OR HAS 50-74% WARTIME AUTHORISED EQUIPMENT ON HAND.': 'Здатний виконувати більшість вимог до місії, але має значну деградацію через проблеми з персоналу чи обладнання, а/або має під рукою 50-74% уповноваженого обладнання воєнного часу.',
  'CAPABLE OF CARRYING OUT LIMITED MISSION REQUIREMENTS BUT HAS SUBSTANTIAL EQUIPMENT PROBLEMS AND/OR HAS 25-49% WARTIME AUTHORISED EQUIPMENT ON HAND.': 'Здатний виконувати обмежені вимоги до місії, але має значні проблеми з обладнанням та/або має під рукою 25-49% уповноваженого обладнання.',
  'EXTENSIVE DEGRADATION DUE TO PERSONNEL AND EQUIPMENT PROBLEMS AND IS NOT CAPABLE OF PERFORMING ITS MISSION SATISFACTORILY AND/OR HAS 05-24% OF WARTIME AUTHORISED EQUIPMENT ON HAND.': 'Широка деградація через проблеми з персоналу та обладнанням і не здатна виконувати свою місію задовільно та/або має під рукою 05-24% уповноваженого обладнання воєнного часу.',
  'USED PRIMARILY BY NAVAL ANALYSTS TO INDICATE THAT A UNIT IS NOT READY FOR OPERATIONS BUT COULD BE MADE READY ON SHORT NOTICE.': 'Використовується насамперед військово -морськими аналітиками, щоб вказати, що одиниця не готова до операцій, але може бути готова в короткому повідомленні.',
  'NO PREPARATION REQUIRED FOR OPERATION.': 'Не потрібна підготовка для роботи.',
  'ABANDONED': 'Занедбаний',
  'CAPTURED': 'Захоплений',
  'DE-ACTIVATED': 'Деактивований',
  'MAY BE RESTORED TO A USABLE CONDITION.': 'Може бути відновлено до корисного стану.',
  'DISMANTLED': 'Розібраний',
  'UNDER MODIFICATION': 'За модифікацією',
  'MOTHBALLED OR DORMANT': 'Мотбол або сплять',
  'TEMPORARY': 'Тимчасовий',
  'UNDER CONSTRUCTION': 'У РОЗРОБЦІ',
  'AWAITING DISPOSITION': 'Очікуючи диспозиції',
  'CONTAMINATED BY RADIOACTIVITY, CHEMICAL OR BIOLOGICAL AGENTS': 'Забруднений радіоактивністю, хімічними або біологічними агентами',
  'DECOMMISSIONED': 'Виведений з експлуатації',
  'DAMAGED': 'Пошкоджений',
  'LOST OR MISSING': 'Загублений або зниклий',
  'OTHER. EXPLAIN IN REMARKS.': 'ІНШИЙ. Поясніть зауваженнями.',
  'OVERHAUL': 'Капітальний ремонт',
  'SCRAPPED': 'Згорнутий',
  'SUNK': 'Затоплений',
  'SOLD': 'Проданий',
  'STORAGE': 'Зберігання',
  'SEIZED, OR HIJACKED BY PERSONS UNKNOWN': 'Вилучені або викрадені невідомих осіб',
  'SEIZED, OR HIJACKED BY FOREIGN POWER': 'Вилучений або викрадений іноземною владою',
  'SEIZED, OR HIJACKED BY TERRORIST GROUP': 'Вилучений або викрадений терористичною групою',
  'AVAILABLE': 'Доступний',
  'BEACHED': 'Пляжний',
  'CONVERSION': 'Перетворення',
  'CRASHED': 'Розбитий',
  'DAMAGED, BY AIRSTRIKE': 'Пошкоджений, авіаперевіркою',
  'DAMAGED, BY ENEMY ACTION': 'Пошкоджений, ворожі дії',
  'DAMAGED, BY FIRE, EXPLOSION, COLLISION, ETC.': 'Пошкоджений, вогнем, вибухом, зіткненням тощо.',
  'DAMAGED, BY GROUND FORCES': 'Пошкоджений, сухопутними силами',
  'DAMAGED, BY WEATHER': 'Пошкоджений, погода',
  'LOAN': 'Позика',
  'LOST AT SEA': 'Загублений у морі',
  'MUSEUM/RELIC': 'Музей/реліквія',
  'MUTINY': 'Заколот',
  'REFIT/YARD REPAIR': 'Ремонт/ремонт дворів',
  'SEA TRIALS': 'Морські випробування',
  'SEIZED, OR HIJACKED BY FOREIGN POWER OR TERRORIST GROUP': 'Вилучений або викрадений іноземною владою або терористичною групою',
  'SUNK, CAN BE RAISED': 'Затопіти, можна підняти',
  'IMAGERY': 'Зображення',
  'ACCRA, LEGION PILLAR (GCS) NO. 121, GHANA': 'Аккра, Легіон Стовп (GCS) Ні. 121, Гана',
  'ADEN': 'Аден',
  'ADINDAN, 30TH MERIDIAN, (SUDAN)': 'Адіндан, 30 -й меридіан, (Судан)',
  'AMERICAN SAMOA 1962 (SAMOA ISLANDS)': 'Американський Самоа 1962 р. (Самоа -острови)',
  'APPROXIMATE LUZON (PHILIPPINES)': 'Орієнтовний Лусон (Філіппіни)',
  'APPROXIMATE NANKING (CHINA)': 'Орієнтовний нанкінг (Китай)',
  'ARC 1950 (AFRICA)': 'ARC 1950 (Африка)',
  'ARC 1960 (AFRICA)': 'ARC 1960 (Африка)',
  'ARKHANGELSK, USSR': 'Архангельськ, СРСР',
  'ASCENSION ISLAND 1958 (ST. HELENA)': 'Острів Вознесіння 1958 р. (Св. Хелена)',
  'ASTRO 84 SOROL ATOLL': 'ASTRO 84 SOROL ATOLL',
  'ASTRONOMIC STA. 1952, MARCUS I (JAPAN)': 'Астрономічний страва. 1952, Маркус I (Японія)',
  'ASTRONOMIC STATION BEACON E. (1945), IWO JIMA, BURIN ISLAND': 'Астрономічна станція Beacon E. (1945), Іво Джіма, острів Бурін',
  'AUSTRALIAN GEODETIC': 'Австралійський геодетник',
  'AYABELE LIGHTHOUSE (DJIBOUTI)': 'Маяк Ayabele (Djibouti)',
  'BAB (TRUST TERRITORY OF THE PACIFIC ISLANDS)': 'Баб (довіра території Тихоокеанських островів)',
  'BARCELONA, VENEZUELA': 'Барселона, Венесуела',
  'BEKAA (SYRIA, LEBANON)': 'Bekaa (Сирія, Ліван)',
  'BELGRANO (ARGENTINA)': 'Белграно (Аргентина)',
  'BELLEVUE, IGN (VANUATU)': 'Bellevue, Ign (vanuatu)',
  'BERMUDA 1957': 'Бермуди 1957',
  'BERNE 1899 (SWITZERLAND)': 'Берн 1899 р. (Швейцарія)',
  'BOA ENTRADA (CAPE VERDE)': 'Boa entrada (Cape Verde)',
  'BOGOTA (COLUMBIA)': 'Богота (Колумбія)',
  'BOROVSKAYA, USSR': 'Боровська, СРСР',
  'BUCHAREST (ROMANIA)': 'Бухарест (Румунія)',
  'BUDAPEST, HUNGARY': 'Будапешт, Угорщина',
  'BUKIT ASA (MALAYSIA)': 'Bukit Asa (Малайзія)',
  'BUKIT RIMPAH (INDONESIA)': 'Bukit Rimpah (Індонезія)',
  'CAMACUPA (ANGOLA)': 'Камакупа (Ангола)',
  'CAMPO INCHAUSPE (ARGENTINA)': 'Campo Incauspe (Аргентина)',
  'CANTON ASTRO 1966 (KIRIBATI)': 'Canton Astro 1966 (Kiribati)',
  'CAPE AREA ASTRO': 'Кейп -район Астро',
  'CAPE CANAVERAL': 'Накидка Канаверал',
  'CHATHAM ISLAND ASTRO 1971': 'Chatham Island Astro 1971',
  'CHATHAM ISLAND OBSERVATORY 1950 (INDIA)': 'Обсерваторія острова Чатем 1950 р. (Індія)',
  'CHUA (PARAGUAY)': 'Чуа (Парагвай)',
  'CONSTANTA (ROMANIA)': 'Константа (Румунія)',
  'CZARIST INDEPENDENT, USSR': 'Czarist Independent, СРСР',
  'D.O.S. 71/4 LADDER HILL FORT (ST. HELENA)': 'D.O.S. 71/4 Форт -пагорб (ст. Хелена)',
  'D.O.S. ASTRO SLX2 (SIERRA LEONE)': 'D.O.S. Astro SLX2 (Sierra Leone)',
  'DARWIN ORIGIN PILLAR (AUSTRALIA)': 'Дарвін Породок стовп (Австралія)',
  'DJKARATA (BATAVIA), JAVA, INDONESIA': 'DJKARATA (Батавія), Ява, Індонезія',
  'DORPAT II (USSR)': 'Дорпат II (СРСР)',
  'EASTER ISLAND 1967 (CHILE)': 'Острів Великодня 1967 (Чилі)',
  'ENIWETOK - WAKE 1960': 'Eniwetok - Wake 1960',
  'EUROPEAN 1950': 'Європейський 1950',
  'FIJI 1956': 'Фіджі 1956',
  'FINAL 1958, (IRAN)': 'Заключний 1958 р. (Іран)',
  'FORT AMBON (LNDONESIA)': 'Форт Амбон (Lndonesia)',
  'GAN 1970 (MALDIVES)': 'Ган 1970 (Мальдіви)',
  'GELLERTHEGY (HUNGARY)': 'Gellerthegy (Угорщина)',
  'GEODESICO CENTROAMERICANO': 'Geodesico Centroamericano',
  'GRACIOSA BASE SW, 1948 (PORTUGAL)': 'Graciosa Base SW, 1948 (Португалія)',
  'GRONIFJORDEN, 1922 (SVALBARD)': 'Gronifjorden, 1922 (Svalbard)',
  'GUAM 1963': 'Гуам 1963',
  'GUDO CAVALEIRO': 'Гудо Кавалейро',
  'GUNUNG SEGERA (INDONESIA)': 'Gunung Segera (Індонезія)',
  'GUNUNG SERINDUNG, 1962 (INDONESIA)': 'Gunung Serindung, 1962 (Індонезія)',
  'GUX 1, 1960 (SOLOMON ISLANDS)': 'GUX 1, 1960 (Соломонські острови)',
  'HELINSKI, KALLIO CHURCH (FINLAND)': 'Хелінський, Калліо Церква (Фінляндія)',
  'HITO XVIII': 'Хіто xviii',
  'HONG ASTRO 1947, MANUS, PAPUA, NEW GUINEA': 'Hong Astro 1947, Manus, Papua, New Guinea',
  'HONG KONG 1963': 'Гонконг 1963',
  'HONG KONG-NEW TERRITORIES 1924': 'Гонконг-Нові території 1924 року',
  'I.S.T.S 073 (BRITISH INDIAN OCEAN TERRITORY)': 'I.S.T.S 073 (Британська територія Індійського океану)',
  'IMAN 1914, USSR': 'Іман 1914, СРСР',
  'INDIAN 1916': 'Індійський 1916',
  'INDIAN 1960': 'Індійський 1960',
  'INDIAN 1975, THAILAND': 'Індійський 1975, Таїланд',
  'IRELAND 1965': 'Ірландія 1965',
  'IRELAND 1975': 'Ірландія 1975',
  'IRKUTSK, USSR': 'Іркутськ, СРСР',
  'ISHIGAKI SHIMA (JAPAN)': 'Ішигакі Шима (Японія)',
  'JOHNSTON ISLAND 1961': 'Острів Джонстон 1961',
  'KERTAU (MALAYAN REVISED TRIANGULATION) 1948, MALAYSIA (WEST)': 'Кертау (малаянська переглянута тріангуляція) 1948, Малайзія (Захід)',
  'KERTAU, REPSOLD SYSTEM (MALAYSIA)': 'Kertau, Repsold System (Малайзія)',
  'KOBOKUTEI (TAIWAN)': 'Kobokutei (Тайвань)',
  'KOSTROMA, USSR': 'Костром, СРСР',
  'LE POUCE (MAURITIUS)': 'Le Pouce (Маврикій)',
  'LEMUTA (WESTERN SAMOA)': 'Лемута (Західний Самоа)',
  'LIBERIA 1964': 'Ліберія 1964',
  'LISBON (PORTUGAL)': 'Лісабон (Португалія)',
  'LOCAL ASTRONOMIC STATIONS': 'Місцеві астрономічні станції',
  'LUZON 1911 (PHILIPPINES)': 'Luzon 1911 (Філіппіни)',
  'MADEIRA ARCHIPELAGO': 'Архіпелаг Мадейра',
  'MADRID 1853 (SPAIN)': 'Мадрид 1853 (Іспанія)',
  'MAROSVASARHELY, HUNGARY': 'Маросвасархелі, Угорщина',
  'MASSAUA 1924 (ETHIOPIA)': 'Massaua 1924 (Ефіопія)',
  'MECURY 1960': 'Mecury 1960',
  'MIDWAY ASTRO 1961': 'Midway Astro 1961',
  'MISURATA MARINA, LIBYA': 'Misurata Marina, Лівія',
  'MONTE MARIO 1940 (ITALY)': 'Монте Маріо 1940 р. (Італія)',
  'MORTJONG LOWE 1932 (INDONESIA)': 'Mortjong Lowe 1932 (Індонезія)',
  'MOUNT CAMPBELL (AUSTRALIA)': 'Маунт Кемпбелл (Австралія)',
  'MURMANSK, USSR': 'Мурманськ, СРСР',
  'NAHRWAN (IRAQ)': 'Нарван (Ірак)',
  'NANKING 1960, CHINA': 'Nanking 1960, Китай',
  'NANKING ASTRO, CHINA': 'Nanking Astro, Китай',
  'NAPARIMA (TRINIDAD AND TOBAGO)': 'НАПАРІМА (Тринідад і Тобаго)',
  'NEW BRITISH, GREAT BRITAIN': 'Новий британський, Великобританія',
  'NEW BULGARIAN': 'Новий болгар',
  'NEW EGYPTIAN 1930': 'Новий єгипетський 1930 рік',
  'NEW FRENCH': 'Нова французька',
  'NORTH AMERICAN 1927': 'Північна Америка 1927 року',
  'NORTH AMERICAN 1983': 'Північна Америка 1983',
  'NORTH SAHARA GEODETIC SYSTEM (ALGERIA)': 'Геодетична система Північної Сахари (Алжир)',
  'OBSERVATORIO DA COMISSAO, BRAZIL': 'Soldertario da Comissao, Бразилія',
  'OBSERVATORIO METEOROLOGICO (PORTUGAL)': 'Meteorologico (Португалія)',
  'OLD EGYPTIAN 1874': 'Старий єгипетський 1874',
  'OLD HAWAIIAN': 'Старий гавайський',
  'OLD ROMANIAN': 'Старий румунський',
  'ORDNANCE SURVEY OF IRELAND': 'Опитування Орденів Ірландії',
  'ORENBURG COSSACKS': 'Оренбургські козаки',
  'ORENBURG TRIANGULATION, USSR': 'Оренбургська триангуляція, СРСР',
  'OSLO OBSERVATORY, OLD (NORWAY)': 'Обсерваторія Осло, стара (Норвегія)',
  'PALESTINE 1928 (ISRAEL/JORDAN)': 'Палестина 1928 р. (Ізраїль/Йордан)',
  'PERTH OBSERVATORY 1989, WESTERN AUSTRALIA': 'Перт Обсерваторія 1989, Західна Австралія',
  'PICO DE LAS NIEVES (SPAIN)': 'Pico de las nieves (Іспанія)',
  'PORTO SANTO 1936 (PORTUGAL)': 'Порто Санто 1936 р. (Португалія)',
  'PROVISIONAL SOUTH AMERICAN 1956': 'Тимчасовий південноамериканський 1956 рік',
  'PULKOVA 1932 SYSTEM, USSR': 'Система Pulkova 1932, СРСР',
  'PULKOVO 1942 (USSR)': 'Pulkovo 1942 (СРСР)',
  'QATAR NATIONAL': 'Катарський національний',
  'QORNOQ (GREENLAND)': 'Qornoq (Гренландія)',
  'RASUSUVA, VITI LEVU ISLAND, FIJI': 'Расусува, острів Віті Леву, Фіджі',
  'RED BRIDGE ASTRONOMIC STATION, CHINA': 'Астрономічна станція Червоного мосту, Китай',
  'REYKJAVIK (ICELAND)': 'Рейк\'явік (Ісландія)',
  'ROYAL GOVERNMENT OF AFGHANISTAN': 'Королівський уряд Афганістану',
  'SAKHALIN MINING AND GEOLOGICAL SURVEY (SMGS), USSR': 'Сахаліновий видобуток та геологічна служба (SMG), СРСР',
  'SAO BRAZ (PORTUGAL)': 'Сан -Браз (Португалія)',
  'SAPPER HILL 1943 (FALKLAND ISLANDS)': 'Sapper Hill 1943 (Фолклендські острови)',
  'SELVAGEM GRANDE, MADEIRA': 'Сельвагем Гранде, Мадейра',
  'SIERRA LEONE 1960, SIERRA LEONE': 'Сьєрра -Леоне 1960, Сьєрра -Леоне',
  'SOUTH AMERICA 1969': 'Південна Америка 1969',
  'SOUTH ASIA OR SOUTHEAST ASIA': 'Південна Азія або Південно -Східна Азія',
  'SPITZBERGEN, SVALBARD': 'Spitzbergen, Svalbard',
  'ST. ANNA DATUM, ROMANIA': 'Вул. Анна Датум, Румунія',
  'STOCKHOLM (SWEDEN)': 'Стокгольм (Швеція)',
  'SVOBODNYY 1935 SYSTEM, USSR': 'Система Svobodnyy 1935, СРСР',
  'SYDNEY OBSERVATORY, NEW SOUTH WALES, AUSTRALIA': 'Обсерваторія Сіднея, Новий Південний Уельс, Австралія',
  'TASHKENT 1875 SYSTEM, USSR': 'Tashkent 1875 система, СРСР',
  'THORSHAVN (FAROE ISLANDS)': 'Торшавн (Фарерські острови)',
  'TIMBALAI 1948 (BRUNEI)': 'Тімбалай 1948 р. (Бруней)',
  'TOKYO 1918 (JAPAN)': 'Токіо 1918 р. (Японія)',
  'TRINIDAD TRIGONOMETRICAL SURVEY 1903 TRINIDAD, LESSER ANTILLES': 'Тринідад Тригонометричне обстеження 1903 р. Тринідад, Менші Антильські острови',
  'TSITSIHAR (LUNGKIANG), MANCHURIA': 'Tsitsihar (Lungkiang), Маньчжурія',
  'UMM ER RUS (SAUDI ARABIA)': 'Умм е -рас (Саудівська Аравія)',
  'UNDETERMINED': 'Невизначений',
  'URAL MAP CONTROL, USSR': 'Уралне управління картою, СРСР',
  'URAL MINING PLANTS (ASSUMED), USSR': 'Уральні гірничі рослини (припускаються), СРСР',
  'URAL MINING PLANTS, USSR': 'Уральські видобутки, СРСР',
  'USSR 1923 SYSTEM': 'Система СРСР 1923',
  'VARESMAE (SYSTEM 1937), ESTONIA': 'Varesmae (система 1937), Естонія',
  'VARIOUS DATUMS': 'Різні дані даних',
  'VIENNA, AUSTRIA': 'Відень, Австрія',
  'VITI LEVU 1916 (FIJI)': 'Віті Леву 1916 р. (Фіджі)',
  'VLADIVOSTOK 1903, USSR': 'Владивосток 1903, СРСР',
  'VOIROL (ALGERIA)': 'Voirol (Алжир)',
  'VOLGAR RIVER TRIANGULATION': 'Тріангуляція річки Волгар',
  'WORLD GEODETIC SYSTEM (GENERAL)': 'Всесвітня геодезична система (загальна)',
  'WORLD GEODETIC SYSTEM 1960': 'Всесвітня геодезична система 1960',
  'WORLD GEODETIC SYSTEM 1966': 'Всесвітня геодезична система 1966',
  'WORLD GEODETIC SYSTEM 1972': 'Всесвітня геодезична система 1972',
  'WORLD GEODETIC SYSTEM 1980': 'Всесвітня геодезична система 1980',
  'WORLD GEODETIC SYSTEM 1984': 'Всесвітня геодезична система 1984',
  'YU-SHAN ASTRO, CHINA': 'Ю-Шан Астро, Китай',
  'INSTALLATION LOCATION': 'Місце встановлення',
  'PHYSICAL NETWORK, NODE LOCATION': 'Фізична мережа, розташування вузла',
  'TARGETING DESIRED MEAN POINT OF IMPACT LOCATION': 'Націлювання на бажану середню точку впливу',
  'TRACK LOCATION': 'Розташування треку',
  'UNIT ALTERNATE LOCATION': 'Альтернативне розташування одиниці',
  'FACILITY LOCATION': 'Місце об\'єкта',
  'EQUIPMENT LOCATION': 'Розташування обладнання',
  'UNIT LOCATION': 'Розташування одиниці',
  'EVENT LOCATION': 'Місце події',
  'FACILITY ANNEX LOCATION': 'Місце розташування прибутку',
  'INDIVIDUAL ADDRESS LOCATION': 'Індивідуальне розташування адреси',
  'OBSERVATION LOCATION': 'Місце спостереження',
  'PHYSICAL NETWORK, LINK LOCATION': 'Фізична мережа, розташування посилань',
  'GEOGRAPHIC REFERENCE, ANALYTICAL': 'Географічна довідка, аналітична',
  'GEOGRAPHIC REFERENCE, OFFICIAL': 'Географічна довідка, офіційна',
  'GEOGRAPHIC REFERENCE, SEARCHED': 'Географічна довідка, пошук',
  'FOR INTEL USE: ELABORATE OR SPECIFY IN REMARKS FIELD OF SET AS NECESSARY': 'Для використання Intel: розробити або вкажіть у полі зауваження набору за необхідності',
  'OFFSET': 'Зміщення',
  'REFERENCE POINT GRAPHIC (RPG) HAS BEEN PRODUCED.': 'Виготовлено графіку опорної точки (RPG).',
  'WAY POINT': 'Шлях',
  'CARTOMETRIC': 'Картометричний',
  'ELLIPSOID ELINT OR SIGINT BASED': 'Еліпсоїд Елінт або Сигінт',
  'GEODETIC': 'Геодетний',
  'IMAGERY - IMAGE DATA EXPLOITATION (IDEX)': 'Зображення - Експлуація даних зображень (IDEX)',
  'IMAGERY - LIGHT TABLE': 'Образи - легкий стіл',
  'COMPUTER MAP ENTRY': 'Вхід на комп\'ютерну карту',
  'CONVENTIONAL MAP': 'Звичайна карта',
  'OFFICIAL STANDARD (PROVIDED BY DIA, DMA, NSA, STATE DEPT., ETC.)': 'Офіційний стандарт (надається DIA, DMA, NSA, Державним департаментом тощо)',
  'GLOBAL POSITIONAL SYSTEM (GPS)': 'Глобальна позиційна система (GPS)',
  'GLOBAL POSITIONAL SYSTEM (GPS), MILITARY MODE, 16-METRE ACCURACY': 'Глобальна позиційна система (GPS), військовий режим, 16-метрова точність',
  'GLOBAL POSITIONAL SYSTEM (GPS), STANDARD MODE, 100-METRE ACCURACY': 'Глобальна позиційна система (GPS), стандартний режим, 100-метрова точність',
  'RPG HAS BEEN PRODUCED': 'РПГ виробляється',
  'ARKHANGELSK': 'Архангельськ',
  'BOROVSKAYA': 'Боровська',
  'BUCHAREST, ROMANIA': 'Бухарест, Румунія',
  'CZARIST INDEPENDENT': 'Царський незалежний',
  'IMAN 1914': 'Іман 1914',
  'IRKUTSK': 'Іркутський',
  'KOSTROMA': 'Кострома',
  'MURMANSK': 'Мурманський',
  'ORENBURG TRIANGULATION': 'Оренбургська триангуляція',
  'PULKOVO 1932 SYSTEM': 'Система Pulkovo 1932',
  'PULKOVO 1942 SYSTEM': 'Система Pulkovo 1942',
  'SVOBODNYY 1935 SYSTEM': 'Система Svobodnyy 1935',
  'TASHKENT 1875 SYSTEM': 'Tashkent 1875',
  'URAL MAP CONTROL': 'Уралне управління картою',
  'URAL MINING PLANTS': 'Уральні видобуток рослини',
  'URAL MINING PLANTS (ASSUMED)': 'Уральні видобуток (припускаються)',
  'VLADIVOSTOK, 1903': 'Владивосток, 1903',
  'VOLGA RIVER TRIANGULATION': 'Тріангуляція річки Волга',
  'WORLD GEODETIC SYSTEM 1966 (NWL-8D)': 'Всесвітня геодезична система 1966 р. (NWL-8d)',
  'WORLD GEODETIC SYSTEM, (YEAR NOT DEFINED)': 'Всесвітня геодезична система (рік не визначений)',
  'EVERY TWO WEEKS.': 'КОЖНИХ ДВА ТИЖНІ.',
  "ENTITY'S LOSS WOULD HINDER THE COUNTRY'S ABILITY TO PERFORM THIS FUNCTION OR SERVICE, HOWEVER, THE EFFECT WOULD NOT BE IMMEDIATE DUE TO OTHER FACTORS SUCH AS PRODUCTION CYCLE OR AVAILABLE STOCKS.": 'Втрати суб\'єкта господарювання перешкоджатимуть здатності країни виконувати цю функцію чи послугу, однак ефект не буде негайним через інші фактори, такі як цикл виробництва або наявні акції.',
  "ENTITY'S LOSS WOULD HINDER THE COUNTRY'S ABILITY TO PERFORM THIS FUNCTION OR SERVICE. LOSS WOULD CAUSE CONSIDERABLE DISRUPTION TO THE ENTITY'S EQUIPMENT INDUSTRY ALTHOUGH OTHER EQUIPMENT COULD BE PARTIALLY REPLACED FROM ALTERNATE SOURCES.": 'Втрата суб\'єкта господарювання перешкоджає здатності країни виконувати цю функцію чи послугу. Втрата спричинить значні зриви для галузі обладнання суб\'єкта господарювання, хоча інше обладнання може бути частково замінене від альтернативних джерел.',
  "LOSS WOULD EFFECTIVELY ELIMINATE THE COUNTRY'S CAPABILITY TO PERFORM THIS FUNCTION OR SERVICE. THE ENTITY IS UNIQUE AND IRREPLACEABLE BY OTHER'S WITHIN COUNTRY. THE ENTITY IS OF PRIMARY IMPORTANCE TO SIGNIFICANT CUSTOMERS.": 'Втрати ефективно усунуть можливість країни виконувати цю функцію чи послугу. Суб\'єкт господарювання унікальний і незамінний з боку інших країн. Суб\'єкт господарювання має основне значення для значних клієнтів.',
  "ENTITY'S LOSS WOULD HINDER COUNTRY'S ABILITY TO PERFORM FUNCTION OR SERVICE, BUT MINOR MODIFICATIONS TO OTHER ENTITIES COULD SERVE TO REPLACE FUNCTIONS OR SERVICES. ADEQUATE REPLACEMENT OF ENTITY EQUIPMENT IS POSSIBLE WITH DIFFICULTY": 'Втрати суб\'єкта господарювання перешкоджатимуть здатності країни виконувати функції чи послугу, але незначні зміни для інших суб\'єктів можуть слугувати для заміни функцій чи послуг. Адекватна заміна суб\'єкта обладнання можлива з труднощами',
  "ENTITY'S LOSS WOULD HINDER THE COUNTRY'S ABILITY TO PERFORM THIS FUNCTION OR SERVICE, BUT SURGES AT OTHER ENTITIES COULD REPLACE AT LEAST HALF OF THE FUNCTION OR SERVICE IN THE NEAR TERM.": 'Втрати суб\'єкта господарювання перешкоджатимуть здатності країни виконувати цю функцію чи послугу, але сплески інших організацій можуть замінити щонайменше половину функції чи послуги в найближчій перспективі.',
  "ENTITY'S LOSS WOULD NOT HAVE A SIGNIFICANT EFFECT ON THE FUNCTION OR SERVICE. LOSS OF ENTITY WOULD BE PRACTICALLY INSIGNIFICANT SINCE ALTERNATE SOURCES EXIST SO AS TO CAUSE NO SIGNIFICANT DETERIORATION OF FUNCTION OR SERVICE.": 'Втрати суб\'єкта не мали б суттєвого впливу на функцію чи послугу. Втрата суб\'єкта господарювання була б практично незначною, оскільки існують альтернативні джерела, щоб не спричинило суттєвого погіршення функції чи послуги.',
  "ENTITY'S LOSS WOULD HAVE NO EFFECT ON THE COUNTRY'S CAPABILITY TO PERFORM THIS FUNCTION OR SERVICE.": 'Втрата суб\'єкта господарювання не вплине на здатність країни виконувати цю функцію чи послугу.',
  'HAS HIGHEST PRIORITIES FOR REPAIR, RECONSTRUCTION AND REPLACEMENT OF DAMAGED OR OUTMODED EQUIPMENT. ENTITY IS SO LOCATED THAT SKILLED TECHNICAL PERSONNEL, LABOR OR EQUIPMENT ARE EASILY OBTAINABLE WHEN NEEDED FOR RECONSTRUCTION AND REPAIRS.': 'Має найвищі пріоритети для ремонту, реконструкції та заміни пошкодженого або застарілого обладнання. Організація настільки розташована, що кваліфікований технічний персонал, праця чи обладнання легко можна отримати, коли це потрібно для реконструкції та ремонту.',
  'PRIORITIES ARE AS HIGH AS TOP PRIORITY, BUT ENTITY IS LOCATED FARTHER FROM SOURCES OF EQUIPMENT AND SKILLED MANPOWER, SO THAT REPAIRS WOULD BE SOMEWHAT DELAYED, OR ENTITY PRIORITIES MAY NOT BE AS HIGH AS THOSE IN TOP PRIORITY.': 'Пріоритети такі ж високі, як і першочерговий, але суб\'єкт господарювання розташована далі від джерел обладнання та кваліфікованої робочої сили, так що ремонт був би дещо затягнутий, або пріоритети суб\'єктів можуть бути не такими високими, як у першому пріоритеті.',
  'ENTITY IS SUFFICIENTLY REMOVED FROM MANUFACTURING AREAS AND / OR PRIORITIES ARE CONSIDERABLE LOWER THAN IN TOP OR HIGH PRIORITY, SO THAT CONSIDERABLE DELAYS IN OBTAINING SKILLED MANPOWER AND EQUIPMENT MUST BE EXPECTED.': 'Суб\'єкт господарювання достатньо видаляється з виробничих районів та / або пріоритетів значно нижчі, ніж у верхньому або високому пріоритеті, так що слід очікувати значних затримок у отриманні кваліфікованої робочої сили та обладнання.',
  'ENTITY OPERATES IN A REMOTE NETWORK AND PRIORITIES FOR EQUIPMENT AND SKILLED MANPOWER ARE VERY LOW. VERY LONG DELAYS FOR REPLACEMENT AND REPAIRS ARE COMMON.': 'Суб\'єкт працює у віддаленій мережі, а пріоритети для обладнання та кваліфікованої робочої сили дуже низькі. Дуже довгі затримки для заміни та ремонту є поширеними.',
  'ENTITY IS OPERATING WITH OUTDATED TECHNOLOGY OR LOCATED IN SUBSTANDARD BUILDING STRUCTURES SUCH THAT ANY DAMAGE WOULD RESULT IN SCRAPPING THE ENTITY AND CONSIDERING A TOTAL REBUILD.': 'Суб\'єкт господарювання працює зі застарілими технологіями або розташована в нестандартних будівельних конструкціях, щоб будь -яка шкода призвела до того, що вони призведуть до скасування суб\'єкта господарювання та врахування загальної відновлення.',
  'A-FRAME, TRAPEZODIAL': 'A-Frame, трапеція',
  'BLIMP': 'Шпигувати',
  'BLIMP, HORIZONTAL ORIENTATION': 'Blimp, горизонтальна орієнтація',
  'BLIMP, VERTICAL ORIENTATION': 'Blimp, вертикальна орієнтація',
  'CONCAVE': 'Увігнутий',
  'CIRCULAR': 'Круговий',
  'CONVEX': 'Опуклий',
  'CYLINDRICAL': 'Циліндричний',
  'CYLINDRICAL, HORIZONTAL ORIENTATION': 'Циліндрична, горизонтальна орієнтація',
  'CYLINDRICAL, VERTICAL ORIENTATION': 'Циліндрична, вертикальна орієнтація',
  'DAISY': 'Ромашка',
  'FAN': 'Фанат',
  'GRID': 'Сітка',
  'HERINGBONE': 'Ірінгбон',
  'IRREGULAR': 'Нерегулярний',
  'LINK': 'Зв\'язок',
  'L-SHAPED': 'L-подібний',
  'EXPLAIN IN SET REMARKS FIELD': 'Поясніть у поле наборі зауважень',
  'OVAL': 'Овальний',
  'PACKAGED CONTAINER': 'Упакований контейнер',
  'RECTANGULAR': 'Прямокутний',
  'RECTANGULAR, HORIZONTAL': 'Прямокутний, горизонтальний',
  'RECTANGULAR, VERTICAL': 'Прямокутний, вертикальний',
  'SPHEROIDAL OR BALLOON ORIENTATION': 'Сфероїдна або балонна орієнтація',
  'SEMICIRCLE': 'Півкруг',
  'SPHERICAL': 'Сферичний',
  'SQUARE': 'МАЙДАН',
  'STAR': 'Зірка',
  'STANDPIPE ORIENTATION': 'Орієнтація',
  'STRAIGHT': 'Прямо',
  'TRIANGULAR': 'Трикутний',
  'T-SHAPED': 'Т-подібний',
  'U-SHAPED': 'U-подібний',
  'VARIOUS': 'Різний',
  'ABCA TARGET IDENTITY': 'Цільова ідентичність ABCA',
  'BASIC ENCYCLOPEDIA NUMBER': 'Основний номер енциклопедії',
  'A UNIQUE IDENTIFIER OF A SPECIFIC ELECTRONIC SITE AND ITS ASSOCIATED EQUIPMENT (ALSO KNOWN AS \PIN NUMBER\")."': 'Унікальний ідентифікатор конкретного електронного сайту та пов\'язане з ним обладнання (також відомий як \ PIN -номер \ ")."',
  'FIBE NUMBER': 'Номер фібра',
  'HULL-TO-EMITTER CORRELATION NUMBER': 'Номер кореляції корпусу',
  'BASIC ENCYCLOPEDIA NUMBER WITH SUFFIX': 'Основний номер енциклопедії з суфіксом',
  'THEATER TARGET NUMBER': 'Цільовий номер театру',
  'PRECISION LOCATION STRIKE SYSTEM IDENTIFICATION NUMBER.': 'Ідентифікаційний номер системи страйку точного розташування.',
  '1/8': '1/8',
  '1/4': '1/4',
  '3/8': '3/8',
  '1/2': '1/2',
  '5/8': '5/8',
  '3/4': '3/4',
  '7/8': '7/8',
  'OVERCAST': 'Похмурий',
  'NOT OBSERVED': 'Не спостерігається',
  'NO LOW CLOUDS': 'Немає низьких хмар',
  '500 FEET OR LESS': '500 футів або менше',
  '1000 FEET': '1000 футів',
  '1500 FEET': '1500 футів',
  '2000 FEET': '2000 футів',
  '2500 FEET': '2500 футів',
  '3000 FEET': '3000 футів',
  '3500 FEET': '3500 футів',
  '4000 FEET': '4000 футів',
  '0 TO LESS THAN 1KM OR 0 TO LESS THAN 1/2NM': '0 до менше 1 км або 0 до менше 1/2 нм',
  '1 TO LESS THAN 2KM OR 1/2 TO LESS THAN 1NM': '1 - менше 2 км або 1/2 до менше 1 нм',
  '2 TO LESS THAN 3KM OR 1 TO LESS THAN 1 1/2NM': '2 до менше 3 км або 1 до менше 1/2 нм',
  '3 TO LESS THAN 4KM OR 1 1/2 TO LESS THAN 2NM': '3 - менше 4 км або 1 1/2 до менше 2 нм',
  '4 TO LESS THAN 5KM OR 2 TO LESS THAN 3NM': '4 до менше 5 км або 2 до менше 3 нм',
  '5 TO LESS THAN 6KM OR 3NM': '5 до менше 6 км або 3 нм',
  '6 TO LESS THAN 7KM OR MORE THAN 3 TO LESS THAN 4NM': 'Від 6 до менше 7 км або більше 3 до 4 нм',
  '7 TO LESS THAN 8KM OR 4 TO LESS THAN 5NM': '7 до менше 8 км або 4 до менше 5 нм',
  '8KM OR GREATER, OR 5NM OR GREATER': '8 км або більше, або 5 нм або більше',
  'NOT REPORTED': 'Не повідомляється',
  'NOT SIGNIFICANT': 'Несуттєво',
  'SLEET': 'Мокрий сніг',
  'DUST OR SMOKE': 'Пил або дим',
  'FOG OR HAZE': 'Туман або імла',
  'SNOW': 'Сніг',
  'SHOWERS': 'Злив',
  'THUNDERSTORMS': 'Грози',
  'HIGH': 'Високий',
  'LOW': 'Низький',
  'ENCODED WEATHER IS SIMULATED (EXERCISE USE)': 'Кодована погода моделюється (використання вправ)',
  'MULTIPLY CLOUD HEIGHTS BY 10': 'Помножте висоту хмари на 10',
  'NO MEDIUM OR SCATTERED MEDIUM CLOUDS': 'Немає середніх або розкиданих середніх хмар',
  'SCATTERED TO VARIABLE BROKEN MEDIUM CLOUDS': 'Розкиданий до змінних зламаних середніх хмар',
  'BROKEN VARIABLE OVERCAST MEDIUM CLOUDS': 'Зламані змінні прохитні середні хмари',
  'CONTRAILS AT FLIGHT LEVEL': 'Спорції на рівні польоту',
  'IFR= INSTRUMENT FLIGHT RULES': 'IFR = Правила польоту приладу',
  'VFR= VISUAL FLIGHT RULES': 'VFR = Правила візуального польоту',
  'GUSTY WINDS AT SURFACE': 'Поривчасті вітри на поверхні',
  'FOG IN VALLEY': 'Туман у Долині',
  'HIGHER TERRAIN OBSCURED': 'Вища місцевість затьмарена',
  'SURFACE CONDITIONS VARIABLE DUE TO SHOWERS': 'Змінні умови поверхні внаслідок душі',
  'THUNDERSTORMS OCCURRING': 'Грози, що виникають',
  'THUNDERSTORMS ENROUTE': 'Грози',
  'ICING AT FLIGHT LEVEL OR FREEZING PRECIPITATION': 'Глазур на рівні польоту або заморожування опадів',
  'SURFACE WIND NE QUADRANT': 'Поверхневий вітер не квадрант',
  'SURFACE WIND SE QUADRANT': 'Поверхневий вітер se квадрант',
  'SURFACE WIND SW QUADRANT': 'Поверхневий вітер SW квадрант',
  'SURFACE WIND NW QUADRANT': 'Поверхневий вітер NW Квадрант',
  'WEATHER BETTER TO NORTH': 'Погода краще на північ',
  'WEATHER BETTER TO EAST': 'Погода краще на схід',
  'WEATHER BETTER TO SOUTH': 'Погода краще на південь',
  'WEATHER BETTER TO WEST': 'Погода краще на Захід',
  'WEATHER SUITABLE FOR MISSION': 'Погода, придатна для місії',
  'WEATHER MARGINAL FOR MISSION': 'Погода гранична для місії',
  'WEATHER UNSUITABLE FOR MISSION': 'Погода непридатна для місії'
};

const messagesList = {
  "AirAttackWarning": "Повітряна тривога",
  "Air Raid Warning": "Повітряна тривога",
  "AmmunitionFireUnitAmmunitionStatus": "Артилерійський вогневий підрозділ – забезпеченість боєприпасами",
  "AmmunitionFireUnitDeploymentCommand": "Команда щодо розміщення позиційного району вогневого підрозділу",
  "BombShellRocketOrMortarReport": "Звіт щодо бомбардування/донесення про ведення артилерійського та мінометного вогню/донесення про мінометний обстріл",
  "CasualtyEvacuationRequest": "Запит на евакуацію поранених та хворих",
  "Cbrn1BiologicalReport": "РХБЗ 1 Доповідь про біологічне зараження",
  "Cbrn1ChemicalReport": "РХБЗ 1 Доповідь про хімічне зараження",
  "Cbrn1NuclearReport": "РХБЗ 1 Доповідь про радіаційне зараження",
  "Cbrn1RadiologicalReport": "РХБЗ 1 Доповідь про радіологічне зараження",
  "CbrnBasicWindReport": "РХБЗ Доповідь про основний напрямок вітру",
  "CbrnChemicalDownwindReport": "РХБЗ Доповідь про хімічне зараження за напрямком вітру",
  "CbrnEffectiveDownwindReport": "РХБЗ Доповідь про напрям та силу дійсного вітру",
  "CbrnHazardousWarningReport": "РХБЗ Попередження про небезпеку",
  "CbrnSituationReport": "РХБЗ Доповідь про ситуацію",
  "CcisStatusReport": "Доповідь про готовність командно-інформаційної системи",
  "CommunicationsSpotReport": "Доповідь про стан засобів зв’язку",
  "DeploymentEndReport": "Доповідь про завершення розгортання",
  "DeploymentReport": "Доповідь про розгортання",
  "DeploymentStartReport": "Доповідь про початок розгортання",
  "EngineerRecceOrder": "Наказ на інженерну розвідку",
  "EngineerRecceReport": "Доповідь про інженерну розвідку",
  "EvacuationRequest": "Запит на евакуацію",
  "Fire Mission - Call For Fire": "Вогневе завдання – виклик вогню",
  "FireMissionFireMissionCommand": "Вогневе завдання – команда на виконання вогневого завдання",
  "FireMissionSubsequentAdjustment": "Вогневе завдання – подальше коригування",
  "FragmentaryOrder": "Окреме бойове розпорядження",
  "IncidentReport": "Доповідь про інцидент",
  "IncidentSpotReport": "Термінова доповідь про інцидент",
  "IntelligenceReport": "Доповідь про розвідку",
  "LogisticsAssistanceRequest": "Запит на матеріально-технічне забезпечення",
  "LogisticsAssistanceResponse": "Відповідь на запит на матеріально-технічне забезпечення",
  "LogisticSituationReportLandForces": "Доповідь щодо матеріально-технічного забезпечення в підрозділах Сухопутних військ",
  "LogisticUpdateReport": "Уточнена доповідь по матеріально-технічному забезпеченню",
  "MapRequest": "Запит на карти",
  "MessageCorrectionOrCancellation": "Повідомлення про відміну попереднього повідомлення",
  "MeteorologicalComputer": "Метеорологічні дані (комп’ютерний варіант)",
  "MeteorologicalRequestForMet": "Метеорологічний запит",
  "MijiWarningReport": "Оповіщення про роботу засобів радіоелектронної боротьби",
  "Deployment End Report": "Доповідь про завершення розгортання",
  "NonNuclearFirePlanningComputeFirePlan": "План-розрахунок неядерного порядку вогневого ураження",
  "NonNuclearFirePlanningFirePlan": "Планування вогню неядерними боєприпасами – план вогню",
  "NuclearStrikeWarningMessage": "Повідомлення про нанесення ядерного удару по противнику",
  "OrderOfBattleLandForces": "Бойовий склад і дислокація частин та підрозділів – сухопутні війська",
  "OwnLandForcesSituationReport": "Доповідь про стан підрозділу",
  "PersonnelReport": "Доповідь про особовий склад",
  "PresenceReport": "Доповідь про дислокацію",
  "RealEstateRequest": "Запит на розташування",
  "RealEstateResponse": "Відповідь на запит на розташування",
  "SatelliteVulnerabilityReport": "Доповідь про оцінку рівня протидії супутниковому спостереженню",
  "SituationReport": "Оперативне зведення",
  "SystemReplyMessage": "Відповідне системне повідомлення",
  "SystemRequestForReport": "Система-запит для звіту",
  "TargetingRecord": "Дані про цілі",
  "TargetWeatherInformation": "Інформація з метеорологічної обстановки",
  "MedicalEvacuationRequest": 'Запит на медичну евакуацію'
};

const blockDescriptions = {
  "Specifies the message identifier, message originator and other message identity details.": "Визначає ідентифікатор повідомлення, відправника повідомлення і інші деталі, що засвідчують особу відправника повідомлення.",
  "Provides geodetic datum reference for geographic locations in the message.": "Вказує географічну систему координат для географічних даних в повідомленні.",
  "Specifies the Air Raid Warning (ARW) state, affected area name and effective time.": "Встановлює стан повітряного нападу (ARW), назву області ураження та очікуваний час.",
  "Specifies the message identifier, message originator and other message identifying details.": "Визначає ідентифікатор повідомлення, відправника повідомлення і інші деталі, що засвідчують особу відправника повідомлення.",
  "Specifies whether the message is to update or create a record (XMT) or to delete a record (CAN).": "Визначає, чи це повідомлення оновлене, створене або повідомлення що відміняє попереднє повідомлення.",
  "Identifies the unit or organization to which the deployment command applies.": "Ідентифікація підрозділу, до якого застосовується команда розгортання.",
  "Specifies the date-time group of the message.": "Дата та час повідомлення.",
  "Specifies the name of the fire plan to which the message relates.": "Найменування плану вогневого ураження, до якого відноситься повідомлення.",
  "Specifies the projectile type and quantity associated with a specific fire plan, or if no fire plan is indicated, for current operations.": "Визначає тип снаряду і кількість, пов'язану з конкретним планом вогневого ураження, або якщо жоден план вогневого ураження не вказано, то для поточних операцій.",
  "Specifies the propellant type and quantity associated with a specific fire plan, or if no fire plan is indicated, for current operations.": "Визначає тип і кількість боєзаряду, пов'язане з конкретним планом вогневого ураження, або, якщо жоден план вогневого ураження не вказано, то для поточних операцій.",
  "Specifies the fuse type and quantity associated with a specific fire plan, or if no fire plan is indicated, for current operations.": "Визначає тип запобіжника і кількість, пов'язані з конкретним планом вогневого ураження, або якщо жоден план вогневого ураження не вказано, то для поточних операцій.",
  "Specifies the name of the deployment order or deployment command.": "Встановлює назву графіка розгортання або команди на розгортання.",
  "Identifies the location (in higher order coordinates) at which liaison is to take place.": "Визначає місце розташування (в координатах більш високого порядку), де повинна бути взаємодія.",
  "Specifies the date-time group when liaison is to occur.": "Задає дату-час, коли буде проводитись сеанс зв’язку.",
  "Specifies the tactical mission assigned to the fire unit identified in set UNT and the zone of responsibility to which it applies.": "Визначає тактичну задачу, на яку виділено боєкомплект вогневого підрозділу і зону відповідальності, до якої вона відноситься.",
  "Specifies the effective date-time of mission assignment.": "Задає дату і час постановки задачі.",
  "Specifies the manoeuvre unit to be supported.": "Задає блок МАНЕВР для забезпечення підтримки.",
  "Specifies the artillery unit being reinforced.": "Вказує артилерійські підрозділи підсилення.",
  "Specifies a location or area to move to, or route of march to be followed.": "Визначає місце розташування або область, куди необхідно переміститись, або маршрут, яким необхідно рухатись.",
  "Specifies the azimuth of fire for the fire unit.": "Визначає азимут ведення вогню вогневим підрозділом.",
  "Indicates the start and end times for movement to occur.": "Визначає початок і кінець маршу.",
  "Provides the date-time group the message was prepared or authorized for release.": "Вказує дату-час підготовки або доведення повідомлення уповноваженою особою.",
  "Provides the unit designator, unit size, nationality, unit role indicator(s), higher formation name and armed service or civilian agency of the unit that drafted the message or authorized its release.": "Вказує найменування підрозділу, розмір підрозділу, національність та індикатор функціональності підрозділу, вище командування, армійську службу або цивільні агенції яка відправила повідомлення.",
  "PROVIDES THE DTG THE REPORT WAS AUTHORISED FOR RELEASE.": "Визначає дату і час оформлення повідомлення.",
  "PROVIDES THE IDENTITY OF THE UNIT THAT RELEASED THE MESSAGE.": "Визначає умовне найменування організації відправника повідомлення.",
  "PROVIDES THE TYPE OF REPORT.": "Визначає тип звіту.",
  "PROVIDES INFORMATION ON THE INCIDENT.": "Надає інформацію про інцидент.",
  "PROVIDES LOCATION AND DIRECTION.": "Визначає місце розташування і напрямок.",
  "PROVIDES INCIDENT DURATION.": "Визначає тривалість інциденту.",
  "PROVIDES THE INCIDENT LOCATION": "Визначає місце інциденту.",
  "PROVIDES INFORMATION ON DELIVERY AND SUBSTANCE CONTAINERS": "Визначає інформацію про засоби доставки і тип речовини в контейнерах.",
  "PROVIDES RELEASE INFORMATION ON CBRN INCIDENTS": "Визначає вихідну інформацію про РХБХ інцидент.",
  "PROVIDES A DESCRIPTION AND STATUS OF A BIOLOGICAL INCIDENT.": "Визначає опис і статус біологічного інциденту.",
  "PROVIDES A DESCRIPTION OF THE TERRAIN": "Визначає опис місцевості.",
  "PROVIDES THE WIND DIRECTION AND SPEED": "Визначає напрямок і швидкість вітру.",
  "PROVIDES MEASURED METEOROLOGICAL INFORMATION": "Визначає метеорологічну інформацію.",
  "PROVIDES CBRN INFORMATION": "Визначає РХБЗ інформацію.",
  "PROVIDES THE TYPE OF REPORT": "Визначає тип звіту.",
  "PROVIDES INFORMATION ON THE INCIDENT": "Визначає інформацію про інцидент.",
  "PROVIDES LOCATION AND DIRECTION": "Визначає місце розташування і напрямок.",
  "PROVIDES INCIDENT DURATION": "Визначає тривалість інциденту.",
  "PROVIDES A DESCRIPTION AND STATUS OF A CHEMICAL INCIDENT.": "Визначає опис і стан хімічного інциденту.",
  "PROVIDES THE TYPE OF NUCLEAR BURST.": "Визначає тип ядерного вибуху.",
  "PROVIDES THE TIME BETWEEN FLASH AND BANG": "Визначає час між спалахом і вибухом.",
  "PROVIDES THE ANGULAR CLOUD WIDTH": "Визначає на кутову ширину хмари.",
  "PROVIDES THE STABILIZED CLOUD MEASUREMENT": "Визначає стабільність хмари.",
  "PROVIDES THE CONTOUR OF RADIOACTIVE CLOUD": "Визначає зовнішній контур радіоактивної хмари.",
  "PROVIDES THE DIRECTION OF RADIOACTIVE CLOUD": "Визначає напрямок радіоактивної хмари.",
  "Specifies the message identifier, message originator, and other message identity details.": "Визначає ідентифікатор повідомлення, відправника повідомлення і інші деталі, що засвідчують особу відправника повідомлення.",
  "Provides the DTG that the report was authorised for release.": "Визначає дату і час оформлення повідомлення.",
  "Provides the identity of the unit that released the message.": "Визначає умовне найменування організації відправника повідомлення.",
  "Specifies the type of CBRN report.": "Вказує тип звіту РХБЗ.",
  "Specifies the incident serial number.": "Вказує на серійний номер інциденту.",
  "Provides the observer's geographic location and direction of incident.": "Визначає на розташування спостерігача і напрямок поширення інциденту.",
  "Provides the duration of the incident.": "Визначає на тривалість інциденту.",
  "Provides the incident location.": "Визначає місце інциденту.",
  "Provides information on delivery and quantity.": "Визначає інформацію про засоби доставки та обмеження інформації.",
  "Provides release information on radiological incidents.": "Визначає інформацію про радіаційні інциденти.",
  "Provides a description and status of a radiological incident.": "Надає опис і статус радіологічного інциденту.",
  "Provides a description of terrain, topography and vegetation.": "Визначає опис місцевості, рельєфу і рослинності.",
  "Specifies the representative downwind direction and representative downwind speed.": "Визначає напрямок і швидкість вітру з підвітряної сторони.",
  "Provides measured meteorological information.": "Визначає метеорологічну інформацію.",
  "Provides additional CBRN information on the chemical incident.": "Визначає додаткову РХБЗ інформацію про хімічний інцидент.",
  "DEFINES THE AREA OF VALIDITY.": "Визначає область валідації.",
  "THE DTG OF THE OBSERVATION AND THE VALIDITY PERIOD.": "Дата-час спостереження і термін дії.",
  "GIVES UNITS OF MEASUREMENT FOR MET DATA": "Визначає одиниці виміру для метеоданих.",
  "SPECIFIES WIND INFORMATION.": "Вказує інформацію про вітер.",
  "PROVIDES THE TYPE OF METEOROLOGICAL REPORT": "Визначає тип метеорологічного звіту.",
  "DEFINES THE AREA OF VALIDITY": "Визначає область валідації.",
  "PROVIDES THE SURFACE METEOROLOGICAL CONDITIONS FOR THE FIRST TWO HOUR PERIOD.": "Визначає метеорологічні умови на поверхні протягом перших двох годин.",
  "PROVIDES THE SURFACE METEOROLOGICAL CONDITIONS FOR THE SECOND TWO HOUR PERIOD.": "Визначає метеорологічні умови на поверхні  для другого двогодинного періоду.",
  "PROVIDES THE SURFACE METEOROLOGICAL CONDITIONS FOR THE THIRD TWO HOUR PERIOD.": "Визначає метеорологічні умови на поверхні для третього періоду дві години.",
  "Provides the type of CBRN report or CBRN weather report.": "Визначає тип метеорологічного звіту. Визначає тип звіту РХБЗ або про погоду.",
  "Defines the area of validity.": "Визначає область валідації.",
  "Provides the DTG of the observation and the validity period.": "Визначає дані про дату і час спостереження і термін дії.",
  "Provides units of measurement for meteorological data.": "Визначає одиниці виміру для метеорологічних даних.",
  "Provides meteorological information for yield group ALFA.": "Визначає метеорологічну інформацію для групи ALFA.",
  "Provides meteorological information for yield group BRAVO.": "Визначає метеорологічну інформацію для групи BRAVO.",
  "Provides meteorological information for yield group CHARLIE.": "Визначає метеорологічну інформацію для групи CHARLIE.",
  "Provides meteorological information for yield group DELTA.": "Визначає метеорологічну інформацію для групи DELTA.",
  "Provides meteorological information for yield group ECHO.": "Визначає метеорологічну інформацію для групи ECHO.",
  "Provides meteorological information for yield group FOXTROT.": "Визначає метеорологічну інформацію для групи FOXTROT.",
  "Provides meteorological information for yield group GOLF.": "Визначає метеорологічну інформацію для групи GOLF.",
  "Specifies the start and end DTG of the incident.": "Вказує початкове та кінцеве значення дати та часу інциденту.",
  "Provides release information on CBRN incidents.": "Визначає вихідну інформацію про РХБЗ інцидент.",
  "Provides a description and status of a CBRN incident.": "Надає опис і статус РХБЗ інциденту.",
  "Provides the predicted incident of the release and hazard area.": "Визначає прогнозований інцидент і область небезпеки.",
  "Specifies the predicted radiological incident release and hazard area.": "Визначає прогнозований радіологічний інцидент і область небезпеки.",
  "Provides the reference DTG for estimated contour lines in ZULU-time.": "Визначає дату-час для розрахункових контурних ліній в ZULU часу.",
  "Provides additional information on the CBRN incident.": "Визначає додаткову інформацію про РХБЗ інцидент.",
  "PROVIDES SITUATION REPORT": "Визначає звіт про ситуацію.",
  "Provides the unique serial number and the unique nickname assigned to a specific task.": "Визначає унікальний серійний номер і унікальний псевдонім, присвоєний конкретній задачі.",
  "Provides a free text statement of the aim of reconnaissance to be carried out.": "Визначає унікальний серійний номер і унікальний псевдонім, присвоєний конкретній задачі.",
  "Indicates when the report is to be submitted, the earliest the reconnaissance can begin, and when the reconnaissance must complete.": "Вказує, що звіт повинен бути представлений не раніше початку розвідки і коли повинна завершитися розвідка.",
  "Specifies whether an acknowledgement is required or not.": "Вказує, чи потрібне підтвердження чи ні.",
  "Provides the date-time group at which the reconnaissance was completed.": "Визначає дату-час, коли була завершена розвідка.",
  "Indicates the capability of the unit to complete the task, the time required to complete it, and the limitation identified.": "Вказує здатність підрозділу, виконати поставлене завдання, час, необхідний для його завершення та обмеження.",
  "The target number assigned to the target being attacked.": "Визначає номер цілі, присвоєний цілі для ураження.",
  "Specifies the number of weapons to be fired in effect against the target.": "Вказує кількість вогневих засобів в залпі для ураження цілі.",
  "Provides the location and the altitude in metres of the target.": "Визначає відстань і висоту в метрах від цілі.",
  "Provides the target type, subtype and degree of protection.": "Визначає тип цілі, підтип і ступінь захищеності.",
  "Provides the number of target elements.": "Визначає кількість цільових елементів.",
  "Provides the target size as either a length, width and attitude (orientation), or as a radius.": "Визначає розмір цілі: довжину, ширину і положення, або як радіус.",
  "Provides the report value accuracy associated with the target.": "Визначає точність значення даних, пов'язану з ціллю.",
  "Indicates the trajectory to be used to attack the target.": "Визначає траєкторію по якій буде нанесено ураження по цілі.",
  "Specifies the initial projectile/fuze combination and one additional projectile/fuze combination.": "Вказує початкову комбінацію снаряд/детонатор і одну додаткової комбінацію снаряд/детонатор.",
  "Indicates the percent effects to be achieved against the target.": "Вказує, який відсоток ефективності ураження цілі повинен бути досягнутий.",
  "Specifies the method of control to be used in attacking the target.": "Вказує метод контролю під час ураження цілі.",
  "Indicates the firing interval between volleys or the duration of fire against the target.": "Вказує вогневий інтервал між залпами або тривалості ураження цілі.",
  "Used to specify end of mission, or end of mission and record as target.": "Використовується для визначення кінця місії: завершити виконання завдання або завершити виконання завдання і записати.",
  "Provides a specific fire mission command.": "Визначає конкретну команду виконання вогневого завдання.",
  "Indicates the fire plan to which the information applies.": "Вказує найменування плану вогневого ураження, до якого відноситься інформація.",
  "Provides the target number assigned to the target to which the information in the message applies.": "Визначає номер цілі, присвоєний цілі для ураження.",
  "Provides instructions to the observer concerning the conduct of a fire mission.": "Визначає інструкції для спостерігача щодо оцінки вогневого ураження.",
  "Provides target number assigned to the target being attacked.": "Визначає номер цілі, присвоєний цілі для ураження.",
  "Specifies the initial projectile/fuze combination. Fields 2 and 3 of this set are optional in this message.": "Вказує початкову комбінацію снаряда/детонатор. Поля 2 і 3 з цього набору не є обов'язковими.",
  "Specifies end of mission, or end of mission and record as target.": "Визначає кінець місії: завершити виконання завдання або завершити виконання завдання і записати.",
  "Specifies the final disposition of a target and the number of enemy casualties or equipment affected at the end of a mission.": "Визначає остаточне розташування цілі і кількість втрат противника або техніки по завершенню вогневого ураження.",
  "Provides the unit designator, unit size, nationality, unit role indicator(s), higher formation name and armed service of the unit issuing the order.": "Вказує найменування підрозділу, розмір підрозділу, національність та індикатор функціональності підрозділу, вище командування, армійську службу підрозділу яка віддає наказ.",
  "Identifies the type and number of the order, and the order to which it refers.": "Визначає тип і номер наказу, а також наказ, до якого він належить.",
  "Provides the time zone used throughout orders.": "Визначає часову зону, яка використовується в наказі.",
  "Identifies the information that follows is the task organization for the fragmentary order.": "Визначає інформацію, яка визначає організаційну структуру для розпорядження.",
  "Identifies the information that follows is part of the situation paragraph of the order.": "Визначає інформацію, яка є наступною частиною пункту обстановка наказу.",
  "Identifies the information that follows is part of the mission paragraph of the fragmentary order.": "Позначає інформацію, яка є наступною частиною пункту розпорядження.",
  "Identifies the information that follows is part of the execution paragraph of the order.": "Визначає інформацію, яка є наступною частиною пункту виконання наказу.",
  "Provides the unit commander's intent.": "Визначає намір командира підрозділу.",
  "Identifies that the information that follows is part of the service support.": "Визначає, що інформація, яка надається, є частиною матеріально-технічного забезпечення.",
  "Provides the alphabetic character of the reference.": "Визначає алфавітний характер посилання.",
  "Identifies that the information that follows is part of the command and signal paragraph of the fragmentary order.": "Визначає, що інформація, яка випливає, є частиною пункту розпорядження про систему зв’язку та управління.",
  "Indicates that there is no change in the command and signal paragraph from the original order.": "Вказує, що не існує будь-яких змін в системі зв’язку та управління початкового наказу.",
  "Provides details of miscellaneous command and signal information, not included in other fields.": "Визначає відомості про різні команди і сигнали, які не включені в інші поля.",
  "Provides a yes/no indication of whether there is a requirement to acknowledge the message.": "Вказує, чи потрібне підтвердження чи ні.",
  "Provides the unit identifier, country of the world, armed service, specialist role, organization type, and command level of the unit who prepared the message authorized is released.": "Вказує найменування підрозділу, країну, армійську службу, функціональність, тип організації і командний рівень підрозділу який готує донесення або надає дозвіл на відправлення.",
  "Identifies the type of incident being reported.": "Визначає тип інциденту про який доповідають.",
  "Provides the date-time group of the incident.": "Вказує дату-час інциденту.",
  "Specifies the coordinates and description of a location.": "Визначає координати і опис місця розташування.",
  "Provides details of the type of weapons used.": "Визначає відомості про тип зброї, що використовується.",
  "Lists the effect of the incident and any damage caused.": "Список наслідків інциденту і будь-яку завдану шкоду.",
  "Identifies the effect the incident has on operations.": "Вказує вплив інциденту на операції.",
  "Provides a brief description of the incident.": "Містить короткий опис інциденту.",
  "Lists the type and number of casualties.": "Відображає тип і кількість жертв.",
  "Provides the effective day-time the message was prepared or authorized for release.": "Визначає фактичний час підготовки повідомлення або надання дозволу на відправлення.",
  "Indicates the purpose of the report, in plain language.": "Вказує призначення звіту, проста мова.",
  "Provides the unit identifier, country of the world, armed service, specialist role, organization type, and command level of the unit who prepared the message or authorized its release.": "Вказує найменування підрозділу, країну, армійську службу, функціональність, тип організації і командний рівень підрозділу який готує донесення або надає дозвіл на відправлення.",
  "Identifies maps or locations referenced in the message, or reference to an agreed dataset if in an automated GIS-based environment.": "Вказує інформацію для ідентифікації карти або схеми, що використовуються в цьому повідомленні, посилання на набір даних для автоматизованих ГІС, повторюється в разі необхідності.",
  "Specifies the date-time group of the message origin or authorization for release.": "Задає дату-час створення повідомлення або надання дозволу на відправлення.",
  "Specifies the reason for the request in free text.": "Вказує причину запиту довільним текстом.",
  "Specifies the transfer method: pickup or delivery.": "Задає спосіб передачі: самовивіз або доставку.",
  "Specifies the latest time the required maps are to be available.": "Задає останню дату отримання карт.",
  "Provides the name of the nearest feature and the location for delivery.": "Визначає ім'я найближчої координати і місця доставки.",
  "Provides the address for delivery.": "Визначає адресу для доставки.",
  "Provides the global octant, met station location, period of met validity, the height (altitude) of the met station and the surface atmospheric pressure.": "Визначає глобальний октант, місцезнаходження метеорологічної станції, період дії даних, висоту  станції над рівнем моря і поверхневий атмосферний тиск.",
  "Provides met data by zone, consisting of wind direction and speed, air temperature and pressure, for up to 37 altitude zones.": "Визначає метеодані по зонах, що складаються з напряму і швидкості вітру, температури повітря і тиску, до 37 висотних зон.",
  "Identifies the type of meteorological support required, either artillery or CBRN related.": "Вказує на варіант метеоданих, для РВіА чи для забезпечення РХБЗ.",
  "Specifies the area of interest for which the met request is needed.": "Визначає район, для якого необхідний запит на метеодані.",
  "Indicates the times for receipt of the first and last meteorological messages.": "Вказує час отримання першого і останнього метеорологічного повідомлення.",
  "Specifies the lowest and highest altitude bands required, the support duration and hourly intervals between messages.": "Вказує максимальні необхідні висоти, тривалість забезпечення метеоданими і часові інтервали між повідомленнями.",
  "Provides the MIJI incident type being reported.": "Визначає тип інциденту РЕБ про який доповідається.",
  "Provides information about the completion of the deployment.": "Визначає інформацію про завершення розгортання.",
  "Provides declassification or downgrading instructions if the message is classified.": "Визначає розсекречення або інструкції, щодо зниження ступеня таємності повідомлення.",
  "Specifies the H-hour of the fire plan.": "Визначає час відліку «Ч» плану вогневого ураження.",
  "Indicates the estimated start and stop times of the fire plan.": "Вказує час початку і завершення плану вогневого ураження.",
  "Indicates which iteration of the target in the plan the information applies to if the target is attacked more than once.": "Вказує, до якої ітерації цілі в плані інформації відноситься, якщо ціль уражається більш ніж один раз.",
  "Provides the number of target elements the target consists of.": "Надає кількість елементів з яких складається ціль.",
  "Provides the time on target relative to H-hour at which the target is to be engaged.": "Визначає час появи цілі відносно часу відліку.",
  "Indicates the target priority assigned in the fire plan.": "Визначає пріоритетність цілі, визначену в плані вогневого ураження.",
  "Identifies the group to which the target is assigned.": "Вказує групу до якої відноситься ціль.",
  "Indicates the target list to which the target is assigned.": "Вказує список цілей до якого відноситься ціль.",
  "Indicates the number of volleys of the first and second (if any) projectile to be used to attack the target.": "Вказує кількість снарядів (ракет) в першому і другому залпах що будуть залучатись для ураження цілі.",
  "Indicates the first and second (if any) projectile to be used to attack the target.": "Вказує перший і другий (якщо такі є) снаряд (ракету) що будуть залучатись для ураження цілі.",
  "Indicates the first and second (if any) fuze to be used to attack the target.": "Вказує перший і другий (якщо такі є) детонатор (головку самонаведення) що будуть залучатись для ураження цілі.",
  "Indicates the percent effects to be achieved against the target, regardless of whether one or more units are scheduled to attack it.": "Вказує на необхідну ступінь вогневого ураження цілі, незалежно від того скільки снарядів (ракет) планується для ураження.",
  "SPECIFIES THE MESSAGE IDENTIFIER, MESSAGE ORIGINATOR, AND OTHER MESSAGE IDENTITY DETAILS.": "Визначає ідентифікатор повідомлення, відправника повідомлення і інші деталі, що засвідчують особу відправника повідомлення.",
  "PROVIDES GEODETIC DATUM REFERENCE FOR GEOGRAPHIC LOCATIONS IN THE MESSAGE.": "Вказує географічну систему координат для географічних даних в повідомленні.",
  "PROVIDES THE TARGET NUMBER/NICKNAME.": "Визначає номер цілі, коротку назву.",
  "PROVIDES THE DATE-TIME OF STRIKE AND CANCELLATION.": "Визначає дату-час удару і його відміну.",
  "PROVIDES THE MINIMUM SAFE DISTANCE 1 IN HUNDREDS OF METRES AND THE LOCATION.": "Визначає мінімальну безпечну відстань 1 сотні метрів і розташування.",
  "PROVIDES THE MINIMUM SAFE DISTANCE 2 IN HUNDREDS OF METRES AND THE LOCATION.": "Визначає мінімальне безпечну відстань 2 сотні метрів і розташування.",
  "PROVIDES THE NUMBER OF SURFACE BURSTS.": "Визначає кількість наземних взривів.",
  "PROVIDES THE NUMBER OF BURSTS IN A MULTIPLE STRIKE.": "Визначає кількість взривів в кратному ударі.",
  "PROVIDE ACKNOWLEDGEMENT REQUIREMENTS.": "Визначає підтвердження запитів.",
  "Provides the effective day-time of the message.": "Визначає фактичний час повідомлення.",
  "Provides the commander's assessment of the own forces task organization.": "Забезпечує оцінку командира організаційно-штатної структури своїх сил.",
  "Provides the commander's assessment of the own forces presence reports.": "Забезпечує оцінку командира звіту щодо наявності свої військ.",
  "Provides the commanders assessment of the own forces unit status.": "Визначає оцінку командира стану підрозділів своїх військ.",
  "Provides the commander's overall assessment of the own land forces situation.": "Забезпечує загальну оцінку командира ситуації щодо своїх військ.",
  "Provides the unit designator, unit size, nationality, unit role indicator(s), higher formation name and armed service or civilian agency of the reporting unit.": "Визначає найменування підрозділу, розмір підрозділу, національність та індикатор функціональності підрозділу, вище командування, армійську службу або цивільні агенції підрозділу про який доповідається.",
  "Provides the reporting unit's (including subordinate units) overall combat effectiveness in terms of personnel and equipment.": "Визначає (в тому числі за підпорядковані підрозділи) загальну бойову ефективність з точки зору особового складу та озброєння.",
  "Provides an overall assessment of the reporting units including comments on its subordinates.": "Визначає загальну оцінку коментарів підпорядкованих підрозділів.",
  "Provides the unit designator, unit size, nationality, unit role indicator(s), higher formation name and armed service or civilian agency of the requesting unit.": "Визначає найменування підрозділу, розмір підрозділу, національність та індикатор функціональності підрозділу, вище командування, армійську службу або цивільні агенції підрозділу який надає запит.",
  "Provides the unit designator, unit size, nationality, unit role indicator(s), higher formation name and armed service or civilian agency of the unit allocating the real estate.": "Визначає найменування підрозділу, розмір підрозділу, національність та індикатор функціональності підрозділу, вище командування, армійську службу або цивільні агенції підрозділу який виділяє місце розташування.",
  "Provides the period of time for vulnerability to satellite reconnaissance.": "Визначає період часу щодо уразливості супутникової розвідки.",
  "Details the satellite countermeasures to be enforced when vulnerable to satellite reconnaissance.": "Деталі контрзаходів проти супутникової розвідки.",
  "Details emitter control policies which will be enforced during periods of satellite vulnerability.": "Деталі заходів, які будуть застосовуватися в періоди загрози супутникової розвідки.",
  "Provides a short specific reply to a message previously received.": "Визначає коротку конкретну відповідь на раніше отримане повідомлення.",
  "Specifies whether the message is to update or create a record (XMT) or to delete a record (CAN). Field 2 is not used in this message.": "Визначає, чи це повідомлення оновлене, створене або повідомлення що відміняє попереднє повідомлення. Поле 2 в цьому повідомленні не використовується.",
  "Identifies the report being requested. Includes the start and end time the request is valid for and the frequency with which the requested report is to be provided.": "Визначає звіт відповідно до запиту. Включає в себе час початку і закінчення його дії, валідність та з якою частотою запит був відпрацьований.",
  "Indicates the target list being requested.": "Вказує список цілей в запиті.",
  "Indicates the battlefield geometry information being requested.": "Показує інформацію про геометрію поля бою в запиті.",
  "Identifies the unit or organization to which the requested information applies.": "Визначає підрозділ або організацію, до якої відноситься інформація запиту.",
  "Specifies the message identifier, message originator and other message identifying details. If used, field 7 of this set in this message must only equal 'NEW' or 'UPD'.": "Визначає ідентифікатор повідомлення, відправника повідомлення і інші деталі, що засвідчують особу відправника повідомлення. Якщо використовується, поле 7 цього набору в цьому повідомленні повинно дорівнювати 'NEW' або 'UPD'.",
  "Provides a unique target identification including the target key, name as well as additional remarks.": "Надає унікальну ідентифікацію цілі, включаючи ключ цілі, ім'я, а також додаткові пояснення.",
  "Defines the currency, validity, security classification, and ownership of the data in the TARGETID set.": "Визначає цінність, строк дії, класифікацію безпеки, а також право власності на дані в блоці TARGETID.",
  "Provides detailed information concerning target characteristics, like operational status, condition, imagery key etc., and must be used if field 7 in set 'MSGID' in this message equals 'NEW' or does not occur.": "Визначає докладну інформацію про характеристики цілі, таких як робочий стан, стан, ключ до зображення тощо, і має бути використаний, якщо поле 7 в наборі 'MSGID' в цьому повідомленні дорівнює 'NEW' або не відбувається.",
  "Defines the currency, validity, security classification, and ownership of the data in the TARCHAR set and must only be used if set 'TARCHAR' occurs.": "Визначає цінність, строк дії, класифікацію безпеки, а також право власності на дані в блоці «TARCHAR».",
  "Provides a free text description of the physical damage inflicted by the relevant attack, usually from imagery analysis.": "Визначає вільне текстовий опис фізичної шкоди, від атаки, як правило, на основі аналізу знімків.",
  "Defines the currency, validity, security classification, and ownership of the data in the preceding GENTEXT set and must be used only if set 'GENTEXT' (DAMAGE INFLICTED) occurs.": "Визначає цінність, строк дії, класифікацію безпеки, а також право власності на дані в блоці GENTEXT і повинен використовуватись лише у випадку, якщо відбувається набір 'GENTEXT' (НАНЕСЕНА ШКОДА).",
  "Describes the capability of a target to recover after an attack and must be used if field 7 of set 'MSGID' of this message equals 'NEW' or does not occur.": "Описує здатність цілі відновлюватися після атаки і повинен використовуватись, якщо поле 7 в наборі 'MSGID' цього повідомлення дорівнює 'NEW' або не відбувається.",
  "Defines the currency, validity, security classification, and ownership of the data in the RECUPCAP set, and must be used only if set 'RECUPCAP' occurs.": "Визначає цінність, строк дії, класифікацію безпеки, а також право власності на дані в блоці RECUPCAP і повинен використовуватись лише у випадку, якщо відбувається набір 'RECUPCAP'.",
  "Describes the target in terms of linear dimensions or gives radius and shape information and must be used if field 7 of set 'MSGID' of this message equals 'NEW' or does not occur.": "Опис цілі лінійними розмірами або радіус і інформація про форму та повинен використовуватись, якщо поле 7 в наборі 'MSGID' цього повідомлення дорівнює 'NEW' або не відбувається.",
  "Defines the currency, validity, security classification, and ownership of the data in the TARGDIM set and must be used only if set 'TARGDIM' occurs.": "Визначає цінність, строк дії, класифікацію безпеки, а також право власності на дані в блоці TARGDIM і повинен використовуватись лише у випадку, якщо відбувається набір 'TARGDIM'.",
  "Provides the meteorological target position.": "Забезпечує метеорологічні дані позицію цілі.",
  "Provides the target weather information.": "Надає інформацію про погоду в районі цілі.",
  "Provides additional information about the target weather report.": "Надає додаткову інформацію про прогноз погоди в районі цілі.",
  "Provides details of the pick up zone (PZ) location.": "Визначає детальну інформацію про місцезнаходження зони підбору (PZ).",
  "Provides details of the unit reporting the medical evacuation request.": "Визначає детальну інформацію про одиницю, яка звітує про запит на медичну евакуацію.",
  "Provides details of the patients priority to be evacuated.": "Визначає детальну інформацію про пріоритет евакуації пацієнтів.",
  "Provides details of any special medical equipment that is required.": "Визначає детальну інформацію про будь-яке спеціальне медичне обладнання, яке потрібно.",
  "Provides details of the number of patients and escorts and the type of transportation, (sitting or lying).": "Визначає детальну інформацію про кількості пацієнтів та супроводжуючих осіб, а також типу транспорту (сидячий або лежачий).",
  "Provides details of the security at the pick up zone (PZ).": "Визначає детальну інформацію про безпеку у зоні збору (PZ).",
  "Provides details of the pick up zone (PZ) marking method.": "Визначає детальну інформацію про метод маркування зони збору (PZ).",
  "Provides details of the number, status and nationality of patients, also includes an indication of armed escorts.": "Визначає детальну інформацію про кількость, статус та громадянство пацієнтів, а також вказує на наявність збройного супроводу.",
  "Provides details of the pick up zone (PZ) terrain and must include any obvious or potential obstacles.": "Визначає детальну інформацію про тип місцевості в зоні збору (PZ) і повинно включати будь-які очевидні або потенційні перешкоди.",
  "Provides details of any critical medical supplies that are required.": "Визначає детальну інформацію про будь-які критичні медичні засоби, які потрібні.",
  "Provides tactical considerations at the landing site, to include friendly or enemy artillery or other weapons firing, recommended approach paths (e.g. direction, altitude), friendly or enemy aircraft activity, NBC conditions.": "Надає тактичні розгляди на місці посадки, включаючи вогневу активність союзних або ворожих артилерійських систем або інших засобів вогню, рекомендовані шляхи підходу (напрямок, висота), активність союзних або ворожих повітряних суден, умови NBC (ядерно-біологічно-хімічної загрози)."
};

const fieldsList = {
  _choice: 'Вибір',
  Amplification: 'Деталізація',
  LocationOfTask: 'Місце знаходження завдання',
  UnitLocation: 'МІСЦЕ РОЗТАШУВАННЯ ПІДРОЗДІЛУ',
  UnitPointOfContact: 'Контактна особа підрозділу',
  UnitReportingTime: 'Час звітування підрозділу',
  PatientsPriority: 'Першочерговість пацієнтів',
  NumberOfPatients: 'Кількість пацієнтів',
  PatientEvacuationPriority: 'Порядок черговості евакуації пацієнта',
  DtgOfTreatment: 'Дата та час проведення лікування',
  TreatmentGiven: 'Проведене лікування',
  DateDdmmmyyyy: 'Дата у форматі ДДМММРРРР ',
  MonthYear: 'ФОРМАТ МІСЯЦЬ РІК',
  TimeCoverageRequired: 'Необхідна тривалість покриття часу',
  DeploymentLocation: 'Розташування розгортання',
  MovementExecutionOrder: 'Порядок виконання руху',
  MechanismInjurySymptomsTreatment: 'Механізм травми, симптоми та лікування',
  MechanismOfInjury: 'Механізм травми',
  DtgForEvacuation: 'Час і дата евакуації',
  AdditionalInformation: 'Додаткова інформація',
  TacticalConsiderations: 'Тактичні врахування',
  SpecialMedicalEquipment: 'Спеціальне медичне обладнання',
  NumberOfPatientsToBeTransportedLyingOrSitting: 'Кількість пацієнтів, які будуть транспортовані у лежачому або сидячому положенні',
  PickUpZoneTerrain: 'Тип місцевості в зоні збору',
  DtgOfInjury: 'Дата та час травми',
  InjuryOrIllnessSustained: 'Травма або захворювання, що виникло',
  SymptomsAndVitalSigns: 'Симптоми та показники життєвої активності',
  PatientType: 'Тип пацієнта',
  PickUpZonePzSecurityDetails: 'Місце збору (PZ) деталі безпеки',
  PickUpZoneSecurityDetails: 'Деталі безпеки в зоні збору',
  ArmedEscortRequirementIndicator: 'Показник вимоги до наявності збройного супроводу',
  CriticalMedicalSupplies: 'Критичні медичні засоби',
  PickUpZonePzMarkingMethod: 'Метод маркування зони збору (PZ)',
  PickUpZoneMarkingMethod: 'Метод маркування зони підйому (PZ)',
  PickUpZoneAidColour: 'Колір допоміжних засобів в зоні підйому (PZ)',
  PzMarkingDetails: 'Деталі маркування зони підйому (PZ)',
  PatientStatusAndNationality: 'Статус пацієнта та громадянство',
  ReportingUnit: 'Звітна одиниця',
  Day: 'День',
  ExerciseAdditionalIdentifier: 'ДОДАТКОВА ІДЕНТИФІКАЦІЯ НАВЧАНЬ',
  ExerciseAdditionalNickname: 'Додатковий назва навчань',
  ExerciseIdentification: 'НАЗВА НАВЧАНЬ',
  ExerciseIdentifier: 'Ідентифікація навчань',
  ExerciseNickname: 'Назва навчань',
  FreeText: 'Вільний текст',
  HourTime: 'Година, час',
  MessageIdentifier: 'ІДЕНТИФІКАЦІЯ ПОВІДОМЛЕННЯ',
  MonthNumeric: 'Місяць, цифрою',
  MinuteTime: 'Хвилини, час',
  OperationCodeword: 'Назва операції',
  PlanOriginatorAndNumber: 'План операції та номер',
  Reference: 'Довідка',
  SecondTime: 'Секунди, час',
  TimeDelimiter: 'Роздільник часу',
  TimeZoneZulu: 'ЧАСОВА ЗОНА',
  Year4Digit: 'Рік, 4 цифри',
  Option: "Опція",
  FireUnit: "ВОГНЕВИЙ ПІДРОЗДІЛ",
  DateTimeGroup: "Група дати та часу",
  FirePlanName: "НАЙМЕНУВАННЯ ПЛАНУ ВОГНЕВОГО УРАЖЕННЯ",
  Projectiles: "ЗАБЕЗПЕЧЕНІСТЬ СНАРЯДАМИ (РАКЕТАМИ)",
  Propellants: "БОЄЗАРЯД",
  Fuzes: "ПІДРИВНИК",
  Remarks: "Примітки",
  PlanOriginator: "План операції",
  BlankSpaceCharacter: "ПРОПУСК",
  PlanNumber: "Номер плану",
  OptionNickname: "Основні опції",
  SecondaryOptionNickname: "Додаткові опції",
  MessageTextFormatIdentifier: "Ідентифікатор шаблона повідомлення",
  Standard: "Стандарт",
  Version: "Версія",
  Originator: "Відправник",
  MessageSerialNumber: "Серійний номер повідомлення",
  MonthNameAbbreviated: "Абревіатура назви місяця",
  DateTimeIso: "Час дата ISO",
  ReferenceTimeOfPublication: "Дата формування повідомлення",
  Qualifier: "Обмеження",
  SerialNumberOfQualifier: "Серійний номер обмеження",
  MessageSecurityPolicy: "Політика безпеки повідомлень",
  SecurityClassificationExtended: "КЛАСИФІКАЦІЯ БЕЗПЕКИ РОЗПОВСЮДЖЕННЯ",
  SecurityClassification: "Класифікація безпеки",
  MessageClassification: "Класифікація повідомлення",
  MessageSecurityCategory: "Категорія безпеки повідомлень",
  SerialLetter: "Серійний номер",
  CommunicationType: "ТИП ЗВ’ЯЗКУ",
  CommunicationsType: "Тип зв’язку або ІД формат текстового повідомлення",
  TitleOfDocument: "Назва документа",
  DateAndOrTimeOfReference: "Дата та (або) час",
  ReferenceSerialNumber: "Серійний номер",
  SpecialNotation: "Спеціальні примітки",
  GroupOfFields: "Група полів",
  Sic: "SIC КОД",
  FilingNumber: "НОМЕР ЗАПОВНЕННЯ",
  SicOrFileNumber: "SIC КОД АБО НОМЕР ФАЙЛУ",
  PrimaryOption: "Основні опції",
  SecondaryOption: "Другорядні опції",
  Section: "Відділення",
  Platoon: "Взвод",
  BatteryCompany: "Батарея/Рота",
  BattalionRegiment: "Батальйон/Полк",
  RegimentBrigadeDivision: "Полк/Бригада/Дивізія",
  Dtg: "Дата-часова група",
  ProjectileType: "Тип снаряду (ракети)",
  NumberOfProjectiles: "Кількість снарядів",
  PropellantType: "Тип боєзаряду",
  NumberOfPropellants: "Кількість боєзарядів",
  ArtilleryFuzeType: "ПІДРИВНИК",
  NumberOfFuzes: "Кількість підривника",
  GeodeticDatum: "ГЕОГРАФИЧЕСКАЯ СИСТЕМА",
  DateTimeGroup_1: "Дата_1",
  OrganizationDesignatorOfDrafterReleaser: "УМОВНЕ НАЙМЕНУВАННЯ ОРГАНІЗАЦІЇ ВІДПРАВНИКА",
  MapData: "ДАНІ ПРО КАРТУ",
  EarlyWarning: "ЗАВЧАСНЕ ПОПЕРЕДЖЕННЯ",
  DateTimeGroup_2: "Дата_2",
  NationalGridSystemCoordinates: "Національна система координат",
  UnitDesignationName: "Умовне найменування підрозділу",
  UnitSizeIndicator: "Рівень підрозділу",
  GeographicalEntity: "Географічна одиниця",
  NonStandardGeographicalEntity: "НЕСТАНДАРТНА ГЕОГРАФІЧНА ОДИНИЦЯ",
  Nationality: "КРАЇНА",
  UnitRoleIndicatorCodeA: "ПІДРОЗДІЛ – ІНДІКАТОР КОДУ A",
  UnitRoleIndicatorCodeB: "ПІДРОЗДІЛ – ІНДІКАТОР КОДУ B",
  UnitRoleIndicatorCodeC: "ПІДРОЗДІЛ – ІНДІКАТОР КОДУ C",
  UnitRoleIndicatorCodeD: "ПІДРОЗДІЛ – ІНДІКАТОР КОДУ D",
  HigherFormationName: "НАЙМЕНУВАННЯ ВИЩОГО ФОРМУВАННЯ",
  ArmedService: "КОД АРМІЙСЬКОЇ СЛУЖБИ",
  CivilianAgencyCode: "Код цивільної агенції",
  ArmedServiceOrCivilianAgencyCode: "Код армійської служби або цивільної агенції",
  UnitIdentificationCode: "Ідентифікаційний код підрозділу",
  FileSequentialLocationNumber: "ПОСЛІДОВНА ЧЕРГОВІСТЬ НОМЕРУ РОЗТАШУВАННЯ",
  MapOrChartSeriesDesignator: "Серія карти",
  MapOrChartSuffixNumber: "Суфікс карти",
  MapOrChartSheetNumber: "Номер аркуша",
  MapOrChartEditionNumber: "Номер видання",
  LatitudeAndLongitudeMinutes04DecimalPlaces: "ШИРОТА І ДОВГОТА, ХВИЛИНИ, 0-4 ЗНАКА ПІСЛЯ КОМИ",
  UniversalTransverseMercatorUtm: "УНІВЕРСАЛЬНА ПОПЕРЕЧНО-ЦИЛІНДРИЧНА СИСТЕМА КООРДИНАТ МЕРКАТОРА",
  MilitaryGridReferenceSystemUtmMgrsUtm: "ВІЙСЬКОВА СИСТЕМА ПРЯМОКУТНИХ КООРДИНАТ МЕРКАТОРА",
  UniversalPolarStereographicUps: "СІТКА КООРДИНАТ В УНІВЕРСАЛЬНІЙ ПОЛЯРНІЙ СТЕРЕОГРАФІЧНІЙ ПРОЕКЦІЇ",
  MilitaryGridReferenceSystemUpsMgrsUps: "ВІЙСЬКОВА СИСТЕМА ПРЯМОКУТНИХ КООРДИНАТ В УНІВЕРСАЛЬНІЙ ПОЛЯРНІЙ СТЕРЕОГРАФІЧНІЙ ПРОЕКЦІЇ",
  LocationOfSighting: "Координати виявлення",
  LatitudeDegrees: "Градуси широти",
  LatitudeMinutes04DecimalPlaces: "ШИРОТА, ХВИЛИНИ, 0-4 ЗНАКА ПІСЛЯ КОМИ",
  LatitudinalHemisphere: "ШИРОТА ПІВКУЛІ",
  Hyphen: "Дефіс",
  LongitudeDegrees: "ДОВГОТА В ГРАДУСАХ",
  LongitudeMinutes04DecimalPlaces: "ДОВГОТА В ХВИЛИНАХ, 0-4 ЗНАКА ПІСЛЯ КОМИ",
  LongitudinalHemisphere: "ДОВГОТА ПІВКУЛІ",
  UtmGridZone: "КООРДИНАТНА ЗОНА UTM",
  Utm1MetreHigherOrderEasting: "ВІДХИЛЕННЯ НА СХІД ДО 1 МЕТРА",
  Utm1MetreHigherOrderNorthing: "ВІДХИЛЕННЯ НА ПІВНІЧ ДО 1 МЕТРА",
  MgrsUtmGridLatitudeBand: "ВКАЗІВНА ЗОНА UTM",
  MgrsUtm100KmColumn: "100 КМ СТОВПЕЦЬ UTM",
  MgrsUtm100KmRow: "100 КМ РЯДОК UTM",
  Mgrs1MetreHigherOrderEasting: "ВІДХИЛЕННЯ В 100 КМ КВАДРАТІ НА СХІД ДО 1 МЕТРА",
  Mgrs1MetreHigherOrderNorthing: "ВІДХИЛЕННЯ В 100 КМ КВАДРАТІ НА ПІВНІЧ ДО 1 МЕТРА",
  Ups1MetreHigherOrderX: "ВІДХИЛЕННЯ ПО X ДО 1 МЕТРА",
  Ups1MetreHigherOrderY: "ВІДХИЛЕННЯ ПО Y ДО 1 МЕТРА",
  MgrsUpsHemisphereIndicator: "ІНДИКАТОР ПІВКУЛІ",
  MgrsUps100KmSquareColumn: "СТОВПЕЦЬ 100 КМ КВАДРАТУ",
  MgrsUps100KmSquareRow: "РЯДОК 100 КМ КВАДРАТУ",
  EwBearingInDegreesTrue: "ЗАВЧАСНЕ ПОПЕРЕДЖЕННЯ в істинних градусах",
  VerifiedCountOfAircraft: "Кількість літаків",
  CountOfAircraft0999: "Кількість літаків 0999",
  VerificationIndicator: "ІНДИКАТОР ДОСТОВІРНОСТІ",
  AircraftGenericCategory: "ЗАГАЛЬНЕ ПОЗНАЧЕННЯ ЛІТАКІВ",
  AltitudeInFeet: "Висота в футах",
  GeneralIndicatorOfAltitude: "ІНДИКАТОР ВИСОТИ",
  Altitude: "Висота",
  AircraftSpeedInKilometresPerHour: "Швидкість літака в кілометрах за годину",
  GeneralIndicatorOfAircraftSpeed: "ЗАГАЛЬНИЙ ІНДИКАТОР ШВИДКОСТІ",
  AircraftSpeed: "Швидкість літака",
  AirRaidWarning: "Повітряна тривога",
  ArwAreaPolygon: "РАЙОН ПОВІТРЯНОЇ ТРИВОГИ",
  ArwAreaCircle: "ЗОНА ПОВІТРЯНОЇ ТРИВОГИ",
  PolygonPoints: "Точки полігону",
  CircleCentre: "Центр зони",
  Radius: "Радіус",
  ContextQuantity0To1DecimalPtPermitted5CharMax: "ЗНАЧЕННЯ 0 до 1 десяткова кома дозволена 5 символів максимум",
  UnitOfLinearMeasurementMKmNm: "ОДИНИЦІ ДОВЖИНИ МКмНм",
  AreaName: "Назва місцевості",
  AirRaidWarningState: "Стан повітряної тривоги",
  EffectiveTime: "Фактичний час",
  TimeZone: "ЧАСОВА ЗОНА",
  PreviousAirRaidWarningState: "Попередній стан повітряної тривоги",
  OrderName: "НАЙМЕНУВАННЯ НАКАЗУ",
  LiaisonLocation: "КООРДИНАТИ ВИХОДУ НА ЗВ’ЯЗОК",
  LiaisonTime: "ЧАС ЗВ’ЯЗКУ",
  TacticalFireUnitMission: "ТАКТИЧНЕ ЗАВДАННЯ ВОГНЕВОГО ПІДРОЗДІЛУ",
  MissionTime: "РОЗРАХУНОК ЧАСУ",
  ManoeuvreUnitSupported: "ПІДРОЗДІЛ, ЩО ПІДТРИМУЄ МАНЕВР ВІЙСЬК",
  ArtilleryUnitReinforced: "АРТИЛЕРІЙСЬКІ ПІДРОЗДІЛИ ПІДСИЛЕННЯ",
  MoveLocationPointOrRoute: "КООРДИНАТИ ТОЧОК МАНЕВРУ АБО МАРШРУТУ",
  AzimuthOfFire: "Азимут СТРІЛЬБИ",
  MoveTime: "ЧАС МАНЕВРУ",
  DtgOfLiason: "Dtg зв'язку",
  MissionType: "Тип місії",
  NameOfZoneOfFire: "Найменування зони",
  DtgOfMissionAssignment: "Час постановки задачі",
  DtgOfMissionTermination: "Час виконання задачі",
  PointSequenceNumber: "Номер точки",
  PointOfLocationOrRoute: "Координати точки",
  AzimuthOfFireInMilsFromGridNorth: "ОСНОВНИЙ НАПРЯМОК СТРІЛЬБИ (У МИЛЯХ ДЛЯ ПІВНІЧНОЇ КООРДИНАТНОЇ СІТКИ)",
  DtgMovementToStart: "Дата та час початку маневру",
  DtgMovementToEnd: "Дата та час закінчення маневру",
  MapDataWithoutGeodeticDatum: "ДАНІ ПРО КАРТУ БЕЗ ГЕОДЕЗИЧНОЇ СИСТЕМИ КООРДИНАТ",
  BombardmentDetailsSegment: "Сегмент деталей обстрілу",
  BombardmentSourceAircraft: "Джерело обстрілу літака",
  BombardmentSourceWeapon: "Зброя джерела обстрілу",
  BombardmentSource: "Джерело обстрілу",
  ObserverGroundLocation: "РОЗТАШУВАННЯ СПОСТЕРІГАЧА",
  ObserverLocation: "Розташування спостерігача",
  AltitudeInMetres: "Висота в метрах",
  HostileBatteryLocationIndicator: "МІСЦЕ РОЗТАШУВАННЯ БАТАРЕЇ ПРОТИВНИКА",
  ObserverFlashSoundAzimuthToHostileBatteryInMils: "АЗИМУТ СПОСТЕРІГАЧ – ЗВУК ВЗРИВУ НА ВОРОЖІЙ БАТАРЕЇ В МИЛЯХ",
  DirectionOfShellGrooveInMils: "НАПРЯМОК ТРАЄКТОРІЇ СНАРЯДУ В МИЛЯХ",
  HostileBatteryDirectionIndication: "ІНДИКАТОР НАПРЯМКУ ВОРОЖОЇ БАТАРЕЇ",
  DistanceInMetresFromObserverToHostileBattery: "Дистанція в метрах між спостерігачем та батареєю противника (м)",
  FlashToBangTimeInSeconds: "час між спалахом в секундах",
  HostileBatteryLocation: "РОЗТАШУВАННЯ БАТАРЕЇ ПРОТИВНИКА",
  BombardedAreaLocation: "ВІДОМОСТІ ЩОДО ПЛОЩІ БОМБАРДУВАННЯ",
  BombardedArea: "Відомості щодо площі бомбардування",
  DurationData: "ТРИВАЛІСТЬ БОМБАРДУВАННЯ",
  EstimatedDateTimeGroupEffectiveFrom: "З (дата і час)",
  EstimatedDateTimeGroupEffectiveTo: "До (дата та час)",
  TargetSize: "РОЗМІРИ ЦІЛІ",
  LengthInMetres19999: "Довжина в метрах 19999",
  RadiusInMetres: "Радіус в метрах",
  LengthOrRadius: "Довжина або радіус (м)",
  WidthInMetres19999: "Ширина в метрах 19999",
  AttitudeInMilsFromGridNorth: "Висота – азимут по довготі (милі)",
  TargetTypeAndSubType: "Тип і підтип цілі",
  ArtilleryTargetTypeAndSubtype: "АРТИЛЕРІЙСЬКИЙ ТИП ТА ПІДТИП ЦІЛІ",
  CombatEngineerTargetTypeAndSubtype: "ІНЖЕНЕРНИЙ ТИП ТА ПІДТИП ЦІЛІ",
  TgtTypeAndSubtype: "ТИП ТА ПІДТИП ЦІЛІ",
  DegreeOfProtection: "СТУПІНЬ ЗАХИЩЕНОСТІ",
  VerifiedQuantityOfEquipmentWeapons: "Достовірність щодо кількості озброєння",
  QuantityOfEquipmentWeapons: "КІЛЬКІСТЬ ОЗБРОЄННЯ",
  VerifiedEquipmentCategory: "Достовірність щодо категорії озброєння",
  EquipmentCategory: "Категорія обладнання",
  VerifiedEquipmentDesignator: "Достовірність щодо позначення озброєння",
  EquipmentDesignator: "Вказівник (десигнатор) обладнання",
  NatureOfHostileBatteryFireMission: "Характеристика вогневої місії ворожої батареї",
  BombardmentMunitionsData: "Дані про боєприпаси обстрілу",
  MunitionsTypeGeneric: "Загальний тип боєприпасів",
  MunitionsCalibreInMillimetres: "Калібр боєприпасів у міліметрах",
  LengthInMetresCentimetresOrMillimetres: "Довжина в метрах, сантиметрах або міліметрах",
  ContextQuantity199999: "Кількість в контексті 199999",
  UnitOfMeasurementMmCmM: "Одиниця виміру мм, см, м",
  DiameterInMetresCentimetresOrMillimetres: "Діаметр у метрах, сантиметрах або міліметрах",
  QuantityOfOrdnance: "Кількість ордерів",
  AttackIntensity: "Інтенсивність атаки",
  DamageSustainedToOwnUnitCapability: "Пошкодження, зазнале власною частинію",
  TargetAcquisition: "Придбання цілі",
  TargetAcquisitionSource: "Джерело придбання цілі",
  LocationAccuracyInMetres: "Точність місцезнаходження в метрах",
  EvaluationOfReportReliability: "Оцінка надійності звіту",
  BoundaryZoneFlagChecked: "Прапорець зони кордону перевірено",
  EvacuationSegment: "Сегмент евакуації",
  OrganizationDesignator: "Дизайнатор організації",
  Origin: "Походження",
  LocationAndNearestFeature: "Місцезнаходження та найближча особливість",
  GeographicPlaceName: "Географічна назва місця",
  GeographicPosition: "Географічне положення",
  CasualtyEvacuationRequirementSegment: "Сегмент вимог до евакуації жертв",
  LocationAndNearestFeatureOfPickUpPoint: "Місцезнаходження та найближча особливість точки підйому",
  GroundSignalsAndRecognition: "Наземні сигнали та розпізнавання",
  RecognitionSignalDevice: "Пристрій сигналу розпізнавання",
  RecognitionSignalColour: "Колір сигналу розпізнавання",
  PanelShape: "Форма панелі",
  TacticalDetails: "Тактичні деталі",
  TextIndicator: "Текстовий індикатор",
  EvacuationConveyanceAndLocationType: "Тип евакуації та місцезнаходження",
  EvacuationConveyance: "Евакуаційний засіб",
  EvacuationLocationType: "Тип місця евакуації",
  MinimumClearRadiusInFeetYardsOrMetres: "Мінімальний чіткий радіус у футах, ярдах або метрах",
  ContextQuantity04DecimalPtPermitted: "Кількість у контексті 04, де допускається десяткова точка",
  UnitOfLinearMeasurementFeetYardsOrMetres: "Одиниця лінійного виміру: фути, ярди або метри",
  DetailedCasualtyEvacuationSegment: "Детальний сегмент евакуації жертв",
  CasualtyEvacuationDetails: "Деталі евакуації жертв",
  MedicalEvacuationPriority: "Пріоритет медичної евакуації",
  EvacuationModeOfTravel: "Спосіб переміщення при евакуації",
  TotalNumberOfCasualties: "Загальна кількість жертв",
  SpecialMedicalNeeds: "Особливі медичні потреби",
  LocationAndNearestFeatureOfDestination: "Місцезнаходження та найближча особливість місця призначення",
  EvacuationCommunications: "Комунікації щодо евакуації",
  PickUpPointCallSignAndFrequency: "Позивний та частота точки підйому",
  CallSignWithoutBlanks: "Позивний без пробілів",
  ContextQuantityDecimalPtPermitted8CharMax: "Кількість в контексті з допустимою десятковою точкою, максимум 8 символів",
  UnitOfElectromagneticEmissionMeasurement: "Одиниця виміру електромагнітного випромінювання",
  DestinationCallSignAndFrequency: "Позивний та частота місця призначення",
  TypeOfCbrnReport: "Тип CBRN звіту",
  IncidentSerialNumber: "Серійний номер інциденту",
  LocationOfObserverAndDirectionOfIncident: "Місцезнаходження спостерігача та напрямок інциденту",
  DateTimeGroupOfIncidentStartAndIncidentEnd: "Група дати та часу початку та закінчення інциденту",
  LocationOfIncident: "Місце інциденту",
  DeliveryAndQuantityInformation: "Інформація про доставку та кількість",
  ReleaseInformationOnCbrnIncidents: "Інформація про випуск в інцидентах CBRN",
  DescriptionAndStatusOfBiologicalIncident: "Опис та статус біологічного інциденту",
  TerrainTopographyAndVegetationDescription: "Опис місцевості, топографії та рослинності",
  DownwindDirectionAndDownwindSpeed: "Напрям вітру та швидкість вітру",
  MeasuredWeatherConditions: "Виміряні погодні умови",
  CbrnInfo: "Інформація CBRN",
  TypeOfCbrnWeatherReport: "Тип метеорологічного звіту CBRN",
  CbrnReport: "Звіт CBRN",
  ValidationCode: "Код підтвердження",
  AreaControlCentreCode: "Код центру контролю району",
  GeographicalEntity2A: "Географічна сутність 2A",
  GeographicalEntity3A: "Географічна сутність 3A",
  GeographicLocation: "Географічне місцезнаходження",
  CodeForOriginator: "Код для ініціатора",
  UnitIdentificationCodeUic: "Код ідентифікації одиниці (UIC)",
  CodeForOriginatingUnit: "Код початкової одиниці",
  SequenceNumber: "Порядковий номер",
  TypeOfIncident: "Тип інциденту",
  LocationOfObserver: "Місцезнаходження спостерігача",
  DirectionInDegrees: "Напрямок у градусах",
  DirectionInMils: "Напрямок в мілах",
  DirectionFromObserver: "Напрямок від спостерігача",
  ContextQuantity000360: "Кількість у контексті 000360",
  UnitOfMeasurementDirectionInDegrees: "Одиниця виміру напрямку в градусах",
  ContextQuantity00006400: "Кількість у контексті 00006400",
  UnitOfMeasurementDirectionInMils: "Одиниця виміру напрямку в мілах",
  DateTimeGroupOfIncidentStart: "Група дати та часу початку інциденту",
  Year: "Рік",
  DateTimeGroupOfIncidentEnded: "Група дати та часу закінчення інциденту",
  PlaceNameExtended: "Розширена назва місця",
  IncidentLocation: "Місцезнаходження інциденту",
  LocationQualifier: "Кваліфікатор місцезнаходження",
  NotKnownLocationQualifier: "Не відомий кваліфікатор місцезнаходження",
  Location: "Місцезнаходження",
  QualificationOfIncident: "Кваліфікація інциденту",
  TypeOfDelivery: "Тип доставки",
  NumberOfDeliverySystems: "Кількість систем доставки",
  TypeOfSubstanceContainers: "Тип контейнерів для речовин",
  ContextQuantity1999: "Кількість у контексті 1999",
  QuantityAndUnitOfWeightMassMeasurement: "Кількість та одиниця виміру маси/ваги",
  SizeOfRelease: "Розмір випуску",
  SubstanceContainerOrRelease: "Контейнер для речовини або випуск",
  ContextQuantity1999999: "Кількість у контексті 1999999",
  UnitOfWeightMassMeasurement: "Одиниця виміру ваги/маси",
  TypeOfBurstOrReleaseHeight: "Тип вибуху або висоти випуску",
  SubstanceReleaseHeight: "Висота випуску речовини",
  UnitOfLinearMeasurementFtKfYdSmNmMHmKm: "Одиниця лінійного виміру FtKfYdSmNmMHmKm",
  TypeOfSubstance: "Тип речовини",
  SubstanceName: "Назва речовини",
  UnNaIdentificationNumber: "Ідентифікаційний номер UN/NA",
  SubstanceDefinition: "Визначення речовини",
  TypeOfPersistency: "Тип персистентності",
  TypeOfDetection: "Тип виявлення",
  DetectionConfidenceLevel: "Рівень впевненості виявлення",
  DescriptionOfIncident: "Опис інциденту",
  StatusOfIncident: "Статус інциденту",
  TerrainTopographyDescription: "Опис рельєфу місцевості",
  VegetationDescription: "Опис рослинності",
  RepresentativeDownwindDirection: "Представницький напрямок вниз по вітру",
  RepresentativeDownwindSpeed: "Представницька швидкість вниз по вітру",
  ContextQuantity000999: "Кількість у контексті 000999",
  UnitOfSpeedVelocityMeasurement: "Одиниця виміру швидкості",
  DetailedAirStabilityCategory: "Детальна категорія стабільності повітря",
  SimplifiedAirStabilityCategory: "Спрощена категорія стабільності повітря",
  AirStabilityCategory: "Категорія стабільності повітря",
  SurfaceAirTemperature: "Температура повітря на поверхні",
  ContextQuantity1DecAndOrMinusPermitted5CharMax: "Кількість у контексті, допускається 1 знак після коми і/або мінус, максимум 5 символів",
  UnitOfTemperatureMeasureDegreesCelsiusOrFahrenheit: "Одиниця виміру температури в градусах Цельсія або Фаренгейта",
  RelativeHumidityRange: "Діапазон відносної вологості",
  SignificantWeatherPhenomena: "Суттєві погодні явища",
  CloudCoverage: "Хмарність",
  DescriptionAndStatusOfChemicalIncident: "Опис і статус хімічного інциденту",
  TypeOfNuclearBurst: "Тип ядерного вибуху",
  NuclearBurstAngularCloudWidthAtH5Minutes: "Ширина ядерного вибуху в радіанах у H+5 хвилин",
  StabilizedCloudMeasurementAtH10Minutes: "Закріплений вимір хмари на H+10 хвилин",
  RadarDeterminedExternalContourOfRadioactiveCloud: "Зовнішній контур радіоактивної хмари, визначений радаром",
  RadarDeterminedDownwindDirectionOfRadioactiveCloud: "Напрямок вниз по вітру радіоактивної хмари, визначений радаром",
  AngularMeasurementInMils: "Вимір углу в мілах",
  AngularMeasurementInDegrees: "Вимір углу в градусах",
  AngularWidthOfCloud: "Углова ширина хмари",
  UnitOfAngularMeasurementMils: "Одиниця виміру углу в мілах",
  UnitOfAngularMeasurementDegrees: "Одиниця виміру углу в градусах",
  CloudSection: "Секція хмари",
  CloudAngle: "Угол хмари",
  CloudHeight: "Висота хмари",
  ExternalContourOfRadioactiveCloud: "Зовнішній контур радіоактивної хмари",
  DownwindDirectionOfRadioactiveCloud: "Напрямок вниз по вітру радіоактивної хмари",
  ReleaseAndSamplingInformationOnRadiologicalIncidents: "Інформація про випуск і вибіркове дослідження радіологічних інцидентів",
  DescriptionAndStatusOfRadiologicalIncident: "Опис і статус радіологічного інциденту",
  TypeOfSource: "Тип джерела",
  TypeOfRadioactivity: "Тип радіоактивності",
  IupacIsotopeNameAndAtomicWeight: "Назва ізотопу і атомна вага за IUPAC",
  TheIdentificationOfAMaterial: "Ідентифікація матеріалу",
  RadioactiveHalfLife: "Радіоактивний період напіврозпаду",
  ScientificNotationQualifier: "Кваліфікатор наукового запису",
  ContextQuantity0099: "Кількість у контексті 0099",
  UnitOfTimeMeasurement: "Одиниця виміру часу",
  MeansOfDetection: "Засоби виявлення",
  TypeOfIdentification: "Тип ідентифікації",
  AreaOfValidity: "Область дії",
  PeriodOfValidity: "Період дії",
  UnitsOfMeasurement: "Одиниці виміру",
  WindConditionsAt2000MIncrementsUpTo30000M: "Умови вітру при збільшенні на 2000 метрів до 30000 метрів",
  NameOfAreaOfValidity: "Назва області дії",
  DtgOfObservationZulu: "Дата та час спостереження (Zulu)",
  EffectiveDtgValidFromZulu: "Ефективна дата та час, дійсні з (Zulu)",
  EffectiveDtgValidToZulu: "Ефективна дата та час, дійсні до (Zulu)",
  UnitOfMeasurementDistance: "Одиниця виміру відстані",
  UnitOfMeasurementDirection: "Одиниця виміру напрямку",
  UnitOfMeasurementSpeed: "Одиниця виміру швидкості",
  UnitOfMeasurementTemperature: "Одиниця виміру температури",
  LayerIndicator: "Індикатор шару",
  WindDirectionInDegrees: "Напрямок вітру в градусах",
  WindDirectionInMils: "Напрямок вітру в мілах",
  WindVariabilityIndicator: "Індикатор варіативності вітру",
  WindDirection: "Напрямок вітру",
  WindSpeed: "Швидкість вітру",
  SurfaceWeatherForTheFirstTwoHourPeriod: "Погода на поверхні для першого двогодинного періоду",
  SurfaceWeatherForTheSecondTwoHourPeriod: "Погода на поверхні для другого двогодинного періоду",
  SurfaceWeatherForTheThirdTwoHourPeriod: "Погода на поверхні для третього двогодинного періоду",
  DownwindDirectionInDegrees: "Напрямок вниз по вітру в градусах",
  DownwindDirection: "Напрямок вниз по вітру",
  SurfaceAirTemperatureUnspecifiedUnitOfMeasure: "Температура повітря на поверхні з невизначеною одиницею виміру",
  EffectiveDownwindForYieldGroupAlfa: "Ефективний напрямок вниз по вітру для групи виходу Alfa",
  EffectiveDownwindForYieldGroupBravo: "Ефективний напрямок вниз по вітру для групи виходу Bravo",
  EffectiveDownwindForYieldGroupCharlie: "Ефективний напрямок вниз по вітру для групи виходу Charlie",
  EffectiveDownwindForYieldGroupDelta: "Ефективний напрямок вниз по вітру для групи виходу Delta",
  EffectiveDownwindForYieldGroupEcho: "Ефективний напрямок вниз по вітру для групи виходу Echo",
  EffectiveDownwindForYieldGroupFoxtrot: "Ефективний напрямок вниз по вітру для групи виходу Foxtrot",
  EffectiveDownwindForYieldGroupGolf: "Ефективний напрямок вниз по вітру для групи виходу Golf",
  RadiusOfZone1: "Радіус зони 1",
  AngleExpansionIndicator: "Індикатор розширення кута",
  DescriptionAndStatusOfIncident: "Опис і статус інциденту",
  PredictedReleaseAndHazardArea: "Прогнозоване випуск та зона небезпеки",
  RadiologicalHazardPredictionParameters: "Параметри прогнозування радіологічної небезпеки",
  HazardAreaLocationForWeatherPeriod: "Розташування зони небезпеки для періоду погоди",
  PredictedContourInformation: "Прогнозована інформація про контур",
  ReferenceDateTimeGroupForEstimatedOrActualContourLines: "Група дати та часу для оцінки або фактичних контурних ліній",
  ReleaseAreaRadius: "Радіус зони випуску",
  UnitOfLinearMeasurementFtYdSmNmMKmHf: "Одиниця лінійного виміру (фути, ярди, морські милі, нм, метри, км, гектофути)",
  DurationOfHazardInReleaseArea: "Тривалість небезпеки в зоні випуску",
  MinimumDurationPeriod: "Мінімальний період тривалості",
  CalculatedLengthOfDurationPeriod: "Розрахована довжина періоду тривалості",
  UnitOfMeasurementHazardDuration: "Одиниця виміру тривалості небезпеки",
  HazardAreaDistance: "Відстань до зони небезпеки",
  DurationOfHazardInHazardArea: "Тривалість небезпеки в зоні небезпеки",
  RadiologicalHazardAreaDistanceR1: "Відстань до радіологічної зони небезпеки R1",
  RadiologicalHazardAreaDistanceR2: "Відстань до радіологічної зони небезпеки R2",
  RadiologicalHazardAreaDistanceR3: "Відстань до радіологічної зони небезпеки R3",
  DateTimeGroupOfStartOfMeteorologicalPeriodZulu: "Група дати та часу початку метеорологічного періоду (Zulu)",
  TypeOfContour: "Тип контуру",
  LevelOfContaminationAndUnitOfMeasurement: "Рівень забруднення та одиниця виміру",
  LevelOfDoseAndUnitOfMeasurement: "Рівень дози та одиниця виміру",
  LevelOfDoseRateDosageAndUnitOfMeasurement: "Рівень швидкості дози, дозування та одиниця виміру",
  LevelOfHazard: "Рівень небезпеки",
  AffirmativeOrNegativeIndicator: "Афірмативний чи негативний індикатор",
  ContaminationDoseDoseRateDosageAndHazard: "Забруднення, доза, швидкість дози, дозування та небезпека",
  ContextQuantity0To6DecimalPlacesPermitted: "Кількість десяткових знаків від 0 до 6 дозволено",
  UnitOfContamination: "Одиниця забруднення",
  UnitOfDose: "Одиниця дози",
  ContextQuantity0To7DecimalPlacesPermitted: "Кількість десяткових знаків від 0 до 7 дозволено",
  UnitOfDoseRateDosage: "Одиниця швидкості дози, дозування",
  TypeOfHazard: "Тип небезпеки",
  ContextQuantity199: "Кількість контексту 199",
  LimitContourLineOrAreaOfContamination: "Межа контурної лінії або зони забруднення",
  DtgForEstimatedContourLines: "DTG для оцінених контурних ліній",
  CbrnSitrep: "CBRN Sitrep",
  CommandersAssessment: "Оцінка командира",
  CcisUnitAndEquipmentStatusSegment: "CCIS сегмент статусу одиниці та обладнання",
  OrganizationDesignatorOfSubordinateUnit: "Ознака організації підлеглого підрозділу",
  CommunicationOutageDetailSegment: "Сегмент деталей відмови в комунікації",
  CommunicationEvent: "Комунікаційна подія",
  CommunicationsEvent: "Подія в комунікаціях",
  EmissionControlPlanIdentifier: "Ідентифікатор плану контролю викидів",
  EventTime: "Час події",
  TerminationTimeQualifier: "Кваліфікатор часу завершення",
  TerminationTime: "Час завершення",
  CommunicationsCircuitName: "Назва комунікаційного контуру",
  CommunicationsCircuitDesignator: "Ознака комунікаційного контуру",
  SystemDesignationName: "Назва ознаки системи",
  UnitIdentifier: "Ідентифікатор одиниці",
  LocationOfEventPlaceName: "Назва місця події",
  ShipName: "Назва судна",
  LocationOfEvent: "Місце події",
  Country: "Країна",
  NicsLocationCode: "Код місця розташування NICS",
  Relocation: "Переміщення",
  LocationOrPositionName: "Назва місця або позиції",
  RelocationPlaceName: "Назва місця переселення",
  RelocationCountry: "Країна переселення",
  NicsRelocationCode: "Код переселення NICS",
  RelocationPosition: "Позиція переселення",
  AlternateRelocation: "Альтернативне переселення",
  AlternateRelocationPlaceName: "Назва місця альтернативного переселення",
  AlternateRelocationCountry: "Країна альтернативного переселення",
  NicsAlternateRelocationCode: "Код альтернативного переселення NICS",
  DeploymentInformationEnd: "Закінчення інформації про розгортання",
  DeploymentForecastEnd: "Закінчення прогнозу розгортання",
  PointOfContact: "Контактна особа",
  SerialNumber: "Серійний номер",
  UnitName: "Назва одиниці",
  ArrivalPlaceName: "Назва місця прибуття",
  ArrivalLocation: "Місце прибуття",
  EstimatedTimeOfArrival: "Орієнтовний час прибуття",
  TimeOfArrival: "Час прибуття",
  ReasonForDelay: "Причина затримки",
  ModeOfTransportation: "Спосіб перевезення",
  NumberOfDeploymentsEndingInNext24Hours: "Кількість розгортань, що закінчуються протягом наступних 24 годин",
  NumberOfDeploymentsEndingInNext48Hours: "Кількість розгортань, що закінчуються протягом наступних 48 годин",
  NumberOfDeploymentsEndingInNext72Hours: "Кількість розгортань, що закінчуються протягом наступних 72 годин",
  NumberOfDeploymentsEndingInNext96Hours: "Кількість розгортань, що закінчуються протягом наступних 96 годин",
  NumberOfDeploymentsEndingAfterNext96Hours: "Кількість розгортань, що закінчуються після наступних 96 годин",
  NumberOfDeployments: "Кількість розгортань",
  DateOfReport: "Дата звіту",
  TotalNumberOfDeployments: "Загальна кількість розгортань",
  DeploymentForecastBegin: "Початок прогнозу розгортання",
  EstimatedTimeOfDeparture: "Орієнтовний час відправлення",
  ModeOfDeparture: "Спосіб відправлення",
  AnticipatedDestinationPlaceName: "Назва передбачуваного місця призначення",
  AnticipatedDestination: "Передбачуване місце призначення",
  AnticipatedMethodOfMovement: "Передбачуваний метод переміщення",
  NumberOfDeploymentsStartingInNext24Hours: "Кількість розгортань, що починаються протягом наступних 24 годин",
  NumberOfDeploymentsStartingInNext48Hours: "Кількість розгортань, що починаються протягом наступних 48 годин",
  NumberOfDeploymentsStartingInNext72Hours: "Кількість розгортань, що починаються протягом наступних 72 годин",
  NumberOfDeploymentsStartingInNext96Hours: "Кількість розгортань, що починаються протягом наступних 96 годин",
  NumberOfDeploymentsStartingAfterNext96Hours: "Кількість розгортань, що починаються після наступних 96 годин",
  DeploymentInformationBegin: "Початок інформації про розгортання",
  DateOfReport2: "Дата звіту 2",
  CurrentLocation: "Поточне місце розташування",
  CurrentPosition: "Поточна позиція",
  DeparturePlaceName: "Назва місця відправлення",
  EstimatedTimeOfDeparture2: "Орієнтовний час відправлення 2",
  TimeOfDeparture2: "Час відправлення 2",
  ReasonForDelay2: "Причина затримки 2",
  ModeOfTransportation2: "Спосіб перевезення 2",
  DestinationPlaceName: "Назва місця призначення",
  DestinationLocation: "Місце призначення",
  EstimatedTimeOfArrival2: "Орієнтовний час прибуття 2",
  TimeOfArrival2: "Час прибуття 2",
  ReasonForDelay3: "Причина затримки 3",
  ModeOfTransportation3: "Спосіб перевезення 3",
  FrequencyOfReports: "Частота звітів",
  ReportingPeriod: "Період звітності",
  LocationInformation: "Інформація про місцезнаходження",
  GeographicPositionGeorefMinute: "Географічне положення (мінутний формат)",
  FifteenDegreeQuadrilateralGeoref: "Географічний квадрилатер (15-градусний формат)",
  OneDegreeQuadrilateralGeoref: "Географічний квадрилатер (1-градусний формат)",
  MinuteEastingGeoref: "Мінутна зона східної довготи",
  MinuteNorthingGeoref: "Мінутна зона північної широти",
  PointOfContactInformation: "Інформація контактної особи",
  PocName: "Ім'я контактної особи",
  PocRankOrPosition: "Посада або звання контактної особи",
  UnitDesignator: "Ідентифікатор підрозділу",
  CallSign: "Викликний знак",
  PocUnit: "Підрозділ контактної особи",
  IcaoLocationIndicator: "ІКАО індикатор місцезнаходження",
  LatitudeAndLongitudeMinutes04DecimalPlacesVerified: "Широта та довгота (4 десяткових знаки)",
  PocLocation: "Місцезнаходження контактної особи",
  ContextQuantity09_1: "Контекстна кількість 09-1",
  ContextQuantity09_2: "Контекстна кількість 09-2",
  NonSecureTelephone: "Телефон (незахищений)",
  SecureFacsimileFaxNumber: "Номер факсу (захищений)",
  NonSecureFacsimileFaxNumber: "Номер факсу (незахищений)",
  RadioFrequency: "Радіочастота",
  FrequencyDesignator: "Дизайнатор частоти",
  FrequencyModeAndNumeric: "Режим та числове значення частоти",
  ElectronicMailAddress: "Електронна адреса",
  SecureElectronicMailAddress: "Захищена електронна адреса",
  ChatRoomName: "Назва чат-кімнати",
  CommunicationMeansParameters: "Параметри засобів зв'язку",
  ContextQuantityDecimalPtPermitted11CharMax: "Контекстна кількість (макс. 11 символів)",
  FrequencyMode: "Режим частоти",
  ContextQuantityDecimalPtPermitted7CharactersMax: "Контекстна кількість (макс. 7 символів)",
  AdditionalCoordinatingInformationPoe: "Додаткова координатна інформація (місце посадки)",
  PointOfDisembarkationSegment: "Сегмент місця висадки",
  PointOfDisembarkationDetails: "Деталі місця висадки",
  AdditionalCoordinatingInformationPod: "Додаткова координатна інформація (місце висадки)",
  DestinationForUnitsToBeDeployed: "Місце призначення для підрозділів, що вирушають",
  TypeOfDestinationAreaShape: "Тип фігури місця призначення",
  Destination: "Місце призначення",
  DeploymentInformationStart: "Початок інформації про розгортання",
  DeploymentForecastStart: "Початок прогнозу розгортання",
  SiteNameOfPeacetimeLocation: "Назва місця розташування в мирний час",
  PeacetimeLocation: "Місце розташування в мирний час",
  DepartureLocation: "Місце відправлення",
  TimeOfDeparture: "Час відправлення",
  DeploymentArrivalLocation: "Місце прибуття розгортання",
  TaskIdentification: "Ідентифікація завдання",
  AimOfReconnaissance: "Мета розвідки",
  ReconnaissanceTiming: "Час проведення розвідки",
  ReconnaissanceType: "Тип розвідки",
  ManpowerData: "Дані про чисельність особового складу",
  EquipmentData: "Дані про техніку",
  MaterialAuthorized: "Авторизований матеріал",
  Acknowledge: "Підтвердження",
  EngineerTaskNumber: "Номер інженерного завдання",
  EngineerTaskNickname: "Псевдонім інженерного завдання",
  DtgToSubmitReportBy: "Дата та час для подання звіту",
  NoEarlierThanDtgForReconToBegin: "Дата та час, з якого можливе початок розвідки",
  DtgForReconToBeCompletedBy: "Дата та час, до якого розвідку потрібно завершити",
  EngineerTaskType: "Тип інженерного завдання",
  MobilityTaskType: "Тип завдання з мобільності",
  CrossingAreaBoundaries: "Перетин меж районів",
  GridReference1: "Сіткова координата 1",
  GridReference2: "Сіткова координата 2",
  RouteStartPoint: "Початкова точка маршруту",
  RouteIntermediatePoint: "Проміжна точка маршруту",
  IntermediatePointNumber: "Номер проміжної точки",
  RouteReleasePoint: "Точка випуску маршруту",
  MilitaryLoadClassificationMlc1WayOr2WayTrackedOrWheeledVehicles: "Військова класифікація навантаження (1 напрямок або 2 напрямки, гусеничні або колісні транспортні засоби)",
  Mlc1WayTrackedVehicles: "Класифікація навантаження (1 напрямок, гусеничні транспортні засоби)",
  Mlc1WayWheeledVehicles: "Класифікація навантаження (1 напрямок, колісні транспортні засоби)",
  Mlc2WayTrackedVehicles: "Класифікація навантаження (2 напрямки, гусеничні транспортні засоби)",
  Mlc2WayWheeledVehicles: "Класифікація навантаження (2 напрямки, колісні транспортні засоби)",
  VehicleConstrainingFactors: "Фактори, що обмежують рух транспортних засобів",
  'OptionsRequired (NumberOfOptions)': "Необхідні опції (кількість опцій)",
  'TaskSerialNumber (TaskSerialNumber)': "Серійний номер завдання",
  ObstacleNumber: "Номер перешкоди",
  ObstacleNickname: "Псевдонім перешкоди",
  ObstacleIdentifier: "Ідентифікатор перешкоди",
  NatoRegionalIdentifier: "Регіональний ідентифікатор НАТО",
  CorpsTroopsOrDivisionDesignator: "Ідентифікатор корпусу, підрозділу або дивізії",
  DivisionTroopsOrBrigadeDesignator: "Ідентифікатор дивізійних військ або бригади",
  TacticalObstacleIdentifier: "Ідентифікатор тактичної перешкоди",
  TacticalObstacleNumber: "Номер тактичної перешкоди",
  ObstacleZoneBeltNumber: "Номер зони/поясу перешкод",
  ObstacleZoneBeltName: "Назва зони/поясу перешкод",
  ObstacleZoneBeltNumberOrName: "Номер або назва зони/поясу перешкод",
  CountermobilityData: "Дані про протимобільні заходи",
  TacticalObjective: "Тактична ціль",
  GeneralStoppingPower: "Загальна зупинна здатність",
  SurvivabilityData: "Дані про виживання",
  SurvivabilityMission: "Місія виживання",
  SurvivabilityTask: "Завдання виживання",
  TypeOfPosition: "Тип позиції",
  CoverOrProtectionLevel: "Рівень укриття або захисту",
  UnitLevel: "Рівень підрозділу",
  UnitType: "Тип підрозділу",
  GeneralEngineerSupportData: "Загальні дані про інженерну підтримку",
  GeneralEngineerSupportTask: "Завдання загальної інженерної підтримки",
  DegreeOfPermanency: "Ступінь постійності",
  GeneralEngineerSupportMission: "Місія загальної інженерної підтримки",
  CombatEngineerTroopOrPlatoonType: "Тип бойового інженерного взводу або взводу",
  TroopOrPlatoonAllocation: "Виділення взводу або взводу",
  NumberRequiredTroopPlatoon: "Кількість потрібних взводів або взводів",
  DurationRequiredHours: "Тривалість, години",
  EquipmentType: "Тип обладнання",
  EquipmentAllocation: "Виділення обладнання",
  QuantityRequired: "Кількість, потрібна",
  TypeOfMaterial: "Тип матеріалу",
  QuantityAuthorizedWithUnitOfMeasure: "Кількість, авторизована з одиницею виміру",
  QuantityAuthorized: "Кількість, авторизована",
  UnitOfMeasurementCombatEngineers: "Одиниця виміру (бойові інженерні війська)",
  AcknowledgeRequirementIndicator: "Показник необхідності підтвердження",
  InstructionsForAcknowledging: "Інструкції для підтвердження",
  ForceOrUnitRequiredToAcknowledge: "Необхідність підтвердження від сили або підрозділу",
  AcknowledgeInstructions: "Інструкції для підтвердження",
  MinefieldBoundary: "Межа мінного поля",
  VehicleLengthInMetres: "Довжина транспортного засобу, метри",
  VehicleWidthInMetres: "Ширина транспортного засобу, метри",
  VehicleHeightInMetresDecimalPermitted: "Висота транспортного засобу, метри (допускається десятковий роздільник)",
  VehicleGroundClearanceInMetres: "Просікання транспортного засобу, метри",
  VehicleLongitudinalWheelBaseInMetres: "Міжосівна відстань транспортного засобу, метри",
  DateTimeGroupReconnaissanceCompleted: "Дата та час завершення розвідки",
  MaterialRequired: "Необхідний матеріал",
  UnitCapability: "Здатність підрозділу",
  RestrictionFound: "Виявлено обмеження",
  RestrictionSize: "Розмір обмеження",
  ContextQuantity09999: "Кількість (до 9999)",
  UnitOfMeasurementRestriction: "Одиниця виміру (обмеження)",
  'SurvivabilityTask (SurvivabilityTask)': "Завдання виживання",
  QuantityRequiredAndUnitOfMeasurement: "Кількість, потрібна з одиницею виміру",
  NumberOfItems: "Кількість предметів",
  CapabilityToCompleteTaskIndicator: "Показник здатності виконати завдання",
  TaskLimitations: "Обмеження завдання",
  EvacuationRequestIdentifier: "Ідентифікатор запиту на евакуацію",
  TotalNumberOfCasualtiesToBeEvacuated: "Загальна кількість постраждалих, які будуть евакуйовані",
  StretcherCasualties: "Кількість постраждалих на ношах",
  SittingCasualties: "Кількість постраждалих, які сидять",
  EscortPersonnelRequired: "Необхідний супровідний персонал",
  SpecialMedicalRequirements: "Особливі медичні вимоги",
  DayMonthYear: "День, місяць, рік",
  NumberOfPriority1StretcherCasualties: "Кількість постраждалих на ношах першого пріоритету",
  NumberOfPriority2StretcherCasualties: "Кількість постраждалих на ношах другого пріоритету",
  NumberOfPriority3StretcherCasualties: "Кількість постраждалих на ношах третього пріоритету",
  NumberOfPriority1SittingCasualties: "Кількість постраждалих, які сидять першого пріоритету",
  NumberOfPriority2SittingCasualties: "Кількість постраждалих, які сидять другого пріоритету",
  NumberOfPriority3SittingCasualties: "Кількість постраждалих, які сидять третього пріоритету",
  NumberRequired: "Кількість, потрібна",
  Comments: "Коментарі",
  TargetNumber: "Номер цілі",
  NumberOfGuns: "Кількість гармат",
  TargetLocation: "Місце розташування цілі",
  TargetStrength: "Сила цілі",
  ReportValueAccuracy: "Точність значення звіту",
  TrajectoryType: "Тип траєкторії",
  MunitionsInEffect: "Боєприпаси в дії",
  EffectsRequired: "Необхідні ефекти",
  MethodOfControl: "Спосіб керування",
  FiringInterval: "Інтервал між пострілами",
  EndOfMission: "Кінець місії",
  TargetAlphabeticIdentifier: "Алфавітний ідентифікатор цілі",
  NumericalTargetDesignator: "Числовий ідентифікатор цілі",
  NumberOfTargetElements: "Кількість елементів цілі",
  ReportAccuracyInMetres: "Точність звіту в метрах",
  NumberOfVolleysRockets: "Кількість залпів/ракет",
  FireForEffectProjectile: "Боєприпас для ефективного вогню",
  FireForEffectFuze: "Запал для ефективного вогню",
  Time: "Час",
  IntervalBetweenVolleysInSeconds: "Інтервал між залпами у секундах",
  DurationOfFireInMinutes: "Тривалість вогню в хвилинах",
  EndOfMissionIndicator: "Показник кінця місії",
  FireMissionCommand: "Команда вогневої місії",
  ReportToObserver: "Звіт спостерігачу",
  SurveillanceOfTarget: "Спостереження за ціллю",
  TargetDisposition: "Розташування цілі",
  NumberOfCasualties: "Кількість постраждалих",
  OrderReferenceDetails: "Деталі посилання на наказ",
  OrderTimeZone: "Часовий пояс наказу",
  TaskOrganization: "Організація завдання",
  AttachedAndDetachedUnitsSegment: "Сегмент прикріплених і роз'єднаних підрозділів",
  MessagePartAndTitle_1: "Частина повідомлення і заголовок 1",
  EnemyInformationFragoSegment: "Сегмент інформації про ворога (Frago)",
  FriendlyForcesSegment: "Сегмент дружніх сил",
  MessagePartAndTitle_2: "Частина повідомлення і заголовок 2",
  MessagePartAndTitle_3: "Частина повідомлення і заголовок 3",
  CommandersIntent: "Намір командира",
  TasksOrMissionsSegment: "Сегмент завдань або місій",
  CoordinatingInstructionsDesignationSegment: "Сегмент координуючих інструкцій і позначення",
  MessagePartAndTitle_4: "Частина повідомлення і заголовок 4",
  SerialLetterOfReference: "Серійний номер посилання",
  ServiceSupportUnitTasksAndMissionsSegment: "Сегмент завдань і місій службово-технічних підрозділів",
  MessagePartAndTitle_5: "Частина повідомлення і заголовок 5",
  NoChangeIndicator_5: "Показник відсутності змін 5",
  HeadquartersLocationAndMovementSegment: "Сегмент розташування та руху штабу",
  CoordinationDetailsSegment: "Сегмент деталей координації",
  OtherCommandAndSignalsInformation: "Інша інформація про команду та сигнали",
  NoChangeIndicator_1: "Показник відсутності змін 1",
  EnemyForces: "Ворожі сили",
  NoChangeIndicator_2: "Показник відсутності змін 2",
  MissionStatement: "Заява про місію",
  NoChangeIndicator_3: "Показник відсутності змін 3",
  ConceptOfOperations: "Концепція операцій",
  NoChangeIndicator_4: "Показник відсутності змін 4",
  ServiceSupport: "Службова підтримка",
  NoChange: "Відсутність змін",
  OrderNameSent: "Надіслане ім'я наказу",
  OrderIdentifierSent: "Ідентифікатор наказу, надісланого",
  YearSent: "Рік, відправлений",
  CommandedAction: "Дія, командована",
  OrderNameReferenced: "Назва наказу, на який є посилання",
  OrderIdentifierReferenced: "Ідентифікатор наказу, на який є посилання",
  YearReferenced: "Рік, на який є посилання",
  OrganizationDesignatorAttachedUnit: "Позначення організації прикріпленого підрозділу",
  OrganizationDesignatorDetachedUnit: "Позначення організації роз'єднаного підрозділу",
  MessagePartIdentifier: "Ідентифікатор частини повідомлення",
  PartTitle: "Заголовок частини",
  SecurityClassificationAbbreviated: "Абревіатура класифікації захищеності",
  SpecialHandlingDesignator: "Позначення особливої обробки",
  EnemyOrganizationDesignator: "Позначення ворожої організації",
  VerifiedUnitSizeIndicator: "Перевірений показник розміру підрозділу",
  VerifiedNationality: "Перевірена національність",
  VerifiedUnitRoleIndicatorCodeA: "Перевірений код показника ролі підрозділу A",
  VerifiedUnitRoleIndicatorCodeB: "Перевірений код показника ролі підрозділу B",
  VerifiedUnitRoleIndicatorCodeC: "Перевірений код показника ролі підрозділу C",
  VerifiedUnitRoleIndicatorCodeD: "Перевірений код показника ролі підрозділу D",
  VerifiedHigherFormationName: "Перевірене ім'я вищої формації",
  VerifiedArmedService: "Перевірена збройна служба",
  UnidentifiedEnemyUnitAddressingNumber: "Невідомий номер адресації ворожого підрозділу",
  UnknownEnemyUnitAddressingNumber: "Невідомий номер адресації невідомого ворожого підрозділу",
  UnidentifiedOrUnknownEnemyUnits: "Невідомі або невідомі ворожі підрозділи",
  UnidentifiedAddressingNumberPrefix: "Префікс невідомого номера адресації",
  UnidentifiedAddressingNumber: "Невідомий номер адресації",
  UnknownAddressingNumberPrefix: "Префікс невідомого номера адресації",
  UnknownAddressingNumber: "Невідомий номер адресації",
  VerifiedNumberOfEnemyUnits: "Перевірена кількість ворожих підрозділів",
  NumberOfEnemyUnits: "Кількість ворожих підрозділів",
  StageOfConfirmation: "Етап підтвердження",
  TypeOfGraphicShape: "Тип графічної форми",
  LocationAmplification: "Розширення місцезнаходження",
  LocationType: "Тип місцезнаходження",
  Activity: "Діяльність",
  ActivityType: "Тип діяльності",
  ActivityQualifier: "Кваліфікатор діяльності",
  ActivityCodewordDesignator: "Позначник кодового слова діяльності",
  Direction: "Напрямок",
  DirectionOfMovement: "Напрямок руху",
  SpeedInKilometresPerHour: "Швидкість у кілометрах на годину",
  EnemyOrganizationalBoundary: "Межа ворожої організації",
  UnitDesignationName1: "Назва позначення підрозділу 1",
  VerifiedUnitSizeIndicator1: "Перевірений показник розміру підрозділу 1",
  VerifiedNationality1: "Перевірена національність 1",
  VerifiedUnitRoleIndicatorCodeA1: "Перевірений код показника ролі підрозділу A1",
  BoundaryUnitLevelIndicator: "Показник рівня підрозділу межі",
  UnitDesignationName2: "Назва позначення підрозділу 2",
  VerifiedUnitSizeIndicator2: "Перевірений показник розміру підрозділу 2",
  VerifiedNationality2: "Перевірена національність 2",
  VerifiedUnitRoleIndicatorCodeA2: "Перевірений код показника ролі підрозділу A2",
  AssessmentOfIntentions: "Оцінка намірів",
  FriendlyForces: "Дружні сили",
  HigherUnitMission: "Місія вищої одиниці",
  HigherCommandersIntentOrConceptOfOperations: "Наміри вищого командування або концепція операцій",
  FriendlyUnitSegment: "Сегмент дружнього підрозділу",
  LocationAndNearestFeatureOfUnit: "Місцезнаходження та найближчий об'єкт підрозділу",
  OperationalMovement: "Операційний рух",
  MovementDirection: "Напрямок руху",
  Speed: "Швидкість",
  ContextQuantity03DecimalPtPermitted: "Кількість з контекстом (03 змінні з трьома десятковими знаками)",
  OrganizationBoundary: "Межа організації",
  UnitDesignationNameLeft: "Назва позначення підрозділу (лівий)",
  UnitSizeIndicatorLeft: "Показник розміру підрозділу (лівий)",
  NationalityLeft: "Національність (ліва)",
  UnitRoleIndicatorLeft: "Показник ролі підрозділу (лівий)",
  UnitDesignationNameRight: "Назва позначення підрозділу (правий)",
  UnitSizeIndicatorRight: "Показник розміру підрозділу (правий)",
  NationalityRight: "Національність (права)",
  UnitRoleIndicatorRight: "Показник ролі підрозділу (правий)",
  LocationAndNearestFeatureOfAdjacentUnit: "Місцезнаходження та найближчий об'єкт сусіднього підрозділу",
  CoordinatingInstructions: "Координуючі інструкції",
  ReportedUnitLocationSegment: "Сегмент місцезнаходження повідомленого підрозділу",
  OrderLocationType: "Тип місця розташування (Тип місця розташування замовлення)",
  Area: "Область",
  GeographicPositionGeorefCentiminute: "Географічне положення (географічний референсний кут від 1 до 100)",
  PlaceName: "Назва місця",
  TrueBearingAndDistanceInNmFromReferencePoint: "Справжнє напрямок і відстань в морських милях від посилання",
  QRoutePosition: "Положення в маршруті Q",
  BoundaryDefinitionPoint: "Точка визначення межі",
  CentiminuteAngular_1: "Центимінутний кутовий (1)",
  CentiminuteAngular_2: "Центимінутний кутовий (2)",
  DirectionInDegreesTrue: "Напрямок в градусах (справжній)",
  Hyphen_1: "Дефіс (1)",
  ReferencePointName: "Назва посилання",
  Hyphen_2: "Дефіс (2)",
  DistanceInNauticalMiles: "Відстань у морських милях",
  QRouteNumber: "Номер маршруту Q",
  QRoutePointDesignator: "Позначення точки маршруту Q",
  DistanceInNauticalMilesFromAQRoutePoint: "Відстань у морських милях від точки маршруту Q",
  QRouteLateralDisplacementQualifier: "Кваліфікатор бокового відхилення маршруту Q",
  LateralDisplacementFromAQRouteTrackInTensOfYds: "Бокове відхилення від треку маршруту Q в десятках ярдів",
  Line: "Лінія",
  LineName: "Назва лінії",
  LineDesignator: "Позначник лінії",
  LineLocation: "Місце розташування лінії",
  TimeQualifier: "Кваліфікатор часу",
  ReferenceTimeIndicator: "Показник посилання на час",
  AdjustedReferenceTime: "Відкоригований посилання на час",
  TimeIndicator: "Показник часу",
  ArithmeticChange: "Арифметична зміна",
  RouteInformationSegment: "Сегмент інформації про маршрут",
  MovementDetails: "Деталі руху",
  MovementStatus: "Статус руху",
  DtgOfArrival: "Дата та час прибуття",
  DtgOfDeparture: "Дата та час відправлення",
  TimeOfArrivalOrDeparture: "Час прибуття або відправлення",
  DelayedDepartedTime: "Час затримки відправлення",
  ReasonForDelayCancel: "Причина затримки/скасування",
  LocationAndNearestFeatureOfOrganization: "Місцезнаходження та найближчий об'єкт організації",
  GuidesReconnaissanceAndHarbourParties: "Керівники розвідувальних та гаванних команд",
  RestrictionsOnReconnaissance: "Обмеження на розвідку",
  Air: "Повітря",
  AirDefence: "Повітряна оборона",
  CbrnDefence: "Захист від ХДВР",
  Patrols: "Патрулювання",
  Deception: "Маскування",
  NightOperations: "Нічні операції",
  SurveillanceCounterSurveillanceAndTargetAcquisition: "Спостереження, контррозвідка та засоби виявлення цілей",
  CoordinatingConference: "Координуюча конференція",
  LocationAndNearestFeatureOfTarget: "Місцезнаходження та найближчий об'єкт цілі",
  OffensiveOperationsSegment: "Сегмент наступальних операцій",
  OffensiveOperations: "Наступальні операції",
  DefensiveOperations: "Оборонні операції",
  TransitionalPhases: "Перехідні фази",
  NewPositionOccupationSegment: "Сегмент зайняття нової позиції",
  OccupationOfNewPositionDetails: "Деталі зайняття нової позиції",
  CarriagewayConditions: "Умови рухової частини дороги",
  ConditionLocation: "Місце розташування стану",
  Condition: "Стан",
  HeadquartersLocationMovement: "Місце розташування та рух штабу",
  HeadquartersType: "Тип штабу",
  HeadquartersLocation: "Місце розташування штабу",
  DtgOfClosure: "Дата та час закриття",
  PlannedLocation: "Заплановане місце розташування",
  DtgPlannedToBecomeEffective: "Дата та час, коли планується набути чинності",
  DtgOfClosureAtPlannedLocation: "Дата та час закриття в запланованому місці розташування",
  OrganizationFunction: "Функція організації",
  CommunicationsRadioFrequencyAndCallsign: "Частота радіозв'язку та викликний знак",
  CallSignAndFrequency: "Викликний знак та частота",
  OwnCallSignOnAllArmsNet: "Власний викликний знак на всіх мережах зв'язку",
  RendezvousDetails: "Деталі зустрічі",
  RendezvousLocation: "Місце зустрічі",
  IncidentType: "Тип інциденту",
  SourceInformationIncrepSegment: "Сегмент інформації джерела",
  IncidentDateTimeGroup: "Дата та час інциденту",
  LocationCoordinatesAndDescription: "Координати місця та опис",
  PersonnelResponsibleForIncidentSegment: "Сегмент відповідальних осіб за інцидент",
  WeaponUsed: "Використана зброя",
  EffectOrDamageCaused: "Спричинений ефект або пошкодження",
  OperationalEffect: "Операційний ефект",
  PersonnelCasualties: "Втрати серед персоналу",
  ArrestIncrepSegment: "Сегмент затримання",
  TerroristBombing: "Терористичний підрив",
  TerroristShooting: "Терористична стрілянина",
  OtherTerroristIncident: "Інцидент іншого терористичного характеру",
  CivilUnrest: "Громадянська нетерпимість",
  NaturalDisaster: "Природна катастрофа",
  MediaEvent: "Подія засобів масової інформації",
  SourceOfInformation: "Джерело інформації",
  GeneralSourceType: "Загальний тип джерела",
  EvaluationOfInformation: "Оцінка інформації",
  EvaluationOfReliability: "Оцінка надійності",
  EvaluationOfAccuracy: "Оцінка точності",
  OrganizationDesignatorOfInformationSource: "Організаційний позначник джерела інформації",
  OrganizationAndPersonnelResponsible: "Організація та відповідальний персонал",
  OrganizationIdentifier: "Ідентифікатор організації",
  PersonnelStrength0999999: "Сила особового складу (від 0 до 999999)",
  NameIndividual: "Ім'я фізичної особи",
  DirectionOfMovementFromEvent: "Напрямок руху від події",
  DirectionOfMovementToEvent: "Напрямок руху до події",
  MeansOfTravel: "Засіб пересування",
  WeaponTypeGeneric: "Тип зброї (загальний)",
  AsOfDateTimeGroup: "Станом на дату та час групи",
  EffectOnOperations: "Вплив на операції",
  DetailsOfOperationalEffect: "Деталі операційного впливу",
  CasualtyCategory: "Категорія втрат",
  NumberKilled: "Кількість загиблих",
  NumberInjured: "Кількість поранених",
  ArrestDetails: "Деталі затримання",
  DateTimeGroupOfArrest: "Дата та час затримання",
  CauseOrReason: "Причина або причина",
  PersonDetails: "Деталі особи",
  Age: "Вік",
  PersonnelGender: "Стать персоналу",
  PersonalIdentificationNumber: "Персональний ідентифікаційний номер",
  Address: "Адреса",
  MilitaryRankOrRateAndRating: "Військове звання або ранг та рейтинг",
  PurposeOfReport: "Мета звіту",
  IncidentInformationSegment: "Сегмент інформації про інцидент",
  IncidentNumber: "Номер інциденту",
  IncidentQualifier: "Додаткові дані про інцидент",
  IncidentDetailsSegment: "Сегмент деталей інциденту",
  FriendlyForcesInvolved: "Заangaуби військ",
  PressInformation: "Інформація для преси",
  IntelligenceSubjectDataSegment: "Сегмент даних розвідки",
  SubjectReferenceData: "Дані про суб'єкт",
  Title: "Назва",
  StateProvinceDistrict: "Штат / Провінція / Район",
  BearingAndDistanceFromReferencePoint: "Курс та відстань від точки відліку",
  GeographicReference: "Географічний посилання",
  Bearing: "Курс",
  AngularMeasurementReference: "Посилання на кутові виміри",
  UnitOfLinearMeasurementFtYdNmMKmKyd: "Одиниця лінійного вимірювання (фут, ярд, морська миля, кілометр, кабельтова довжина)",
  StartDateYyyymmdd: "Дата початку (у форматі ррррммдд)",
  StartDateTimeYyyymmddhhmm: "Дата та час початку (у форматі ррррммддггхххх)",
  DtgOfStart: "Дата та час початку",
  StartingDate: "Дата початку",
  EndDateYyyymmdd: "Дата завершення (у форматі ррррммдд)",
  EndDateTimeYyyymmddhhmm: "Дата та час завершення (у форматі ррррммддггхххх)",
  DtgOfStop: "Дата та час завершення",
  EndingDate: "Дата завершення",
  SubjectDescription: "Опис суб'єкта",
  SubjectAssessment: "Оцінка суб'єкта",
  LogisticAssistanceRequestSegment: "Сегмент запиту на логістичну допомогу",
  RequestItems: "Елементи запиту",
  NatoStockNumber: "Номер НАТО для запасів",
  ShortTitle: "Коротка назва",
  UnitOfMeasurementStockItem: "Одиниця вимірювання для запасу",
  ContextQuantity0999999: "Кількість (від 0 до 999999)",
  QuantityOfItems: "Кількість елементів",
  UnitOfMeasure: "Одиниця виміру",
  TargetDateForDelivery: "Цільова дата доставки",
  TransportRequired: "Потреба в транспортуванні (позначник потреби в транспортуванні)",
  TransportationDestination: "Пункт призначення для транспортування",
  DebarkationLocationType: "Тип місця висадки",
  AirportOfDebarkation: "Аеропорт висадки",
  DestinationPortCode: "Код пункту призначення",
  Obstruction: "Перешкода",
  StatusOfConfirmation: "Статус підтвердження",
  ObstructionType: "Тип перешкоди",
  ShapeOfObstacle: "Форма перешкоди",
  MissionRefuellingLocationAndFuelInformation: "Інформація про місце польотного заправлення та паливо",
  GridReferenceLocationCoordinate: "Координати місцезнаходження за сіткою координат",
  FuelQuantity: "Кількість палива",
  ContextQuantityDecimalPtPermitted12CharMax: "Контекстна кількість (з десятковою крапкою, максимум 12 символів)",
  UnitOfFuelMeasurement: "Одиниця виміру палива",
  NatoStandardFuelType: "Стандартний тип палива НАТО",
  NatoStandardFuelTypeAlternate: "Альтернативний тип палива НАТО",
  Explanation: "Пояснення",
  AdditionalServicesRequired: "Потрібні додаткові послуги",
  ImpactOfNonsupply: "Вплив непостачання",
  MethodOfReimbursement: "Метод відшкодування",
  ReimbursementInformation: "Інформація про відшкодування",
  SupplyOfRequestedItemSegment: "Сегмент постачання запитуваного елемента",
  LogisticsSupportDecision: "Рішення про логістичну підтримку",
  AuthorityDecision: "Рішення органу влади",
  ReasonForRefusalOfLogisticsSupportRequest: "Причина відмови у запиті на логістичну підтримку",
  Reimbursement: "Відшкодування",
  DecisionInformation: "Інформація про рішення",
  IssueLocationAndTime: "Місце видачі та час",
  UnitLogisticInformationSegment: "Сегмент інформації про логістику військової частини",
  UnitSLogisticSustainability: "Логістична стійкість військової частини",
  CurrentOrFutureRequirementsSegment: "Сегмент поточних або майбутніх вимог",
  CurrentCapabilityFutureRequirement: "Поточна можливість / майбутні вимоги (загальний період часу)",
  StockNumber: "Номер запасу",
  DaysOfSupply: "Кількість днів запасу",
  UnitOfLinearMeasurement: "Одиниця виміру довжини",
  UnitOfVolumeMeasurement: "Одиниця виміру об'єму",
  UnitOfWeightMeasurement: "Одиниця виміру ваги",
  UnitOfPackingMeasurement: "Одиниця виміру упаковки",
  UnitOfMeasurement: "Одиниця виміру",
  QuantityOnHand: "Кількість на руках",
  ReportableItemCodeRic: "Код повідомлення про елемент RIC",
  ItemIdentifyingNumber: "Ідентифікаційний номер елемента",
  StockItemQuantityAndUnitOfMeasurement: "Кількість елементів запасу та одиниця виміру",
  Quantity: "Кількість",
  AffirmativeOrNegativeIndicatorAbbreviated: "Позитивний або негативний індикатор (скорочено)",
  PolTypeName: "Назва типу ПОЛ",
  CubicMetresOnHand: "Кубічні метри на руках",
  AmmunitionShortTitleSpecificationName: "Коротка назва / специфікація боєприпасів",
  QuantityOnHandIncludingUblRounds: "Кількість на руках, включаючи РЗБ",
  GeneralPersonnelType: "Загальний тип персоналу",
  CountOfPersonnel0999999999: "Кількість персоналу (від 0 до 999999999)",
  WaterType: "Тип води",
  QuantityAndUnitOfCapacityMeasurement: "Кількість та одиниця виміру ємності",
  ContextQuantity19999999: "Контекстна кількість (від 0 до 19999999)",
  UnitOfCapacityMeasurement: "Одиниця виміру ємності",
  SurlusOrDeficientItemsSegment: "Сегмент надлишків або дефіцитів",
  SurplusOrDeficiency: "Надлишки або дефіцити",
  ClassOfSupply: "Клас постачання",
  RoundsPerWeapon: "Кількість пострілів на зброю",
  Allowance: "Допустиме значення",
  DataEntry: "Введення даних",
  ActionsTakenOrInHandToCorrect: "Дії, проведені або виконані для виправлення",
  ActionRequiredOfHigherAuthority: "Дія, яку потрібно виконати вищим органом влади",
  LogisticUpdateSegment: "Сегмент логістичного оновлення",
  ReportedItemDetailsSegment: "Сегмент деталей про звітний елемент",
  ReportedItem: "Звітний елемент",
  ReportableItemCode: "Код звітного елемента",
  RequiredTotal: "Потрібна загальна кількість",
  RequiredOnHand: "Потрібна кількість на руках",
  ActualOnHand: "Фактична кількість на руках",
  TotalDueIn: "Загальна кількість, що надходить",
  TotalDueOut: "Загальна кількість, що виходить",
  RequiredStocksCalculation: "Розрахунок потрібних запасів",
  LimitingFactorsRemarks: "Обмежувальні фактори / зауваження",
  EffectiveDtg: "Ефективна Дата/час/група",
  MapsRequired: "Потрібні карти",
  ReasonForTheRequest: "Причина запиту",
  PickupOrDelivery: "Забір або доставка",
  TransferMethodIndicator: "Індикатор методу передачі",
  FirstLineOfAddress: "Перший рядок адреси",
  SecondLineOfAddress: "Другий рядок адреси",
  ThirdLineOfAddress: "Третій рядок адреси",
  FourthLineOfAddress: "Четвертий рядок адреси",
  PostalCode: "Поштовий індекс",
  AmendmentNumber: "Номер зміни",
  MessageCancellation: "Скасування повідомлення",
  AmendmentMessageSerialNumber: "Серійний номер змінного повідомлення",
  ReferenceSetSerialLetter: "Серійний лист посилання на набір",
  SetAmendmentToAPreviouslyTransmittedMessage: "Зміна до попередньо переданого повідомлення",
  AmendmentSetIdentifier: "Ідентифікатор змінного набору",
  RepeatedSetOccurrence: "Повторна подія набору",
  AmendmentType: "Тип зміни",
  FormetsSetType: "Тип набору FORMETS",
  SetSequenceNumber: "Порядковий номер набору",
  DataLineSequenceNumber: "Порядковий номер лінії даних",
  SetIdentifierAndAmendedText: "Ідентифікатор набору та змінений текст",
  MetStationData: "Дані метеостанції",
  ComputerMeteorologicalData: "Комп'ютерні метеорологічні дані",
  GlobalOctant: "Глобальний октант",
  GeographicPositionLatLongTenthsOfDegreesMet: "Географічне положення (широта та довгота в десятках градусів)",
  CodedLocationName: "Закодована назва місця",
  MetStationLocation: "Місце розташування метеостанції",
  LatitudeTenthsOfDegrees: "Широта (десятки градусів)",
  LongitudeTenthsOfDegreesMet: "Довгота (десятки градусів)",
  MetValidity: "Валідність метеоданих",
  TenthOfHour: "Десята години",
  MetPeriodOfValidityInHours: "Період валідності метеоданих в годинах",
  MetStationHeightAndPressure: "Висота метеостанції та тиск",
  MetStationHeightInTensOfMetres: "Висота метеостанції (десятки метрів)",
  SurfacePressureInMillibars: "Атмосферний тиск на поверхні (мілібари)",
  ComputerMeteorologicalZoneCode: "Код зони комп'ютерної метеорології",
  WindDirectionAndSpeed: "Напрямок та швидкість вітру",
  WindDirectionFromTrueNorthInTensOfMils: "Напрямок вітру від справжнього північного (десятки міль)",
  WindSpeedInKnots: "Швидкість вітру в вузлах",
  AirTemperatureAndPressure: "Температура повітря та тиск",
  AirTemperatureTenthsOfADegreeKelvin: "Температура повітря (десятки градусів Кельвіна)",
  AtmosphericPressureMillibars: "Атмосферний тиск (мілібари)",
  MetType: "Тип метеоданих",
  MetArea: "Область метеоданих",
  MetTiming: "Часові рамки метеоданих",
  MetLineNumbers: "Номери ліній метеоданих",
  MetTypeAndGlobalOctant: "Тип метеоданих та глобальний октант",
  MetMessageRequest: "Запит на повідомлення про метеодані",
  TypeOfMetReportRequested: "Тип запиту на метеодані",
  AreaOfInterest: "Область інтересу",
  SupportTiming: "Часові рамки підтримки",
  StartHourTime: "Початок (години)",
  StopHourTime: "Кінець (години)",
  MetDataLimits: "Обмеження метеоданих",
  LowestMeteorologicalAltitudeBand: "Найнижчий метеорологічний пояс",
  HighestMeteorologicalAltitudeBand: "Найвищий метеорологічний пояс",
  DaysSupportRequested: "Кількість запитаних днів підтримки",
  IntervalBetweenMetMessagesInHours: "Інтервал між повідомленнями про метеодані в годинах",
  OrganizationDesignator_1: "Ідентифікатор організації 1",
  MijiIncidentType: "Тип інциденту МІЖІ",
  OrganizationDesignator_2: "Ідентифікатор організації 2",
  SystemsAffected: "Порушені системи",
  MijiTargetType: "Тип цілі МІЖІ",
  FrequencyChannelAffected: "Затронуті частотні канали",
  FrequencyBandAndChannel: "Діапазон і канал частот",
  Frequency: "Частота",
  AlphabeticIdentifierFreqencyBand: "Алфавітний ідентифікатор діапазону частот",
  CommunicationsChannelNumber: "Номер комунікаційного каналу",
  ModulationType: "Тип модуляції",
  SignalStrength: "Сила сигналу",
  DurationInTime: "Тривалість в часі",
  StartTime: "Початок (час)",
  DurationInDays: "Тривалість в днях",
  DurationInHours0099: "Тривалість в годинах (00-99)",
  DurationInMinutes0099: "Тривалість в хвилинах (00-99)",
  DurationInSeconds0099: "Тривалість в секундах (00-99)",
  Duration: "Тривалість",
  Assessment: "Оцінка",
  MovementCompletionReport: "Звіт про завершення руху",
  MessageDowngradingOrDeclassificationData: "Дані про пониження класу або декласифікацію повідомлення",
  UnitIdentificationCode_1: "Ідентифікаційний код одиниці 1",
  UnitIdentificationCode_2: "Ідентифікаційний код одиниці 2",
  TaskedUnit: "Призначена одиниця",
  Identification: "Ідентифікація",
  OperationalStatusTime: "Час оперативного стану",
  NewLocation: "Нове розташування",
  DerivativeSourceForClassification: "Похідне джерело для класифікації",
  NameOfOriginalClassificationAuthority: "Назва органу, що здійснив початкову класифікацію",
  SourceOfClassification: "Джерело класифікації",
  ReasonForClassification: "Причина класифікації",
  DowngradingOrDeclassificationInstructions: "Інструкції щодо пониження класу або декласифікації",
  DowngradeOrDeclassificationDate: "Дата пониження класу або декласифікації",
  DowngradingOrDeclassificationExemptionCode: "Код винятку пониження класу або декласифікації",
  FirePlanHHour: "Плановий час початку вогню",
  EstimatedFirePlanTime: "Приблизний час початку вогню",
  EstimatedDateTimeGroupOfStop: "Приблизна дата/час/група кінця",
  EstimatedDtgOfStop: "Приблизна Дата/час/група кінця",
  TargetInstance: "Екземпляр цілі",
  TimeRelativeToHHour: "Час відносно години H",
  TargetPriority: "Пріоритет цілі",
  TargetGroup: "Група цілей",
  TargetListDesignator: "Ідентифікатор списку цілей",
  Volleys: "Вольт",
  ProjectilesShells: "Снаряди/гільзи",
  Fuze: "Бойовий запал",
  TimeRelativeToHHourInMinutes: "Час відносно години H (хвилини)",
  DurationOfFireInMinutesRelativeToHHour: "Тривалість вогню в хвилинах відносно години H",
  TargetGroupName: "Назва групи цілей",
  TrajectoryOfAttack: "Траєкторія атаки",
  NumberOfVolleys: "Кількість вольт",
  VolleysFirstProjectileFuze: "Перший снаряд/гільза/бойовий запал в вольті",
  VolleysSecondProjectileFuze: "Другий снаряд/гільза/бойовий запал в вольті",
  AttackProjectiles: "Снаряди для атаки",
  FirstSelectedProjectile: "Перший вибраний снаряд",
  SecondSelectedProjectile: "Другий вибраний снаряд",
  FuzesForAttack: "Бойові запали для атаки",
  FirstSelectedFuze: "Перший вибраний бойовий запал",
  SecondSelectedFuze: "Другий вибраний бойовий запал",
  StrikwarnTargetIdentifier: "Ідентифікатор цілі Strikwarn",
  DateTimeOfStrikeDateTimeStrikeCancelled: "Дата/час забиття/скасування удару",
  MinimumSafeDistanceOne: "Мінімальна безпечна відстань 1",
  MinimumSafeDistanceTwo: "Мінімальна безпечна відстань 2",
  NumberOfSurfaceBursts: "Кількість поверхневих вибухів",
  NumberOfBurstsInAMultipleStrike: "Кількість вибухів у множинному ударі",
  TargetReferenceNumber: "Номер посилання на ціль",
  TargetNickname: "Псевдонім цілі",
  TargetNumberNickname: "Номер або псевдонім цілі",
  DtgOfStrikeZulu: "Дата/час/група удару Zulu",
  DtgStrikeCancelledZulu: "Дата/час/група скасування удару Zulu",
  MinimumSafeDistance1InHundredsOfMetres: "Мінімальна безпечна відстань 1 (сотні метрів)",
  Msd1BoxArea: "Площа для Мінімальна безпечна відстань 1",
  MinimumSafeDistance2InHundredsOfMetres: "Мінімальна безпечна відстань 2 (сотні метрів)",
  Msd2BoxArea: "Площа для Мінімальна безпечна відстань 2",
  UnitDetailsSegment: "Сегмент деталей підрозділу",
  CombatEffectiveness: "Бойова ефективність",
  PersonnelStrengthRating: "Рейтинг чисельності персоналу",
  ManpowerFatigueRating: "Рейтинг втоми персоналу",
  MajorEquipmentsAvailableRating: "Рейтинг доступності основного обладнання",
  AmmunitionHoldingsRating: "Рейтинг наявності боєприпасів",
  FuelHoldingsRating: "Рейтинг наявності палива",
  UnitStatus: "Статус підрозділу",
  UnitReadinessStatus: "Статус готовності підрозділу",
  UnitAvailabilityStatus: "Статус доступності підрозділу",
  UnitAssignmentStatus: "Статус призначення підрозділу",
  CommandRelationship: "Відношення командування",
  PlannedOperationalCommand: "Запланована оперативна команда",
  CommandId: "Ідентифікатор команди",
  PlannedOperationalControl: "Запланований оперативний контроль",
  ControlId: "Ідентифікатор контролю",
  PlannedTaskDesignator: "Запланований ідентифікатор завдання",
  NationalParentIdentifier: "Національний ідентифікатор батьківської одиниці",
  ParentId: "Ідентифікатор батьківської одиниці",
  NatoParentIdentifier: "Ідентифікатор батьківської одиниці НАТО",
  NatoId: "Ідентифікатор НАТО",
  PlannedToaCommand: "Запланований Toa команди",
  PlannedToaControl: "Запланований Toa контролю",
  UnitDataInformation: "Інформація про дані підрозділу",
  EchelonLevel: "Рівень ешелону",
  ForceType: "Тип сили",
  UnitFunctionalCategory: "Функціональна категорія підрозділу",
  NumberOfUnits: "Кількість підрозділів",
  Unit: "Підрозділ",
  WartimeBaseOrLocation: "База або місцезнаходження воєнного часу",
  WartimeLocation: "Місцезнаходження воєнного часу",
  DayCodeToArrival: "Код дня до прибуття",
  EstimatedDateOfArrivalDdmmmyyyy: "Орієнтовна дата прибуття (ДДМММРРРР)",
  DayCode: "Код дня",
  PlusOrMinusIndicator: "Індикатор плюс або мінус",
  Identifier: "Ідентифікатор",
  DeploymentData: "Дані про розгортання",
  OwnForcesTaskOrganizationSegment: "Сегмент організації завдань власних сил",
  CommandersAssessmentOwnForcesTaskOrganization: "Оцінка командира організації завдань власних сил",
  OwnForcesPresenceReportSegment: "Сегмент звіту про присутність власних сил",
  CommandersAssessmentOwnForcesPresenceReports: "Оцінка командира звітів про присутність власних сил",
  OwnForcesUnitStatusSegment: "Сегмент стану підрозділів власних сил",
  CommandersAssessmentOwnForcesUnitStatus: "Оцінка командира стану підрозділів власних сил",
  ControlAndCoordinationLinesSegment: "Сегмент ліній контролю та координації",
  CdrAssessOwnSituation: "Оцінка командира власної ситуації",
  MessagePartAndTitle: "Частина повідомлення та заголовок",
  UnitTaskOrganizationSegment: "Сегмент організації завдань підрозділу",
  CommandRelationshipType: "Тип відношення командування",
  CommandRelationshipQualifier: "Кваліфікатор відношення командування",
  SubordinateUnitTaskOrganizationSegment: "Сегмент організації завдань підпорядкованих підрозділів",
  ReportedUnitSegment: "Сегмент звіту про підрозділи",
  TimePeriodCovered: "Часовий період",
  DateTimeGroupFrom: "Дата/час (початок)",
  DateTimeGroupTo: "Дата/час (кінець)",
  ReportedUnitLocationsSegment: "Сегмент місцезнаходження звіту про підрозділи",
  LocationNearestFeatureAndConfirmationStatus: "Місцезнаходження найближчого об'єкту та статус підтвердження",
  UnitActivitySegment: "Сегмент активності підрозділу",
  UnitEffectivenessSegment: "Сегмент ефективності підрозділу",
  ContextQuantity099999: "Кількість (контекст) (0-99999)",
  CommunicationsLinkType: "Тип зв'язку",
  QuantityOfEquipment: "Кількість обладнання",
  CommunicationTrafficType: "Тип комунікаційного трафіку",
  LinkStatus: "Статус зв'язку",
  NatoSecurityClassificationAbbreviated: "Скорочена НАТО-класифікація безпеки",
  SpecialHandlingMarkings: "Спеціальні позначення",
  FrequencySpectrumDesignator: "Ідентифікатор спектру частот",
  EnemyInformationSegment: "Сегмент інформації про ворожий підрозділ",
  AttitudeAndIntention: "Сталище та наміри",
  UnitControlAndCoordinationLineSegment: "Сегмент ліній контролю та координації підрозділу",
  ControlOrCoordinationLineTypeSegment: "Сегмент типу ліній контролю та координації",
  ControlOrCoordinationLineType: "Тип лінії контролю та координації",
  LineIdentifier: "Ідентифікатор лінії",
  QualifiedStartTime: "Кваліфікований початок",
  StartTimeQualifier: "Кваліфікатор початку",
  PersonnelDetailsSegment: "Сегмент даних про персонал",
  OverallAssessment: "Загальна оцінка",
  PersonnelCategoryAndGender: "Категорія та стать персоналу",
  NumberAuthorized: "Кількість (уповноважена)",
  EffectiveStrength: "Фактична чисельність",
  NumberAttached: "Кількість (прикріплено)",
  NumberDetached: "Кількість (відокремлено)",
  NumberOfDead: "Кількість (загиблих)",
  NumberOfIncapacitated: "Кількість (непрацездатних)",
  NumberOfMissing: "Кількість (зниклих)",
  NumberOfOwnPow: "Кількість (власних плацдармів)",
  NumberOfEnemyPow: "Кількість (ворожих плацдармів)",
  PersonnelAssessment: "Оцінка персоналу",
  ReportedUnitLocation: "Місцезнаходження звіту про підрозділи",
  ReportedSubordinateUnit: "Підпорядкований підрозділ звіту",
  ReportedUnitLocationDetails: "Деталі місцезнаходження звіту про підрозділи",
  ReportableTime: "Час звіту",
  ReportableQualifier: "Кваліфікатор звіту",
  SubordinateUnitLocation: "Місцезнаходження підпорядкованого підрозділу",
  ReportedSubordinateUnitLocationDetails: "Деталі місцезнаходження підпорядкованого підрозділу",
  UnitRelocationRequest: "Запит на переведення підрозділу",
  OrganizationDesignatorOfRequestOriginator: "Організаційний ідентифікатор ініціатора запиту",
  'DateTimeGroupOfDeparture (Dtg)': "Дата/час відправлення (Dtg)",
  UnitRelocationDetails: "Деталі переведення підрозділу",
  ProposedLocationAndNearestFeature: "Запропоноване місцезнаходження та найближчий об'єкт",
  ArrivalAndDepartureTimings: "Час прибуття та відправлення",
  DtgOfEarliestArrival: "Дата/час найближчого прибуття",
  DtgOfLatestDeparture: "Дата/час останнього відправлення",
  AuthorizingUnitDecision: "Рішення уповноваженого підрозділу",
  OrganizationDesignatorOfAllocatingUnit: "Організаційний ідентифікатор виділяючого підрозділу",
  RelocationDecision: "Рішення щодо переведення",
  Decision: "Рішення",
  PointOfContactNewLocation: "Контактна особа нового місцезнаходження",
  PeriodOfTime: "Період часу",
  PassageAndIntendedMovementSegment: "Сегмент проходу та задуманого руху",
  SatelliteVulnerability: "Уразливість супутника",
  SatelliteCountermeasures: "Контрзаходи для супутника",
  EmitterControl: "Контроль над випромінювачем",
  StartOfPeriod: "Початок періоду",
  DtgAsapaft: "Дата/час після-початковий",
  DtgAsapnlt: "Дата/час після-кінцевий",
  DtgNet: "Дата/час нетто",
  DtgNlt: "Дата/час нетто-кінцевий",
  VariableTimeQualifier: "Кваліфікатор змінного часу",
  EndOfPeriod: "Кінець періоду",
  PassagePlan: "План проходу",
  MovementArea: "Область руху",
  GeographicPositionOfStartForThePassage: "Географічне положення початку проходу",
  DtgAtPosition: "Дата/час на позиції",
  PositionOrPointName: "Назва позиції або точки",
  DtgOfEnteringArea: "Дата/час входу в область",
  DtgOfLeavingArea: "Дата/час виходу з області",
  SatelliteSystemNameOrDesignator: "Назва або ідентифікатор супутникової системи",
  StartTimeOfSatelliteVulnerability: "Початковий час уразливості супутника",
  StopTimeOfSatelliteVulnerability: "Кінцевий час уразливості супутника",
  GeneralStatement: "Загальна заява",
  Weather: "Погода",
  PoliticalDevelopments: "Політичні події",
  IntelligenceInformation: "Розвідувальна інформація",
  MaritimeOperations: "Морські операції",
  LandOperations: "Сухопутні операції",
  AirOperations: "Повітряні операції",
  ReleaseOperations: "Операції звільнення",
  PersonnelSituation: "Ситуація з особовим складом",
  LogisticSituation: "Логістична ситуація",
  CommunicationSituation: "Ситуація зі зв'язком",
  PublicInformationSituation: "Ситуація з публічною інформацією",
  InformationCampaignSituation: "Ситуація з інформаційною кампанією",
  CivilMilitaryCooperation: "Цивільно-військове співробітництво",
  MessageReply: "Відповідь на повідомлення",
  MessageReplyCode: "Код відповіді на повідомлення",
  ReportRequirement: "Вимога до звіту",
  BattlefieldGeometry: "Геометрія бойового поля",
  TypeOfFireSupportReportRequested: "Тип запиту звіту про підтримку вогнем",
  ReportFrequency: "Частота звіту",
  DateTimeGroupOfStart: "Дата/час початку (DateTimeGroup)",
  DateTimeGroupOfStop: "Дата/час закінчення (DateTimeGroup)",
  BattlefieldGeometryType: "Тип геометрії бойового поля",
  BattlefieldGeometryTypeName: "Назва типу геометрії бойового поля",
  TargetIdentification: "Ідентифікація цілі",
  AdministrationData: "Адміністративні дані",
  TargetReferenceDetailsSegment: "Сегмент деталей посилання на ціль",
  EntityAssociationSegment: "Сегмент асоціації сутності",
  TargetCharacteristicsRoot: "Кореневі характеристики цілі",
  TargetDataValidity: "Дійсність даних про ціль",
  TargetLocationDetailsSegment: "Сегмент деталей місцезнаходження цілі",
  DamageInflicted: "Завдані пошкодження",
  InflictedDamageData: "Дані про завдані пошкодження",
  RecuperationCapability: "Можливість відновлення",
  RecuperationData: "Дані про відновлення",
  TargetDimensions: "Розміри цілі",
  TargetDimensionValidity: "Дійсність розмірів цілі",
  TargetKey: "Ключ цілі",
  TargetIndicator: "Індикатор цілі",
  RecordOriginator: "Автор запису",
  TargetAccessionNumber: "Номер доступу до цілі",
  TargetName: "Назва цілі",
  IntelligenceSecurityClassificationAbbreviated: "Скорочена класифікація захисту розвідувальної інформації",
  RecordStatus: "Статус запису",
  DateTimeZCreatedYyyymmddhhmmss: "Дата/час створення (DateTimeZ)",
  DateTimeZOfFirstInformationYyyymmddhhmmss: "Дата/час першої інформації (DateTimeZ)",
  DateTimeZOfLastInformationYyyymmddhhmmss: "Дата/час останньої інформації (DateTimeZ)",
  ReviewDateZYyyymmdd: "Дата перегляду (DateTimeZ)",
  DateTimeZOfLastChangeYyyymmddhhmmss: "Дата/час останньої зміни (DateTimeZ)",
  Validity: "Дійсність",
  TargetReferences: "Посилання на ціль",
  TargetOnNoStrikeList: "Ціль у списку заборонених обстрілів",
  TargetListName: "Назва списку цілей",
  TargetListNumber: "Номер списку цілей",
  TargetReferenceValidity: "Дійсність посилання на ціль",
  EntityAssociation: "Асоціація сутності",
  PlaceKey: "Ключ місця",
  EquipmentKey: "Ключ обладнання",
  UnitKey: "Ключ підрозділу",
  EventKey: "Ключ події",
  BiographicKey: "Біографічний ключ",
  EntityKey: "Ключ сутності",
  WorldAreaNumber: "Номер світової області",
  PlaceAccessionNumber: "Номер доступу до місця",
  Hyphen_3: "Дефіс",
  SuffixPlaceKey: "Суфікс ключа місця",
  EquipmentIndicator: "Індикатор обладнання",
  EquipmentFamily: "Родина обладнання",
  EquipmentSubFamily: "Підродина обладнання",
  EquipmentAccessionNumber: "Номер доступу до обладнання",
  UnitIndicator: "Індикатор підрозділу",
  OrderOfBattleIndicator: "Індикатор порядку бою",
  Hyphen_4: "Дефіс",
  UnitAccessionNumber: "Номер доступу до підрозділу",
  EventIndicator: "Індикатор події",
  EventAccessionNumber: "Номер доступу до події",
  BiographicIndicator: "Індикатор особового запису",
  BiographicAccessionNumber: "Номер доступу до особового запису",
  EntityData: "Дані сутності",
  TargetOperationalStatus: "Операційний статус цілі",
  TargetCondition: "Стан цілі",
  TargetConditionAvailability: "Доступність цілі",
  VerticalOrientationDegrees: "Вертикальна орієнтація (градуси)",
  ImageryKey: "Ключ зображення",
  ImageryIndicator: "Індикатор зображення",
  ImageryAccessionNumber: "Номер доступу до зображення",
  DetailsOnTheDesiredMeanPointOfImpactDmpi: "Деталі щодо бажаної точки удару",
  TargetDesiredMeanPointOfImpactDmpi: "Бажана точка удару цілі",
  TargetInSequence: "Ціль в послідовності",
  StartDateTimeZYyyymmddhhmmssOfADurationPeriod: "Дата/час початку (DateTimeZ) періоду тривалості",
  EndDateTimeZYyyymmddhhmmssOfADurationPeriod: "Дата/час закінчення (DateTimeZ) періоду тривалості",
  GeographicLocationData: "Дані про географічне розташування",
  TargetGeographicLocation: "Географічне розташування цілі",
  CoordinatesDatum: "Датум координат",
  GeodeticLocationData: "Дані про геодезичне розташування",
  IntelligenceCoordinates: "Координати розвідувальної інформації",
  CoordinatesBasis: "Основа координат",
  CoordinatesDateTimeZYyyymmddhhmmss: "Дата/час координат (DateTimeZ)",
  MethodOfCoordinatesDerivation: "Метод отримання координат",
  CoordinatesDerivationAccuracy: "Точність отримання координат",
  CoordinatesRadiusOfAccuracy: "Радіус точності координат",
  ContextQuantityKOrMPermitted4CharacterMax: "Кількість (K або M) (до 4 символів)",
  CoordinatesRadiusOfAccuracyConfidenceLevel: "Радіус точності координат рівня довіри",
  GeodeticInformationProducerIdentifier: "Ідентифікатор виробника геодезичної інформації",
  ElevationData: "Дані щодо висоти",
  GeographicElevation: "Географічна висота",
  ElevationMeasurementAccuracy: "Точність вимірювання висоти",
  UnitOfLinearMeasurementGeographic: "Одиниця лінійного вимірювання (географічна)",
  ElevationConfidenceLevel: "Рівень довіри висоти",
  MapDatum: "Датум карти",
  MethodOfElevationDerivation: "Метод отримання висоти",
  ElevationDerivationAccuracy: "Точність отримання висоти",
  UnitOfLinearMeasurementDerivationAccuracy: "Одиниця лінійного вимірювання (точність отримання)",
  ElevationMsl: "Висота над рівнем моря",
  ElevationMeasurementMslAccuracy: "Точність вимірювання висоти над рівнем моря",
  UnitOfLinearMeasurementMsl: "Одиниця лінійного вимірювання (над рівнем моря)",
  ElevationMslConfidenceLevel: "Рівень довіри висоти над рівнем моря",
  MethodElevationMslDerivation: "Метод отримання висоти над рівнем моря",
  ElevationMslDerivationAccuracy: "Точність отримання висоти над рівнем моря",
  UnitOfLinearMeasurementMslDerivationAccuracy: "Одиниця лінійного вимірювання (точність отримання висоти над рівнем моря)",
  GeoidalMslSeparation: "Геоїдальна відстань від рівня моря",
  UnitOfLinearMeasurementGeoidal: "Одиниця лінійного вимірювання (геоїдальна)",
  TargetLocationDataValidity: "Дійсність даних про розташування цілі",
  RecuperationInterval: "Інтервал відновлення",
  UnitOfTimeMeasurementDayWkBiwkMonYr: "Одиниця вимірювання часу (день/тиждень/два тижні/місяць/рік)",
  RecuperationIntervalMaximum: "Максимальний інтервал відновлення",
  RecuperationLossImpact: "Вплив втрат відновлення",
  RecuperationRepairPriority: "Пріоритет ремонту відновлення",
  RelativeRanking: "Відносний ранг",
  TargetDimensionsLengthWidth: "Розміри цілі (довжина/ширина)",
  TargetRadiusFtYdSmNmMKmHf: "Радіус цілі (фути/ярди/милі/морські милі/кілометри/вірсти)",
  TargetDimensionsLength: "Довжина цілі",
  TargetDimensionsWidth: "Ширина цілі",
  TargetHeightFeetOrMetres: "Висота цілі (фути/метри)",
  ContextQuantity0999: "Кількість (0-999)",
  UnitOfLinearMeasurementFeetOrMetres: "Одиниця лінійного вимірювання (фути/метри)",
  TargetShapeIntelligence: "Форма цілі (розвідування)",
  MeteoTgtPosition: "Положення метеорологічної цілі",
  TgtWeatherInfo: "Інформація про погоду цілі",
  TarwiRemarks: "Примітки TARWI",
  ObservationTime: "Час спостереження",
  TargetDescription: "Опис цілі",
  TargetPositionIdentifier: "Ідентифікатор позиції цілі",
  BasicEncyclopediaNumber: "Номер базової енциклопедії",
  AbcaTargetNumber: "Номер цілі ABCA",
  TargetIdentifier: "Ідентифікатор цілі",
  ProgramIndicatorBeNumber: "Індикатор програми/номер BE",
  InstallationIdentificationSerialNumber: "Серійний номер ідентифікації установки",
  TargetSystemIdentifier: "Ідентифікатор системи цілі",
  TargetOriginatorAndTargetClassification: "Виникнення цілі та класифікація цілі",
  TargetAreaDescription: "Опис області цілі",
  TargetPosition: "Позиція цілі",
  LowCloudCover: "Покриття хмар низької висоти",
  LowCloudBaseHeight: "Висота основи хмар низької висоти",
  SurfaceVisibility: "Видимість на поверхні",
  WeatherCondition: "Погодні умови",
  LunarIllumination: "Світіння Місяця",
  WeatherRemarks: "Примітки погоди",
  RankOrPosition: "Звання або посада",
  ContactName: "Ім'я контактної особи",
  DesignationOfTelephoneNumber: "Позначення телефонного номера",
  SecureTelephoneNumber: "Безпечний телефонний номер",
  EMailAddress30Characters: "Адреса електронної пошти (30 символів)",
  FaxNumber: "Номер факсу",
  TelephoneNumberOrEMailAddress: "Телефонний номер або адреса електронної пошти",
  DeploymentPlan: "План розгортання",
  UnitDeploymentSegment: "Сегмент розгортання підрозділу",
  DeploymentProgressAndCriticalIssues: "Хід розгортання та критичні питання",
  HeadingInformation: "Інформація про заголовок",
  OrganizationDesignatorOfUnitBeingReportedOn: "Організаційний ідентифікатор підрозділу, про який звітується",
  HomebaseOrStartingPointOfAUnitToBeDeployed: "База або точка виходу підрозділу, який буде розгорнуто",
  LocationName: "Назва місцезнаходження",
  GeographicPositionLatLongSeconds: "Географічне положення (широта, довгота у секундах)",
  EventTimeFrame: "Часовий рамки події",
  StartDateTime: "Дата/час початку",
  EndDateTime: "Дата/час закінчення",
  SorNumber: "Номер SOR",
  PeriodConsidered: "Розглянутий період",
  ModeOfTransport: "Режим транспортування",
  NumberOfPassengers: "Кількість пасажирів",
  LengthInLaneMetres19999: "Довжина у полосних метрах (до 19999)",
  NumberAndTypeOfContainers: "Кількість і тип контейнерів",
  ContextQuantity19999: "Кількість (до 19999)",
  TypeOfContainerMovementsAndTransportationMsgs: "Тип рухів контейнерів та повідомлень про транспортування",
  CargoWeightInMetricTons: "Вага вантажу в метричних тоннах",
  PointOfEmbarkationSegment: "Сегмент місця посадки",
  PointOfEmbarkationDetails: "Деталі місця посадки",
  EmbarkationDisembarkationLocationQualifier: "Кваліфікатор місця посадки/висадки",
  NameOfNearestTown: "Назва найближчого міста",
  GeographicAreaDescription: "Опис географічної області",
  PickUpZoneLocation: "Місце збору",
};

const inputHelpers = {
  'Provides the symptoms and vital signs, to include details on airway, breathing rate, pulse rate, concious/unconcious and any other relevant information such as blood pressure or temperature.': 'Надає симптоми та показники життєвої активності, включаючи деталі щодо дихальних шляхів, частоти дихання, частоти пульсу, стану свідомості, а також будь-яку іншу важливу інформацію, таку як артеріальний тиск чи температуру.',
  'Provides details of the mechanism of the injury.': 'Надає деталі щодо механізму травми.',
  'Enter any further details about the PZ markings, especially if "OTHER" is entered in Field 1 or 2.': 'Вкажіть будь-які додаткові деталі про маркування зони підйому (PZ), особливо якщо в полі 1 або 2 вказано "ІНШЕ" (OTHER).',
  'Enter the exercise additional identifier, for example: "DISTAFF".': 'Введіть додатковий ідентифікатор тренування, наприклад: "DISTAFF".',
  'Enter "NICK:" followed by the exercise additional nickname, for example: "NICK:COBRA GOLD".': 'Введіть "NICK:" і додайте додатковий прізвисько тренування, наприклад: "NICK:COBRA GOLD".',
  'Enter "GRID:" followed by the location using a national coordinate system, for example: "GRID:SU654345".': 'Введіть "GRID:" та вкажіть місцезнаходження за допомогою національної системи координат, наприклад: "GRID:SU654345".',
  'Enter the month standard 3-letter abbreviation, for example: "DEC".': 'Введіть стандартну 3-літерну абревіатуру місяця, наприклад: "DEC".',
  'Enter the time using the ISO format, for example: "20060810T032518Z".': 'Введіть час в форматі ISO, наприклад: "20060810T032518Z".',
  'Enter the security classification of the message, unabbreviated, for example: "UNCLASSIFIED".': 'Введіть рівень секретності повідомлення без скорочень, наприклад: "UNCLASSIFIED".',
  'Enter "OTH:" followed by the security classification that applies to the information contained in the message, as directed by the appropriate security policy, for example: "OTH:PROTECT".': 'Введіть "OTH:" та рівень секретності, який застосовується до інформації, що міститься у повідомленні, згідно відповідної політики забезпечення безпеки, наприклад: "OTH:PROTECT".',
  'Enter the message text format (MTF) being referenced, for example: "BARNSTORM".': 'Введіть посилання на формат тексту повідомлення (MTF), наприклад: "BARNSTORM".',
  'Enter "TYPE:" followed by the communication type if other than an MTF, for example: "TYPE:LTR".': 'Введіть "TYPE:" та вкажіть тип зв\'язку, якщо він відрізняється від MTF, наприклад: "TYPE:LTR".',
  'Specifies the date and/or time used to identify the reference.': 'Вказує дату і/або час, що використовується для ідентифікації посилання.',
  'Enter the Subject Indicator Code (SIC) of the referenced message, for example: "LGQ".': 'Введіть код індикатора теми (SIC) посилання на повідомлення, наприклад: "LGQ".',
  'Enter "FN:" followed by the file number listed on the reference document, for example: "FN:4503B".': 'Введіть "FN:" та вкажіть номер файлу, вказаний у посиланому документі, наприклад: "FN:4503B".',
  'Provides the name of the air raid warning area.': 'Вказує назву зони попередження про повітряну рейду.',
  'Provides the air raid warning state.': 'Надає стан попередження про повітряний наліт.',
  'Provides the previous air raid warning state.': 'Надає попередній стан попередження про повітряний наліт.',
  'ENTER THE GEOGRAPHICAL ENTITY, FOR EXAMPLE: "NOR".': 'ВВЕДІТЬ ГЕОГРАФІЧНУ ОБ\'ЄКТ, НАПРИКЛАД: "NOR".',
  'ENTER "OTH:" FOLLOWED BY A NON-STANDARD 3-LETTER COUNTRY CODE, AS ASSIGNED BY THE RESPONSIBLE AUTHORITY IN ACCORDANCE WITH CURRENT NATO POLICY FOR THE ASSIGNMENT OF COUNTRY CODES, FOR EXAMPLE: "OTH:XXA".': 'ВВЕДІТЬ "OTH:" І ДОДАЙТЕ НЕСТАНДАРТНИЙ 3-ЛІТЕРНИЙ КОД КРАЇНИ, ЯКИЙ НАДАЄТЬСЯ ВІДПОВІДНИМ УПРАВЛІННЯМ ЗГІДНО З ПОЛІТИКОЮ НАТО З ПРИСВОЄННЯ КОДІВ КРАЇН, НАПРИКЛАД: "OTH:XXA".',
  'ENTER THE CODE FOR THE ARMED SERVICE OF THE REQUIRED FORCE, FOR EXAMPLE: "F".': 'ВВЕДІТЬ КОД ЗБРОЙНИХ СИЛ НЕОБХІДНОЇ СИЛИ, НАПРИКЛАД: "F".',
  'ENTER THE CIVILIAN OR NON-GOVERNMENT AGENCY CODE, FOR EXAMPLE: "ICRC".': 'ВВЕДІТЬ КОД ЦИВІЛЬНОЇ АБО НЕУРЯДОВОЇ ОРГАНІЗАЦІЇ, НАПРИКЛАД: "ICRC".',
  'Enter the altitude in feet, for example: "8000".': 'Введіть висоту у футах, наприклад: "8000".',
  'Enter the general indicator of altitude, for example: "H".': 'Введіть загальний показник висоти, наприклад: "H".',
  'Enter the aircraft speed in kilometres per hour, for example: "550".': 'Введіть швидкість літака в кілометрах на годину, наприклад: "550".',
  'Enter the general indicator of aircraft speed, for example: "FAST".': 'Введіть загальний показник швидкості літака, наприклад: "FAST".',
  'INDICATE THE GENERAL AIRCRAFT SYSTEM USED TO DELIVER THE BOMBARDMENT, FOR EXAMPLE: "HEL".': 'ВКАЖІТЬ ЗАГАЛЬНУ СИСТЕМУ ЛІТАКА, ЯКА ВИКОРИСТОВУЄТЬСЯ ДЛЯ ЗДІЙСНЕННЯ БОМБАРДУВАННЯ, НАПРИКЛАД: "HEL".',
  'INDICATE THE GENERAL WEAPON SYSTEM USED TO DELIVER THE BOMBARDMENT, FOR EXAMPLE: "ARTY".': 'ВКАЖІТЬ ЗАГАЛЬНУ СИСТЕМУ ЗБРОЇ, ЯКА ВИКОРИСТОВУЄТЬСЯ ДЛЯ ЗДІЙСНЕННЯ БОМБАРДУВАННЯ, НАПРИКЛАД: "ARTY".',
  'ENTER THE AZIMUTH IN MILS (0000-6399) TO THE MUZZLE FLASH OR ACOUSTIC SIGNATURE, FOR EXAMPLE: "1345".': 'ВВЕДІТЬ АЗИМУТ У МІЛЯХ (0000-6399) ДО БЛИСКУ З ФЛАШКИ ЧИ АКУСТИЧНОГО СИГНАЛУ, НАПРИКЛАД: "1345".',
  'ENTER THE FIELD DESCRIPTOR "DSG:" FOLLOWED BY THE AZIMUTH IN MILS (0000-6399) DERIVED FROM THE ANALYSIS OF THE SHELL CRATER OR GROOVE, FOR EXAMPLE: "DSG:3675".': 'ВВЕДІТЬ ОПИС ПОЛЯ "DSG:" І ПОСЛІДОВНО ВКАЖІТЬ АЗИМУТ У МІЛЯХ (0000-6399), ОТРИМАНИЙ З АНАЛІЗУ КРАТЕРА АБО КАНАВИЧКА ГРАНАТИ, НАПРИКЛАД: "DSG:3675".',
  'Enter the target length in metres, for example: "225".': 'Введіть довжину цілі в метрах, наприклад: "225".',
  'Enter "R:" followed by the radius in metres, for example: "R:150".': 'Введіть "R:" та вкажіть радіус у метрах, наприклад: "R:150".',
  'Can only be used in conjunction with the alternative "length in metres, 1-9999".': 'Може використовуватись тільки в поєднанні з альтернативою "довжина в метрах, від 1 до 9999".',
  'Enter the artillery target type and subtype, for example: "PERS-INF".': 'Введіть тип і підтип артилерійської цілі, наприклад: "PERS-INF".',
  'Enter the combat engineer target type and subtype, for example: "BRIDGE-NABT".': 'Введіть тип і підтип цілі бойового інженера, наприклад: "BRIDGE-NABT".',
  'Enter the geographical entity, for example: "NOR".': 'Введіть географічний об\'єкт, наприклад: "NOR".',
  'Enter "OTH:" followed by a non-standard 3-letter country code, as assigned by the responsible authority in accordance with current NATO policy for the assignment of country codes, for example: "OTH:XXA".': 'Введіть "OTH:" та додайте нестандартний 3-літерний код країни, який надається відповідальним органом відповідно до поточної політики НАТО щодо призначення кодів країн, наприклад: "OTH:XXA".',
  'Enter the armed service code, for example: "A".': 'Введіть код збройних сил, наприклад: "A".',
  'Enter the civilian or non-government agency code, for example: "ICRC".': 'Введіть код цивільної або неправління організації, наприклад: "ICRC".',
  'ENTER "GRID:" FOLLOWED BY THE LOCATION USING A NATIONAL COORDINATE SYSTEM, FOR EXAMPLE: "GRID:SU654345".': 'ВВЕДІТЬ "GRID:" ТА ВКАЖІТЬ МІСЦЕЗНАХОДЖЕННЯ ЗА ДОПОМОГОЮ НАЦІОНАЛЬНОЇ СИСТЕМИ КООРДИНАТ, НАПРИКЛАД: "GRID:SU654345".',
  'ENTER THE TYPE OF CBRN REPORT, FOR EXAMPLE: "RAD".': 'ВКАЖІТЬ ТИП ЗВІТУ ПРО ХМАРНО-БАКТЕРІОЛОГІЧНУ ЧИ ХМАРНО-ХІМІЧНУ СИТУАЦІЮ, НАПРИКЛАД: "RAD".',
  'ENTER "WEA:" FOLLOWED BY THE TYPE OF CBRN WEATHER REPORT, FOR EXAMPLE: "WEA:BWM".': 'ВКАЖІТЬ "WEA:" ТА ВКАЖІТЬ ТИП ЗВІТУ ПРО МЕТЕОРОЛОГІЧНУ СИТУАЦІЮ ПРИ РОЗПОВСЮДЖЕННІ ХМАРИ ЗАГАЛЬНОГО ЗРОСТАННЯ, НАПРИКЛАД: "WEA:BWM".',
  'ENTER "ACC:" FOLLOWED BY THE CODE SPECIFYING THE LOCATION OF AREA CONTROL CENTRE, FOR EXAMPLE: "ACC:D45".': 'ВКАЖІТЬ "ACC:" ТА ВКАЖІТЬ КОД, ЩО ВКАЗУЄ НА МІСЦЕ РОЗМІЩЕННЯ ЦЕНТРУ КОНТРОЛЮ НАД ЗОНОЮ, НАПРИКЛАД: "ACC:D45".',
  'ENTER THE TWO LETTER COUNTRY CODE, FOR EXAMPLE: "FR".': 'ВВЕДІТЬ ДВОЛІТЕРНИЙ КОД КРАЇНИ, НАПРИКЛАД: "FR".',
  'ENTER THE THREE LETTER COUNTRY CODE, FOR EXAMPLE: "FRA".': 'ВВЕДІТЬ ТРИЛІТЕРНИЙ КОД КРАЇНИ, НАПРИКЛАД: "FRA".',
  'ENTER THE UNIT CODE WHICH IDENTIFIES THE UNIT ISSUING THE MESSAGE, FOR EXAMPLE: "1HF4".': 'ВКАЖІТЬ КОД ПІДРОЗДІЛУ, ЯКИЙ ВИЗНАЧАЄ ВИПУСКНИЙ ПІДРОЗДІЛ, НАПРИКЛАД: "1HF4".',
  'ENTER "NAME:" FOLLOWED BY THE GEOGRAPHIC PLACE NAME, FOR EXAMPLE: "NAME:OAK HILL".': 'ВВЕДІТЬ "NAME:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ ГЕОГРАФІЧНОГО МІСЦЯ, НАПРИКЛАД: "NAME:OAK HILL".',
  'ENTER "NAME:" FOLLOWED BY THE NAME ASSIGNED TO THE LOCATION, FOR EXAMPLE: "NAME:GARGANTA DEL DIABLO".': 'ВВЕДІТЬ "NAME:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ, ПРИСВОЄНУ МІСЦЮ, НАПРИКЛАД: "NAME:GARGANTA DEL DIABLO".',
  'ENTER THE ACTUAL OR ESTIMATED LOCATION QUALIFIER, FOR EXAMPLE: "EE".': 'ВКАЖІТЬ ФАКТИЧНИЙ АБО ПРИБЛИЗНИЙ КВАЛІФІКАТОР МІСЦЕЗНАХОДЖЕННЯ, НАПРИКЛАД: "EE".',
  'ENTER THE CODE SPECIFYING THAT THE LOCATION QUALIFIER IS NOT KNOWN, FOR EXAMPLE: "NKN".': 'ВКАЖІТЬ КОД, ЩО ВКАЗУЄ, ЩО КВАЛІФІКАТОР МІСЦЕЗНАХОДЖЕННЯ НЕ ВІДОМИЙ, НАПРИКЛАД: "NKN".',
  'The state of awareness of a CBRN incident.': 'Стан обізнаності щодо події зі СХОРБН.',
  'Enter the number of substance containers, for example: "16".': 'Введіть кількість контейнерів з речовинами, наприклад: "16".',
  'Enter the size of the release, for example: "MEDBIO".': 'Введіть розмір викиду, наприклад: "MEDBIO".',
  'ENTER THE TYPE OF BURST OR TYPE OF SUBSTANCE RELEASE HEIGHT, FOR EXAMPLE: "AIR".': 'ВВЕДІТЬ ТИП ВИБУХУ АБО ВИСОТУ ВИЛУЧЕННЯ РЕЧОВИНИ, НАПРИКЛАД: "AIR".',
  'ENTER "TS:" FOLLOWED BY THE TYPE OF THE CBRN SUBSTANCE, FOR EXAMPLE: "TS:NERV".': 'ВКАЖІТЬ "TS:" І ПОСЛІДОВНО ВКАЖІТЬ ТИП РЕЧОВИНИ ЗІ СХОРБН, НАПРИКЛАД: "TS:NERV".',
  'ENTER "SN:" FOLLOWED BY THE SUBSTANCE NAME, FOR EXAMPLE: "SN:RICB".': 'ВВЕДІТЬ "SN:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ РЕЧОВИНИ, НАПРИКЛАД: "SN:RICB".',
  'ENTER THE FOUR DIGITS GIVING THE SUBSTANCE IDENTIFICATION AS DEFINED BY STANAG 2909, FOR EXAMPLE: "4639".': 'ВВЕДІТЬ ЧОТИРИЗНАЧНИЙ КОД, ЩО ВКАЗУЄ ІДЕНТИФІКАЦІЮ РЕЧОВИНИ ЗГІДНО ЗІ СТАНАГ 2909, НАПРИКЛАД: "4639".',
  'ENTER ONE DIGIT DEFINING THE DETAILED AIR STABILITY CATEGORY, FOR EXAMPLE: "7".': 'ВКАЖІТЬ ОДНУ ЦИФРУ, ЩО ВКАЗУЄ ДЕТАЛІЗОВАНУ КАТЕГОРІЮ СТІЙКОСТІ ПОВІТРЯ, НАПРИКЛАД: "7".',
  'ENTER ONE LETTER DEFINING THE SIMPLIFIED AIR STABILITY CATEGORY, FOR EXAMPLE: "N".': 'ВВЕДІТЬ ОДНУ ЛІТЕРУ, ЩО ВКАЗУЄ СИМПЛІФІКОВАНУ КАТЕГОРІЮ СТІЙКОСТІ ПОВІТРЯ, НАПРИКЛАД: "N".',
  'ENTER THE TYPE OF RADIOACTIVITY RELEASED IN ONE RADIOLOGICAL INCIDENT, FOR EXAMPLE: "BET".': 'ВКАЖІТЬ ТИП ВИДІЛЕНОГО В УМОВАХ РАДІАЦІЙНОЇ ПОДІЇ, НАПРИКЛАД: "BET".',
  'ENTER THE UN/NA IDENTIFICATION NUMBER OF THE RADIOLOGICAL MATERIAL, FOR EXAMPLE: "3456".': 'ВКАЖІТЬ ІДЕНТИФІКАЦІЙНИЙ НОМЕР УМОВНОЇ ОБОЗНАЧЕНОЇ РАДІОЛОГІЧНОЇ РЕЧОВИНИ (UN/NA), НАПРИКЛАД: "3456".',
  'ENTER THE IUPAC (INTERNATIONAL UNION OF PURE AND APPLIED CHEMISTRY) ISOTOPE NAME AND ATOMIC WEIGHT RELATED TO THE RADIOLOGICAL SOURCE, FOR EXAMPLE: "CS137".': 'ВВЕДІТЬ НАЗВУ ІЗОТОПА ТА АТОМНУ МАСУ ЗА МІЖНАРОДНИМ СТАНДАРТОМ ЧИСЛЕННЯ РЕЧОВИН, ЩО ВКАЗУЄ НА РАДІОЛОГІЧНИЙ ДЖЕРЕЛО, НАПРИКЛАД: "CS137".',
  'ENTER THE UNIT OF MEASUREMENT, DIRECTION IN DEGREES, FOR EXAMPLE: "DGM".': 'ВВЕДІТЬ ОДИНИЦЮ ВИМІРЮВАННЯ, НАПРИКЛАД: "DGM".',
  'ENTER THE UNIT OF MEASUREMENT, DIRECTION IN MILS, FOR EXAMPLE: "MLM".': 'ВВЕДІТЬ ОДИНИЦЮ ВИМІРЮВАННЯ, НАПРИКЛАД: "MLM".',
  'ENTER "DEG:" FOLLOWED BY THE WIND DIRECTION IN DEGREES, FOR EXAMPLE: "DEG:240".': 'ВВЕДІТЬ "DEG:" І ПОСЛІДОВНО ВКАЖІТЬ НАПРЯМ ВІТРУ В ГРАДУСАХ, НАПРИКЛАД: "DEG:240".',
  'ENTER "MIL:" FOLLOWED BY THE WIND DIRECTION IN MILS, FOR EXAMPLE: "MIL:3200".': 'ВВЕДІТЬ "MIL:" І ПОСЛІДОВНО ВКАЖІТЬ НАПРЯМ ВІТРУ В МІЛЯХ, НАПРИКЛАД: "MIL:3200".',
  'ENTER THE INDICATOR SPECIFYING THAT THE DOWNWIND DIRECTION IS VARIABLE, FOR EXAMPLE: "VAB".': 'ВКАЖІТЬ ІНДИКАТОР, ЩО ВКАЗУЄ, ЩО НАПРЯМ ВІТРУ МОЖЕ ЗМІНЮВАТИСЬ, НАПРИКЛАД: "VAB".',
  'ENTER "DEG:" FOLLOWED BY THE DOWNWIND DIRECTION IN DEGREES, FOR EXAMPLE: "DEG:180".': 'ВВЕДІТЬ "DEG:" І ПОСЛІДОВНО ВКАЖІТЬ НАПРЯМ ВІТРУ В НАПРЯМКУ ЗВІТУ В ГРАДУСАХ, НАПРИКЛАД: "DEG:180".',
  'ENTER "MIL:" FOLLOWED BY THE DOWNWIND WIND DIRECTION IN MILS. FOR EXAMPLE: "MIL:3200".': 'ВВЕДІТЬ "MIL:" І ПОСЛІДОВНО ВКАЖІТЬ НАПРЯМ ВІТРУ В НАПРЯМКУ ЗВІТУ В МІЛЯХ, НАПРИКЛАД: "MIL:3200".',
  'ENTER THE DETAILED AIR STABILITY CATEGORY, FOR EXAMPLE: "1".': 'ВВЕДІТЬ ДЕТАЛЬНУ КАТЕГОРІЮ СТІЙКОСТІ ПОВІТРЯ, НАПРИКЛАД: "1".',
  'ENTER THE SIMPLIFIED AIR STABILITY CATEGORY, FOR EXAMPLE: "S".': 'ВВЕДІТЬ СИМПЛІФІКОВАНУ КАТЕГОРІЮ СТІЙКОСТІ ПОВІТРЯ, НАПРИКЛАД: "S".',
  'ENTER THE DETAILED AIR STABILITY CATEGORY, FOR EXAMPLE: "3".': 'ВВЕДІТЬ ДЕТАЛЬНУ КАТЕГОРІЮ СТІЙКОСТІ ПОВІТРЯ, НАПРИКЛАД: "3".',
  'ENTER THE SIMPLIFIED AIR STABILITY CATEGORY, FOR EXAMPLE: "N".': 'ВВЕДІТЬ СИМПЛІФІКОВАНУ КАТЕГОРІЮ СТІЙКОСТІ ПОВІТРЯ, НАПРИКЛАД: "N".',
  'THE RADIUS OF ZONE 1.': 'РАДІУС ЗОНИ 1.',
  'THE DOWNWIND WIND SPEED.': 'ШВИДКІСТЬ ВІТРУ В НАПРЯМКУ ЗВІТУ.',
  'THE INDICATOR OF ANGLE EXPANSION BETWEEN RADIAL LINES.': 'ІНДИКАТОР КУТОВОГО РОЗШИРЕННЯ МІЖ РАДІАЛЬНИМИ ЛІНІЯМИ.',
  'THE INDICATOR OF ANGLE EXPANSION BETWEEN RADIAL LINES': 'ІНДИКАТОР КУТОВОГО РОЗШИРЕННЯ МІЖ РАДІАЛЬНИМИ ЛІНІЯМИ',
  'Enter an affirmative or negative indicator specifying the presence, or not, of miosis, for example: "YES".': 'ВВЕДІТЬ ПОДАТИ АБО ВІД\'ЄМНИЙ ІНДИКАТОР, ЩО ВКАЗУЄ НА НАЯВНІСТЬ АБО ВІДСУТНІСТЬ МІОЗУ, НАПРИКЛАД: "YES".',
  'Enter the communications event, for example: "ALTERNATE".': 'ВВЕДІТЬ ПОДІЮ ЗВ\'ЯЗКУ, НАПРИКЛАД: "ALTERNATE".',
  'Enter "EMCON:" followed by the emission control plan identifier, for example: "EMCON:EW224SD".': 'ВВЕДІТЬ "EMCON:" І ПОСЛІДОВНО ВКАЖІТЬ ІДЕНТИФІКАТОР ПЛАНУ КОНТРОЛЮ ВИПРОМІНЮВАННЯ, НАПРИКЛАД: "EMCON:EW224SD".',
  'Enter the termination time qualifier, for example: "ASAP".': 'ВВЕДІТЬ ЯКІСТЬ ЧАСУ ЗАВЕРШЕННЯ, НАПРИКЛАД: "ASAP".',
  'Enter the date-time group when the communications event stops, for example: "091030ZJAN2005".': 'ВВЕДІТЬ ГРУПУ ДАТИ І ЧАСУ, КОЛИ ЗАВЕРШУЄТЬСЯ ПОДІЯ ЗВ\'ЯЗКУ, НАПРИКЛАД: "091030ZJAN2005".',
  'Enter "CCN:" followed by the communications circuit name, for example: "CCN:ALPHA FOXTROT ZULU".': 'ВВЕДІТЬ "CCN:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ КОМУНІКАЦІЙНОГО КОЛА, НАПРИКЛАД: "CCN:ALPHA FOXTROT ZULU".',
  'Enter the communications circuit designator, for example: "22DAF231".': 'ВВЕДІТЬ ІДЕНТИФІКАТОР КОМУНІКАЦІЙНОГО КОЛА, НАПРИКЛАД: "22DAF231".',
  'Enter "SDN:" followed by the system designator name, for example: "SDN:132DFA-1223DDD".': 'ВВЕДІТЬ "SDN:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ ІДЕНТИФІКАТОРА СИСТЕМИ, НАПРИКЛАД: "SDN:132DFA-1223DDD".',
  'Enter "UNITID:" followed by the unit identifier, for example: "UNITID:SIERRA4".': 'ВВЕДІТЬ "UNITID:" І ПОСЛІДОВНО ВКАЖІТЬ ІДЕНТИФІКАТОР ПІДПОРЯДКОВАНОЇ ОДИНИЦІ, НАПРИКЛАД: "UNITID:SIERRA4".',
  'ENTER THE PLACE NAME, FOR EXAMPLE: "NAPLES".': 'ВВЕДІТЬ НАЗВУ МІСЦЯ, НАПРИКЛАД: "NAPLES".',
  'ENTER "SHIP:" FOLLOWED BY THE SHIP NAME, FOR EXAMPLE: "SHIP:REITAN".': 'ВВЕДІТЬ "SHIP:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ КОРАБЛЯ, НАПРИКЛАД: "SHIP:REITAN".',
  'ENTER "NICS:" FOLLOWED BY THE NICS LOCATION CODE, FOR EXAMPLE: "NICS:HIGH".': 'ВВЕДІТЬ "NICS:" І ПОСЛІДОВНО ВКАЖІТЬ КОД МІСЦЕЗНАХОДЖЕННЯ В МЕЖАХ СИСТЕМИ NICS, НАПРИКЛАД: "NICS:HIGH".',
  'ENTER "NAME:" FOLLOWED BY THE PLACE NAME, FOR EXAMPLE: "NAME:LAMMERSDORF".': 'ВВЕДІТЬ "NAME:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ МІСЦЯ, НАПРИКЛАД: "NAME:LAMMERSDORF".',
  'SHIP NAME, FOR EXAMPLE: "ANDREA DORIA".': 'НАЗВА КОРАБЛЯ, НАПРИКЛАД: "ANDREA DORIA".',
  'ENTER "NICS:" FOLLOWED BY THE NICS RELOCATION CODE, FOR EXAMPLE: "NICS:HMAZ".': 'ВВЕДІТЬ "NICS:" І ПОСЛІДОВНО ВКАЖІТЬ КОД ПЕРЕМІЩЕННЯ В МЕЖАХ СИСТЕМИ NICS, НАПРИКЛАД: "NICS:HMAZ".',
  'ENTER THE ALTERNATE RELOCATION PLACE NAME, FOR EXAMPLE: "KOLSAAS".': 'ВВЕДІТЬ НАЗВУ АЛЬТЕРНАТИВНОГО МІСЦЯ ПЕРЕМІЩЕННЯ, НАПРИКЛАД: "KOLSAAS".',
  'ENTER "SHIP:" FOLLOWED BY THE NAME, FOR EXAMPLE: "SHIP:CORMORANO".': 'ВВЕДІТЬ "SHIP:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ КОРАБЛЯ, НАПРИКЛАД: "SHIP:CORMORANO".',
  'Enter "APN:" followed by the name of the arrival location, for example: "APN:DORTMUND".': 'ВВЕДІТЬ "APN:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ МІСЦЯ ПРИБУТТЯ, НАПРИКЛАД: "APN:DORTMUND".',
  'Enter "TEL:" followed by the telephone number of the Point Of Contact. If required, country code should be placed in parentheses, for example: "TEL:(1) 810 234 1234".': 'ВВЕДІТЬ "TEL:" І ПОСЛІДОВНО ВКАЖІТЬ ТЕЛЕФОННИЙ НОМЕР КОНТАКТНОЇ ОСОБИ. ЗАПИСАТИ КОД КРАЇНИ В ДУЖКАХ, НАПРИКЛАД: "TEL:(1) 810 234 1234".',
  'Enter "SEC:" followed by the secure telephone number of the Point Of Contact. If required, country code should be placed in parentheses, for example: "SEC:(1) 810 234 1234".': 'ВВЕДІТЬ "SEC:" І ПОСЛІДОВНО ВКАЖІТЬ НОМЕР БЕЗПЕКИ ТЕЛЕФОНА КОНТАКТНОЇ ОСОБИ. ЗАПИСАТИ КОД КРАЇНИ В ДУЖКАХ, НАПРИКЛАД: "SEC:(1) 810 234 1234".',
  'Enter "EMAIL:" followed by the email address of the point of contact, for example: "EMAIL:MTF@NHQC3.NATO.INT". If required, the @ symbol should be shown as "(a)".': 'ВВЕДІТЬ "EMAIL:" І ПОСЛІДОВНО ВКАЖІТЬ АДРЕСУ ЕЛЕКТРОННОЇ ПОШТИ КОНТАКТНОЇ ОСОБИ, НАПРИКЛАД: "EMAIL:MTF@NHQC3.NATO.INT". ЯКЩО ПОТРІБНО, СИМВОЛ @ ПОВИНЕН БУТИ ПОКАЗАНИЙ ЯК "(a)".',
  'Enter "FAX:" followed by the fax number of the Point Of Contact. If required, country code should be placed in parentheses, for example: "FAX:(44) 123 3456 234".': 'ВВЕДІТЬ "FAX:" І ПОСЛІДОВНО ВКАЖІТЬ ФАКСНИЙ НОМЕР КОНТАКТНОЇ ОСОБИ. ЗАПИСАТИ КОД КРАЇНИ В ДУЖКАХ, НАПРИКЛАД: "FAX:(44) 123 3456 234".',
  'ENTER "GRID:" FOLLOWED BY THE LOCATION OF THE HOMEBASE OR STARTING POINT OF THE UNIT TO BE DEPLOYED, USING A NATIONAL COORDINATE SYSTEM, FOR EXAMPLE: "GRID:SU654345".': 'ВВЕДІТЬ "GRID:" І ПОСЛІДОВНО ВКАЖІТЬ МІСЦЕ ЗНАХОДЖЕННЯ БАЗОВОЇ ЧИ ПОЧАТКОВОЇ ТОЧКИ ОДИНИЦІ ДЛЯ РОЗМІЩЕННЯ ЗА ВИКОРИСТАННЯМ НАЦІОНАЛЬНОЇ СИСТЕМИ КООРДИНАТ, НАПРИКЛАД: "GRID:SU654345".',
  'TO BE USED ONLY IF FIELDS 1 AND 2 DO NOT CONTAIN A VALID TIME.': 'ВИКОРИСТОВУЙТЕ ТІЛЬКИ ТОДІ, КОЛИ ПОЛЯ 1 І 2 НЕ МІСТЯТЬ ДІЮЧОГО ЧАСУ.',
  'ENTER "TOWN:" FOLLOWED BY THE NAME OF THE NEAREST TOWN TO IDENTIFY THE AREA IN WHICH THE POD IS TO BE FOUND, FOR EXAMPLE: "TOWN:KANSAS CITY".': 'ВВЕДІТЬ "TOWN:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ НАЙБЛИЖЧОГО МІСТА, ЩО ВИЗНАЧАТИМЕ ОБЛАСТЬ, ДЕ ЗНАХОДИТЬСЯ ПОД, НАПРИКЛАД: "TOWN:KANSAS CITY".',
  'ENTER "AREA:" FOLLOWED BY A DESCRIPTION OF THE GEOGRAPHIC FEATURES, FOR EXAMPLE: "AREA:SANDY PLAIN EAST OF LINWOOD FOREST".': 'ВВЕДІТЬ "AREA:" І ПОСЛІДОВНО ВКАЖІТЬ ОПИС ГЕОГРАФІЧНИХ ОСОБЛИВОСТЕЙ, НАПРИКЛАД: "AREA:SANDY PLAIN EAST OF LINWOOD FOREST".',
  'Provides the name identifying the point of contact.': 'Надає назву, яка ідентифікує контактну особу.',
  'Provides the rank or position of the point of contact.': 'Надає звання або посаду контактної особи.',
  'Enter "UNIT:" followed by the identification of a military, para-military or government agency unit, as used in official communications within the military establishments, for example: "UNIT:4077 MASH".': 'ВВЕДІТЬ "UNIT:" І ПОСЛІДОВНО ВКАЖІТЬ ІДЕНТИФІКАЦІЮ ВІЙСЬКОВОЇ, ПАРАВІЙСЬКОЇ АБО УРЯДОВОЇ ПІДРОЗДІЛКИ, ЯК ВИКОРИСТОВУЄТЬСЯ В ОФІЦІЙНІЙ КОМУНІКАЦІЇ В СКЛАДІ ВІЙСЬКОВИХ УСТАНОВ, НАПРИКЛАД: "UNIT:4077 MASH".',
  'Enter "CALLSIGN:" followed by the call sign of the unit or agency, for example: "CALLSIGN:HAWKEYE".': 'ВВЕДІТЬ "CALLSIGN:" І ПОСЛІДОВНО ВКАЖІТЬ ВИЗНАЧЕННЯ ВИКЛИКУ ПІДРОЗДІЛУ АБО АГЕНЦІЇ, НАПРИКЛАД: "CALLSIGN:HAWKEYE".',
  'Enter "LOC:" followed by the name assigned to the location, for example: "LOC:GARGANTA DEL DIABLO".': 'ВВЕДІТЬ "LOC:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ, ЯКА ПРИСВОЄНА МІСЦЕЗНАХОДЖЕННЮ, НАПРИКЛАД: "LOC:GARGANTA DEL DIABLO".',
  'Enter "ICAO:" followed by the ICAO Location Indicator, for example: “ICAO:ENOL”.': 'ВВЕДІТЬ "ICAO:" І ПОСЛІДОВНО ВКАЖІТЬ ПОКАЗНИК МІСЦЕЗНАХОДЖЕННЯ ICAO, НАПРИКЛАД: "ICAO:ENOL".',
  'Enter "TEL:" followed by the designated nonsecure telephone number, for example: "TEL:804-555-4142".': 'ВВЕДІТЬ "TEL:" І ПОСЛІДОВНО ВКАЖІТЬ ВИЗНАЧЕНИЙ НЕЗАХИЩЕНИЙ ТЕЛЕФОННИЙ НОМЕР, НАПРИКЛАД: "TEL:804-555-4142".',
  'Enter "SECTEL:" followed by the designated secure telephone number, for example: "SECTEL:555-4311".': 'ВВЕДІТЬ "SECTEL:" І ПОСЛІДОВНО ВКАЖІТЬ ВИЗНАЧЕНИЙ БЕЗПЕЧНИЙ ТЕЛЕФОННИЙ НОМЕР, НАПРИКЛАД: "SECTEL:555-4311".',
  'Enter "SECFAX:" followed by the designated secure fax number, for example: "SECFAX:0032-2-707-4444".': 'ВВЕДІТЬ "SECFAX:" І ПОСЛІДОВНО ВКАЖІТЬ ВИЗНАЧЕНИЙ БЕЗПЕЧНИЙ ФАКСНИЙ НОМЕР, НАПРИКЛАД: "SECFAX:0032-2-707-4444".',
  'Enter "FAX:" followed by the designated nonsecure fax number, for example: "FAX:0032-2-707-5834".': 'ВВЕДІТЬ "FAX:" І ПОСЛІДОВНО ВКАЖІТЬ ВИЗНАЧЕНИЙ НЕЗАХИЩЕНИЙ ФАКСНИЙ НОМЕР, НАПРИКЛАД: "FAX:0032-2-707-5834".',
  'Enter "FRQDES:" followed by the frequency designator, for example: "FRQDES:RED1".': 'ВВЕДІТЬ "FRQDES:" І ПОСЛІДОВНО ВКАЖІТЬ ПОКАЗНИК ЧАСТОТИ, НАПРИКЛАД: "FRQDES:RED1".',
  'Enter "EMAIL:" followed by the identifier used by senders and recipients of electronic mail(e-mail) over computer networks, for example: "EMAIL:EWINGP(AT)NYKNICKS.COM". If required, the @ symbol should be shown as "(a)".': 'ВВЕДІТЬ "EMAIL:" І ПОСЛІДОВНО ВКАЖІТЬ ІДЕНТИФІКАТОР, ЯКИЙ ВИКОРИСТОВУЄТЬСЯ ВІДПРАВНИКАМИ ТА ОТРИМУВАЧАМИ ЕЛЕКТРОННОЇ ПОШТИ (ЕЛЕКТРОННОЇ ПОШТИ) В КОМП\'ЮТЕРНИХ МЕРЕЖАХ, НАПРИКЛАД: "EMAIL:EWINGP(AT)NYKNICKS.COM". За потреби символ @ повинен бути показаний як "(a)".',
  'Enter "SEMAIL:" followed by the identifier used by senders and recipients of electronic mail (e-mail) over computer networks, for example: "SEMAIL:SEWINGP(AT)SNEAKYNICKS.COM".': 'ВВЕДІТЬ "SEMAIL:" І ПОСЛІДОВНО ВКАЖІТЬ ІДЕНТИФІКАТОР, ЯКИЙ ВИКОРИСТОВУЄТЬСЯ ВІДПРАВНИКАМИ ТА ОТРИМУВАЧАМИ ЕЛЕКТРОННОЇ ПОШТИ (ЕЛЕКТРОННОЇ ПОШТИ) В КОМП\'ЮТЕРНИХ МЕРЕЖАХ, НАПРИКЛАД: "SEMAIL:SEWINGP(AT)SNEAKYNICKS.COM".',
  'Enter "CHAT:" followed by the name of the CHAT ROOM where the POC can be contacted, for example: "CHAT:AMDLNO".': 'ВВЕДІТЬ "CHAT:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ КІМНАТИ ЧАТУ, ДЕ МОЖНА ЗВ\'ЯЗАТИСЯ З POC, НАПРИКЛАД: "CHAT:AMDLNO".',
  'ENTER "AREA:" FOLLOWED BY A LITERAL DESCRIPTION OF THE GEOGRAPHIC FEATURES, FOR EXAMPLE: "AREA:SANDY PLAIN EAST OF LINWOOD FOREST".': 'ВВЕДІТЬ "AREA:" І ПОСЛІДОВНО ВКАЖІТЬ БУКВАЛЬНИЙ ОПИС ГЕОГРАФІЧНИХ ОСОБЛИВОСТЕЙ, НАПРИКЛАД: "AREA:SANDY PLAIN EAST OF LINWOOD FOREST".',
  'Enter "LOC:" followed by the name of the peace time location, for example: "LOC:DORTMUND".': 'ВВЕДІТЬ "LOC:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ МІСЦЯ В МИРОВИЙ ЧАС, НАПРИКЛАД: "LOC:DORTMUND".',
  'Enter "NAME:" followed by the name of the departure location, for example: "NAME:FRANKFURT".': 'ВВЕДІТЬ "NAME:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ ПУНКТУ ВІДПРАВЛЕННЯ, НАПРИКЛАД: "NAME:FRANKFURT".',
  'Enter "NAME:" followed by the name of the departure location, for example: "NAME:LYON".': 'ВВЕДІТЬ "NAME:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ ПУНКТУ ВІДПРАВЛЕННЯ, НАПРИКЛАД: "NAME:LYON".',
  'ENTER "NN:" FOLLOWED BY THE NICKNAME OF THE OBSTACLE BEING REFERED TO, FOR EXAMPLE: "NN:TURBINE".': 'ВВЕДІТЬ "NN:" І ПОСЛІДОВНО ВКАЖІТЬ ПРІЗВИЩЕ ПЕРЕШКОДИ, НА НЕЯКИЙ ВИКЛИКАЄТЬСЯ ПОСИЛАННЯ, НАПРИКЛАД: "NN:TURBINE".',
  'ENTER "NO:" FOLLOWED BY THE NUMBER OF THE OBSTACLE ZONE/BELT (OR THE NATIONAL EQUIVALENT), FOR EXAMPLE: "NO:B034".': 'ВВЕДІТЬ "NO:" І ПОСЛІДОВНО ВКАЖІТЬ НОМЕР ЗОНИ / ПОЯСА ПЕРЕШКОДИ (АБО НАЦІОНАЛЬНИЙ ЕКВІВАЛЕНТ), НАПРИКЛАД: "NO:B034".',
  'ENTER THE NAME OF THE OBSTACLE ZONE/BELT (OR THE NATIONAL EQUIVALENT), FOR EXAMPLE: "XRAY2".': 'ВВЕДІТЬ НАЗВУ ЗОНИ / ПОЯСА ПЕРЕШКОДИ (АБО НАЦІОНАЛЬНИЙ ЕКВІВАЛЕНТ), НАПРИКЛАД: "XRAY2".',
  'Enter "INST:" followed by a brief note or textual data giving instructions, for example: "INST:UPON RECEIPT OF MESSAGE".': 'ВВЕДІТЬ "INST:" І ПОСЛІДОВНО ВКАЖІТЬ КОРОТКУ ЗАМІТКУ АБО ТЕКСТОВІ ДАНІ, ЩО НАДАЮТЬ ІНСТРУКЦІЇ, НАПРИКЛАД: "INST:UPON RECEIPT OF MESSAGE".',
  'Enter the name of the force or unit required to acknowledge, for example: "MIDDLEBURG".': 'ВВЕДІТЬ НАЗВУ СИЛИ АБО ПІДРОЗДІЛУ, ЯКИЙ ПОТРІБНО ПІДТВЕРДИТИ, НАПРИКЛАД: "MIDDLEBURG".',
  'Enter a cardinal direction, for example: "NE".': 'ВВЕДІТЬ КАРДИНАЛЬНИЙ НАПРЯМ, НАПРИКЛАД: "NE".',
  'Enter "NAME:" followed by the pre-defined name or designator for a boundary definition point, for example: "NAME:AA".': 'ВВЕДІТЬ "NAME:" І ПОСЛІДОВНО ВКАЖІТЬ ПЕРЕДБАЧЕНЕ ІМ\'Я АБО ВИЗНАЧЕННЯ ДЛЯ ТОЧКИ ВИЗНАЧЕННЯ МЕЖІ, НАПРИКЛАД: "NAME:AA".',
  'ENTER "NAME:" AND AN ASSIGNED NICKNAME FOR THE LINE, FOR EXAMPLE: "NAME:PL HOTTON".': 'ВВЕДІТЬ "NAME:" ТА ПРИСВОЄНЕ ПРІЗВИЩЕ ДЛЯ ЛІНІЇ, НАПРИКЛАД: "NAME:PL HOTTON".',
  'ENTER A DESIGNATOR FOR THE LINE, FOR EXAMPLE: "AA23NAA3".': 'ВВЕДІТЬ ВИЗНАЧЕННЯ ДЛЯ ЛІНІЇ, НАПРИКЛАД: "AA23NAA3".',
  'Enter the date-time group, for example: "261752ZNOV2010".': 'ВВЕДІТЬ ГРУПУ ДАТИ-ЧАСУ, НАПРИКЛАД: "261752ZNOV2010".',
  'Enter reference time indicator, for example: "D".': 'ВВЕДІТЬ ПОКАЗНИК ПОСИЛАННЯ НА ЧАС, НАПРИКЛАД: "D".',
  'ENTER THE TYPE OF TERRORIST BOMBING, FOR EXAMPLE "TBPR".': 'ВВЕДІТЬ ТИП ТЕРОРИСТИЧНОГО ПОВІТРЯНОГО УДАРУ, НАПРИКЛАД "TBPR".',
  'ENTER THE TYPE OF TERRORIST SHOOTING, FOR EXAMPLE "TSAM".': 'ВВЕДІТЬ ТИП ТЕРОРИСТИЧНОГО СТРІЛЬБИ, НАПРИКЛАД "TSAM".',
  'ENTER THE TYPE OF TERRORIST INCIDENT, FOR EXAMPLE "TMUR".': 'ВВЕДІТЬ ТИП ТЕРОРИСТИЧНОГО ІНЦИДЕНТУ, НАПРИКЛАД "TMUR".',
  'ENTER THE TYPE OF CIVIL UNREST, FOR EXAMPLE "CR".': 'ВВЕДІТЬ ТИП ЦИВІЛЬНОЇ НЕСПОКОЮ, НАПРИКЛАД "CR".',
  'ENTER THE TYPE OF NATURAL DISASTER, FOR EXAMPLE "NDR".': 'ВВЕДІТЬ ТИП ПРИРОДНОЇ КАТАСТРОФИ, НАПРИКЛАД "NDR".',
  'ENTER THE TYPE OF MEDIA EVENT, FOR EXAMPLE "MAL".': 'ВВЕДІТЬ ТИП МЕДІАПОДІЇ, НАПРИКЛАД "MAL".',
  'ENTER THE DIRECTION OF MOVEMENT AWAY FROM THE LOCATION, FOR EXAMPLE: "SSE".': 'ВВЕДІТЬ НАПРЯМ РУХУ ВІД МІСЦЕЗНАХОДЖЕННЯ, НАПРИКЛАД: "SSE".',
  'ENTER "TO:" FOLLOWED BY THE DIRECTION OF MOVEMENT TO THE LOCATION, FOR EXAMPLE: "TO:NNW".': 'ВВЕДІТЬ "TO:" І ПОСЛІДОВНО ВКАЖІТЬ НАПРЯМ РУХУ ДО МІСЦЕЗНАХОДЖЕННЯ, НАПРИКЛАД: "TO:NNW".',
  'Enter the regional entity (state/province/district) to which the subject refers, for example: "IT05".': 'ВВЕДІТЬ РЕГІОНАЛЬНУ ОБ\'ЄДНАНУЮ (ДЕРЖАВУ/ПРОВІНЦІЮ/ОКРУГУ), НА ЯКУ ПОСИЛАЄТЬСЯ ОБ\'ЄКТ, НАПРИКЛАД: "IT05".',
  'Enter "REQUEST:" followed by the quantity, for example: "REQUEST:9500".': 'ВВЕДІТЬ "REQUEST:" І ПОСЛІДОВНО ВКАЖІТЬ КІЛЬКІСТЬ, НАПРИКЛАД: "REQUEST:9500".',
  'Enter "ISSUED:" followed by the quantity, for example: "ISSUED:1500".': 'ВВЕДІТЬ "ISSUED:" І ПОСЛІДОВНО ВКАЖІТЬ КІЛЬКІСТЬ, НАПРИКЛАД: "ISSUED:1500".',
  'Specifies the status of the receiving unit\'s intended movement.': 'ВКАЗУЄ СТАН ЗАПЛАНОВАНОГО РУХУ ОТРИМУВАЧА.',
  'Enter “ICAO:” followed by the ICAO Location Indicator, for example: “ICAO:ENOL”.': 'ВВЕДІТЬ "ICAO:" І ПОСЛІДОВНО ВКАЖІТЬ ПОКАЗНИК МІСЦЕЗНАХОДЖЕННЯ ICAO, НАПРИКЛАД: "ICAO:ENOL".',
  'Enter "NAME:" followed by the name of the location in plain text, for example: "NAME:SCHERPENZEEL".': 'ВВЕДІТЬ "NAME:" І ПОСЛІДОВНО ВКАЖІТЬ НАЗВУ МІСЦЯ У ЗВИЧАЙНОМУ ТЕКСТІ, НАПРИКЛАД: "NAME:SCHERPENZEEL".',
  'Can only be used in conjunction with "OTHER" as NATO standard fuel type or additional NATO standard fuel type.': 'МОЖЕ ВИКОРИСТОВУВАТИСЯ ТІЛЬКИ У ЗВ\'ЯЗКУ З "ІНШИМ" ЯК СТАНДАРТНИМ ВИДОМ ПАЛИВА НАТО АБО ДОДАТКОВИМ СТАНДАРТНИМ ВИДОМ ПАЛИВА НАТО.',
  'ENTER THE LINEAR UNIT OF MEASUREMENT ASSOCIATED WITH THE QUANTITY IN FIELD 5, FOR EXAMPLE "CM".': 'ВВЕДІТЬ ЛІНІЙНУ ОДИНИЦЮ ВИМІРЮВАННЯ, ПОВ\'ЯЗАНУ З КІЛЬКІСТЮ В ПОЛІ 5, НАПРИКЛАД "CM".',
  'ENTER THE VOLUME UNIT OF MEASUREMENT ASSOCIATED WITH THE QUANTITY IN FIELD 5, FOR EXAMPLE "LI".': 'ВВЕДІТЬ ОДИНИЦЮ ОБ\'ЄМУ ВИМІРЮВАННЯ, ПОВ\'ЯЗАНУ З КІЛЬКІСТЮ В ПОЛІ 5, НАПРИКЛАД "LI".',
  'ENTER THE WEIGHT UNIT OF MEASUREMENT ASSOCIATED WITH THE QUANTITY IN FIELD 5, FOR EXAMPLE "KG".': 'ВВЕДІТЬ ОДИНИЦЮ ВАГИ ВИМІРЮВАННЯ, ПОВ\'ЯЗАНУ З КІЛЬКІСТЮ В ПОЛІ 5, НАПРИКЛАД "KG".',
  'ENTER THE PACKING UNIT OF MEASUREMENT ASSOCIATED WITH THE QUANTITY IN FIELD 5, FOR EXAMPLE "EA".': 'ВВЕДІТЬ ОДИНИЦЮ ВИМІРЮВАННЯ УПАКОВКИ, ПОВ\'ЯЗАНУ З КІЛЬКІСТЮ В ПОЛІ 5, НАПРИКЛАД "EA".',
  'ENTER THE LITERAL ITEM CODE FROM THE LAND FORCES REPORTABLE ITEM LIST (RIL), FOR EXAMPLE: "AK1234".': 'ВВЕДІТЬ ЛІТЕРАЛЬНИЙ КОД ПРЕДМЕТА З ПЕРЕЛІКУ ЗВІТНИХ ПРЕДМЕТІВ СИЛ СУХОПУТНИХ СИЛ (RIL), НАПРИКЛАД: "AK1234".',
  'ENTER "STOCK:" FOLLOWED BY THE STOCK NUMBER FOR THE ITEM REPORTED, FOR EXAMPLE: "STOCK:1234AB5C6789".': 'ВВЕДІТЬ "STOCK:" І ПОСЛІДОВНО ВКАЖІТЬ НОМЕР СКЛАДУ ДЛЯ ПОВІДОМЛЕННЯ ПРЕДМЕТА, НАПРИКЛАД: "STOCK:1234AB5C6789".',
  'ENTER THE QUANTITY [0-999999999] OF THE ITEM INDICATED IN FIELD 1, FOR EXAMPLE: "1280000".': 'ВВЕДІТЬ КІЛЬКІСТЬ [0-999999999] ПРЕДМЕТА, ЗАЗНАЧЕНОГО В ПОЛІ 1, НАПРИКЛАД: "1280000".',
  'ENTER THE LITERAL ITEM CODE FROM THE NATO LAND FORCES REPORTABLE ITEM LIST (LFRIL), FOR EXAMPLE: "AK1234".': 'ВВЕДІТЬ ЛІТЕРАЛЬНИЙ КОД ПРЕДМЕТА З ЛІСТА ПРЕДМЕТІВ, ЗВІТНИХ ДЛЯ СИЛ СУХОПУТНИХ СИЛ НАТО (LFRIL), НАПРИКЛАД: "AK1234".',
  'ENTER "STO:" FOLLOWED BY THE STOCK NUMBER FOR THE ITEM REPORTED, FOR EXAMPLE: "STO:1234AB5C6789".': 'ВВЕДІТЬ "STO:" І ПОСЛІДОВНО ВКАЖІТЬ НОМЕР СКЛАДУ ДЛЯ ПОВІДОМЛЕННЯ ПРЕДМЕТА, НАПРИКЛАД: "STO:1234AB5C6789".',
  'ENTER "QOH:" FOLLOWED BY THE QUANTITY OF AND ITEM THAT IS COUNTED (WITHOUT REFERENCE TO A UNIT OF MEASUREMENT SUCH AS RADIOS), FOR EXAMPLE: "QOH:99".': 'ВВЕДІТЬ "QOH:" І ПОСЛІДОВНО ВКАЖІТЬ КІЛЬКІСТЬ ПРЕДМЕТА, ЯКИЙ ПІДЛИЧУЄТЬСЯ (БЕЗ ВІДНОШЕННЯ ДО ОДИНИЦІ ВИМІРЮВАННЯ, НАПРИКЛАД, РАДІО), НАПРИКЛАД: "QOH:99".',
  'ENTER THE NUMBER OF DAYS OF SUPPLY [0-9999] OF THE ITEM INDICATED IN FIELD 1, FOR EXAMPLE: "22".': 'ВВЕДІТЬ КІЛЬКІСТЬ ДНІВ ЗАПАСІВ [0-9999] ПРЕДМЕТА, ВКАЗАНОГО В ПОЛІ 1, НАПРИКЛАД: "22".',
  'ENTER "RW:" FOLLOWED BY THE NUMBER OF ROUNDS PER WEAPON, FOR EXAMPLE: "RW:398".': 'ВВЕДІТЬ "RW:" І ПОСЛІДОВНО ВКАЖІТЬ КІЛЬКІСТЬ ПАТРОНІВ НА ОДНУ ЗБРОЮ, НАПРИКЛАД: "RW:398".',
  'Provides the reportable item code or an expanded identifier, i.e. an NSN plus additional Id.': 'Надає код предмета, що підлягає звіту, або розширений ідентифікатор, тобто NSN плюс додатковий ідентифікатор.',
  'Provides the short title of the item being reported. See LOGREP software/RIC system, or RIL provided by SC/designated command.': 'Надає коротку назву предмета, про який звітується. Див. програмне забезпечення LOGREP/RIC систему або RIL, наданий SC/визначеним командуванням.',
  'Provides unit of measurement used for the reported item.': 'Надає одиницю виміру, що використовується для звіту про предмет.',
  'Provides total quantity of an item required to be on-hand and in reserve.': 'Надає загальну кількість предмета, яка повинна бути наявною і в резерві.',
  'Provides the quantity of item required to be held by a unit in accordance with force standards.': 'Надає кількість предмета, яку повинна мати одиниця згідно з нормами сил.',
  'Provides the quantity of items expected to be received before the next reporting period.': 'Надає кількість предметів, які очікуються отримати до наступного періоду звітності.',
  'Provides the quantity of items expected to be transferred out before the next reporting period.': 'Надає кількість предметів, які очікується передати до наступного періоду звітності.',
  'Provides the code specifying required total stocks calculation method.': 'Надає код, який визначає метод розрахунку необхідних загальних запасів.',
  'Provides the logistics factors which are degrading the operational capability of a formation and/or any substantial remarks to the item being reported.': 'Надає логістичні фактори, які погіршують бойові можливості формування і/або будь-які значні зауваження до предмета, про який звітується.',
  'Enter "NAME:" followed by the code of the met station location, for example: "NAME:MET23A".': 'ВВЕДІТЬ "NAME:" І ПОСЛІДОВНО ВКАЖІТЬ КОД МІСЦЯ РОЗТАШУВАННЯ МЕТЕОСТАНЦІЇ, НАПРИКЛАД: "NAME:MET23A".',
  'ENTER "NAME:" FOLLOWED BY 6 ALPHANUMERICS TO SPECIFY A CODED ENTRY FOR THE CENTRE OF THE AREA OF INTEREST, FOR EXAMPLE: "NAME:TAB23A".': 'ВВЕДІТЬ "NAME:" І ПОСЛІДОВНО ВКАЖІТЬ 6 АЛФАНУМЕРИЧНИХ СИМВОЛІВ ДЛЯ ВКАЗУВАННЯ КОДОВАНОГО ЗАПИСУ ЦЕНТРУ ІНТЕРЕСУЮЧОЇ ОБЛАСТІ, НАПРИКЛАД: "NAME:TAB23A".',
  'ENTER "DESIG:" FOLLOWED BY THE FREQUENCY DESIGNATOR, FOR EXAMPLE: "DESIG:TA121".': 'ВВЕДІТЬ "DESIG:" І ПОСЛІДОВНО ВКАЖІТЬ ПОЗИВНУ ЧАСТОТУ, НАПРИКЛАД: "DESIG:TA121".',
  'Enter "DAY:" followed by days, for example: "DAY:02".': 'ВВЕДІТЬ "DAY:" І ПОСЛІДОВНО ВКАЖІТЬ ДНІ, НАПРИКЛАД: "DAY:02".',
  'Enter "HR:" followed by hours, for example: "HR:03".': 'ВВЕДІТЬ "HR:" І ПОСЛІДОВНО ВКАЖІТЬ ГОДИНИ, НАПРИКЛАД: "HR:03".',
  'Enter "MIN:" followed by minutes, for example: "MIN:02".': 'ВВЕДІТЬ "MIN:" І ПОСЛІДОВНО ВКАЖІТЬ ХВИЛИНИ, НАПРИКЛАД: "MIN:02".',
  'Enter "SEC:" followed by seconds, for example: "SEC:15".': 'ВВЕДІТЬ "SEC:" І ПОСЛІДОВНО ВКАЖІТЬ СЕКУНДИ, НАПРИКЛАД: "SEC:15".',
  'Enter "UID:" followed by the unit identifier, for example: "UID:502 WG".': 'ВВЕДІТЬ "UID:" І ПОСЛІДОВНО ВКАЖІТЬ ІДЕНТИФІКАТОР ОДИНИЦІ, НАПРИКЛАД: "UID:502 WG".',
  'Enter "TAS:" followed by the tasked unit, for example: "TAS:44 CONT SQ".': 'ВВЕДІТЬ "TAS:" І ПОСЛІДОВНО ВКАЖІТЬ ЗАВДАНУ ОДИНИЦЮ, НАПРИКЛАД: "TAS:44 CONT SQ".',
  'Enter "DERI:" followed by the derivative source of classification, for example: "DERI:OPLAN 55-01".': 'ВВЕДІТЬ "DERI:" І ПОСЛІДОВНО ВКАЖІТЬ ПОХІДНЕ ДЖЕРЕЛО КЛАСИФІКАЦІЇ, НАПРИКЛАД: "DERI:OPLAN 55-01".',
  'Enter "ORIG:" followed by the original classification authority, for example: "ORIG:SHAPE".': 'ВВЕДІТЬ "ORIG:" І ПОСЛІДОВНО ВКАЖІТЬ ПОЧАТКОВИЙ ОРГАН КЛАСИФІКАЦІЇ, НАПРИКЛАД: "ORIG:SHAPE".',
  'Can only be used when name of original classification authority is specified in this set.': 'Можна використовувати лише тоді, коли в цьому наборі вказано назву оригінального органу класифікації.',
  'Can only be used when downgrading or declassification instructions and date have not been specified within this set.': 'Можна використовувати лише тоді, коли вказані інструкції про зниження рівня секретності або дату зняття з секретності не вказані в цьому наборі.',
  'ENTER "TNO:" FOLLOWED BY THE TARGET NUMBER, FOR EXAMPLE: "TNO:150".': 'ВВЕДІТЬ "TNO:" І ПОСЛІДОВНО ВКАЖІТЬ НОМЕР ЦІЛІ, НАПРИКЛАД: "TNO:150".',
  'ENTER THE TARGET NICKNAME, FOR EXAMPLE: "BARBARA".': 'ВВЕДІТЬ ПРІЗВИЩЕ ЦІЛІ, НАПРИКЛАД: "BARBARA".',
  'ENTER THE UNIT NAME AS A LITERAL, FOR EXAMPLE: "INVINCIBLE".': 'ВВЕДІТЬ НАЗВУ ОДИНИЦІ ЯК ЛІТЕРАЛ, НАПРИКЛАД: "INVINCIBLE".',
  'ENTER AS A LITERAL NAME, FOR EXAMPLE: "LANDCENT" OR "2 FLEET".': 'ВВЕДІТЬ ЯК ЛІТЕРАЛЬНЕ ІМ\'Я, НАПРИКЛАД: "LANDCENT" АБО "2 FLEET".',
  'ENTER AS A LITERAL NAME, FOR EXAMPLE: "5CORPS" OF "TF405.1".': 'ВВЕДІТЬ ЯК ЛІТЕРАЛЬНЕ ІМ\'Я, НАПРИКЛАД: "5CORPS" АБО "TF405.1".',
  'ENTER AS A LITERAL NAME, FOR EXAMPLE: "3GE" OR "6(US) FLEET".': 'ВВЕДІТЬ ЯК ЛІТЕРАЛЬНЕ ІМ\'Я, НАПРИКЛАД: "3GE" АБО "6(US) FLEET".',
  'ENTER AS A LITERAL NAME, FOR EXAMPLE: "3GE" OR "COMUKNLLF".': 'ВВЕДІТЬ ЯК ЛІТЕРАЛЬНЕ ІМ\'Я, НАПРИКЛАД: "3GE" АБО "COMUKNLLF".',
  'ENTER THE SHIP NAME AS A LITERAL, FOR EXAMPLE: "ANDREA DORIA".': 'ВВЕДІТЬ ЯК ЛІТЕРАЛЬНЕ ІМ\'Я КОРАБЛЯ, НАПРИКЛАД: "ANDREA DORIA".',
  'ENTER THE ESTIMATED DTG OF ARRIVAL, FOR EXAMPLE: "101432ZFEB2008".': 'ВВЕДІТЬ ПЕРЕДБАЧЕНИЙ DTG ПРИБУТТЯ, НАПРИКЛАД: "101432ZFEB2008".',
  'ENTER THE SHIP NAME, FOR EXAMPLE: "SARATOGA".': 'ВВЕДІТЬ ІМ\'Я КОРАБЛЯ, НАПРИКЛАД: "SARATOGA".',
  'Enter the assigned a nickname, for example: "PL HOTTEN".': 'ВВЕДІТЬ ПРИЗВІЩЕ, НАПРИКЛАД: "PL HOTTEN".',
  'Enter "DES:" followed by a designator, for example: "DES:AA23NAA3".': 'ВВЕДІТЬ "DES:" І ПОСЛІДОВНО ВКАЖІТЬ ПОЗНАЧЕННЯ, НАПРИКЛАД: "DES:AA23NAA3".',
  'Enter the DTG representing the stop-time of the period, for example: "051800ZJUN2008".': 'ВВЕДІТЬ DTG, ЩО ПРЕДСТАВЛЯЄ ЗУПИНКУ ПЕРІОДУ, НАПРИКЛАД: "051800ZJUN2008".',
  'Enter a variable stop-time qualifier code that cannot be specified with a DTG, for example: "UFN".': 'ВВЕДІТЬ КОД КВАЛІФІКАТОРА ЗМІННОГО ЧАСУ ЗУПИНКИ, ЯКИЙ НЕ МОЖЕ БУТИ ВИЗНАЧЕНИЙ ЗА ДОПОМОГОЮ DTG, НАПРИКЛАД: "UFN"'
};

export default {
  ...options,
  ...messagesList,
  ...blockDescriptions,
  ...fieldsList,
  ...inputHelpers
};
