import React, { createContext, useCallback, useContext, useMemo, useState, useRef } from 'react';
import { SNACKBAR_MESSAGE_DURATION, SnackbarStatus as STATUS } from '../constants';

// Context object
export const SnackbarContext = createContext(undefined);
SnackbarContext.displayName = 'SnackbarContext';

// React Provider Component
export const SnackbarProvider = props => {
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(STATUS.default);
  const timeoutRef = useRef();

  // Updates snackbar properties
  const updateSnackbar = (newMessage, newStatus) => {
    // First, cancel the timeout (if exists)
    clearTimeout(timeoutRef.current);

    // Update the snackbar message and status
    setMessage(newMessage);
    setStatus(newStatus);
  };

  // Updates the Context Provider to request the App to hide the Snackbar
  const hideSnackbar = useCallback(() => {
    updateSnackbar('', STATUS.default);
  }, []);

  // Updates the Context Provider to request the App to show the Snackbar
  const showSnackbar = useCallback(
    ({
      message: newMessage,
      status: newStatus = STATUS.default,
      shouldAutoHide = true,
      autoHideTimeout = SNACKBAR_MESSAGE_DURATION,
    }) => {
      // If the snackbar is visible and it is showing another message, hide it first.
      if (message && message !== newMessage) {
        hideSnackbar();
      }

      // Show it
      updateSnackbar(newMessage, newStatus);

      // Should hide the Snackbar automatically?
      if (shouldAutoHide) {
        timeoutRef.current = setTimeout(hideSnackbar, autoHideTimeout);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hideSnackbar, message, status],
  );

  const value = useMemo(() => ({ hideSnackbar, message, showSnackbar, status }), [
    hideSnackbar,
    message,
    showSnackbar,
    status,
  ]);

  return <SnackbarContext.Provider value={value} {...props} />;
};

// Convenient export of Snackbar's STATUS enum type
export { STATUS };

/**
 * useContext hook to rerender and return the current context value of SnackbarContext
 *
 * @returns {object} An object with the current value of SnackbarContext with these properties:
 *  @property {string} message - The current Snackbar message
 *
 *  @property {string} status - The current Snackbar status
 *
 *  @property {function} showSnackbar - Function to show the Snackbar. Parameters:
 *    It accepts one object with the following fields:
 *      1. {string} message - The message
 *      2. {STATUS} [status=STATUS.default] - The status
 *      3. {boolean} [shouldAutoHide=true] - Whether the Snackbar should reset (hide) automatically
 *      4. {number} [autoHideTimeout=SNACKBAR_MESSAGE_DURATION] - The length of time the Snackbar will be visible
 *
 *  @property {function} hideSnackbar - Function to reset all the properties of the Snackbar.
 *    This function sets the message to "", causing the App to hide the Snackbar.
 *
 */
export const useSnackbar = () => useContext(SnackbarContext);
